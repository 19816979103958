import { DatePicker } from "antd";
import "./index.scss";
import moment from "moment";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { useState, useEffect } from "react";
import convertHoursToMinutes from "utils/convertHoursToMinutes";
import dynamicRangeHandler from "utils/dynamicRangeHandler";
import { useSelector } from "react-redux";
import momentTimezone from "moment-timezone";
import { RangeFilters } from "utils/enums";

const data = [
  "Shift A",
  "Shift B",
  "Shift C",
  "Today",
  "Yesterday",
  "This Week",
  "Last Week",
  "MTD",
  "YTD",
];
export const CustomTimePicker = (props: any) => {
  const { Option } = Select;
  const {
    setStartTime,
    setEndTime,
    startTime,
    endTime,
    setSelectedValue,
    selectedValue,
  } = props;
  const { RangePicker } = DatePicker;

  let startTimeRedux = useSelector(
    (state: any) => state.root.filterValues.startTime
  );
  let endTimeRedux = useSelector(
    (state: any) => state.root.filterValues.endTime
  );

  const [shiftStartTime, setShiftStartTime] = useState(startTimeRedux);
  const [shiftEndTime, setShiftEndTime] = useState(endTimeRedux);

  const onChange = (value: any) => {
    setSelectedValue(value);
  };

  const rangeHandler = (value) => {
    if (props.setStartTime) {
      setStartTime(moment(value[0]).valueOf());
      setEndTime(moment(value[1]).valueOf());
    }
  };

  const datePickerChangeHandler = (value) => {
    if (selectedValue === "Shift A") {
      setStartTime(
        momentTimezone
          .tz(`${value.format("YYYY-MM-DD")} 08:00:00`, "Europe/London")
          .valueOf()
      );
      setEndTime(
        momentTimezone
          .tz(`${value.format("YYYY-MM-DD")} 15:59:59`, "Europe/London")
          .valueOf()
      );
      setShiftStartTime(
        momentTimezone
          .tz(`${value.format("YYYY-MM-DD")} 08:00:00`, "Europe/London")
          .valueOf()
      );
      setShiftEndTime(
        momentTimezone
          .tz(`${value.format("YYYY-MM-DD")} 15:59:59`, "Europe/London")
          .valueOf()
      );
    } else if (selectedValue === "Shift B") {
      let startTime= momentTimezone
      .tz(`${value.format("YYYY-MM-DD")} 16:00:00`, "Europe/London")
      .valueOf();
      setStartTime(startTime);
      setEndTime(
        momentTimezone(
        moment(startTime).add(28799,"seconds"),"Europe/London").valueOf()
      );
      setShiftStartTime(
        startTime
      );
      setShiftEndTime(
        momentTimezone(
          moment(startTime).add(28799,"seconds"),"Europe/London").valueOf()
      );
    } else {
      let startTime= momentTimezone
      .tz(`${value.format("YYYY-MM-DD")} 16:00:00`, "Europe/London").valueOf()
        let startTimeWith8HoursAddition= momentTimezone(
        moment(startTime).add(28800,"seconds"),"Europe/London").valueOf()
        let endTimeWith16HoursAddition= momentTimezone(
          moment(startTime).add(57599,"seconds"),"Europe/London").valueOf()
      setStartTime(
        startTimeWith8HoursAddition
      );
      setEndTime(
        endTimeWith16HoursAddition
      );
      setShiftStartTime(
        startTimeWith8HoursAddition
      );
      setShiftEndTime(
        endTimeWith16HoursAddition
      );
    }
  };
  const onSearch = () => {};
  useEffect(() => {
    dynamicRangeHandler(selectedValue, rangeHandler,shiftStartTime,setShiftStartTime,setShiftEndTime);
  }, [selectedValue]);

  return (
    <>
      <Select
        style={{
          width: "140px",
          height: "30px",
        }}
        showSearch
        placeholder={"YTD"}
        optionFilterProp="children"
        value={selectedValue}
        onChange={(value) => onChange(value)}
        onSearch={onSearch}
        filterOption={true}
      >
        {data?.map((item: any) => {
          return (
            <>
              <Option value={item}>{item}</Option>
            </>
          );
        })}
        ;
      </Select>
      {!selectedValue.includes("Shift") ? (
        <RangePicker
          suffixIcon={<ClockCircleOutlined />}
          allowClear={true}
          format={"DD-MM-YYYY HH:mm:ss"}
          showTime
          className={"rangepicker"}
          value={[moment(startTime), moment(endTime)]}
          onChange={rangeHandler}
        />
      ) : (
        <>
          <DatePicker
          //Note : As per discussion with Devendra this is done specific to UPL
            value={selectedValue===RangeFilters.SHIFT_C? moment(shiftStartTime).subtract(1,"days"):moment(shiftStartTime)}
            onChange={datePickerChangeHandler}
          />
          <div className="shift">
            {moment(shiftStartTime).format("DD-MM-YYYY HH:mm:ss")} to{" "}
            {moment(shiftEndTime).format("DD-MM-YYYY HH:mm:ss")}
          </div>
        </>
      )}
    </>
  );
};
