import { MouseEventHandler, useState } from "react";
import { Document, HTMLElement } from "types";

export const useFullscreen = (): [
  boolean,
  MouseEventHandler<HTMLLIElement> | undefined
] => {
  const [isFullScreen, setFullScreen] = useState(false);

  function fullScreen() {
    let doc = window.document;
    let docEl = doc.documentElement;

    let requestFullScreen =
      docEl.requestFullscreen ||
      (docEl as HTMLElement).mozRequestFullscreen ||
      (docEl as HTMLElement).webkitRequestFullscreen ||
      (docEl as HTMLElement).msRequestFullscreen;
    let cancelFullScreen =
      doc.exitFullscreen ||
      (docEl as Document).mozCancelFullScreen ||
      (docEl as Document).webkitExitFullscreen ||
      (docEl as Document).msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !(docEl as Document).mozFullScreenElement &&
      !(docEl as Document).webkitFullscreenElement &&
      !(docEl as Document).msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
      setFullScreen(true);
    } else {
      setFullScreen(false);
      cancelFullScreen.call(doc);
    }
  }
  return [isFullScreen, fullScreen];
};
