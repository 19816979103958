import * as types from './actionTypes';


export const getDatatrendingViewOptions = (payload: any) => ({
    type: types.GET_DATA_TRENDING_VIEW,
    payload,
});

export const getDatatrendingViewDetails = (payload: any) => ({
    type: types.GET_DATA_TRENDING_VIEW_DETAILS,
    payload,
});

export const saveCurrentView = (payload: any) => ({
    type: types.SAVE_DATA_TRENDING_SCREEN_VIEW,
    payload,
});

export const deleteCurrentView = (payload: any) => ({
    type: types.DELETE_DATA_TRENDING_SCREEN_VIEW,
    payload,
});

export const getAggregatedDataForTagAction = (payload: any) => ({
    type: types.GET_AGGREGATED_DATA_FOR_TAG,
    payload,
});

export const getTagOverviewDataAction = (payload: any) => ({
    type: types.GET_TAG_OVERVIEW_DATA_FOR_DATA_TABLE,
    payload,
});

export const getTagOverviewData = (payload: any) => ({
    type: types.GET_TAG_OVERVIEW_DATA,
    payload,
});

export const removeTagOverviewData = (payload: any) => ({
    type: types.REMOVE_TAG_OVERVIEW_DATA,
    payload,
});

export const setGraphType = (payload: any) => ({
    type: types.SET_GRAPH_TYPE,
    payload,
});

export const updateSelectedGraphTags = (payload: any) => ({
    type: types.UPDATE_SELECTED_GRAPH_TAGS,
    payload,
});

export const emptyTableBody = () => ({
    type: types.EMPTY_TABLE_BODY,
});

export const getAllTags = (payload: any) => ({
    type: types.GET_ALL_TAGS,
    payload,
});

export const getModelsList = (payload: any) => ({
    type: types.GET_MODELS_LIST,
    payload,
});

export const getTagMappingTree = (payload: any) => ({
    type: types.GET_TAGMAPPING_TREE,
    payload,
});

export const getRawCSVData = (payload: any) => ({
    type: types.GET_RAW_CSV_DATA,
    payload,
});