import { ProgressBarProps } from "types";
import "./index.scss";

const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  variableName,
  showPercentage = false,
}) => {
  var color = "82, 196, 26";

  if (value > 10) color = "82, 196, 26";
  if (value < 10) color = "250, 84, 28";

  return (
    <div className="light-grey">
      <div
        className="container green center"
        style={{
          border: `1px solid rgb(${color},1)`,
        }}
      >
        <div
          style={{
            backgroundColor: `rgb(${color},0.25)`,
            width: `${value}%`,
            borderRadius: 12,
            display: "flex",
          }}
        >
          <div
            className="progress-bar-variable"
            style={{ color: `rgb(${color},1)` }}
          >
            {variableName}
          </div>
        </div>
        <div
          className="progress-bar-value"
          style={{ color: `rgb(${color},1)` }}
        >
          {value}
          {showPercentage ? "%" : ""}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
