import { all, call, put, takeLatest } from "redux-saga/effects";
import * as types from 'store/actions/actionTypes';
import { ReasonCodeService } from "store/services/reasonCodeService";

export function* editReasonCodeCategorySaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const reasonCodeService = ReasonCodeService.getInstance();
    const isCategoryUpdated = yield call([reasonCodeService, reasonCodeService.editReasonCodeCategory], payload);
    if (isCategoryUpdated) {
      const response = yield call([reasonCodeService, reasonCodeService.getAllReasonCodes], payload);
      yield put({ type: types.GET_REASON_CODE_CATEGORY_DATA_SUCCESS, payload: response });
    }
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* createReasonCodeCategorySaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const reasonCodeService = ReasonCodeService.getInstance();
    const isCategoryCreated = yield call([reasonCodeService, reasonCodeService.createReasonCodeCategory], payload);
    if (isCategoryCreated) {
      const response = yield call([reasonCodeService, reasonCodeService.getAllReasonCodes], payload);
      yield put({ type: types.GET_REASON_CODE_CATEGORY_DATA_SUCCESS, payload: response });
    }
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* createReasonCodeFactorSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const reasonCodeService = ReasonCodeService.getInstance();
    const isFactorCreated = yield call([reasonCodeService, reasonCodeService.createResonCodeFactor], payload);
    if (isFactorCreated) {
      const response = yield call([reasonCodeService, reasonCodeService.getAllReasonCodes], payload);
      yield put({ type: types.GET_REASON_CODE_CATEGORY_DATA_SUCCESS, payload: response });
    }
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* createReasonCodeDetailsSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const reasonCodeService = ReasonCodeService.getInstance();
    const isReasonCodeDetailsCreated = yield call([reasonCodeService, reasonCodeService.createReasonCodeDetails], payload);
    if (isReasonCodeDetailsCreated) {
      const response = yield call([reasonCodeService, reasonCodeService.getAllReasonCodes], payload);
      yield put({ type: types.GET_REASON_CODE_CATEGORY_DATA_SUCCESS, payload: response });
    }
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getReasonCodeDetails({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const reasonCodeService = ReasonCodeService.getInstance();
    const reasonCodeDetails = yield call([reasonCodeService, reasonCodeService.getReasonCodeDetails]);
    yield put({ type: types.GET_REASON_CODE_CATEGORIES_SUCCESS, payload: reasonCodeDetails });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getAllReasonCodesSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const reasonCodeService = ReasonCodeService.getInstance();
    const response = yield call([reasonCodeService, reasonCodeService.getAllReasonCodes], payload);
    yield put({ type: types.GET_REASON_CODE_CATEGORY_DATA_SUCCESS, payload: response });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getAssignedReasonCodeSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const reasonCodeService = ReasonCodeService.getInstance();
    const response = yield call([reasonCodeService, reasonCodeService.getAllAssignedReasonCode], payload);
    yield put({ type: types.GET_ASSIGNED_REASON_CODE_SUCCESS, payload: response });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getAmdDetailsListSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const reasonCodeService = ReasonCodeService.getInstance();
    const response = yield call([reasonCodeService, reasonCodeService.getAMDList], payload);
    yield put({ type: types.GET_AMD_LIST_SUCCESS, payload: response });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* assignReasonCodeToResourceSage({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const reasonCodeService = ReasonCodeService.getInstance();
    const response = yield call([reasonCodeService, reasonCodeService.assignmentReasonCodeToResource], payload);
    yield put({ type: types.ASSIGN_REASON_CODE_TO_RESOURCE_SUCCESS, payload: response });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
};

export function* unAssignReasonCodeToResourceSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const reasonCodeService = ReasonCodeService.getInstance();
    const response = yield call([reasonCodeService, reasonCodeService.unAssignmentReasonCodeToResource], payload);
    yield put({ type: types.UNASSIGN_REASON_CODE_TO_RESOURCE_SUCCESS, payload: response });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
};

export function* deleteReasonCodeCategorySaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const reasonCodeService = ReasonCodeService.getInstance();
    const response = yield call([reasonCodeService, reasonCodeService.deleteReasonCodeCategory], payload);
    if (response) {
      const response = yield call([reasonCodeService, reasonCodeService.getAllReasonCodes], { viewType: "grid" });   //payload for getting all reason code
      yield put({ type: types.GET_REASON_CODE_CATEGORY_DATA_SUCCESS, payload: response });
    }
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* endDownTimeAmdSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const reasonCodeService = ReasonCodeService.getInstance();
    const response = yield call([reasonCodeService, reasonCodeService.endDownTimeAmd], payload);
    yield put({ type: types.END_DOWNTIME_AMD_SUCCESS, payload: response });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* createReasonCodeLogsSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const reasonCodeService = ReasonCodeService.getInstance();
    const isReasonCodeLogsCreated = yield call([reasonCodeService, reasonCodeService.createReasonCodeLogs], payload);
    if (isReasonCodeLogsCreated) {
      const response = yield call([reasonCodeService, reasonCodeService.getAMDList], payload);
      yield put({ type: types.GET_AMD_LIST_SUCCESS, payload: response });
    }
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* deleteReasonCodeLogsSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const reasonCodeService = ReasonCodeService.getInstance();
    const response = yield call([reasonCodeService, reasonCodeService.deleteRasonCodeLogs], payload);
    yield put({ type: types.DELETE_REASON_CODE_LOGS_SUCCESS, payload: response });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* editReasonCodeLogsSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const reasonCodeService = ReasonCodeService.getInstance();
    const isReasonCodeLogsEdited = yield call([reasonCodeService, reasonCodeService.editReasonCodeLogs], payload);
    if (isReasonCodeLogsEdited) {
      const response = yield call([reasonCodeService, reasonCodeService.getAMDList], payload);
      yield put({ type: types.GET_AMD_LIST_SUCCESS, payload: response });
    }
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}



export function* reasonCodeManagementSaga() {
  yield all([
    takeLatest(types.CREATE_REASON_CODE_CATEGORY, createReasonCodeCategorySaga),
    takeLatest(types.GET_REASON_CODE_CATEGORIES, getReasonCodeDetails),
    takeLatest(types.CREATE_REASON_CODE_FACTOR, createReasonCodeFactorSaga),
    takeLatest(types.GET_REASON_CODE_CATEGORY_DATA, getAllReasonCodesSaga),
    takeLatest(types.ADD_REASON_CODE_DETAILS, createReasonCodeDetailsSaga),
    takeLatest(types.GET_ASSIGNED_REASON_CODE, getAssignedReasonCodeSaga),
    takeLatest(types.GET_AMD_LIST, getAmdDetailsListSaga),
    takeLatest(types.ASSIGN_REASON_CODE_TO_RESOURCE, assignReasonCodeToResourceSage),
    takeLatest(types.DELETE_REASON_CODE_CATEGORY, deleteReasonCodeCategorySaga),
    takeLatest(types.UNASSIGN_REASON_CODE_TO_RESOURCE, unAssignReasonCodeToResourceSaga),
    takeLatest(types.END_DOWNTIME_AMD, endDownTimeAmdSaga),
    takeLatest(types.CREATE_REASON_CODE_LOGS, createReasonCodeLogsSaga),
    takeLatest(types.DELETE_REASON_CODE_LOGS, deleteReasonCodeLogsSaga),
    takeLatest(types.EDIT_REASON_CODE_LOGS, editReasonCodeLogsSaga),
    takeLatest(types.EDIT_REASON_CODE_CATEGORY, editReasonCodeCategorySaga),
  ]);
}