import { ResonCodeHeader } from "./ReasonCodeHeader";
import ReasonCodeLandingPage from "./ReasonCodeLandingPage";
import { Sidebar } from "./Sidebar";
import './index.scss';

export const ReasonCodeOperator = () => {
  return (
    <>
    <div className="reason-code-container">
      <ResonCodeHeader />
      <div className="reason-code-app-container">
        <Sidebar />
        <div>
          <ReasonCodeLandingPage />
        </div>
      </div>
      </div>
    </>
  );
}