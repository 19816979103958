import { Divider } from "antd";
import { DropdownFilterComponent } from "components/DropdownFilterComponent";
import StatisticsTable from "components/StatisticsTable";
import { getUrl } from "config/apiconfig";
import { getDate, getMonth, getYear } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRuntimeAnalysisDetailsData } from "store/actions/runtimeAnalysis";
import { exportCSVFile } from "utils/common";

const Details: React.FC = () => {
  const StoreFilterValues = {
    lineObject: useSelector((state: any) => state.root.filterValues.lineObject),
    nodeId: useSelector((state: any) => state.root.filterValues.nodeId),
    startTime: useSelector((state: any) => state.root.filterValues.startTime),
    endTime: useSelector((state: any) => state.root.filterValues.endTime),
    severity: useSelector((state: any) => state.root.filterValues.severity),
    batches: useSelector((state: any) => state.root.filterValues.batches),
    sku: useSelector((state: any) => state.root.filterValues.sku),
  };
  const [payload, setPayload] = useState<any>(StoreFilterValues);
  const csvData = [];
  const dispatch = useDispatch();
  const filterFlag = useSelector((state: any) => state.root.filterFlag);

  const exportRuntimeStastics = () => {
    const columns = [
      "Line",
      "Machine",
      "Sku",
      "Description",
      "Status ",
      "Start Time",
      "End Time",
      "Duration",
      "Reason Code",
    ].join(",");
    let str = columns;
    let runtimeAnalysisDetailsDataCollection: any[] = [];

    runtimeAnalysisDetailsData?.runtimeStatistics.map((item: any, index) => {
      runtimeAnalysisDetailsDataCollection.push(...item.data);
    });
    runtimeAnalysisDetailsDataCollection.length &&
      runtimeAnalysisDetailsDataCollection.forEach((item) => {
        let currentRow =
          item.line +
          "," +
          item.machine +
          "," +
          item.sku +
          "," +
          item.description +
          "," +
          item.status +
          "," +
          item.startTime.replace(",", "") +
          "," +
          item.endTime.replace(",", "") +
          "," +
          item.duration +
          "," +
          item.reasonCode;
        str = str + "\n" + currentRow;
      });
    let day =
      getDate(new Date()) +
      "_" +
      (getMonth(new Date()) + 1) +
      "_" +
      getYear(new Date());
    const fileName = `RuntimeStasticsReport_${day}.csv`;
    exportCSVFile(str, fileName);
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(getRuntimeAnalysisDetailsData(StoreFilterValues));
    }, 100);
  }, [payload, filterFlag]);

  const runtimeAnalysisDetailsData = useSelector(
    (state: any) => state.runtimeAnalysis.details
  );

  return (
    <>
      <DropdownFilterComponent
        setPayload={setPayload}
        download={() => exportRuntimeStastics()}
      />

      <div
        className="alarmevent-dashboard-wrapper-container"
        style={{ paddingLeft: "5px", paddingRight: "8px" }}
      >
        <div style={{ margin: "0px 8px" }}>
          <Divider style={{ margin: "8px 0px" }}>Runtime Statistics</Divider>
          <StatisticsTable
            data={runtimeAnalysisDetailsData.runtimeStatistics}
          />
          <Divider style={{ margin: "8px 0px" }}>Downtime Statistics</Divider>
          <StatisticsTable
            data={runtimeAnalysisDetailsData.downtimeStatistics}
          />
        </div>
      </div>
    </>
  );
};

export default Details;
