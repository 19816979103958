import { ReactNode, useState } from "react";
import {
  QuestionCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { Popover, Tag } from "antd";
import "./login.scss";
import { Text } from "components/Text";
import { AboutModal } from "components/AboutModal";
import { useTime } from "hooks/useTime";
import LoginFooter from "components/LoginFooter";
import { LicenseModal } from "components/LicenseModal";
import loginRect from "assets/images/login-rect.png";
import ForgotPassword from "./Forgot-Password";
import { UserLogin } from "./User-Login";
import { SessionExpired } from "./Session-expired";
import { ResetPassword } from "./Reset-Password";

const Login: React.FC = (props: any) => {
  const { setAuthenticated } = props;
  const displayTime = useTime();

  const [openAboutModal, setOpenAboutModal] = useState<boolean>(false);
  const [toggleLicenseModal, setLicenseModal] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const replaceCurrentComponent = (value: string) => {
    if (value === 'forgotpassword') {
      setCurrentComponent(<ForgotPassword openResetPassword={replaceCurrentComponent} />);
    } else if (value === 'resetPassword') {
      setCurrentComponent(<ResetPassword openSessionExpired={replaceCurrentComponent} />);
    } else if (value === 'sessionexpired') {
      setCurrentComponent(<SessionExpired openLogin={replaceCurrentComponent}/>)
    } else {
      setCurrentComponent(<UserLogin setAuthenticated={setAuthenticated} openForgotPassword={replaceCurrentComponent} />)
    }
  }

  const [currentComponent, setCurrentComponent] = useState<ReactNode>(
    <UserLogin setAuthenticated={setAuthenticated} openForgotPassword={replaceCurrentComponent} />
  );

  const helpMeContent = (
    <div className="modal-help-warpper">
      <p
        className="sign-out"
        onClick={() => {
          setOpenAboutModal(true);
          setShowPopover(!showPopover);
        }}
      >
        <Text type="descriptionGrey65">About</Text>

      </p>
      <p
        className="sign-out blue-title"
        onClick={() => {
          setLicenseModal(true);
          setShowPopover(!showPopover);
        }}
      >
        <Text type="descriptionGrey65">License</Text>

      </p>
      <AboutModal
        visible={openAboutModal}
        handleCancel={() => setOpenAboutModal(false)}
      />

      <LicenseModal
        visible={toggleLicenseModal}
        handleCancel={() => setLicenseModal(false)}
      />
    </div>
  );
  return (
    <div className="login-container">
      <div className="login-help-about">
        <Popover
          content={helpMeContent}
          trigger="click"
          title={<Text type="descriptionGrey65">Help</Text>}
          placement="bottomRight"
          visible={showPopover}
          onVisibleChange={() => setShowPopover(!showPopover)}
        >
          <QuestionCircleOutlined className="question-mark-circle"></QuestionCircleOutlined>
        </Popover>
        <div className="connect-button">
          <Tag color="success">Connected</Tag>
        </div>
        <div className="clock-wrapper">
          <div className="clock-container">
            <ClockCircleOutlined />
            <div>{displayTime}</div>
          </div>
        </div>
      </div>
      <div className="parent-container">
        <img className="login-rect" src={loginRect} alt="brabo" />
        {currentComponent}
      </div>
      <LoginFooter />
    </div>
  );
};

export default Login;
