import { useState } from "react";
import { Assigned } from "./Assigned";
import "./index.scss";
import { Resources } from "./Resources";

export const ReasonCodeAssignment = () => {
  const [equipmentId, setEquipmentId] = useState();

  return (
    <div className="assignment-tab-container">
      <Resources setEquipmentId={setEquipmentId} />
      <Assigned equipmentId={equipmentId} />
    </div>
  );
};
