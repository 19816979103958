// @ts-nocheck
import { message } from "antd";
import { getUrl } from "config/apiconfig";
import { mapModelResponse, mapTagList, mapTree, mapMachineData } from "./mapper/asset.mapper";
const { ApiStatusCode } = require('protobuf/asset_proto/common/Enums_pb');


export class AssetModelService {
    private static instance: AssetModelService;
    private assetModelServiceClient: any;
    private assetModelProtoObj: any;
    private apiProtoObj: any;
    private assetObjectProto: any;
    private nodeServicePromiseClient: any;
    private equipmentProtoObj: any;
    private nodeTypeProtoObj: any;
    private streamServicePromiseClient: any;
    private authToken: any = '';
    private metadata: any;


    private constructor() {
        const assetURL = getUrl('assetServiceGrpc');
        this.authToken = localStorage.getItem('authToken');
        this.metadata = { 'authorization': 'Bearer ' + this.authToken };
        const assetModelService = require('protobuf/asset_proto/service/AssetRpc_grpc_web_pb');
        this.assetModelProtoObj = require('protobuf/asset_proto/service/AssetRpc_pb');
        this.apiProtoObj = require('protobuf/asset_proto/common/API_pb');
        this.assetObjectProto = require('protobuf/asset_proto/objects/Asset_pb');
        this.assetModelServiceClient =
            new assetModelService.ModelServicePromiseClient(assetURL, null, null);
        this.nodeServicePromiseClient =
            new assetModelService.NodeServicePromiseClient(assetURL, null, null);
        this.equipmentProtoObj = require('protobuf/asset_proto/objects/EquipmentClass_pb');
        this.nodeTypeProtoObj = require('protobuf/asset_proto/objects/NodeType_pb');
        this.streamServicePromiseClient =
            new assetModelService.StreamServicePromiseClient(assetURL, null, null);
    }


    public static getInstance(): AssetModelService {
        if (!AssetModelService.instance) {
            AssetModelService.instance = new AssetModelService();
        }
        return AssetModelService.instance;
    }


    getList = (payload: any) => {
        const request = new this.assetModelProtoObj.ModelOverviewParameters();
        if (Object.keys(payload).length > 0) {
            request.setSortBy(payload.sortBy);
            request.setSortOrder(payload.sortOrder);
            request.setFilterByCreatedOn(payload.filterByCreatedOn);
            request.setFilterByUpdatedOn(payload.filterByUpdatedOn);
        }

        return this.assetModelServiceClient
            .getList(request, this.metadata)
            .then((response) => {
                if (
                    response &&
                    response.getApiResponse().getCode() === ApiStatusCode.SUCCESS
                ) {
                    message.loading('Loading models list ', 1);
                    return mapModelResponse(response.getModelList());
                } else {
                    message.error('Unable to load Models list');
                    return [];
                }
            })
            .catch((err) => {
                message.error('Unable to load Models list');
                throw new Error(err);
            });
    };

    getAllTags = (payload: number) => {
        const request = new this.assetModelProtoObj.StreamFilter();
        request.setStatus(payload.status);
        const IDReq = new this.apiProtoObj.ID();

        if (payload.nodeId) {
            IDReq.setId(payload.nodeId)
            request.setParentNodeId(IDReq);
        }
        request.setIncludeChildNodes(true);

        return this.streamServicePromiseClient
            .getList(request, this.metadata)
            .then((response) => {
                if (
                    response &&
                    response.getApiResponse().getCode() === ApiStatusCode.SUCCESS
                ) {
                    return mapTagList(response.getStreamsList());
                } else {
                    message.error('Unable to get all tags');
                }
            })
            .catch((err) => {
                message.error('Unable to get all tags');
                throw new Error(err);
            });
    };

    getTagMappingTree = (modelId: number, pushToCloud: number) => {
        const request = new this.assetModelProtoObj.ModelTreeParameters();
        const id = new this.apiProtoObj.ID();
        id.setId(modelId);
        request.setModelId(id);
        request.setCloudPushEligible(pushToCloud);
        return this.nodeServicePromiseClient
            .getTree(request, this.metadata)
            .then((response) => {
                if (
                    response &&
                    response.getApiResponse().getCode() === ApiStatusCode.SUCCESS
                ) {
                    return mapTree(response.getNode());
                } else {
                    message.error('Unable to get Heirarchy Tree');
                }
            })
            .catch((err) => {
                message.error('Unable to get Heirarchy Tree');
                throw new Error(err);
            });
    };

}