// source: common/ReasonCodeDetails.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.proto.ReasonCode', null, global);
goog.exportSymbol('proto.proto.ReasonCode.SingleSelectionFilterCase', null, global);
goog.exportSymbol('proto.proto.ReasonCodeCategoryFilter', null, global);
goog.exportSymbol('proto.proto.ReasonCodeCategoryFilter.SingleSelectionFilterCase', null, global);
goog.exportSymbol('proto.proto.ReasonCodeFactor', null, global);
goog.exportSymbol('proto.proto.ReasonCodeFactor.SingleSelectionFilterCase', null, global);
goog.exportSymbol('proto.proto.ViewType', null, global);
goog.exportSymbol('proto.proto.ViewType.ViewfilterCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeCategoryFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.proto.ReasonCodeCategoryFilter.oneofGroups_);
};
goog.inherits(proto.proto.ReasonCodeCategoryFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeCategoryFilter.displayName = 'proto.proto.ReasonCodeCategoryFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeFactor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.proto.ReasonCodeFactor.oneofGroups_);
};
goog.inherits(proto.proto.ReasonCodeFactor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeFactor.displayName = 'proto.proto.ReasonCodeFactor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.proto.ReasonCode.oneofGroups_);
};
goog.inherits(proto.proto.ReasonCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCode.displayName = 'proto.proto.ReasonCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ViewType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.proto.ViewType.oneofGroups_);
};
goog.inherits(proto.proto.ViewType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ViewType.displayName = 'proto.proto.ViewType';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.proto.ReasonCodeCategoryFilter.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.proto.ReasonCodeCategoryFilter.SingleSelectionFilterCase = {
  SINGLE_SELECTION_FILTER_NOT_SET: 0,
  DEFAULT: 1,
  ALPHABET_ASCENDING: 2,
  ALPHABET_DESCENDING: 3,
  NUMERIC_ASCENDING: 4,
  NUMERIC_DESCENDING: 5
};

/**
 * @return {proto.proto.ReasonCodeCategoryFilter.SingleSelectionFilterCase}
 */
proto.proto.ReasonCodeCategoryFilter.prototype.getSingleSelectionFilterCase = function() {
  return /** @type {proto.proto.ReasonCodeCategoryFilter.SingleSelectionFilterCase} */(jspb.Message.computeOneofCase(this, proto.proto.ReasonCodeCategoryFilter.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeCategoryFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeCategoryFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeCategoryFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeCategoryFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_default: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    alphabetAscending: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    alphabetDescending: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    numericAscending: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    numericDescending: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeCategoryFilter}
 */
proto.proto.ReasonCodeCategoryFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeCategoryFilter;
  return proto.proto.ReasonCodeCategoryFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeCategoryFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeCategoryFilter}
 */
proto.proto.ReasonCodeCategoryFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefault(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlphabetAscending(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlphabetDescending(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNumericAscending(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNumericDescending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeCategoryFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeCategoryFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeCategoryFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeCategoryFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional bool default = 1;
 * @return {boolean}
 */
proto.proto.ReasonCodeCategoryFilter.prototype.getDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCodeCategoryFilter} returns this
 */
proto.proto.ReasonCodeCategoryFilter.prototype.setDefault = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.proto.ReasonCodeCategoryFilter.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ReasonCodeCategoryFilter} returns this
 */
proto.proto.ReasonCodeCategoryFilter.prototype.clearDefault = function() {
  return jspb.Message.setOneofField(this, 1, proto.proto.ReasonCodeCategoryFilter.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeCategoryFilter.prototype.hasDefault = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool alphabet_ascending = 2;
 * @return {boolean}
 */
proto.proto.ReasonCodeCategoryFilter.prototype.getAlphabetAscending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCodeCategoryFilter} returns this
 */
proto.proto.ReasonCodeCategoryFilter.prototype.setAlphabetAscending = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.proto.ReasonCodeCategoryFilter.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ReasonCodeCategoryFilter} returns this
 */
proto.proto.ReasonCodeCategoryFilter.prototype.clearAlphabetAscending = function() {
  return jspb.Message.setOneofField(this, 2, proto.proto.ReasonCodeCategoryFilter.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeCategoryFilter.prototype.hasAlphabetAscending = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool alphabet_descending = 3;
 * @return {boolean}
 */
proto.proto.ReasonCodeCategoryFilter.prototype.getAlphabetDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCodeCategoryFilter} returns this
 */
proto.proto.ReasonCodeCategoryFilter.prototype.setAlphabetDescending = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.proto.ReasonCodeCategoryFilter.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ReasonCodeCategoryFilter} returns this
 */
proto.proto.ReasonCodeCategoryFilter.prototype.clearAlphabetDescending = function() {
  return jspb.Message.setOneofField(this, 3, proto.proto.ReasonCodeCategoryFilter.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeCategoryFilter.prototype.hasAlphabetDescending = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool numeric_ascending = 4;
 * @return {boolean}
 */
proto.proto.ReasonCodeCategoryFilter.prototype.getNumericAscending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCodeCategoryFilter} returns this
 */
proto.proto.ReasonCodeCategoryFilter.prototype.setNumericAscending = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.proto.ReasonCodeCategoryFilter.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ReasonCodeCategoryFilter} returns this
 */
proto.proto.ReasonCodeCategoryFilter.prototype.clearNumericAscending = function() {
  return jspb.Message.setOneofField(this, 4, proto.proto.ReasonCodeCategoryFilter.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeCategoryFilter.prototype.hasNumericAscending = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool numeric_descending = 5;
 * @return {boolean}
 */
proto.proto.ReasonCodeCategoryFilter.prototype.getNumericDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCodeCategoryFilter} returns this
 */
proto.proto.ReasonCodeCategoryFilter.prototype.setNumericDescending = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.proto.ReasonCodeCategoryFilter.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ReasonCodeCategoryFilter} returns this
 */
proto.proto.ReasonCodeCategoryFilter.prototype.clearNumericDescending = function() {
  return jspb.Message.setOneofField(this, 5, proto.proto.ReasonCodeCategoryFilter.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeCategoryFilter.prototype.hasNumericDescending = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.proto.ReasonCodeFactor.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.proto.ReasonCodeFactor.SingleSelectionFilterCase = {
  SINGLE_SELECTION_FILTER_NOT_SET: 0,
  DEFAULT: 1,
  ALPHABET_ASCENDING: 2,
  ALPHABET_DESCENDING: 3,
  NUMERIC_ASCENDING: 4,
  NUMERIC_DESCENDING: 5
};

/**
 * @return {proto.proto.ReasonCodeFactor.SingleSelectionFilterCase}
 */
proto.proto.ReasonCodeFactor.prototype.getSingleSelectionFilterCase = function() {
  return /** @type {proto.proto.ReasonCodeFactor.SingleSelectionFilterCase} */(jspb.Message.computeOneofCase(this, proto.proto.ReasonCodeFactor.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeFactor.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeFactor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeFactor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeFactor.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_default: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    alphabetAscending: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    alphabetDescending: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    numericAscending: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    numericDescending: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeFactor}
 */
proto.proto.ReasonCodeFactor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeFactor;
  return proto.proto.ReasonCodeFactor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeFactor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeFactor}
 */
proto.proto.ReasonCodeFactor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefault(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlphabetAscending(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlphabetDescending(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNumericAscending(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNumericDescending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeFactor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeFactor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeFactor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeFactor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional bool default = 1;
 * @return {boolean}
 */
proto.proto.ReasonCodeFactor.prototype.getDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCodeFactor} returns this
 */
proto.proto.ReasonCodeFactor.prototype.setDefault = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.proto.ReasonCodeFactor.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ReasonCodeFactor} returns this
 */
proto.proto.ReasonCodeFactor.prototype.clearDefault = function() {
  return jspb.Message.setOneofField(this, 1, proto.proto.ReasonCodeFactor.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeFactor.prototype.hasDefault = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool alphabet_ascending = 2;
 * @return {boolean}
 */
proto.proto.ReasonCodeFactor.prototype.getAlphabetAscending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCodeFactor} returns this
 */
proto.proto.ReasonCodeFactor.prototype.setAlphabetAscending = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.proto.ReasonCodeFactor.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ReasonCodeFactor} returns this
 */
proto.proto.ReasonCodeFactor.prototype.clearAlphabetAscending = function() {
  return jspb.Message.setOneofField(this, 2, proto.proto.ReasonCodeFactor.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeFactor.prototype.hasAlphabetAscending = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool alphabet_descending = 3;
 * @return {boolean}
 */
proto.proto.ReasonCodeFactor.prototype.getAlphabetDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCodeFactor} returns this
 */
proto.proto.ReasonCodeFactor.prototype.setAlphabetDescending = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.proto.ReasonCodeFactor.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ReasonCodeFactor} returns this
 */
proto.proto.ReasonCodeFactor.prototype.clearAlphabetDescending = function() {
  return jspb.Message.setOneofField(this, 3, proto.proto.ReasonCodeFactor.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeFactor.prototype.hasAlphabetDescending = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool numeric_ascending = 4;
 * @return {boolean}
 */
proto.proto.ReasonCodeFactor.prototype.getNumericAscending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCodeFactor} returns this
 */
proto.proto.ReasonCodeFactor.prototype.setNumericAscending = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.proto.ReasonCodeFactor.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ReasonCodeFactor} returns this
 */
proto.proto.ReasonCodeFactor.prototype.clearNumericAscending = function() {
  return jspb.Message.setOneofField(this, 4, proto.proto.ReasonCodeFactor.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeFactor.prototype.hasNumericAscending = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool numeric_descending = 5;
 * @return {boolean}
 */
proto.proto.ReasonCodeFactor.prototype.getNumericDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCodeFactor} returns this
 */
proto.proto.ReasonCodeFactor.prototype.setNumericDescending = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.proto.ReasonCodeFactor.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ReasonCodeFactor} returns this
 */
proto.proto.ReasonCodeFactor.prototype.clearNumericDescending = function() {
  return jspb.Message.setOneofField(this, 5, proto.proto.ReasonCodeFactor.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeFactor.prototype.hasNumericDescending = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.proto.ReasonCode.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.proto.ReasonCode.SingleSelectionFilterCase = {
  SINGLE_SELECTION_FILTER_NOT_SET: 0,
  DEFAULT: 1,
  ALPHABET_ASCENDING: 2,
  ALPHABET_DESCENDING: 3,
  NUMERIC_ASCENDING: 4,
  NUMERIC_DESCENDING: 5
};

/**
 * @return {proto.proto.ReasonCode.SingleSelectionFilterCase}
 */
proto.proto.ReasonCode.prototype.getSingleSelectionFilterCase = function() {
  return /** @type {proto.proto.ReasonCode.SingleSelectionFilterCase} */(jspb.Message.computeOneofCase(this, proto.proto.ReasonCode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCode.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_default: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    alphabetAscending: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    alphabetDescending: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    numericAscending: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    numericDescending: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCode}
 */
proto.proto.ReasonCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCode;
  return proto.proto.ReasonCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCode}
 */
proto.proto.ReasonCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefault(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlphabetAscending(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlphabetDescending(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNumericAscending(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNumericDescending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional bool default = 1;
 * @return {boolean}
 */
proto.proto.ReasonCode.prototype.getDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCode} returns this
 */
proto.proto.ReasonCode.prototype.setDefault = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.proto.ReasonCode.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ReasonCode} returns this
 */
proto.proto.ReasonCode.prototype.clearDefault = function() {
  return jspb.Message.setOneofField(this, 1, proto.proto.ReasonCode.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCode.prototype.hasDefault = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool alphabet_ascending = 2;
 * @return {boolean}
 */
proto.proto.ReasonCode.prototype.getAlphabetAscending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCode} returns this
 */
proto.proto.ReasonCode.prototype.setAlphabetAscending = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.proto.ReasonCode.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ReasonCode} returns this
 */
proto.proto.ReasonCode.prototype.clearAlphabetAscending = function() {
  return jspb.Message.setOneofField(this, 2, proto.proto.ReasonCode.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCode.prototype.hasAlphabetAscending = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool alphabet_descending = 3;
 * @return {boolean}
 */
proto.proto.ReasonCode.prototype.getAlphabetDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCode} returns this
 */
proto.proto.ReasonCode.prototype.setAlphabetDescending = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.proto.ReasonCode.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ReasonCode} returns this
 */
proto.proto.ReasonCode.prototype.clearAlphabetDescending = function() {
  return jspb.Message.setOneofField(this, 3, proto.proto.ReasonCode.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCode.prototype.hasAlphabetDescending = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool numeric_ascending = 4;
 * @return {boolean}
 */
proto.proto.ReasonCode.prototype.getNumericAscending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCode} returns this
 */
proto.proto.ReasonCode.prototype.setNumericAscending = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.proto.ReasonCode.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ReasonCode} returns this
 */
proto.proto.ReasonCode.prototype.clearNumericAscending = function() {
  return jspb.Message.setOneofField(this, 4, proto.proto.ReasonCode.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCode.prototype.hasNumericAscending = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool numeric_descending = 5;
 * @return {boolean}
 */
proto.proto.ReasonCode.prototype.getNumericDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCode} returns this
 */
proto.proto.ReasonCode.prototype.setNumericDescending = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.proto.ReasonCode.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ReasonCode} returns this
 */
proto.proto.ReasonCode.prototype.clearNumericDescending = function() {
  return jspb.Message.setOneofField(this, 5, proto.proto.ReasonCode.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCode.prototype.hasNumericDescending = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.proto.ViewType.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.proto.ViewType.ViewfilterCase = {
  VIEWFILTER_NOT_SET: 0,
  GRID_VIEW: 1,
  LIST_VIEW: 2,
  EXPANDED_VIEW: 3
};

/**
 * @return {proto.proto.ViewType.ViewfilterCase}
 */
proto.proto.ViewType.prototype.getViewfilterCase = function() {
  return /** @type {proto.proto.ViewType.ViewfilterCase} */(jspb.Message.computeOneofCase(this, proto.proto.ViewType.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ViewType.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ViewType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ViewType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ViewType.toObject = function(includeInstance, msg) {
  var f, obj = {
    gridView: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    listView: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    expandedView: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ViewType}
 */
proto.proto.ViewType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ViewType;
  return proto.proto.ViewType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ViewType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ViewType}
 */
proto.proto.ViewType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGridView(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setListView(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpandedView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ViewType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ViewType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ViewType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ViewType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool grid_view = 1;
 * @return {boolean}
 */
proto.proto.ViewType.prototype.getGridView = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ViewType} returns this
 */
proto.proto.ViewType.prototype.setGridView = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.proto.ViewType.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ViewType} returns this
 */
proto.proto.ViewType.prototype.clearGridView = function() {
  return jspb.Message.setOneofField(this, 1, proto.proto.ViewType.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ViewType.prototype.hasGridView = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool list_view = 2;
 * @return {boolean}
 */
proto.proto.ViewType.prototype.getListView = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ViewType} returns this
 */
proto.proto.ViewType.prototype.setListView = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.proto.ViewType.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ViewType} returns this
 */
proto.proto.ViewType.prototype.clearListView = function() {
  return jspb.Message.setOneofField(this, 2, proto.proto.ViewType.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ViewType.prototype.hasListView = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool expanded_view = 3;
 * @return {boolean}
 */
proto.proto.ViewType.prototype.getExpandedView = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ViewType} returns this
 */
proto.proto.ViewType.prototype.setExpandedView = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.proto.ViewType.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.ViewType} returns this
 */
proto.proto.ViewType.prototype.clearExpandedView = function() {
  return jspb.Message.setOneofField(this, 3, proto.proto.ViewType.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ViewType.prototype.hasExpandedView = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.proto);
