// source: model/AlarmDetails.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_Enum_pb = require('../common/Enum_pb.js');
goog.object.extend(proto, common_Enum_pb);
goog.exportSymbol('proto.upl_sandbach.ActiveAlarmModel', null, global);
goog.exportSymbol('proto.upl_sandbach.ActiveAlarmOverview', null, global);
goog.exportSymbol('proto.upl_sandbach.AlarmPieChart', null, global);
goog.exportSymbol('proto.upl_sandbach.AlarmStackedBarChart', null, global);
goog.exportSymbol('proto.upl_sandbach.BubbleHeatMap', null, global);
goog.exportSymbol('proto.upl_sandbach.SeverityCounts', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.ActiveAlarmOverview = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.ActiveAlarmOverview.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.ActiveAlarmOverview, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.ActiveAlarmOverview.displayName = 'proto.upl_sandbach.ActiveAlarmOverview';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.BubbleHeatMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.BubbleHeatMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.BubbleHeatMap.displayName = 'proto.upl_sandbach.BubbleHeatMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.ActiveAlarmModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.ActiveAlarmModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.ActiveAlarmModel.displayName = 'proto.upl_sandbach.ActiveAlarmModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AlarmPieChart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.AlarmPieChart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AlarmPieChart.displayName = 'proto.upl_sandbach.AlarmPieChart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AlarmStackedBarChart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.AlarmStackedBarChart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AlarmStackedBarChart.displayName = 'proto.upl_sandbach.AlarmStackedBarChart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.SeverityCounts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.SeverityCounts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.SeverityCounts.displayName = 'proto.upl_sandbach.SeverityCounts';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.ActiveAlarmOverview.repeatedFields_ = [10,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.ActiveAlarmOverview.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.ActiveAlarmOverview.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.ActiveAlarmOverview} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ActiveAlarmOverview.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeAlarmModelList: jspb.Message.toObjectList(msg.getActiveAlarmModelList(),
    proto.upl_sandbach.ActiveAlarmModel.toObject, includeInstance),
    bubbleHeatMapList: jspb.Message.toObjectList(msg.getBubbleHeatMapList(),
    proto.upl_sandbach.BubbleHeatMap.toObject, includeInstance),
    severityCounts: (f = msg.getSeverityCounts()) && proto.upl_sandbach.SeverityCounts.toObject(includeInstance, f),
    totalCount: jspb.Message.getFieldWithDefault(msg, 70, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.ActiveAlarmOverview}
 */
proto.upl_sandbach.ActiveAlarmOverview.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.ActiveAlarmOverview;
  return proto.upl_sandbach.ActiveAlarmOverview.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.ActiveAlarmOverview} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.ActiveAlarmOverview}
 */
proto.upl_sandbach.ActiveAlarmOverview.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new proto.upl_sandbach.ActiveAlarmModel;
      reader.readMessage(value,proto.upl_sandbach.ActiveAlarmModel.deserializeBinaryFromReader);
      msg.addActiveAlarmModel(value);
      break;
    case 20:
      var value = new proto.upl_sandbach.BubbleHeatMap;
      reader.readMessage(value,proto.upl_sandbach.BubbleHeatMap.deserializeBinaryFromReader);
      msg.addBubbleHeatMap(value);
      break;
    case 30:
      var value = new proto.upl_sandbach.SeverityCounts;
      reader.readMessage(value,proto.upl_sandbach.SeverityCounts.deserializeBinaryFromReader);
      msg.setSeverityCounts(value);
      break;
    case 70:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.ActiveAlarmOverview.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.ActiveAlarmOverview.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.ActiveAlarmOverview} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ActiveAlarmOverview.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveAlarmModelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.upl_sandbach.ActiveAlarmModel.serializeBinaryToWriter
    );
  }
  f = message.getBubbleHeatMapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.upl_sandbach.BubbleHeatMap.serializeBinaryToWriter
    );
  }
  f = message.getSeverityCounts();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.upl_sandbach.SeverityCounts.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      70,
      f
    );
  }
};


/**
 * repeated ActiveAlarmModel active_alarm_model = 10;
 * @return {!Array<!proto.upl_sandbach.ActiveAlarmModel>}
 */
proto.upl_sandbach.ActiveAlarmOverview.prototype.getActiveAlarmModelList = function() {
  return /** @type{!Array<!proto.upl_sandbach.ActiveAlarmModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.upl_sandbach.ActiveAlarmModel, 10));
};


/**
 * @param {!Array<!proto.upl_sandbach.ActiveAlarmModel>} value
 * @return {!proto.upl_sandbach.ActiveAlarmOverview} returns this
*/
proto.upl_sandbach.ActiveAlarmOverview.prototype.setActiveAlarmModelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.upl_sandbach.ActiveAlarmModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.ActiveAlarmModel}
 */
proto.upl_sandbach.ActiveAlarmOverview.prototype.addActiveAlarmModel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.upl_sandbach.ActiveAlarmModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.ActiveAlarmOverview} returns this
 */
proto.upl_sandbach.ActiveAlarmOverview.prototype.clearActiveAlarmModelList = function() {
  return this.setActiveAlarmModelList([]);
};


/**
 * repeated BubbleHeatMap bubble_heat_map = 20;
 * @return {!Array<!proto.upl_sandbach.BubbleHeatMap>}
 */
proto.upl_sandbach.ActiveAlarmOverview.prototype.getBubbleHeatMapList = function() {
  return /** @type{!Array<!proto.upl_sandbach.BubbleHeatMap>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.upl_sandbach.BubbleHeatMap, 20));
};


/**
 * @param {!Array<!proto.upl_sandbach.BubbleHeatMap>} value
 * @return {!proto.upl_sandbach.ActiveAlarmOverview} returns this
*/
proto.upl_sandbach.ActiveAlarmOverview.prototype.setBubbleHeatMapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.upl_sandbach.BubbleHeatMap=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.BubbleHeatMap}
 */
proto.upl_sandbach.ActiveAlarmOverview.prototype.addBubbleHeatMap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.upl_sandbach.BubbleHeatMap, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.ActiveAlarmOverview} returns this
 */
proto.upl_sandbach.ActiveAlarmOverview.prototype.clearBubbleHeatMapList = function() {
  return this.setBubbleHeatMapList([]);
};


/**
 * optional SeverityCounts severity_counts = 30;
 * @return {?proto.upl_sandbach.SeverityCounts}
 */
proto.upl_sandbach.ActiveAlarmOverview.prototype.getSeverityCounts = function() {
  return /** @type{?proto.upl_sandbach.SeverityCounts} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.SeverityCounts, 30));
};


/**
 * @param {?proto.upl_sandbach.SeverityCounts|undefined} value
 * @return {!proto.upl_sandbach.ActiveAlarmOverview} returns this
*/
proto.upl_sandbach.ActiveAlarmOverview.prototype.setSeverityCounts = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.ActiveAlarmOverview} returns this
 */
proto.upl_sandbach.ActiveAlarmOverview.prototype.clearSeverityCounts = function() {
  return this.setSeverityCounts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.ActiveAlarmOverview.prototype.hasSeverityCounts = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional int64 total_count = 70;
 * @return {number}
 */
proto.upl_sandbach.ActiveAlarmOverview.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 70, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.ActiveAlarmOverview} returns this
 */
proto.upl_sandbach.ActiveAlarmOverview.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 70, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.BubbleHeatMap.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.BubbleHeatMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.BubbleHeatMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BubbleHeatMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taguuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    severity: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.BubbleHeatMap}
 */
proto.upl_sandbach.BubbleHeatMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.BubbleHeatMap;
  return proto.upl_sandbach.BubbleHeatMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.BubbleHeatMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.BubbleHeatMap}
 */
proto.upl_sandbach.BubbleHeatMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaguuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {!proto.upl_sandbach.Severity} */ (reader.readEnum());
      msg.setSeverity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.BubbleHeatMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.BubbleHeatMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.BubbleHeatMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BubbleHeatMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTaguuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSeverity();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.upl_sandbach.BubbleHeatMap.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BubbleHeatMap} returns this
 */
proto.upl_sandbach.BubbleHeatMap.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string tagUuid = 2;
 * @return {string}
 */
proto.upl_sandbach.BubbleHeatMap.prototype.getTaguuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BubbleHeatMap} returns this
 */
proto.upl_sandbach.BubbleHeatMap.prototype.setTaguuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.upl_sandbach.BubbleHeatMap.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BubbleHeatMap} returns this
 */
proto.upl_sandbach.BubbleHeatMap.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Severity severity = 4;
 * @return {!proto.upl_sandbach.Severity}
 */
proto.upl_sandbach.BubbleHeatMap.prototype.getSeverity = function() {
  return /** @type {!proto.upl_sandbach.Severity} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.upl_sandbach.Severity} value
 * @return {!proto.upl_sandbach.BubbleHeatMap} returns this
 */
proto.upl_sandbach.BubbleHeatMap.prototype.setSeverity = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.ActiveAlarmModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.ActiveAlarmModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ActiveAlarmModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 10, 0),
    deviceName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    deviceTag: jspb.Message.getFieldWithDefault(msg, 20, ""),
    description: jspb.Message.getFieldWithDefault(msg, 25, ""),
    severity: jspb.Message.getFieldWithDefault(msg, 30, 0),
    nodeName: jspb.Message.getFieldWithDefault(msg, 35, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 40, 0),
    timeStamp: jspb.Message.getFieldWithDefault(msg, 45, 0),
    errorCode: jspb.Message.getFieldWithDefault(msg, 50, ""),
    tagUuid: jspb.Message.getFieldWithDefault(msg, 55, ""),
    alarmState: jspb.Message.getFieldWithDefault(msg, 60, ""),
    tagname: jspb.Message.getFieldWithDefault(msg, 70, ""),
    activeDuration: jspb.Message.getFieldWithDefault(msg, 75, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.ActiveAlarmModel}
 */
proto.upl_sandbach.ActiveAlarmModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.ActiveAlarmModel;
  return proto.upl_sandbach.ActiveAlarmModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.ActiveAlarmModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.ActiveAlarmModel}
 */
proto.upl_sandbach.ActiveAlarmModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setKey(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceName(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceTag(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 30:
      var value = /** @type {!proto.upl_sandbach.Severity} */ (reader.readEnum());
      msg.setSeverity(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeName(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNodeId(value);
      break;
    case 45:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeStamp(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagUuid(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlarmState(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagname(value);
      break;
    case 75:
      var value = /** @type {string} */ (reader.readString());
      msg.setActiveDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.ActiveAlarmModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.ActiveAlarmModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ActiveAlarmModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getDeviceName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDeviceTag();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getSeverity();
  if (f !== 0.0) {
    writer.writeEnum(
      30,
      f
    );
  }
  f = message.getNodeName();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getNodeId();
  if (f !== 0) {
    writer.writeInt64(
      40,
      f
    );
  }
  f = message.getTimeStamp();
  if (f !== 0) {
    writer.writeInt64(
      45,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getTagUuid();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getAlarmState();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getTagname();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getActiveDuration();
  if (f.length > 0) {
    writer.writeString(
      75,
      f
    );
  }
};


/**
 * optional int64 key = 10;
 * @return {number}
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.getKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.ActiveAlarmModel} returns this
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.setKey = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string device_name = 15;
 * @return {string}
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.getDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ActiveAlarmModel} returns this
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.setDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string device_tag = 20;
 * @return {string}
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.getDeviceTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ActiveAlarmModel} returns this
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.setDeviceTag = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string description = 25;
 * @return {string}
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ActiveAlarmModel} returns this
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional Severity severity = 30;
 * @return {!proto.upl_sandbach.Severity}
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.getSeverity = function() {
  return /** @type {!proto.upl_sandbach.Severity} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {!proto.upl_sandbach.Severity} value
 * @return {!proto.upl_sandbach.ActiveAlarmModel} returns this
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.setSeverity = function(value) {
  return jspb.Message.setProto3EnumField(this, 30, value);
};


/**
 * optional string node_name = 35;
 * @return {string}
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.getNodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ActiveAlarmModel} returns this
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.setNodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional int64 node_id = 40;
 * @return {number}
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.getNodeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.ActiveAlarmModel} returns this
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional int64 time_stamp = 45;
 * @return {number}
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.getTimeStamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.ActiveAlarmModel} returns this
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.setTimeStamp = function(value) {
  return jspb.Message.setProto3IntField(this, 45, value);
};


/**
 * optional string error_code = 50;
 * @return {string}
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ActiveAlarmModel} returns this
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string tag_uuid = 55;
 * @return {string}
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.getTagUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ActiveAlarmModel} returns this
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.setTagUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional string alarm_state = 60;
 * @return {string}
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.getAlarmState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ActiveAlarmModel} returns this
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.setAlarmState = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional string tagName = 70;
 * @return {string}
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.getTagname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ActiveAlarmModel} returns this
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.setTagname = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional string active_duration = 75;
 * @return {string}
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.getActiveDuration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 75, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ActiveAlarmModel} returns this
 */
proto.upl_sandbach.ActiveAlarmModel.prototype.setActiveDuration = function(value) {
  return jspb.Message.setProto3StringField(this, 75, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AlarmPieChart.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AlarmPieChart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AlarmPieChart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmPieChart.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 10, ""),
    catValue: jspb.Message.getFieldWithDefault(msg, 20, 0),
    fullCount: jspb.Message.getFieldWithDefault(msg, 30, 0),
    percentage: jspb.Message.getFieldWithDefault(msg, 40, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AlarmPieChart}
 */
proto.upl_sandbach.AlarmPieChart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AlarmPieChart;
  return proto.upl_sandbach.AlarmPieChart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AlarmPieChart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AlarmPieChart}
 */
proto.upl_sandbach.AlarmPieChart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCatValue(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFullCount(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AlarmPieChart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AlarmPieChart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AlarmPieChart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmPieChart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCatValue();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getFullCount();
  if (f !== 0) {
    writer.writeInt64(
      30,
      f
    );
  }
  f = message.getPercentage();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
};


/**
 * optional string category = 10;
 * @return {string}
 */
proto.upl_sandbach.AlarmPieChart.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.AlarmPieChart} returns this
 */
proto.upl_sandbach.AlarmPieChart.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 cat_value = 20;
 * @return {number}
 */
proto.upl_sandbach.AlarmPieChart.prototype.getCatValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.AlarmPieChart} returns this
 */
proto.upl_sandbach.AlarmPieChart.prototype.setCatValue = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int64 full_count = 30;
 * @return {number}
 */
proto.upl_sandbach.AlarmPieChart.prototype.getFullCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.AlarmPieChart} returns this
 */
proto.upl_sandbach.AlarmPieChart.prototype.setFullCount = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional string percentage = 40;
 * @return {string}
 */
proto.upl_sandbach.AlarmPieChart.prototype.getPercentage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.AlarmPieChart} returns this
 */
proto.upl_sandbach.AlarmPieChart.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AlarmStackedBarChart.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AlarmStackedBarChart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AlarmStackedBarChart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmStackedBarChart.toObject = function(includeInstance, msg) {
  var f, obj = {
    equipmentName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    severityCounts: (f = msg.getSeverityCounts()) && proto.upl_sandbach.SeverityCounts.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AlarmStackedBarChart}
 */
proto.upl_sandbach.AlarmStackedBarChart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AlarmStackedBarChart;
  return proto.upl_sandbach.AlarmStackedBarChart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AlarmStackedBarChart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AlarmStackedBarChart}
 */
proto.upl_sandbach.AlarmStackedBarChart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipmentName(value);
      break;
    case 20:
      var value = new proto.upl_sandbach.SeverityCounts;
      reader.readMessage(value,proto.upl_sandbach.SeverityCounts.deserializeBinaryFromReader);
      msg.setSeverityCounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AlarmStackedBarChart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AlarmStackedBarChart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AlarmStackedBarChart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmStackedBarChart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEquipmentName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSeverityCounts();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.upl_sandbach.SeverityCounts.serializeBinaryToWriter
    );
  }
};


/**
 * optional string equipment_name = 10;
 * @return {string}
 */
proto.upl_sandbach.AlarmStackedBarChart.prototype.getEquipmentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.AlarmStackedBarChart} returns this
 */
proto.upl_sandbach.AlarmStackedBarChart.prototype.setEquipmentName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional SeverityCounts severity_counts = 20;
 * @return {?proto.upl_sandbach.SeverityCounts}
 */
proto.upl_sandbach.AlarmStackedBarChart.prototype.getSeverityCounts = function() {
  return /** @type{?proto.upl_sandbach.SeverityCounts} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.SeverityCounts, 20));
};


/**
 * @param {?proto.upl_sandbach.SeverityCounts|undefined} value
 * @return {!proto.upl_sandbach.AlarmStackedBarChart} returns this
*/
proto.upl_sandbach.AlarmStackedBarChart.prototype.setSeverityCounts = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmStackedBarChart} returns this
 */
proto.upl_sandbach.AlarmStackedBarChart.prototype.clearSeverityCounts = function() {
  return this.setSeverityCounts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmStackedBarChart.prototype.hasSeverityCounts = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.SeverityCounts.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.SeverityCounts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.SeverityCounts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.SeverityCounts.toObject = function(includeInstance, msg) {
  var f, obj = {
    warningCount: jspb.Message.getFieldWithDefault(msg, 15, 0),
    minorCount: jspb.Message.getFieldWithDefault(msg, 20, 0),
    majorCount: jspb.Message.getFieldWithDefault(msg, 25, 0),
    criticalCount: jspb.Message.getFieldWithDefault(msg, 30, 0),
    eventCount: jspb.Message.getFieldWithDefault(msg, 35, 0),
    totalCount: jspb.Message.getFieldWithDefault(msg, 40, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.SeverityCounts}
 */
proto.upl_sandbach.SeverityCounts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.SeverityCounts;
  return proto.upl_sandbach.SeverityCounts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.SeverityCounts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.SeverityCounts}
 */
proto.upl_sandbach.SeverityCounts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWarningCount(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinorCount(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMajorCount(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCriticalCount(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventCount(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.SeverityCounts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.SeverityCounts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.SeverityCounts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.SeverityCounts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWarningCount();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getMinorCount();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getMajorCount();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
  f = message.getCriticalCount();
  if (f !== 0) {
    writer.writeInt64(
      30,
      f
    );
  }
  f = message.getEventCount();
  if (f !== 0) {
    writer.writeInt64(
      35,
      f
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      40,
      f
    );
  }
};


/**
 * optional int64 warning_count = 15;
 * @return {number}
 */
proto.upl_sandbach.SeverityCounts.prototype.getWarningCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.SeverityCounts} returns this
 */
proto.upl_sandbach.SeverityCounts.prototype.setWarningCount = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 minor_count = 20;
 * @return {number}
 */
proto.upl_sandbach.SeverityCounts.prototype.getMinorCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.SeverityCounts} returns this
 */
proto.upl_sandbach.SeverityCounts.prototype.setMinorCount = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int64 major_count = 25;
 * @return {number}
 */
proto.upl_sandbach.SeverityCounts.prototype.getMajorCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.SeverityCounts} returns this
 */
proto.upl_sandbach.SeverityCounts.prototype.setMajorCount = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional int64 critical_count = 30;
 * @return {number}
 */
proto.upl_sandbach.SeverityCounts.prototype.getCriticalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.SeverityCounts} returns this
 */
proto.upl_sandbach.SeverityCounts.prototype.setCriticalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional int64 event_count = 35;
 * @return {number}
 */
proto.upl_sandbach.SeverityCounts.prototype.getEventCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.SeverityCounts} returns this
 */
proto.upl_sandbach.SeverityCounts.prototype.setEventCount = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional int64 total_count = 40;
 * @return {number}
 */
proto.upl_sandbach.SeverityCounts.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.SeverityCounts} returns this
 */
proto.upl_sandbach.SeverityCounts.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


goog.object.extend(exports, proto.upl_sandbach);
