import "./index.scss";
import "antd/dist/antd.css";
import { Button } from "antd";
import { Text } from "components/Text";
import {  EditOutlined } from "@ant-design/icons";

import { ReactComponent as AlarmIcon } from "assets/icons/alarmIcon.svg";
import { ReactComponent as CriticalAlarmIcon } from "assets/icons/criticalAlarmIcon.svg";
import { ReactComponent as MajorAlarmIcon } from "assets/icons/majorAlarmIcon.svg";
import { ReactComponent as MinorAlarmIcon } from "assets/icons/minorAlarmIcon.svg";
import { ReactComponent as WarningAlarmIcon } from "assets/icons/warningAlarmIcon.svg";
import { ReactComponent as ApplyIcon } from "assets/icons/applyIcon.svg";
import { ReactComponent as ResetIcon } from "assets/icons/resetIcon.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/downloadIcon.svg";
import {
  ImportOutlined,
  DeleteOutlined,
  PlusOutlined,
  FilterOutlined,
  StopOutlined,
  UploadOutlined
} from "@ant-design/icons";

export const CustomButton = (props: any) => {
  const { type, count, onClick } = props;
  let icon: any = AlarmIcon;
  switch (type) {
    case "Total":
      icon = <AlarmIcon />;
      break;
    case "Event":
      icon = <WarningAlarmIcon />;
      break;
    case "Critical":
      icon = <CriticalAlarmIcon />;
      break;
    case "Major":
      icon = <MajorAlarmIcon />;
      break;
    case "Minor":
      icon = <MinorAlarmIcon />;
      break;
    case "Warning":
      icon = <WarningAlarmIcon />;
      break;
    case "Apply":
      icon = <ApplyIcon />;
      break;
    case "Reset":
      icon = <ResetIcon />;
      break;
    case "Download":
      icon = <DownloadIcon />;
      break;
    case "Upload":
      icon = <UploadOutlined />;
      break;
    case "Import":
      icon = <ImportOutlined />;
      break;
    case "Delete":
      icon = <DeleteOutlined />;
      break;
    case "Add":
      icon = <PlusOutlined />;
      break;
    case "Add Reason Code":
      icon = <PlusOutlined />;
      break;
      case "Edit Reason Code":
        icon = <EditOutlined />;
        break;
    case "Save Template":
      icon = <DownloadIcon />;
      break;
    case "Filter":
      icon = <FilterOutlined />;
      break;
    case "Assign":
      icon = <DownloadIcon />;
      break;
    case "End Downtime":
      icon = <StopOutlined />;
      break;
    case "Assign Reason Code":
      icon = "";
      break;
  }

  const customButtonClick = () => {
    onClick();
  }

  return (
    <>
      <Button
        type="default"
        className={type.toLowerCase()}
        icon={icon}
        onClick={customButtonClick}
      >
        <Text>{type}</Text>
        {count && <Text>{count}</Text>}
      </Button>
    </>
  );
};
