// source: objects/Historian.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_API_pb = require('../common/API_pb.js');
goog.object.extend(proto, common_API_pb);
goog.exportSymbol('proto.Reading', null, global);
goog.exportSymbol('proto.TagTimeseriesInfo', null, global);
goog.exportSymbol('proto.TagTimeseriesValueInfo', null, global);
goog.exportSymbol('proto.TimeSeries', null, global);
goog.exportSymbol('proto.TimeSeriesStats', null, global);
goog.exportSymbol('proto.TimestampSet', null, global);
goog.exportSymbol('proto.TimestampValueSet', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Reading = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Reading, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Reading.displayName = 'proto.Reading';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TimeSeries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TimeSeries.repeatedFields_, null);
};
goog.inherits(proto.TimeSeries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TimeSeries.displayName = 'proto.TimeSeries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TimeSeriesStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TimeSeriesStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TimeSeriesStats.displayName = 'proto.TimeSeriesStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TimestampSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TimestampSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TimestampSet.displayName = 'proto.TimestampSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TagTimeseriesInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TagTimeseriesInfo.repeatedFields_, null);
};
goog.inherits(proto.TagTimeseriesInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TagTimeseriesInfo.displayName = 'proto.TagTimeseriesInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TimestampValueSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TimestampValueSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TimestampValueSet.displayName = 'proto.TimestampValueSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TagTimeseriesValueInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TagTimeseriesValueInfo.repeatedFields_, null);
};
goog.inherits(proto.TagTimeseriesValueInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TagTimeseriesValueInfo.displayName = 'proto.TagTimeseriesValueInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Reading.prototype.toObject = function(opt_includeInstance) {
  return proto.Reading.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Reading} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Reading.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Reading}
 */
proto.Reading.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Reading;
  return proto.Reading.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Reading} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Reading}
 */
proto.Reading.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Reading.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Reading.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Reading} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Reading.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.Reading.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Reading} returns this
 */
proto.Reading.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.Reading.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Reading} returns this
 */
proto.Reading.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TimeSeries.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TimeSeries.prototype.toObject = function(opt_includeInstance) {
  return proto.TimeSeries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TimeSeries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeSeries.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    readingsList: jspb.Message.toObjectList(msg.getReadingsList(),
    proto.Reading.toObject, includeInstance),
    totalCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 4, ""),
    minRange: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    maxRange: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TimeSeries}
 */
proto.TimeSeries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TimeSeries;
  return proto.TimeSeries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TimeSeries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TimeSeries}
 */
proto.TimeSeries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = new proto.Reading;
      reader.readMessage(value,proto.Reading.deserializeBinaryFromReader);
      msg.addReadings(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinRange(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TimeSeries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TimeSeries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TimeSeries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeSeries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReadingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Reading.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMinRange();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMaxRange();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.TimeSeries.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TimeSeries} returns this
 */
proto.TimeSeries.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Reading readings = 2;
 * @return {!Array<!proto.Reading>}
 */
proto.TimeSeries.prototype.getReadingsList = function() {
  return /** @type{!Array<!proto.Reading>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Reading, 2));
};


/**
 * @param {!Array<!proto.Reading>} value
 * @return {!proto.TimeSeries} returns this
*/
proto.TimeSeries.prototype.setReadingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Reading=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Reading}
 */
proto.TimeSeries.prototype.addReadings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Reading, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TimeSeries} returns this
 */
proto.TimeSeries.prototype.clearReadingsList = function() {
  return this.setReadingsList([]);
};


/**
 * optional int64 total_count = 3;
 * @return {number}
 */
proto.TimeSeries.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeSeries} returns this
 */
proto.TimeSeries.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string unit = 4;
 * @return {string}
 */
proto.TimeSeries.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TimeSeries} returns this
 */
proto.TimeSeries.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double min_range = 5;
 * @return {number}
 */
proto.TimeSeries.prototype.getMinRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TimeSeries} returns this
 */
proto.TimeSeries.prototype.setMinRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double max_range = 6;
 * @return {number}
 */
proto.TimeSeries.prototype.getMaxRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TimeSeries} returns this
 */
proto.TimeSeries.prototype.setMaxRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TimeSeriesStats.prototype.toObject = function(opt_includeInstance) {
  return proto.TimeSeriesStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TimeSeriesStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeSeriesStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagUuid: (f = msg.getTagUuid()) && common_API_pb.Uuid.toObject(includeInstance, f),
    min: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    max: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    stdDev: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    avg: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    minValueTimestamp: jspb.Message.getFieldWithDefault(msg, 6, 0),
    maxValueTimestamp: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TimeSeriesStats}
 */
proto.TimeSeriesStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TimeSeriesStats;
  return proto.TimeSeriesStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TimeSeriesStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TimeSeriesStats}
 */
proto.TimeSeriesStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.Uuid;
      reader.readMessage(value,common_API_pb.Uuid.deserializeBinaryFromReader);
      msg.setTagUuid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMax(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStdDev(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvg(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinValueTimestamp(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxValueTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TimeSeriesStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TimeSeriesStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TimeSeriesStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeSeriesStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagUuid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getMin();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMax();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getStdDev();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getAvg();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMinValueTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getMaxValueTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional Uuid tag_uuid = 1;
 * @return {?proto.Uuid}
 */
proto.TimeSeriesStats.prototype.getTagUuid = function() {
  return /** @type{?proto.Uuid} */ (
    jspb.Message.getWrapperField(this, common_API_pb.Uuid, 1));
};


/**
 * @param {?proto.Uuid|undefined} value
 * @return {!proto.TimeSeriesStats} returns this
*/
proto.TimeSeriesStats.prototype.setTagUuid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TimeSeriesStats} returns this
 */
proto.TimeSeriesStats.prototype.clearTagUuid = function() {
  return this.setTagUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TimeSeriesStats.prototype.hasTagUuid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double min = 2;
 * @return {number}
 */
proto.TimeSeriesStats.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TimeSeriesStats} returns this
 */
proto.TimeSeriesStats.prototype.setMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double max = 3;
 * @return {number}
 */
proto.TimeSeriesStats.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TimeSeriesStats} returns this
 */
proto.TimeSeriesStats.prototype.setMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double std_dev = 4;
 * @return {number}
 */
proto.TimeSeriesStats.prototype.getStdDev = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TimeSeriesStats} returns this
 */
proto.TimeSeriesStats.prototype.setStdDev = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double avg = 5;
 * @return {number}
 */
proto.TimeSeriesStats.prototype.getAvg = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TimeSeriesStats} returns this
 */
proto.TimeSeriesStats.prototype.setAvg = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int64 min_value_timestamp = 6;
 * @return {number}
 */
proto.TimeSeriesStats.prototype.getMinValueTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeSeriesStats} returns this
 */
proto.TimeSeriesStats.prototype.setMinValueTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 max_value_timestamp = 7;
 * @return {number}
 */
proto.TimeSeriesStats.prototype.getMaxValueTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeSeriesStats} returns this
 */
proto.TimeSeriesStats.prototype.setMaxValueTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TimestampSet.prototype.toObject = function(opt_includeInstance) {
  return proto.TimestampSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TimestampSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimestampSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TimestampSet}
 */
proto.TimestampSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TimestampSet;
  return proto.TimestampSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TimestampSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TimestampSet}
 */
proto.TimestampSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TimestampSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TimestampSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TimestampSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimestampSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.TimestampSet.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimestampSet} returns this
 */
proto.TimestampSet.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.TimestampSet.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimestampSet} returns this
 */
proto.TimestampSet.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TagTimeseriesInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TagTimeseriesInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.TagTimeseriesInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TagTimeseriesInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TagTimeseriesInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagUuid: (f = msg.getTagUuid()) && common_API_pb.Uuid.toObject(includeInstance, f),
    timestampSetList: jspb.Message.toObjectList(msg.getTimestampSetList(),
    proto.TimestampSet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TagTimeseriesInfo}
 */
proto.TagTimeseriesInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TagTimeseriesInfo;
  return proto.TagTimeseriesInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TagTimeseriesInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TagTimeseriesInfo}
 */
proto.TagTimeseriesInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.Uuid;
      reader.readMessage(value,common_API_pb.Uuid.deserializeBinaryFromReader);
      msg.setTagUuid(value);
      break;
    case 2:
      var value = new proto.TimestampSet;
      reader.readMessage(value,proto.TimestampSet.deserializeBinaryFromReader);
      msg.addTimestampSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TagTimeseriesInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TagTimeseriesInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TagTimeseriesInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TagTimeseriesInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagUuid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getTimestampSetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.TimestampSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional Uuid tag_uuid = 1;
 * @return {?proto.Uuid}
 */
proto.TagTimeseriesInfo.prototype.getTagUuid = function() {
  return /** @type{?proto.Uuid} */ (
    jspb.Message.getWrapperField(this, common_API_pb.Uuid, 1));
};


/**
 * @param {?proto.Uuid|undefined} value
 * @return {!proto.TagTimeseriesInfo} returns this
*/
proto.TagTimeseriesInfo.prototype.setTagUuid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TagTimeseriesInfo} returns this
 */
proto.TagTimeseriesInfo.prototype.clearTagUuid = function() {
  return this.setTagUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TagTimeseriesInfo.prototype.hasTagUuid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TimestampSet timestamp_set = 2;
 * @return {!Array<!proto.TimestampSet>}
 */
proto.TagTimeseriesInfo.prototype.getTimestampSetList = function() {
  return /** @type{!Array<!proto.TimestampSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TimestampSet, 2));
};


/**
 * @param {!Array<!proto.TimestampSet>} value
 * @return {!proto.TagTimeseriesInfo} returns this
*/
proto.TagTimeseriesInfo.prototype.setTimestampSetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.TimestampSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TimestampSet}
 */
proto.TagTimeseriesInfo.prototype.addTimestampSet = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.TimestampSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TagTimeseriesInfo} returns this
 */
proto.TagTimeseriesInfo.prototype.clearTimestampSetList = function() {
  return this.setTimestampSetList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TimestampValueSet.prototype.toObject = function(opt_includeInstance) {
  return proto.TimestampValueSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TimestampValueSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimestampValueSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampSet: (f = msg.getTimestampSet()) && proto.TimestampSet.toObject(includeInstance, f),
    firstValue: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastValue: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TimestampValueSet}
 */
proto.TimestampValueSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TimestampValueSet;
  return proto.TimestampValueSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TimestampValueSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TimestampValueSet}
 */
proto.TimestampValueSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TimestampSet;
      reader.readMessage(value,proto.TimestampSet.deserializeBinaryFromReader);
      msg.setTimestampSet(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFirstValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TimestampValueSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TimestampValueSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TimestampValueSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimestampValueSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampSet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.TimestampSet.serializeBinaryToWriter
    );
  }
  f = message.getFirstValue();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLastValue();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional TimestampSet timestamp_set = 1;
 * @return {?proto.TimestampSet}
 */
proto.TimestampValueSet.prototype.getTimestampSet = function() {
  return /** @type{?proto.TimestampSet} */ (
    jspb.Message.getWrapperField(this, proto.TimestampSet, 1));
};


/**
 * @param {?proto.TimestampSet|undefined} value
 * @return {!proto.TimestampValueSet} returns this
*/
proto.TimestampValueSet.prototype.setTimestampSet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TimestampValueSet} returns this
 */
proto.TimestampValueSet.prototype.clearTimestampSet = function() {
  return this.setTimestampSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TimestampValueSet.prototype.hasTimestampSet = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 first_value = 2;
 * @return {number}
 */
proto.TimestampValueSet.prototype.getFirstValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimestampValueSet} returns this
 */
proto.TimestampValueSet.prototype.setFirstValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 last_value = 3;
 * @return {number}
 */
proto.TimestampValueSet.prototype.getLastValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimestampValueSet} returns this
 */
proto.TimestampValueSet.prototype.setLastValue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TagTimeseriesValueInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TagTimeseriesValueInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.TagTimeseriesValueInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TagTimeseriesValueInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TagTimeseriesValueInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagUuid: (f = msg.getTagUuid()) && common_API_pb.Uuid.toObject(includeInstance, f),
    timestampValueSetList: jspb.Message.toObjectList(msg.getTimestampValueSetList(),
    proto.TimestampValueSet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TagTimeseriesValueInfo}
 */
proto.TagTimeseriesValueInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TagTimeseriesValueInfo;
  return proto.TagTimeseriesValueInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TagTimeseriesValueInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TagTimeseriesValueInfo}
 */
proto.TagTimeseriesValueInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.Uuid;
      reader.readMessage(value,common_API_pb.Uuid.deserializeBinaryFromReader);
      msg.setTagUuid(value);
      break;
    case 2:
      var value = new proto.TimestampValueSet;
      reader.readMessage(value,proto.TimestampValueSet.deserializeBinaryFromReader);
      msg.addTimestampValueSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TagTimeseriesValueInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TagTimeseriesValueInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TagTimeseriesValueInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TagTimeseriesValueInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagUuid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getTimestampValueSetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.TimestampValueSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional Uuid tag_uuid = 1;
 * @return {?proto.Uuid}
 */
proto.TagTimeseriesValueInfo.prototype.getTagUuid = function() {
  return /** @type{?proto.Uuid} */ (
    jspb.Message.getWrapperField(this, common_API_pb.Uuid, 1));
};


/**
 * @param {?proto.Uuid|undefined} value
 * @return {!proto.TagTimeseriesValueInfo} returns this
*/
proto.TagTimeseriesValueInfo.prototype.setTagUuid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TagTimeseriesValueInfo} returns this
 */
proto.TagTimeseriesValueInfo.prototype.clearTagUuid = function() {
  return this.setTagUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TagTimeseriesValueInfo.prototype.hasTagUuid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TimestampValueSet timestamp_value_set = 2;
 * @return {!Array<!proto.TimestampValueSet>}
 */
proto.TagTimeseriesValueInfo.prototype.getTimestampValueSetList = function() {
  return /** @type{!Array<!proto.TimestampValueSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TimestampValueSet, 2));
};


/**
 * @param {!Array<!proto.TimestampValueSet>} value
 * @return {!proto.TagTimeseriesValueInfo} returns this
*/
proto.TagTimeseriesValueInfo.prototype.setTimestampValueSetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.TimestampValueSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TimestampValueSet}
 */
proto.TagTimeseriesValueInfo.prototype.addTimestampValueSet = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.TimestampValueSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TagTimeseriesValueInfo} returns this
 */
proto.TagTimeseriesValueInfo.prototype.clearTimestampValueSetList = function() {
  return this.setTimestampValueSetList([]);
};


goog.object.extend(exports, proto);
