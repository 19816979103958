import { all, call, put, takeLatest } from "redux-saga/effects";
import * as types from 'store/actions/actionTypes';
import { getDownTimePareto } from "store/actions/oeeScreen";
import { OeeAnlysisService } from "store/services/oeeAnlysisService";

export function* getOEEAvailabilitySaga({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const oeeAnlysisService = OeeAnlysisService.getInstance();
        const data = yield call(
            [oeeAnlysisService, oeeAnlysisService.getOEEAvailabilityDetails],
            payload
        );

        if (data.length) {
            yield put({
                type: types.GET_OEE_AVAILABILTY_DETAILS_SUCCESS,
                data,
            });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }

    } catch (error) {
        yield put({ type: types.GET_OEE_AVAILABILTY_DETAILS_ERROR, error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getOeeQualitySaga({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const oeeAnlysisService = OeeAnlysisService.getInstance();
        const data = yield call(
            [oeeAnlysisService, oeeAnlysisService.getOEEQualityDetails],
            payload
        );
        if (data.length) {
            yield put({
                type: types.GET_OEE_QUALITY_SUCCESS,
                data,
            });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: types.GET_OEE_QUALITY_ERROR, error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getOEEPerformanceSaga({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });

        const oeeAnlysisService = OeeAnlysisService.getInstance();
        const data = yield call(
            [oeeAnlysisService, oeeAnlysisService.getOEEPerformanceDetails], payload);
        if (data.length) {
            yield put({
                type: types.GET_OEE_PERFORMANCE_SUCCESS, data,
            });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: types.GET_OEE_PERFORMANCE_ERROR, error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}


export function* getOEEMachineSaga({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });

        const oeeAnlysisService = OeeAnlysisService.getInstance();
        const data = yield call(
            [oeeAnlysisService, oeeAnlysisService.getOEEMachineDetails], payload);
        if (data.length) {
            yield put({
                type: types.GET_OEE_MACHINEWISE_SUCCESS, data
            });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: types.GET_OEE_MACHINEWISE_ERROR, error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getOEEByLineSaga({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const oeeAnlysisService = OeeAnlysisService.getInstance();
        const data = yield call(
            [oeeAnlysisService, oeeAnlysisService.getOEEByLineData],
            payload
        );
        if (data.length) {
            yield put({
                type: types.GET_OEE_BY_LINE_SUCCESS,
                data
            });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: types.GET_OEE_BY_LINE_ERROR, error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getPercentageLossAction({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const oeeAnlysisService = OeeAnlysisService.getInstance();
        const data = yield call(
            [oeeAnlysisService, oeeAnlysisService.getPercentageLossService], payload);
        if (data.length) {
            yield put({ type: types.GET_PERCENT_LOSS_SUCCESS, data });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: types.GET_PERCENT_LOSS_ERROR, error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getLossTimeSaga({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const oeeAnlysisService = OeeAnlysisService.getInstance();
        const data = yield call([oeeAnlysisService, oeeAnlysisService.getLossTimeService], payload);
        if (data.length) {
            yield put({ type: types.GET_LOSS_TIME_ACTION_SUCCESS, data });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: types.GET_LOSS_TIME_ACTION_ERROR, error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getOeeSkuSaga({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const oeeAnlysisService = OeeAnlysisService.getInstance();
        const data = yield call([oeeAnlysisService, oeeAnlysisService.getOeeSkuService], payload);

        if (data) {
            yield put({ type: types.GET_OEE_BY_SKU_SUCCESS, data });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: types.GET_OEE_BY_SKU_ERROR, error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getProductionRateSaga({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const oeeAnlysisService = OeeAnlysisService.getInstance();
        const data = yield call(
            [oeeAnlysisService, oeeAnlysisService.getproductionRateService], payload);
        if (data) {
            yield put({
                type: types.GET_PRODUCTION_RATE_SUCCESS, data
            });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: types.GET_PRODUCTION_RATE_ERROR, error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getProductionQualitySaga({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const oeeAnlysisService = OeeAnlysisService.getInstance();
        const data = yield call(
            [oeeAnlysisService, oeeAnlysisService.getProductionQualityService],
            payload
        );
        if (data.length) {
            yield put({
                type: types.GET_PRODUCTION_QUALITY_SUCCESS,
                data
            });

            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: types.GET_PRODUCTION_QUALITY_ERROR, error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getProductiveTimeSaga({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const oeeAnlysisService = OeeAnlysisService.getInstance();
        const data = yield call(
            [oeeAnlysisService, oeeAnlysisService.getProductiveTimeService],
            payload
        );
        if (data.length) {
            yield put({
                type: types.GET_PRODUCTIVE_TIME_SUCCESS,
                data
            });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: types.GET_PRODUCTIVE_TIME_ERROR, error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getDownTimeDetailsSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const oeeAnlysisService = OeeAnlysisService.getInstance();
        const downtimeDetails = yield call([oeeAnlysisService, oeeAnlysisService.getDownTimeDetails], payload);
        if (downtimeDetails) {
            yield put({ type: types.GET_DOWNTIME_DETAILS_SUCCESS, downtimeDetails });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getDownTimeDurationCountSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const oeeAnlysisService = OeeAnlysisService.getInstance();
        const response = yield call([oeeAnlysisService, oeeAnlysisService.getDownTimeDurationCount], payload);
        if (response) {
            yield put({ type: types.GET_DOWNTIME_DURATION_COUNT_SUCCESS, payload: response });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }

    } catch (error) {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getDownTimeParetoSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const oeeAnlysisService = OeeAnlysisService.getInstance();
        const downTimePareto = yield call([oeeAnlysisService, oeeAnlysisService.getDownTimePareto], payload);
        if (downTimePareto) {
            yield put({ type: types.GET_DOWNTIME_PARETO_SUCCESS, payload: downTimePareto });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}



export function* oeeAanlysisSaga() {
    yield all([
        takeLatest(types.GET_OEE_AVAILABILTY_DETAILS, getOEEAvailabilitySaga),
        takeLatest(types.GET_OEE_QUALITY, getOeeQualitySaga),
        takeLatest(types.GET_OEE_PERFORMANCE, getOEEPerformanceSaga),
        takeLatest(types.GET_OEE_MACHINEWISE, getOEEMachineSaga),
        takeLatest(types.GET_OEE_BY_LINE, getOEEByLineSaga),
        takeLatest(types.GET_OEE_PERFORMANCE, getPercentageLossAction),
        takeLatest(types.GET_PERCENT_LOSS, getPercentageLossAction),
        takeLatest(types.GET_DOWNTIME_DETAILS, getDownTimeDetailsSaga),
        takeLatest(types.GET_LOSS_TIME_ACTION, getLossTimeSaga),
        takeLatest(types.GET_OEE_BY_SKU, getOeeSkuSaga),
        takeLatest(types.GET_PRODUCTION_RATE, getProductionRateSaga),
        takeLatest(types.GET_PRODUCTION_QUALITY, getProductionQualitySaga),
        takeLatest(types.GET_PRODUCTIVE_TIME, getProductiveTimeSaga),
        takeLatest(types.GET_DOWNTIME_DURATION_COUNT, getDownTimeDurationCountSaga),
        takeLatest(types.GET_DOWNTIME_PARETO, getDownTimeParetoSaga)
    ]);
}