// @ts-nocheck
import {
    InfoCircleOutlined, ClockCircleOutlined, SettingOutlined
} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Menu,
    Select,
    Radio,
    Table,
    TimePicker,
    Pagination,
    Tooltip,
    message,
} from 'antd';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text/index';
import { CustomButton } from "../../../components/Common/Button";
import moment from 'moment';
import { FC, useEffect, useRef, useState } from 'react';
import { getTagOverviewDataAction, getAllTags, getRawCSVData } from 'store/actions/dataVisualization.actions';

import { useDispatch, useSelector } from 'react-redux';
import './tagsDataTable.scss';
import { ReactComponent as DownArrow } from "assets/icons/down-arrow.svg";
import { RetrievalModal, TagOverview, TagOverviewDataResponse } from 'interface/assetInterface';
import { TAG_DATA_TABLE_FILTER_COL } from 'utils/Constants';
import { getDate, getMonth, getYear } from 'date-fns';
import { TagsFilterPanel } from '../TagsFilterPanel';
import { exportCSVFile } from 'utils/common';
import { setFilterValuesDataVisualization } from 'store/actions/rootAction';
import convertHoursToMinutes from 'utils/convertHoursToMinutes';
import dynamicRangeHandler from 'utils/dynamicRangeHandler';
import momentTimezone from "moment-timezone";
import { RangeFilters } from 'utils/enums';

interface Option {
    value: string;
    label: string;
    children?: Option[];
}

const options: Option[] = [
    {
        value: 'zhejiang',
        label: 'Zhejiang',
        children: [
            {
                value: 'hangzhou',
                label: 'Hangzhou',
                children: [
                    {
                        value: 'xihu',
                        label: 'West Lake',
                    },
                ],
            },
        ],
    },
    {
        value: 'jiangsu',
        label: 'Jiangsu',
        children: [
            {
                value: 'nanjing',
                label: 'Nanjing',
                children: [
                    {
                        value: 'zhonghuamen',
                        label: 'Zhong Hua Men',
                    },
                ],
            },
        ],
    },
];

const columns = [
    {
        title: 'Tag Name',
        dataIndex: 'tagName',
        ellipsis: false,
        width: '118px',
    },
    {
        title: 'Description',
        dataIndex: 'tagDescription',
        ellipsis: true,
        width: '181px',
    },
    {
        title: 'Value',
        dataIndex: 'value',
        ellipsis: true,
        width: '84px',
    },
    {
        title: 'Device Name',
        dataIndex: 'deviceName',
        ellipsis: false,
        width: '225px',
    },
    {
        title: 'Date & Time',
        dataIndex: 'timeStamp',
        ellipsis: true,
        width: '225px',
    },
    {
        title: 'Unit',
        dataIndex: 'unit',
        ellipsis: true,
        width: '104px',
    },
    {
        title: 'Min Range',
        dataIndex: 'minRange',
        ellipsis: true,
        width: '116px',
    },
    {
        title: 'Max Range',
        dataIndex: 'maxRange',
        ellipsis: true,
        width: '107px',
    },
    {
        title: 'Equipment ID',
        dataIndex: 'equipmentId',
        ellipsis: true,
        width: '107px',
    },
];

export const DataVisualizationTable: FC = () => {
    const dispatch = useDispatch();
    const { Option } = Select;
    const [loading, visible] = useState(false);
    const [page, setPage] = useState<number>(1);
    const selectedTagIdsRef = useRef<Array<string>>([]);
    const tableList: TagOverviewDataResponse = useSelector(
        (state: any) => state.dataVisualizationReducer.tableList
    );
    const totalData: Array<TagOverview> = useSelector(
        (state: any) => state.dataVisualizationReducer.totalTagData
    );
    let currentViewDetails = useSelector(
        (state: any) => state.dataVisualizationReducer.currentViewDetails
    );
    const [columnFilter, setColumnFilter] = useState<boolean>(false);
    const [selectionType, setSelectionType] = useState<string>('checkbox');
    const [tagName, setTagName] = useState<string>('');
    const [description, setTagDescription] = useState<string>('');
    const popUpTitle = (
        <>
            <div style={{ display: 'flex' }}>
                <Text type="p16"> Retrieval Mode​</Text>
                <Tooltip title="Retrieval mode defines how your tag will be retrieved for a selected time range">
                    <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                </Tooltip>
            </div>
        </>
    );
    const [deviceName, setDeviceName] = useState<string>('');
  
    const filterValuesDataVisualizationRedux  = useSelector(
        (state: any) => state.root.filterValuesDataVisualization.selectedRange
      );
    
    const [selectedTimeValue, setSelectedTimeValue] = useState<any>(filterValuesDataVisualizationRedux);
    const [resetDropdown, setresetDropdown] = useState<any>();
    const { RangePicker } = DatePicker;
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [selectedTagsForGraph, setSelectedTagsForGraph] = useState<Array<object>>([]);

    const startTimeRedux  = useSelector(
        (state: any) => state.root.filterValuesDataVisualization.startTime
      );
      const endTimeRedux  = useSelector(
       (state: any) => state.root.filterValuesDataVisualization.endTime
     );
 
    const filterFlag = useSelector((state: any) => state.root.filterFlag);
  
    useEffect(() => {
        changeDateRange([moment(startTimeRedux), moment(endTimeRedux)]);
      }, [filterFlag,startTimeRedux,endTimeRedux]);
      useEffect(()=>{
        setTimeout(()=>{changeDateRange([moment(startTimeRedux), moment(endTimeRedux)]);},100)
      },[filterFlag])
      useEffect(() => {
        setSelectedTimeValue(filterValuesDataVisualizationRedux);
      }, [filterValuesDataVisualizationRedux]);
    const [tableData, setTableData] = useState<TagOverview[]>([]);
    const [currentSelectedTag, setCurrentSelectedTag] = useState<any>(null);
    const [retrievalMode, setRetrievalMode] = useState<RetrievalModal>({
        decimalPlace: '2',
        valueInterval: 0,
        mode: 'FULL',
        sortingOrder: 0,
    });
    const [valueDisabled, setValueDisabled] = useState<boolean>(true);

    const [total, setTotal] = useState<number>(0);
    const tablePagination = useRef<any>({
        current: 0,
        defaultPageSize: 50,
        pageSize: 50,
    });
    const [selectedTagId, setSelectedTagId] = useState<string>('');
    const [tableColumns, setTableColumns] = useState(columns);
  
    let modelNameRedux = useSelector(
        (state: any) => state.root.filterValuesDataVisualization.modelName
      );
      let nodeNameRedux = useSelector(
        (state: any) => state.root.filterValuesDataVisualization.nodeName
      );
    const [modelName, setModelName] = useState<string>(modelNameRedux);
    const [nodeName, setNodeName] = useState<string>(nodeNameRedux);
    const [stateData, setStateData] = useState<any>({
        columnArray: [
            'Tag Name',
            'Description',
            'Value',
            'Date & Time',
            'Unit',
            'Min Range',
            'Max Range',
        ],
    });

    useEffect(() => {
        let columns = [
            'Tag Name',
            'Description',
            'Value',
            "Device Name",
            'Date & Time',
            'Unit',
            'Min Range',
            'Max Range',
        ];
        handleSelectChange(columns);
        setdefaultDateTime();
    }, []);


    useEffect(() => {
        if (currentViewDetails && Object.keys(currentViewDetails).length > 0) {
            dispatch(getAllTags({
                status: 0
            }));
            setSelectedKeys(currentViewDetails.selectedRowKeys);

        }
    }, [currentViewDetails]);

    useEffect(() => {
        if (tableList) {
            setTableData(tableList.data);
            setTotal(tableList.total);
        } else {
            setTableData([]);
            setTotal(0);
        }
    }, [tableList]);

    const showModal = () => {
        visible(true);
    };

    const handleDownload = () => {
        message.loading('Download in progress', 1);
        if(currentSelectedTag.length===0){
        message.loading('Please add tags', 1);
        }
        else{
        dispatch(getRawCSVData(
            {   tagIds: currentSelectedTag,
                dateRange: dateRange,
            }
        ))
        }
    }

    const handleOk = () => {
        if (
            retrievalMode.valueInterval &&
            typeof retrievalMode.valueInterval === 'object'
        ) {
            let interval =
                retrievalMode.valueInterval.getHours() * 3600 +
                retrievalMode.valueInterval.getMinutes() * 60 +
                retrievalMode.valueInterval.getSeconds();
            retrievalMode.valueInterval = interval;
        }
        visible(false);
        if (currentSelectedTag) handleSelectedTags(currentSelectedTag);
    };

    const handleCancel = () => {
        visible(false);
    };

    const onDateRangeChange = (dateRange: any) => {
        if (dateRange) {
            changeDateRange([dateRange[0], dateRange[1]]);
        } else {
            changeDateRange(null);
        }
    };

    const handleDateChangeApiCall = (customDateRange?: any) => {
        dispatch(
            getTagOverviewDataAction({
                tagIds: currentSelectedTag,
                dateRange: customDateRange,
                page: page
            })
        );
    };

    const setdefaultDateTime = () => {
        let last24hoursDate = moment(
            new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
        );
        changeDateRange([last24hoursDate, moment(new Date())]);
    };

    const handleSelectChange = (selectedColumns) => {
        let str = selectedColumns.join(',').toLowerCase();
        let filterCol = columns.filter((element) =>
            str.includes(element.title.toLowerCase())
        );
        setTableColumns(filterCol);
        setStateData({ columnArray: selectedColumns });
    };

    const handleRetrivalMode = (value: string) => {
        if (value === 'FULL' || value === 'AGGREGATE') {
            setValueDisabled(true);
        } else {
            setValueDisabled(false);
        }
        setRetrievalMode({ ...retrievalMode, mode: value });
    };

    const handleMenuClick = (value: any) => {
        let columnsNames: string[] = [...stateData.columnArray];
        if (columnsNames.includes(value.key)) {
            columnsNames.splice(columnsNames.indexOf(value.key), 1);
        } else {
            columnsNames.push(value.key);
        }
        if (columnsNames.length === 0) {
            setTableColumns(columns);
        } else {
            let str = columnsNames.join(',').toLowerCase();
            let filterCol = columns.filter((element) =>
                str.includes(element.title.toLowerCase())
            );
            setTableColumns(filterCol);
        }
        setStateData({ columnArray: columnsNames });
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            {TAG_DATA_TABLE_FILTER_COL.map((item) => {
                const { columnArray } = stateData;
                return (
                    <Menu.Item key={item}>
                        <Checkbox checked={columnArray.indexOf(item) >= 0}>{item}</Checkbox>
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    const handleVisibleChange = (flag) => {
        setColumnFilter(flag);
    };

    useEffect(()=>{
        setModelName(modelNameRedux)
        setNodeName(nodeNameRedux)
    },[modelNameRedux,nodeNameRedux])

    //Note: need to check why date range are not updating in tab switching for the first time
    const handleSelectedTags = (tagIds?: any) => {
        setCurrentSelectedTag(tagIds);
        dispatch(setFilterValuesDataVisualization({  key: "startTime",
        value: dateRange[0].valueOf()}));
        dispatch(setFilterValuesDataVisualization({  key: "endTime",
        value: dateRange[1].valueOf()}));
        dispatch(setFilterValuesDataVisualization({  key: "selectedRange",
        value:selectedTimeValue}));
        dispatch(
            getTagOverviewDataAction({
                tagIds,
                dateRange
            })
        );

        dispatch(
            setFilterValuesDataVisualization({
              key: "modelName",
              value: modelName,
            })
        )
        dispatch(
            setFilterValuesDataVisualization({
              key: "nodeName",
              value: nodeName,
            })
        )

    };

    const handleReset = () => {
        handleUserSelectedTime({
            category: "Day",
            value: "86400000"
        });
        setTableData([]);
        setdefaultDateTime();
        setNodeName("");
        setModelName("");
    };

    const [selectedValue,setSelectedValue]=useState("Today")

    const handleUserSelectedTime = (value: any) => {
        setSelectedValue(value);
      };

    const timeData = ["Shift A","Shift B","Shift C","Today", "Yesterday", "This Week", "Last Week", "MTD", "YTD"];
    
  const [shiftStartTime, setShiftStartTime] = useState(startTimeRedux);
  const [shiftEndTime, setShiftEndTime] = useState(endTimeRedux);

  const datePickerChangeHandler = (value) => {
    if (selectedValue === "Shift A") {
        changeDateRange([moment(
            momentTimezone
              .tz(`${value.format("YYYY-MM-DD")} 08:00:00`, "Europe/London")
              .valueOf()
          ),
          moment(
            momentTimezone
              .tz(`${value.format("YYYY-MM-DD")} 15:59:59`, "Europe/London")
              .valueOf()
          )])
          setShiftStartTime(
            momentTimezone
              .tz(`${value.format("YYYY-MM-DD")} 08:00:00`, "Europe/London")
              .valueOf()
          );
          setShiftEndTime(
            momentTimezone
              .tz(`${value.format("YYYY-MM-DD")} 15:59:59`, "Europe/London")
              .valueOf()
          );
    } else if (selectedValue === "Shift B") {

      let startTime= momentTimezone
      .tz(`${value.format("YYYY-MM-DD")} 16:00:00`, "Europe/London")
      .valueOf();
     changeDateRange([moment(startTime),
      moment(
        momentTimezone(
        moment(startTime).add(28799,"seconds"),"Europe/London").valueOf()
      )]);
      setShiftStartTime(
        startTime
      );
      setShiftEndTime(
        momentTimezone(
          moment(startTime).add(28799,"seconds"),"Europe/London").valueOf()
      );
    } else {
      let startTime= momentTimezone
      .tz(`${value.format("YYYY-MM-DD")} 16:00:00`, "Europe/London").valueOf()
        let startTimeWith8HoursAddition= momentTimezone(
        moment(startTime).add(28800,"seconds"),"Europe/London").valueOf()
        let endTimeWith16HoursAddition= momentTimezone(
          moment(startTime).add(57599,"seconds"),"Europe/London").valueOf()
      changeDateRange([ moment(
        startTimeWith8HoursAddition
      ),
      moment(
        endTimeWith16HoursAddition
      )]);
      setShiftStartTime(
        startTimeWith8HoursAddition
      );
      setShiftEndTime(
        endTimeWith16HoursAddition
      );
    }
  };

    const [dateRange, changeDateRange] = useState<any>([
        moment(startTimeRedux),
        moment(endTimeRedux),
    ]);

    const rangeHandler = (value) => {
        changeDateRange([moment(value[0]),
          moment(value[1])])
        }
      
        useEffect(() => {
            dynamicRangeHandler(selectedValue, rangeHandler,shiftStartTime,setShiftStartTime,setShiftEndTime);
          }, [selectedValue]);

    const onPageChange = (page) => {
        setPage(page);
        dispatch(
            getAggregatedDataForTagAction({
                selectedTagsForGraph,
                dateRange,
                pageId: page
            })
        );

    }

    const handleApply = () => {
        if (currentSelectedTag) {
            dispatch(setFilterValuesDataVisualization({  key: "startTime",
            value: dateRange[0].valueOf()}));
            dispatch(setFilterValuesDataVisualization({  key: "endTime",
            value: dateRange[1].valueOf()}));
            dispatch(setFilterValuesDataVisualization({  key: "selectedRange",
            value:selectedTimeValue}));
            dispatch(
                getTagOverviewDataAction({
                    tagIds: currentSelectedTag,
                    dateRange: dateRange,
                    page: page
                })
            );
            dispatch(
                setFilterValuesDataVisualization({
                  key: "modelName",
                  value: modelName,
                })
            )
            dispatch(
                setFilterValuesDataVisualization({
                  key: "nodeName",
                  value: nodeName,
                })
            )
        } else {
            message.info("Please Select Tag!")
        }
    }

    const changeHandler = (page) => {
        setPage(page);
        if (currentSelectedTag) {
            dispatch(
                getTagOverviewDataAction({
                    tagIds: currentSelectedTag,
                    dateRange: dateRange,
                    page: page
                })
            );
        } else {
            message.info("Please Select Tag!")
        }
    };

    return (
        <>
            <div className="dataTable-container">
                <div className="trending-container-buttons">
                    <div className="time-selector">
                        <p>Quick Time Selector : </p>
                        <Select
                            style={{
                                width: "140px",
                                height: "30px",
                            }}
                            className="time-selector-select"
                            showSearch={false}
                            placeholder="Day"
                            value={selectedValue}
                            defaultValue={"Today"}
                            optionFilterProp="children"
                            onChange={handleUserSelectedTime}
                            filterOption={true}
                            suffixIcon={<DownArrow />}
                        >
                            {timeData?.map((item: any) => {
                                return (
                                    <>
                                        <Option value={item}>{item}</Option>
                                    </>
                                );
                            })}
                            ;
                        </Select>
                    {!selectedValue.includes("Shift") ? (

                        <RangePicker
                            suffixIcon={<ClockCircleOutlined />}
                            allowClear={true}
                            value={dateRange}
                            className="date-picker"
                            showTime={{ format: 'HH:mm:ss' }}
                            format={"DD-MM-YYYY HH:mm:ss"}
                            onChange={onDateRangeChange}
                        />
                        )
                    : (
                      <>
                        <DatePicker
                          //Note : As per discussion with Devendra this is done specific to UPL
                        value={selectedValue===RangeFilters.SHIFT_C? moment(shiftStartTime).subtract(1,"days"):moment(shiftStartTime)}
                          onChange={datePickerChangeHandler}
                        />
                        <div className="shift">
                          {moment(shiftStartTime).format("DD-MM-YYYY HH:mm:ss")} to{" "}
                          {moment(shiftEndTime).format("DD-MM-YYYY HH:mm:ss")}
                        </div>
                      </>
                    )}          
                    </div>
                    <div className="dropdownFiltercard-right-container flex-gap">
                        <CustomButton type="Apply" onClick={handleApply} />
                        <CustomButton type="Reset" onClick={handleReset} />
                        <CustomButton onClick={handleDownload} type="Download" />
                    </div>
                </div>
                <div className="dataTable-container-body">
                    <div className="dataTable-filter-col">
                        <TagsFilterPanel
                            rowselection={true}
                            selectedTag={handleSelectedTags}
                            extraRequired={false}
                            selectionType={selectionType}
                            modelName={modelName}
                            nodeName={nodeName}
                            setModelName={setModelName}
                            setNodeName={setNodeName}
                        />
                    </div>
                    <div className="dataTable-table-column">
                        <Table
                            columns={tableColumns}
                            dataSource={tableData}
                            scroll={{ y: 'calc(100vh - 267px)' }}
                            pagination={{
                                total: total,
                                pageSize: 50,
                                // hideOnSinglePage: true,
                                defaultCurrent: 1,
                                position: ['bottomRight'],
                                showSizeChanger: false,
                                onChange: changeHandler,
                            }}
                            locale={{
                                emptyText: (
                                    <div className="empty-table-text">
                                        No data for given selection.
                                    </div>
                                ),
                            }}

                        />
                        {/* <Pagination
                            hideOnSinglePage
                            pageSizeOptions="[30,40,50]"
                            defaultPageSize={50}
                            onChange={onPageChange}
                            total={totalData} /> */}
                    </div>
                </div>
                <Col span={24}>
                    <Modal
                        visible={loading}
                        title={popUpTitle}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={[
                            <Button key="back" onClick={handleCancel}>
                                Cancel
                            </Button>,
                            <Button key="submit" type="primary" onClick={handleOk}>
                                Ok
                            </Button>,
                        ]}
                    >
                        <div className="modal-container">
                            <p>Retrieval Mode</p>
                            <Select
                                placeholder="Full"
                                style={{ width: '100%' }}
                                onChange={handleRetrivalMode}
                            >
                                <Option value="CYCLIC">Cyclic</Option>
                                <Option value="FULL">Full</Option>
                                <Option value="AGGREGATE">Aggregate</Option>
                            </Select>
                            <p>
                                Value at Intervals
                                <Tooltip title="Please enter value in seconds for defining the time spacing of data retrieval. Applicable only if cyclic mode is selected">
                                    <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                                </Tooltip>
                            </p>
                            <TimePicker
                                showNow={false}
                                disabled={valueDisabled}
                                onChange={(value) =>
                                    setRetrievalMode({
                                        ...retrievalMode,
                                        valueInterval: value ? value.toDate() : null,
                                    })
                                }
                            />
                            <p>Decimal Places</p>
                            <Select
                                placeholder="2"
                                style={{ width: '100%' }}
                                onChange={(value: string) =>
                                    setRetrievalMode({
                                        ...retrievalMode,
                                        decimalPlace: value,
                                    })
                                }
                            >
                                <Option value="0">0</Option>
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                            </Select>
                            <p>
                                Order
                                <Tooltip title="Define the order in which you want to retrieve your value on the basis of timestamp - Latest first or oldest first">
                                    <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                                </Tooltip>
                            </p>
                            <Select
                                placeholder="Lastest first"
                                style={{ width: '100%' }}
                                onChange={(value: string) =>
                                    setRetrievalMode({
                                        ...retrievalMode,
                                        sortingOrder: Number(value),
                                    })
                                }
                            >
                                <Option value="0">Lastest first</Option>
                                <Option value="1">Oldest first</Option>
                            </Select>
                        </div>
                    </Modal>
                </Col>
            </div>
        </>
    );
};


