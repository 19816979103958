import { message, Modal } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import './index.scss';
import { useDispatch } from "react-redux";
import { deleteReasonCodeCategory } from "store/actions/reasonCodeManagement";


export const DeleteResonCode = (props: any) => {
    const { visible, onOk, onCancel, category, data, type, code, factor, childActive, factorChild } = props;
    const dispatch = useDispatch();

    const handleDelete = () => {
        switch (type) {
            case 0:
                data?.status || childActive ? message.success('ReasonCode Category is Active Now') : dispatch(deleteReasonCodeCategory({ categoryUuid: category }))
                onOk();
                break;
            case 1:
                data?.status || factorChild ? message.success('ReasonCode Factor is Active Now') : dispatch(deleteReasonCodeCategory({ categoryUuid: category, factorUuid: factor }))
                onOk();
                break;
            case 2:
                data?.status ? message.success('ReasonCode  is Active Now') : dispatch(deleteReasonCodeCategory({ categoryUuid: category, factorUuid: factor, codeUuid: code }))
                onOk();
                break;
            default:
                break;
        }
    }

    return (
        <Modal
            open={visible}
            centered
            onOk={handleDelete}
            onCancel={onCancel}
            width={330}
            closeIcon={<CloseCircleFilled />}
            wrapClassName="create-reason-code-category"
            okText="Ok"
            destroyOnClose={true}
        >
            <div className="title">Confirmation</div>
            <div className="row">
                <label>The changes will be saved. Are you sure you want to continue?</label>
            </div>
        </Modal>
    )
}