import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
const Gantt: React.FC<any> = ({ chartID, data, machineNames }) => {
  useLayoutEffect(() => {
    var root = am5.Root.new(chartID);
    root.dateFormatter.setAll({
      dateFormat: "yyyy-MM-dd HH:mm:ss",
      dateFields: ["valueX", "openValueX"],
    });

    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: am5xy.AxisRendererY.new(root, { inversed: true }),
        tooltip: am5.Tooltip.new(root, {
          themeTags: ["axis"],
          animationDuration: 200,
        }),
      })
    );

    yAxis.data.setAll(machineNames);

    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "minute", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, { strokeOpacity: 0.1 }),
      })
    );

    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        openValueXField: "start",
        valueXField: "end",
        categoryYField: "category",
        sequencedInterpolation: true,
      })
    );
    
    let legend = chart.children.push(am5.Legend.new(root, {
        layout: root.horizontalLayout,
        nameField: "name",
        fillField: "color",
        strokeField: "color",
        y: am5.percent(-2),
        x: am5.percent(80)
      }));
      
      legend.data.setAll([{
        name: "Running",
        color: am5.color(0x95DE64)
      }, {
        name: "Stopped",
        color: am5.color(0xFF7875)
      }]);
  

    series.columns.template.setAll({
      templateField: "columnSettings",
      height:30,
      strokeOpacity: 0,
      tooltipText:
        "{category}: {openValueX.formatDate('yyyy-MM-dd HH:mm:ss')} - {valueX.formatDate('yyyy-MM-dd HH:mm:ss')}\nReason:{reasonCode}",
    });

    series.data.processor = am5.DataProcessor.new(root, {
      dateFields: ["start", "end"],
      dateFormat: "yyyy-MM-dd HH:mm:ss",
    });
    series.data.setAll(data);

    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    );
    series.appear();
    chart.appear(1000, 100);
    root?._logo?.dispose();
    return () => root.dispose();
  }, [chartID, data]);
  return <div style={{ width: "100%", height: "100%" }} id={chartID}></div>;
};
export default Gantt;
