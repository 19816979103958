import { Table, Divider, Tooltip, Spin } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import ChartContainer from "components/ChartContainer";
import LineChart from "components/Charts/LineChart";
import StackedBar from "components/Charts/StackedBar";
import { DropdownFilterComponent } from "components/DropdownFilterComponent";
import RuntimeDataCard from "components/RuntimeDataCard";
import StatisticsDataCard from "components/StatisticsDataCard";
import { RuntimeDataCardProps } from "types";
import "./index.scss";
import { SolidGuage } from "components/Charts/SolidGuage/SolidGuage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getRuntimeAnalysisAssetUtilizationData,
  getRuntimeAnalysisDeashboardGanttData,
  getRuntimeAnalysisDowntimeData,
  getRuntimeAnalysisMachineRuntimeStatusData,
  getRuntimeAnalysisMtbfData,
  getRuntimeAnalysisMttrData,
  getRuntimeAnalysisPlannedStoptimeData,
  getRuntimeAnalysisRuntimeData,
} from "store/actions/runtimeAnalysis";
import moment from "moment";
import { milliToHrs } from "utils/milliToHrs";
import { getUrl } from "config/apiconfig";
import Gantt from "components/Charts/Gantt";

const Dashboard: React.FC = () => {
  const columns = [
    {
      title: "Machine",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Available Time",
      dataIndex: "availableTime",
      key: "availableTime",
      render: (availableTime) => <div>{milliToHrs(availableTime)} Hrs</div>,
    },
    {
      title: "Total Runtime",
      dataIndex: "runtime",
      key: "runtime",
      render: (runtime) => <div>{milliToHrs(runtime)} Hrs</div>,
    },
  ];
  const StoreFilterValues = {
    lineObject: useSelector((state: any) => state.root.filterValues.lineObject),
    nodeId: useSelector((state: any) => state.root.filterValues.nodeId),
    startTime: useSelector((state: any) => state.root.filterValues.startTime),
    endTime: useSelector((state: any) => state.root.filterValues.endTime),
    severity: useSelector((state: any) => state.root.filterValues.severity),
    batches: useSelector((state: any) => state.root.filterValues.batches),
    sku: useSelector((state: any) => state.root.filterValues.sku),
  }
  const selectedNodeIds: any = useSelector(
    (state: any) => state.root.filterValues.nodeId
  );
  const [payload, setPayload] = useState<any>(StoreFilterValues);
  const dispatch = useDispatch();
  const filterFlag = useSelector((state: any) => state.root.filterFlag);
  useEffect(() => {
    if (selectedNodeIds.length > 0) {
      setPayload(payload => ({
        ...payload,
        nodeId: selectedNodeIds
      }));
    }
  }, [selectedNodeIds])
  useEffect(() => {
      setTimeout(() => {
      dispatch(getRuntimeAnalysisDowntimeData(payload));
      dispatch(getRuntimeAnalysisRuntimeData(payload));
      dispatch(getRuntimeAnalysisPlannedStoptimeData(payload));
      dispatch(getRuntimeAnalysisAssetUtilizationData(payload));
      dispatch(getRuntimeAnalysisMachineRuntimeStatusData(payload));
      dispatch(getRuntimeAnalysisMttrData(payload));
      dispatch(getRuntimeAnalysisMtbfData(payload));
      dispatch(getRuntimeAnalysisDeashboardGanttData(payload))
    }, 100);
  }, [payload, filterFlag]);

  const runtimeAnalysisDowntime = useSelector(
    (state: any) => state.runtimeAnalysis.downtime
  );
  const runtimeAnalysisRuntime = useSelector(
    (state: any) => state.runtimeAnalysis.runtime
  );
  const runtimeAnalysisPlannedStoptime = useSelector(
    (state: any) => state.runtimeAnalysis.plannedStoptime
  );
  const runtimeAnalysisAssetUtilization = useSelector(
    (state: any) => state.runtimeAnalysis.assetUtilization
  );
  const runtimeAnalysisMachineRuntimeStatus = useSelector(
    (state: any) => state.runtimeAnalysis.machineRuntimeStatus
  );
  const runtimeAnalysisMttrList = useSelector(
    (state: any) => state.runtimeAnalysis.mttr.runtimeAnalysisMttrList
  );
  const runtimeAnalysisMttrCardData = useSelector(
    (state: any) => state.runtimeAnalysis.mttr.runtimeAnalysisMttrCardData
  );

  const runtimeAnalysisMtbfList = useSelector(
    (state: any) => state.runtimeAnalysis.mtbf.runtimeAnalysisMtbfList
  );
  const runtimeAnalysisMtbfCardData = useSelector(
    (state: any) => state.runtimeAnalysis.mtbf.runtimeAnalysisMtbfCardData
  );
  const runtimeAnalysisGanttData = useSelector(
    (state: any) => state.runtimeAnalysis.gantt
  );
  const runtimeAnalysisGanttLoading = useSelector(
    (state: any) => state.runtimeAnalysis.ganttLoading
  );
  const runtimeAnalysisMachineNames = useSelector(
    (state: any) => state.runtimeAnalysis.machineNames
  );
  const [cardData, setCardData] = useState<any>([]);

  useEffect(() => {
    setCardData([
      {
        heading: "Runtime",
        value: `${milliToHrs(runtimeAnalysisRuntime?.currentValue)} Hrs`,
        color: "#52C41A",
        difference: `${runtimeAnalysisRuntime?.percentage.toFixed(2)}%`,
        previousValue: `${milliToHrs(runtimeAnalysisRuntime?.lastValue)} Hrs`,
      },
      {
        heading: "Downtime",
        value: `${milliToHrs(runtimeAnalysisDowntime?.currentValue)} Hrs`,

        color: "#FA541C",

        difference: `${runtimeAnalysisDowntime?.percentage.toFixed(2)}%`,
        previousValue: `${milliToHrs(runtimeAnalysisDowntime?.lastValue)} Hrs`,
      },
      {
        heading: "Planned Stoptime",
        value: `${milliToHrs(
          runtimeAnalysisPlannedStoptime?.currentValue
        )} Hrs`,

        color: "#FAAD14",

        difference: `${runtimeAnalysisPlannedStoptime?.percentage.toFixed(2)}%`,
        previousValue: `${milliToHrs(
          runtimeAnalysisPlannedStoptime?.lastValue
        )} Hrs`,
      },
    ]);
  }, [
    runtimeAnalysisDowntime,
    runtimeAnalysisRuntime,
    runtimeAnalysisPlannedStoptime,
    runtimeAnalysisAssetUtilization,
    payload
  ]);



  return (
    <div className="wrapper-dashboard">
      <DropdownFilterComponent setPayload={setPayload} />
      <div
        className="alarmevent-dashboard-wrapper-container"
        style={{ paddingLeft: "5px", paddingRight: "8px" }}
      >
        <div className="runtime-card-container">
          {cardData.map((runtimeData: RuntimeDataCardProps) => (
            <RuntimeDataCard {...runtimeData} />
          ))}
        </div>
        <Divider style={{ margin: "8px 0px" }}>Runtime Statistics</Divider>
        <ChartContainer
            id="topDowntimeReasons"
            title="Machine Timeline"
            alignChartTitle="left"
            tooltip=""
            child={
              <div className="machine-timeline">
               {runtimeAnalysisGanttLoading?<Spin/>: <Gantt
                  chartID="machine-timeline-graph"
                  data={runtimeAnalysisGanttData}
                  machineNames={runtimeAnalysisMachineNames}
                  screen="runtimeAnalysis"
                />}
              </div>
            }
            width="150px"
            height="280px"
            showFullScreen={true}
          />
        <div className="runtime-statistics">
          <div className="asset-chart-container">
            <div className="asset-title-container">Asset Utilization</div>
            <div className="table-chart-container">
              <Table
                size="small"
                columns={columns}
                pagination={false}
                dataSource={runtimeAnalysisAssetUtilization}
              />
              <div className="multi-guage-chart-container">
                <SolidGuage
                  data={runtimeAnalysisAssetUtilization}
                  chartID={"multi-guage-asset-utilization"}
                />
              </div>
            </div>
          </div>
          <ChartContainer
            id="topDowntimeReasons"
            title="Machine Running Status"
            alignChartTitle="left"
            tooltip=""
            child={
              <div className="stacked-top-downtime">
                <StackedBar
                  chartID="stacked-top-downtime"
                  data={runtimeAnalysisMachineRuntimeStatus}
                  legendXPosition={15}
                  screen="runtimeAnalysis"
                  selectedFilter="all"
                />
              </div>
            }
            width="150px"
            height="190px"
            showFullScreen={true}
          />
        </div>
        <Divider style={{ margin: "8px 0px" }}>Downtime Statistics</Divider>
        <div className="downtime-statistics">
          <div className="chart-wrapper">
            <ChartContainer
              id="mttr-over-time"
              title="MTTR over time"
              alignChartTitle="left"
              tooltip=""
              child={
                <div className="mtrr-chart-container">
                  <LineChart
                    chartID="stacked-machine-runtime-statuws"
                    data={runtimeAnalysisMttrList}
                  />
                  <div className="mtrr-data-bar">
                    <StatisticsDataCard
                      variableName="Total Downtime Hours"
                      value={`${milliToHrs(
                        runtimeAnalysisMttrCardData?.totalTime
                      )}HRS`}
                    />
                    <StatisticsDataCard
                      variableName="MTTR"
                      value={`${milliToHrs(
                        runtimeAnalysisMttrCardData?.actualTime
                      )}HRS`}
                    />
                  </div>
                </div>
              }
              width="150px"
              height="190px"
              showFullScreen={true}
            />
          </div>
          <div className="chart-wrapper">
            <ChartContainer
              id="topDowntimeReasons"
              title="MTBF over time"
              alignChartTitle="left"
              tooltip=""
              child={
                <div className="mtrr-chart-container">
                  <LineChart
                    chartID="stacked-mtbf-over-time"
                    data={runtimeAnalysisMtbfList}
                  />
                  <div className="mtrr-data-bar">
                    <StatisticsDataCard
                      variableName="Total Time Spent"
                      value={`${milliToHrs(
                        runtimeAnalysisMtbfCardData?.totalTime
                      )}HRS`}
                    />
                    <StatisticsDataCard
                      variableName="MTBF"
                      value={`${milliToHrs(
                        runtimeAnalysisMtbfCardData?.actualTime
                      )}HRS`}
                    />
                  </div>
                </div>
              }
              width="150px"
              height="190px"
              showFullScreen={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
