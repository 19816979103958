import * as types from "./actionTypes";

export const getRuntimeAnalysisDowntimeData = (payload: any) => ({
  type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_DOWNTIME_DATA,
  payload,
});
export const getRuntimeAnalysisRuntimeData = (payload: any) => ({
  type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_RUNTIME_DATA,
  payload,
});
export const getRuntimeAnalysisPlannedStoptimeData = (payload: any) => ({
  type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_PLANNED_STOPTIME_DATA,
  payload,
});
export const getRuntimeAnalysisAssetUtilizationData = (payload: any) => ({
  type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_ASSET_UTILIZATION_DATA,
  payload,
});
export const getRuntimeAnalysisMachineRuntimeStatusData = (payload: any) => ({
  type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_MACHINE_RUNTIME_STATUS_DATA,
  payload,
});
export const getRuntimeAnalysisMttrData = (payload: any) => ({
  type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_MTTR_DATA,
  payload,
});

export const getRuntimeAnalysisMtbfData = (payload: any) => ({
  type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_MTBF_DATA,
  payload,
});

export const getRuntimeAnalysisDetailsData = (payload: any) => ({
  type: types.GET_RUNTIME_ANALYSIS_DETAILS_DATA,
  payload,
});

export const getRuntimeAnalysisDeashboardGanttData = (payload: any) => ({
  type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_GANTT_DATA,
  payload,
});