import * as types from "store/actions/actionTypes";
import initialState from "./initalState";

export default function productionOverviewReducer(
  state = initialState.productionOverview,
  action: any
) {
  switch (action.type) {
    case types.GET_PRODUCTION_OVERVIEW_COUNT_DATA_SUCCESS:
      state = {
        ...state,
        productionOverviewBatchCountMetrics:
          action.payload.productionOverviewBatchCountMetrics,
      };
      return state;
    case types.GET_PRODUCTION_OVERVIEW_BATCH_DATA_SUCCESS:
      state = {
        ...state,
        productionOverviewBatchDetailsMetrics:
          action.payload.productionOverviewBatchDetailsMetrics,
      };
      return state;
    case types.GET_PRODUCTION_OVERVIEW_ALARMS_DATA_SUCCESS:
      state = {
        ...state,
        productionOverviewActiveAlarmData: {
          ...state.productionOverviewActiveAlarmData,
          productionOverviewAlarmsSummaryCardData:
            action.payload.alarmsEventsActiveAlarmsCardData,
        },
      };
      return state;

    case types.GET_PRODUCTION_OVERVIEW_TABLE_DATA_SUCCESS:
      state = {
        ...state,
        productionOverviewActiveAlarmData: {
          ...state.productionOverviewActiveAlarmData,
          productionOverviewAlarmsTableList:
            action.payload.alarmsEventsActiveAlarmsList,
          productionOverviewRowCount: action.payload.totalNoOfRows,
          productionOverviewAlarmsSummaryCardData:
            action.payload.alarmsEventsActiveAlarmsCardData,
        },
      };
      return state;

    case types.GET_PRODUCTION_OVERVIEW_PARETO_CHART_DATA_SUCCESS:
      const { alarmsEventsCountByTagsList } = action.payload;
      state = {
        ...state,
        productionOverviewActiveAlarmData: {
          ...state.productionOverviewActiveAlarmData,
          productionOverviewAlarmsCountByTagsList: alarmsEventsCountByTagsList,
        },
      };
      return state;

    case types.GET_PRODUCTION_OVERVIEW_OEE_DATA_SUCCESS:
      state = {
        ...state,
        productionOverviewOeeData: action.payload,
      };
      return state;
    case types.GET_PRODUCTION_OVERVIEW_AVERAGE_CYCLE_TIME_DATA_SUCCESS:
      state = {
        ...state,
        productionOverviewAverageCycleTimeData: action.payload,
      };
      return state;
    case types.GET_PRODUCTION_OVERVIEW_ASSET_UTILIZATION_DATA_SUCCESS:
      //Note: needs improvement from api to calculate same and send this response
      state = {
        ...state,
        productionAssetUtitlization_percentage: action.payload.percentage,
        productionAssetUtitlization_availableTime: action.payload.availableTime,
        productionAssetUtitlization_runtime: action.payload.runtime,
      };
      return state;
    case types.GET_PRODUCTION_OVERVIEW_BATCH_DATA_LOADER_SUCCESS:
      state = {
        ...state,
        productionOverviewBatchLoader: action.productionOverviewBatchLoader,
      };
      return state;
    case types.GET_PRODUCTION_OVERVIEW_PACKAGING_DATA_SUCCESS:
      state = {
        ...state,
        packagingData: action.payload,
      };
      return state;
    case types.GET_PRODUCTION_OVERVIEW_MACHINE_STATUS_SUCCESS:
      return { ...state, machineStatus: action.machineStatus };
    default:
      return state;
  }
}
