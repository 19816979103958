// source: common/Enums.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.ApiStatusCode', null, global);
goog.exportSymbol('proto.ApiVersion', null, global);
/**
 * @enum {number}
 */
proto.ApiVersion = {
  V0_1: 0
};

/**
 * @enum {number}
 */
proto.ApiStatusCode = {
  SUCCESS: 0,
  CONFIG_UPSERT_FAILED: 1,
  GET_CONFIG_LIST_FAILED: 2,
  GET_CONFIG_DETAILS_FAILED: 3,
  DELETE_CONFIG_FAILED: 4
};

goog.object.extend(exports, proto);
