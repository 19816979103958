// source: common/Common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_Enum_pb = require('../common/Enum_pb.js');
goog.object.extend(proto, common_Enum_pb);
goog.exportSymbol('proto.upl_sandbach.AssetUtilization', null, global);
goog.exportSymbol('proto.upl_sandbach.DropDownData', null, global);
goog.exportSymbol('proto.upl_sandbach.Filter', null, global);
goog.exportSymbol('proto.upl_sandbach.FilterRequest', null, global);
goog.exportSymbol('proto.upl_sandbach.GeneralRequest', null, global);
goog.exportSymbol('proto.upl_sandbach.GeneralResponse', null, global);
goog.exportSymbol('proto.upl_sandbach.ParetoData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.GeneralRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.GeneralRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.GeneralRequest.displayName = 'proto.upl_sandbach.GeneralRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.GeneralResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.GeneralResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.GeneralResponse.displayName = 'proto.upl_sandbach.GeneralResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.DropDownData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.DropDownData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.DropDownData.displayName = 'proto.upl_sandbach.DropDownData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.ParetoData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.ParetoData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.ParetoData.displayName = 'proto.upl_sandbach.ParetoData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AssetUtilization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.AssetUtilization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AssetUtilization.displayName = 'proto.upl_sandbach.AssetUtilization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.Filter.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.Filter.displayName = 'proto.upl_sandbach.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.FilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.FilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.FilterRequest.displayName = 'proto.upl_sandbach.FilterRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.GeneralRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.GeneralRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.GeneralRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.GeneralRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestedBy: jspb.Message.getFieldWithDefault(msg, 10, ""),
    requestedService: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.GeneralRequest}
 */
proto.upl_sandbach.GeneralRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.GeneralRequest;
  return proto.upl_sandbach.GeneralRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.GeneralRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.GeneralRequest}
 */
proto.upl_sandbach.GeneralRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestedBy(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestedService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.GeneralRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.GeneralRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.GeneralRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.GeneralRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestedBy();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRequestedService();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string requested_by = 10;
 * @return {string}
 */
proto.upl_sandbach.GeneralRequest.prototype.getRequestedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.GeneralRequest} returns this
 */
proto.upl_sandbach.GeneralRequest.prototype.setRequestedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string requested_service = 20;
 * @return {string}
 */
proto.upl_sandbach.GeneralRequest.prototype.getRequestedService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.GeneralRequest} returns this
 */
proto.upl_sandbach.GeneralRequest.prototype.setRequestedService = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.GeneralResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.GeneralResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.GeneralResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.GeneralResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseMessage: jspb.Message.getFieldWithDefault(msg, 10, ""),
    responseCode: jspb.Message.getFieldWithDefault(msg, 20, 0),
    additionalInfo: jspb.Message.getFieldWithDefault(msg, 30, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.GeneralResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.GeneralResponse;
  return proto.upl_sandbach.GeneralResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.GeneralResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.GeneralResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponseMessage(value);
      break;
    case 20:
      var value = /** @type {!proto.upl_sandbach.GeneralStatusCode} */ (reader.readEnum());
      msg.setResponseCode(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.GeneralResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.GeneralResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.GeneralResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.GeneralResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseMessage();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getResponseCode();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getAdditionalInfo();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
};


/**
 * optional string response_message = 10;
 * @return {string}
 */
proto.upl_sandbach.GeneralResponse.prototype.getResponseMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.GeneralResponse} returns this
 */
proto.upl_sandbach.GeneralResponse.prototype.setResponseMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional GeneralStatusCode response_code = 20;
 * @return {!proto.upl_sandbach.GeneralStatusCode}
 */
proto.upl_sandbach.GeneralResponse.prototype.getResponseCode = function() {
  return /** @type {!proto.upl_sandbach.GeneralStatusCode} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.upl_sandbach.GeneralStatusCode} value
 * @return {!proto.upl_sandbach.GeneralResponse} returns this
 */
proto.upl_sandbach.GeneralResponse.prototype.setResponseCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional string additional_info = 30;
 * @return {string}
 */
proto.upl_sandbach.GeneralResponse.prototype.getAdditionalInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.GeneralResponse} returns this
 */
proto.upl_sandbach.GeneralResponse.prototype.setAdditionalInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.DropDownData.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.DropDownData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.DropDownData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.DropDownData.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 10, ""),
    value: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.DropDownData}
 */
proto.upl_sandbach.DropDownData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.DropDownData;
  return proto.upl_sandbach.DropDownData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.DropDownData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.DropDownData}
 */
proto.upl_sandbach.DropDownData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.DropDownData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.DropDownData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.DropDownData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.DropDownData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string key = 10;
 * @return {string}
 */
proto.upl_sandbach.DropDownData.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.DropDownData} returns this
 */
proto.upl_sandbach.DropDownData.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string value = 20;
 * @return {string}
 */
proto.upl_sandbach.DropDownData.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.DropDownData} returns this
 */
proto.upl_sandbach.DropDownData.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.ParetoData.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.ParetoData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.ParetoData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ParetoData.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 10, ""),
    value: jspb.Message.getFieldWithDefault(msg, 20, 0),
    desc: jspb.Message.getFieldWithDefault(msg, 30, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.ParetoData}
 */
proto.upl_sandbach.ParetoData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.ParetoData;
  return proto.upl_sandbach.ParetoData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.ParetoData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.ParetoData}
 */
proto.upl_sandbach.ParetoData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.ParetoData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.ParetoData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.ParetoData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ParetoData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getDesc();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
};


/**
 * optional string name = 10;
 * @return {string}
 */
proto.upl_sandbach.ParetoData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ParetoData} returns this
 */
proto.upl_sandbach.ParetoData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 value = 20;
 * @return {number}
 */
proto.upl_sandbach.ParetoData.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.ParetoData} returns this
 */
proto.upl_sandbach.ParetoData.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string desc = 30;
 * @return {string}
 */
proto.upl_sandbach.ParetoData.prototype.getDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ParetoData} returns this
 */
proto.upl_sandbach.ParetoData.prototype.setDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AssetUtilization.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AssetUtilization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AssetUtilization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AssetUtilization.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    availableTime: jspb.Message.getFieldWithDefault(msg, 20, 0),
    totalRuntime: jspb.Message.getFieldWithDefault(msg, 30, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AssetUtilization}
 */
proto.upl_sandbach.AssetUtilization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AssetUtilization;
  return proto.upl_sandbach.AssetUtilization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AssetUtilization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AssetUtilization}
 */
proto.upl_sandbach.AssetUtilization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineName(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAvailableTime(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRuntime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AssetUtilization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AssetUtilization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AssetUtilization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AssetUtilization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAvailableTime();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getTotalRuntime();
  if (f !== 0) {
    writer.writeInt64(
      30,
      f
    );
  }
};


/**
 * optional string machine_name = 10;
 * @return {string}
 */
proto.upl_sandbach.AssetUtilization.prototype.getMachineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.AssetUtilization} returns this
 */
proto.upl_sandbach.AssetUtilization.prototype.setMachineName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 available_time = 20;
 * @return {number}
 */
proto.upl_sandbach.AssetUtilization.prototype.getAvailableTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.AssetUtilization} returns this
 */
proto.upl_sandbach.AssetUtilization.prototype.setAvailableTime = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int64 total_runtime = 30;
 * @return {number}
 */
proto.upl_sandbach.AssetUtilization.prototype.getTotalRuntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.AssetUtilization} returns this
 */
proto.upl_sandbach.AssetUtilization.prototype.setTotalRuntime = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.Filter.repeatedFields_ = [35];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    line: (f = msg.getLine()) && proto.upl_sandbach.DropDownData.toObject(includeInstance, f),
    machine: (f = msg.getMachine()) && proto.upl_sandbach.DropDownData.toObject(includeInstance, f),
    sku: (f = msg.getSku()) && proto.upl_sandbach.DropDownData.toObject(includeInstance, f),
    nodeidsList: (f = jspb.Message.getRepeatedField(msg, 35)) == null ? undefined : f,
    startTime: jspb.Message.getFieldWithDefault(msg, 40, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 50, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.Filter}
 */
proto.upl_sandbach.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.Filter;
  return proto.upl_sandbach.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.Filter}
 */
proto.upl_sandbach.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new proto.upl_sandbach.DropDownData;
      reader.readMessage(value,proto.upl_sandbach.DropDownData.deserializeBinaryFromReader);
      msg.setLine(value);
      break;
    case 20:
      var value = new proto.upl_sandbach.DropDownData;
      reader.readMessage(value,proto.upl_sandbach.DropDownData.deserializeBinaryFromReader);
      msg.setMachine(value);
      break;
    case 30:
      var value = new proto.upl_sandbach.DropDownData;
      reader.readMessage(value,proto.upl_sandbach.DropDownData.deserializeBinaryFromReader);
      msg.setSku(value);
      break;
    case 35:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNodeids(values[i]);
      }
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLine();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.upl_sandbach.DropDownData.serializeBinaryToWriter
    );
  }
  f = message.getMachine();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.upl_sandbach.DropDownData.serializeBinaryToWriter
    );
  }
  f = message.getSku();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.upl_sandbach.DropDownData.serializeBinaryToWriter
    );
  }
  f = message.getNodeidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      35,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      40,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      50,
      f
    );
  }
};


/**
 * optional DropDownData line = 10;
 * @return {?proto.upl_sandbach.DropDownData}
 */
proto.upl_sandbach.Filter.prototype.getLine = function() {
  return /** @type{?proto.upl_sandbach.DropDownData} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.DropDownData, 10));
};


/**
 * @param {?proto.upl_sandbach.DropDownData|undefined} value
 * @return {!proto.upl_sandbach.Filter} returns this
*/
proto.upl_sandbach.Filter.prototype.setLine = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.Filter} returns this
 */
proto.upl_sandbach.Filter.prototype.clearLine = function() {
  return this.setLine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.Filter.prototype.hasLine = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional DropDownData machine = 20;
 * @return {?proto.upl_sandbach.DropDownData}
 */
proto.upl_sandbach.Filter.prototype.getMachine = function() {
  return /** @type{?proto.upl_sandbach.DropDownData} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.DropDownData, 20));
};


/**
 * @param {?proto.upl_sandbach.DropDownData|undefined} value
 * @return {!proto.upl_sandbach.Filter} returns this
*/
proto.upl_sandbach.Filter.prototype.setMachine = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.Filter} returns this
 */
proto.upl_sandbach.Filter.prototype.clearMachine = function() {
  return this.setMachine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.Filter.prototype.hasMachine = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional DropDownData sku = 30;
 * @return {?proto.upl_sandbach.DropDownData}
 */
proto.upl_sandbach.Filter.prototype.getSku = function() {
  return /** @type{?proto.upl_sandbach.DropDownData} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.DropDownData, 30));
};


/**
 * @param {?proto.upl_sandbach.DropDownData|undefined} value
 * @return {!proto.upl_sandbach.Filter} returns this
*/
proto.upl_sandbach.Filter.prototype.setSku = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.Filter} returns this
 */
proto.upl_sandbach.Filter.prototype.clearSku = function() {
  return this.setSku(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.Filter.prototype.hasSku = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * repeated int64 nodeIds = 35;
 * @return {!Array<number>}
 */
proto.upl_sandbach.Filter.prototype.getNodeidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 35));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.upl_sandbach.Filter} returns this
 */
proto.upl_sandbach.Filter.prototype.setNodeidsList = function(value) {
  return jspb.Message.setField(this, 35, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.Filter} returns this
 */
proto.upl_sandbach.Filter.prototype.addNodeids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 35, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.Filter} returns this
 */
proto.upl_sandbach.Filter.prototype.clearNodeidsList = function() {
  return this.setNodeidsList([]);
};


/**
 * optional int64 start_time = 40;
 * @return {number}
 */
proto.upl_sandbach.Filter.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.Filter} returns this
 */
proto.upl_sandbach.Filter.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional int64 end_time = 50;
 * @return {number}
 */
proto.upl_sandbach.Filter.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.Filter} returns this
 */
proto.upl_sandbach.Filter.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 50, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.FilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.FilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.FilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.FilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.upl_sandbach.Filter.toObject(includeInstance, f),
    generalRequest: (f = msg.getGeneralRequest()) && proto.upl_sandbach.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.FilterRequest}
 */
proto.upl_sandbach.FilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.FilterRequest;
  return proto.upl_sandbach.FilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.FilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.FilterRequest}
 */
proto.upl_sandbach.FilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new proto.upl_sandbach.Filter;
      reader.readMessage(value,proto.upl_sandbach.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 20:
      var value = new proto.upl_sandbach.GeneralRequest;
      reader.readMessage(value,proto.upl_sandbach.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.FilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.FilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.FilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.FilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.upl_sandbach.Filter.serializeBinaryToWriter
    );
  }
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.upl_sandbach.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional Filter filter = 10;
 * @return {?proto.upl_sandbach.Filter}
 */
proto.upl_sandbach.FilterRequest.prototype.getFilter = function() {
  return /** @type{?proto.upl_sandbach.Filter} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.Filter, 10));
};


/**
 * @param {?proto.upl_sandbach.Filter|undefined} value
 * @return {!proto.upl_sandbach.FilterRequest} returns this
*/
proto.upl_sandbach.FilterRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.FilterRequest} returns this
 */
proto.upl_sandbach.FilterRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.FilterRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralRequest general_request = 20;
 * @return {?proto.upl_sandbach.GeneralRequest}
 */
proto.upl_sandbach.FilterRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.upl_sandbach.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.GeneralRequest, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralRequest|undefined} value
 * @return {!proto.upl_sandbach.FilterRequest} returns this
*/
proto.upl_sandbach.FilterRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.FilterRequest} returns this
 */
proto.upl_sandbach.FilterRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.FilterRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 20) != null;
};


goog.object.extend(exports, proto.upl_sandbach);
