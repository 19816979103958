export const paretoTooltipText = (category: string, description: string) => {
  let catogoryNamesArray = category.split("-");
  if (description)
    return (
      description + " - " + catogoryNamesArray[1] + "- " + catogoryNamesArray[2]
    );
  else
    return (
      catogoryNamesArray[3] +
      " - " +
      catogoryNamesArray[1] +
      "- " +
      catogoryNamesArray[2]
    );
};
