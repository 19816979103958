import { Table, Input, Popover, Divider, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import { MoreOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { CustomTimePicker } from "components/Common/CustomTimePicker/CustomTimePicker";
import { CustomButton } from "components/Common/Button";
import "./index.scss";
import { ReactComponent as UpArrow } from "assets/icons/up-arrow.svg";
import { ReactComponent as DownArrow } from "assets/icons/down-arrow.svg";
import {
  ascendingNumberSort,
  ascendingSort,
  decendingSort,
  descendingNumberSort,
} from "utils/common";
import { useEffect, useRef, useState } from "react";
import { AddReasonCodeModal } from "./AddReasonCodeModal";
import { EditReasonCodeModal } from "./EditReasonCodeModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReasonCodeLogs,
  endDownTimeAMD,
  getAMDList,
} from "store/actions/reasonCodeManagement";
import moment from "moment";

export const ReasonCodeAMD = () => {
  const { Search } = Input;
  const dispatch = useDispatch();
  const amdList = useSelector((state: any) => state.reasonCode.amdList);
  const [tableData, setTableData] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState<any>([]);
  const [EndDate, setEndDate] = useState<any>("");
  const timer = useRef<any>(null);
  const [startTime, setStartTime] = useState(
    moment(new Date()).subtract(1, "days").valueOf()
  );
  const [endTime, setEndTime] = useState(moment(new Date()).valueOf());
  const [searchText, setSearchText] = useState<string>("");
  const [selectedEditId, setSelectedEditId] = useState<any>();
  const [selectedValue, setSelectedValue] = useState("This Week");
  const [addModalData, setAddModalData] = useState<any>({});
  const onSearch = (value: string) => {
    setSearchText(value);
  };

  const onChangeSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    
    dispatch(
      getAMDList({
        shiftInfo: 0,
        startTime: moment(moment().startOf("week")).valueOf(),
        endTime: moment(new Date()).valueOf(),
        searchText: "", 
      })
    );
  }, [dispatch]);

  const onFilterSearchHandler = () => {
    if (startTime && endTime) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        dispatch(
          getAMDList({
            shiftInfo: 0,
            startTime,
            endTime,
            searchText,
          })
        );
      }, 500);  
    }
  };
  const fetchAmdList = () => {
    dispatch(
      getAMDList({
        shiftInfo: 0,
        startTime,
        endTime,
        searchText,
      })
    );
}
  
  useEffect(() => {
    setTableData(amdList);
  }, [amdList]);

  const modalShow = (state: any, setState: any) => {
    setState(true);
  };

  const okHandle = (state: any, setState: any) => {
    setState(false);
  };

  const cancelHandle = (state: any, setState: any) => {
    setState(false);
  };

  const rowSelection: any = {
    onSelect: (record: any) => {
      setSelectedKey([...selectedKey, record.stoppageId]);
      setEndDate(record.endDate);
      if (record) {
        setSelectedKey([...selectedKey, record.stoppageId]);
        setEndDate(record.endDate);
        setAddModalData(record);
      }
    },
  };
  const onEndDownTimeHandler = () => {
    if (selectedKey.length) {
      dispatch(endDownTimeAMD({ stoppageId: selectedKey, endTime: EndDate }));
    } else {
      message.error("please select Reascon Code log");
    }
  };

  const onDeleteReasonCodeLog = () => {
    if (selectedKey.length) {
      dispatch(deleteReasonCodeLogs({ stoppageId: selectedKey }));
    } else {
      message.error("please select Reascon Code log");
    }
  };

  const selectedRowFilterData = tableData?.find((item: any) => {
    return item.stoppageId === selectedEditId;
  });
  const moreContent = (status: any) => {
    return (
      <>
        <div className="more-container">
          <div
            className={`more-content ${status.toLowerCase()}`}
            onClick={
              status === "Inactive"
                ? () => {
                    modalShow(isEditModalOpen, setIsEditModalOpen);
                  }
                : undefined
            }
          >
            <EditOutlined />
            Edit
          </div>
          <Divider />
          
        </div>
      </>
    );
  };

  const columns: ColumnsType<any> = [
    {
      key: "stoppageId",
      title: (
        <div className="column-title">
          <div>Stoppage ID</div>
          <div className="sortArrows">
            <UpArrow
              onClick={() =>
                setTableData(ascendingNumberSort("stoppageId", [...tableData]))
              }
            />
            <DownArrow
              fill="white"
              onClick={() =>
                setTableData(descendingNumberSort("stoppageId", [...tableData]))
              }
            />
          </div>
        </div>
      ),
      dataIndex: "stoppageId",
    },
    {
      key: "FPLine",
      title: (
        <div className="column-title">
          <div>F&P Line</div>
          <div className="sortArrows">
            <UpArrow
              onClick={() =>
                setTableData(ascendingSort("fpLine", [...tableData]))
              }
            />
            <DownArrow
              onClick={() =>
                setTableData(decendingSort("fpLine", [...tableData]))
              }
            />
          </div>
        </div>
      ),
      dataIndex: "fpLine",
    },
    {
      key: "Equipment",
      title: (
        <div className="column-title">
          <div>Equipment</div>
          <div className="sortArrows">
            <UpArrow
              onClick={() =>
                setTableData(ascendingSort("equipment", [...tableData]))
              }
            />
            <DownArrow
              onClick={() =>
                setTableData(decendingSort("equipment", [...tableData]))
              }
            />
          </div>
        </div>
      ),
      dataIndex: "equipment",
    },
    {
      key: "BatchOrder",
      title: (
        <div className="column-title">
          <div>Batch Order</div>
          <div className="sortArrows">
            <UpArrow
              onClick={() =>
                setTableData(ascendingSort("batchOrder", [...tableData]))
              }
            />
            <DownArrow
              onClick={() =>
                setTableData(decendingSort("batchOrder", [...tableData]))
              }
            />
          </div>
        </div>
      ),
      dataIndex: "batchOrder",
    },
    {
      key: "SKU",
      title: (
        <div className="column-title">
          <div>SKU</div>
          <div className="sortArrows">
            <UpArrow
              onClick={() => setTableData(ascendingSort("sku", [...tableData]))}
            />
            <DownArrow
              onClick={() => setTableData(decendingSort("sku", [...tableData]))}
            />
          </div>
        </div>
      ),
      dataIndex: "sku",
    },
    {
      key: "reasonCode",
      title: (
        <div className="column-title">
          <div>Reason Code</div>
          <div className="sortArrows">
            <UpArrow
              onClick={() =>
                setTableData(ascendingSort("reasonCode", [...tableData]))
              }
            />
            <DownArrow
              onClick={() =>
                setTableData(decendingSort("reasonCode", [...tableData]))
              }
            />
          </div>
        </div>
      ),
      dataIndex: "reasonCode",
    },
    {
      key: "operator",
      title: (
        <div className="column-title">
          <div>Operator</div>
          <div className="sortArrows">
            <UpArrow
              onClick={() =>
                setTableData(ascendingSort("operator", [...tableData]))
              }
            />
            <DownArrow
              onClick={() =>
                setTableData(decendingSort("operator", [...tableData]))
              }
            />
          </div>
        </div>
      ),
      dataIndex: "operator",
    },
    {
      key: "startDate",
      title: (
        <div className="column-title">
          <div>Start Date</div>
          <div className="sortArrows">
            <UpArrow
              onClick={() =>
                setTableData(ascendingSort("startDate", [...tableData]))
              }
            />
            <DownArrow
              onClick={() =>
                setTableData(decendingSort("startDate", [...tableData]))
              }
            />
          </div>
        </div>
      ),
      dataIndex: "startDate",
    },
    {
      key: "endDate",
      title: (
        <div className="column-title">
          <div>End Date</div>
          <div className="sortArrows">
            <UpArrow
              onClick={() =>
                setTableData(ascendingSort("endDate", [...tableData]))
              }
            />
            <DownArrow
              onClick={() =>
                setTableData(decendingSort("endDate", [...tableData]))
              }
            />
          </div>
        </div>
      ),
      dataIndex: "endDate",
    },
    {
      key: "duration",
      title: (
        <div className="column-title">
          <div>Duration</div>
          <div className="sortArrows">
            <UpArrow
              onClick={() =>
                setTableData(ascendingSort("duration", [...tableData]))
              }
            />
            <DownArrow
              onClick={() =>
                setTableData(decendingSort("duration", [...tableData]))
              }
            />
          </div>
        </div>
      ),
      dataIndex: "duration",
    },
    {
      key: "status",
      title: (
        <div className="column-title">
          <div>Status</div>
          <div className="sortArrows">
            <UpArrow
              onClick={() =>
                setTableData(ascendingSort("status", [...tableData]))
              }
            />
            <DownArrow
              onClick={() =>
                setTableData(decendingSort("status", [...tableData]))
              }
            />
          </div>
        </div>
      ),
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          <div className="status-container">
            <div className="value">
              <span className={`${status}`}>{status}</span>
            </div>
            <Popover content={moreContent(status)}>
              <MoreOutlined />
            </Popover>
          </div>
        </>
      ),
    },
  ];


  return (
    <>
      <div className="management-filter-container">
        <div className="shift-container">
          <CustomTimePicker
            startTime={startTime}
            endTime={endTime}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
          />
        </div>
        <div className="search-container">
          <Search
            placeholder="Search"
            onChange={onChangeSearch}
            onSearch={onSearch}
            style={{ width: "100%" }}
            bordered={false}
            value={searchText}
          />
        </div>
        <div className="amd-button">
          <CustomButton type="Apply" onClick={() => onFilterSearchHandler()} />
          <CustomButton
            type="Edit Reason Code"
            onClick={() =>
              addModalData.stoppageId
                ? modalShow(isModalOpen, setIsModalOpen)
                : message.error("Please select stopage.")
            }
          />
          <CustomButton
            type="End Downtime"
            onClick={() => onEndDownTimeHandler()}
          />
        </div>
      </div>
      <AddReasonCodeModal
        destroyOnClose={true}
        open={isModalOpen}
        onOk={() => okHandle(isModalOpen, setIsModalOpen)}
        onCancel={() => cancelHandle(isModalOpen, setIsModalOpen)}
        selectedRowFilterData={addModalData}
        onFilterSearchHandler={() => onFilterSearchHandler()}
        fetchData={()=>fetchAmdList()}
      />
      {/* <EditReasonCodeModal
        open={isEditModalOpen}
        onOk={() => okHandle(isEditModalOpen, setIsEditModalOpen)}
        onCancel={() => cancelHandle(isEditModalOpen, setIsEditModalOpen)}
        selectedRowFilterData={selectedRowFilterData}
        onFilterSearchHandler={() => onFilterSearchHandler()}
      /> */}
      <div className="grid-view-container">
        <Table
          rowSelection={{
            type: "radio",
            ...rowSelection,
          }}
          columns={columns}
          dataSource={tableData}
          pagination={false}
          showSorterTooltip={false}
          //onChange={onChange}
          scroll={{ y: 650 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                setSelectedEditId(record.stoppageId);
                setSelectedKey([...selectedKey, record.stoppageId]);
              },
            };
          }}
        />
      </div>
    </>
  );
};
