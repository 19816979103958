import { Input, message, Modal, Switch } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import './index.scss';
import { Dropdown } from "components/Common/Dropdown/Dropdown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createReasonCodeFactor, getReasonCodeCategories } from "store/actions/reasonCodeManagement";

export const NewResonCodeFactor = (props: any) => {
  const { visible, open, onOk, onCancel, currentComponent } = props;
  const dispatch = useDispatch();
  const categoryList = useSelector((state: any) => state.reasonCode.reasonCodeCategoryList);

  const [payload, setPayload] = useState<any>({
    categoryId: '',
    factorName: '',
    status: true
  })

  useEffect(() => {
    dispatch(getReasonCodeCategories());
  }, [dispatch])

  const onChange = (checked: boolean) => {
    setPayload({ ...payload, status: checked });
  };

  const selectedvalue = (value: string) => {
    setPayload({ ...payload, categoryId: value });
  }

  const handleCreate = () => {
    if (payload.categoryId === "") {
      message.error('please select Reascon Code Category');
      return;
    } else if(payload.factorName === "") {
      message.error('please enter Reason Code Factor Name');
      return;
    }

    dispatch(createReasonCodeFactor({...payload, viewType: currentComponent}));
    onOk();
    setPayload({
      categoryId: '',
      factorName: '',
      status: true
    })
  }

  return (
    <Modal
      visible={visible}
      centered
      open={open}
      onOk={handleCreate}
      onCancel={onCancel}
      width={336}
      closeIcon={<CloseCircleFilled />}
      wrapClassName="create-reason-code-category"
      okText="Create"
      destroyOnClose={true}
    >
      <div className="title">Create New Reason Code Factor</div>
      <div className="row">
        <label>Select Reason Code Category</label>
        <Dropdown optionData={categoryList} placeHolder={"Category Drop down"}
          selectedValue={selectedvalue} />
      </div>
      <div className="row">
        <label>Reason Code Factor Name</label>
        <Input placeholder="Enter Reason Code Factor Name" className="input-text"
          onChange={(e) => setPayload({ ...payload, factorName: e.target.value })} />
      </div>

      <div className="status-row">
        <label>Status</label>
        <Switch defaultChecked onChange={onChange} />
      </div>
    </Modal>
  )
}