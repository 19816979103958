import { Select } from "antd";
import { MetricsDataListProps, MetricsDataType } from "types";
import "./index.scss";

const MetricsCard: React.FC<MetricsDataListProps> = ({
  metricsData,
  setSelectedBatchNumber,
}) => {
  if (!metricsData.length) return null;
  const handleChange = (value: string[]) => {
    setSelectedBatchNumber([value]);
  };
  const CardList = metricsData.map((data: MetricsDataType) => {
    if (data.metricsName === "Process Order") {
      let options = [] as any;
      data.metricsValue &&
        data.metricsValue.forEach((value: string) =>
          options.push({ value: value, label: value })
        );
      return (
        <div>
          <div className="metrics-heading">{data.metricsName}</div>
          <div className="metrics-value">
            <Select
              style={{ width: 160 }}
              options={options}
              onChange={handleChange}
              placeholder="Select Process Order"
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="metrics-heading">{data.metricsName}</div>
          <div className="metrics-value">
            {typeof data.metricsValue === "string" &&
            data.metricsValue.charAt(0) === "N"
              ? "NA"
              : data.metricsValue}
          </div>
        </div>
      );
    }
  });
  return <div className="metrics-container">{CardList}</div>;
};

export default MetricsCard;
