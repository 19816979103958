import ContentHeader from "components/ContentHeader";
import { Tabs } from "antd";
import { ReasonCodeManagementFilterComonent } from "./Management";
import { ReasonCodeAssignment } from "./Assignment";
import { ReasonCodeAMD } from "./AMD";

export const ReasonCode = () => {
  const items = [
    {
      label: `Management`,
      key: "management",
      children: <ReasonCodeManagementFilterComonent />,
    },
    {
      label: `Assignment`,
      key: "assignment",
      children: <ReasonCodeAssignment />,
    },
    {
      label: `AMD`,
      key: "amd",
      children: <ReasonCodeAMD />,
    },
  ];
  return (
    <div className="tabs-wrapper-container">
      <ContentHeader
        heading="Reason Code"
        
      />
      <Tabs defaultActiveKey="1">
        {items.map((item, index) => {
          return (
            <Tabs.TabPane tab={item.label} key={item.key}>
              {item.children}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};
