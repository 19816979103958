import { message } from 'antd';
import { getUrl } from 'config/apiconfig';

export const loginUser = (userInfo: any, rememberMe: boolean) => {
  const loginRequestURL = getUrl('loginUrl');

  return fetch(loginRequestURL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userName: userInfo.userName,
      password: userInfo.userPassword,
      rememberMe: rememberMe,
    })
  })
};

export const logOutNotify = (uid: any) => {
  const logOutURL = getUrl('logoutUrl');
  const logOutRequest = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: uid,
  };

  return fetch(logOutURL, logOutRequest);
};

export const generateOtp = (username: string) => {
  const genrateOTP = getUrl('generateOTP');
  const generateOTPRequest = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId: username
    })
  };

  fetch(genrateOTP, generateOTPRequest)
  .then(async (response) => {
    let generatotpResponse = await response.json();
    message.success(generatotpResponse.responseMessage);
  }).catch((error: any) => {
    if (error !== 500) {
      message.error('Network error, Please check your connection');
    } else {
      message.error('Incorrect Username');
    }
  })
}


export const validateOTP = (userInfo : any) => {
  const validateOTP = getUrl('validateOTP');
  const validateOTPRequest = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId: userInfo.userId,
      otp: userInfo.otp,
      validateOtp: userInfo.validateOTP
    })
  }
  return fetch(validateOTP, validateOTPRequest);
}

export const changePassword = (userInfo: any) => {
  const changePassword = getUrl('validateOTP');
  const changeOTPRequest = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId: userInfo.userId,
      isPasswordUpdate: true,
      otp: userInfo.otp,
      validateOtp: false,
      newPassword: userInfo.newPassword
    })
  }
  return fetch(changePassword, changeOTPRequest);
}