import { Input, Modal, Switch } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import './index.scss';
import { Dropdown } from "components/Common/Dropdown/Dropdown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editReasonCodeCategory, getReasonCodeCategories } from "store/actions/reasonCodeManagement";

export const EditResonCode = (props: any) => {
    const { visible, onOk, onCancel, category, data } = props;
    const dispatch = useDispatch();
    const categoryList = useSelector((state: any) => state.reasonCode.reasonCodeCategoryList);
    const reasonCodeFactor = useSelector((state: any) => state.reasonCode.reasonCodeFactor);
    const [reasonCodeFactorDropdownData, setreasonCodeFactorDropdownData] = useState<any>([]);
    const [categoryName, setCategoryName] = useState<any>("")
    const [categorUuid, setCategoryUuid] = useState("")
    const [FactorUuid, setFactorUuid] = useState("")
    const [factorName, setFactorName] = useState<any>("")
    const [codeName, setCodeName] = useState("")
    const [status, setStatus] = useState<boolean>();

    useEffect(() => {
        dispatch(getReasonCodeCategories());
    }, [dispatch])

    useEffect(() => {
        if (reasonCodeFactor) {
            let data = reasonCodeFactor[category]
            setreasonCodeFactorDropdownData(data);
        }
    }, [reasonCodeFactor])

    useEffect(() => {
        setCategoryName(selectedCategoryFilterData)
        setFactorName(selectedFactorFilterData)
        setCodeName(data?.reasonCodeTitle)
        setStatus(data?.status)
    }, [data])

    const onChange = (checked: boolean) => {
        setStatus(checked)
    };

    const handleCreate = () => {
        dispatch(editReasonCodeCategory({ category: categoryName?.value ? categoryName?.value : categorUuid, reasonName: codeName, reasonUuid: data?.key, factorUuid: factorName?.value ? factorName.value : FactorUuid, status: status, viewType: "grid", type: 2 }));
        onOk();
    }

    const selectedFactor = (value: string) => {
        setFactorUuid(value)
    }

    const selectedCategory = (value: string) => {
        setCategoryUuid(value);
        setreasonCodeFactorDropdownData(reasonCodeFactor[value])
    }

    const selectedCategoryFilterData = categoryList?.find((item: any) => {
        return item.category === data?.reasonCodeCategory
    })
    const selectedFactorFilterData = reasonCodeFactorDropdownData?.find((item: any) => {
        return item.category === data?.reasonCodeFactor
    })


    return (
        <Modal
            open={visible}
            centered
            onOk={handleCreate}
            onCancel={onCancel}
            width={336}
            closeIcon={<CloseCircleFilled />}
            wrapClassName="create-reason-code-category"
            okText="Save"
            destroyOnClose={true}
        >
            <div className="title">Edit Reason Code</div>
            <div className="row">
                <label>Select Reason Code Category</label>
                <Dropdown
                    optionData={categoryList}
                    placeHolder={"Category Drop down"}
                    selectedValue={selectedCategory}
                    DefaultValue={selectedCategoryFilterData}
                />
            </div>

            <div className="row">
                <label>Select Reason Code Factor</label>
                <Dropdown
                    optionData={reasonCodeFactorDropdownData}
                    placeHolder={"Factor Drop down"}
                    selectedValue={selectedFactor}
                    DefaultValue={selectedFactorFilterData} />
            </div>
            <div className="row">
                <label>Reason Code Name</label>
                <Input
                    placeholder="Enter Reason Code Name"
                    className="input-text"
                    value={codeName}
                    onChange={(e) => setCodeName(e.target.value)} />
            </div>
            <div className="status-row">
                <label>Status</label>
                <Switch checked={status} onChange={(checked: boolean) => onChange(checked)}  />
            </div>
        </Modal>
    )
}