// source: model/ReasonCodeOperatorModel.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var model_ReasonCodeModel_pb = require('../model/ReasonCodeModel_pb.js');
goog.object.extend(proto, model_ReasonCodeModel_pb);
var enums_ReasonCodeEnum_pb = require('../enums/ReasonCodeEnum_pb.js');
goog.object.extend(proto, enums_ReasonCodeEnum_pb);
goog.exportSymbol('proto.proto.BatchInfo', null, global);
goog.exportSymbol('proto.proto.FpLineDetails', null, global);
goog.exportSymbol('proto.proto.FpLineInfo', null, global);
goog.exportSymbol('proto.proto.FpLogs', null, global);
goog.exportSymbol('proto.proto.LogFilters', null, global);
goog.exportSymbol('proto.proto.MachineData', null, global);
goog.exportSymbol('proto.proto.MachineInfo', null, global);
goog.exportSymbol('proto.proto.MachineStatusInfo', null, global);
goog.exportSymbol('proto.proto.MachineWiseRateMatrixCount', null, global);
goog.exportSymbol('proto.proto.ProcessLine', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FpLineInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.FpLineInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FpLineInfo.displayName = 'proto.proto.FpLineInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FpLineDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.FpLineDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FpLineDetails.displayName = 'proto.proto.FpLineDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MachineInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MachineInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MachineInfo.displayName = 'proto.proto.MachineInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MachineData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MachineData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MachineData.displayName = 'proto.proto.MachineData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LogFilters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LogFilters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LogFilters.displayName = 'proto.proto.LogFilters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FpLogs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.FpLogs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FpLogs.displayName = 'proto.proto.FpLogs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ProcessLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ProcessLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ProcessLine.displayName = 'proto.proto.ProcessLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BatchInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.BatchInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BatchInfo.displayName = 'proto.proto.BatchInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MachineWiseRateMatrixCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MachineWiseRateMatrixCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MachineWiseRateMatrixCount.displayName = 'proto.proto.MachineWiseRateMatrixCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MachineStatusInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MachineStatusInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MachineStatusInfo.displayName = 'proto.proto.MachineStatusInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FpLineInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FpLineInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FpLineInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FpLineInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    fpLineName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fpLineDescription: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FpLineInfo}
 */
proto.proto.FpLineInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FpLineInfo;
  return proto.proto.FpLineInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FpLineInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FpLineInfo}
 */
proto.proto.FpLineInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFpLineName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFpLineDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FpLineInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FpLineInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FpLineInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FpLineInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFpLineName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFpLineDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string fp_line_name = 1;
 * @return {string}
 */
proto.proto.FpLineInfo.prototype.getFpLineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FpLineInfo} returns this
 */
proto.proto.FpLineInfo.prototype.setFpLineName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fp_line_description = 2;
 * @return {string}
 */
proto.proto.FpLineInfo.prototype.getFpLineDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FpLineInfo} returns this
 */
proto.proto.FpLineInfo.prototype.setFpLineDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FpLineDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FpLineDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FpLineDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FpLineDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    processOrder: jspb.Message.getFieldWithDefault(msg, 2, 0),
    productSku: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fpLine: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FpLineDetails}
 */
proto.proto.FpLineDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FpLineDetails;
  return proto.proto.FpLineDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FpLineDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FpLineDetails}
 */
proto.proto.FpLineDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBatchNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProcessOrder(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductSku(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFpLine(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FpLineDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FpLineDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FpLineDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FpLineDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchNumber();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProcessOrder();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getProductSku();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFpLine();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 batch_number = 1;
 * @return {number}
 */
proto.proto.FpLineDetails.prototype.getBatchNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.FpLineDetails} returns this
 */
proto.proto.FpLineDetails.prototype.setBatchNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 process_order = 2;
 * @return {number}
 */
proto.proto.FpLineDetails.prototype.getProcessOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.FpLineDetails} returns this
 */
proto.proto.FpLineDetails.prototype.setProcessOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string product_sku = 3;
 * @return {string}
 */
proto.proto.FpLineDetails.prototype.getProductSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FpLineDetails} returns this
 */
proto.proto.FpLineDetails.prototype.setProductSku = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fp_line = 4;
 * @return {string}
 */
proto.proto.FpLineDetails.prototype.getFpLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FpLineDetails} returns this
 */
proto.proto.FpLineDetails.prototype.setFpLine = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.proto.FpLineDetails.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FpLineDetails} returns this
 */
proto.proto.FpLineDetails.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MachineInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MachineInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MachineInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    machineId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MachineInfo}
 */
proto.proto.MachineInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MachineInfo;
  return proto.proto.MachineInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MachineInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MachineInfo}
 */
proto.proto.MachineInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMachineId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MachineInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MachineInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MachineInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMachineId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string machine_name = 1;
 * @return {string}
 */
proto.proto.MachineInfo.prototype.getMachineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MachineInfo} returns this
 */
proto.proto.MachineInfo.prototype.setMachineName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 machine_id = 2;
 * @return {number}
 */
proto.proto.MachineInfo.prototype.getMachineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MachineInfo} returns this
 */
proto.proto.MachineInfo.prototype.setMachineId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.proto.MachineInfo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MachineInfo} returns this
 */
proto.proto.MachineInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.proto.MachineInfo.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MachineInfo} returns this
 */
proto.proto.MachineInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MachineData.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MachineData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MachineData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineData.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    processOrder: jspb.Message.getFieldWithDefault(msg, 2, 0),
    productSku: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fpLine: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    reasonCodeCategoryInfo: (f = msg.getReasonCodeCategoryInfo()) && model_ReasonCodeModel_pb.ReasonCodeCategoryInfo.toObject(includeInstance, f),
    reasonCodeFactor: (f = msg.getReasonCodeFactor()) && model_ReasonCodeModel_pb.ReasonCodeFactorInfo.toObject(includeInstance, f),
    reasonCodeInfo: (f = msg.getReasonCodeInfo()) && model_ReasonCodeModel_pb.ReasonCodeDetailsInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MachineData}
 */
proto.proto.MachineData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MachineData;
  return proto.proto.MachineData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MachineData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MachineData}
 */
proto.proto.MachineData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBatchNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProcessOrder(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductSku(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFpLine(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 6:
      var value = new model_ReasonCodeModel_pb.ReasonCodeCategoryInfo;
      reader.readMessage(value,model_ReasonCodeModel_pb.ReasonCodeCategoryInfo.deserializeBinaryFromReader);
      msg.setReasonCodeCategoryInfo(value);
      break;
    case 7:
      var value = new model_ReasonCodeModel_pb.ReasonCodeFactorInfo;
      reader.readMessage(value,model_ReasonCodeModel_pb.ReasonCodeFactorInfo.deserializeBinaryFromReader);
      msg.setReasonCodeFactor(value);
      break;
    case 8:
      var value = new model_ReasonCodeModel_pb.ReasonCodeDetailsInfo;
      reader.readMessage(value,model_ReasonCodeModel_pb.ReasonCodeDetailsInfo.deserializeBinaryFromReader);
      msg.setReasonCodeInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MachineData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MachineData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MachineData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchNumber();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProcessOrder();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getProductSku();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFpLine();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getReasonCodeCategoryInfo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      model_ReasonCodeModel_pb.ReasonCodeCategoryInfo.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeFactor();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      model_ReasonCodeModel_pb.ReasonCodeFactorInfo.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      model_ReasonCodeModel_pb.ReasonCodeDetailsInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 batch_number = 1;
 * @return {number}
 */
proto.proto.MachineData.prototype.getBatchNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MachineData} returns this
 */
proto.proto.MachineData.prototype.setBatchNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 process_order = 2;
 * @return {number}
 */
proto.proto.MachineData.prototype.getProcessOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MachineData} returns this
 */
proto.proto.MachineData.prototype.setProcessOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string product_sku = 3;
 * @return {string}
 */
proto.proto.MachineData.prototype.getProductSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MachineData} returns this
 */
proto.proto.MachineData.prototype.setProductSku = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fp_line = 4;
 * @return {string}
 */
proto.proto.MachineData.prototype.getFpLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MachineData} returns this
 */
proto.proto.MachineData.prototype.setFpLine = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 start_time = 5;
 * @return {number}
 */
proto.proto.MachineData.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MachineData} returns this
 */
proto.proto.MachineData.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional ReasonCodeCategoryInfo reason_code_category_info = 6;
 * @return {?proto.proto.ReasonCodeCategoryInfo}
 */
proto.proto.MachineData.prototype.getReasonCodeCategoryInfo = function() {
  return /** @type{?proto.proto.ReasonCodeCategoryInfo} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeModel_pb.ReasonCodeCategoryInfo, 6));
};


/**
 * @param {?proto.proto.ReasonCodeCategoryInfo|undefined} value
 * @return {!proto.proto.MachineData} returns this
*/
proto.proto.MachineData.prototype.setReasonCodeCategoryInfo = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MachineData} returns this
 */
proto.proto.MachineData.prototype.clearReasonCodeCategoryInfo = function() {
  return this.setReasonCodeCategoryInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MachineData.prototype.hasReasonCodeCategoryInfo = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ReasonCodeFactorInfo reason_code_factor = 7;
 * @return {?proto.proto.ReasonCodeFactorInfo}
 */
proto.proto.MachineData.prototype.getReasonCodeFactor = function() {
  return /** @type{?proto.proto.ReasonCodeFactorInfo} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeModel_pb.ReasonCodeFactorInfo, 7));
};


/**
 * @param {?proto.proto.ReasonCodeFactorInfo|undefined} value
 * @return {!proto.proto.MachineData} returns this
*/
proto.proto.MachineData.prototype.setReasonCodeFactor = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MachineData} returns this
 */
proto.proto.MachineData.prototype.clearReasonCodeFactor = function() {
  return this.setReasonCodeFactor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MachineData.prototype.hasReasonCodeFactor = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ReasonCodeDetailsInfo reason_code_info = 8;
 * @return {?proto.proto.ReasonCodeDetailsInfo}
 */
proto.proto.MachineData.prototype.getReasonCodeInfo = function() {
  return /** @type{?proto.proto.ReasonCodeDetailsInfo} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeModel_pb.ReasonCodeDetailsInfo, 8));
};


/**
 * @param {?proto.proto.ReasonCodeDetailsInfo|undefined} value
 * @return {!proto.proto.MachineData} returns this
*/
proto.proto.MachineData.prototype.setReasonCodeInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MachineData} returns this
 */
proto.proto.MachineData.prototype.clearReasonCodeInfo = function() {
  return this.setReasonCodeInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MachineData.prototype.hasReasonCodeInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LogFilters.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LogFilters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LogFilters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LogFilters.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LogFilters}
 */
proto.proto.LogFilters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LogFilters;
  return proto.proto.LogFilters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LogFilters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LogFilters}
 */
proto.proto.LogFilters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LogFilters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LogFilters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LogFilters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LogFilters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FpLogs.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FpLogs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FpLogs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FpLogs.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FpLogs}
 */
proto.proto.FpLogs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FpLogs;
  return proto.proto.FpLogs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FpLogs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FpLogs}
 */
proto.proto.FpLogs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FpLogs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FpLogs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FpLogs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FpLogs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ProcessLine.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ProcessLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ProcessLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProcessLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    processLineId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    processLineName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    batchInfo: (f = msg.getBatchInfo()) && proto.proto.BatchInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ProcessLine}
 */
proto.proto.ProcessLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ProcessLine;
  return proto.proto.ProcessLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ProcessLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ProcessLine}
 */
proto.proto.ProcessLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProcessLineId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessLineName(value);
      break;
    case 3:
      var value = new proto.proto.BatchInfo;
      reader.readMessage(value,proto.proto.BatchInfo.deserializeBinaryFromReader);
      msg.setBatchInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ProcessLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ProcessLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ProcessLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProcessLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessLineId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProcessLineName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBatchInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.BatchInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 process_line_id = 1;
 * @return {number}
 */
proto.proto.ProcessLine.prototype.getProcessLineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ProcessLine} returns this
 */
proto.proto.ProcessLine.prototype.setProcessLineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string process_line_name = 2;
 * @return {string}
 */
proto.proto.ProcessLine.prototype.getProcessLineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ProcessLine} returns this
 */
proto.proto.ProcessLine.prototype.setProcessLineName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BatchInfo batch_info = 3;
 * @return {?proto.proto.BatchInfo}
 */
proto.proto.ProcessLine.prototype.getBatchInfo = function() {
  return /** @type{?proto.proto.BatchInfo} */ (
    jspb.Message.getWrapperField(this, proto.proto.BatchInfo, 3));
};


/**
 * @param {?proto.proto.BatchInfo|undefined} value
 * @return {!proto.proto.ProcessLine} returns this
*/
proto.proto.ProcessLine.prototype.setBatchInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ProcessLine} returns this
 */
proto.proto.ProcessLine.prototype.clearBatchInfo = function() {
  return this.setBatchInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ProcessLine.prototype.hasBatchInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BatchInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BatchInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BatchInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    processOrder: jspb.Message.getFieldWithDefault(msg, 2, 0),
    productSku: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BatchInfo}
 */
proto.proto.BatchInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BatchInfo;
  return proto.proto.BatchInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BatchInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BatchInfo}
 */
proto.proto.BatchInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBatchNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProcessOrder(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductSku(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BatchInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BatchInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BatchInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchNumber();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProcessOrder();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getProductSku();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 batch_number = 1;
 * @return {number}
 */
proto.proto.BatchInfo.prototype.getBatchNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.BatchInfo} returns this
 */
proto.proto.BatchInfo.prototype.setBatchNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 process_order = 2;
 * @return {number}
 */
proto.proto.BatchInfo.prototype.getProcessOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.BatchInfo} returns this
 */
proto.proto.BatchInfo.prototype.setProcessOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string product_sku = 3;
 * @return {string}
 */
proto.proto.BatchInfo.prototype.getProductSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.BatchInfo} returns this
 */
proto.proto.BatchInfo.prototype.setProductSku = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MachineWiseRateMatrixCount.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MachineWiseRateMatrixCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MachineWiseRateMatrixCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineWiseRateMatrixCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalSpeedPpm: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    totalSpeedPph: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    rejectSpeedPpm: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    goodSpeedPph: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    goodSpeedPpm: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    inSpeedPph: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    inSpeedPpm: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    machineInfo: (f = msg.getMachineInfo()) && proto.proto.MachineInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MachineWiseRateMatrixCount}
 */
proto.proto.MachineWiseRateMatrixCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MachineWiseRateMatrixCount;
  return proto.proto.MachineWiseRateMatrixCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MachineWiseRateMatrixCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MachineWiseRateMatrixCount}
 */
proto.proto.MachineWiseRateMatrixCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalSpeedPpm(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalSpeedPph(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRejectSpeedPpm(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGoodSpeedPph(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGoodSpeedPpm(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInSpeedPph(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInSpeedPpm(value);
      break;
    case 8:
      var value = new proto.proto.MachineInfo;
      reader.readMessage(value,proto.proto.MachineInfo.deserializeBinaryFromReader);
      msg.setMachineInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MachineWiseRateMatrixCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MachineWiseRateMatrixCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MachineWiseRateMatrixCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineWiseRateMatrixCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalSpeedPpm();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTotalSpeedPph();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getRejectSpeedPpm();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getGoodSpeedPph();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getGoodSpeedPpm();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getInSpeedPph();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getInSpeedPpm();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getMachineInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.proto.MachineInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional double total_speed_ppm = 1;
 * @return {number}
 */
proto.proto.MachineWiseRateMatrixCount.prototype.getTotalSpeedPpm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MachineWiseRateMatrixCount} returns this
 */
proto.proto.MachineWiseRateMatrixCount.prototype.setTotalSpeedPpm = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double total_speed_pph = 2;
 * @return {number}
 */
proto.proto.MachineWiseRateMatrixCount.prototype.getTotalSpeedPph = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MachineWiseRateMatrixCount} returns this
 */
proto.proto.MachineWiseRateMatrixCount.prototype.setTotalSpeedPph = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double reject_speed_ppm = 3;
 * @return {number}
 */
proto.proto.MachineWiseRateMatrixCount.prototype.getRejectSpeedPpm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MachineWiseRateMatrixCount} returns this
 */
proto.proto.MachineWiseRateMatrixCount.prototype.setRejectSpeedPpm = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double good_speed_pph = 4;
 * @return {number}
 */
proto.proto.MachineWiseRateMatrixCount.prototype.getGoodSpeedPph = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MachineWiseRateMatrixCount} returns this
 */
proto.proto.MachineWiseRateMatrixCount.prototype.setGoodSpeedPph = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double good_speed_ppm = 5;
 * @return {number}
 */
proto.proto.MachineWiseRateMatrixCount.prototype.getGoodSpeedPpm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MachineWiseRateMatrixCount} returns this
 */
proto.proto.MachineWiseRateMatrixCount.prototype.setGoodSpeedPpm = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double in_speed_pph = 6;
 * @return {number}
 */
proto.proto.MachineWiseRateMatrixCount.prototype.getInSpeedPph = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MachineWiseRateMatrixCount} returns this
 */
proto.proto.MachineWiseRateMatrixCount.prototype.setInSpeedPph = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double in_speed_ppm = 7;
 * @return {number}
 */
proto.proto.MachineWiseRateMatrixCount.prototype.getInSpeedPpm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MachineWiseRateMatrixCount} returns this
 */
proto.proto.MachineWiseRateMatrixCount.prototype.setInSpeedPpm = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional MachineInfo machine_info = 8;
 * @return {?proto.proto.MachineInfo}
 */
proto.proto.MachineWiseRateMatrixCount.prototype.getMachineInfo = function() {
  return /** @type{?proto.proto.MachineInfo} */ (
    jspb.Message.getWrapperField(this, proto.proto.MachineInfo, 8));
};


/**
 * @param {?proto.proto.MachineInfo|undefined} value
 * @return {!proto.proto.MachineWiseRateMatrixCount} returns this
*/
proto.proto.MachineWiseRateMatrixCount.prototype.setMachineInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MachineWiseRateMatrixCount} returns this
 */
proto.proto.MachineWiseRateMatrixCount.prototype.clearMachineInfo = function() {
  return this.setMachineInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MachineWiseRateMatrixCount.prototype.hasMachineInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MachineStatusInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MachineStatusInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MachineStatusInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineStatusInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    machineStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MachineStatusInfo}
 */
proto.proto.MachineStatusInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MachineStatusInfo;
  return proto.proto.MachineStatusInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MachineStatusInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MachineStatusInfo}
 */
proto.proto.MachineStatusInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMachineId(value);
      break;
    case 2:
      var value = /** @type {!proto.proto.MachineStatus} */ (reader.readEnum());
      msg.setMachineStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MachineStatusInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MachineStatusInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MachineStatusInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineStatusInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMachineStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 machine_id = 1;
 * @return {number}
 */
proto.proto.MachineStatusInfo.prototype.getMachineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MachineStatusInfo} returns this
 */
proto.proto.MachineStatusInfo.prototype.setMachineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional MachineStatus machine_status = 2;
 * @return {!proto.proto.MachineStatus}
 */
proto.proto.MachineStatusInfo.prototype.getMachineStatus = function() {
  return /** @type {!proto.proto.MachineStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.proto.MachineStatus} value
 * @return {!proto.proto.MachineStatusInfo} returns this
 */
proto.proto.MachineStatusInfo.prototype.setMachineStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


goog.object.extend(exports, proto.proto);
