// source: service/ReasonCodeManagementService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var model_ReasonCodeModel_pb = require('../model/ReasonCodeModel_pb.js');
goog.object.extend(proto, model_ReasonCodeModel_pb);
var common_Commons_pb = require('../common/Commons_pb.js');
goog.object.extend(proto, common_Commons_pb);
var common_ReasonCodeDetails_pb = require('../common/ReasonCodeDetails_pb.js');
goog.object.extend(proto, common_ReasonCodeDetails_pb);
var enums_ReasonCodeEnum_pb = require('../enums/ReasonCodeEnum_pb.js');
goog.object.extend(proto, enums_ReasonCodeEnum_pb);
goog.exportSymbol('proto.proto.AMDReasonCodeDetailRequest', null, global);
goog.exportSymbol('proto.proto.AMDReasonCodeDetailResponse', null, global);
goog.exportSymbol('proto.proto.AmdCsvResponse', null, global);
goog.exportSymbol('proto.proto.AssignNewCodeRequest', null, global);
goog.exportSymbol('proto.proto.AssignedCodeRequest', null, global);
goog.exportSymbol('proto.proto.AssignedCodeResponse', null, global);
goog.exportSymbol('proto.proto.DeleteLogRequest', null, global);
goog.exportSymbol('proto.proto.EndDowntimeRequest', null, global);
goog.exportSymbol('proto.proto.ReasonCodeInfoRequest', null, global);
goog.exportSymbol('proto.proto.ReasonCodeInfoResponse', null, global);
goog.exportSymbol('proto.proto.ReasonCodeManagementRequest', null, global);
goog.exportSymbol('proto.proto.ReasonCodeManagementResponse', null, global);
goog.exportSymbol('proto.proto.ResourcesViewRequest', null, global);
goog.exportSymbol('proto.proto.ResourcesViewResponse', null, global);
goog.exportSymbol('proto.proto.UpsertReasonCodeLogsRequest', null, global);
goog.exportSymbol('proto.proto.UpsertReasonCodeRequest', null, global);
goog.exportSymbol('proto.proto.UpsertReasonCodeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReasonCodeManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeManagementRequest.displayName = 'proto.proto.ReasonCodeManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReasonCodeManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeManagementResponse.displayName = 'proto.proto.ReasonCodeManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ResourcesViewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ResourcesViewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ResourcesViewRequest.displayName = 'proto.proto.ResourcesViewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ResourcesViewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ResourcesViewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ResourcesViewResponse.displayName = 'proto.proto.ResourcesViewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AssignedCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AssignedCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AssignedCodeRequest.displayName = 'proto.proto.AssignedCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AssignedCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AssignedCodeResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.AssignedCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AssignedCodeResponse.displayName = 'proto.proto.AssignedCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AssignNewCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AssignNewCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AssignNewCodeRequest.displayName = 'proto.proto.AssignNewCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AMDReasonCodeDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AMDReasonCodeDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AMDReasonCodeDetailRequest.displayName = 'proto.proto.AMDReasonCodeDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AMDReasonCodeDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AMDReasonCodeDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.AMDReasonCodeDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AMDReasonCodeDetailResponse.displayName = 'proto.proto.AMDReasonCodeDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpsertReasonCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpsertReasonCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpsertReasonCodeRequest.displayName = 'proto.proto.UpsertReasonCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpsertReasonCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpsertReasonCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpsertReasonCodeResponse.displayName = 'proto.proto.UpsertReasonCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReasonCodeInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeInfoRequest.displayName = 'proto.proto.ReasonCodeInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReasonCodeInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeInfoResponse.displayName = 'proto.proto.ReasonCodeInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.EndDowntimeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.EndDowntimeRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.EndDowntimeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.EndDowntimeRequest.displayName = 'proto.proto.EndDowntimeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpsertReasonCodeLogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpsertReasonCodeLogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpsertReasonCodeLogsRequest.displayName = 'proto.proto.UpsertReasonCodeLogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DeleteLogRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.DeleteLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteLogRequest.displayName = 'proto.proto.DeleteLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AmdCsvResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AmdCsvResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AmdCsvResponse.displayName = 'proto.proto.AmdCsvResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonCodeData: (f = msg.getReasonCodeData()) && model_ReasonCodeModel_pb.ReasonCodeData.toObject(includeInstance, f),
    generalRequest: (f = msg.getGeneralRequest()) && common_Commons_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeManagementRequest}
 */
proto.proto.ReasonCodeManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeManagementRequest;
  return proto.proto.ReasonCodeManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeManagementRequest}
 */
proto.proto.ReasonCodeManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new model_ReasonCodeModel_pb.ReasonCodeData;
      reader.readMessage(value,model_ReasonCodeModel_pb.ReasonCodeData.deserializeBinaryFromReader);
      msg.setReasonCodeData(value);
      break;
    case 2:
      var value = new common_Commons_pb.GeneralRequest;
      reader.readMessage(value,common_Commons_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonCodeData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      model_ReasonCodeModel_pb.ReasonCodeData.serializeBinaryToWriter
    );
  }
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_Commons_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReasonCodeData reason_code_data = 1;
 * @return {?proto.proto.ReasonCodeData}
 */
proto.proto.ReasonCodeManagementRequest.prototype.getReasonCodeData = function() {
  return /** @type{?proto.proto.ReasonCodeData} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeModel_pb.ReasonCodeData, 1));
};


/**
 * @param {?proto.proto.ReasonCodeData|undefined} value
 * @return {!proto.proto.ReasonCodeManagementRequest} returns this
*/
proto.proto.ReasonCodeManagementRequest.prototype.setReasonCodeData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeManagementRequest} returns this
 */
proto.proto.ReasonCodeManagementRequest.prototype.clearReasonCodeData = function() {
  return this.setReasonCodeData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeManagementRequest.prototype.hasReasonCodeData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GeneralRequest general_request = 2;
 * @return {?proto.proto.GeneralRequest}
 */
proto.proto.ReasonCodeManagementRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.proto.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralRequest, 2));
};


/**
 * @param {?proto.proto.GeneralRequest|undefined} value
 * @return {!proto.proto.ReasonCodeManagementRequest} returns this
*/
proto.proto.ReasonCodeManagementRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeManagementRequest} returns this
 */
proto.proto.ReasonCodeManagementRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeManagementRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonCodeData: (f = msg.getReasonCodeData()) && model_ReasonCodeModel_pb.ReasonCodeData.toObject(includeInstance, f),
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    reasonCodeCategoryData: (f = msg.getReasonCodeCategoryData()) && model_ReasonCodeModel_pb.ReasonCodeCategoryData.toObject(includeInstance, f),
    reasonCodeCategoryListData: (f = msg.getReasonCodeCategoryListData()) && model_ReasonCodeModel_pb.ReasonCodeCategoryListData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeManagementResponse}
 */
proto.proto.ReasonCodeManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeManagementResponse;
  return proto.proto.ReasonCodeManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeManagementResponse}
 */
proto.proto.ReasonCodeManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new model_ReasonCodeModel_pb.ReasonCodeData;
      reader.readMessage(value,model_ReasonCodeModel_pb.ReasonCodeData.deserializeBinaryFromReader);
      msg.setReasonCodeData(value);
      break;
    case 2:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 3:
      var value = new model_ReasonCodeModel_pb.ReasonCodeCategoryData;
      reader.readMessage(value,model_ReasonCodeModel_pb.ReasonCodeCategoryData.deserializeBinaryFromReader);
      msg.setReasonCodeCategoryData(value);
      break;
    case 4:
      var value = new model_ReasonCodeModel_pb.ReasonCodeCategoryListData;
      reader.readMessage(value,model_ReasonCodeModel_pb.ReasonCodeCategoryListData.deserializeBinaryFromReader);
      msg.setReasonCodeCategoryListData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonCodeData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      model_ReasonCodeModel_pb.ReasonCodeData.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeCategoryData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      model_ReasonCodeModel_pb.ReasonCodeCategoryData.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeCategoryListData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      model_ReasonCodeModel_pb.ReasonCodeCategoryListData.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReasonCodeData reason_code_data = 1;
 * @return {?proto.proto.ReasonCodeData}
 */
proto.proto.ReasonCodeManagementResponse.prototype.getReasonCodeData = function() {
  return /** @type{?proto.proto.ReasonCodeData} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeModel_pb.ReasonCodeData, 1));
};


/**
 * @param {?proto.proto.ReasonCodeData|undefined} value
 * @return {!proto.proto.ReasonCodeManagementResponse} returns this
*/
proto.proto.ReasonCodeManagementResponse.prototype.setReasonCodeData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeManagementResponse} returns this
 */
proto.proto.ReasonCodeManagementResponse.prototype.clearReasonCodeData = function() {
  return this.setReasonCodeData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeManagementResponse.prototype.hasReasonCodeData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GeneralResponse general_response = 2;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.ReasonCodeManagementResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 2));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.ReasonCodeManagementResponse} returns this
*/
proto.proto.ReasonCodeManagementResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeManagementResponse} returns this
 */
proto.proto.ReasonCodeManagementResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeManagementResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ReasonCodeCategoryData reason_code_category_data = 3;
 * @return {?proto.proto.ReasonCodeCategoryData}
 */
proto.proto.ReasonCodeManagementResponse.prototype.getReasonCodeCategoryData = function() {
  return /** @type{?proto.proto.ReasonCodeCategoryData} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeModel_pb.ReasonCodeCategoryData, 3));
};


/**
 * @param {?proto.proto.ReasonCodeCategoryData|undefined} value
 * @return {!proto.proto.ReasonCodeManagementResponse} returns this
*/
proto.proto.ReasonCodeManagementResponse.prototype.setReasonCodeCategoryData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeManagementResponse} returns this
 */
proto.proto.ReasonCodeManagementResponse.prototype.clearReasonCodeCategoryData = function() {
  return this.setReasonCodeCategoryData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeManagementResponse.prototype.hasReasonCodeCategoryData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ReasonCodeCategoryListData reason_code_category_list_data = 4;
 * @return {?proto.proto.ReasonCodeCategoryListData}
 */
proto.proto.ReasonCodeManagementResponse.prototype.getReasonCodeCategoryListData = function() {
  return /** @type{?proto.proto.ReasonCodeCategoryListData} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeModel_pb.ReasonCodeCategoryListData, 4));
};


/**
 * @param {?proto.proto.ReasonCodeCategoryListData|undefined} value
 * @return {!proto.proto.ReasonCodeManagementResponse} returns this
*/
proto.proto.ReasonCodeManagementResponse.prototype.setReasonCodeCategoryListData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeManagementResponse} returns this
 */
proto.proto.ReasonCodeManagementResponse.prototype.clearReasonCodeCategoryListData = function() {
  return this.setReasonCodeCategoryListData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeManagementResponse.prototype.hasReasonCodeCategoryListData = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ResourcesViewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ResourcesViewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ResourcesViewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ResourcesViewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalRequest: (f = msg.getGeneralRequest()) && common_Commons_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ResourcesViewRequest}
 */
proto.proto.ResourcesViewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ResourcesViewRequest;
  return proto.proto.ResourcesViewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ResourcesViewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ResourcesViewRequest}
 */
proto.proto.ResourcesViewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralRequest;
      reader.readMessage(value,common_Commons_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ResourcesViewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ResourcesViewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ResourcesViewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ResourcesViewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralRequest general_request = 1;
 * @return {?proto.proto.GeneralRequest}
 */
proto.proto.ResourcesViewRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.proto.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralRequest, 1));
};


/**
 * @param {?proto.proto.GeneralRequest|undefined} value
 * @return {!proto.proto.ResourcesViewRequest} returns this
*/
proto.proto.ResourcesViewRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ResourcesViewRequest} returns this
 */
proto.proto.ResourcesViewRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ResourcesViewRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ResourcesViewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ResourcesViewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ResourcesViewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ResourcesViewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    resourceViewInfo: (f = msg.getResourceViewInfo()) && model_ReasonCodeModel_pb.ResourceViewInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ResourcesViewResponse}
 */
proto.proto.ResourcesViewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ResourcesViewResponse;
  return proto.proto.ResourcesViewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ResourcesViewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ResourcesViewResponse}
 */
proto.proto.ResourcesViewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = new model_ReasonCodeModel_pb.ResourceViewInfo;
      reader.readMessage(value,model_ReasonCodeModel_pb.ResourceViewInfo.deserializeBinaryFromReader);
      msg.setResourceViewInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ResourcesViewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ResourcesViewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ResourcesViewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ResourcesViewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getResourceViewInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      model_ReasonCodeModel_pb.ResourceViewInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.ResourcesViewResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.ResourcesViewResponse} returns this
*/
proto.proto.ResourcesViewResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ResourcesViewResponse} returns this
 */
proto.proto.ResourcesViewResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ResourcesViewResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResourceViewInfo resource_view_info = 2;
 * @return {?proto.proto.ResourceViewInfo}
 */
proto.proto.ResourcesViewResponse.prototype.getResourceViewInfo = function() {
  return /** @type{?proto.proto.ResourceViewInfo} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeModel_pb.ResourceViewInfo, 2));
};


/**
 * @param {?proto.proto.ResourceViewInfo|undefined} value
 * @return {!proto.proto.ResourcesViewResponse} returns this
*/
proto.proto.ResourcesViewResponse.prototype.setResourceViewInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ResourcesViewResponse} returns this
 */
proto.proto.ResourcesViewResponse.prototype.clearResourceViewInfo = function() {
  return this.setResourceViewInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ResourcesViewResponse.prototype.hasResourceViewInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AssignedCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AssignedCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AssignedCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AssignedCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalRequest: (f = msg.getGeneralRequest()) && common_Commons_pb.GeneralRequest.toObject(includeInstance, f),
    resourceData: (f = msg.getResourceData()) && model_ReasonCodeModel_pb.ResourceData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AssignedCodeRequest}
 */
proto.proto.AssignedCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AssignedCodeRequest;
  return proto.proto.AssignedCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AssignedCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AssignedCodeRequest}
 */
proto.proto.AssignedCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralRequest;
      reader.readMessage(value,common_Commons_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    case 2:
      var value = new model_ReasonCodeModel_pb.ResourceData;
      reader.readMessage(value,model_ReasonCodeModel_pb.ResourceData.deserializeBinaryFromReader);
      msg.setResourceData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AssignedCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AssignedCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AssignedCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AssignedCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
  f = message.getResourceData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      model_ReasonCodeModel_pb.ResourceData.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralRequest general_request = 1;
 * @return {?proto.proto.GeneralRequest}
 */
proto.proto.AssignedCodeRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.proto.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralRequest, 1));
};


/**
 * @param {?proto.proto.GeneralRequest|undefined} value
 * @return {!proto.proto.AssignedCodeRequest} returns this
*/
proto.proto.AssignedCodeRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AssignedCodeRequest} returns this
 */
proto.proto.AssignedCodeRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AssignedCodeRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResourceData resource_data = 2;
 * @return {?proto.proto.ResourceData}
 */
proto.proto.AssignedCodeRequest.prototype.getResourceData = function() {
  return /** @type{?proto.proto.ResourceData} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeModel_pb.ResourceData, 2));
};


/**
 * @param {?proto.proto.ResourceData|undefined} value
 * @return {!proto.proto.AssignedCodeRequest} returns this
*/
proto.proto.AssignedCodeRequest.prototype.setResourceData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AssignedCodeRequest} returns this
 */
proto.proto.AssignedCodeRequest.prototype.clearResourceData = function() {
  return this.setResourceData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AssignedCodeRequest.prototype.hasResourceData = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AssignedCodeResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AssignedCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AssignedCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AssignedCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AssignedCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    reasonCodeInfoList: jspb.Message.toObjectList(msg.getReasonCodeInfoList(),
    model_ReasonCodeModel_pb.ReasonCodeDetailsInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AssignedCodeResponse}
 */
proto.proto.AssignedCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AssignedCodeResponse;
  return proto.proto.AssignedCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AssignedCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AssignedCodeResponse}
 */
proto.proto.AssignedCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = new model_ReasonCodeModel_pb.ReasonCodeDetailsInfo;
      reader.readMessage(value,model_ReasonCodeModel_pb.ReasonCodeDetailsInfo.deserializeBinaryFromReader);
      msg.addReasonCodeInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AssignedCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AssignedCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AssignedCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AssignedCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      model_ReasonCodeModel_pb.ReasonCodeDetailsInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.AssignedCodeResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.AssignedCodeResponse} returns this
*/
proto.proto.AssignedCodeResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AssignedCodeResponse} returns this
 */
proto.proto.AssignedCodeResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AssignedCodeResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ReasonCodeDetailsInfo reason_code_info = 2;
 * @return {!Array<!proto.proto.ReasonCodeDetailsInfo>}
 */
proto.proto.AssignedCodeResponse.prototype.getReasonCodeInfoList = function() {
  return /** @type{!Array<!proto.proto.ReasonCodeDetailsInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_ReasonCodeModel_pb.ReasonCodeDetailsInfo, 2));
};


/**
 * @param {!Array<!proto.proto.ReasonCodeDetailsInfo>} value
 * @return {!proto.proto.AssignedCodeResponse} returns this
*/
proto.proto.AssignedCodeResponse.prototype.setReasonCodeInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.ReasonCodeDetailsInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ReasonCodeDetailsInfo}
 */
proto.proto.AssignedCodeResponse.prototype.addReasonCodeInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.ReasonCodeDetailsInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AssignedCodeResponse} returns this
 */
proto.proto.AssignedCodeResponse.prototype.clearReasonCodeInfoList = function() {
  return this.setReasonCodeInfoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AssignNewCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AssignNewCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AssignNewCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AssignNewCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalRequest: (f = msg.getGeneralRequest()) && common_Commons_pb.GeneralRequest.toObject(includeInstance, f),
    reasonCodeUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    equipmentId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    equipmentName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    equipmentDesc: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AssignNewCodeRequest}
 */
proto.proto.AssignNewCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AssignNewCodeRequest;
  return proto.proto.AssignNewCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AssignNewCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AssignNewCodeRequest}
 */
proto.proto.AssignNewCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralRequest;
      reader.readMessage(value,common_Commons_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCodeUuid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEquipmentId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipmentName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipmentDesc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AssignNewCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AssignNewCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AssignNewCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AssignNewCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEquipmentId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEquipmentName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEquipmentDesc();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional GeneralRequest general_request = 1;
 * @return {?proto.proto.GeneralRequest}
 */
proto.proto.AssignNewCodeRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.proto.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralRequest, 1));
};


/**
 * @param {?proto.proto.GeneralRequest|undefined} value
 * @return {!proto.proto.AssignNewCodeRequest} returns this
*/
proto.proto.AssignNewCodeRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AssignNewCodeRequest} returns this
 */
proto.proto.AssignNewCodeRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AssignNewCodeRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string reason_code_uuid = 2;
 * @return {string}
 */
proto.proto.AssignNewCodeRequest.prototype.getReasonCodeUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AssignNewCodeRequest} returns this
 */
proto.proto.AssignNewCodeRequest.prototype.setReasonCodeUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 equipment_id = 3;
 * @return {number}
 */
proto.proto.AssignNewCodeRequest.prototype.getEquipmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AssignNewCodeRequest} returns this
 */
proto.proto.AssignNewCodeRequest.prototype.setEquipmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string equipment_name = 4;
 * @return {string}
 */
proto.proto.AssignNewCodeRequest.prototype.getEquipmentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AssignNewCodeRequest} returns this
 */
proto.proto.AssignNewCodeRequest.prototype.setEquipmentName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string equipment_desc = 5;
 * @return {string}
 */
proto.proto.AssignNewCodeRequest.prototype.getEquipmentDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AssignNewCodeRequest} returns this
 */
proto.proto.AssignNewCodeRequest.prototype.setEquipmentDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AMDReasonCodeDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AMDReasonCodeDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AMDReasonCodeDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AMDReasonCodeDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalRequest: (f = msg.getGeneralRequest()) && common_Commons_pb.GeneralRequest.toObject(includeInstance, f),
    amdFilter: (f = msg.getAmdFilter()) && model_ReasonCodeModel_pb.AMDFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AMDReasonCodeDetailRequest}
 */
proto.proto.AMDReasonCodeDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AMDReasonCodeDetailRequest;
  return proto.proto.AMDReasonCodeDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AMDReasonCodeDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AMDReasonCodeDetailRequest}
 */
proto.proto.AMDReasonCodeDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralRequest;
      reader.readMessage(value,common_Commons_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    case 2:
      var value = new model_ReasonCodeModel_pb.AMDFilter;
      reader.readMessage(value,model_ReasonCodeModel_pb.AMDFilter.deserializeBinaryFromReader);
      msg.setAmdFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AMDReasonCodeDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AMDReasonCodeDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AMDReasonCodeDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AMDReasonCodeDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
  f = message.getAmdFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      model_ReasonCodeModel_pb.AMDFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralRequest general_request = 1;
 * @return {?proto.proto.GeneralRequest}
 */
proto.proto.AMDReasonCodeDetailRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.proto.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralRequest, 1));
};


/**
 * @param {?proto.proto.GeneralRequest|undefined} value
 * @return {!proto.proto.AMDReasonCodeDetailRequest} returns this
*/
proto.proto.AMDReasonCodeDetailRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AMDReasonCodeDetailRequest} returns this
 */
proto.proto.AMDReasonCodeDetailRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AMDReasonCodeDetailRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AMDFilter amd_filter = 2;
 * @return {?proto.proto.AMDFilter}
 */
proto.proto.AMDReasonCodeDetailRequest.prototype.getAmdFilter = function() {
  return /** @type{?proto.proto.AMDFilter} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeModel_pb.AMDFilter, 2));
};


/**
 * @param {?proto.proto.AMDFilter|undefined} value
 * @return {!proto.proto.AMDReasonCodeDetailRequest} returns this
*/
proto.proto.AMDReasonCodeDetailRequest.prototype.setAmdFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AMDReasonCodeDetailRequest} returns this
 */
proto.proto.AMDReasonCodeDetailRequest.prototype.clearAmdFilter = function() {
  return this.setAmdFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AMDReasonCodeDetailRequest.prototype.hasAmdFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AMDReasonCodeDetailResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AMDReasonCodeDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AMDReasonCodeDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AMDReasonCodeDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AMDReasonCodeDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonCodeLogInfoList: jspb.Message.toObjectList(msg.getReasonCodeLogInfoList(),
    model_ReasonCodeModel_pb.ReasonCodeLogInfo.toObject, includeInstance),
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AMDReasonCodeDetailResponse}
 */
proto.proto.AMDReasonCodeDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AMDReasonCodeDetailResponse;
  return proto.proto.AMDReasonCodeDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AMDReasonCodeDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AMDReasonCodeDetailResponse}
 */
proto.proto.AMDReasonCodeDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new model_ReasonCodeModel_pb.ReasonCodeLogInfo;
      reader.readMessage(value,model_ReasonCodeModel_pb.ReasonCodeLogInfo.deserializeBinaryFromReader);
      msg.addReasonCodeLogInfo(value);
      break;
    case 2:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AMDReasonCodeDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AMDReasonCodeDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AMDReasonCodeDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AMDReasonCodeDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonCodeLogInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      model_ReasonCodeModel_pb.ReasonCodeLogInfo.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReasonCodeLogInfo reason_code_log_info = 1;
 * @return {!Array<!proto.proto.ReasonCodeLogInfo>}
 */
proto.proto.AMDReasonCodeDetailResponse.prototype.getReasonCodeLogInfoList = function() {
  return /** @type{!Array<!proto.proto.ReasonCodeLogInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_ReasonCodeModel_pb.ReasonCodeLogInfo, 1));
};


/**
 * @param {!Array<!proto.proto.ReasonCodeLogInfo>} value
 * @return {!proto.proto.AMDReasonCodeDetailResponse} returns this
*/
proto.proto.AMDReasonCodeDetailResponse.prototype.setReasonCodeLogInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.ReasonCodeLogInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ReasonCodeLogInfo}
 */
proto.proto.AMDReasonCodeDetailResponse.prototype.addReasonCodeLogInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.ReasonCodeLogInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AMDReasonCodeDetailResponse} returns this
 */
proto.proto.AMDReasonCodeDetailResponse.prototype.clearReasonCodeLogInfoList = function() {
  return this.setReasonCodeLogInfoList([]);
};


/**
 * optional GeneralResponse general_response = 2;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.AMDReasonCodeDetailResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 2));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.AMDReasonCodeDetailResponse} returns this
*/
proto.proto.AMDReasonCodeDetailResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AMDReasonCodeDetailResponse} returns this
 */
proto.proto.AMDReasonCodeDetailResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AMDReasonCodeDetailResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpsertReasonCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpsertReasonCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpsertReasonCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpsertReasonCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalRequest: (f = msg.getGeneralRequest()) && common_Commons_pb.GeneralRequest.toObject(includeInstance, f),
    reasonCodeBatchDetails: (f = msg.getReasonCodeBatchDetails()) && model_ReasonCodeModel_pb.ReasonCodeBatchDetails.toObject(includeInstance, f),
    operationInfo: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpsertReasonCodeRequest}
 */
proto.proto.UpsertReasonCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpsertReasonCodeRequest;
  return proto.proto.UpsertReasonCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpsertReasonCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpsertReasonCodeRequest}
 */
proto.proto.UpsertReasonCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralRequest;
      reader.readMessage(value,common_Commons_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    case 2:
      var value = new model_ReasonCodeModel_pb.ReasonCodeBatchDetails;
      reader.readMessage(value,model_ReasonCodeModel_pb.ReasonCodeBatchDetails.deserializeBinaryFromReader);
      msg.setReasonCodeBatchDetails(value);
      break;
    case 3:
      var value = /** @type {!proto.proto.OperationInfo} */ (reader.readEnum());
      msg.setOperationInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpsertReasonCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpsertReasonCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpsertReasonCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpsertReasonCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeBatchDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      model_ReasonCodeModel_pb.ReasonCodeBatchDetails.serializeBinaryToWriter
    );
  }
  f = message.getOperationInfo();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional GeneralRequest general_request = 1;
 * @return {?proto.proto.GeneralRequest}
 */
proto.proto.UpsertReasonCodeRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.proto.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralRequest, 1));
};


/**
 * @param {?proto.proto.GeneralRequest|undefined} value
 * @return {!proto.proto.UpsertReasonCodeRequest} returns this
*/
proto.proto.UpsertReasonCodeRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UpsertReasonCodeRequest} returns this
 */
proto.proto.UpsertReasonCodeRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UpsertReasonCodeRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ReasonCodeBatchDetails reason_code_batch_details = 2;
 * @return {?proto.proto.ReasonCodeBatchDetails}
 */
proto.proto.UpsertReasonCodeRequest.prototype.getReasonCodeBatchDetails = function() {
  return /** @type{?proto.proto.ReasonCodeBatchDetails} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeModel_pb.ReasonCodeBatchDetails, 2));
};


/**
 * @param {?proto.proto.ReasonCodeBatchDetails|undefined} value
 * @return {!proto.proto.UpsertReasonCodeRequest} returns this
*/
proto.proto.UpsertReasonCodeRequest.prototype.setReasonCodeBatchDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UpsertReasonCodeRequest} returns this
 */
proto.proto.UpsertReasonCodeRequest.prototype.clearReasonCodeBatchDetails = function() {
  return this.setReasonCodeBatchDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UpsertReasonCodeRequest.prototype.hasReasonCodeBatchDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional OperationInfo operation_info = 3;
 * @return {!proto.proto.OperationInfo}
 */
proto.proto.UpsertReasonCodeRequest.prototype.getOperationInfo = function() {
  return /** @type {!proto.proto.OperationInfo} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.proto.OperationInfo} value
 * @return {!proto.proto.UpsertReasonCodeRequest} returns this
 */
proto.proto.UpsertReasonCodeRequest.prototype.setOperationInfo = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpsertReasonCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpsertReasonCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpsertReasonCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpsertReasonCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpsertReasonCodeResponse}
 */
proto.proto.UpsertReasonCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpsertReasonCodeResponse;
  return proto.proto.UpsertReasonCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpsertReasonCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpsertReasonCodeResponse}
 */
proto.proto.UpsertReasonCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpsertReasonCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpsertReasonCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpsertReasonCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpsertReasonCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.UpsertReasonCodeResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.UpsertReasonCodeResponse} returns this
*/
proto.proto.UpsertReasonCodeResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UpsertReasonCodeResponse} returns this
 */
proto.proto.UpsertReasonCodeResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UpsertReasonCodeResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonCode: (f = msg.getReasonCode()) && common_ReasonCodeDetails_pb.ReasonCode.toObject(includeInstance, f),
    generalRequest: (f = msg.getGeneralRequest()) && common_Commons_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeInfoRequest}
 */
proto.proto.ReasonCodeInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeInfoRequest;
  return proto.proto.ReasonCodeInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeInfoRequest}
 */
proto.proto.ReasonCodeInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_ReasonCodeDetails_pb.ReasonCode;
      reader.readMessage(value,common_ReasonCodeDetails_pb.ReasonCode.deserializeBinaryFromReader);
      msg.setReasonCode(value);
      break;
    case 2:
      var value = new common_Commons_pb.GeneralRequest;
      reader.readMessage(value,common_Commons_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonCode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_ReasonCodeDetails_pb.ReasonCode.serializeBinaryToWriter
    );
  }
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_Commons_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReasonCode reason_code = 1;
 * @return {?proto.proto.ReasonCode}
 */
proto.proto.ReasonCodeInfoRequest.prototype.getReasonCode = function() {
  return /** @type{?proto.proto.ReasonCode} */ (
    jspb.Message.getWrapperField(this, common_ReasonCodeDetails_pb.ReasonCode, 1));
};


/**
 * @param {?proto.proto.ReasonCode|undefined} value
 * @return {!proto.proto.ReasonCodeInfoRequest} returns this
*/
proto.proto.ReasonCodeInfoRequest.prototype.setReasonCode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeInfoRequest} returns this
 */
proto.proto.ReasonCodeInfoRequest.prototype.clearReasonCode = function() {
  return this.setReasonCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeInfoRequest.prototype.hasReasonCode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GeneralRequest general_request = 2;
 * @return {?proto.proto.GeneralRequest}
 */
proto.proto.ReasonCodeInfoRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.proto.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralRequest, 2));
};


/**
 * @param {?proto.proto.GeneralRequest|undefined} value
 * @return {!proto.proto.ReasonCodeInfoRequest} returns this
*/
proto.proto.ReasonCodeInfoRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeInfoRequest} returns this
 */
proto.proto.ReasonCodeInfoRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeInfoRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonCodeBatchDetails: (f = msg.getReasonCodeBatchDetails()) && model_ReasonCodeModel_pb.ReasonCodeBatchDetails.toObject(includeInstance, f),
    reasonCodeCategoryData: (f = msg.getReasonCodeCategoryData()) && model_ReasonCodeModel_pb.ReasonCodeCategoryData.toObject(includeInstance, f),
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeInfoResponse}
 */
proto.proto.ReasonCodeInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeInfoResponse;
  return proto.proto.ReasonCodeInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeInfoResponse}
 */
proto.proto.ReasonCodeInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new model_ReasonCodeModel_pb.ReasonCodeBatchDetails;
      reader.readMessage(value,model_ReasonCodeModel_pb.ReasonCodeBatchDetails.deserializeBinaryFromReader);
      msg.setReasonCodeBatchDetails(value);
      break;
    case 2:
      var value = new model_ReasonCodeModel_pb.ReasonCodeCategoryData;
      reader.readMessage(value,model_ReasonCodeModel_pb.ReasonCodeCategoryData.deserializeBinaryFromReader);
      msg.setReasonCodeCategoryData(value);
      break;
    case 3:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonCodeBatchDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      model_ReasonCodeModel_pb.ReasonCodeBatchDetails.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeCategoryData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      model_ReasonCodeModel_pb.ReasonCodeCategoryData.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReasonCodeBatchDetails reason_code_batch_details = 1;
 * @return {?proto.proto.ReasonCodeBatchDetails}
 */
proto.proto.ReasonCodeInfoResponse.prototype.getReasonCodeBatchDetails = function() {
  return /** @type{?proto.proto.ReasonCodeBatchDetails} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeModel_pb.ReasonCodeBatchDetails, 1));
};


/**
 * @param {?proto.proto.ReasonCodeBatchDetails|undefined} value
 * @return {!proto.proto.ReasonCodeInfoResponse} returns this
*/
proto.proto.ReasonCodeInfoResponse.prototype.setReasonCodeBatchDetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeInfoResponse} returns this
 */
proto.proto.ReasonCodeInfoResponse.prototype.clearReasonCodeBatchDetails = function() {
  return this.setReasonCodeBatchDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeInfoResponse.prototype.hasReasonCodeBatchDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ReasonCodeCategoryData reason_code_category_data = 2;
 * @return {?proto.proto.ReasonCodeCategoryData}
 */
proto.proto.ReasonCodeInfoResponse.prototype.getReasonCodeCategoryData = function() {
  return /** @type{?proto.proto.ReasonCodeCategoryData} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeModel_pb.ReasonCodeCategoryData, 2));
};


/**
 * @param {?proto.proto.ReasonCodeCategoryData|undefined} value
 * @return {!proto.proto.ReasonCodeInfoResponse} returns this
*/
proto.proto.ReasonCodeInfoResponse.prototype.setReasonCodeCategoryData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeInfoResponse} returns this
 */
proto.proto.ReasonCodeInfoResponse.prototype.clearReasonCodeCategoryData = function() {
  return this.setReasonCodeCategoryData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeInfoResponse.prototype.hasReasonCodeCategoryData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GeneralResponse general_response = 3;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.ReasonCodeInfoResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 3));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.ReasonCodeInfoResponse} returns this
*/
proto.proto.ReasonCodeInfoResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeInfoResponse} returns this
 */
proto.proto.ReasonCodeInfoResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeInfoResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.EndDowntimeRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.EndDowntimeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.EndDowntimeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.EndDowntimeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.EndDowntimeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stoppageIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.EndDowntimeRequest}
 */
proto.proto.EndDowntimeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.EndDowntimeRequest;
  return proto.proto.EndDowntimeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.EndDowntimeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.EndDowntimeRequest}
 */
proto.proto.EndDowntimeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStoppageId(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.EndDowntimeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.EndDowntimeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.EndDowntimeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.EndDowntimeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoppageIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated int64 stoppage_id = 1;
 * @return {!Array<number>}
 */
proto.proto.EndDowntimeRequest.prototype.getStoppageIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.EndDowntimeRequest} returns this
 */
proto.proto.EndDowntimeRequest.prototype.setStoppageIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.EndDowntimeRequest} returns this
 */
proto.proto.EndDowntimeRequest.prototype.addStoppageId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.EndDowntimeRequest} returns this
 */
proto.proto.EndDowntimeRequest.prototype.clearStoppageIdList = function() {
  return this.setStoppageIdList([]);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.proto.EndDowntimeRequest.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.EndDowntimeRequest} returns this
 */
proto.proto.EndDowntimeRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpsertReasonCodeLogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpsertReasonCodeLogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpsertReasonCodeLogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stoppageId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    processLine: jspb.Message.getFieldWithDefault(msg, 2, ""),
    batchNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    processOrder: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productSku: jspb.Message.getFieldWithDefault(msg, 5, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    reasonCodeId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    parentId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    generalRequest: (f = msg.getGeneralRequest()) && common_Commons_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpsertReasonCodeLogsRequest}
 */
proto.proto.UpsertReasonCodeLogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpsertReasonCodeLogsRequest;
  return proto.proto.UpsertReasonCodeLogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpsertReasonCodeLogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpsertReasonCodeLogsRequest}
 */
proto.proto.UpsertReasonCodeLogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStoppageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessLine(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessOrder(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductSku(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReasonCodeId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParentId(value);
      break;
    case 10:
      var value = new common_Commons_pb.GeneralRequest;
      reader.readMessage(value,common_Commons_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpsertReasonCodeLogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpsertReasonCodeLogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpsertReasonCodeLogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoppageId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProcessLine();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBatchNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProcessOrder();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductSku();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getReasonCodeId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getParentId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_Commons_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 stoppage_id = 1;
 * @return {number}
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.getStoppageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpsertReasonCodeLogsRequest} returns this
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.setStoppageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string process_line = 2;
 * @return {string}
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.getProcessLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpsertReasonCodeLogsRequest} returns this
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.setProcessLine = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string batch_number = 3;
 * @return {string}
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.getBatchNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpsertReasonCodeLogsRequest} returns this
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.setBatchNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string process_order = 4;
 * @return {string}
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.getProcessOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpsertReasonCodeLogsRequest} returns this
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.setProcessOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product_sku = 5;
 * @return {string}
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.getProductSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpsertReasonCodeLogsRequest} returns this
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.setProductSku = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 start_time = 6;
 * @return {number}
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpsertReasonCodeLogsRequest} returns this
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 end_time = 7;
 * @return {number}
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpsertReasonCodeLogsRequest} returns this
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 reason_code_id = 8;
 * @return {number}
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.getReasonCodeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpsertReasonCodeLogsRequest} returns this
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.setReasonCodeId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 parent_id = 9;
 * @return {number}
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpsertReasonCodeLogsRequest} returns this
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.setParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional GeneralRequest general_request = 10;
 * @return {?proto.proto.GeneralRequest}
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.proto.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralRequest, 10));
};


/**
 * @param {?proto.proto.GeneralRequest|undefined} value
 * @return {!proto.proto.UpsertReasonCodeLogsRequest} returns this
*/
proto.proto.UpsertReasonCodeLogsRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UpsertReasonCodeLogsRequest} returns this
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UpsertReasonCodeLogsRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DeleteLogRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stoppageIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteLogRequest}
 */
proto.proto.DeleteLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteLogRequest;
  return proto.proto.DeleteLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteLogRequest}
 */
proto.proto.DeleteLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStoppageId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoppageIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 stoppage_id = 1;
 * @return {!Array<number>}
 */
proto.proto.DeleteLogRequest.prototype.getStoppageIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.DeleteLogRequest} returns this
 */
proto.proto.DeleteLogRequest.prototype.setStoppageIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.DeleteLogRequest} returns this
 */
proto.proto.DeleteLogRequest.prototype.addStoppageId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DeleteLogRequest} returns this
 */
proto.proto.DeleteLogRequest.prototype.clearStoppageIdList = function() {
  return this.setStoppageIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AmdCsvResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AmdCsvResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AmdCsvResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AmdCsvResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    amdCsvData: jspb.Message.getFieldWithDefault(msg, 1, ""),
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AmdCsvResponse}
 */
proto.proto.AmdCsvResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AmdCsvResponse;
  return proto.proto.AmdCsvResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AmdCsvResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AmdCsvResponse}
 */
proto.proto.AmdCsvResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmdCsvData(value);
      break;
    case 2:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AmdCsvResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AmdCsvResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AmdCsvResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AmdCsvResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmdCsvData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string amd_csv_data = 1;
 * @return {string}
 */
proto.proto.AmdCsvResponse.prototype.getAmdCsvData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AmdCsvResponse} returns this
 */
proto.proto.AmdCsvResponse.prototype.setAmdCsvData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GeneralResponse general_response = 2;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.AmdCsvResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 2));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.AmdCsvResponse} returns this
*/
proto.proto.AmdCsvResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AmdCsvResponse} returns this
 */
proto.proto.AmdCsvResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AmdCsvResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.proto);
