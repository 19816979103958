import { ReactComponent as Upl } from "assets/icons/operator-logo.svg";
import { useTime } from "hooks/useTime";
import './index.scss';
import { useState } from "react";
import { Popover, Tag } from "antd";
import { AboutModal } from "pages/operator/ReasonCodeHeader/AboutModal";
import { Text } from "components/Text";
import {
  QuestionCircleOutlined,
  ClockCircleOutlined
} from "@ant-design/icons";

export const ResonCodeHeader = () => {
  const displayTime = useTime();
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [openAboutModal, setOpenAboutModal] = useState<boolean>(false);

  const helpMeContent = (
    <div>
      <p className="sign-out">
        <a
          onClick={() => {
            setShowPopover(!showPopover);
          }}
          href={"https://docs.solulever.com/"}
          target="_blank"
          rel={"noopener noreferrer"}
        >
          <Text type="descriptionGrey65">Documentation</Text>
        </a>
      </p>
      <p
        className="sign-out"
        onClick={() => {
          setOpenAboutModal(true);
          setShowPopover(!showPopover);
        }}
      >
        <Text type="descriptionGrey65">About</Text>
      </p>
      <AboutModal
        visible={openAboutModal}
        handleCancel={() => setOpenAboutModal(false)}
      />
    </div>
  );

  return (
    <div className="reason-code-header-container">
      <Upl className="upl-logo" />
      <p className="header-text">Reason Code Selection</p>
      <div className="login-help-about">
        <Popover
          content={helpMeContent}
          trigger="click"
          title={<Text type="descriptionGrey65">Help</Text>}
          visible={showPopover}
          onVisibleChange={() => setShowPopover(!showPopover)}
        >
          <QuestionCircleOutlined className="question-mark-circle"></QuestionCircleOutlined>
        </Popover>
        <div className="connect-button">
          <Tag color="success">Connected</Tag>
        </div>
        <div className="clock-wrapper">
          <div className="clock-container">
            <ClockCircleOutlined />
            <div>{displayTime}</div>
          </div>
        </div>
      </div>
    </div>
  )
}