import { DatePicker, Input, Modal } from 'antd'
import { CloseCircleFilled, ClockCircleOutlined } from "@ant-design/icons";

import React, { useEffect, useState } from 'react'
import { dateToEpoch } from 'utils/common';
import moment from 'moment';
import { updateRejejectCount } from 'store/actions/productionOverview';
import { useDispatch } from 'react-redux';
import { start } from 'repl';
import { any } from '@amcharts/amcharts5/.internal/core/util/Array';
import { DateFilters } from 'utils/enums';
import "./index.scss";


function NewRejectCountModal(props: any) {
  const { visible, open, onOk, onCancel, onFilterSearchHandler, selectedRowFilterData,fetchData } = props;
  const dispatch=useDispatch()
  const [rejectCountModal, setRejectCountModal] = useState({
    processOrderNo: '',
    productDescription: '',
    line: '',
    batchNumber:'',
    sku: '',
    startTime: selectedRowFilterData && selectedRowFilterData?.StartTime && moment(selectedRowFilterData?.StartTime,DateFilters.Date_Format),
    endTime: selectedRowFilterData && selectedRowFilterData?.EndTime && moment(selectedRowFilterData?.EndTime,DateFilters.Date_Format),
    material: '',
    orderQuantity: '',
    totalQuantityProduced: '',
    goodQuantity: '',
    totalReject: '',
    startUpReject: '',
    productionReject:''
  })
  const { processOrderNo,
    productDescription,
    line,
    sku,
    batchNumber,
    startTime,
    endTime,
    material,
    orderQuantity,
    totalQuantityProduced,
    goodQuantity,
    totalReject,
    startUpReject,
    productionReject } = rejectCountModal;
  
    const onStartTimeHandler = (time: any, timeString: string) => {
      const getStartTime = dateToEpoch(moment(time?._d).format('YYYY-MM-DD h:mm::ss:SSS'));
      setRejectCountModal({...rejectCountModal,startTime:getStartTime})
    };
    const onEndTimeHandler = (time: any, timeString: string) => {
      setRejectCountModal(rejectCountModal => ({
        ...rejectCountModal,
        endTime: time
       }));    };
  const handleChange = (e) => {
    const value = e.target.value;
    setRejectCountModal(rejectCountModal => ({
        ...rejectCountModal,
        [e.target.name]: Number(value)
       }));
  }
  const handleCreate = () => {
    
    dispatch(updateRejejectCount({productionOrderNumberValue:processOrderNo,startTimeValue:startTime,startUpRejectValue:startUpReject,productionRejectValue:productionReject}))
      onOk();
      fetchData()
  
  }
  useEffect(() => {
    
    setRejectCountModal(rejectCountModal => ({
      ...rejectCountModal,
      processOrderNo: selectedRowFilterData && selectedRowFilterData[0]?.processOrder,
      batchNumber:selectedRowFilterData && selectedRowFilterData[0]?.BatchNumber,
      productDescription: selectedRowFilterData && selectedRowFilterData[0]?.productDescription,
      line: selectedRowFilterData && selectedRowFilterData[0]?.line,
      sku:selectedRowFilterData && selectedRowFilterData[0]?.sku,
      startTime:  selectedRowFilterData && selectedRowFilterData[0]?.StartTime && moment(selectedRowFilterData[0]?.StartTime,DateFilters.Date_Format),
      endTime: selectedRowFilterData && selectedRowFilterData[0]?.EndTime && moment(selectedRowFilterData[0]?.EndTime,DateFilters.Date_Format),
      material: selectedRowFilterData && selectedRowFilterData[0]?.Materials,
      orderQuantity: selectedRowFilterData && selectedRowFilterData[0]?.orderQuantity,
      totalQuantityProduced: selectedRowFilterData && selectedRowFilterData[0]?.totalQuantityProduced,
      goodQuantity: selectedRowFilterData && selectedRowFilterData[0]?.goodQuantity,
      totalReject: selectedRowFilterData && selectedRowFilterData[0]?.totalReject,
      startUpReject: selectedRowFilterData && selectedRowFilterData[0]?.StartupRejects,
      productionReject:selectedRowFilterData && selectedRowFilterData[0]?.ProductionRejects
    }));
  
  }, [selectedRowFilterData.procesOrder, open])
  
  
 

  return (
   
    <Modal
   
    centered
    open={open}
      onOk={() => { handleCreate() }}
    onCancel={onCancel}

    width={528}
    closeIcon={<CloseCircleFilled />}
    wrapClassName="add-reasoncode-modal"
    okText="Submit"
  >
    <div className="add-modal-container">
      <div className="title">Add Reject Count</div>
      <div className="input-container">
        <div className="input-content">
          <span>Process Order No</span>
          <Input placeholder="Process Order No" value={processOrderNo} disabled />

        </div>

        <div className="input-content">
          <span>Product Description</span>
            <Input placeholder="Line" value={productDescription}disabled
            />
        </div>
      </div>
      <div className="input-container">
        <div className="input-content">
          <span>Line</span>
          <Input
            placeholder="Line"
            value={productDescription}disabled
            />
        </div>
        <div className="input-content">
          <span>SKU</span>
          <Input
            placeholder="SKU"
              value={sku}
              defaultValue={sku}disabled
            />
        </div>
      </div>
    
      <div className="input-container">
        <div className="input-content">
          <span>Start Time</span>
            <DatePicker
              onChange={onStartTimeHandler}
              showTime={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              format="YYYY-MM-DD HH:mm:ss"
              suffixIcon={<ClockCircleOutlined />}
              value={startTime}disabled
          />

        </div>
        <div className="input-content">
          <span>End Time</span>
          <DatePicker
              onChange={onEndTimeHandler}
              showTime={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              format="YYYY-MM-DD HH:mm:ss"
              suffixIcon={<ClockCircleOutlined />}
              value={endTime}disabled
              
         />
        </div>
      </div>

      <div className="input-container">
      <div className="input-content">
          <span>Batch Number</span>
          <Input
            placeholder="Batch Number"
            value={batchNumber} disabled
            />
        </div>
        <div className="input-content">
          <span>Material</span>
          <Input
            placeholder="Material"
            value={material}disabled
            />
        </div>
       
      </div>

       <div className="input-container">
       <div className="input-content">
          <span>Order Quantity</span>
          <Input
            placeholder="Order Quantity"
            value={orderQuantity}disabled
            />
        </div>
        <div className="input-content">
          <span>Total Quantity Produced</span>
          <Input
              placeholder="Total Quantity Produced"
              value={totalQuantityProduced}disabled
            />
        </div>
        
        </div> 

       <div className="input-container">
       <div className="input-content">
          <span>Good Quantity</span>
          <Input
            placeholder="Good Quantity"
            value={goodQuantity}disabled
            />
        </div>
        <div className="input-content">
          <span>Total Reject</span>
          <Input
            placeholder="Total Reject"
              value={totalReject}disabled
            />
        </div>
        
        </div>  
        <div className="input-container">
        <div className="input-content edit-field">
          <span>Start-up Reject</span>
            <Input
              name="startUpReject"
            placeholder="Start-up Reject"
              value={startUpReject}
              onChange={handleChange}
            />
        </div>
        <div className="input-content edit-field">
          <span>Production Reject</span>
          <Input
            placeholder="Production Reject"
              value={productionReject}
              name="productionReject"
              onChange={handleChange}
            />
        </div>
       
      </div>   
      
    </div>
    
  </Modal>
  
  )
}

export default NewRejectCountModal


