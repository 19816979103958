import { Modal } from "antd";
import { Text } from "../Text";
import { ReactComponent as SoluleverImg } from "assets/icons/solulever.svg";
import { ReactComponent as BraboTagImg } from "assets/icons/braboTag.svg";

import "./index.scss";

export const AboutModal = (props: any) => {
  return (
    <Modal
      title="About Brabo"
      visible={props.visible}
      centered
      className="about-us-main"
      onCancel={props.handleCancel}
      footer={null}
      width={482}
    >
      <div className="about-modal">
        <BraboTagImg />

        <div className="desc">
          <Text type="description" screenClass="">
            Copyright © 2020 Solulever. All Rights Reserved.
          </Text>
        </div>
        <div className="properties-title">
          <Text screenClass="gray-text">
            For Technical Support Please Contact:
          </Text>
        </div>
        <div className="properties">
          <ul className="keys">
            <li>
              <Text type="para14M" screenClass="gray-text">
                Phone
              </Text>
            </li>
            <li>
              <Text type="para14M" screenClass="gray-text">
                Product Sales
              </Text>
            </li>
            <li>
              <Text type="para14M" screenClass="gray-text">
                Product Support
              </Text>
            </li>
            <li>
              <Text type="para14M" screenClass="gray-text">
                Website
              </Text>
            </li>
            <li>
              <Text type="para14M" screenClass="gray-text">
                Address
              </Text>
            </li>
          </ul>
          <ul className="values">
            <li >
              <Text screenClass="blue-text">+31 (0) 23 561 0331</Text>
            </li>
            <li className="blue-text">
              <a href="mailto:sales@solulever.com">
                <Text screenClass="blue-text">sales@solulever.com</Text>
              </a>
            </li>
            <li className="blue-text">
              <a href="mailto:support@solulever.com">
                <Text screenClass="blue-text">support@solulever.com</Text>
              </a>
            </li>
            <li className="blue-text">
              <a
                href={"https://www.solulever.com"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text screenClass="blue-text">www.solulever.com</Text>
              </a>
            </li>
            <li>
              <Text screenClass="gray-text">
                Solulever B.V. Beech avenue 113, 1119 RB Schiphol-Rijk, The
                Netherlands
              </Text>
            </li>
          </ul>
        </div>
        <div className="footer">
          <SoluleverImg />
        </div>
      </div>
    </Modal>
  );
};
