import { Input, message, Modal, Switch } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import './index.scss';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { editReasonCodeCategory } from "store/actions/reasonCodeManagement";

export const EditResonCodeCategory = (props: any) => {
    const { visible, onOk, onCancel, open, selectedCategoryFilterData, reasonCodeAllStatus } = props;

    const [category, setCategoryTitle] = useState<any>("");
    const [localStatusState, setLocalStatusState] = useState(false)

    const uuid = selectedCategoryFilterData?.key
    const dispatch = useDispatch();
    const onHandleSave = () => {
        dispatch(editReasonCodeCategory({ category, status: localStatusState, uuid, viewType: "grid", type: 0 }))
        onOk()
    }

    useEffect(() => {
        setLocalStatusState(selectedCategoryFilterData?.status)
    }, [open])


    useEffect(() => {
        setCategoryTitle(selectedCategoryFilterData?.reasonCodeCategory)
        setLocalStatusState(selectedCategoryFilterData?.status)
    }, [selectedCategoryFilterData])

    const onChange = (checked: boolean) => {
        if (reasonCodeAllStatus.length === 0) {
            setLocalStatusState(checked)
        }
        else {
            message.error('Please mark all reason codes inactive');
            return;
        }
    };
    return (
        <Modal
            open={open}
            visible={visible}
            centered
            onOk={onHandleSave}
            onCancel={onCancel}
            width={336}
            closeIcon={< CloseCircleFilled />}
            wrapClassName="create-reason-code-category"
            okText="Save"
            destroyOnClose={true}
        >
            <div className="title" >Edit Reason Code Category </div>
            < div className="row" >
                <label>Category Name </label>
                < Input placeholder="Edit Category Name"
                    value={category}
                    className="input-text"
                    onChange={(e) => setCategoryTitle(e.target.value)} />
            </div>

            < div className="status-row" >
                <label>Status </label>
                <Switch checked={localStatusState}
                    onChange={(checked: boolean) => onChange(checked)}
                />
            </div>
        </Modal>
    )
}
