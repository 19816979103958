import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { ChartDataProps } from "types";

export const Pie: React.FC<ChartDataProps> = ({ chartID, data, page }) => {
  let colors = ["#722ED1", "#67B7DC", "#5CDBD3", "#FF7875", "#FF0087", "#87009D"];
  useLayoutEffect(() => {
    let root = am5.Root.new(chartID);

    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        radius: am5.percent(80),
      })
    );


    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: "value",
        categoryField: "label",
      })
    );
    // series.data.setAll(dataWithColors);
    series.ticks.template.set("visible", false);
    series.labels.template.set("visible", false);
    series.slices.template.set("toggleKey", "none");

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          centerX: am5.p50,
          centerY: am5.p50,
          layout: root.horizontalLayout,
          fill: am5.color(0xffffff),
          populateText: false,
        }),
      });
    });

    if (page === "oee") {
      let dataWithColors: any = [];
      if (data && data.length > 0) {
        data.forEach((element: any, index: number) => {
          dataWithColors.push({
            ...element,
            columnSettings: {
              fill: colors[index],
            },
          });
        });
      }

      series.slices.template.setAll({
        templateField: "columnSettings",
        strokeWidth: 0,
        stroke: am5.color(0xd9d9d9),
      });
      series.data.setAll(dataWithColors);
      let legend = chart.children.push(am5.Legend.new(root, {
        nameField: "label",
        stroke: am5.color(0xffffff),
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: am5.GridLayout.new(root, {
          fixedWidthGrid: true,
        })
      }));
      legend.valueLabels.template.set("forceHidden", true);
      legend.data.setAll(series.dataItems);
    }
    else {
      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.percent(50),
          x: am5.percent(50),
          layout: root.gridLayout,
        })
      );
      series.data.setAll(data);
      legend.markers.template.setAll({
        width: 12,
        height: 12,
      });

      legend.labels.template.setAll({
        fontSize: 14,
        fontWeight: "300"
      });
      legend.data.setAll(series.dataItems);
    }
    root?._logo?.dispose();

    return () => {
      root.dispose();
    };
  }, [chartID, data]);

  return <div id={chartID} style={{ width: "100%", height: "100%" }}></div>;
};
