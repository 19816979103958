import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export const SolidGuage = (props: any) => {
  const { data, chartID, screen } = props;
  let colors = ["#67B7DC", "#6794DC", "#6771DC", "#8067DC", "#8067DC", "#8067DC"];

  useLayoutEffect(() => {
    let root = am5.Root.new(chartID);

    let dataWithColors: any = [];
    if (data && data.length > 0) {
      data.forEach((element: any, index: number) => {
        dataWithColors.push({
          ...element,
          columnSettings: {
            fill: colors[index],
          },
        });
      });
    }

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        innerRadius: am5.percent(30),
        radius: am5.percent(100),
        startAngle: -90,
        endAngle: 180,
      })
    );

    let cursor = chart.set(
      "cursor",
      am5radar.RadarCursor.new(root, {
        behavior: "zoomX",
      })
    );

    //cursor.lineY.set("visible", false);

    let xRenderer = am5radar.AxisRendererCircular.new(root, {
      minGridDistance: 40,
    });

    xRenderer.labels.template.setAll({
      radius: 0,
    });

    xRenderer.grid.template.setAll({
      forceHidden: true,
    });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: xRenderer,
        min: 0,
        max: 100,
        strictMinMax: true,
        numberFormat: "#'%'",
      })
    );

    let yRenderer = am5radar.AxisRendererRadial.new(root, {
      minGridDistance: 10,
    });

    yRenderer.labels.template.setAll({
      centerX: am5.p100,
      fontWeight: "400",
      fontSize: 12,
      templateField: "columnSettings",
    });

    yRenderer.grid.template.setAll({
      forceHidden: true,
    });

    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: yRenderer,
      })
    );

    yAxis.data.setAll(dataWithColors);

    let series1 = chart.series.push(
      am5radar.RadarColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        clustered: false,
        valueXField: "full",
        categoryYField: "category",
        fill: root.interfaceColors.get("alternativeBackground"),
      })
    );

    series1.columns.template.setAll({
      width: am5.p100,
      fillOpacity: 0.08,
      strokeOpacity: 0,
      cornerRadius: 20,
    });

    series1.data.setAll(dataWithColors);
    series1.set("stroke", am5.color("#00ff00"));

    let series2 = chart.series.push(
      am5radar.RadarColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        clustered: false,
        valueXField: screen ? "percentage" : "value",
        categoryYField: "category",
      })
    );

    series2.columns.template.setAll({
      width: am5.p100,
      strokeOpacity: 0,
      tooltipText: "{category}: {valueX}%",
      cornerRadius: 10,
      templateField: "columnSettings",
    });

    series2.data.setAll(dataWithColors);
    series1.appear(100);
    series2.appear(100);
    chart.appear(100, 100);

    root?._logo?.dispose();

    return () => {
      root.dispose();
    };
  }, [chartID, data]);

  return <div id={chartID} style={{ width: "100%", height: "100%" }}></div>;
};
