import moment from "moment";
import { milliToHrs } from "utils/milliToHrs";

export const mapRuntimeAnalysisDowntimeData = (details: any) => {
  let runtimeAnalysisCardData: any = {};

  runtimeAnalysisCardData.currentValue = details.getDowntime();
  runtimeAnalysisCardData.lastValue = details.getLastDowntime();
  runtimeAnalysisCardData.percentage = details.getPercentage();

  return {
    runtimeAnalysisCardData,
  };
};

export const mapRuntimeAnalysisRuntimeData = (details: any) => {
  let runtimeAnalysisCardData: any = {};

  runtimeAnalysisCardData.currentValue = details.getRuntime();
  runtimeAnalysisCardData.lastValue = details.getLastRuntime();
  runtimeAnalysisCardData.percentage = details.getPercentage();

  return {
    runtimeAnalysisCardData,
  };
};

export const mapRuntimeAnalysisPlannedStoptimeData = (details: any) => {
  let runtimeAnalysisCardData: any = {};

  runtimeAnalysisCardData.currentValue = details.getPlannedStoptime();
  runtimeAnalysisCardData.lastValue = details.getLastPlannedStoptime();
  runtimeAnalysisCardData.percentage = details.getPercentage();

  return {
    runtimeAnalysisCardData,
  };
};

export const mapRuntimeAnalysisAssetUtilizationData = (details: any) => {
  let runtimeAnalysisAssetUtilizationList: any = [];
  details.getMachineTimeInfoList().forEach((element: any) => {
    runtimeAnalysisAssetUtilizationList.push({
      name: element.getMachineName(),
      runtime: element.getRuntime(),
      percentage: element.getPercentage().toFixed(2),
      availableTime: element.getAvailabletime(),
      value: element.getPercentage().toFixed(2),
      full: 100,
      category: element.getMachineName(),
    });
  });
  return {
    runtimeAnalysisAssetUtilizationList,
  };
};

export const mapProductionAssetUtilization = (details:any) =>{
  let runtime=details.getRuntime();
  let availableTime=details.getAvailabletime();
  let percentage=details.getPercentage()
  return {runtime,availableTime,percentage}
}

export const mapRuntimeAnalysisMachineRuntimeStatusData = (details: any) => {
  let runtimeAnalysisMachineRuntimeStatusList: any = [];
  details.getMachineRuntimeStatusList().forEach((element: any) => {
    let tempDataObj = {
      equipmentName: element.getMachineName(),
    };
    element.getStatusDurationList().forEach((el: any) => {
      tempDataObj[el.getStatus()] = Math.floor(el.getDuration() / 60000);
    });
    runtimeAnalysisMachineRuntimeStatusList.push(tempDataObj);
  });

  return {
    runtimeAnalysisMachineRuntimeStatusList,
  };
};

export const mapRuntimeAnalysisMttrData = (details: any) => {
  let runtimeAnalysisMttrList: any = [];
  let runtimeAnalysisMttrCardData: any = {};

  details.getTimeMttrList().forEach((element: any) => {
    runtimeAnalysisMttrList.push({
      category: Math.floor(element.getMttr() / 60000),
      value: moment(element.getTime()).format("DD MMM YYYY"),
    });
  });
  runtimeAnalysisMttrCardData.totalTime = details.getTotalDowntime();
  runtimeAnalysisMttrCardData.actualTime = details.getTotalMttr();

  return {
    runtimeAnalysisMttrList,
    runtimeAnalysisMttrCardData,
  };
};

export const mapRuntimeAnalysisMtbfData = (details: any) => {
  let runtimeAnalysisMtbfList: any = [];
  let runtimeAnalysisMtbfCardData: any = {};
  details.getTimeMtbfList().forEach((element: any) => {
    runtimeAnalysisMtbfList.push({
      category: Math.floor(element.getMttr() / 60000),
      value: moment(element.getTime()).format("DD MMM YYYY"),
    });
  });
  runtimeAnalysisMtbfCardData.totalTime = details.getTotalTimeSpent();
  runtimeAnalysisMtbfCardData.actualTime = details.getTotalMtbf();

  return {
    runtimeAnalysisMtbfList,
    runtimeAnalysisMtbfCardData,
  };
};

const setRow = (element: any) => {
  const batchHistoryRow = {} as any;
  batchHistoryRow.line = element.getLine();
  batchHistoryRow.machine = element.getMachine();
  batchHistoryRow.sku = element.getSku() + " Ltr";
  batchHistoryRow.description = element.getDescription();
  batchHistoryRow.status = element.getStatus();
  batchHistoryRow.startTime = moment(element.getStartTime()).format("DD MMM YYYY, HH:mm:ss");
  batchHistoryRow.endTime = moment(element.getEndTime()).format("DD MMM YYYY, HH:mm:ss");
  batchHistoryRow.duration =
    milliToHrs(element.getEndTime() - element.getStartTime()) +
    " Hrs";
  batchHistoryRow.reasonCode = element.getReasonCode();
  return batchHistoryRow;
};

const setGanttRow = (element: any) => {
  const batchHistoryRow = {} as any;
  batchHistoryRow.line = element.getLine();
  batchHistoryRow.category = element.getMachine();
  batchHistoryRow.sku = element.getSku() + " Ltr";
  batchHistoryRow.description = element.getDescription();
  batchHistoryRow.status = element.getStatus();
  batchHistoryRow.start=moment(element.getStartTime()).format("YYYY-MM-DD HH:mm:ss");
  batchHistoryRow.end = moment(element.getEndTime()).format("YYYY-MM-DD HH:mm:ss");
  batchHistoryRow.columnSettings= {
    fill:  element.getStatus()==="Running"?"#95DE64":"#FF7875"
  }
  batchHistoryRow.duration =
    milliToHrs(element.getEndTime() - element.getStartTime()) + " Hrs";
  batchHistoryRow.reasonCode = element.getReasonCode();
  return batchHistoryRow;
};


export const mapRuntimeAnalysisDashboardData = (response: any) => {
  let runtimeAnalysisDashboardGanttData: any = [];
  let machineNames: any = [];
  response.getDetailsList().forEach((item: any) => {
    runtimeAnalysisDashboardGanttData.push(setGanttRow(item));
    const i = machineNames.findIndex((e) => e.category === item.getMachine());
    if (i <= -1) {
      machineNames.push({ category: item.getMachine() });
    }
  });
  return {runtimeAnalysisDashboardGanttData,machineNames};
};



export const mapRuntimeAnalysisDetailsData = (response: any) => {
  let runtimeAnalysisDetailsRuntimeStatisticsList: any = [];
  let runtimeAnalysisDetailsDowntimeStatisticsList: any = [];
  const rowTitles = {};
  const rowTitlesDowntime = {};
  let countRows = 0;
  let countRowsDowntime = 0;
  response.getDetailsList().forEach((item: any) => {
    let rowName = moment(item.getStartTime()).format("DD MMMM yyyy") as any;
    if (rowTitles[rowName] >= 0) {
      runtimeAnalysisDetailsRuntimeStatisticsList[rowTitles[rowName]].data.push(
        setRow(item)
      );
    } else {
      rowTitles[rowName] = countRows;
      runtimeAnalysisDetailsRuntimeStatisticsList[countRows] = {
        row: rowName,
        data: [setRow(item)],
      };
      countRows++;
    }

    if (rowTitlesDowntime[rowName] >= 0) {
      if (item.getStatus() === "Stopped") {
        runtimeAnalysisDetailsDowntimeStatisticsList[
          rowTitlesDowntime[rowName]
        ].data.push(setRow(item));
      }
    } else {
      if (item.getStatus() === "Stopped") {
        rowTitlesDowntime[rowName] = countRowsDowntime;
        runtimeAnalysisDetailsDowntimeStatisticsList[countRowsDowntime] = {
          row: rowName,
          data: [setRow(item)],
        };
        countRowsDowntime++;
      }
    }
  });

  return {
    runtimeAnalysisDetailsRuntimeStatisticsList,
    runtimeAnalysisDetailsDowntimeStatisticsList,
  };
};

export const mapAvgCycleDetailsData = (details: any) => {
  let avgCycleDetails: any = {};
  avgCycleDetails.ratedCycleTime = details.getRatedCycleTime();
  avgCycleDetails.averageCycleTime = details.getAvgCycleTime();
  avgCycleDetails.deviation = details.getDeviation();

  return avgCycleDetails;
};
