import { Typography } from "antd";
import "./index.scss";

export const Text = (props: any) => {
  const { customClassName } = props;

  return (
    <Typography.Text className={customClassName}>
      {props.children}
    </Typography.Text>
  );
};
