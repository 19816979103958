import "./index.scss";
import { Text } from "../../../components/Text/index";

export const LineOverviewCard = (props: any) => {

  const { machine } = props;
  const { attributeList, img } = machine
  return (
    <>
      <div className="LineOverviewCard-wrapper">
        <div className="LineOverviewCard-header-wrapper">
          <img src={img} alt="filling_machine" />
          <div className="LineOverviewCard-title-container">
            <Text type={"mediump16Bold"} customClassName={"mediump16Bold"}>
              {attributeList.Machine_type}
            </Text>
            <Text customClassName={"p14"}>{machine.subheading}</Text>
          </div>
        </div>
        <div className="LineOverviewCard-table-body-container">
          <div className="LineOverview-table-text-container">
            <Text customClassName={"p14primary85"}><b>Model No</b></Text>
            <Text customClassName={"p14Dark"}>{attributeList.Model_no}</Text>
          </div>
          <div className="LineOverview-table-text-container">
            <Text customClassName={"p14primary85"}><b>Throughput</b></Text>
            <Text customClassName={"p14Dark"}>{attributeList.Throughput}</Text>
          </div>
          <div className="LineOverview-table-text-container">
            <Text customClassName={"p14primary85"}><b>Installation Date</b></Text>
            <Text customClassName={"p14Dark"}>10th Oct. 2019</Text>
          </div>
          <div className="LineOverview-table-text-container">
            <Text customClassName={"p14primary85"}><b>Weight</b></Text>
            <Text customClassName={"p14Dark"}>20 Kgs</Text>
          </div>
          <div className="LineOverview-table-text-container">
            <Text customClassName={"p14primary85"}><b>Size/Dimension</b></Text>
            <Text customClassName={"p14Dark"}>{attributeList['Size( Dimenstions )']}</Text>
          </div>
          <div className="LineOverview-table-text-container">
            <Text customClassName={"p14primary85"}><b>OEM</b></Text>
            <Text customClassName={"p14Dark"}>{attributeList.OEM}</Text>
          </div>
        </div>
      </div>
    </>
  );
};
