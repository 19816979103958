import { CustomButton } from "../Common/Button";
import "./index.scss";
import { DropdownFiltercardProps } from "types/index";
import { CustomDropdownTree } from "../Common/Dropdown/CustomDropdownTree";
import { Dropdown } from "../Common/Dropdown/Dropdown";
import { CustomTimePicker } from "../Common/CustomTimePicker/CustomTimePicker";
import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { downloadAlarmsData } from "store/services/downloadService";
import { message } from "antd";
import { setFilterValues } from "store/actions/rootAction";
import {  getMachineNodeIds } from "utils/common";


export const DropdownFilterComponent: React.FC<DropdownFiltercardProps> = ({
  alarmAndEventsFilter,
  upload,
  onClick,
  setPayload,
  screen,
  download,
  downloadPdf
}) => {
  const skuData = [
    {
      category: "1 Ltr",
      value: "1",
      full: 100,
    },
    {
      category: "5 Ltr",
      value: "5",
      full: 100,
    },
    {
      category: "10 Ltr",
      value: "10",
      full: 100,
    },
    {
      category: "15 Ltr",
      value: "15",
      full: 100,
    },
    {
      category: "20 Ltr",
      value: "20",
      full: 100,
    },
  ];

  const severityData = [
    {
      category: "All",
      value: "0",
      full: 100,
    },
    {
      category: "Event",
      value: "10",
      full: 100,
    },
    {
      category: "Warning",
      value: "20",
      full: 100,
    },
    {
      category: "Minor",
      value: "30",
      full: 100,
    },
    {
      category: "Major",
      value: "40",
      full: 100,
    },
    {
      category: "Critical",
      value: "50",
      full: 100,
    },
  ];
  let severityRedux = useSelector(
    (state: any) => state.root.filterValues.severity
  );

  const [selectedMachines, setSelectedMachines] = useState([]);
  const filterTreeData = useSelector((state: any) => state.root.filterTree);
  const nodeArr= getMachineNodeIds(filterTreeData?.children)
  let skuRedux = useSelector((state: any) => state.root.filterValues.sku);

  const [sku, setSku] = useState<any>(skuRedux);
  const [severity, setSeverity] = useState<any>(severityRedux);

  let startTimeRedux = useSelector(
    (state: any) => state.root.filterValues.startTime
  );
  let endTimeRedux = useSelector(
    (state: any) => state.root.filterValues.endTime
  );
  let selectedRangeRedux = useSelector(
    (state: any) => state.root.filterValues.selectedRange
  );
  let selectedNodeIdsRedux = useSelector(
    (state: any) => state.root.filterValues.nodeId
  );
  const filterFlag = useSelector((state: any) => state.root.filterFlag);

  useEffect(() => {
    setSku(skuRedux);
    setSeverity(severityRedux);
  }, [filterFlag]);

  const [startTime, setStartTime] = useState(startTimeRedux);
  const [endTime, setEndTime] = useState(endTimeRedux);
  const [selectedValue, setSelectedValue] = useState(
    useSelector((state: any) => state.root.filterValues.selectedRange)
  );
  const [searchText, setSearchText] = useState<any>(
    useSelector((state: any) => state.root.filterValues.nodeId)
  );

  useEffect(() => {
    setSelectedValue(selectedRangeRedux);
  }, [selectedRangeRedux]);
  useEffect(() => {
    setSearchText(selectedNodeIdsRedux);
  }, [selectedNodeIdsRedux]);

  useEffect(() => {
    setStartTime(startTimeRedux);
    setEndTime(endTimeRedux);
  }, [startTimeRedux, endTimeRedux]);


  const getAncestors = (target, children, ancestors = []) => {
    for (let node of children) {
      if (node.title === target) {
        return ancestors.concat(node.id);
      }
      const found = getAncestors(
        target,
        node.children,
        ancestors.concat(node.title)
      );
      if (found) {
        return found;
      }
    }
    return undefined;
  };

  const makeLineRequest = (lineObject: any) => {
    let resultArr = [] as any;
    lineObject.forEach((element: any) => {
      const i = resultArr.findIndex((e: any) => e.line === element.parent);
      if (i > -1) {
        resultArr[i].machine.push(element.key);
      } else {
        resultArr.push({ line: element.parent, machine: [element.key] });
      }
    });
    return resultArr;
  };

  const [lineObject, setLineObject] = useState<any>([]);
  const [nodeId, setNodeId] = useState<any>(
    useSelector((state: any) => state.root.filterValues.nodeId)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedMachines.length) {
      let tempLineReq = [] as any;
      selectedMachines.forEach((value: any) => {
        const result = getAncestors(value, filterTreeData.children);
        tempLineReq.push({
          key: value,
          parent: result[result.length - 2],
        });
      });

      setLineObject(makeLineRequest(tempLineReq));
    }
  }, [selectedMachines]);
  const applyHandler = () => {
    if (nodeId.length === 0) {
      message.error("Please select at least one machine");
    } else if (setPayload) {
      dispatch(
        setFilterValues({
          key: "sku",
          value: sku,
        })
      );
      dispatch(
        setFilterValues({
          key: "startTime",
          value: startTime,
        })
      );
      dispatch(
        setFilterValues({
          key: "endTime",
          value: endTime,
        })
      );
      dispatch(
        setFilterValues({
          key: "lineObject",
          value: lineObject,
        })
      );
      dispatch(
        setFilterValues({
          key: "nodeId",
          value: nodeId,
        })
      );
      dispatch(
        setFilterValues({
          key: "severity",
          value: severity,
        })
      );
      dispatch(
        setFilterValues({
          key: "selectedRange",
          value: selectedValue,
        })
      );
      setPayload({
        sku,
        startTime,
        endTime,
        lineObject,
        nodeId,
        severity,
      });
    }
  };

  const downloadHandler = () => {
    if (screen === "alarms") {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (timezone === "Asia/Calcutta") timezone = "Asia/Kolkata";
      message.info("Download in progress");
      downloadAlarmsData(startTime, endTime, severity, nodeId, timezone);
    }
    if (downloadPdf) {
      downloadPdf();
    }
  };

  const resetHandler = () => {
    setSku("5");
    setSeverity("0");
    setStartTime(moment(moment().startOf("week")).valueOf());
    setEndTime(moment(new Date()).valueOf());
    setNodeId(nodeArr);
    setLineObject([]);
    setSelectedValue("This Week");
    setSearchText(nodeArr);
    dispatch(
      setFilterValues({
        key: "sku",
        value: "5",
      })
    );
    dispatch(
      setFilterValues({
        key: "startTime",
        value: moment(moment().startOf("week")).valueOf(),
      })
    );
    dispatch(
      setFilterValues({
        key: "endTime",
        value: moment(new Date()).valueOf(),
      })
    );
    dispatch(
      setFilterValues({
        key: "lineObject",
        value: [],
      })
    );
    dispatch(
      setFilterValues({
        key: "nodeId",
        value: nodeArr,
      })
    );
    dispatch(
      setFilterValues({
        key: "severity",
        value: "0",
      })
    );
    dispatch(
      setFilterValues({
        key: "selectedRange",
        value: "This Week",
      })
    );
    if (setPayload) {
      setPayload({
        sku: "5",
        startTime: moment(moment().startOf("week")).valueOf(),
        endTime: moment(new Date()).valueOf(),
        lineObject: [],
        nodeId: nodeArr,
        severity: 0,
      });
    }
  };
  return (
    <>
      <div className="dropdownFilterCard-main-wrapper-container">
        <div className="dropdownFiltercard-left-container flex-gap">
          <CustomDropdownTree
            setValue={setSelectedMachines}
            setNodeId={setNodeId}
            searchText={searchText}
            setSearchText={setSearchText}
          />
          {screen && screen === "alarms" ? (
            <Dropdown
              optionData={severityData}
              placeHolder={"Select severity"}
              selectedItem={severity}
              setSku={setSeverity}
              screen="alarms"
            />
          ) : (
            <Dropdown
              selectedItem={sku}
              optionData={skuData}
              placeHolder={"Select SKU"}
              setSku={setSku}
            />
          )}
          <CustomTimePicker
            startTime={startTime}
            endTime={endTime}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
          />
        </div>
        <div className="dropdownFiltercard-right-container flex-gap">
          <CustomButton type="Apply" onClick={applyHandler} />
          <CustomButton type="Reset" onClick={resetHandler} />
          {upload ? (
            <CustomButton
              type="Upload"
              onClick={() => onClick && onClick("upload")}
            />
          ) : null}
          <CustomButton type="Download"
            onClick={download ? download : () => downloadHandler()} />
        </div>
      </div>
    </>
  );
};
