export const BarboLogo = (props: any) => {
  return (
    <>
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 128 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.514 20.2272C24.514 24.6728 20.8306 27.3084 13.9082 27.3084H0V1.52419H13.1779C19.9415 1.52419 23.2756 4.28678 23.2756 8.25602C23.2756 10.7328 22.0372 12.7016 19.8144 13.8447C22.7358 14.8926 24.514 17.0836 24.514 20.2272ZM7.2399 6.79533V11.7807H12.257C14.7021 11.7807 15.9405 10.9233 15.9405 9.27215C15.9405 7.6527 14.7021 6.79533 12.257 6.79533H7.2399ZM17.1789 19.4016C17.1789 17.6234 15.8452 16.7978 13.3366 16.7978H7.2399V22.0372H13.3366C15.8452 22.0372 17.1789 21.1798 17.1789 19.4016Z"
          fill="#057AD1"
        />
        <path
          d="M42.1057 6.98586V13.2731C41.4706 13.2096 41.0261 13.1779 40.518 13.1779C37.6919 13.1779 35.7232 14.6068 35.7232 18.0997V27.2766H28.7373V7.33515H35.4056V9.71669C36.8346 7.90672 39.1526 6.98586 42.1057 6.98586Z"
          fill="#057AD1"
        />
        <path
          d="M64.27 16.1945V27.2766H57.7605V24.7045C56.7761 26.6098 54.7756 27.6259 51.8542 27.6259C47.0594 27.6259 44.4238 24.9268 44.4238 21.4656C44.4238 17.7822 47.1229 15.4959 53.2832 15.4959H57.2524C57.1572 13.4637 55.7917 12.3205 53.0291 12.3205C51.1239 12.3205 48.9646 12.9874 47.631 14.0352L45.2812 9.27215C47.5675 7.74796 50.9651 6.9541 54.077 6.9541C60.5231 6.98586 64.27 9.87547 64.27 16.1945ZM57.2524 20.8941V19.1794H54.2358C51.9813 19.1794 51.1874 20.005 51.1874 21.2116C51.1874 22.3865 52.14 23.2439 53.7912 23.2439C55.3472 23.2121 56.7126 22.45 57.2524 20.8941Z"
          fill="#057AD1"
        />
        <path
          d="M91.7688 17.2741C91.7688 23.6566 87.3868 27.6259 81.9886 27.6259C79.3848 27.6259 77.416 26.8956 76.0824 25.3079V27.3084H69.4141V0H76.3999V9.08161C77.7653 7.68444 79.6388 7.01761 81.9569 7.01761C87.3868 6.98585 91.7688 10.9551 91.7688 17.2741ZM84.6877 17.2741C84.6877 14.2575 82.846 12.5745 80.4962 12.5745C78.1464 12.5745 76.3047 14.2575 76.3047 17.2741C76.3047 20.3225 78.1464 22.069 80.4962 22.069C82.846 22.069 84.6877 20.3225 84.6877 17.2741Z"
          fill="#057AD1"
        />
        <path
          d="M94.4678 17.2741C94.4678 11.2726 99.2309 7.01761 105.74 7.01761C112.345 7.01761 117.013 11.3044 117.013 17.2741C117.013 23.3074 112.345 27.6259 105.74 27.6259C99.2309 27.6259 94.4678 23.3074 94.4678 17.2741ZM109.932 17.2741C109.932 14.2575 108.122 12.5745 105.74 12.5745C103.391 12.5745 101.549 14.2575 101.549 17.2741C101.549 20.3225 103.391 22.069 105.74 22.069C108.154 22.069 109.932 20.3225 109.932 17.2741Z"
          fill="#057AD1"
        />
        <path
          d="M119.617 23.4661C119.617 20.9576 121.459 19.3381 123.809 19.3381C126.19 19.3381 128 20.9576 128 23.4661C128 25.9112 126.19 27.6259 123.809 27.6259C121.459 27.6259 119.617 25.8794 119.617 23.4661Z"
          fill="#FF6700"
        />
      </svg>
    </>
  );
};
