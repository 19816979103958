// source: service/AssetRpc.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_Enums_pb = require('../common/Enums_pb.js');
goog.object.extend(proto, common_Enums_pb);
var common_BaseMessage_pb = require('../common/BaseMessage_pb.js');
goog.object.extend(proto, common_BaseMessage_pb);
var common_API_pb = require('../common/API_pb.js');
goog.object.extend(proto, common_API_pb);
var objects_Asset_pb = require('../objects/Asset_pb.js');
goog.object.extend(proto, objects_Asset_pb);
goog.exportSymbol('proto.DataTypeList', null, global);
goog.exportSymbol('proto.GetChildNodesParams', null, global);
goog.exportSymbol('proto.ModelDetails', null, global);
goog.exportSymbol('proto.ModelOverviewParameters', null, global);
goog.exportSymbol('proto.ModelOverviewParameters.ModelOverviewSortColumns', null, global);
goog.exportSymbol('proto.ModelTreeParameters', null, global);
goog.exportSymbol('proto.ModelsOverviewList', null, global);
goog.exportSymbol('proto.NodeChildMapList', null, global);
goog.exportSymbol('proto.NodeDetails', null, global);
goog.exportSymbol('proto.NodeDetailsList', null, global);
goog.exportSymbol('proto.NodeDetailsParameters', null, global);
goog.exportSymbol('proto.NodeIdList', null, global);
goog.exportSymbol('proto.StreamDetails', null, global);
goog.exportSymbol('proto.StreamFilter', null, global);
goog.exportSymbol('proto.StreamFilter.Status', null, global);
goog.exportSymbol('proto.StreamName', null, global);
goog.exportSymbol('proto.StreamOverviewList', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelOverviewParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ModelOverviewParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelOverviewParameters.displayName = 'proto.ModelOverviewParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelsOverviewList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ModelsOverviewList.repeatedFields_, null);
};
goog.inherits(proto.ModelsOverviewList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelsOverviewList.displayName = 'proto.ModelsOverviewList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ModelDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelDetails.displayName = 'proto.ModelDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NodeDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NodeDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NodeDetails.displayName = 'proto.NodeDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NodeDetailsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.NodeDetailsList.repeatedFields_, null);
};
goog.inherits(proto.NodeDetailsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NodeDetailsList.displayName = 'proto.NodeDetailsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelTreeParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ModelTreeParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelTreeParameters.displayName = 'proto.ModelTreeParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NodeDetailsParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NodeDetailsParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NodeDetailsParameters.displayName = 'proto.NodeDetailsParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NodeIdList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.NodeIdList.repeatedFields_, null);
};
goog.inherits(proto.NodeIdList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NodeIdList.displayName = 'proto.NodeIdList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NodeChildMapList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NodeChildMapList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NodeChildMapList.displayName = 'proto.NodeChildMapList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetChildNodesParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetChildNodesParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetChildNodesParams.displayName = 'proto.GetChildNodesParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StreamOverviewList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.StreamOverviewList.repeatedFields_, null);
};
goog.inherits(proto.StreamOverviewList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StreamOverviewList.displayName = 'proto.StreamOverviewList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StreamDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StreamDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StreamDetails.displayName = 'proto.StreamDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DataTypeList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DataTypeList.repeatedFields_, null);
};
goog.inherits(proto.DataTypeList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DataTypeList.displayName = 'proto.DataTypeList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StreamFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StreamFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StreamFilter.displayName = 'proto.StreamFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StreamName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StreamName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StreamName.displayName = 'proto.StreamName';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelOverviewParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelOverviewParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelOverviewParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelOverviewParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    sortBy: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sortOrder: jspb.Message.getFieldWithDefault(msg, 2, 0),
    filterByCreatedOn: jspb.Message.getFieldWithDefault(msg, 3, 0),
    filterByUpdatedOn: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelOverviewParameters}
 */
proto.ModelOverviewParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelOverviewParameters;
  return proto.ModelOverviewParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelOverviewParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelOverviewParameters}
 */
proto.ModelOverviewParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ModelOverviewParameters.ModelOverviewSortColumns} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    case 2:
      var value = /** @type {!proto.SortOrder} */ (reader.readEnum());
      msg.setSortOrder(value);
      break;
    case 3:
      var value = /** @type {!proto.TimeInterval} */ (reader.readEnum());
      msg.setFilterByCreatedOn(value);
      break;
    case 4:
      var value = /** @type {!proto.TimeInterval} */ (reader.readEnum());
      msg.setFilterByUpdatedOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelOverviewParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelOverviewParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelOverviewParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelOverviewParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSortOrder();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFilterByCreatedOn();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFilterByUpdatedOn();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.ModelOverviewParameters.ModelOverviewSortColumns = {
  ID: 0,
  NAME: 1,
  TYPE: 2,
  DESCRIPTION: 3,
  CREATED_BY: 4,
  CREATED_ON: 5,
  UPDATED_BY: 6,
  UPDATED_ON: 7
};

/**
 * optional ModelOverviewSortColumns sort_by = 1;
 * @return {!proto.ModelOverviewParameters.ModelOverviewSortColumns}
 */
proto.ModelOverviewParameters.prototype.getSortBy = function() {
  return /** @type {!proto.ModelOverviewParameters.ModelOverviewSortColumns} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ModelOverviewParameters.ModelOverviewSortColumns} value
 * @return {!proto.ModelOverviewParameters} returns this
 */
proto.ModelOverviewParameters.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional SortOrder sort_order = 2;
 * @return {!proto.SortOrder}
 */
proto.ModelOverviewParameters.prototype.getSortOrder = function() {
  return /** @type {!proto.SortOrder} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.SortOrder} value
 * @return {!proto.ModelOverviewParameters} returns this
 */
proto.ModelOverviewParameters.prototype.setSortOrder = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional TimeInterval filter_by_created_on = 3;
 * @return {!proto.TimeInterval}
 */
proto.ModelOverviewParameters.prototype.getFilterByCreatedOn = function() {
  return /** @type {!proto.TimeInterval} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.TimeInterval} value
 * @return {!proto.ModelOverviewParameters} returns this
 */
proto.ModelOverviewParameters.prototype.setFilterByCreatedOn = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional TimeInterval filter_by_updated_on = 4;
 * @return {!proto.TimeInterval}
 */
proto.ModelOverviewParameters.prototype.getFilterByUpdatedOn = function() {
  return /** @type {!proto.TimeInterval} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.TimeInterval} value
 * @return {!proto.ModelOverviewParameters} returns this
 */
proto.ModelOverviewParameters.prototype.setFilterByUpdatedOn = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ModelsOverviewList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelsOverviewList.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelsOverviewList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelsOverviewList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelsOverviewList.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelList: jspb.Message.toObjectList(msg.getModelList(),
    objects_Asset_pb.Model.toObject, includeInstance),
    apiResponse: (f = msg.getApiResponse()) && common_API_pb.ApiResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelsOverviewList}
 */
proto.ModelsOverviewList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelsOverviewList;
  return proto.ModelsOverviewList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelsOverviewList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelsOverviewList}
 */
proto.ModelsOverviewList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new objects_Asset_pb.Model;
      reader.readMessage(value,objects_Asset_pb.Model.deserializeBinaryFromReader);
      msg.addModel(value);
      break;
    case 2:
      var value = new common_API_pb.ApiResponse;
      reader.readMessage(value,common_API_pb.ApiResponse.deserializeBinaryFromReader);
      msg.setApiResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelsOverviewList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelsOverviewList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelsOverviewList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelsOverviewList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      objects_Asset_pb.Model.serializeBinaryToWriter
    );
  }
  f = message.getApiResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_API_pb.ApiResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Model model = 1;
 * @return {!Array<!proto.Model>}
 */
proto.ModelsOverviewList.prototype.getModelList = function() {
  return /** @type{!Array<!proto.Model>} */ (
    jspb.Message.getRepeatedWrapperField(this, objects_Asset_pb.Model, 1));
};


/**
 * @param {!Array<!proto.Model>} value
 * @return {!proto.ModelsOverviewList} returns this
*/
proto.ModelsOverviewList.prototype.setModelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Model=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Model}
 */
proto.ModelsOverviewList.prototype.addModel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Model, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ModelsOverviewList} returns this
 */
proto.ModelsOverviewList.prototype.clearModelList = function() {
  return this.setModelList([]);
};


/**
 * optional ApiResponse api_response = 2;
 * @return {?proto.ApiResponse}
 */
proto.ModelsOverviewList.prototype.getApiResponse = function() {
  return /** @type{?proto.ApiResponse} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ApiResponse, 2));
};


/**
 * @param {?proto.ApiResponse|undefined} value
 * @return {!proto.ModelsOverviewList} returns this
*/
proto.ModelsOverviewList.prototype.setApiResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ModelsOverviewList} returns this
 */
proto.ModelsOverviewList.prototype.clearApiResponse = function() {
  return this.setApiResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ModelsOverviewList.prototype.hasApiResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: (f = msg.getModel()) && objects_Asset_pb.Model.toObject(includeInstance, f),
    apiResponse: (f = msg.getApiResponse()) && common_API_pb.ApiResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelDetails}
 */
proto.ModelDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelDetails;
  return proto.ModelDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelDetails}
 */
proto.ModelDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new objects_Asset_pb.Model;
      reader.readMessage(value,objects_Asset_pb.Model.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    case 2:
      var value = new common_API_pb.ApiResponse;
      reader.readMessage(value,common_API_pb.ApiResponse.deserializeBinaryFromReader);
      msg.setApiResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      objects_Asset_pb.Model.serializeBinaryToWriter
    );
  }
  f = message.getApiResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_API_pb.ApiResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional Model model = 1;
 * @return {?proto.Model}
 */
proto.ModelDetails.prototype.getModel = function() {
  return /** @type{?proto.Model} */ (
    jspb.Message.getWrapperField(this, objects_Asset_pb.Model, 1));
};


/**
 * @param {?proto.Model|undefined} value
 * @return {!proto.ModelDetails} returns this
*/
proto.ModelDetails.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ModelDetails} returns this
 */
proto.ModelDetails.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ModelDetails.prototype.hasModel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ApiResponse api_response = 2;
 * @return {?proto.ApiResponse}
 */
proto.ModelDetails.prototype.getApiResponse = function() {
  return /** @type{?proto.ApiResponse} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ApiResponse, 2));
};


/**
 * @param {?proto.ApiResponse|undefined} value
 * @return {!proto.ModelDetails} returns this
*/
proto.ModelDetails.prototype.setApiResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ModelDetails} returns this
 */
proto.ModelDetails.prototype.clearApiResponse = function() {
  return this.setApiResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ModelDetails.prototype.hasApiResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NodeDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.NodeDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NodeDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NodeDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && objects_Asset_pb.Node.toObject(includeInstance, f),
    parentNode: (f = msg.getParentNode()) && objects_Asset_pb.Node.toObject(includeInstance, f),
    apiResponse: (f = msg.getApiResponse()) && common_API_pb.ApiResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NodeDetails}
 */
proto.NodeDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NodeDetails;
  return proto.NodeDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NodeDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NodeDetails}
 */
proto.NodeDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new objects_Asset_pb.Node;
      reader.readMessage(value,objects_Asset_pb.Node.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = new objects_Asset_pb.Node;
      reader.readMessage(value,objects_Asset_pb.Node.deserializeBinaryFromReader);
      msg.setParentNode(value);
      break;
    case 3:
      var value = new common_API_pb.ApiResponse;
      reader.readMessage(value,common_API_pb.ApiResponse.deserializeBinaryFromReader);
      msg.setApiResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NodeDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NodeDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NodeDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NodeDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      objects_Asset_pb.Node.serializeBinaryToWriter
    );
  }
  f = message.getParentNode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      objects_Asset_pb.Node.serializeBinaryToWriter
    );
  }
  f = message.getApiResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_API_pb.ApiResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional Node node = 1;
 * @return {?proto.Node}
 */
proto.NodeDetails.prototype.getNode = function() {
  return /** @type{?proto.Node} */ (
    jspb.Message.getWrapperField(this, objects_Asset_pb.Node, 1));
};


/**
 * @param {?proto.Node|undefined} value
 * @return {!proto.NodeDetails} returns this
*/
proto.NodeDetails.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.NodeDetails} returns this
 */
proto.NodeDetails.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NodeDetails.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Node parent_node = 2;
 * @return {?proto.Node}
 */
proto.NodeDetails.prototype.getParentNode = function() {
  return /** @type{?proto.Node} */ (
    jspb.Message.getWrapperField(this, objects_Asset_pb.Node, 2));
};


/**
 * @param {?proto.Node|undefined} value
 * @return {!proto.NodeDetails} returns this
*/
proto.NodeDetails.prototype.setParentNode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.NodeDetails} returns this
 */
proto.NodeDetails.prototype.clearParentNode = function() {
  return this.setParentNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NodeDetails.prototype.hasParentNode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ApiResponse api_response = 3;
 * @return {?proto.ApiResponse}
 */
proto.NodeDetails.prototype.getApiResponse = function() {
  return /** @type{?proto.ApiResponse} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ApiResponse, 3));
};


/**
 * @param {?proto.ApiResponse|undefined} value
 * @return {!proto.NodeDetails} returns this
*/
proto.NodeDetails.prototype.setApiResponse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.NodeDetails} returns this
 */
proto.NodeDetails.prototype.clearApiResponse = function() {
  return this.setApiResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NodeDetails.prototype.hasApiResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.NodeDetailsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NodeDetailsList.prototype.toObject = function(opt_includeInstance) {
  return proto.NodeDetailsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NodeDetailsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NodeDetailsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeList: jspb.Message.toObjectList(msg.getNodeList(),
    objects_Asset_pb.Node.toObject, includeInstance),
    apiResponse: (f = msg.getApiResponse()) && common_API_pb.ApiResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NodeDetailsList}
 */
proto.NodeDetailsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NodeDetailsList;
  return proto.NodeDetailsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NodeDetailsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NodeDetailsList}
 */
proto.NodeDetailsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new objects_Asset_pb.Node;
      reader.readMessage(value,objects_Asset_pb.Node.deserializeBinaryFromReader);
      msg.addNode(value);
      break;
    case 2:
      var value = new common_API_pb.ApiResponse;
      reader.readMessage(value,common_API_pb.ApiResponse.deserializeBinaryFromReader);
      msg.setApiResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NodeDetailsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NodeDetailsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NodeDetailsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NodeDetailsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      objects_Asset_pb.Node.serializeBinaryToWriter
    );
  }
  f = message.getApiResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_API_pb.ApiResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Node node = 1;
 * @return {!Array<!proto.Node>}
 */
proto.NodeDetailsList.prototype.getNodeList = function() {
  return /** @type{!Array<!proto.Node>} */ (
    jspb.Message.getRepeatedWrapperField(this, objects_Asset_pb.Node, 1));
};


/**
 * @param {!Array<!proto.Node>} value
 * @return {!proto.NodeDetailsList} returns this
*/
proto.NodeDetailsList.prototype.setNodeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Node=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Node}
 */
proto.NodeDetailsList.prototype.addNode = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Node, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.NodeDetailsList} returns this
 */
proto.NodeDetailsList.prototype.clearNodeList = function() {
  return this.setNodeList([]);
};


/**
 * optional ApiResponse api_response = 2;
 * @return {?proto.ApiResponse}
 */
proto.NodeDetailsList.prototype.getApiResponse = function() {
  return /** @type{?proto.ApiResponse} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ApiResponse, 2));
};


/**
 * @param {?proto.ApiResponse|undefined} value
 * @return {!proto.NodeDetailsList} returns this
*/
proto.NodeDetailsList.prototype.setApiResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.NodeDetailsList} returns this
 */
proto.NodeDetailsList.prototype.clearApiResponse = function() {
  return this.setApiResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NodeDetailsList.prototype.hasApiResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelTreeParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelTreeParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelTreeParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelTreeParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: (f = msg.getModelId()) && common_API_pb.ID.toObject(includeInstance, f),
    cloudPushEligible: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelTreeParameters}
 */
proto.ModelTreeParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelTreeParameters;
  return proto.ModelTreeParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelTreeParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelTreeParameters}
 */
proto.ModelTreeParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.ID;
      reader.readMessage(value,common_API_pb.ID.deserializeBinaryFromReader);
      msg.setModelId(value);
      break;
    case 2:
      var value = /** @type {!proto.CloudPushEligible} */ (reader.readEnum());
      msg.setCloudPushEligible(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelTreeParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelTreeParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelTreeParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelTreeParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getCloudPushEligible();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional ID model_id = 1;
 * @return {?proto.ID}
 */
proto.ModelTreeParameters.prototype.getModelId = function() {
  return /** @type{?proto.ID} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ID, 1));
};


/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.ModelTreeParameters} returns this
*/
proto.ModelTreeParameters.prototype.setModelId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ModelTreeParameters} returns this
 */
proto.ModelTreeParameters.prototype.clearModelId = function() {
  return this.setModelId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ModelTreeParameters.prototype.hasModelId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CloudPushEligible cloud_push_eligible = 2;
 * @return {!proto.CloudPushEligible}
 */
proto.ModelTreeParameters.prototype.getCloudPushEligible = function() {
  return /** @type {!proto.CloudPushEligible} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.CloudPushEligible} value
 * @return {!proto.ModelTreeParameters} returns this
 */
proto.ModelTreeParameters.prototype.setCloudPushEligible = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NodeDetailsParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.NodeDetailsParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NodeDetailsParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NodeDetailsParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeId: (f = msg.getNodeId()) && common_API_pb.ID.toObject(includeInstance, f),
    cloudPushEligible: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NodeDetailsParameters}
 */
proto.NodeDetailsParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NodeDetailsParameters;
  return proto.NodeDetailsParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NodeDetailsParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NodeDetailsParameters}
 */
proto.NodeDetailsParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.ID;
      reader.readMessage(value,common_API_pb.ID.deserializeBinaryFromReader);
      msg.setNodeId(value);
      break;
    case 2:
      var value = /** @type {!proto.CloudPushEligible} */ (reader.readEnum());
      msg.setCloudPushEligible(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NodeDetailsParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NodeDetailsParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NodeDetailsParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NodeDetailsParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getCloudPushEligible();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional ID node_id = 1;
 * @return {?proto.ID}
 */
proto.NodeDetailsParameters.prototype.getNodeId = function() {
  return /** @type{?proto.ID} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ID, 1));
};


/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.NodeDetailsParameters} returns this
*/
proto.NodeDetailsParameters.prototype.setNodeId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.NodeDetailsParameters} returns this
 */
proto.NodeDetailsParameters.prototype.clearNodeId = function() {
  return this.setNodeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NodeDetailsParameters.prototype.hasNodeId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CloudPushEligible cloud_push_eligible = 2;
 * @return {!proto.CloudPushEligible}
 */
proto.NodeDetailsParameters.prototype.getCloudPushEligible = function() {
  return /** @type {!proto.CloudPushEligible} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.CloudPushEligible} value
 * @return {!proto.NodeDetailsParameters} returns this
 */
proto.NodeDetailsParameters.prototype.setCloudPushEligible = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.NodeIdList.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NodeIdList.prototype.toObject = function(opt_includeInstance) {
  return proto.NodeIdList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NodeIdList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NodeIdList.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiResponse: (f = msg.getApiResponse()) && common_API_pb.ApiResponse.toObject(includeInstance, f),
    nodeIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NodeIdList}
 */
proto.NodeIdList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NodeIdList;
  return proto.NodeIdList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NodeIdList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NodeIdList}
 */
proto.NodeIdList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.ApiResponse;
      reader.readMessage(value,common_API_pb.ApiResponse.deserializeBinaryFromReader);
      msg.setApiResponse(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNodeId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NodeIdList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NodeIdList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NodeIdList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NodeIdList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.ApiResponse.serializeBinaryToWriter
    );
  }
  f = message.getNodeIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional ApiResponse api_response = 1;
 * @return {?proto.ApiResponse}
 */
proto.NodeIdList.prototype.getApiResponse = function() {
  return /** @type{?proto.ApiResponse} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ApiResponse, 1));
};


/**
 * @param {?proto.ApiResponse|undefined} value
 * @return {!proto.NodeIdList} returns this
*/
proto.NodeIdList.prototype.setApiResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.NodeIdList} returns this
 */
proto.NodeIdList.prototype.clearApiResponse = function() {
  return this.setApiResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NodeIdList.prototype.hasApiResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated int64 node_id = 2;
 * @return {!Array<number>}
 */
proto.NodeIdList.prototype.getNodeIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.NodeIdList} returns this
 */
proto.NodeIdList.prototype.setNodeIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.NodeIdList} returns this
 */
proto.NodeIdList.prototype.addNodeId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.NodeIdList} returns this
 */
proto.NodeIdList.prototype.clearNodeIdList = function() {
  return this.setNodeIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NodeChildMapList.prototype.toObject = function(opt_includeInstance) {
  return proto.NodeChildMapList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NodeChildMapList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NodeChildMapList.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiResponse: (f = msg.getApiResponse()) && common_API_pb.ApiResponse.toObject(includeInstance, f),
    nodeChildMapJson: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NodeChildMapList}
 */
proto.NodeChildMapList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NodeChildMapList;
  return proto.NodeChildMapList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NodeChildMapList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NodeChildMapList}
 */
proto.NodeChildMapList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.ApiResponse;
      reader.readMessage(value,common_API_pb.ApiResponse.deserializeBinaryFromReader);
      msg.setApiResponse(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeChildMapJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NodeChildMapList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NodeChildMapList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NodeChildMapList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NodeChildMapList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.ApiResponse.serializeBinaryToWriter
    );
  }
  f = message.getNodeChildMapJson();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ApiResponse api_response = 1;
 * @return {?proto.ApiResponse}
 */
proto.NodeChildMapList.prototype.getApiResponse = function() {
  return /** @type{?proto.ApiResponse} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ApiResponse, 1));
};


/**
 * @param {?proto.ApiResponse|undefined} value
 * @return {!proto.NodeChildMapList} returns this
*/
proto.NodeChildMapList.prototype.setApiResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.NodeChildMapList} returns this
 */
proto.NodeChildMapList.prototype.clearApiResponse = function() {
  return this.setApiResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NodeChildMapList.prototype.hasApiResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string node_child_map_json = 2;
 * @return {string}
 */
proto.NodeChildMapList.prototype.getNodeChildMapJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.NodeChildMapList} returns this
 */
proto.NodeChildMapList.prototype.setNodeChildMapJson = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetChildNodesParams.prototype.toObject = function(opt_includeInstance) {
  return proto.GetChildNodesParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetChildNodesParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetChildNodesParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nodeLevel: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetChildNodesParams}
 */
proto.GetChildNodesParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetChildNodesParams;
  return proto.GetChildNodesParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetChildNodesParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetChildNodesParams}
 */
proto.GetChildNodesParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNodeLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetChildNodesParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetChildNodesParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetChildNodesParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetChildNodesParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNodeLevel();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 model_id = 1;
 * @return {number}
 */
proto.GetChildNodesParams.prototype.getModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GetChildNodesParams} returns this
 */
proto.GetChildNodesParams.prototype.setModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 node_level = 2;
 * @return {number}
 */
proto.GetChildNodesParams.prototype.getNodeLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.GetChildNodesParams} returns this
 */
proto.GetChildNodesParams.prototype.setNodeLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.StreamOverviewList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StreamOverviewList.prototype.toObject = function(opt_includeInstance) {
  return proto.StreamOverviewList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StreamOverviewList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamOverviewList.toObject = function(includeInstance, msg) {
  var f, obj = {
    streamsList: jspb.Message.toObjectList(msg.getStreamsList(),
    objects_Asset_pb.Stream.toObject, includeInstance),
    apiResponse: (f = msg.getApiResponse()) && common_API_pb.ApiResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StreamOverviewList}
 */
proto.StreamOverviewList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StreamOverviewList;
  return proto.StreamOverviewList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StreamOverviewList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StreamOverviewList}
 */
proto.StreamOverviewList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new objects_Asset_pb.Stream;
      reader.readMessage(value,objects_Asset_pb.Stream.deserializeBinaryFromReader);
      msg.addStreams(value);
      break;
    case 2:
      var value = new common_API_pb.ApiResponse;
      reader.readMessage(value,common_API_pb.ApiResponse.deserializeBinaryFromReader);
      msg.setApiResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StreamOverviewList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StreamOverviewList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StreamOverviewList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamOverviewList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      objects_Asset_pb.Stream.serializeBinaryToWriter
    );
  }
  f = message.getApiResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_API_pb.ApiResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Stream streams = 1;
 * @return {!Array<!proto.Stream>}
 */
proto.StreamOverviewList.prototype.getStreamsList = function() {
  return /** @type{!Array<!proto.Stream>} */ (
    jspb.Message.getRepeatedWrapperField(this, objects_Asset_pb.Stream, 1));
};


/**
 * @param {!Array<!proto.Stream>} value
 * @return {!proto.StreamOverviewList} returns this
*/
proto.StreamOverviewList.prototype.setStreamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Stream=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Stream}
 */
proto.StreamOverviewList.prototype.addStreams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Stream, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.StreamOverviewList} returns this
 */
proto.StreamOverviewList.prototype.clearStreamsList = function() {
  return this.setStreamsList([]);
};


/**
 * optional ApiResponse api_response = 2;
 * @return {?proto.ApiResponse}
 */
proto.StreamOverviewList.prototype.getApiResponse = function() {
  return /** @type{?proto.ApiResponse} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ApiResponse, 2));
};


/**
 * @param {?proto.ApiResponse|undefined} value
 * @return {!proto.StreamOverviewList} returns this
*/
proto.StreamOverviewList.prototype.setApiResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StreamOverviewList} returns this
 */
proto.StreamOverviewList.prototype.clearApiResponse = function() {
  return this.setApiResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StreamOverviewList.prototype.hasApiResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StreamDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.StreamDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StreamDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    stream: (f = msg.getStream()) && objects_Asset_pb.Stream.toObject(includeInstance, f),
    apiResponse: (f = msg.getApiResponse()) && common_API_pb.ApiResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StreamDetails}
 */
proto.StreamDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StreamDetails;
  return proto.StreamDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StreamDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StreamDetails}
 */
proto.StreamDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new objects_Asset_pb.Stream;
      reader.readMessage(value,objects_Asset_pb.Stream.deserializeBinaryFromReader);
      msg.setStream(value);
      break;
    case 2:
      var value = new common_API_pb.ApiResponse;
      reader.readMessage(value,common_API_pb.ApiResponse.deserializeBinaryFromReader);
      msg.setApiResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StreamDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StreamDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StreamDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStream();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      objects_Asset_pb.Stream.serializeBinaryToWriter
    );
  }
  f = message.getApiResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_API_pb.ApiResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional Stream stream = 1;
 * @return {?proto.Stream}
 */
proto.StreamDetails.prototype.getStream = function() {
  return /** @type{?proto.Stream} */ (
    jspb.Message.getWrapperField(this, objects_Asset_pb.Stream, 1));
};


/**
 * @param {?proto.Stream|undefined} value
 * @return {!proto.StreamDetails} returns this
*/
proto.StreamDetails.prototype.setStream = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StreamDetails} returns this
 */
proto.StreamDetails.prototype.clearStream = function() {
  return this.setStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StreamDetails.prototype.hasStream = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ApiResponse api_response = 2;
 * @return {?proto.ApiResponse}
 */
proto.StreamDetails.prototype.getApiResponse = function() {
  return /** @type{?proto.ApiResponse} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ApiResponse, 2));
};


/**
 * @param {?proto.ApiResponse|undefined} value
 * @return {!proto.StreamDetails} returns this
*/
proto.StreamDetails.prototype.setApiResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StreamDetails} returns this
 */
proto.StreamDetails.prototype.clearApiResponse = function() {
  return this.setApiResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StreamDetails.prototype.hasApiResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DataTypeList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DataTypeList.prototype.toObject = function(opt_includeInstance) {
  return proto.DataTypeList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DataTypeList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataTypeList.toObject = function(includeInstance, msg) {
  var f, obj = {
    streamDataTypesList: jspb.Message.toObjectList(msg.getStreamDataTypesList(),
    objects_Asset_pb.StreamDataType.toObject, includeInstance),
    apiResponse: (f = msg.getApiResponse()) && common_API_pb.ApiResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DataTypeList}
 */
proto.DataTypeList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DataTypeList;
  return proto.DataTypeList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DataTypeList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DataTypeList}
 */
proto.DataTypeList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new objects_Asset_pb.StreamDataType;
      reader.readMessage(value,objects_Asset_pb.StreamDataType.deserializeBinaryFromReader);
      msg.addStreamDataTypes(value);
      break;
    case 2:
      var value = new common_API_pb.ApiResponse;
      reader.readMessage(value,common_API_pb.ApiResponse.deserializeBinaryFromReader);
      msg.setApiResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DataTypeList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DataTypeList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DataTypeList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataTypeList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreamDataTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      objects_Asset_pb.StreamDataType.serializeBinaryToWriter
    );
  }
  f = message.getApiResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_API_pb.ApiResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StreamDataType stream_data_types = 1;
 * @return {!Array<!proto.StreamDataType>}
 */
proto.DataTypeList.prototype.getStreamDataTypesList = function() {
  return /** @type{!Array<!proto.StreamDataType>} */ (
    jspb.Message.getRepeatedWrapperField(this, objects_Asset_pb.StreamDataType, 1));
};


/**
 * @param {!Array<!proto.StreamDataType>} value
 * @return {!proto.DataTypeList} returns this
*/
proto.DataTypeList.prototype.setStreamDataTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.StreamDataType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.StreamDataType}
 */
proto.DataTypeList.prototype.addStreamDataTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.StreamDataType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DataTypeList} returns this
 */
proto.DataTypeList.prototype.clearStreamDataTypesList = function() {
  return this.setStreamDataTypesList([]);
};


/**
 * optional ApiResponse api_response = 2;
 * @return {?proto.ApiResponse}
 */
proto.DataTypeList.prototype.getApiResponse = function() {
  return /** @type{?proto.ApiResponse} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ApiResponse, 2));
};


/**
 * @param {?proto.ApiResponse|undefined} value
 * @return {!proto.DataTypeList} returns this
*/
proto.DataTypeList.prototype.setApiResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DataTypeList} returns this
 */
proto.DataTypeList.prototype.clearApiResponse = function() {
  return this.setApiResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DataTypeList.prototype.hasApiResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StreamFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.StreamFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StreamFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    parentNodeId: (f = msg.getParentNodeId()) && common_API_pb.ID.toObject(includeInstance, f),
    cloudPushEligible: jspb.Message.getFieldWithDefault(msg, 3, 0),
    includeChildNodes: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StreamFilter}
 */
proto.StreamFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StreamFilter;
  return proto.StreamFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StreamFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StreamFilter}
 */
proto.StreamFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.StreamFilter.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = new common_API_pb.ID;
      reader.readMessage(value,common_API_pb.ID.deserializeBinaryFromReader);
      msg.setParentNodeId(value);
      break;
    case 3:
      var value = /** @type {!proto.CloudPushEligible} */ (reader.readEnum());
      msg.setCloudPushEligible(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeChildNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StreamFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StreamFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StreamFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getParentNodeId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_API_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getCloudPushEligible();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getIncludeChildNodes();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.StreamFilter.Status = {
  ALL: 0,
  ALL_MAPPED: 1,
  ALL_UNMAPPED: 2
};

/**
 * optional Status status = 1;
 * @return {!proto.StreamFilter.Status}
 */
proto.StreamFilter.prototype.getStatus = function() {
  return /** @type {!proto.StreamFilter.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.StreamFilter.Status} value
 * @return {!proto.StreamFilter} returns this
 */
proto.StreamFilter.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ID parent_node_id = 2;
 * @return {?proto.ID}
 */
proto.StreamFilter.prototype.getParentNodeId = function() {
  return /** @type{?proto.ID} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ID, 2));
};


/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.StreamFilter} returns this
*/
proto.StreamFilter.prototype.setParentNodeId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StreamFilter} returns this
 */
proto.StreamFilter.prototype.clearParentNodeId = function() {
  return this.setParentNodeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StreamFilter.prototype.hasParentNodeId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CloudPushEligible cloud_push_eligible = 3;
 * @return {!proto.CloudPushEligible}
 */
proto.StreamFilter.prototype.getCloudPushEligible = function() {
  return /** @type {!proto.CloudPushEligible} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.CloudPushEligible} value
 * @return {!proto.StreamFilter} returns this
 */
proto.StreamFilter.prototype.setCloudPushEligible = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool include_child_nodes = 4;
 * @return {boolean}
 */
proto.StreamFilter.prototype.getIncludeChildNodes = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.StreamFilter} returns this
 */
proto.StreamFilter.prototype.setIncludeChildNodes = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StreamName.prototype.toObject = function(opt_includeInstance) {
  return proto.StreamName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StreamName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamName.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StreamName}
 */
proto.StreamName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StreamName;
  return proto.StreamName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StreamName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StreamName}
 */
proto.StreamName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StreamName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StreamName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StreamName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.StreamName.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.StreamName} returns this
 */
proto.StreamName.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto);
