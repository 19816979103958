import { Dropdownprops } from "types";
import { Select } from "antd";
import "./index.scss";
import { ReactComponent as DownArrow } from "assets/icons/dropdownIcon.svg";
import { useState } from "react";

export const Dropdown: React.FC<Dropdownprops> = ({
  optionData,
  placeHolder,
  selectedValue,
  setFilter,
  setSku,
  screen,
  setValueName,
  data,
  selectedItem,
  DefaultValue,
  disabled
}) => {
  const { Option } = Select;
  // let defaultValue: any = "0";
  // {
  //   screen && screen === "alarms" ? (defaultValue = "0") : (defaultValue = "1");
  // }
  const [value, setValue] = useState(DefaultValue);
  const onChange = (value: string, option: any) => {
    if (selectedValue) {
      selectedValue(value);
      setValue(option.value);
    }
    if(setSku) setSku(value);
    setValue(value);
    if (setValueName) {
      setValueName(option.children);
    }
    if (setValueName) setValueName(option.children);
    if (setFilter) setFilter(value);
  };

  return (
    <Select
      style={{
        width: "84px",
        height: "30px",
      }}
      value={selectedItem ? selectedItem : value}
      showSearch
      placeholder={placeHolder}
      optionFilterProp="children"
      onChange={onChange}
      filterOption={true}
      suffixIcon={<DownArrow />}
      defaultValue={DefaultValue}
      disabled={disabled}
    >
      {optionData?.map((item: any) => {
        return (
          <>
            <Option value={item.value}>{item.category}</Option>
          </>
        );
      })}
      {data?.map((item: any) => {
        return (
          <>
            <Option value={item.code}>{item.category}</Option>
          </>
        );
      })}
    </Select>
  );
};
