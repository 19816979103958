// @ts-nocheck
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { getUrl } from 'config/apiconfig';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

export const Graph = (props: any) => {
  const assetURL = getUrl('assetServiceGrpc');
  const timeStampProtoObj = require('protobuf/asset_proto/service/HistorianRpc_grpc_web_pb');
  const {
    HistorianServiceClient,
  } = require('protobuf/asset_proto/service/HistorianRpc_grpc_web_pb');
  const timeSeriesServiceClient = new HistorianServiceClient(
    assetURL,
    null,
    null
  );
  const {
    data,
    type,
    numberOfSeries,
    fullScreenMode,
    exportTitle,
    currentTagsArr,
    handleSelectedDateRange,
    setLegendsColor,
    isStreamMode,
    setliveStreamClientId,
    showVerticalLine,
    showHorizonalLine,
    setStreamSparkline,
    sparklineData,
  } = props;
  const graphEle = useRef<any>();
  const chartRef = useRef<any>(null);
  const dispatch = useDispatch();
  const [zoomedIn, setZoomedIn] = useState<boolean>(false);
  const [liveStreamId, setLiveStreamId] = useState<string>('');

  let timeout,
    legendsColors = {};
  useEffect(() => {
    var dateAxisRangeAdded = false,
      valueAxisRangeAdded = false;
    function createSeries(
      field: string,
      type: string,
      currentTagNumber: number,
      currentTagName?: string,
      fillOpacity?: number
    ) {
      let series = chartRef.current.series.push(new am4charts[type]());
      series.dataFields.valueY = field;
      series.dataFields.dateX = 'date';
      series.dataFields.tagId = 'tagName';
      series.dataFields.deviceName = 'deviceName';
      series.strokeWidth = 2;
      series.minBulletDistance = 10;
      series.tooltipText =
        "Tag Name: {name}\nValue: {valueY}\n Time: {dateX.formatDate('yyyy-MM-dd HH:mm:ss.SSS')}\n Device Name: {deviceName}";
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltip.background.cornerRadius = 20;
      series.tooltip.label.padding(8, 8, 8, 8);
      series.tooltip.fontSize = 10;
      series.fillOpacity = fillOpacity;
      series.name = currentTagName;
      if (currentTagName !== undefined) {
        legendsColors[currentTagName] =
          chartRef.current.colors.getIndex(currentTagNumber).hex;
        setLegendsColor(legendsColors);
      }
      if (
        chartRef.current.data.length &&
        !dateAxisRangeAdded &&
        showVerticalLine
      ) {
        var seriesRange = dateAxis.createSeriesRange(series);
        seriesRange.contents.strokeDasharray = '2,3';
        seriesRange.contents.stroke = chartRef.current.colors.getIndex(8);
        seriesRange.contents.strokeWidth = 1;
        //add vertical range
        var range = dateAxis.axisRanges.push(new am4charts.DateAxisDataItem());
        range.grid.stroke = chartRef.current.colors.getIndex(0);
        range.grid.strokeOpacity = 1;
        range.bullet = new am4core.ResizeButton();
        range.bullet.background.fill = chartRef.current.colors.getIndex(0);
        range.bullet.background.states.copyFrom(
          chartRef.current.zoomOutButton.background.states
        );
        range.bullet.minX = 0;
        range.bullet.adapter.add('minY', function (minY, target) {
          target.maxY = chartRef.current.plotContainer.maxHeight;
          target.maxX = chartRef.current.plotContainer.maxWidth;
          return chartRef.current.plotContainer.maxHeight;
        });

        range.bullet.events.on('dragged', function () {
          range.value = dateAxis.xToValue(range.bullet.pixelX);
          seriesRange.value = range.value;
        });

        var firstTime = chartRef.current.data[0].date.getTime();
        var lastTime =
          chartRef.current.data[
            chartRef.current.data.length - 1
          ].date.getTime();
        var date = new Date(firstTime + (lastTime - firstTime) / 2);

        range.date = date;

        seriesRange.date = date;
        seriesRange.endDate =
          chartRef.current.data[chartRef.current.data.length - 1].date;
        dateAxisRangeAdded = true;
      }
    }

    chartRef.current = am4core.create(graphEle.current, am4charts.XYChart);
    chartRef.current.data = data;

    // Create axes
    let dateAxis = chartRef.current.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 30;

    dateAxis.groupData = true;
    dateAxis.groupCount = 500;

    let valueAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis());
      chartRef.current.scrollbarX = new am4core.Scrollbar();

    // Add cursor
    chartRef.current.cursor = new am4charts.XYCursor();
    chartRef.current.cursor.xAxis = dateAxis;

    // Remove zoom out button.

    // Export Menu Options
    chartRef.current.exporting.menu = new am4core.ExportMenu();
    chartRef.current.exporting.menu.container = document.getElementById(
      'trending-graph-heading-tools'
    );
    chartRef.current.exporting.menu.items[0].icon =
      'assets/images/icons/export-menu.svg';
    chartRef.current.exporting.filePrefix = exportTitle;

    dateAxis.events.on('startendchanged', dateAxisChanged);
    function dateAxisChanged(ev) {
      let start = moment(new Date(ev.target.minZoomed));
      let end = moment(new Date(ev.target.maxZoomed));
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        handleSelectedDateRange([start, end]);
      }, 100);
    }
    // Live Mode
    let request;
    if (isStreamMode) {
      request = new timeStampProtoObj.TimeStampStreamingQuery();
      let currentTags: Array<string> = [];
      for (let i = 1; i <= currentTagsArr.length; i++) {
        const lastTagId = currentTagsArr[currentTagsArr.length - i]['tagId'];
        let ID = new timeStampProtoObj.Uuid();
        ID.setUuid(lastTagId);
        currentTags.push(ID);
      }
      request.setTagIdList(currentTags);
      request.setStreamingIntervalMs(1000);
      let streamResponse = timeSeriesServiceClient.streamTimeStampRawData(
        request,
        {}
      );
      streamResponse.on('data', (response) => {
        setliveStreamClientId(response.getClientId().getUuid());
        setLiveStreamId(response.getClientId().getUuid());
        let tempTagData: object = {},
          i = 1;
        let currentTagSparklineData = { ...sparklineData };
        response.getStreamsList().forEach((responseElement) => {
          responseElement.getReadingsList().forEach((dataPoint) => {
            tempTagData['date'] = new Date(dataPoint.getTimestamp());
            if (tempTagData['date']) {
              tempTagData['value' + i] = dataPoint.getValue();
              if (dataPoint.getValue()) {
                currentTagSparklineData[responseElement.getUuid()].shift();
                currentTagSparklineData[responseElement.getUuid()].push([
                  dataPoint.getValue(),
                ]);
              }
              i++;
            }
          });
        });
        setStreamSparkline(currentTagSparklineData);
        if (tempTagData['date']) {
          chartRef.current.data.shift();
          chartRef.current.addData(tempTagData);
        }
      });
    } else if (liveStreamId) {
      request = new timeStampProtoObj.CloseRawDataStreamingReq();
      let ID = new timeStampProtoObj.Uuid();
      ID.setUuid(liveStreamId);
      request.setClientId(ID);
      timeSeriesServiceClient.closeRawDataStreaming(request, {});
    }
    // for legends in chart
    chartRef.current.legend = new am4charts.Legend();
    chartRef.current.legend.position = 'bottom';
    chartRef.current.legend.scrollable = true;
    chartRef.current.legend.fontSize = 8;

    setZoomedIn(true);
    switch (type) {
      case 'line':
        for (let i = 0; i < currentTagsArr.length; i++) {
          createSeries(
            'value' + (i + 1),
            'LineSeries',
            i,
            currentTagsArr[i]['tagName']
          );
        }
        break;
      case 'column':
        for (let i = 0; i < numberOfSeries; i++) {
          createSeries(
            'value' + (i + 1),
            'ColumnSeries',
            i,
            currentTagsArr[i]['tagName']
          );
        }
        break;
      case 'area':
        for (let i = 0; i < numberOfSeries; i++) {
          createSeries(
            'value' + (i + 1),
            'LineSeries',
            i,
            currentTagsArr[i]['tagName'],
            0.5
          );
        }
        break;
      case 'step':
        for (let i = 0; i < numberOfSeries; i++) {
          createSeries(
            'value' + (i + 1),
            'StepLineSeries',
            i,
            currentTagsArr[i]['tagName']
          );
        }
        break;
      default:
        for (let i = 0; i < numberOfSeries; i++) {
          createSeries(
            'value' + (i + 1),
            'LineSeries',
            i,
            currentTagsArr[i]['tagName']
          );
        }
    }

    if (document.getElementById('zoom-in-button') !== null) {
      // @ts-ignore
      document
        .getElementById('zoom-in-button')
        .addEventListener('click', function () {
          var diff = dateAxis.maxZoomed - dateAxis.minZoomed;
          var delta = diff * 0.2;
          dateAxis.zoomToDates(
            new Date(dateAxis.minZoomed + delta),
            new Date(dateAxis.maxZoomed - delta)
          );
        });
    }

    if (document.getElementById('zoom-out-button') !== null) {
      // @ts-ignore
      document
        .getElementById('zoom-out-button')
        .addEventListener('click', function () {
          handleSelectedDateRange();
        });
    }
  }, [
    type,
    data,
    numberOfSeries,
    isStreamMode,
    showVerticalLine,
    showHorizonalLine,
  ]);

  // useEffect(() => {
  //   if (fullScreenMode && chartRef.current) {
  //     // Add scrollbar

  //     chartRef.current.scrollbarX = new am4core.Scrollbar();
  //   } else if (chartRef.current && chartRef.current.scrollbarX) {
  //     chartRef.current.scrollbarX = new am4core.Scrollbar();
  //   }
  // }, [fullScreenMode]);

  return <div className="graph" ref={graphEle}></div>;
};
