import { message } from "antd";
import { getUrl } from "config/apiconfig";
import {
  mapFPLineDetails,
  mapProcessLines,
  mapStatusToMachine,
} from "mappers/operator.mapper";

const { Lines } = require("protobuf/upl-sandbach-protos/common/Enum_pb");
export class OperatorService {
  private static instance: OperatorService;
  private commonProtoObj: any;
  private productionCommonObj: any;
  private operatorServiceClient: any;
  private productionoverviewServiceClient: any;
  private operatorProtoObj: any;
  private productionOverviewProtoObj: any;
  private productionOverviewModelProtoObj: any;
  private authToken: any = "";
  private metadata: any;
  private operatorModelProto: any;

  public static getInstance(): OperatorService {
    if (!OperatorService.instance) {
      OperatorService.instance = new OperatorService();
    }
    return OperatorService.instance;
  }

  private constructor() {
    const reasonCodeURL = getUrl("reasonCodeUrl");
    const productionOverviewURL = getUrl("productionOverviewServiceUrl");
    this.commonProtoObj = require("protobuf/reason-code-management-protos/common/Commons_pb");
    this.productionCommonObj = require("protobuf/upl-sandbach-protos/common/Common_pb");
    const operatorService = require("protobuf/reason-code-management-protos/service/ReasonCodeOperatorService_grpc_web_pb");
    const productionOverviewService = require("protobuf/upl-sandbach-protos/service/ProductionOverview_grpc_web_pb");
    this.productionoverviewServiceClient =
      new productionOverviewService.ProductionOverviewServicePromiseClient(
        productionOverviewURL,
        null,
        null
      );
    this.operatorServiceClient =
      new operatorService.ReasonCodeOperatorServicePromiseClient(
        reasonCodeURL,
        null,
        null
      );
    this.operatorProtoObj = require("protobuf/reason-code-management-protos/service/ReasonCodeOperatorService_pb");
    this.productionOverviewProtoObj = require("protobuf/upl-sandbach-protos/service/ProductionOverview_pb");
    this.productionOverviewModelProtoObj = require("protobuf/upl-sandbach-protos/model/ProductionOverview_pb");
    this.authToken = localStorage.getItem("authToken");
    this.metadata = { authorization: "Bearer " + this.authToken };
    this.operatorModelProto = require("protobuf/reason-code-management-protos/model/ReasonCodeOperatorModel_pb");
  }

  getProcessLines = () => {
    const request = new this.commonProtoObj.GeneralRequest();
    request.setRequestedby("brabo");
    request.setRequestedfor("process-line-info");
    return this.operatorServiceClient
      .getProcessLineInfo(request, this.metadata)
      .then((response: any) => {
        return mapProcessLines(response.getProcessLineListList());
      })
      .catch((err: any) => {
        console.error(err);
        message.error("Unable to Load FP Line Details");
      });
  };

  getLineValue = (payload: any) => {
    switch (payload) {
      case 0:
        return Lines.F1;
      case 1:
        return Lines.F2;
      case 2:
        return Lines.F3;
    }
  };

  getBatchesByLine = (payload: string) => {
    const request = new this.productionOverviewProtoObj.LineFilterRequest();
    const generalRequest = new this.productionCommonObj.GeneralRequest();
    generalRequest.setRequestedBy("brabo-web");
    generalRequest.setRequestedService(`get-batches-by-line-${payload}`);
    request.setRequest(generalRequest);
    const lineFilter = new this.productionOverviewModelProtoObj.LineFilter();
    lineFilter.setLine(this.getLineValue(parseInt(payload)));
    request.setLineFilter(lineFilter);
    return this.productionoverviewServiceClient
      .getBatchesByLine(request, this.metadata)
      .then((response: any) => {
        return mapFPLineDetails(response.getBatchesList()[0]);
      })
      .catch((err: any) => {
        console.error("error in service", err);
        message.error("Unable to Load FP Line Details");
      });
  };

  assignOperatorLog = (payload: any) => {
    const request = new this.operatorProtoObj.ReasonCodeAssignRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedby("brabo");
    generalRequest.setRequestedfor("process-line-info");
    if (payload.reasonCodeId && payload.reasonCodeId !== "") {
      request.setStoppageIdList(payload.stoppageIdList);
      request.setReasonCodeId(payload.reasonCodeId);
      request.setGeneralRequest(generalRequest);
    }
    return this.operatorServiceClient
      .assignReasonCodeToLog(request, this.metadata)
      .then((response: any) => {
        message.success("Reason Code Details has been assigned to log");
      })
      .catch((err: any) => {
        message.error("Unable to get Assign Reason Code Details to log");
      });
  };

  getMachineStatus = () => {
    const request = new this.operatorProtoObj.MachinesRunningStatusRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedby("brabo");
    generalRequest.setRequestedfor("process-line-info");
    request.setGeneralRequest(generalRequest);
    return this.operatorServiceClient
      .getMachinesRunningStatus(request, this.metadata)
      .then((response: any) => {
        return mapStatusToMachine(response.getMachineStatusInfoList());
      })
      .catch((err: any) => {
        message.error("Unable to get machine status");
      });
  };
}
