import { ReactComponent as UplLogo } from "assets/icons/uplLogo.svg";
import { Text } from "../Text";
import "./login.scss";

const LoginFooter: React.FC = () => {
  return (
    <div className="application-upl">
      <div className={"application-text"}>
        <UplLogo /> <Text type="p14">Application for UPL Sandbach </Text>
      </div>

      <div className="policy-and-terms">
        <Text type="p14">
          © 2022 Solulever B.V. All rights reserved.
          <a
            href={"https://www.solulever.com/privacy-policy"}
            rel={"noreferrer noopener"}
            target="_blank"
          >
            {" "}
            Privacy Policy
          </a>{" "}
          |{" "}
          <a
            href={"https://www.solulever.com/terms-of-use"}
            rel={"noreferrer noopener"}
            target="_blank"
          >
            Terms of Service{" "}
          </a>
        </Text>
      </div>
    </div>
  );
};

export default LoginFooter;
