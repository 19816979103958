import { RuntimeDataCardProps } from "types";
import "./index.scss";
const RuntimeDataCard: React.FC<RuntimeDataCardProps> = ({
  value,
  heading,
  color,
  difference,
  previousValue,
}) => {
  return (
    <div
      className="runtime-data-card-container"
      style={{
        border: `1px solid ${color}`,
        boxShadow: `inset 4px 0px 0px ${color}`,
      }}
    >
      <div className="runtime-left">
        <div className="heading">{heading}</div>
        <div className="value" style={{ color: `${color}` }}>
          {value}
        </div>
        <div className="percentage-difference" style={{ color: `${color}` }}>
          {difference}
        </div>
      </div>
      <div className="runtime-right">
        <div className="heading">Last {heading}</div>
        <div className="value" style={{ color: `${color}` }}>
          {previousValue}
        </div>
      </div>
    </div>
  );
};

export default RuntimeDataCard;
