// @ts-nocheck
import { message } from "antd";
import { getUrl } from "config/apiconfig";
import { shortHandLabel } from "utils/common";
import { milliToMins } from "utils/milliToMinutes";
import { object } from "yup";
const {
  ApiStatusCode,
} = require("protobuf/dashboard_config_proto/common/Enums_pb");

export class LineOverviewService {
  private static instance: LineOverviewService;
  private lineOverviewServiceClient: any;
  private lineOverviewProtoObj: any;
  private lineOverviewModel: any;
  private authToken: any = "";
  private metadata: any;

  private constructor() {
    const lineOverviewUrl = getUrl("reasonCodeUrl");
    this.authToken = localStorage.getItem("authToken");
    this.metadata = { authorization: "Bearer " + this.authToken };
    this.lineOverviewModel = require("protobuf/reason-code-management-protos/model/ReasonCodeOperatorModel_pb");
    const lineOverviewService = require("protobuf/reason-code-management-protos/service/ReasonCodeDashboardService_grpc_web_pb");
    this.lineOverviewProtoObj = require("protobuf/reason-code-management-protos/service/ReasonCodeDashboardService_pb");
    this.lineOverviewServiceClient =
      new lineOverviewService.ReasonCodeDashboardServicePromiseClient(
        lineOverviewUrl,
        null,
        null
      );
  }

  public static getInstance(): LineOverviewService {
    if (!LineOverviewService.instance) {
      LineOverviewService.instance = new LineOverviewService();
    }
    return LineOverviewService.instance;
  }

  getOEEData = (payload: any) => {
    const request = new this.lineOverviewProtoObj.LineOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    if (payload.sku && payload.sku !== ""){
      request.setSku(payload.sku);
    }
    request.setNodeIdList(payload.nodeId);
    return this.lineOverviewServiceClient
      .getOEEParams(request, this.metadata)
      .then((response: any) => {
        let data = {};
        data["OEE"] = response.getOee();
        data["Availability"] = response.getAvailability();
        data["Performance"] = response.getPerformance();
        data["Quality"] = response.getQuality();
        return mapOeeData(data);
      })
      .catch((err: any) => {
        message.error("Unable to get Line Overview OEE Data");
      });
  };

  getTeepMatrixData = (payload: any) => {
    const request = new this.lineOverviewProtoObj.LineOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    request.setSku(payload.sku);
    request.setNodeIdList(payload.nodeId);

    return this.lineOverviewServiceClient
      .getTeepMatrix(request, this.metadata)
      .then((response: any) => {
        let data = {};
        data["Teep"] = response.getTeep();
        data["Utilization"] = response.getUtilization();
        data["Scheduled Loss Time"] = response.getScheduledlosstime();
        return mapTeepData(data);
      })
      .catch((err: any) => {
        return [];
        message.error("Unable to get Line Overview OEE Data");
      });
  };

  getDownTimeData = (payload: any) => {
    const request = new this.lineOverviewProtoObj.LineOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    request.setSku(payload.sku);
    request.setNodeIdList(payload.nodeId);

    return this.lineOverviewServiceClient
      .getDowntimeTrend(request, this.metadata)
      .then((response: any) => {
        let data = {};
        data["Planned Downtime"] = response.getPlannedCount();
        data["Unplanned Downtime"] = response.getUnplannedCount();
        data["Small Stops"] = response.getSmallStopsCount();
        data["Quality Stops"] = response.getQualityStopsCount();
        data["Not Scheduled Stops"] = response.getNotScheduledStopsCount();
        data["Other Stops"] = response.getOtherStopsCount();
        return mapDownTimeData(data);
      })
      .catch((err: any) => {
        message.error("Unable to get Line Overview OEE Data");
      });
  };

  fetchRuntimeDowntime = (payload: any) => {
    const request = new this.lineOverviewProtoObj.LineOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    request.setSku(payload.sku);
    request.setNodeIdList(payload.nodeId);
    return this.lineOverviewServiceClient
      .getRuntimeDowntime(request, this.metadata)
      .then((response: any) => {
        let data = {};
        data["runTime"] = response.getRuntime();
        data["downTime"] = response.getDowntime();
        return data;
      })
      .catch((err: any) => {
        message.error("Unable to get Line Overview OEE Data");
      });
  };

  getRateMatrixData = (payload: any) => {
    const request = new this.lineOverviewProtoObj.LineOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    request.setSku(payload.sku);
    request.setNodeIdList(payload.nodeId);
    return this.lineOverviewServiceClient
      .getRateMatrix(request, this.metadata)
      .then((response: any) => {
        return mapRateMatrix(response.getMachineRateCountList());
      })
      .catch((err: any) => {
        message.error("Unable to get Line Overview OEE Data");
      });
  };

  getLostTimeMatrixData = (payload: any) => {
    const request = new this.lineOverviewProtoObj.LineOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    request.setSku(payload.sku);
    request.setNodeIdList(payload.nodeId);

    return this.lineOverviewServiceClient
      .getLostTimeMatrix(request, this.metadata)
      .then((response: any) => {
        return mapLostTimeMatrix(response.getLostTimeMatrixList());
      })
      .catch((err: any) => {
        message.error("Unable to get Line Overview OEE Data");
      });
  };
}

const mapRateMatrix = (data: any) => {
  let rateMatrixData: any[] = [];
  data.forEach((element: any) => {
    rateMatrixData.push({
      totalSpeedPpm: element.getTotalSpeedPpm(),
      totalSpeedPph: element.getTotalSpeedPph(),
      rejectSpeedPpm: element.getRejectSpeedPpm(),
      goodSpeedPph: element.getGoodSpeedPph(),
      inSpeedPph: element.getInSpeedPph(),
      goodSpeedPpm: element.getGoodSpeedPpm(),
      inSpeedPpm: element.getInSpeedPpm(),
      machineName: shortHandLabel(element.getMachineInfo().getMachineName()),
      machineId: element.getMachineInfo().getMachineId(),
      machineType: element.getMachineInfo().getType(),
      machineStatus: element.getMachineInfo().getStatus(),
    });
  });

  return rateMatrixData;
};

const mapLostTimeMatrix = (data: any) => {
  let lostTimeData: any[] = [];
  data.forEach((element: any) => {
    lostTimeData.push({
      machineName: shortHandLabel(element.getMachineName()),
      downLostTime: milliToMins(element.getDownLostTime()),
      plannedStopTime: milliToMins(element.getPlannedStopLostTime()),
      smallStopLostTime: milliToMins(element.getSmallStopLostTime()),
      avgSmallStopTime: milliToMins(element.getAverageSmallStopTime()),
    });
  });
  return lostTimeData;
};

const mapOeeData = (res: any) => {
  let oeeArr: any = [];
  for (const property in res) {
    oeeArr.push({
      category: property,
      value: res[property].toFixed(2),
      full: 100,
    });
  }
  return oeeArr;
};

const mapTeepData = (res: any) => {
  let teepArr: any = [];
  for (const property in res) {
    teepArr.push({
      label: property,
      value: res[property].toFixed(2),
    });
  }
  return teepArr;
};

const mapDownTimeData = (res: any) => {
  let downTimeArr: any = [];
  for (const property in res) {
    downTimeArr.push({
      category: property,
      value: res[property],
    });
  }
  return downTimeArr;
};
