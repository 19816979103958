import * as types from "store/actions/actionTypes";
import initialState from "./initalState";

export default function ProcessOrderSummary(
  state = initialState.processOrderSummary,
  action: any
) {
  switch (action.type) {
    case types.GET_PPALL_BATCHES_DATA_SUCCESS:
      
      state = {
        ...state,
        PPAllBatches: action.payload ,
      };
      return state;
      case types.UPDATE_REJECT_COUNT_SUCCESS:
      
      state = {
        ...state,
        rejectCountUpdate: action.payload,
      };
      return state;
    default:
      return state;
  }
}
