import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useState, useEffect } from "react";
import "./index.scss";
import { BackgroundColor, TextColor } from "utils/TableConstants";
import { TimelineTableprops } from "types";
import { Dropdown } from "../Dropdown/Dropdown";
import moment from "moment";
import { format } from 'date-fns';

export const TimeLineTable: React.FC<TimelineTableprops> = (props) => {
  const [searchErrorCode, setsearchErrorCode] = useState("");
  const [searchDeviceName, setsearchDeviceName] = useState("");
  const [searchDeviceTag, setsearchDeviceTag] = useState("");
  const [searchAlarmDescription, setsearchAlarmDescription] = useState("");
  const [searchAlarmState, setsearchAlarmState] = useState("");
  const [searchSeverity, setsearchSeverity] = useState("");
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "radio"
  );

  //filter and sorting functionality yet to be done (will be do later)
  //( to do later) to be improved but can be done with API integration.

  const [filterData, setFilterData] = useState(
    props.alarmsEventsDetailsTableList
  );

  useEffect(() => {
    setFilterData(
      props.alarmsEventsDetailsTableList.filter((value: any) => {
        if (
          value.AlarmState?.toLowerCase().includes(
            searchAlarmState.toLowerCase()
          )
        ) {
          return value;
        }
      })
    );
  }, [searchAlarmState]);
  const columns: ColumnsType<any> = [
    {
      title: (
        <div className="column-title-container">
          <div> Start Time</div>
        </div>
      ),
      dataIndex: "timeStamp",
      // sorter: (a, b) => a.timeStamp - b.timeStamp,
      render: (timeStamp) => (
        <div>
          {format(new Date(timeStamp), 'dd/MM/yy HH:mm:ss')}
        </div>
      ),
    },
    {
      title: (
        <div className="column-title-container">
          <div>End Time</div>
        </div>
      ),
      dataIndex: "endTimestamp",
      render: (endTimestamp) => (
        <div>
          {endTimestamp==="-"?"-": format(new Date(endTimestamp), 'dd/MM/yy HH:mm:ss')}
        </div>
      ),
      
    },
    {
      title: (
        <div className="column-title-container">
          <div>Error Code</div>
          {/* <input
            style={{ width: "120px", border: "1px solid #D9D9D9" }}
            type="text"
            placeholder="Search..."
            value={searchErrorCode}
            onChange={(event) => setsearchErrorCode(event.target.value)}
          /> */}
        </div>
      ),
      dataIndex: "errorCode",
      render: (errorCode) => (
        <div style={{ color: "#2F54EB" }}>{errorCode}</div>
      ),
    },
    {
      title: (
        <div className="column-title-wrapper-container">
          <div>Device Name</div>
          {/* <input
            style={{ width: "120px", border: "1px solid #D9D9D9" }}
            type="text"
            placeholder="Search..."
            value={searchDeviceName}
            onChange={(event) => setsearchDeviceName(event.target.value)}
          /> */}
        </div>
      ),
      dataIndex: "deviceName",
      // sorter: (a, b) => a.deviceName - b.deviceName,
    },
    {
      title: (
        <div className="column-title-wrapper-container">
          <div>Device Tag</div>
          {/* <input
            style={{ width: "120px", border: "1px solid #D9D9D9" }}
            type="text"
            placeholder="Search..."
            value={searchDeviceTag}
            onChange={(event) => setsearchDeviceTag(event.target.value)}
          /> */}
        </div>
      ),
      dataIndex: "deviceTag",
    },
    {
      title: (
        <div className="column-title-wrapper-container">
          <div>Alarm Description</div>
          {/* <input
            style={{ width: "120px", border: "1px solid #D9D9D9" }}
            type="text"
            placeholder="Search..."
            value={searchAlarmDescription}
            onChange={(event) => setsearchAlarmDescription(event.target.value)}
          /> */}
        </div>
      ),
      dataIndex: "description",
    },
    {
      title: (
        <div className="column-title-wrapper-container">
          <div>Alarm State</div>
          {/* <input
            style={{ width: "100px", border: "1px solid #D9D9D9" }}
            type="text"
            placeholder="Search..."
            value={searchAlarmState}
            onChange={(event) => setsearchAlarmState(event.target.value)}
          /> */}
        </div>
      ),
      dataIndex: "alarmState",
      render: (_, { alarmState }) => (
        <div style={{ color: alarmState === "Active" ? "#FF4D4F" : "#52C41A" }}>
          {alarmState}
        </div>
      ),
    },
    {
      title: (
        <div className="column-title-container">
          <div>Severity</div>
          {/* <Dropdown optionData={[]} placeHolder={"Search..."} /> */}
        </div>
      ),
      dataIndex: "severity",
      render: (_, { severity }) => (
        <div
          className="table-severity"
          style={{
            backgroundColor: BackgroundColor(severity),
            color: TextColor(severity),
          }}
        >
          {severity}
        </div>
      ),
    },
    {
      title: (
        <div className="column-activeduration-container">
          <div>Active Duration</div>
          {/* <Dropdown optionData={[]} placeHolder={"Search..."} /> */}
        </div>
      ),
      dataIndex: "activeDuration",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      props.setSelectedTableRowIndex(selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.TimeLine === "Disabled User",
      TimeLine: record.TimeLine,
    }),
  };


  const changeHandler = (page) => {
    props.setPage(page);
  };

  return (
    <Table
      rowSelection={{
        type: selectionType,
        ...rowSelection,
      }}
      pagination={{
        total: props.alarmsEventsActiveAlarmsRowCount,
        defaultCurrent: 1,
        pageSize: 50,
        showSizeChanger: false,
        onChange: changeHandler,
      }}
      columns={columns}
      dataSource={[...props.alarmsEventsDetailsTableList]}
      scroll={{ x: 700, y: 260 }}
    />
  );
};
