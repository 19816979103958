import { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import * as am5xy from "@amcharts/amcharts5/xy";
import { ChartDataProps } from "types";

const Pareto: React.FC<ChartDataProps> = (props: any) => {
  const { chartID, data, screen = "" } = props;

  useLayoutEffect(() => {
    var root = am5.Root.new(chartID);

    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      })
    );

    prepareParetoData();

    function prepareParetoData() {
      var total = 0;

      for (var i = 0; i < data?.length; i++) {
        var value = data[i].value;
        total += value;
      }

      var sum = 0;
      for (var i = 0; i < data?.length; i++) {
        var value = data[i].value;
        sum += value;
        data[i].pareto = (sum / total) * 100;
      }
    }

    var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 10 });
    xRenderer.labels.template.setAll({
      rotation: -75,
      centerY: 27,
      centerX: am5.p100,
      paddingRight: 10,
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: xRenderer,
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      paddingTop: 20,
      visible: false,
    });

    xAxis.data.setAll(data);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        numberFormat: screen === "alarms" ? "#  Min" : "#",
      })
    );

    var paretoAxisRenderer = am5xy.AxisRendererY.new(root, { opposite: true });
    var paretoAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: paretoAxisRenderer,
        min: 0,
        max: 100,
        strictMinMax: true,
      })
    );

    paretoAxisRenderer.grid.template.set("forceHidden", true);
    paretoAxis.set("numberFormat", "#'%");

    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category",
      })
    );

    series.columns.template.setAll({
      tooltipText:
        screen === "alarms"
          ? "{categoryX}: {valueY}  Min"
          : "{categoryX}: {valueY} ",
      tooltipY: 0,
      strokeOpacity: 0,
      cornerRadiusTL: 6,
      cornerRadiusTR: 6,
    });

    var paretoSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: xAxis,
        yAxis: paretoAxis,
        valueYField: "pareto",
        categoryXField: "category",
        stroke: root.interfaceColors.get("alternativeBackground"),
        maskBullets: false,
      })
    );

    paretoSeries.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: am5.Circle.new(root, {
          radius: 5,
          fill: series.get("fill"),
          stroke: root.interfaceColors.get("alternativeBackground"),
        tooltipText:"{valueY.formatNumber('#.0')}%[/]"
        }),
      });
    });

    series.set("fill", am5.color("#85A5FF"));
    series.data.setAll(data);
    paretoSeries.data.setAll(data);

    series.appear();
    chart.appear(1000, 100);
    root?._logo?.dispose();

    return () => root.dispose();
  }, [chartID, data]);

  return <div style={{ width: "100%", height: "100%" }} id={chartID}></div>;
};
export default Pareto;
