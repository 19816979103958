import { SwapOutlined } from "@ant-design/icons";
import { SwitchModule } from "components/Switch-Module";
import { useState } from "react";

export const FooterLink = ({ collapsed, braboRedirect }: any) => {
  const[showSwitchModule, setShowSwitchModule] = useState<boolean>(false);

  const openSwitchModule = () => {
    setShowSwitchModule(prevValue => !prevValue);
  }
  
  const closeModal = (item: boolean) => {
    setShowSwitchModule(item);
  }

  return (
    <>
      <div className="empty-space"></div>
      {collapsed ? (
        <div className="switch-module" onClick={openSwitchModule}>
          <SwapOutlined />
        </div>
      ) : (
        <div className="switch-module" onClick={openSwitchModule}>
          <SwapOutlined style={{ marginRight: "10px" }} /> Switch Module
        </div>
      )}
      <SwitchModule showSwitchModule={showSwitchModule} closeModal={closeModal}/>
    </>
  );
};
