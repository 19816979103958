import ChartContainer from "components/ChartContainer";
import { SolidGuage } from "components/Charts/SolidGuage/SolidGuage";
import { ActiveAlarmCard } from "components/ActiveAlarmTable";
import "./index.scss";
import Pareto from "components/Charts/Pareto";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "components/Common/Dropdown/Dropdown";
import { SolidGuageAnalysisContainer } from "components/Common/SolidGuageAnalysis";
import StackedBar from "components/Charts/StackedBar";
import {
  getAlarmsAndEventsDashboardData,
  getAlarmsAndEventsGuageData,
  getAlarmsAndEventsParetoData,
  getAlarmsAndEventsStackedBarData,
  getAlarmsAndEventsTableData,
} from "store/actions/alarmsEvents";
import { useEffect, useState } from "react";
import moment from "moment";
import { DropdownFilterComponent } from "components/DropdownFilterComponent";

const AlarmDashboard = () => {
  const [payload, setPayload] = useState<any>({
    lineObject: useSelector((state: any) => state.root.filterValues.lineObject),
    nodeId: useSelector((state: any) => state.root.filterValues.nodeId),
    startTime: useSelector((state: any) => state.root.filterValues.startTime),
    endTime: useSelector((state: any) => state.root.filterValues.endTime),
    severity: useSelector((state: any) => state.root.filterValues.severity),
    batches: useSelector((state: any) => state.root.filterValues.batches),
    sku: useSelector((state: any) => state.root.filterValues.sku),
    page: useSelector((state: any) => state.root.filterValues.page),
  });

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const filterFlag = useSelector((state: any) => state.root.filterFlag);
  const selectedNodeIds: any = useSelector(
    (state: any) => state.root.filterValues.nodeId
  );
  useEffect(() => {
    if (selectedNodeIds.length > 0) {
      setPayload(payload => ({
        ...payload,
        nodeId: selectedNodeIds
      }));
    }
  }, [selectedNodeIds])
  useEffect(() => {
   
    setTimeout(() => {
      dispatch(getAlarmsAndEventsStackedBarData({ ...payload, page }));
      dispatch(getAlarmsAndEventsParetoData({ ...payload, page }));
      dispatch(getAlarmsAndEventsTableData({ ...payload, page }));
      dispatch(getAlarmsAndEventsGuageData({ ...payload, page }));
    }, 100);
  }, [payload, filterFlag]);

  useEffect(() => {
    dispatch(getAlarmsAndEventsTableData({ ...payload, page }));
  }, [page,payload]);

  const alarmsEventsSummaryByCountList = useSelector(
    (state: any) => state.alarmsEvents.alarmsEventsSummaryByCountList
  );
  const alarmsEventsSummaryByDurationList = useSelector(
    (state: any) => state.alarmsEvents.alarmsEventsSummaryByDurationList
  );

  const alarmsEventsGuageSummaryByCountDataList = useSelector(
    (state: any) => state.alarmsEvents.alarmsEventsGuageSummaryByCountDataList
  );

  const alarmsEventsGuageSummaryByDurationDataList = useSelector(
    (state: any) =>
      state.alarmsEvents.alarmsEventsGuageSummaryByDurationDataList
  );
  const alarmsEventsCountByTagsList = useSelector(
    (state: any) => state.alarmsEvents.alarmsEventsCountByTagsList
  );
  const alarmsEventsDurationByTagsList = useSelector(
    (state: any) => state.alarmsEvents.alarmsEventsDurationByTagsList
  );
  const alarmsEventsCountByDevicesList = useSelector(
    (state: any) => state.alarmsEvents.alarmsEventsCountByDevicesList
  );
  const alarmsEventsDurationByDevicesList = useSelector(
    (state: any) => state.alarmsEvents.alarmsEventsDurationByDevicesList
  );
  const alarmsEventsActiveAlarmsList = useSelector(
    (state: any) => state.alarmsEvents.alarmsEventsActiveAlarmsList
  );

  const alarmsEventsActiveAlarmsCardData = useSelector(
    (state: any) => state.alarmsEvents.alarmsEventsActiveAlarmsCardData
  );

  const alarmsEventsActiveAlarmsRowCount = useSelector(
    (state: any) => state.alarmsEvents.alarmEventRowCount
  );

  const [countByDevicesSelectedFilter, setCountByDevicesSelectedFilter] =
    useState("all");
  const [countByDurationSelectedFilter, setCountByDurationSelectedFilter] =
    useState("all");

  return (
    <>
      <DropdownFilterComponent setPayload={setPayload} screen="alarms" />
      <div
        className="alarmevent-dashboard-wrapper-container"
        style={{ paddingLeft: "5px", paddingRight: "8px" }}
      >
        <div className="alarmevent-dashboard-graph-container marginTop8">
          <ChartContainer
            id={"AlarmandErrorsSummarybycount"}
            title={"Alarm & Errors Summary (By Count)"}
            alignChartTitle={"left"}
            child={
              <SolidGuage
                chartID={"AlarmandErrorsSummaryByCount"}
                data={alarmsEventsSummaryByCountList}
                screen="alarm"
              />
            }
            width={"256px"}
            height="256px"
            guageAnalysis={
              <SolidGuageAnalysisContainer
                data={alarmsEventsGuageSummaryByCountDataList}
              />
            }
          />
          <ChartContainer
            id={"AlarmandErrorsSummarybyduration"}
            title={"Alarm & Errors Summary (By Duration)"}
            alignChartTitle={"left"}
            child={
              <SolidGuage
                chartID={"AlarmandErrorsSummaryByDuration"}
                data={alarmsEventsSummaryByDurationList}
                screen="alarm"
              />
            }
            width={"256px"}
            height="256px"
            guageAnalysis={
              <SolidGuageAnalysisContainer
                data={alarmsEventsGuageSummaryByDurationDataList}
              />
            }
          />
          <ChartContainer
            paddingtop=""
            id="AlarmandeventActiveAlarm"
            title="Active Alarm"
            alignChartTitle="left"
            tooltip="Active Alarm"
            child={
              <ActiveAlarmCard
                chartID="AlarmAndEventActiveAlarm"
                data={alarmsEventsActiveAlarmsList}
                cardData={alarmsEventsActiveAlarmsCardData}
                alarmsEventsActiveAlarmsRowCount={
                  alarmsEventsActiveAlarmsRowCount
                }
                setPage={setPage}
              />
            }
            width="564px"
            height="256px"
          />
        </div>
        <div className="alarmevent-dashboard-devices-row-container marginTop8">
          <ChartContainer
            id={"Alarm&ErrorsCount-By-Devices"}
            title={"Alarm & Errors Count By Devices"}
            alignChartTitle={"left"}
            child={
              <div className="stacked-top-downtime">
                <StackedBar
                  chartID="Alarm&ErrorsCount-By-DevicesID"
                  data={alarmsEventsCountByDevicesList}
                  legendXPosition={10}
                  selectedFilter={countByDevicesSelectedFilter}
                />
              </div>
            }
            width="150px"
            height="190px"
            showFullScreen={true}
          />
          <ChartContainer
            id={"Alarm-Errors-Duration-By-Devices"}
            title={"Alarm & Errors Duration By Devices"}
            alignChartTitle={"left"}
            child={
              <div className="stacked-top-downtime">
                <StackedBar
                  chartID="Alarm&ErrorsDuration-ByDevicesID"
                  data={alarmsEventsDurationByDevicesList}
                  legendXPosition={10}
                  selectedFilter={countByDurationSelectedFilter}
                  screen="alarms"
                />
              </div>
            }
            width="150px"
            height="190px"
            showFullScreen={true}
          />
        </div>
        <div className="alarmevent-dashboard-devices-row-container marginTop8">
          <ChartContainer
            id="Alarm-Errors-Count-By-Tags"
            title="Alarm & Errors Count By Tags"
            alignChartTitle="left"
            child={
              <Pareto
                chartID="Pareto-Alarm-Errors-Count-By-Tags"
                data={alarmsEventsCountByTagsList}
              />
            }
            width="600px"
            height="400px"
            showFullScreen={true}
          />
          <ChartContainer
            id="Alarm-Errors-Duration-By-Tags"
            title="Alarm & Errors Duration By Tags"
            alignChartTitle="left"
            child={
              <Pareto
                chartID="Pareto-Alarm-Errors-Duration-By-Tags"
                data={alarmsEventsDurationByTagsList}
                screen="alarms"
              />
            }
            height="400px"
            showFullScreen={true}
          />
        </div>
      </div>
    </>
  );
};

export default AlarmDashboard;
