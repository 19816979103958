import { MetricsDataListProps, MetricsDataType } from "types";
import "./index.scss";

const MetricsCard: React.FC<MetricsDataListProps> = ({ metricsData }) => {
  const CardList = metricsData.map((data: MetricsDataType) => {
    return (
      <div className="reason-code-metrics-card">
        <div className="reason-code-metrics-heading">{data.metricsName}</div>
        <div className="reason-code-metrics-value">{data.metricsValue}</div>
      </div>
    );
  });
  return <div className="reason-code-metrics-container">{CardList}</div>;
};

export default MetricsCard;
