import { all, call, put, takeLatest } from "redux-saga/effects";
import * as types from "store/actions/actionTypes";
import { RuntimeAnalysisService } from "store/services/runtimeAnalysisService";

export function* getRuntimeAnalysisDashboardDowntimeDataSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const runtimeAnalysisService = RuntimeAnalysisService.getInstance();
    const runtimeAnalysisServiceDetails = yield call(
      [runtimeAnalysisService, runtimeAnalysisService.getDowntimeValues],
      payload
    );
    yield put({
      type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_DOWNTIME_DATA_SUCCESS,
      payload: runtimeAnalysisServiceDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getRuntimeAnalysisDashboardRuntimeDataSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const runtimeAnalysisService = RuntimeAnalysisService.getInstance();
    const runtimeAnalysisServiceDetails = yield call(
      [runtimeAnalysisService, runtimeAnalysisService.getRuntimeValues],
      payload
    );
    yield put({
      type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_RUNTIME_DATA_SUCCESS,
      payload: runtimeAnalysisServiceDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getRuntimeAnalysisDashboardPlannedStoptimeDataSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const runtimeAnalysisService = RuntimeAnalysisService.getInstance();
    const runtimeAnalysisServiceDetails = yield call(
      [runtimeAnalysisService, runtimeAnalysisService.getPlannedStoptimeValues],
      payload
    );
    yield put({
      type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_PLANNED_STOPTIME_DATA_SUCCESS,
      payload: runtimeAnalysisServiceDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getRuntimeAnalysisDashboardAssetUtilizationDataSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const runtimeAnalysisService = RuntimeAnalysisService.getInstance();
    const runtimeAnalysisServiceDetails = yield call(
      [
        runtimeAnalysisService,
        runtimeAnalysisService.getAssetUtilizationValues,
      ],
      payload
    );
    yield put({
      type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_ASSET_UTILIZATION_DATA_SUCCESS,
      payload: runtimeAnalysisServiceDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getRuntimeAnalysisDashboardMachineRuntimeStatusDataSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const runtimeAnalysisService = RuntimeAnalysisService.getInstance();
    const runtimeAnalysisServiceDetails = yield call(
      [
        runtimeAnalysisService,
        runtimeAnalysisService.getMachineRuntimeStatusValues,
      ],
      payload
    );
    yield put({
      type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_MACHINE_RUNTIME_STATUS_DATA_SUCCESS,
      payload: runtimeAnalysisServiceDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getRuntimeAnalysisDashboardMttrDataSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const runtimeAnalysisService = RuntimeAnalysisService.getInstance();
    const runtimeAnalysisServiceDetails = yield call(
      [
        runtimeAnalysisService,
        runtimeAnalysisService.getMachineRuntimeMttrValues,
      ],
      payload
    );
    yield put({
      type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_MTTR_DATA_SUCCESS,
      payload: runtimeAnalysisServiceDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getRuntimeAnalysisDashboardMtbfDataSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const runtimeAnalysisService = RuntimeAnalysisService.getInstance();
    const runtimeAnalysisServiceDetails = yield call(
      [
        runtimeAnalysisService,
        runtimeAnalysisService.getMachineRuntimeMtbfValues,
      ],
      payload
    );
    yield put({
      type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_MTBF_DATA_SUCCESS,
      payload: runtimeAnalysisServiceDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getRuntimeAnalysisDetailsDataSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const runtimeAnalysisService = RuntimeAnalysisService.getInstance();
    const runtimeAnalysisServiceDetails = yield call(
      [
        runtimeAnalysisService,
        runtimeAnalysisService.getMachineRuntimeDetailsValues,
      ],
      payload
    );
    yield put({
      type: types.GET_RUNTIME_ANALYSIS_DETAILS_DATA_SUCCESS,
      payload: runtimeAnalysisServiceDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getRuntimeAnalysisDashboardGanttSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    yield put({ type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_GANTT_DATA_LOADING, showLoader: true });
    const runtimeAnalysisService = RuntimeAnalysisService.getInstance();
    const runtimeAnalysisServiceDetails = yield call(
      [
        runtimeAnalysisService,
        runtimeAnalysisService.getMachineRuntimeDashboardGanttValues,
      ],
      payload
    );
    yield put({
      type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_GANTT_DATA_SUCCESS,
      payload: runtimeAnalysisServiceDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    yield put({ type: types.GET_RUNTIME_ANALYSIS_DASHBOARD_GANTT_DATA_LOADING, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}


export function* runtimeAnalysisSaga() {
  yield all([
    takeLatest(
      types.GET_RUNTIME_ANALYSIS_DASHBOARD_DOWNTIME_DATA,
      getRuntimeAnalysisDashboardDowntimeDataSaga
    ),
    takeLatest(
      types.GET_RUNTIME_ANALYSIS_DASHBOARD_RUNTIME_DATA,
      getRuntimeAnalysisDashboardRuntimeDataSaga
    ),
    takeLatest(
      types.GET_RUNTIME_ANALYSIS_DASHBOARD_PLANNED_STOPTIME_DATA,
      getRuntimeAnalysisDashboardPlannedStoptimeDataSaga
    ),
    takeLatest(
      types.GET_RUNTIME_ANALYSIS_DASHBOARD_ASSET_UTILIZATION_DATA,
      getRuntimeAnalysisDashboardAssetUtilizationDataSaga
    ),
    takeLatest(
      types.GET_RUNTIME_ANALYSIS_DASHBOARD_MACHINE_RUNTIME_STATUS_DATA,
      getRuntimeAnalysisDashboardMachineRuntimeStatusDataSaga
    ),
    takeLatest(
      types.GET_RUNTIME_ANALYSIS_DASHBOARD_MTTR_DATA,
      getRuntimeAnalysisDashboardMttrDataSaga
    ),
    takeLatest(
      types.GET_RUNTIME_ANALYSIS_DASHBOARD_MTBF_DATA,
      getRuntimeAnalysisDashboardMtbfDataSaga
    ),
    takeLatest(
      types.GET_RUNTIME_ANALYSIS_DETAILS_DATA,
      getRuntimeAnalysisDetailsDataSaga
    ),
    takeLatest(
      types.GET_RUNTIME_ANALYSIS_DASHBOARD_GANTT_DATA,
      getRuntimeAnalysisDashboardGanttSaga
    ),
  ]);
}
