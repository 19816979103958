import {
  FilterOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Input, Menu} from "antd";
import { ChangeEvent, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CollapsibleSidebarProps, MenuItem } from "types";
import "./index.scss";

export const CollapsibleSidebar = (props: CollapsibleSidebarProps) => {
  const { SubMenu } = Menu;
  const { menu, collapsed, changeSideBar } = props;
  const [selectedKey, setSelectedKey] = useState<string>("");
  const [searchMenuValue, setSearchMenuValue] = useState<string>("");
  const location = useLocation();

  const toggleCollapse = () => {
    changeSideBar(!collapsed);
  };

  const searchMenuItem = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchMenuValue(e.target.value);
  };

  const getMenuSearchResults = (route: MenuItem) => {
    let result = false;
    let searchQuery = searchMenuValue.toLowerCase().trim();
    if (route.children) {
      result = route.children.some((item: MenuItem) =>
        item.name.toLowerCase().includes(searchQuery)
      );
    }
    return route.name.toLowerCase().includes(searchQuery) || result;
  };

  const renderSubMenuItems = (route: MenuItem) => {
    return (
      <>
        {getMenuSearchResults(route) && (
          <SubMenu
            style={{ marginBottom: 8 }}
            icon={route.icon}
            key={route.key}
            title={route.name}
          >
            {getSubmenu(route)}
          </SubMenu>
        )}
      </>
    );
  };

  const renderMenuItems = (route: MenuItem) => {
    return (
      <>
        {route.name
          .toLowerCase()
          .includes(searchMenuValue.toLowerCase().trim()) && (
            <Menu.Item
              icon={route.icon}
              eventKey={route.key}
              className="dashboard-icon"
            >
              <Link to={route.path!}>{route.name}</Link>
            </Menu.Item>
          )}
      </>
    );
  };

  const getSubmenu = (route: MenuItem) => {
    let searchQuery = searchMenuValue.toLowerCase().trim();
    return route.children.map((child: MenuItem) => {
      return child.name.toLowerCase().includes(searchQuery) ? (
        <div className="childern-submenu">
          <Menu.Item
            eventKey={child.key}
            icon={
              selectedKey === child.key && child.activeIcon
                ? child.activeIcon
                : child.icon
            }
          >
            {child.target === "_blank" ? (
              <a href={child.path} target={child.target}>
                {child.name}
              </a>
            ) : (
              <Link to={child.path!} target={child.target}>
                {child.name}
              </Link>
            )}
          </Menu.Item>
        </div>
      ) : (
        <></>
      );
    });
  };

  return (
    <>
      <div className="filter-navigator">
        {collapsed ? null : (
          <Input
            onChange={searchMenuItem}
            className="filter-input"
            prefix={<FilterOutlined />}
            placeholder="Filter Navigator"
          />
        )}
        {collapsed ? (
          <MenuUnfoldOutlined onClick={toggleCollapse} />
        ) : (
          <MenuFoldOutlined onClick={toggleCollapse} />
        )}
      </div>
      <div className={collapsed ? "collapsed-side-menu-tabs" : "side-menu-tabs"}>
        <Menu mode="inline" className="menu-class"
          defaultSelectedKeys={[ location.pathname.split('/')[1] ? location.pathname.split('/')[1] : 'dashboard']}>
          {menu &&
            menu.length &&
            menu.map((route: MenuItem) => {
              return route.children
                ? renderSubMenuItems(route)
                : renderMenuItems(route);
            })}
        </Menu>
      </div>
    </>
  );
};
