import { all, call, put, takeLatest } from "redux-saga/effects";
import * as types from "store/actions/actionTypes";
import { AlarmsEventsService } from "store/services/alarmsEventsService";

export function* getAlarmsEventsDashboardDataSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const alarmsEventsService = AlarmsEventsService.getInstance();
    const alarmsEventsDetails = yield call(
      [alarmsEventsService, alarmsEventsService.getAlarmsDashboardData],
      payload
    );
    yield put({
      type: types.GET_ALARMS_EVENTS_DASHBOARD_DATA_SUCCESS,
      payload: alarmsEventsDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getAlarmsDetailsSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const alarmsEventsService = AlarmsEventsService.getInstance();
    const alarmsEventsDetails = yield call(
      [alarmsEventsService, alarmsEventsService.getAlarmsDetailsData],
      payload
    );
    yield put({
      type: types.GET_ALARMS_EVENTS_DETAILS_DATA_SUCCESS,
      payload: alarmsEventsDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getAlarmsEventsStackedChartDataSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const alarmsEventsService = AlarmsEventsService.getInstance();
    const alarmsEventsDetails = yield call(
      [
        alarmsEventsService,
        alarmsEventsService.getAlarmsDashboardStackedChartData,
      ],
      { ...payload, type: "BY_COUNT" }
    );
    yield put({
      type: types.GET_ALARMS_EVENTS_STACKED_BAR_CHART_DATA_SUCCESS,
      payload: alarmsEventsDetails,
      typeOfValue: "BY_COUNT"
    });
    const alarmsEventsDetailsByDuration = yield call(
      [
        alarmsEventsService,
        alarmsEventsService.getAlarmsDashboardStackedChartData,
      ],
      { ...payload, type: "BY_DURATION" }
    );
    yield put({
      type: types.GET_ALARMS_EVENTS_STACKED_BAR_CHART_DATA_SUCCESS,
      payload: alarmsEventsDetailsByDuration,
      typeOfValue: "BY_DURATION"
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getAlarmsEventsParetoDataSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const alarmsEventsService = AlarmsEventsService.getInstance();
    const alarmsEventsDetails = yield call(
      [
        alarmsEventsService,
        alarmsEventsService.getAlarmsDashboardParetoChartData,
      ],
      { ...payload, type: "BY_COUNT" }
    );
    yield put({
      type: types.GET_ALARMS_EVENTS_PARETO_CHART_DATA_SUCCESS,
      payload: alarmsEventsDetails,
      typeOfValue: "BY_COUNT"
    });
    const alarmsEventsDetailsByDuration = yield call(
      [
        alarmsEventsService,
        alarmsEventsService.getAlarmsDashboardParetoChartData,
      ],
      { ...payload, type: "BY_DURATION" }
    );
    yield put({
      type: types.GET_ALARMS_EVENTS_PARETO_CHART_DATA_SUCCESS,
      payload: alarmsEventsDetailsByDuration,
      typeOfValue: "BY_DURATION"
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getAlarmsEventsTableDataSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const alarmsEventsService = AlarmsEventsService.getInstance();
    const alarmsEventsDetails = yield call(
      [alarmsEventsService, alarmsEventsService.getAlarmsTableData],
      payload
    );
    yield put({
      type: types.GET_ALARMS_EVENTS_TABLE_DATA_SUCCESS,
      payload: alarmsEventsDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getAlarmsEventsGuageDataSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const alarmsEventsService = AlarmsEventsService.getInstance();
    const guageData = yield call(
      [alarmsEventsService, alarmsEventsService.getGuageData],
      payload
    );
    yield put({
      type: types.GET_ALARMS_EVENTS_GUAGE_DATA_SUCCESS,
      payload: guageData,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}



export function* alarmsEventsSaga() {
  yield all([
    takeLatest(
      types.GET_ALARMS_EVENTS_DASHBOARD_DATA,
      getAlarmsEventsDashboardDataSaga
    ),
    takeLatest(types.GET_ALARMS_EVENTS_DETAILS_DATA, getAlarmsDetailsSaga),
    takeLatest(
      types.GET_ALARMS_EVENTS_STACKED_BAR_CHART_DATA,
      getAlarmsEventsStackedChartDataSaga
    ),
    takeLatest(
      types.GET_ALARMS_EVENTS_PARETO_DATA,
      getAlarmsEventsParetoDataSaga
    ),
    takeLatest(
      types.GET_ALARMS_EVENTS_TABLE_DATA,
      getAlarmsEventsTableDataSaga
    ),
    takeLatest(
      types.GET_ALARMS_EVENTS_GUAGE_DATA,
      getAlarmsEventsGuageDataSaga
    ),
  ]);
}
