import * as types from "store/actions/actionTypes";
import initialState from "./initalState";

export default function operatorReducer(
  state = initialState.operator,
  action: any
) {
  switch (action.type) {
    case types.GET_PROCESS_LINE_INFO_SUCCESS:
      return { ...state, processLineList: action.payload };
    case types.GET_BATCHES_BY_LINE_SUCCESS:
      return { ...state, currentLineDetails: action.currentLineDetails };
    case types.SELECTED_FP_LINE:
      return { ...state, selectedFpLine: action.payload };
      // Note: kept for reference
    // case types.ASSIGN_OPERATOR_LOG:
    //   return { ...state, assignReasonCodeLoading: true };
    // case types.ASSIGN_OPERATOR_LOG_SUCCESS:
    //   // ASSIGN_OPERATOR_LOG_SUCCESS
    //   return { ...state, assignReasonCodeLoading: false };
    case types.GET_MACHINE_STATUS_SUCCESS:
      return {...state,machineStatus:action.machineStatus};
    default:
      return state;
  }
}
