/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: service/HistorianRpc.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var objects_Historian_pb = require('../objects/Historian_pb.js')

var common_API_pb = require('../common/API_pb.js')

var common_Enums_pb = require('../common/Enums_pb.js')
const proto = require('./HistorianRpc_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.HistorianServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.HistorianServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TimeStampMultipleQuery,
 *   !proto.TimeStampMultipleResponse>}
 */
const methodDescriptor_HistorianService_getTimeStampRawData = new grpc.web.MethodDescriptor(
  '/HistorianService/getTimeStampRawData',
  grpc.web.MethodType.UNARY,
  proto.TimeStampMultipleQuery,
  proto.TimeStampMultipleResponse,
  /**
   * @param {!proto.TimeStampMultipleQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TimeStampMultipleResponse.deserializeBinary
);


/**
 * @param {!proto.TimeStampMultipleQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TimeStampMultipleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TimeStampMultipleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.HistorianServiceClient.prototype.getTimeStampRawData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/HistorianService/getTimeStampRawData',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTimeStampRawData,
      callback);
};


/**
 * @param {!proto.TimeStampMultipleQuery} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TimeStampMultipleResponse>}
 *     Promise that resolves to the response
 */
proto.HistorianServicePromiseClient.prototype.getTimeStampRawData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/HistorianService/getTimeStampRawData',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTimeStampRawData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TimeStampMultipleQuery,
 *   !proto.TimeStampMultipleResponse>}
 */
const methodDescriptor_HistorianService_getTimeStampAggregatedData = new grpc.web.MethodDescriptor(
  '/HistorianService/getTimeStampAggregatedData',
  grpc.web.MethodType.UNARY,
  proto.TimeStampMultipleQuery,
  proto.TimeStampMultipleResponse,
  /**
   * @param {!proto.TimeStampMultipleQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TimeStampMultipleResponse.deserializeBinary
);


/**
 * @param {!proto.TimeStampMultipleQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TimeStampMultipleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TimeStampMultipleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.HistorianServiceClient.prototype.getTimeStampAggregatedData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/HistorianService/getTimeStampAggregatedData',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTimeStampAggregatedData,
      callback);
};


/**
 * @param {!proto.TimeStampMultipleQuery} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TimeStampMultipleResponse>}
 *     Promise that resolves to the response
 */
proto.HistorianServicePromiseClient.prototype.getTimeStampAggregatedData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/HistorianService/getTimeStampAggregatedData',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTimeStampAggregatedData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TimeStampMultipleQuery,
 *   !proto.TimeStampStatsResponse>}
 */
const methodDescriptor_HistorianService_getTimeStampStats = new grpc.web.MethodDescriptor(
  '/HistorianService/getTimeStampStats',
  grpc.web.MethodType.UNARY,
  proto.TimeStampMultipleQuery,
  proto.TimeStampStatsResponse,
  /**
   * @param {!proto.TimeStampMultipleQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TimeStampStatsResponse.deserializeBinary
);


/**
 * @param {!proto.TimeStampMultipleQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TimeStampStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TimeStampStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.HistorianServiceClient.prototype.getTimeStampStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/HistorianService/getTimeStampStats',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTimeStampStats,
      callback);
};


/**
 * @param {!proto.TimeStampMultipleQuery} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TimeStampStatsResponse>}
 *     Promise that resolves to the response
 */
proto.HistorianServicePromiseClient.prototype.getTimeStampStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/HistorianService/getTimeStampStats',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTimeStampStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TimeStampCyclicQuery,
 *   !proto.TimeStampMultipleResponse>}
 */
const methodDescriptor_HistorianService_getTimeStampCyclicData = new grpc.web.MethodDescriptor(
  '/HistorianService/getTimeStampCyclicData',
  grpc.web.MethodType.UNARY,
  proto.TimeStampCyclicQuery,
  proto.TimeStampMultipleResponse,
  /**
   * @param {!proto.TimeStampCyclicQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TimeStampMultipleResponse.deserializeBinary
);


/**
 * @param {!proto.TimeStampCyclicQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TimeStampMultipleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TimeStampMultipleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.HistorianServiceClient.prototype.getTimeStampCyclicData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/HistorianService/getTimeStampCyclicData',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTimeStampCyclicData,
      callback);
};


/**
 * @param {!proto.TimeStampCyclicQuery} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TimeStampMultipleResponse>}
 *     Promise that resolves to the response
 */
proto.HistorianServicePromiseClient.prototype.getTimeStampCyclicData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/HistorianService/getTimeStampCyclicData',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTimeStampCyclicData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TimeStampMultipleQuery,
 *   !proto.TimeStampMultipleResponse>}
 */
const methodDescriptor_HistorianService_getTimeStampLastData = new grpc.web.MethodDescriptor(
  '/HistorianService/getTimeStampLastData',
  grpc.web.MethodType.UNARY,
  proto.TimeStampMultipleQuery,
  proto.TimeStampMultipleResponse,
  /**
   * @param {!proto.TimeStampMultipleQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TimeStampMultipleResponse.deserializeBinary
);


/**
 * @param {!proto.TimeStampMultipleQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TimeStampMultipleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TimeStampMultipleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.HistorianServiceClient.prototype.getTimeStampLastData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/HistorianService/getTimeStampLastData',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTimeStampLastData,
      callback);
};


/**
 * @param {!proto.TimeStampMultipleQuery} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TimeStampMultipleResponse>}
 *     Promise that resolves to the response
 */
proto.HistorianServicePromiseClient.prototype.getTimeStampLastData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/HistorianService/getTimeStampLastData',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTimeStampLastData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TimeStampMultipleQuery,
 *   !proto.Base64Response>}
 */
const methodDescriptor_HistorianService_getTimeStampRawExcel = new grpc.web.MethodDescriptor(
  '/HistorianService/getTimeStampRawExcel',
  grpc.web.MethodType.UNARY,
  proto.TimeStampMultipleQuery,
  common_API_pb.Base64Response,
  /**
   * @param {!proto.TimeStampMultipleQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_API_pb.Base64Response.deserializeBinary
);


/**
 * @param {!proto.TimeStampMultipleQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Base64Response)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Base64Response>|undefined}
 *     The XHR Node Readable Stream
 */
proto.HistorianServiceClient.prototype.getTimeStampRawExcel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/HistorianService/getTimeStampRawExcel',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTimeStampRawExcel,
      callback);
};


/**
 * @param {!proto.TimeStampMultipleQuery} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Base64Response>}
 *     Promise that resolves to the response
 */
proto.HistorianServicePromiseClient.prototype.getTimeStampRawExcel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/HistorianService/getTimeStampRawExcel',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTimeStampRawExcel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TimeStampMultipleQuery,
 *   !proto.PlainTextResponse>}
 */
const methodDescriptor_HistorianService_getTimeStampRawCSV = new grpc.web.MethodDescriptor(
  '/HistorianService/getTimeStampRawCSV',
  grpc.web.MethodType.UNARY,
  proto.TimeStampMultipleQuery,
  common_API_pb.PlainTextResponse,
  /**
   * @param {!proto.TimeStampMultipleQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_API_pb.PlainTextResponse.deserializeBinary
);


/**
 * @param {!proto.TimeStampMultipleQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.PlainTextResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.PlainTextResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.HistorianServiceClient.prototype.getTimeStampRawCSV =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/HistorianService/getTimeStampRawCSV',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTimeStampRawCSV,
      callback);
};


/**
 * @param {!proto.TimeStampMultipleQuery} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.PlainTextResponse>}
 *     Promise that resolves to the response
 */
proto.HistorianServicePromiseClient.prototype.getTimeStampRawCSV =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/HistorianService/getTimeStampRawCSV',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTimeStampRawCSV);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TimeStampStreamingQuery,
 *   !proto.TimeStampStreamingResponse>}
 */
const methodDescriptor_HistorianService_streamTimeStampRawData = new grpc.web.MethodDescriptor(
  '/HistorianService/streamTimeStampRawData',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.TimeStampStreamingQuery,
  proto.TimeStampStreamingResponse,
  /**
   * @param {!proto.TimeStampStreamingQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TimeStampStreamingResponse.deserializeBinary
);


/**
 * @param {!proto.TimeStampStreamingQuery} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.TimeStampStreamingResponse>}
 *     The XHR Node Readable Stream
 */
proto.HistorianServiceClient.prototype.streamTimeStampRawData =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/HistorianService/streamTimeStampRawData',
      request,
      metadata || {},
      methodDescriptor_HistorianService_streamTimeStampRawData);
};


/**
 * @param {!proto.TimeStampStreamingQuery} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.TimeStampStreamingResponse>}
 *     The XHR Node Readable Stream
 */
proto.HistorianServicePromiseClient.prototype.streamTimeStampRawData =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/HistorianService/streamTimeStampRawData',
      request,
      metadata || {},
      methodDescriptor_HistorianService_streamTimeStampRawData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.CloseRawDataStreamingReq,
 *   !proto.ApiResponse>}
 */
const methodDescriptor_HistorianService_closeRawDataStreaming = new grpc.web.MethodDescriptor(
  '/HistorianService/closeRawDataStreaming',
  grpc.web.MethodType.UNARY,
  proto.CloseRawDataStreamingReq,
  common_API_pb.ApiResponse,
  /**
   * @param {!proto.CloseRawDataStreamingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_API_pb.ApiResponse.deserializeBinary
);


/**
 * @param {!proto.CloseRawDataStreamingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.HistorianServiceClient.prototype.closeRawDataStreaming =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/HistorianService/closeRawDataStreaming',
      request,
      metadata || {},
      methodDescriptor_HistorianService_closeRawDataStreaming,
      callback);
};


/**
 * @param {!proto.CloseRawDataStreamingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ApiResponse>}
 *     Promise that resolves to the response
 */
proto.HistorianServicePromiseClient.prototype.closeRawDataStreaming =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/HistorianService/closeRawDataStreaming',
      request,
      metadata || {},
      methodDescriptor_HistorianService_closeRawDataStreaming);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Empty,
 *   !proto.TemplateDownloadResponse>}
 */
const methodDescriptor_HistorianService_downloadTagDataTemplate = new grpc.web.MethodDescriptor(
  '/HistorianService/downloadTagDataTemplate',
  grpc.web.MethodType.UNARY,
  common_API_pb.Empty,
  proto.TemplateDownloadResponse,
  /**
   * @param {!proto.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TemplateDownloadResponse.deserializeBinary
);


/**
 * @param {!proto.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TemplateDownloadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TemplateDownloadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.HistorianServiceClient.prototype.downloadTagDataTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/HistorianService/downloadTagDataTemplate',
      request,
      metadata || {},
      methodDescriptor_HistorianService_downloadTagDataTemplate,
      callback);
};


/**
 * @param {!proto.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TemplateDownloadResponse>}
 *     Promise that resolves to the response
 */
proto.HistorianServicePromiseClient.prototype.downloadTagDataTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/HistorianService/downloadTagDataTemplate',
      request,
      metadata || {},
      methodDescriptor_HistorianService_downloadTagDataTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TemplateUploadReq,
 *   !proto.ApiResponse>}
 */
const methodDescriptor_HistorianService_uploadTagDataTemplate = new grpc.web.MethodDescriptor(
  '/HistorianService/uploadTagDataTemplate',
  grpc.web.MethodType.UNARY,
  proto.TemplateUploadReq,
  common_API_pb.ApiResponse,
  /**
   * @param {!proto.TemplateUploadReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_API_pb.ApiResponse.deserializeBinary
);


/**
 * @param {!proto.TemplateUploadReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.HistorianServiceClient.prototype.uploadTagDataTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/HistorianService/uploadTagDataTemplate',
      request,
      metadata || {},
      methodDescriptor_HistorianService_uploadTagDataTemplate,
      callback);
};


/**
 * @param {!proto.TemplateUploadReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ApiResponse>}
 *     Promise that resolves to the response
 */
proto.HistorianServicePromiseClient.prototype.uploadTagDataTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/HistorianService/uploadTagDataTemplate',
      request,
      metadata || {},
      methodDescriptor_HistorianService_uploadTagDataTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TagTimeseriesRequest,
 *   !proto.TagTimeseriesResponse>}
 */
const methodDescriptor_HistorianService_getTagTimeseriesInfo = new grpc.web.MethodDescriptor(
  '/HistorianService/getTagTimeseriesInfo',
  grpc.web.MethodType.UNARY,
  proto.TagTimeseriesRequest,
  proto.TagTimeseriesResponse,
  /**
   * @param {!proto.TagTimeseriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TagTimeseriesResponse.deserializeBinary
);


/**
 * @param {!proto.TagTimeseriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TagTimeseriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TagTimeseriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.HistorianServiceClient.prototype.getTagTimeseriesInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/HistorianService/getTagTimeseriesInfo',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTagTimeseriesInfo,
      callback);
};


/**
 * @param {!proto.TagTimeseriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TagTimeseriesResponse>}
 *     Promise that resolves to the response
 */
proto.HistorianServicePromiseClient.prototype.getTagTimeseriesInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/HistorianService/getTagTimeseriesInfo',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTagTimeseriesInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TagTimeseriesValueInfoRequest,
 *   !proto.TagTimeseriesValueInfoResponse>}
 */
const methodDescriptor_HistorianService_getTagTimeseriesWithValue = new grpc.web.MethodDescriptor(
  '/HistorianService/getTagTimeseriesWithValue',
  grpc.web.MethodType.UNARY,
  proto.TagTimeseriesValueInfoRequest,
  proto.TagTimeseriesValueInfoResponse,
  /**
   * @param {!proto.TagTimeseriesValueInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TagTimeseriesValueInfoResponse.deserializeBinary
);


/**
 * @param {!proto.TagTimeseriesValueInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TagTimeseriesValueInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TagTimeseriesValueInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.HistorianServiceClient.prototype.getTagTimeseriesWithValue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/HistorianService/getTagTimeseriesWithValue',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTagTimeseriesWithValue,
      callback);
};


/**
 * @param {!proto.TagTimeseriesValueInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TagTimeseriesValueInfoResponse>}
 *     Promise that resolves to the response
 */
proto.HistorianServicePromiseClient.prototype.getTagTimeseriesWithValue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/HistorianService/getTagTimeseriesWithValue',
      request,
      metadata || {},
      methodDescriptor_HistorianService_getTagTimeseriesWithValue);
};


module.exports = proto;

