import { ProgressProps } from "types";
import "./index.scss";

const colors = ["#2F54EB", "#ADC6FF", "#F5F5F5"];
const valueColors = ["#2F54EB", "#FAAD14", "#A0D911"];

const Progress: React.FC<ProgressProps> = ({ data }) => {
  return (
    <div className="progress-container">
      <div className="progress">
        {data.map((data, i) => {
          return (
            <div
              className="progress-done"
              style={{
                opacity: 1,
                width: `${data.width}`,
                background: colors[i],
              }}
            ></div>
          );
        })}
      </div>
      <div className="progress-data">
        {data.map((data, i) => {
          return (
            <div>
              <div style={{ color: valueColors[i] }}>{data.value}</div>
              <div>{data.key}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Progress;
