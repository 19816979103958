// source: service/ReasonCodeOperatorService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_Commons_pb = require('../common/Commons_pb.js');
goog.object.extend(proto, common_Commons_pb);
var model_ReasonCodeOperatorModel_pb = require('../model/ReasonCodeOperatorModel_pb.js');
goog.object.extend(proto, model_ReasonCodeOperatorModel_pb);
goog.exportSymbol('proto.proto.FpLineOverviewRequest', null, global);
goog.exportSymbol('proto.proto.FpLineOverviewResponse', null, global);
goog.exportSymbol('proto.proto.FpLogRequest', null, global);
goog.exportSymbol('proto.proto.FpLogResponse', null, global);
goog.exportSymbol('proto.proto.MachineDetailRequest', null, global);
goog.exportSymbol('proto.proto.MachineDetailResponse', null, global);
goog.exportSymbol('proto.proto.MachinesRunningStatusRequest', null, global);
goog.exportSymbol('proto.proto.MachinesRunningStatusResponse', null, global);
goog.exportSymbol('proto.proto.ProcessLineInfoResponse', null, global);
goog.exportSymbol('proto.proto.ReasonCodeAssignRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FpLineOverviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.FpLineOverviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FpLineOverviewRequest.displayName = 'proto.proto.FpLineOverviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FpLineOverviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.FpLineOverviewResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.FpLineOverviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FpLineOverviewResponse.displayName = 'proto.proto.FpLineOverviewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MachineDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MachineDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MachineDetailRequest.displayName = 'proto.proto.MachineDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeAssignRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ReasonCodeAssignRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ReasonCodeAssignRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeAssignRequest.displayName = 'proto.proto.ReasonCodeAssignRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MachineDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MachineDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MachineDetailResponse.displayName = 'proto.proto.MachineDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FpLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.FpLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FpLogRequest.displayName = 'proto.proto.FpLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FpLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.FpLogResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.FpLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FpLogResponse.displayName = 'proto.proto.FpLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ProcessLineInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ProcessLineInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ProcessLineInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ProcessLineInfoResponse.displayName = 'proto.proto.ProcessLineInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MachinesRunningStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MachinesRunningStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MachinesRunningStatusRequest.displayName = 'proto.proto.MachinesRunningStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MachinesRunningStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.MachinesRunningStatusResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.MachinesRunningStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MachinesRunningStatusResponse.displayName = 'proto.proto.MachinesRunningStatusResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FpLineOverviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FpLineOverviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FpLineOverviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FpLineOverviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalRequest: (f = msg.getGeneralRequest()) && common_Commons_pb.GeneralRequest.toObject(includeInstance, f),
    fpLineInfo: (f = msg.getFpLineInfo()) && model_ReasonCodeOperatorModel_pb.FpLineInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FpLineOverviewRequest}
 */
proto.proto.FpLineOverviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FpLineOverviewRequest;
  return proto.proto.FpLineOverviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FpLineOverviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FpLineOverviewRequest}
 */
proto.proto.FpLineOverviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralRequest;
      reader.readMessage(value,common_Commons_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    case 2:
      var value = new model_ReasonCodeOperatorModel_pb.FpLineInfo;
      reader.readMessage(value,model_ReasonCodeOperatorModel_pb.FpLineInfo.deserializeBinaryFromReader);
      msg.setFpLineInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FpLineOverviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FpLineOverviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FpLineOverviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FpLineOverviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
  f = message.getFpLineInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      model_ReasonCodeOperatorModel_pb.FpLineInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralRequest general_request = 1;
 * @return {?proto.proto.GeneralRequest}
 */
proto.proto.FpLineOverviewRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.proto.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralRequest, 1));
};


/**
 * @param {?proto.proto.GeneralRequest|undefined} value
 * @return {!proto.proto.FpLineOverviewRequest} returns this
*/
proto.proto.FpLineOverviewRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FpLineOverviewRequest} returns this
 */
proto.proto.FpLineOverviewRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FpLineOverviewRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FpLineInfo fp_line_info = 2;
 * @return {?proto.proto.FpLineInfo}
 */
proto.proto.FpLineOverviewRequest.prototype.getFpLineInfo = function() {
  return /** @type{?proto.proto.FpLineInfo} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeOperatorModel_pb.FpLineInfo, 2));
};


/**
 * @param {?proto.proto.FpLineInfo|undefined} value
 * @return {!proto.proto.FpLineOverviewRequest} returns this
*/
proto.proto.FpLineOverviewRequest.prototype.setFpLineInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FpLineOverviewRequest} returns this
 */
proto.proto.FpLineOverviewRequest.prototype.clearFpLineInfo = function() {
  return this.setFpLineInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FpLineOverviewRequest.prototype.hasFpLineInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.FpLineOverviewResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FpLineOverviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FpLineOverviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FpLineOverviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FpLineOverviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    fpLineDetails: (f = msg.getFpLineDetails()) && model_ReasonCodeOperatorModel_pb.FpLineDetails.toObject(includeInstance, f),
    machineInfoList: jspb.Message.toObjectList(msg.getMachineInfoList(),
    model_ReasonCodeOperatorModel_pb.MachineInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FpLineOverviewResponse}
 */
proto.proto.FpLineOverviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FpLineOverviewResponse;
  return proto.proto.FpLineOverviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FpLineOverviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FpLineOverviewResponse}
 */
proto.proto.FpLineOverviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = new model_ReasonCodeOperatorModel_pb.FpLineDetails;
      reader.readMessage(value,model_ReasonCodeOperatorModel_pb.FpLineDetails.deserializeBinaryFromReader);
      msg.setFpLineDetails(value);
      break;
    case 3:
      var value = new model_ReasonCodeOperatorModel_pb.MachineInfo;
      reader.readMessage(value,model_ReasonCodeOperatorModel_pb.MachineInfo.deserializeBinaryFromReader);
      msg.addMachineInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FpLineOverviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FpLineOverviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FpLineOverviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FpLineOverviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getFpLineDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      model_ReasonCodeOperatorModel_pb.FpLineDetails.serializeBinaryToWriter
    );
  }
  f = message.getMachineInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      model_ReasonCodeOperatorModel_pb.MachineInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.FpLineOverviewResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.FpLineOverviewResponse} returns this
*/
proto.proto.FpLineOverviewResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FpLineOverviewResponse} returns this
 */
proto.proto.FpLineOverviewResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FpLineOverviewResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FpLineDetails fp_line_details = 2;
 * @return {?proto.proto.FpLineDetails}
 */
proto.proto.FpLineOverviewResponse.prototype.getFpLineDetails = function() {
  return /** @type{?proto.proto.FpLineDetails} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeOperatorModel_pb.FpLineDetails, 2));
};


/**
 * @param {?proto.proto.FpLineDetails|undefined} value
 * @return {!proto.proto.FpLineOverviewResponse} returns this
*/
proto.proto.FpLineOverviewResponse.prototype.setFpLineDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FpLineOverviewResponse} returns this
 */
proto.proto.FpLineOverviewResponse.prototype.clearFpLineDetails = function() {
  return this.setFpLineDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FpLineOverviewResponse.prototype.hasFpLineDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated MachineInfo machine_info = 3;
 * @return {!Array<!proto.proto.MachineInfo>}
 */
proto.proto.FpLineOverviewResponse.prototype.getMachineInfoList = function() {
  return /** @type{!Array<!proto.proto.MachineInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_ReasonCodeOperatorModel_pb.MachineInfo, 3));
};


/**
 * @param {!Array<!proto.proto.MachineInfo>} value
 * @return {!proto.proto.FpLineOverviewResponse} returns this
*/
proto.proto.FpLineOverviewResponse.prototype.setMachineInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.proto.MachineInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MachineInfo}
 */
proto.proto.FpLineOverviewResponse.prototype.addMachineInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.proto.MachineInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.FpLineOverviewResponse} returns this
 */
proto.proto.FpLineOverviewResponse.prototype.clearMachineInfoList = function() {
  return this.setMachineInfoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MachineDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MachineDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MachineDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalRequest: (f = msg.getGeneralRequest()) && common_Commons_pb.GeneralRequest.toObject(includeInstance, f),
    machineInfo: (f = msg.getMachineInfo()) && model_ReasonCodeOperatorModel_pb.MachineInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MachineDetailRequest}
 */
proto.proto.MachineDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MachineDetailRequest;
  return proto.proto.MachineDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MachineDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MachineDetailRequest}
 */
proto.proto.MachineDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralRequest;
      reader.readMessage(value,common_Commons_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    case 2:
      var value = new model_ReasonCodeOperatorModel_pb.MachineInfo;
      reader.readMessage(value,model_ReasonCodeOperatorModel_pb.MachineInfo.deserializeBinaryFromReader);
      msg.setMachineInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MachineDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MachineDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MachineDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
  f = message.getMachineInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      model_ReasonCodeOperatorModel_pb.MachineInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralRequest general_request = 1;
 * @return {?proto.proto.GeneralRequest}
 */
proto.proto.MachineDetailRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.proto.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralRequest, 1));
};


/**
 * @param {?proto.proto.GeneralRequest|undefined} value
 * @return {!proto.proto.MachineDetailRequest} returns this
*/
proto.proto.MachineDetailRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MachineDetailRequest} returns this
 */
proto.proto.MachineDetailRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MachineDetailRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MachineInfo machine_info = 2;
 * @return {?proto.proto.MachineInfo}
 */
proto.proto.MachineDetailRequest.prototype.getMachineInfo = function() {
  return /** @type{?proto.proto.MachineInfo} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeOperatorModel_pb.MachineInfo, 2));
};


/**
 * @param {?proto.proto.MachineInfo|undefined} value
 * @return {!proto.proto.MachineDetailRequest} returns this
*/
proto.proto.MachineDetailRequest.prototype.setMachineInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MachineDetailRequest} returns this
 */
proto.proto.MachineDetailRequest.prototype.clearMachineInfo = function() {
  return this.setMachineInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MachineDetailRequest.prototype.hasMachineInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ReasonCodeAssignRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeAssignRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeAssignRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeAssignRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeAssignRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stoppageIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    reasonCodeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    generalRequest: (f = msg.getGeneralRequest()) && common_Commons_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeAssignRequest}
 */
proto.proto.ReasonCodeAssignRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeAssignRequest;
  return proto.proto.ReasonCodeAssignRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeAssignRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeAssignRequest}
 */
proto.proto.ReasonCodeAssignRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStoppageId(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReasonCodeId(value);
      break;
    case 3:
      var value = new common_Commons_pb.GeneralRequest;
      reader.readMessage(value,common_Commons_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeAssignRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeAssignRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeAssignRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeAssignRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoppageIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getReasonCodeId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_Commons_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int64 stoppage_id = 1;
 * @return {!Array<number>}
 */
proto.proto.ReasonCodeAssignRequest.prototype.getStoppageIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ReasonCodeAssignRequest} returns this
 */
proto.proto.ReasonCodeAssignRequest.prototype.setStoppageIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ReasonCodeAssignRequest} returns this
 */
proto.proto.ReasonCodeAssignRequest.prototype.addStoppageId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ReasonCodeAssignRequest} returns this
 */
proto.proto.ReasonCodeAssignRequest.prototype.clearStoppageIdList = function() {
  return this.setStoppageIdList([]);
};


/**
 * optional int64 reason_code_id = 2;
 * @return {number}
 */
proto.proto.ReasonCodeAssignRequest.prototype.getReasonCodeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReasonCodeAssignRequest} returns this
 */
proto.proto.ReasonCodeAssignRequest.prototype.setReasonCodeId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional GeneralRequest general_request = 3;
 * @return {?proto.proto.GeneralRequest}
 */
proto.proto.ReasonCodeAssignRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.proto.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralRequest, 3));
};


/**
 * @param {?proto.proto.GeneralRequest|undefined} value
 * @return {!proto.proto.ReasonCodeAssignRequest} returns this
*/
proto.proto.ReasonCodeAssignRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeAssignRequest} returns this
 */
proto.proto.ReasonCodeAssignRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeAssignRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MachineDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MachineDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MachineDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    machineData: (f = msg.getMachineData()) && model_ReasonCodeOperatorModel_pb.MachineData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MachineDetailResponse}
 */
proto.proto.MachineDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MachineDetailResponse;
  return proto.proto.MachineDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MachineDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MachineDetailResponse}
 */
proto.proto.MachineDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = new model_ReasonCodeOperatorModel_pb.MachineData;
      reader.readMessage(value,model_ReasonCodeOperatorModel_pb.MachineData.deserializeBinaryFromReader);
      msg.setMachineData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MachineDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MachineDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MachineDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getMachineData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      model_ReasonCodeOperatorModel_pb.MachineData.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.MachineDetailResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.MachineDetailResponse} returns this
*/
proto.proto.MachineDetailResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MachineDetailResponse} returns this
 */
proto.proto.MachineDetailResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MachineDetailResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MachineData machine_data = 2;
 * @return {?proto.proto.MachineData}
 */
proto.proto.MachineDetailResponse.prototype.getMachineData = function() {
  return /** @type{?proto.proto.MachineData} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeOperatorModel_pb.MachineData, 2));
};


/**
 * @param {?proto.proto.MachineData|undefined} value
 * @return {!proto.proto.MachineDetailResponse} returns this
*/
proto.proto.MachineDetailResponse.prototype.setMachineData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MachineDetailResponse} returns this
 */
proto.proto.MachineDetailResponse.prototype.clearMachineData = function() {
  return this.setMachineData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MachineDetailResponse.prototype.hasMachineData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FpLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FpLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FpLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FpLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalRequest: (f = msg.getGeneralRequest()) && common_Commons_pb.GeneralRequest.toObject(includeInstance, f),
    logFilters: (f = msg.getLogFilters()) && model_ReasonCodeOperatorModel_pb.LogFilters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FpLogRequest}
 */
proto.proto.FpLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FpLogRequest;
  return proto.proto.FpLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FpLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FpLogRequest}
 */
proto.proto.FpLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralRequest;
      reader.readMessage(value,common_Commons_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    case 2:
      var value = new model_ReasonCodeOperatorModel_pb.LogFilters;
      reader.readMessage(value,model_ReasonCodeOperatorModel_pb.LogFilters.deserializeBinaryFromReader);
      msg.setLogFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FpLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FpLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FpLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FpLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
  f = message.getLogFilters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      model_ReasonCodeOperatorModel_pb.LogFilters.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralRequest general_request = 1;
 * @return {?proto.proto.GeneralRequest}
 */
proto.proto.FpLogRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.proto.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralRequest, 1));
};


/**
 * @param {?proto.proto.GeneralRequest|undefined} value
 * @return {!proto.proto.FpLogRequest} returns this
*/
proto.proto.FpLogRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FpLogRequest} returns this
 */
proto.proto.FpLogRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FpLogRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LogFilters log_filters = 2;
 * @return {?proto.proto.LogFilters}
 */
proto.proto.FpLogRequest.prototype.getLogFilters = function() {
  return /** @type{?proto.proto.LogFilters} */ (
    jspb.Message.getWrapperField(this, model_ReasonCodeOperatorModel_pb.LogFilters, 2));
};


/**
 * @param {?proto.proto.LogFilters|undefined} value
 * @return {!proto.proto.FpLogRequest} returns this
*/
proto.proto.FpLogRequest.prototype.setLogFilters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FpLogRequest} returns this
 */
proto.proto.FpLogRequest.prototype.clearLogFilters = function() {
  return this.setLogFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FpLogRequest.prototype.hasLogFilters = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.FpLogResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FpLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FpLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FpLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FpLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    fpLogsList: jspb.Message.toObjectList(msg.getFpLogsList(),
    model_ReasonCodeOperatorModel_pb.FpLogs.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FpLogResponse}
 */
proto.proto.FpLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FpLogResponse;
  return proto.proto.FpLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FpLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FpLogResponse}
 */
proto.proto.FpLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = new model_ReasonCodeOperatorModel_pb.FpLogs;
      reader.readMessage(value,model_ReasonCodeOperatorModel_pb.FpLogs.deserializeBinaryFromReader);
      msg.addFpLogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FpLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FpLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FpLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FpLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getFpLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      model_ReasonCodeOperatorModel_pb.FpLogs.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.FpLogResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.FpLogResponse} returns this
*/
proto.proto.FpLogResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FpLogResponse} returns this
 */
proto.proto.FpLogResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FpLogResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated FpLogs fp_logs = 2;
 * @return {!Array<!proto.proto.FpLogs>}
 */
proto.proto.FpLogResponse.prototype.getFpLogsList = function() {
  return /** @type{!Array<!proto.proto.FpLogs>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_ReasonCodeOperatorModel_pb.FpLogs, 2));
};


/**
 * @param {!Array<!proto.proto.FpLogs>} value
 * @return {!proto.proto.FpLogResponse} returns this
*/
proto.proto.FpLogResponse.prototype.setFpLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.FpLogs=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.FpLogs}
 */
proto.proto.FpLogResponse.prototype.addFpLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.FpLogs, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.FpLogResponse} returns this
 */
proto.proto.FpLogResponse.prototype.clearFpLogsList = function() {
  return this.setFpLogsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ProcessLineInfoResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ProcessLineInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ProcessLineInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ProcessLineInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProcessLineInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processLineListList: jspb.Message.toObjectList(msg.getProcessLineListList(),
    model_ReasonCodeOperatorModel_pb.ProcessLine.toObject, includeInstance),
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ProcessLineInfoResponse}
 */
proto.proto.ProcessLineInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ProcessLineInfoResponse;
  return proto.proto.ProcessLineInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ProcessLineInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ProcessLineInfoResponse}
 */
proto.proto.ProcessLineInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new model_ReasonCodeOperatorModel_pb.ProcessLine;
      reader.readMessage(value,model_ReasonCodeOperatorModel_pb.ProcessLine.deserializeBinaryFromReader);
      msg.addProcessLineList(value);
      break;
    case 2:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ProcessLineInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ProcessLineInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ProcessLineInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProcessLineInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessLineListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      model_ReasonCodeOperatorModel_pb.ProcessLine.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProcessLine process_line_list = 1;
 * @return {!Array<!proto.proto.ProcessLine>}
 */
proto.proto.ProcessLineInfoResponse.prototype.getProcessLineListList = function() {
  return /** @type{!Array<!proto.proto.ProcessLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_ReasonCodeOperatorModel_pb.ProcessLine, 1));
};


/**
 * @param {!Array<!proto.proto.ProcessLine>} value
 * @return {!proto.proto.ProcessLineInfoResponse} returns this
*/
proto.proto.ProcessLineInfoResponse.prototype.setProcessLineListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.ProcessLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ProcessLine}
 */
proto.proto.ProcessLineInfoResponse.prototype.addProcessLineList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.ProcessLine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ProcessLineInfoResponse} returns this
 */
proto.proto.ProcessLineInfoResponse.prototype.clearProcessLineListList = function() {
  return this.setProcessLineListList([]);
};


/**
 * optional GeneralResponse general_response = 2;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.ProcessLineInfoResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 2));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.ProcessLineInfoResponse} returns this
*/
proto.proto.ProcessLineInfoResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ProcessLineInfoResponse} returns this
 */
proto.proto.ProcessLineInfoResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ProcessLineInfoResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MachinesRunningStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MachinesRunningStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MachinesRunningStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachinesRunningStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalRequest: (f = msg.getGeneralRequest()) && common_Commons_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MachinesRunningStatusRequest}
 */
proto.proto.MachinesRunningStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MachinesRunningStatusRequest;
  return proto.proto.MachinesRunningStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MachinesRunningStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MachinesRunningStatusRequest}
 */
proto.proto.MachinesRunningStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralRequest;
      reader.readMessage(value,common_Commons_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MachinesRunningStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MachinesRunningStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MachinesRunningStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachinesRunningStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralRequest general_request = 1;
 * @return {?proto.proto.GeneralRequest}
 */
proto.proto.MachinesRunningStatusRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.proto.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralRequest, 1));
};


/**
 * @param {?proto.proto.GeneralRequest|undefined} value
 * @return {!proto.proto.MachinesRunningStatusRequest} returns this
*/
proto.proto.MachinesRunningStatusRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MachinesRunningStatusRequest} returns this
 */
proto.proto.MachinesRunningStatusRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MachinesRunningStatusRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.MachinesRunningStatusResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MachinesRunningStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MachinesRunningStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MachinesRunningStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachinesRunningStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineStatusInfoList: jspb.Message.toObjectList(msg.getMachineStatusInfoList(),
    model_ReasonCodeOperatorModel_pb.MachineStatusInfo.toObject, includeInstance),
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MachinesRunningStatusResponse}
 */
proto.proto.MachinesRunningStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MachinesRunningStatusResponse;
  return proto.proto.MachinesRunningStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MachinesRunningStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MachinesRunningStatusResponse}
 */
proto.proto.MachinesRunningStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new model_ReasonCodeOperatorModel_pb.MachineStatusInfo;
      reader.readMessage(value,model_ReasonCodeOperatorModel_pb.MachineStatusInfo.deserializeBinaryFromReader);
      msg.addMachineStatusInfo(value);
      break;
    case 2:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MachinesRunningStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MachinesRunningStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MachinesRunningStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachinesRunningStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineStatusInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      model_ReasonCodeOperatorModel_pb.MachineStatusInfo.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MachineStatusInfo machine_status_info = 1;
 * @return {!Array<!proto.proto.MachineStatusInfo>}
 */
proto.proto.MachinesRunningStatusResponse.prototype.getMachineStatusInfoList = function() {
  return /** @type{!Array<!proto.proto.MachineStatusInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_ReasonCodeOperatorModel_pb.MachineStatusInfo, 1));
};


/**
 * @param {!Array<!proto.proto.MachineStatusInfo>} value
 * @return {!proto.proto.MachinesRunningStatusResponse} returns this
*/
proto.proto.MachinesRunningStatusResponse.prototype.setMachineStatusInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.MachineStatusInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MachineStatusInfo}
 */
proto.proto.MachinesRunningStatusResponse.prototype.addMachineStatusInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.MachineStatusInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.MachinesRunningStatusResponse} returns this
 */
proto.proto.MachinesRunningStatusResponse.prototype.clearMachineStatusInfoList = function() {
  return this.setMachineStatusInfoList([]);
};


/**
 * optional GeneralResponse general_response = 2;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.MachinesRunningStatusResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 2));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.MachinesRunningStatusResponse} returns this
*/
proto.proto.MachinesRunningStatusResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MachinesRunningStatusResponse} returns this
 */
proto.proto.MachinesRunningStatusResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MachinesRunningStatusResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.proto);
