// source: objects/EquipmentClass.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_Enums_pb = require('../common/Enums_pb.js');
goog.object.extend(proto, common_Enums_pb);
var common_API_pb = require('../common/API_pb.js');
goog.object.extend(proto, common_API_pb);
var common_BaseMessage_pb = require('../common/BaseMessage_pb.js');
goog.object.extend(proto, common_BaseMessage_pb);
goog.exportSymbol('proto.EquipmentAttribute', null, global);
goog.exportSymbol('proto.EquipmentClass', null, global);
goog.exportSymbol('proto.EquipmentClassAttribute', null, global);
goog.exportSymbol('proto.EquipmentClassCategory', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EquipmentClass = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EquipmentClass.repeatedFields_, null);
};
goog.inherits(proto.EquipmentClass, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EquipmentClass.displayName = 'proto.EquipmentClass';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EquipmentClassCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EquipmentClassCategory.repeatedFields_, null);
};
goog.inherits(proto.EquipmentClassCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EquipmentClassCategory.displayName = 'proto.EquipmentClassCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EquipmentClassAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EquipmentClassAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EquipmentClassAttribute.displayName = 'proto.EquipmentClassAttribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EquipmentAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EquipmentAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EquipmentAttribute.displayName = 'proto.EquipmentAttribute';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EquipmentClass.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EquipmentClass.prototype.toObject = function(opt_includeInstance) {
  return proto.EquipmentClass.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EquipmentClass} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentClass.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && common_API_pb.ID.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.EquipmentClassCategory.toObject, includeInstance),
    base: (f = msg.getBase()) && common_BaseMessage_pb.BaseMessage.toObject(includeInstance, f),
    umiIndex: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EquipmentClass}
 */
proto.EquipmentClass.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EquipmentClass;
  return proto.EquipmentClass.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EquipmentClass} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EquipmentClass}
 */
proto.EquipmentClass.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.ID;
      reader.readMessage(value,common_API_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.EquipmentClassCategory;
      reader.readMessage(value,proto.EquipmentClassCategory.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    case 5:
      var value = new common_BaseMessage_pb.BaseMessage;
      reader.readMessage(value,common_BaseMessage_pb.BaseMessage.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUmiIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EquipmentClass.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EquipmentClass.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EquipmentClass} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentClass.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.EquipmentClassCategory.serializeBinaryToWriter
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_BaseMessage_pb.BaseMessage.serializeBinaryToWriter
    );
  }
  f = message.getUmiIndex();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional ID id = 1;
 * @return {?proto.ID}
 */
proto.EquipmentClass.prototype.getId = function() {
  return /** @type{?proto.ID} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ID, 1));
};


/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.EquipmentClass} returns this
*/
proto.EquipmentClass.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EquipmentClass} returns this
 */
proto.EquipmentClass.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EquipmentClass.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.EquipmentClass.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentClass} returns this
 */
proto.EquipmentClass.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.EquipmentClass.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentClass} returns this
 */
proto.EquipmentClass.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated EquipmentClassCategory categories = 4;
 * @return {!Array<!proto.EquipmentClassCategory>}
 */
proto.EquipmentClass.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.EquipmentClassCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EquipmentClassCategory, 4));
};


/**
 * @param {!Array<!proto.EquipmentClassCategory>} value
 * @return {!proto.EquipmentClass} returns this
*/
proto.EquipmentClass.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.EquipmentClassCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EquipmentClassCategory}
 */
proto.EquipmentClass.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.EquipmentClassCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EquipmentClass} returns this
 */
proto.EquipmentClass.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * optional BaseMessage base = 5;
 * @return {?proto.BaseMessage}
 */
proto.EquipmentClass.prototype.getBase = function() {
  return /** @type{?proto.BaseMessage} */ (
    jspb.Message.getWrapperField(this, common_BaseMessage_pb.BaseMessage, 5));
};


/**
 * @param {?proto.BaseMessage|undefined} value
 * @return {!proto.EquipmentClass} returns this
*/
proto.EquipmentClass.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EquipmentClass} returns this
 */
proto.EquipmentClass.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EquipmentClass.prototype.hasBase = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 umi_index = 6;
 * @return {number}
 */
proto.EquipmentClass.prototype.getUmiIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentClass} returns this
 */
proto.EquipmentClass.prototype.setUmiIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EquipmentClassCategory.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EquipmentClassCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.EquipmentClassCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EquipmentClassCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentClassCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && common_API_pb.ID.toObject(includeInstance, f),
    category: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attributesList: jspb.Message.toObjectList(msg.getAttributesList(),
    proto.EquipmentClassAttribute.toObject, includeInstance),
    base: (f = msg.getBase()) && common_BaseMessage_pb.BaseMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EquipmentClassCategory}
 */
proto.EquipmentClassCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EquipmentClassCategory;
  return proto.EquipmentClassCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EquipmentClassCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EquipmentClassCategory}
 */
proto.EquipmentClassCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.ID;
      reader.readMessage(value,common_API_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.EquipmentClassAttribute;
      reader.readMessage(value,proto.EquipmentClassAttribute.deserializeBinaryFromReader);
      msg.addAttributes(value);
      break;
    case 5:
      var value = new common_BaseMessage_pb.BaseMessage;
      reader.readMessage(value,common_BaseMessage_pb.BaseMessage.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EquipmentClassCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EquipmentClassCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EquipmentClassCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentClassCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.EquipmentClassAttribute.serializeBinaryToWriter
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_BaseMessage_pb.BaseMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ID id = 1;
 * @return {?proto.ID}
 */
proto.EquipmentClassCategory.prototype.getId = function() {
  return /** @type{?proto.ID} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ID, 1));
};


/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.EquipmentClassCategory} returns this
*/
proto.EquipmentClassCategory.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EquipmentClassCategory} returns this
 */
proto.EquipmentClassCategory.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EquipmentClassCategory.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string category = 2;
 * @return {string}
 */
proto.EquipmentClassCategory.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentClassCategory} returns this
 */
proto.EquipmentClassCategory.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.EquipmentClassCategory.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentClassCategory} returns this
 */
proto.EquipmentClassCategory.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated EquipmentClassAttribute attributes = 4;
 * @return {!Array<!proto.EquipmentClassAttribute>}
 */
proto.EquipmentClassCategory.prototype.getAttributesList = function() {
  return /** @type{!Array<!proto.EquipmentClassAttribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EquipmentClassAttribute, 4));
};


/**
 * @param {!Array<!proto.EquipmentClassAttribute>} value
 * @return {!proto.EquipmentClassCategory} returns this
*/
proto.EquipmentClassCategory.prototype.setAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.EquipmentClassAttribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EquipmentClassAttribute}
 */
proto.EquipmentClassCategory.prototype.addAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.EquipmentClassAttribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EquipmentClassCategory} returns this
 */
proto.EquipmentClassCategory.prototype.clearAttributesList = function() {
  return this.setAttributesList([]);
};


/**
 * optional BaseMessage base = 5;
 * @return {?proto.BaseMessage}
 */
proto.EquipmentClassCategory.prototype.getBase = function() {
  return /** @type{?proto.BaseMessage} */ (
    jspb.Message.getWrapperField(this, common_BaseMessage_pb.BaseMessage, 5));
};


/**
 * @param {?proto.BaseMessage|undefined} value
 * @return {!proto.EquipmentClassCategory} returns this
*/
proto.EquipmentClassCategory.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EquipmentClassCategory} returns this
 */
proto.EquipmentClassCategory.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EquipmentClassCategory.prototype.hasBase = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EquipmentClassAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.EquipmentClassAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EquipmentClassAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentClassAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && common_API_pb.ID.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dataType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    validationRegex: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 5, ""),
    defaultValue: jspb.Message.getFieldWithDefault(msg, 6, ""),
    base: (f = msg.getBase()) && common_BaseMessage_pb.BaseMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EquipmentClassAttribute}
 */
proto.EquipmentClassAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EquipmentClassAttribute;
  return proto.EquipmentClassAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EquipmentClassAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EquipmentClassAttribute}
 */
proto.EquipmentClassAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.ID;
      reader.readMessage(value,common_API_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.DataTypeEnum} */ (reader.readEnum());
      msg.setDataType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationRegex(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultValue(value);
      break;
    case 7:
      var value = new common_BaseMessage_pb.BaseMessage;
      reader.readMessage(value,common_BaseMessage_pb.BaseMessage.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EquipmentClassAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EquipmentClassAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EquipmentClassAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentClassAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDataType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getValidationRegex();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDefaultValue();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      common_BaseMessage_pb.BaseMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ID id = 1;
 * @return {?proto.ID}
 */
proto.EquipmentClassAttribute.prototype.getId = function() {
  return /** @type{?proto.ID} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ID, 1));
};


/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.EquipmentClassAttribute} returns this
*/
proto.EquipmentClassAttribute.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EquipmentClassAttribute} returns this
 */
proto.EquipmentClassAttribute.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EquipmentClassAttribute.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.EquipmentClassAttribute.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentClassAttribute} returns this
 */
proto.EquipmentClassAttribute.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DataTypeEnum data_type = 3;
 * @return {!proto.DataTypeEnum}
 */
proto.EquipmentClassAttribute.prototype.getDataType = function() {
  return /** @type {!proto.DataTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.DataTypeEnum} value
 * @return {!proto.EquipmentClassAttribute} returns this
 */
proto.EquipmentClassAttribute.prototype.setDataType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string validation_regex = 4;
 * @return {string}
 */
proto.EquipmentClassAttribute.prototype.getValidationRegex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentClassAttribute} returns this
 */
proto.EquipmentClassAttribute.prototype.setValidationRegex = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string unit = 5;
 * @return {string}
 */
proto.EquipmentClassAttribute.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentClassAttribute} returns this
 */
proto.EquipmentClassAttribute.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string default_value = 6;
 * @return {string}
 */
proto.EquipmentClassAttribute.prototype.getDefaultValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentClassAttribute} returns this
 */
proto.EquipmentClassAttribute.prototype.setDefaultValue = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional BaseMessage base = 7;
 * @return {?proto.BaseMessage}
 */
proto.EquipmentClassAttribute.prototype.getBase = function() {
  return /** @type{?proto.BaseMessage} */ (
    jspb.Message.getWrapperField(this, common_BaseMessage_pb.BaseMessage, 7));
};


/**
 * @param {?proto.BaseMessage|undefined} value
 * @return {!proto.EquipmentClassAttribute} returns this
*/
proto.EquipmentClassAttribute.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EquipmentClassAttribute} returns this
 */
proto.EquipmentClassAttribute.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EquipmentClassAttribute.prototype.hasBase = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EquipmentAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.EquipmentAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EquipmentAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && common_API_pb.ID.toObject(includeInstance, f),
    equipmentClassAttributeId: (f = msg.getEquipmentClassAttributeId()) && common_API_pb.ID.toObject(includeInstance, f),
    attributeName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attributeValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    base: (f = msg.getBase()) && common_BaseMessage_pb.BaseMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EquipmentAttribute}
 */
proto.EquipmentAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EquipmentAttribute;
  return proto.EquipmentAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EquipmentAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EquipmentAttribute}
 */
proto.EquipmentAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.ID;
      reader.readMessage(value,common_API_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new common_API_pb.ID;
      reader.readMessage(value,common_API_pb.ID.deserializeBinaryFromReader);
      msg.setEquipmentClassAttributeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributeName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributeValue(value);
      break;
    case 5:
      var value = new common_BaseMessage_pb.BaseMessage;
      reader.readMessage(value,common_BaseMessage_pb.BaseMessage.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EquipmentAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EquipmentAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EquipmentAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentClassAttributeId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_API_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getAttributeName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttributeValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_BaseMessage_pb.BaseMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ID id = 1;
 * @return {?proto.ID}
 */
proto.EquipmentAttribute.prototype.getId = function() {
  return /** @type{?proto.ID} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ID, 1));
};


/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.EquipmentAttribute} returns this
*/
proto.EquipmentAttribute.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EquipmentAttribute} returns this
 */
proto.EquipmentAttribute.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EquipmentAttribute.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ID equipment_class_attribute_id = 2;
 * @return {?proto.ID}
 */
proto.EquipmentAttribute.prototype.getEquipmentClassAttributeId = function() {
  return /** @type{?proto.ID} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ID, 2));
};


/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.EquipmentAttribute} returns this
*/
proto.EquipmentAttribute.prototype.setEquipmentClassAttributeId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EquipmentAttribute} returns this
 */
proto.EquipmentAttribute.prototype.clearEquipmentClassAttributeId = function() {
  return this.setEquipmentClassAttributeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EquipmentAttribute.prototype.hasEquipmentClassAttributeId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string attribute_name = 3;
 * @return {string}
 */
proto.EquipmentAttribute.prototype.getAttributeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentAttribute} returns this
 */
proto.EquipmentAttribute.prototype.setAttributeName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string attribute_value = 4;
 * @return {string}
 */
proto.EquipmentAttribute.prototype.getAttributeValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentAttribute} returns this
 */
proto.EquipmentAttribute.prototype.setAttributeValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional BaseMessage base = 5;
 * @return {?proto.BaseMessage}
 */
proto.EquipmentAttribute.prototype.getBase = function() {
  return /** @type{?proto.BaseMessage} */ (
    jspb.Message.getWrapperField(this, common_BaseMessage_pb.BaseMessage, 5));
};


/**
 * @param {?proto.BaseMessage|undefined} value
 * @return {!proto.EquipmentAttribute} returns this
*/
proto.EquipmentAttribute.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EquipmentAttribute} returns this
 */
proto.EquipmentAttribute.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EquipmentAttribute.prototype.hasBase = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto);
