import { message } from "antd";
import { getUrl } from "config/apiconfig";
import {
  mapPPAllBatchesDetails,
  mapProductionOverviewBatchCount,
  mapProductionOverviewBatchDetails,
  mapProductionOverviewPackagingDetails,
} from "mappers/productionOverview.mapper";
import { lineToEnum, machineToEnum } from "utils/serviceHelperFunctions";
const {
  Lines,
  Machines,
  Skus,
} = require("protobuf/upl-sandbach-protos/common/Enum_pb");

export class ProductionOverviewService {
  private static instance: ProductionOverviewService;
  private productionOverviewServiceClient: any;
  private productionOverviewProtoObj: any;
  private commonProtoObj: any;
  private filterProtoObj: any;
  private authToken: any = "";
  private metadata: any;
  processOrderServiceClient: any;

  private constructor() {
    const productionOverviewURL = getUrl("productionOverviewServiceUrl");
    const processOderURL=getUrl('')
    this.commonProtoObj = require("protobuf/upl-sandbach-protos/common/Common_pb");
    this.filterProtoObj = require("protobuf/upl-sandbach-protos/service/Filter_pb");

    const productionOverviewService = require("protobuf/upl-sandbach-protos/service/ProductionOverview_grpc_web_pb");
    this.productionOverviewProtoObj = require("protobuf/upl-sandbach-protos/service/ProductionOverview_pb");
    this.productionOverviewServiceClient =
      new productionOverviewService.ProductionOverviewServicePromiseClient(
        productionOverviewURL,
        null,
        null
      );
    this.authToken = localStorage.getItem("authToken");
    this.metadata = { authorization: "Bearer " + this.authToken };
  }

  public static getInstance(): ProductionOverviewService {
    if (!ProductionOverviewService.instance) {
      ProductionOverviewService.instance = new ProductionOverviewService();
    }
    return ProductionOverviewService.instance;
  }

  getProductionBatchCountData = (payload: any) => {
    const request = new this.productionOverviewProtoObj.BatchFilterRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    const dropdownFilterSku = new this.commonProtoObj.DropDownData();
    const batchFilter = new this.filterProtoObj.BatchFilter();
    generalRequest.setRequestedBy("brabo-web");
    generalRequest.setRequestedService("production-overview-data");
    request.setRequest(generalRequest);
    dropdownFilterSku.setValue(payload.sku);
    dropdownFilterSku.setKey(payload.sku);
    if (payload.lineObject.length) {
      payload.lineObject.forEach((element: any) => {
        let lineObj = new this.filterProtoObj.BatchFilter.Line();

        element.machine.forEach((machine: any) => {
          const machineObj = new this.filterProtoObj.BatchFilter.Line.Machine();
          machineObj.setName(Machines[machineToEnum(machine)]);
          lineObj.addMachine(machineObj);
        });
        lineObj.setName(element.line);
        batchFilter.addLine(lineObj);
      });
    }
    if (payload.sku && payload.sku !== "")
      batchFilter.setSku(dropdownFilterSku);
    batchFilter.setStartTime(payload.startTime);
    batchFilter.setEndTime(payload.endTime);
    batchFilter.setBatchesList(payload.batches ? payload.batches : []);
    request.setFilter(batchFilter);
    return this.productionOverviewServiceClient
      .getBatchCount(request, this.metadata)
      .then((response: any) => {
        return mapProductionOverviewBatchCount(response.getCount());
      })
      .catch((err: any) => {
        message.error("Unable to get Production Overview data");
      });
  };

  getProductionBatchDetailsData = (payload: any) => {
    const request = new this.productionOverviewProtoObj.BatchFilterRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    const dropdownFilterSku = new this.commonProtoObj.DropDownData();
    const batchFilter = new this.filterProtoObj.BatchFilter();
    generalRequest.setRequestedBy("brabo-web");
    generalRequest.setRequestedService("production-overview-data");
    request.setRequest(generalRequest);
    dropdownFilterSku.setValue(payload.sku);
    dropdownFilterSku.setKey(payload.sku);
    if (payload.lineObject.length) {
      payload.lineObject.forEach((element: any) => {
        let lineObj = new this.filterProtoObj.BatchFilter.Line();
        element.machine.forEach((machine: any) => {
          const machineObj = new this.filterProtoObj.BatchFilter.Line.Machine();
          machineObj.setName(Machines[machineToEnum(machine)]);
          lineObj.addMachine(machineObj);
        });
        lineObj.setName(element.line);
        batchFilter.addLine(lineObj);
      });
    }
    if (payload.sku && payload.sku !== "")
      batchFilter.setSku(dropdownFilterSku);
    batchFilter.setStartTime(payload.startTime);
    batchFilter.setEndTime(payload.endTime);
    batchFilter.setBatchesList(payload.batches ? payload.batches : []);
    request.setFilter(batchFilter);
    return this.productionOverviewServiceClient
      .getBatchDetails(request, this.metadata)
      .then((response: any) => {
        return mapProductionOverviewBatchDetails(response.getData());
      })
      .catch((err: any) => {
        message.error("Unable to get Production Overview batch data");
      });
  };

  getPackagingDetails = (payload: any) => {
    const request = new this.productionOverviewProtoObj.BatchFilterRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    const dropdownFilterSku = new this.commonProtoObj.DropDownData();
    const batchFilter = new this.filterProtoObj.BatchFilter();
    generalRequest.setRequestedBy("brabo-web");
    generalRequest.setRequestedService("production-overview-data");
    request.setRequest(generalRequest);
    dropdownFilterSku.setValue(payload.sku);
    dropdownFilterSku.setKey(payload.sku);
    if (payload.lineObject.length) {
      payload.lineObject.forEach((element: any) => {
        let lineObj = new this.filterProtoObj.BatchFilter.Line();
        element.machine.forEach((machine: any) => {
          const machineObj = new this.filterProtoObj.BatchFilter.Line.Machine();
          machineObj.setName(Machines[machineToEnum(machine)]);
          lineObj.addMachine(machineObj);
        });
        lineObj.setName(element.line);
        batchFilter.addLine(lineObj);
      });
    }
    if (payload.sku && payload.sku !== "")
      batchFilter.setSku(dropdownFilterSku);
    batchFilter.setStartTime(payload.startTime);
    batchFilter.setEndTime(payload.endTime);
    batchFilter.setBatchesList(payload.batches ? payload.batches : []);
    request.setFilter(batchFilter);
    return this.productionOverviewServiceClient
      .getPackagingDetails(request, this.metadata)
      .then((response: any) => {
        return mapProductionOverviewPackagingDetails(response.getDetails());
      })
      .catch((err: any) => {
        message.error("Unable to get Production Overview Packaging data");
      });
  };
  getPPAllBatchesDetails = (payload: any) => {
    const request = new this.productionOverviewProtoObj.PPBatchesFilterRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();    
    generalRequest.setRequestedBy("brabo-web");
    generalRequest.setRequestedService("production-overview-data");
    request.setRequest(generalRequest);
    const ppaAllBAtchesFilterRequest = new this.productionOverviewProtoObj.PPBatchesFilter();
    ppaAllBAtchesFilterRequest.setSearchString(payload.searchString)
    ppaAllBAtchesFilterRequest.setStartTime(payload.startTime);
    ppaAllBAtchesFilterRequest.setEndTime(payload.endTime)
    request.setFilter(ppaAllBAtchesFilterRequest);
    return this.productionOverviewServiceClient
      .getPPAllBatches(request, this.metadata)
      .then((response: any) => {
        return mapPPAllBatchesDetails(response.getBatchesList());
      })
      .catch((err: any) => {
        message.error("Unable to get Production Overview Packaging data");
      });
  };
  updateRejectCountDetails = (payload: any) => {
    const request = new this.productionOverviewProtoObj.PPUpdateBatchRejectCountsRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();    
    generalRequest.setRequestedBy("brabo-web");
    generalRequest.setRequestedService("production-overview-data");
    request.setRequest(generalRequest);
    const updateRejectCountRequest = new this.productionOverviewProtoObj.PPUpdateBatchRejectCounts()
    updateRejectCountRequest.setProductionOrderNumber(payload.productionOrderNumberValue)
    updateRejectCountRequest.setBatchStartTime(payload.startTimeValue)
    updateRejectCountRequest.setStartupReject(payload.startUpRejectValue)
    updateRejectCountRequest.setProductionReject(payload.productionRejectValue)
    request.setBatchFilter(updateRejectCountRequest)
    return this.productionOverviewServiceClient
      .updateRejectCounts(request, this.metadata)
      
      .then((response: any) => {
        message.success('Reject count updated');
      })
      .catch((err: any) => {
        message.error("Unable to update Reject Count");
      });
  }
  
}
