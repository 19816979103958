// source: enums/ReasonCodeEnum.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.proto.MachineStatus', null, global);
goog.exportSymbol('proto.proto.OperationInfo', null, global);
goog.exportSymbol('proto.proto.ResourceView', null, global);
goog.exportSymbol('proto.proto.ShiftInfo', null, global);
goog.exportSymbol('proto.proto.Status', null, global);
/**
 * @enum {number}
 */
proto.proto.ResourceView = {
  TREE: 0,
  LIST: 1
};

/**
 * @enum {number}
 */
proto.proto.ShiftInfo = {
  SHIFT1: 0,
  SHIFT2: 1,
  SHIFT3: 2
};

/**
 * @enum {number}
 */
proto.proto.OperationInfo = {
  CREATE: 0,
  UPDATE: 1,
  READ: 2,
  DELETE: 3
};

/**
 * @enum {number}
 */
proto.proto.Status = {
  ALL: 0,
  ACTIVE: 1,
  INACTIVE: 2
};

/**
 * @enum {number}
 */
proto.proto.MachineStatus = {
  UNKNOWN: 0,
  RUNNING: 1,
  STOPPED: 2
};

goog.object.extend(exports, proto.proto);
