// source: objects/Asset.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_Enums_pb = require('../common/Enums_pb.js');
goog.object.extend(proto, common_Enums_pb);
var common_BaseMessage_pb = require('../common/BaseMessage_pb.js');
goog.object.extend(proto, common_BaseMessage_pb);
var common_API_pb = require('../common/API_pb.js');
goog.object.extend(proto, common_API_pb);
var objects_EquipmentClass_pb = require('../objects/EquipmentClass_pb.js');
goog.object.extend(proto, objects_EquipmentClass_pb);
var objects_NodeType_pb = require('../objects/NodeType_pb.js');
goog.object.extend(proto, objects_NodeType_pb);
goog.exportSymbol('proto.Model', null, global);
goog.exportSymbol('proto.Node', null, global);
goog.exportSymbol('proto.Stream', null, global);
goog.exportSymbol('proto.StreamDataType', null, global);
goog.exportSymbol('proto.StreamListFilter', null, global);
goog.exportSymbol('proto.StreamTag', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Model = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Model, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Model.displayName = 'proto.Model';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Node = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Node.repeatedFields_, null);
};
goog.inherits(proto.Node, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Node.displayName = 'proto.Node';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Stream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Stream.repeatedFields_, null);
};
goog.inherits(proto.Stream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Stream.displayName = 'proto.Stream';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StreamDataType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StreamDataType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StreamDataType.displayName = 'proto.StreamDataType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StreamTag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StreamTag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StreamTag.displayName = 'proto.StreamTag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StreamListFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.StreamListFilter.repeatedFields_, null);
};
goog.inherits(proto.StreamListFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StreamListFilter.displayName = 'proto.StreamListFilter';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Model.prototype.toObject = function(opt_includeInstance) {
  return proto.Model.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Model} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Model.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && common_API_pb.ID.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    umiIndex: jspb.Message.getFieldWithDefault(msg, 5, 0),
    base: (f = msg.getBase()) && common_BaseMessage_pb.BaseMessage.toObject(includeInstance, f),
    rootNode: (f = msg.getRootNode()) && proto.Node.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Model}
 */
proto.Model.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Model;
  return proto.Model.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Model} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Model}
 */
proto.Model.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.ID;
      reader.readMessage(value,common_API_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.ModelType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUmiIndex(value);
      break;
    case 6:
      var value = new common_BaseMessage_pb.BaseMessage;
      reader.readMessage(value,common_BaseMessage_pb.BaseMessage.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 7:
      var value = new proto.Node;
      reader.readMessage(value,proto.Node.deserializeBinaryFromReader);
      msg.setRootNode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Model.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Model.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Model} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Model.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getUmiIndex();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_BaseMessage_pb.BaseMessage.serializeBinaryToWriter
    );
  }
  f = message.getRootNode();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.Node.serializeBinaryToWriter
    );
  }
};


/**
 * optional ID id = 1;
 * @return {?proto.ID}
 */
proto.Model.prototype.getId = function() {
  return /** @type{?proto.ID} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ID, 1));
};


/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.Model} returns this
*/
proto.Model.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Model} returns this
 */
proto.Model.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Model.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.Model.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Model} returns this
 */
proto.Model.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.Model.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Model} returns this
 */
proto.Model.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ModelType type = 4;
 * @return {!proto.ModelType}
 */
proto.Model.prototype.getType = function() {
  return /** @type {!proto.ModelType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.ModelType} value
 * @return {!proto.Model} returns this
 */
proto.Model.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 umi_index = 5;
 * @return {number}
 */
proto.Model.prototype.getUmiIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.Model} returns this
 */
proto.Model.prototype.setUmiIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional BaseMessage base = 6;
 * @return {?proto.BaseMessage}
 */
proto.Model.prototype.getBase = function() {
  return /** @type{?proto.BaseMessage} */ (
    jspb.Message.getWrapperField(this, common_BaseMessage_pb.BaseMessage, 6));
};


/**
 * @param {?proto.BaseMessage|undefined} value
 * @return {!proto.Model} returns this
*/
proto.Model.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Model} returns this
 */
proto.Model.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Model.prototype.hasBase = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Node root_node = 7;
 * @return {?proto.Node}
 */
proto.Model.prototype.getRootNode = function() {
  return /** @type{?proto.Node} */ (
    jspb.Message.getWrapperField(this, proto.Node, 7));
};


/**
 * @param {?proto.Node|undefined} value
 * @return {!proto.Model} returns this
*/
proto.Model.prototype.setRootNode = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Model} returns this
 */
proto.Model.prototype.clearRootNode = function() {
  return this.setRootNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Model.prototype.hasRootNode = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Node.repeatedFields_ = [9,11,13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Node.prototype.toObject = function(opt_includeInstance) {
  return proto.Node.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Node} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Node.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && common_API_pb.ID.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    umiIndex: jspb.Message.getFieldWithDefault(msg, 5, 0),
    base: (f = msg.getBase()) && common_BaseMessage_pb.BaseMessage.toObject(includeInstance, f),
    model: (f = msg.getModel()) && proto.Model.toObject(includeInstance, f),
    equipmentClass: (f = msg.getEquipmentClass()) && objects_EquipmentClass_pb.EquipmentClass.toObject(includeInstance, f),
    equipmentAttributesList: jspb.Message.toObjectList(msg.getEquipmentAttributesList(),
    objects_EquipmentClass_pb.EquipmentAttribute.toObject, includeInstance),
    nodeType: (f = msg.getNodeType()) && objects_NodeType_pb.NodeType.toObject(includeInstance, f),
    nodeAttributesList: jspb.Message.toObjectList(msg.getNodeAttributesList(),
    objects_NodeType_pb.NodeAttribute.toObject, includeInstance),
    parentNode: (f = msg.getParentNode()) && common_API_pb.ID.toObject(includeInstance, f),
    childNodesList: jspb.Message.toObjectList(msg.getChildNodesList(),
    proto.Node.toObject, includeInstance),
    streamsList: jspb.Message.toObjectList(msg.getStreamsList(),
    proto.Stream.toObject, includeInstance),
    isCloudPushEligible: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    isMaintenanceEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    maintenanceTime: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Node}
 */
proto.Node.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Node;
  return proto.Node.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Node} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Node}
 */
proto.Node.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.ID;
      reader.readMessage(value,common_API_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.ModelType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUmiIndex(value);
      break;
    case 6:
      var value = new common_BaseMessage_pb.BaseMessage;
      reader.readMessage(value,common_BaseMessage_pb.BaseMessage.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 7:
      var value = new proto.Model;
      reader.readMessage(value,proto.Model.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    case 8:
      var value = new objects_EquipmentClass_pb.EquipmentClass;
      reader.readMessage(value,objects_EquipmentClass_pb.EquipmentClass.deserializeBinaryFromReader);
      msg.setEquipmentClass(value);
      break;
    case 9:
      var value = new objects_EquipmentClass_pb.EquipmentAttribute;
      reader.readMessage(value,objects_EquipmentClass_pb.EquipmentAttribute.deserializeBinaryFromReader);
      msg.addEquipmentAttributes(value);
      break;
    case 10:
      var value = new objects_NodeType_pb.NodeType;
      reader.readMessage(value,objects_NodeType_pb.NodeType.deserializeBinaryFromReader);
      msg.setNodeType(value);
      break;
    case 11:
      var value = new objects_NodeType_pb.NodeAttribute;
      reader.readMessage(value,objects_NodeType_pb.NodeAttribute.deserializeBinaryFromReader);
      msg.addNodeAttributes(value);
      break;
    case 12:
      var value = new common_API_pb.ID;
      reader.readMessage(value,common_API_pb.ID.deserializeBinaryFromReader);
      msg.setParentNode(value);
      break;
    case 13:
      var value = new proto.Node;
      reader.readMessage(value,proto.Node.deserializeBinaryFromReader);
      msg.addChildNodes(value);
      break;
    case 14:
      var value = new proto.Stream;
      reader.readMessage(value,proto.Stream.deserializeBinaryFromReader);
      msg.addStreams(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCloudPushEligible(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMaintenanceEnabled(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaintenanceTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Node.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Node.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Node} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Node.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getUmiIndex();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_BaseMessage_pb.BaseMessage.serializeBinaryToWriter
    );
  }
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.Model.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentClass();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      objects_EquipmentClass_pb.EquipmentClass.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      objects_EquipmentClass_pb.EquipmentAttribute.serializeBinaryToWriter
    );
  }
  f = message.getNodeType();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      objects_NodeType_pb.NodeType.serializeBinaryToWriter
    );
  }
  f = message.getNodeAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      objects_NodeType_pb.NodeAttribute.serializeBinaryToWriter
    );
  }
  f = message.getParentNode();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      common_API_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getChildNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.Node.serializeBinaryToWriter
    );
  }
  f = message.getStreamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.Stream.serializeBinaryToWriter
    );
  }
  f = message.getIsCloudPushEligible();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getIsMaintenanceEnabled();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getMaintenanceTime();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
};


/**
 * optional ID id = 1;
 * @return {?proto.ID}
 */
proto.Node.prototype.getId = function() {
  return /** @type{?proto.ID} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ID, 1));
};


/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.Node} returns this
*/
proto.Node.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Node.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.Node.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.Node.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ModelType type = 4;
 * @return {!proto.ModelType}
 */
proto.Node.prototype.getType = function() {
  return /** @type {!proto.ModelType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.ModelType} value
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 umi_index = 5;
 * @return {number}
 */
proto.Node.prototype.getUmiIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.setUmiIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional BaseMessage base = 6;
 * @return {?proto.BaseMessage}
 */
proto.Node.prototype.getBase = function() {
  return /** @type{?proto.BaseMessage} */ (
    jspb.Message.getWrapperField(this, common_BaseMessage_pb.BaseMessage, 6));
};


/**
 * @param {?proto.BaseMessage|undefined} value
 * @return {!proto.Node} returns this
*/
proto.Node.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Node.prototype.hasBase = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Model model = 7;
 * @return {?proto.Model}
 */
proto.Node.prototype.getModel = function() {
  return /** @type{?proto.Model} */ (
    jspb.Message.getWrapperField(this, proto.Model, 7));
};


/**
 * @param {?proto.Model|undefined} value
 * @return {!proto.Node} returns this
*/
proto.Node.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Node.prototype.hasModel = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EquipmentClass equipment_class = 8;
 * @return {?proto.EquipmentClass}
 */
proto.Node.prototype.getEquipmentClass = function() {
  return /** @type{?proto.EquipmentClass} */ (
    jspb.Message.getWrapperField(this, objects_EquipmentClass_pb.EquipmentClass, 8));
};


/**
 * @param {?proto.EquipmentClass|undefined} value
 * @return {!proto.Node} returns this
*/
proto.Node.prototype.setEquipmentClass = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.clearEquipmentClass = function() {
  return this.setEquipmentClass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Node.prototype.hasEquipmentClass = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated EquipmentAttribute equipment_attributes = 9;
 * @return {!Array<!proto.EquipmentAttribute>}
 */
proto.Node.prototype.getEquipmentAttributesList = function() {
  return /** @type{!Array<!proto.EquipmentAttribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, objects_EquipmentClass_pb.EquipmentAttribute, 9));
};


/**
 * @param {!Array<!proto.EquipmentAttribute>} value
 * @return {!proto.Node} returns this
*/
proto.Node.prototype.setEquipmentAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.EquipmentAttribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EquipmentAttribute}
 */
proto.Node.prototype.addEquipmentAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.EquipmentAttribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.clearEquipmentAttributesList = function() {
  return this.setEquipmentAttributesList([]);
};


/**
 * optional NodeType node_type = 10;
 * @return {?proto.NodeType}
 */
proto.Node.prototype.getNodeType = function() {
  return /** @type{?proto.NodeType} */ (
    jspb.Message.getWrapperField(this, objects_NodeType_pb.NodeType, 10));
};


/**
 * @param {?proto.NodeType|undefined} value
 * @return {!proto.Node} returns this
*/
proto.Node.prototype.setNodeType = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.clearNodeType = function() {
  return this.setNodeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Node.prototype.hasNodeType = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated NodeAttribute node_attributes = 11;
 * @return {!Array<!proto.NodeAttribute>}
 */
proto.Node.prototype.getNodeAttributesList = function() {
  return /** @type{!Array<!proto.NodeAttribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, objects_NodeType_pb.NodeAttribute, 11));
};


/**
 * @param {!Array<!proto.NodeAttribute>} value
 * @return {!proto.Node} returns this
*/
proto.Node.prototype.setNodeAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.NodeAttribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.NodeAttribute}
 */
proto.Node.prototype.addNodeAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.NodeAttribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.clearNodeAttributesList = function() {
  return this.setNodeAttributesList([]);
};


/**
 * optional ID parent_node = 12;
 * @return {?proto.ID}
 */
proto.Node.prototype.getParentNode = function() {
  return /** @type{?proto.ID} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ID, 12));
};


/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.Node} returns this
*/
proto.Node.prototype.setParentNode = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.clearParentNode = function() {
  return this.setParentNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Node.prototype.hasParentNode = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated Node child_nodes = 13;
 * @return {!Array<!proto.Node>}
 */
proto.Node.prototype.getChildNodesList = function() {
  return /** @type{!Array<!proto.Node>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Node, 13));
};


/**
 * @param {!Array<!proto.Node>} value
 * @return {!proto.Node} returns this
*/
proto.Node.prototype.setChildNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.Node=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Node}
 */
proto.Node.prototype.addChildNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.Node, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.clearChildNodesList = function() {
  return this.setChildNodesList([]);
};


/**
 * repeated Stream streams = 14;
 * @return {!Array<!proto.Stream>}
 */
proto.Node.prototype.getStreamsList = function() {
  return /** @type{!Array<!proto.Stream>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Stream, 14));
};


/**
 * @param {!Array<!proto.Stream>} value
 * @return {!proto.Node} returns this
*/
proto.Node.prototype.setStreamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.Stream=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Stream}
 */
proto.Node.prototype.addStreams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.Stream, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.clearStreamsList = function() {
  return this.setStreamsList([]);
};


/**
 * optional bool is_cloud_push_eligible = 15;
 * @return {boolean}
 */
proto.Node.prototype.getIsCloudPushEligible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.setIsCloudPushEligible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool is_maintenance_enabled = 16;
 * @return {boolean}
 */
proto.Node.prototype.getIsMaintenanceEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.setIsMaintenanceEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional int64 maintenance_time = 17;
 * @return {number}
 */
proto.Node.prototype.getMaintenanceTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.Node} returns this
 */
proto.Node.prototype.setMaintenanceTime = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Stream.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Stream.prototype.toObject = function(opt_includeInstance) {
  return proto.Stream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Stream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Stream.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && common_API_pb.Uuid.toObject(includeInstance, f),
    tagName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tagDesc: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deviceName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deviceDesc: jspb.Message.getFieldWithDefault(msg, 5, ""),
    deviceUuid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: jspb.Message.getFieldWithDefault(msg, 7, 0),
    parentNode: (f = msg.getParentNode()) && proto.Node.toObject(includeInstance, f),
    parentModel: (f = msg.getParentModel()) && proto.Model.toObject(includeInstance, f),
    metadata: jspb.Message.getFieldWithDefault(msg, 10, ""),
    dataType: (f = msg.getDataType()) && proto.StreamDataType.toObject(includeInstance, f),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.StreamTag.toObject, includeInstance),
    base: (f = msg.getBase()) && common_BaseMessage_pb.BaseMessage.toObject(includeInstance, f),
    isCloudPushEligible: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    alias: jspb.Message.getFieldWithDefault(msg, 15, ""),
    uniqueName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    tagOrigin: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Stream}
 */
proto.Stream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Stream;
  return proto.Stream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Stream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Stream}
 */
proto.Stream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.Uuid;
      reader.readMessage(value,common_API_pb.Uuid.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagDesc(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceDesc(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceUuid(value);
      break;
    case 7:
      var value = /** @type {!proto.TagType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 8:
      var value = new proto.Node;
      reader.readMessage(value,proto.Node.deserializeBinaryFromReader);
      msg.setParentNode(value);
      break;
    case 9:
      var value = new proto.Model;
      reader.readMessage(value,proto.Model.deserializeBinaryFromReader);
      msg.setParentModel(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetadata(value);
      break;
    case 11:
      var value = new proto.StreamDataType;
      reader.readMessage(value,proto.StreamDataType.deserializeBinaryFromReader);
      msg.setDataType(value);
      break;
    case 12:
      var value = new proto.StreamTag;
      reader.readMessage(value,proto.StreamTag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 13:
      var value = new common_BaseMessage_pb.BaseMessage;
      reader.readMessage(value,common_BaseMessage_pb.BaseMessage.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCloudPushEligible(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueName(value);
      break;
    case 17:
      var value = /** @type {!proto.TagOrigin} */ (reader.readEnum());
      msg.setTagOrigin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Stream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Stream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Stream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Stream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getTagName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTagDesc();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeviceName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeviceDesc();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeviceUuid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getParentNode();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.Node.serializeBinaryToWriter
    );
  }
  f = message.getParentModel();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.Model.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDataType();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.StreamDataType.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.StreamTag.serializeBinaryToWriter
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      common_BaseMessage_pb.BaseMessage.serializeBinaryToWriter
    );
  }
  f = message.getIsCloudPushEligible();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUniqueName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTagOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
};


/**
 * optional Uuid id = 1;
 * @return {?proto.Uuid}
 */
proto.Stream.prototype.getId = function() {
  return /** @type{?proto.Uuid} */ (
    jspb.Message.getWrapperField(this, common_API_pb.Uuid, 1));
};


/**
 * @param {?proto.Uuid|undefined} value
 * @return {!proto.Stream} returns this
*/
proto.Stream.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Stream.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string tag_name = 2;
 * @return {string}
 */
proto.Stream.prototype.getTagName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.setTagName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tag_desc = 3;
 * @return {string}
 */
proto.Stream.prototype.getTagDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.setTagDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string device_name = 4;
 * @return {string}
 */
proto.Stream.prototype.getDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.setDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string device_desc = 5;
 * @return {string}
 */
proto.Stream.prototype.getDeviceDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.setDeviceDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string device_uuid = 6;
 * @return {string}
 */
proto.Stream.prototype.getDeviceUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.setDeviceUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional TagType type = 7;
 * @return {!proto.TagType}
 */
proto.Stream.prototype.getType = function() {
  return /** @type {!proto.TagType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.TagType} value
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional Node parent_node = 8;
 * @return {?proto.Node}
 */
proto.Stream.prototype.getParentNode = function() {
  return /** @type{?proto.Node} */ (
    jspb.Message.getWrapperField(this, proto.Node, 8));
};


/**
 * @param {?proto.Node|undefined} value
 * @return {!proto.Stream} returns this
*/
proto.Stream.prototype.setParentNode = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.clearParentNode = function() {
  return this.setParentNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Stream.prototype.hasParentNode = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Model parent_model = 9;
 * @return {?proto.Model}
 */
proto.Stream.prototype.getParentModel = function() {
  return /** @type{?proto.Model} */ (
    jspb.Message.getWrapperField(this, proto.Model, 9));
};


/**
 * @param {?proto.Model|undefined} value
 * @return {!proto.Stream} returns this
*/
proto.Stream.prototype.setParentModel = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.clearParentModel = function() {
  return this.setParentModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Stream.prototype.hasParentModel = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string metadata = 10;
 * @return {string}
 */
proto.Stream.prototype.getMetadata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.setMetadata = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional StreamDataType data_type = 11;
 * @return {?proto.StreamDataType}
 */
proto.Stream.prototype.getDataType = function() {
  return /** @type{?proto.StreamDataType} */ (
    jspb.Message.getWrapperField(this, proto.StreamDataType, 11));
};


/**
 * @param {?proto.StreamDataType|undefined} value
 * @return {!proto.Stream} returns this
*/
proto.Stream.prototype.setDataType = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.clearDataType = function() {
  return this.setDataType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Stream.prototype.hasDataType = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated StreamTag tags = 12;
 * @return {!Array<!proto.StreamTag>}
 */
proto.Stream.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.StreamTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.StreamTag, 12));
};


/**
 * @param {!Array<!proto.StreamTag>} value
 * @return {!proto.Stream} returns this
*/
proto.Stream.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.StreamTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.StreamTag}
 */
proto.Stream.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.StreamTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional BaseMessage base = 13;
 * @return {?proto.BaseMessage}
 */
proto.Stream.prototype.getBase = function() {
  return /** @type{?proto.BaseMessage} */ (
    jspb.Message.getWrapperField(this, common_BaseMessage_pb.BaseMessage, 13));
};


/**
 * @param {?proto.BaseMessage|undefined} value
 * @return {!proto.Stream} returns this
*/
proto.Stream.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Stream.prototype.hasBase = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool is_cloud_push_eligible = 14;
 * @return {boolean}
 */
proto.Stream.prototype.getIsCloudPushEligible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.setIsCloudPushEligible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string alias = 15;
 * @return {string}
 */
proto.Stream.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string unique_name = 16;
 * @return {string}
 */
proto.Stream.prototype.getUniqueName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.setUniqueName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional TagOrigin tag_origin = 17;
 * @return {!proto.TagOrigin}
 */
proto.Stream.prototype.getTagOrigin = function() {
  return /** @type {!proto.TagOrigin} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.TagOrigin} value
 * @return {!proto.Stream} returns this
 */
proto.Stream.prototype.setTagOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StreamDataType.prototype.toObject = function(opt_includeInstance) {
  return proto.StreamDataType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StreamDataType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamDataType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && common_API_pb.ID.toObject(includeInstance, f),
    unit: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    aggregationMethod: jspb.Message.getFieldWithDefault(msg, 4, 0),
    primitiveType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    minRange: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    maxRange: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    rawMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    rawMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    base: (f = msg.getBase()) && common_BaseMessage_pb.BaseMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StreamDataType}
 */
proto.StreamDataType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StreamDataType;
  return proto.StreamDataType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StreamDataType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StreamDataType}
 */
proto.StreamDataType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.ID;
      reader.readMessage(value,common_API_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.AggregationMethod} */ (reader.readEnum());
      msg.setAggregationMethod(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimitiveType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinRange(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxRange(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRawMin(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRawMax(value);
      break;
    case 10:
      var value = new common_BaseMessage_pb.BaseMessage;
      reader.readMessage(value,common_BaseMessage_pb.BaseMessage.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StreamDataType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StreamDataType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StreamDataType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamDataType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAggregationMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPrimitiveType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMinRange();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getMaxRange();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getRawMin();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getRawMax();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_BaseMessage_pb.BaseMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ID id = 1;
 * @return {?proto.ID}
 */
proto.StreamDataType.prototype.getId = function() {
  return /** @type{?proto.ID} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ID, 1));
};


/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.StreamDataType} returns this
*/
proto.StreamDataType.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StreamDataType} returns this
 */
proto.StreamDataType.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StreamDataType.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string unit = 2;
 * @return {string}
 */
proto.StreamDataType.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.StreamDataType} returns this
 */
proto.StreamDataType.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.StreamDataType.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.StreamDataType} returns this
 */
proto.StreamDataType.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AggregationMethod aggregation_method = 4;
 * @return {!proto.AggregationMethod}
 */
proto.StreamDataType.prototype.getAggregationMethod = function() {
  return /** @type {!proto.AggregationMethod} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.AggregationMethod} value
 * @return {!proto.StreamDataType} returns this
 */
proto.StreamDataType.prototype.setAggregationMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string primitive_type = 5;
 * @return {string}
 */
proto.StreamDataType.prototype.getPrimitiveType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.StreamDataType} returns this
 */
proto.StreamDataType.prototype.setPrimitiveType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double min_range = 6;
 * @return {number}
 */
proto.StreamDataType.prototype.getMinRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.StreamDataType} returns this
 */
proto.StreamDataType.prototype.setMinRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double max_range = 7;
 * @return {number}
 */
proto.StreamDataType.prototype.getMaxRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.StreamDataType} returns this
 */
proto.StreamDataType.prototype.setMaxRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double raw_min = 8;
 * @return {number}
 */
proto.StreamDataType.prototype.getRawMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.StreamDataType} returns this
 */
proto.StreamDataType.prototype.setRawMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double raw_max = 9;
 * @return {number}
 */
proto.StreamDataType.prototype.getRawMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.StreamDataType} returns this
 */
proto.StreamDataType.prototype.setRawMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional BaseMessage base = 10;
 * @return {?proto.BaseMessage}
 */
proto.StreamDataType.prototype.getBase = function() {
  return /** @type{?proto.BaseMessage} */ (
    jspb.Message.getWrapperField(this, common_BaseMessage_pb.BaseMessage, 10));
};


/**
 * @param {?proto.BaseMessage|undefined} value
 * @return {!proto.StreamDataType} returns this
*/
proto.StreamDataType.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StreamDataType} returns this
 */
proto.StreamDataType.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StreamDataType.prototype.hasBase = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StreamTag.prototype.toObject = function(opt_includeInstance) {
  return proto.StreamTag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StreamTag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamTag.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && common_API_pb.ID.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    base: (f = msg.getBase()) && common_BaseMessage_pb.BaseMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StreamTag}
 */
proto.StreamTag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StreamTag;
  return proto.StreamTag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StreamTag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StreamTag}
 */
proto.StreamTag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.ID;
      reader.readMessage(value,common_API_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new common_BaseMessage_pb.BaseMessage;
      reader.readMessage(value,common_BaseMessage_pb.BaseMessage.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StreamTag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StreamTag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StreamTag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamTag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_BaseMessage_pb.BaseMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ID id = 1;
 * @return {?proto.ID}
 */
proto.StreamTag.prototype.getId = function() {
  return /** @type{?proto.ID} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ID, 1));
};


/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.StreamTag} returns this
*/
proto.StreamTag.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StreamTag} returns this
 */
proto.StreamTag.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StreamTag.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.StreamTag.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.StreamTag} returns this
 */
proto.StreamTag.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.StreamTag.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.StreamTag} returns this
 */
proto.StreamTag.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional BaseMessage base = 4;
 * @return {?proto.BaseMessage}
 */
proto.StreamTag.prototype.getBase = function() {
  return /** @type{?proto.BaseMessage} */ (
    jspb.Message.getWrapperField(this, common_BaseMessage_pb.BaseMessage, 4));
};


/**
 * @param {?proto.BaseMessage|undefined} value
 * @return {!proto.StreamTag} returns this
*/
proto.StreamTag.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StreamTag} returns this
 */
proto.StreamTag.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StreamTag.prototype.hasBase = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.StreamListFilter.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StreamListFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.StreamListFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StreamListFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamListFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: (f = msg.getModelId()) && common_API_pb.ID.toObject(includeInstance, f),
    nodeIdList: jspb.Message.toObjectList(msg.getNodeIdList(),
    common_API_pb.ID.toObject, includeInstance),
    tagIdList: jspb.Message.toObjectList(msg.getTagIdList(),
    common_API_pb.Uuid.toObject, includeInstance),
    deviceIdList: jspb.Message.toObjectList(msg.getDeviceIdList(),
    common_API_pb.Uuid.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StreamListFilter}
 */
proto.StreamListFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StreamListFilter;
  return proto.StreamListFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StreamListFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StreamListFilter}
 */
proto.StreamListFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.ID;
      reader.readMessage(value,common_API_pb.ID.deserializeBinaryFromReader);
      msg.setModelId(value);
      break;
    case 2:
      var value = new common_API_pb.ID;
      reader.readMessage(value,common_API_pb.ID.deserializeBinaryFromReader);
      msg.addNodeId(value);
      break;
    case 3:
      var value = new common_API_pb.Uuid;
      reader.readMessage(value,common_API_pb.Uuid.deserializeBinaryFromReader);
      msg.addTagId(value);
      break;
    case 4:
      var value = new common_API_pb.Uuid;
      reader.readMessage(value,common_API_pb.Uuid.deserializeBinaryFromReader);
      msg.addDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StreamListFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StreamListFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StreamListFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamListFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getNodeIdList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      common_API_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getTagIdList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      common_API_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getDeviceIdList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      common_API_pb.Uuid.serializeBinaryToWriter
    );
  }
};


/**
 * optional ID model_id = 1;
 * @return {?proto.ID}
 */
proto.StreamListFilter.prototype.getModelId = function() {
  return /** @type{?proto.ID} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ID, 1));
};


/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.StreamListFilter} returns this
*/
proto.StreamListFilter.prototype.setModelId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StreamListFilter} returns this
 */
proto.StreamListFilter.prototype.clearModelId = function() {
  return this.setModelId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StreamListFilter.prototype.hasModelId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ID node_id = 2;
 * @return {!Array<!proto.ID>}
 */
proto.StreamListFilter.prototype.getNodeIdList = function() {
  return /** @type{!Array<!proto.ID>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_API_pb.ID, 2));
};


/**
 * @param {!Array<!proto.ID>} value
 * @return {!proto.StreamListFilter} returns this
*/
proto.StreamListFilter.prototype.setNodeIdList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ID}
 */
proto.StreamListFilter.prototype.addNodeId = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.StreamListFilter} returns this
 */
proto.StreamListFilter.prototype.clearNodeIdList = function() {
  return this.setNodeIdList([]);
};


/**
 * repeated Uuid tag_id = 3;
 * @return {!Array<!proto.Uuid>}
 */
proto.StreamListFilter.prototype.getTagIdList = function() {
  return /** @type{!Array<!proto.Uuid>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_API_pb.Uuid, 3));
};


/**
 * @param {!Array<!proto.Uuid>} value
 * @return {!proto.StreamListFilter} returns this
*/
proto.StreamListFilter.prototype.setTagIdList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Uuid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Uuid}
 */
proto.StreamListFilter.prototype.addTagId = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Uuid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.StreamListFilter} returns this
 */
proto.StreamListFilter.prototype.clearTagIdList = function() {
  return this.setTagIdList([]);
};


/**
 * repeated Uuid device_id = 4;
 * @return {!Array<!proto.Uuid>}
 */
proto.StreamListFilter.prototype.getDeviceIdList = function() {
  return /** @type{!Array<!proto.Uuid>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_API_pb.Uuid, 4));
};


/**
 * @param {!Array<!proto.Uuid>} value
 * @return {!proto.StreamListFilter} returns this
*/
proto.StreamListFilter.prototype.setDeviceIdList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Uuid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Uuid}
 */
proto.StreamListFilter.prototype.addDeviceId = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Uuid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.StreamListFilter} returns this
 */
proto.StreamListFilter.prototype.clearDeviceIdList = function() {
  return this.setDeviceIdList([]);
};


goog.object.extend(exports, proto);
