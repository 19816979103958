// source: service/ProductionOverview.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_Common_pb = require('../common/Common_pb.js');
goog.object.extend(proto, common_Common_pb);
var model_ProductionOverview_pb = require('../model/ProductionOverview_pb.js');
goog.object.extend(proto, model_ProductionOverview_pb);
var model_Filter_pb = require('../model/Filter_pb.js');
goog.object.extend(proto, model_Filter_pb);
var model_AlarmOverview_pb = require('../model/AlarmOverview_pb.js');
goog.object.extend(proto, model_AlarmOverview_pb);
goog.exportSymbol('proto.upl_sandbach.BatchCountResponse', null, global);
goog.exportSymbol('proto.upl_sandbach.BatchDataResponse', null, global);
goog.exportSymbol('proto.upl_sandbach.BatchDetailsResponse', null, global);
goog.exportSymbol('proto.upl_sandbach.BatchFilterRequest', null, global);
goog.exportSymbol('proto.upl_sandbach.BatchesDataFromAFPackRequest', null, global);
goog.exportSymbol('proto.upl_sandbach.LatestDateResponse', null, global);
goog.exportSymbol('proto.upl_sandbach.LineFilterRequest', null, global);
goog.exportSymbol('proto.upl_sandbach.MachineAndBatchRequest', null, global);
goog.exportSymbol('proto.upl_sandbach.MachineAndBatchResponse', null, global);
goog.exportSymbol('proto.upl_sandbach.PPBatchesDetailsResponse', null, global);
goog.exportSymbol('proto.upl_sandbach.PPBatchesFilterRequest', null, global);
goog.exportSymbol('proto.upl_sandbach.PPUpdateBatchRejectCountsRequest', null, global);
goog.exportSymbol('proto.upl_sandbach.PPUpdateBatchRejectCountsResponse', null, global);
goog.exportSymbol('proto.upl_sandbach.PackagingDetailsResponse', null, global);
goog.exportSymbol('proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest', null, global);
goog.exportSymbol('proto.upl_sandbach.ProcessOrderListResponse', null, global);
goog.exportSymbol('proto.upl_sandbach.ProductionDashboardOverviewResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.BatchFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.BatchFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.BatchFilterRequest.displayName = 'proto.upl_sandbach.BatchFilterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.BatchDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.BatchDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.BatchDataResponse.displayName = 'proto.upl_sandbach.BatchDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.BatchCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.BatchCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.BatchCountResponse.displayName = 'proto.upl_sandbach.BatchCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.PackagingDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.PackagingDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.PackagingDetailsResponse.displayName = 'proto.upl_sandbach.PackagingDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.LineFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.LineFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.LineFilterRequest.displayName = 'proto.upl_sandbach.LineFilterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.BatchDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.BatchDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.BatchDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.BatchDetailsResponse.displayName = 'proto.upl_sandbach.BatchDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.PPBatchesFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.PPBatchesFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.PPBatchesFilterRequest.displayName = 'proto.upl_sandbach.PPBatchesFilterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.PPBatchesDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.PPBatchesDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.PPBatchesDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.PPBatchesDetailsResponse.displayName = 'proto.upl_sandbach.PPBatchesDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.PPUpdateBatchRejectCountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.displayName = 'proto.upl_sandbach.PPUpdateBatchRejectCountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.PPUpdateBatchRejectCountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.displayName = 'proto.upl_sandbach.PPUpdateBatchRejectCountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.BatchesDataFromAFPackRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.BatchesDataFromAFPackRequest.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.BatchesDataFromAFPackRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.BatchesDataFromAFPackRequest.displayName = 'proto.upl_sandbach.BatchesDataFromAFPackRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.ProcessOrderListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.ProcessOrderListResponse.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.ProcessOrderListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.ProcessOrderListResponse.displayName = 'proto.upl_sandbach.ProcessOrderListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.displayName = 'proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.LatestDateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.LatestDateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.LatestDateResponse.displayName = 'proto.upl_sandbach.LatestDateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.MachineAndBatchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.MachineAndBatchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.MachineAndBatchRequest.displayName = 'proto.upl_sandbach.MachineAndBatchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.MachineAndBatchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.MachineAndBatchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.MachineAndBatchResponse.displayName = 'proto.upl_sandbach.MachineAndBatchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.ProductionDashboardOverviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.ProductionDashboardOverviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.ProductionDashboardOverviewResponse.displayName = 'proto.upl_sandbach.ProductionDashboardOverviewResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.BatchFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.BatchFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.BatchFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && model_Filter_pb.BatchFilter.toObject(includeInstance, f),
    request: (f = msg.getRequest()) && common_Common_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.BatchFilterRequest}
 */
proto.upl_sandbach.BatchFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.BatchFilterRequest;
  return proto.upl_sandbach.BatchFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.BatchFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.BatchFilterRequest}
 */
proto.upl_sandbach.BatchFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_Filter_pb.BatchFilter;
      reader.readMessage(value,model_Filter_pb.BatchFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralRequest;
      reader.readMessage(value,common_Common_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.BatchFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.BatchFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.BatchFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      model_Filter_pb.BatchFilter.serializeBinaryToWriter
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional BatchFilter filter = 10;
 * @return {?proto.upl_sandbach.BatchFilter}
 */
proto.upl_sandbach.BatchFilterRequest.prototype.getFilter = function() {
  return /** @type{?proto.upl_sandbach.BatchFilter} */ (
    jspb.Message.getWrapperField(this, model_Filter_pb.BatchFilter, 10));
};


/**
 * @param {?proto.upl_sandbach.BatchFilter|undefined} value
 * @return {!proto.upl_sandbach.BatchFilterRequest} returns this
*/
proto.upl_sandbach.BatchFilterRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.BatchFilterRequest} returns this
 */
proto.upl_sandbach.BatchFilterRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.BatchFilterRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralRequest request = 20;
 * @return {?proto.upl_sandbach.GeneralRequest}
 */
proto.upl_sandbach.BatchFilterRequest.prototype.getRequest = function() {
  return /** @type{?proto.upl_sandbach.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralRequest, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralRequest|undefined} value
 * @return {!proto.upl_sandbach.BatchFilterRequest} returns this
*/
proto.upl_sandbach.BatchFilterRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.BatchFilterRequest} returns this
 */
proto.upl_sandbach.BatchFilterRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.BatchFilterRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.BatchDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.BatchDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.BatchDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && model_ProductionOverview_pb.BatchData.toObject(includeInstance, f),
    response: (f = msg.getResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.BatchDataResponse}
 */
proto.upl_sandbach.BatchDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.BatchDataResponse;
  return proto.upl_sandbach.BatchDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.BatchDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.BatchDataResponse}
 */
proto.upl_sandbach.BatchDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_ProductionOverview_pb.BatchData;
      reader.readMessage(value,model_ProductionOverview_pb.BatchData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.BatchDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.BatchDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.BatchDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      model_ProductionOverview_pb.BatchData.serializeBinaryToWriter
    );
  }
  f = message.getResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional BatchData data = 10;
 * @return {?proto.upl_sandbach.BatchData}
 */
proto.upl_sandbach.BatchDataResponse.prototype.getData = function() {
  return /** @type{?proto.upl_sandbach.BatchData} */ (
    jspb.Message.getWrapperField(this, model_ProductionOverview_pb.BatchData, 10));
};


/**
 * @param {?proto.upl_sandbach.BatchData|undefined} value
 * @return {!proto.upl_sandbach.BatchDataResponse} returns this
*/
proto.upl_sandbach.BatchDataResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.BatchDataResponse} returns this
 */
proto.upl_sandbach.BatchDataResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.BatchDataResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralResponse response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.BatchDataResponse.prototype.getResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.BatchDataResponse} returns this
*/
proto.upl_sandbach.BatchDataResponse.prototype.setResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.BatchDataResponse} returns this
 */
proto.upl_sandbach.BatchDataResponse.prototype.clearResponse = function() {
  return this.setResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.BatchDataResponse.prototype.hasResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.BatchCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.BatchCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.BatchCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: (f = msg.getCount()) && model_ProductionOverview_pb.BatchCount.toObject(includeInstance, f),
    response: (f = msg.getResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.BatchCountResponse}
 */
proto.upl_sandbach.BatchCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.BatchCountResponse;
  return proto.upl_sandbach.BatchCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.BatchCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.BatchCountResponse}
 */
proto.upl_sandbach.BatchCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_ProductionOverview_pb.BatchCount;
      reader.readMessage(value,model_ProductionOverview_pb.BatchCount.deserializeBinaryFromReader);
      msg.setCount(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.BatchCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.BatchCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.BatchCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      model_ProductionOverview_pb.BatchCount.serializeBinaryToWriter
    );
  }
  f = message.getResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional BatchCount count = 10;
 * @return {?proto.upl_sandbach.BatchCount}
 */
proto.upl_sandbach.BatchCountResponse.prototype.getCount = function() {
  return /** @type{?proto.upl_sandbach.BatchCount} */ (
    jspb.Message.getWrapperField(this, model_ProductionOverview_pb.BatchCount, 10));
};


/**
 * @param {?proto.upl_sandbach.BatchCount|undefined} value
 * @return {!proto.upl_sandbach.BatchCountResponse} returns this
*/
proto.upl_sandbach.BatchCountResponse.prototype.setCount = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.BatchCountResponse} returns this
 */
proto.upl_sandbach.BatchCountResponse.prototype.clearCount = function() {
  return this.setCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.BatchCountResponse.prototype.hasCount = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralResponse response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.BatchCountResponse.prototype.getResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.BatchCountResponse} returns this
*/
proto.upl_sandbach.BatchCountResponse.prototype.setResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.BatchCountResponse} returns this
 */
proto.upl_sandbach.BatchCountResponse.prototype.clearResponse = function() {
  return this.setResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.BatchCountResponse.prototype.hasResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.PackagingDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.PackagingDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.PackagingDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PackagingDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    details: (f = msg.getDetails()) && model_ProductionOverview_pb.PackagingDetails.toObject(includeInstance, f),
    response: (f = msg.getResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.PackagingDetailsResponse}
 */
proto.upl_sandbach.PackagingDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.PackagingDetailsResponse;
  return proto.upl_sandbach.PackagingDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.PackagingDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.PackagingDetailsResponse}
 */
proto.upl_sandbach.PackagingDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_ProductionOverview_pb.PackagingDetails;
      reader.readMessage(value,model_ProductionOverview_pb.PackagingDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.PackagingDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.PackagingDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.PackagingDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PackagingDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      model_ProductionOverview_pb.PackagingDetails.serializeBinaryToWriter
    );
  }
  f = message.getResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional PackagingDetails details = 10;
 * @return {?proto.upl_sandbach.PackagingDetails}
 */
proto.upl_sandbach.PackagingDetailsResponse.prototype.getDetails = function() {
  return /** @type{?proto.upl_sandbach.PackagingDetails} */ (
    jspb.Message.getWrapperField(this, model_ProductionOverview_pb.PackagingDetails, 10));
};


/**
 * @param {?proto.upl_sandbach.PackagingDetails|undefined} value
 * @return {!proto.upl_sandbach.PackagingDetailsResponse} returns this
*/
proto.upl_sandbach.PackagingDetailsResponse.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.PackagingDetailsResponse} returns this
 */
proto.upl_sandbach.PackagingDetailsResponse.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.PackagingDetailsResponse.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralResponse response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.PackagingDetailsResponse.prototype.getResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.PackagingDetailsResponse} returns this
*/
proto.upl_sandbach.PackagingDetailsResponse.prototype.setResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.PackagingDetailsResponse} returns this
 */
proto.upl_sandbach.PackagingDetailsResponse.prototype.clearResponse = function() {
  return this.setResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.PackagingDetailsResponse.prototype.hasResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.LineFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.LineFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.LineFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.LineFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lineFilter: (f = msg.getLineFilter()) && model_ProductionOverview_pb.LineFilter.toObject(includeInstance, f),
    request: (f = msg.getRequest()) && common_Common_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.LineFilterRequest}
 */
proto.upl_sandbach.LineFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.LineFilterRequest;
  return proto.upl_sandbach.LineFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.LineFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.LineFilterRequest}
 */
proto.upl_sandbach.LineFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_ProductionOverview_pb.LineFilter;
      reader.readMessage(value,model_ProductionOverview_pb.LineFilter.deserializeBinaryFromReader);
      msg.setLineFilter(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralRequest;
      reader.readMessage(value,common_Common_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.LineFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.LineFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.LineFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.LineFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLineFilter();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      model_ProductionOverview_pb.LineFilter.serializeBinaryToWriter
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional LineFilter line_filter = 10;
 * @return {?proto.upl_sandbach.LineFilter}
 */
proto.upl_sandbach.LineFilterRequest.prototype.getLineFilter = function() {
  return /** @type{?proto.upl_sandbach.LineFilter} */ (
    jspb.Message.getWrapperField(this, model_ProductionOverview_pb.LineFilter, 10));
};


/**
 * @param {?proto.upl_sandbach.LineFilter|undefined} value
 * @return {!proto.upl_sandbach.LineFilterRequest} returns this
*/
proto.upl_sandbach.LineFilterRequest.prototype.setLineFilter = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.LineFilterRequest} returns this
 */
proto.upl_sandbach.LineFilterRequest.prototype.clearLineFilter = function() {
  return this.setLineFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.LineFilterRequest.prototype.hasLineFilter = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralRequest request = 20;
 * @return {?proto.upl_sandbach.GeneralRequest}
 */
proto.upl_sandbach.LineFilterRequest.prototype.getRequest = function() {
  return /** @type{?proto.upl_sandbach.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralRequest, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralRequest|undefined} value
 * @return {!proto.upl_sandbach.LineFilterRequest} returns this
*/
proto.upl_sandbach.LineFilterRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.LineFilterRequest} returns this
 */
proto.upl_sandbach.LineFilterRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.LineFilterRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 20) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.BatchDetailsResponse.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.BatchDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.BatchDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.BatchDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchesList: jspb.Message.toObjectList(msg.getBatchesList(),
    model_ProductionOverview_pb.BatchDetails.toObject, includeInstance),
    response: (f = msg.getResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.BatchDetailsResponse}
 */
proto.upl_sandbach.BatchDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.BatchDetailsResponse;
  return proto.upl_sandbach.BatchDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.BatchDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.BatchDetailsResponse}
 */
proto.upl_sandbach.BatchDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_ProductionOverview_pb.BatchDetails;
      reader.readMessage(value,model_ProductionOverview_pb.BatchDetails.deserializeBinaryFromReader);
      msg.addBatches(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.BatchDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.BatchDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.BatchDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      model_ProductionOverview_pb.BatchDetails.serializeBinaryToWriter
    );
  }
  f = message.getResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BatchDetails batches = 10;
 * @return {!Array<!proto.upl_sandbach.BatchDetails>}
 */
proto.upl_sandbach.BatchDetailsResponse.prototype.getBatchesList = function() {
  return /** @type{!Array<!proto.upl_sandbach.BatchDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_ProductionOverview_pb.BatchDetails, 10));
};


/**
 * @param {!Array<!proto.upl_sandbach.BatchDetails>} value
 * @return {!proto.upl_sandbach.BatchDetailsResponse} returns this
*/
proto.upl_sandbach.BatchDetailsResponse.prototype.setBatchesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.upl_sandbach.BatchDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.BatchDetails}
 */
proto.upl_sandbach.BatchDetailsResponse.prototype.addBatches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.upl_sandbach.BatchDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.BatchDetailsResponse} returns this
 */
proto.upl_sandbach.BatchDetailsResponse.prototype.clearBatchesList = function() {
  return this.setBatchesList([]);
};


/**
 * optional GeneralResponse response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.BatchDetailsResponse.prototype.getResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.BatchDetailsResponse} returns this
*/
proto.upl_sandbach.BatchDetailsResponse.prototype.setResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.BatchDetailsResponse} returns this
 */
proto.upl_sandbach.BatchDetailsResponse.prototype.clearResponse = function() {
  return this.setResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.BatchDetailsResponse.prototype.hasResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.PPBatchesFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.PPBatchesFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.PPBatchesFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PPBatchesFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && model_ProductionOverview_pb.PPBatchesFilter.toObject(includeInstance, f),
    request: (f = msg.getRequest()) && common_Common_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.PPBatchesFilterRequest}
 */
proto.upl_sandbach.PPBatchesFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.PPBatchesFilterRequest;
  return proto.upl_sandbach.PPBatchesFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.PPBatchesFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.PPBatchesFilterRequest}
 */
proto.upl_sandbach.PPBatchesFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_ProductionOverview_pb.PPBatchesFilter;
      reader.readMessage(value,model_ProductionOverview_pb.PPBatchesFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralRequest;
      reader.readMessage(value,common_Common_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.PPBatchesFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.PPBatchesFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.PPBatchesFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PPBatchesFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      model_ProductionOverview_pb.PPBatchesFilter.serializeBinaryToWriter
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional PPBatchesFilter filter = 10;
 * @return {?proto.upl_sandbach.PPBatchesFilter}
 */
proto.upl_sandbach.PPBatchesFilterRequest.prototype.getFilter = function() {
  return /** @type{?proto.upl_sandbach.PPBatchesFilter} */ (
    jspb.Message.getWrapperField(this, model_ProductionOverview_pb.PPBatchesFilter, 10));
};


/**
 * @param {?proto.upl_sandbach.PPBatchesFilter|undefined} value
 * @return {!proto.upl_sandbach.PPBatchesFilterRequest} returns this
*/
proto.upl_sandbach.PPBatchesFilterRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.PPBatchesFilterRequest} returns this
 */
proto.upl_sandbach.PPBatchesFilterRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.PPBatchesFilterRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralRequest request = 20;
 * @return {?proto.upl_sandbach.GeneralRequest}
 */
proto.upl_sandbach.PPBatchesFilterRequest.prototype.getRequest = function() {
  return /** @type{?proto.upl_sandbach.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralRequest, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralRequest|undefined} value
 * @return {!proto.upl_sandbach.PPBatchesFilterRequest} returns this
*/
proto.upl_sandbach.PPBatchesFilterRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.PPBatchesFilterRequest} returns this
 */
proto.upl_sandbach.PPBatchesFilterRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.PPBatchesFilterRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 20) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.PPBatchesDetailsResponse.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.PPBatchesDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.PPBatchesDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.PPBatchesDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PPBatchesDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchesList: jspb.Message.toObjectList(msg.getBatchesList(),
    model_ProductionOverview_pb.PPBatchesDetails.toObject, includeInstance),
    response: (f = msg.getResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.PPBatchesDetailsResponse}
 */
proto.upl_sandbach.PPBatchesDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.PPBatchesDetailsResponse;
  return proto.upl_sandbach.PPBatchesDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.PPBatchesDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.PPBatchesDetailsResponse}
 */
proto.upl_sandbach.PPBatchesDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_ProductionOverview_pb.PPBatchesDetails;
      reader.readMessage(value,model_ProductionOverview_pb.PPBatchesDetails.deserializeBinaryFromReader);
      msg.addBatches(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.PPBatchesDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.PPBatchesDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.PPBatchesDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PPBatchesDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      model_ProductionOverview_pb.PPBatchesDetails.serializeBinaryToWriter
    );
  }
  f = message.getResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PPBatchesDetails batches = 10;
 * @return {!Array<!proto.upl_sandbach.PPBatchesDetails>}
 */
proto.upl_sandbach.PPBatchesDetailsResponse.prototype.getBatchesList = function() {
  return /** @type{!Array<!proto.upl_sandbach.PPBatchesDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_ProductionOverview_pb.PPBatchesDetails, 10));
};


/**
 * @param {!Array<!proto.upl_sandbach.PPBatchesDetails>} value
 * @return {!proto.upl_sandbach.PPBatchesDetailsResponse} returns this
*/
proto.upl_sandbach.PPBatchesDetailsResponse.prototype.setBatchesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.upl_sandbach.PPBatchesDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.PPBatchesDetails}
 */
proto.upl_sandbach.PPBatchesDetailsResponse.prototype.addBatches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.upl_sandbach.PPBatchesDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.PPBatchesDetailsResponse} returns this
 */
proto.upl_sandbach.PPBatchesDetailsResponse.prototype.clearBatchesList = function() {
  return this.setBatchesList([]);
};


/**
 * optional GeneralResponse response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.PPBatchesDetailsResponse.prototype.getResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.PPBatchesDetailsResponse} returns this
*/
proto.upl_sandbach.PPBatchesDetailsResponse.prototype.setResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.PPBatchesDetailsResponse} returns this
 */
proto.upl_sandbach.PPBatchesDetailsResponse.prototype.clearResponse = function() {
  return this.setResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.PPBatchesDetailsResponse.prototype.hasResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.PPUpdateBatchRejectCountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchFilter: (f = msg.getBatchFilter()) && model_ProductionOverview_pb.PPUpdateBatchRejectCounts.toObject(includeInstance, f),
    request: (f = msg.getRequest()) && common_Common_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCountsRequest}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.PPUpdateBatchRejectCountsRequest;
  return proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.PPUpdateBatchRejectCountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCountsRequest}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_ProductionOverview_pb.PPUpdateBatchRejectCounts;
      reader.readMessage(value,model_ProductionOverview_pb.PPUpdateBatchRejectCounts.deserializeBinaryFromReader);
      msg.setBatchFilter(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralRequest;
      reader.readMessage(value,common_Common_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.PPUpdateBatchRejectCountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchFilter();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      model_ProductionOverview_pb.PPUpdateBatchRejectCounts.serializeBinaryToWriter
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional PPUpdateBatchRejectCounts batch_filter = 10;
 * @return {?proto.upl_sandbach.PPUpdateBatchRejectCounts}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.prototype.getBatchFilter = function() {
  return /** @type{?proto.upl_sandbach.PPUpdateBatchRejectCounts} */ (
    jspb.Message.getWrapperField(this, model_ProductionOverview_pb.PPUpdateBatchRejectCounts, 10));
};


/**
 * @param {?proto.upl_sandbach.PPUpdateBatchRejectCounts|undefined} value
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCountsRequest} returns this
*/
proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.prototype.setBatchFilter = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCountsRequest} returns this
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.prototype.clearBatchFilter = function() {
  return this.setBatchFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.prototype.hasBatchFilter = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralRequest request = 20;
 * @return {?proto.upl_sandbach.GeneralRequest}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.prototype.getRequest = function() {
  return /** @type{?proto.upl_sandbach.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralRequest, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralRequest|undefined} value
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCountsRequest} returns this
*/
proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCountsRequest} returns this
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.PPUpdateBatchRejectCountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    batch: (f = msg.getBatch()) && model_ProductionOverview_pb.PPBatchesDetails.toObject(includeInstance, f),
    response: (f = msg.getResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCountsResponse}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.PPUpdateBatchRejectCountsResponse;
  return proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.PPUpdateBatchRejectCountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCountsResponse}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_ProductionOverview_pb.PPBatchesDetails;
      reader.readMessage(value,model_ProductionOverview_pb.PPBatchesDetails.deserializeBinaryFromReader);
      msg.setBatch(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.PPUpdateBatchRejectCountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatch();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      model_ProductionOverview_pb.PPBatchesDetails.serializeBinaryToWriter
    );
  }
  f = message.getResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional PPBatchesDetails batch = 10;
 * @return {?proto.upl_sandbach.PPBatchesDetails}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.prototype.getBatch = function() {
  return /** @type{?proto.upl_sandbach.PPBatchesDetails} */ (
    jspb.Message.getWrapperField(this, model_ProductionOverview_pb.PPBatchesDetails, 10));
};


/**
 * @param {?proto.upl_sandbach.PPBatchesDetails|undefined} value
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCountsResponse} returns this
*/
proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.prototype.setBatch = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCountsResponse} returns this
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.prototype.clearBatch = function() {
  return this.setBatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.prototype.hasBatch = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralResponse response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.prototype.getResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCountsResponse} returns this
*/
proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.prototype.setResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCountsResponse} returns this
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.prototype.clearResponse = function() {
  return this.setResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.prototype.hasResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.BatchesDataFromAFPackRequest.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.BatchesDataFromAFPackRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.BatchesDataFromAFPackRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.BatchesDataFromAFPackRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchesDataFromAFPackRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchesList: jspb.Message.toObjectList(msg.getBatchesList(),
    model_ProductionOverview_pb.BatchesDataFromAFPack.toObject, includeInstance),
    request: (f = msg.getRequest()) && common_Common_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.BatchesDataFromAFPackRequest}
 */
proto.upl_sandbach.BatchesDataFromAFPackRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.BatchesDataFromAFPackRequest;
  return proto.upl_sandbach.BatchesDataFromAFPackRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.BatchesDataFromAFPackRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.BatchesDataFromAFPackRequest}
 */
proto.upl_sandbach.BatchesDataFromAFPackRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_ProductionOverview_pb.BatchesDataFromAFPack;
      reader.readMessage(value,model_ProductionOverview_pb.BatchesDataFromAFPack.deserializeBinaryFromReader);
      msg.addBatches(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralRequest;
      reader.readMessage(value,common_Common_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.BatchesDataFromAFPackRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.BatchesDataFromAFPackRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.BatchesDataFromAFPackRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchesDataFromAFPackRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      model_ProductionOverview_pb.BatchesDataFromAFPack.serializeBinaryToWriter
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BatchesDataFromAFPack batches = 10;
 * @return {!Array<!proto.upl_sandbach.BatchesDataFromAFPack>}
 */
proto.upl_sandbach.BatchesDataFromAFPackRequest.prototype.getBatchesList = function() {
  return /** @type{!Array<!proto.upl_sandbach.BatchesDataFromAFPack>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_ProductionOverview_pb.BatchesDataFromAFPack, 10));
};


/**
 * @param {!Array<!proto.upl_sandbach.BatchesDataFromAFPack>} value
 * @return {!proto.upl_sandbach.BatchesDataFromAFPackRequest} returns this
*/
proto.upl_sandbach.BatchesDataFromAFPackRequest.prototype.setBatchesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.upl_sandbach.BatchesDataFromAFPack=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.BatchesDataFromAFPack}
 */
proto.upl_sandbach.BatchesDataFromAFPackRequest.prototype.addBatches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.upl_sandbach.BatchesDataFromAFPack, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.BatchesDataFromAFPackRequest} returns this
 */
proto.upl_sandbach.BatchesDataFromAFPackRequest.prototype.clearBatchesList = function() {
  return this.setBatchesList([]);
};


/**
 * optional GeneralRequest request = 20;
 * @return {?proto.upl_sandbach.GeneralRequest}
 */
proto.upl_sandbach.BatchesDataFromAFPackRequest.prototype.getRequest = function() {
  return /** @type{?proto.upl_sandbach.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralRequest, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralRequest|undefined} value
 * @return {!proto.upl_sandbach.BatchesDataFromAFPackRequest} returns this
*/
proto.upl_sandbach.BatchesDataFromAFPackRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.BatchesDataFromAFPackRequest} returns this
 */
proto.upl_sandbach.BatchesDataFromAFPackRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.BatchesDataFromAFPackRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 20) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.ProcessOrderListResponse.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.ProcessOrderListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.ProcessOrderListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.ProcessOrderListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ProcessOrderListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processOrdersList: jspb.Message.toObjectList(msg.getProcessOrdersList(),
    model_ProductionOverview_pb.ProcessOrder.toObject, includeInstance),
    response: (f = msg.getResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.ProcessOrderListResponse}
 */
proto.upl_sandbach.ProcessOrderListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.ProcessOrderListResponse;
  return proto.upl_sandbach.ProcessOrderListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.ProcessOrderListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.ProcessOrderListResponse}
 */
proto.upl_sandbach.ProcessOrderListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_ProductionOverview_pb.ProcessOrder;
      reader.readMessage(value,model_ProductionOverview_pb.ProcessOrder.deserializeBinaryFromReader);
      msg.addProcessOrders(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.ProcessOrderListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.ProcessOrderListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.ProcessOrderListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ProcessOrderListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      model_ProductionOverview_pb.ProcessOrder.serializeBinaryToWriter
    );
  }
  f = message.getResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProcessOrder process_orders = 10;
 * @return {!Array<!proto.upl_sandbach.ProcessOrder>}
 */
proto.upl_sandbach.ProcessOrderListResponse.prototype.getProcessOrdersList = function() {
  return /** @type{!Array<!proto.upl_sandbach.ProcessOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_ProductionOverview_pb.ProcessOrder, 10));
};


/**
 * @param {!Array<!proto.upl_sandbach.ProcessOrder>} value
 * @return {!proto.upl_sandbach.ProcessOrderListResponse} returns this
*/
proto.upl_sandbach.ProcessOrderListResponse.prototype.setProcessOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.upl_sandbach.ProcessOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.ProcessOrder}
 */
proto.upl_sandbach.ProcessOrderListResponse.prototype.addProcessOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.upl_sandbach.ProcessOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.ProcessOrderListResponse} returns this
 */
proto.upl_sandbach.ProcessOrderListResponse.prototype.clearProcessOrdersList = function() {
  return this.setProcessOrdersList([]);
};


/**
 * optional GeneralResponse response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.ProcessOrderListResponse.prototype.getResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.ProcessOrderListResponse} returns this
*/
proto.upl_sandbach.ProcessOrderListResponse.prototype.setResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.ProcessOrderListResponse} returns this
 */
proto.upl_sandbach.ProcessOrderListResponse.prototype.clearResponse = function() {
  return this.setResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.ProcessOrderListResponse.prototype.hasResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    processOrderEndTimeDetailsList: jspb.Message.toObjectList(msg.getProcessOrderEndTimeDetailsList(),
    model_ProductionOverview_pb.ProcessOrderEndTimeDetails.toObject, includeInstance),
    request: (f = msg.getRequest()) && common_Common_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest}
 */
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest;
  return proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest}
 */
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_ProductionOverview_pb.ProcessOrderEndTimeDetails;
      reader.readMessage(value,model_ProductionOverview_pb.ProcessOrderEndTimeDetails.deserializeBinaryFromReader);
      msg.addProcessOrderEndTimeDetails(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralRequest;
      reader.readMessage(value,common_Common_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessOrderEndTimeDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      model_ProductionOverview_pb.ProcessOrderEndTimeDetails.serializeBinaryToWriter
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProcessOrderEndTimeDetails process_order_end_time_details = 10;
 * @return {!Array<!proto.upl_sandbach.ProcessOrderEndTimeDetails>}
 */
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.prototype.getProcessOrderEndTimeDetailsList = function() {
  return /** @type{!Array<!proto.upl_sandbach.ProcessOrderEndTimeDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_ProductionOverview_pb.ProcessOrderEndTimeDetails, 10));
};


/**
 * @param {!Array<!proto.upl_sandbach.ProcessOrderEndTimeDetails>} value
 * @return {!proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest} returns this
*/
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.prototype.setProcessOrderEndTimeDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.upl_sandbach.ProcessOrderEndTimeDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.ProcessOrderEndTimeDetails}
 */
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.prototype.addProcessOrderEndTimeDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.upl_sandbach.ProcessOrderEndTimeDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest} returns this
 */
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.prototype.clearProcessOrderEndTimeDetailsList = function() {
  return this.setProcessOrderEndTimeDetailsList([]);
};


/**
 * optional GeneralRequest request = 20;
 * @return {?proto.upl_sandbach.GeneralRequest}
 */
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.prototype.getRequest = function() {
  return /** @type{?proto.upl_sandbach.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralRequest, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralRequest|undefined} value
 * @return {!proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest} returns this
*/
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest} returns this
 */
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.LatestDateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.LatestDateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.LatestDateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.LatestDateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: jspb.Message.getFieldWithDefault(msg, 10, 0),
    response: (f = msg.getResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.LatestDateResponse}
 */
proto.upl_sandbach.LatestDateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.LatestDateResponse;
  return proto.upl_sandbach.LatestDateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.LatestDateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.LatestDateResponse}
 */
proto.upl_sandbach.LatestDateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.LatestDateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.LatestDateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.LatestDateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.LatestDateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 time = 10;
 * @return {number}
 */
proto.upl_sandbach.LatestDateResponse.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.LatestDateResponse} returns this
 */
proto.upl_sandbach.LatestDateResponse.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional GeneralResponse response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.LatestDateResponse.prototype.getResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.LatestDateResponse} returns this
*/
proto.upl_sandbach.LatestDateResponse.prototype.setResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.LatestDateResponse} returns this
 */
proto.upl_sandbach.LatestDateResponse.prototype.clearResponse = function() {
  return this.setResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.LatestDateResponse.prototype.hasResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.MachineAndBatchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.MachineAndBatchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.MachineAndBatchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.MachineAndBatchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    productionDashboardFilter: (f = msg.getProductionDashboardFilter()) && model_ProductionOverview_pb.ProductionDashboardFilter.toObject(includeInstance, f),
    generalRequest: (f = msg.getGeneralRequest()) && common_Common_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.MachineAndBatchRequest}
 */
proto.upl_sandbach.MachineAndBatchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.MachineAndBatchRequest;
  return proto.upl_sandbach.MachineAndBatchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.MachineAndBatchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.MachineAndBatchRequest}
 */
proto.upl_sandbach.MachineAndBatchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_ProductionOverview_pb.ProductionDashboardFilter;
      reader.readMessage(value,model_ProductionOverview_pb.ProductionDashboardFilter.deserializeBinaryFromReader);
      msg.setProductionDashboardFilter(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralRequest;
      reader.readMessage(value,common_Common_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.MachineAndBatchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.MachineAndBatchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.MachineAndBatchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.MachineAndBatchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductionDashboardFilter();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      model_ProductionOverview_pb.ProductionDashboardFilter.serializeBinaryToWriter
    );
  }
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProductionDashboardFilter production_dashboard_filter = 10;
 * @return {?proto.upl_sandbach.ProductionDashboardFilter}
 */
proto.upl_sandbach.MachineAndBatchRequest.prototype.getProductionDashboardFilter = function() {
  return /** @type{?proto.upl_sandbach.ProductionDashboardFilter} */ (
    jspb.Message.getWrapperField(this, model_ProductionOverview_pb.ProductionDashboardFilter, 10));
};


/**
 * @param {?proto.upl_sandbach.ProductionDashboardFilter|undefined} value
 * @return {!proto.upl_sandbach.MachineAndBatchRequest} returns this
*/
proto.upl_sandbach.MachineAndBatchRequest.prototype.setProductionDashboardFilter = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.MachineAndBatchRequest} returns this
 */
proto.upl_sandbach.MachineAndBatchRequest.prototype.clearProductionDashboardFilter = function() {
  return this.setProductionDashboardFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.MachineAndBatchRequest.prototype.hasProductionDashboardFilter = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralRequest general_request = 20;
 * @return {?proto.upl_sandbach.GeneralRequest}
 */
proto.upl_sandbach.MachineAndBatchRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.upl_sandbach.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralRequest, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralRequest|undefined} value
 * @return {!proto.upl_sandbach.MachineAndBatchRequest} returns this
*/
proto.upl_sandbach.MachineAndBatchRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.MachineAndBatchRequest} returns this
 */
proto.upl_sandbach.MachineAndBatchRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.MachineAndBatchRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.MachineAndBatchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.MachineAndBatchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.MachineAndBatchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.MachineAndBatchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineAndBatchInfo: (f = msg.getMachineAndBatchInfo()) && model_ProductionOverview_pb.MachineAndBatchInfo.toObject(includeInstance, f),
    generalResponse: (f = msg.getGeneralResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.MachineAndBatchResponse}
 */
proto.upl_sandbach.MachineAndBatchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.MachineAndBatchResponse;
  return proto.upl_sandbach.MachineAndBatchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.MachineAndBatchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.MachineAndBatchResponse}
 */
proto.upl_sandbach.MachineAndBatchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_ProductionOverview_pb.MachineAndBatchInfo;
      reader.readMessage(value,model_ProductionOverview_pb.MachineAndBatchInfo.deserializeBinaryFromReader);
      msg.setMachineAndBatchInfo(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.MachineAndBatchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.MachineAndBatchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.MachineAndBatchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.MachineAndBatchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineAndBatchInfo();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      model_ProductionOverview_pb.MachineAndBatchInfo.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional MachineAndBatchInfo machine_and_batch_info = 10;
 * @return {?proto.upl_sandbach.MachineAndBatchInfo}
 */
proto.upl_sandbach.MachineAndBatchResponse.prototype.getMachineAndBatchInfo = function() {
  return /** @type{?proto.upl_sandbach.MachineAndBatchInfo} */ (
    jspb.Message.getWrapperField(this, model_ProductionOverview_pb.MachineAndBatchInfo, 10));
};


/**
 * @param {?proto.upl_sandbach.MachineAndBatchInfo|undefined} value
 * @return {!proto.upl_sandbach.MachineAndBatchResponse} returns this
*/
proto.upl_sandbach.MachineAndBatchResponse.prototype.setMachineAndBatchInfo = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.MachineAndBatchResponse} returns this
 */
proto.upl_sandbach.MachineAndBatchResponse.prototype.clearMachineAndBatchInfo = function() {
  return this.setMachineAndBatchInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.MachineAndBatchResponse.prototype.hasMachineAndBatchInfo = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralResponse general_response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.MachineAndBatchResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.MachineAndBatchResponse} returns this
*/
proto.upl_sandbach.MachineAndBatchResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.MachineAndBatchResponse} returns this
 */
proto.upl_sandbach.MachineAndBatchResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.MachineAndBatchResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.ProductionDashboardOverviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.ProductionDashboardOverviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.ProductionDashboardOverviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ProductionDashboardOverviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    productionDashboardOverview: (f = msg.getProductionDashboardOverview()) && model_ProductionOverview_pb.ProductionDashboardOverview.toObject(includeInstance, f),
    generalResponse: (f = msg.getGeneralResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.ProductionDashboardOverviewResponse}
 */
proto.upl_sandbach.ProductionDashboardOverviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.ProductionDashboardOverviewResponse;
  return proto.upl_sandbach.ProductionDashboardOverviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.ProductionDashboardOverviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.ProductionDashboardOverviewResponse}
 */
proto.upl_sandbach.ProductionDashboardOverviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_ProductionOverview_pb.ProductionDashboardOverview;
      reader.readMessage(value,model_ProductionOverview_pb.ProductionDashboardOverview.deserializeBinaryFromReader);
      msg.setProductionDashboardOverview(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.ProductionDashboardOverviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.ProductionDashboardOverviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.ProductionDashboardOverviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ProductionDashboardOverviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductionDashboardOverview();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      model_ProductionOverview_pb.ProductionDashboardOverview.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProductionDashboardOverview production_dashboard_overview = 10;
 * @return {?proto.upl_sandbach.ProductionDashboardOverview}
 */
proto.upl_sandbach.ProductionDashboardOverviewResponse.prototype.getProductionDashboardOverview = function() {
  return /** @type{?proto.upl_sandbach.ProductionDashboardOverview} */ (
    jspb.Message.getWrapperField(this, model_ProductionOverview_pb.ProductionDashboardOverview, 10));
};


/**
 * @param {?proto.upl_sandbach.ProductionDashboardOverview|undefined} value
 * @return {!proto.upl_sandbach.ProductionDashboardOverviewResponse} returns this
*/
proto.upl_sandbach.ProductionDashboardOverviewResponse.prototype.setProductionDashboardOverview = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.ProductionDashboardOverviewResponse} returns this
 */
proto.upl_sandbach.ProductionDashboardOverviewResponse.prototype.clearProductionDashboardOverview = function() {
  return this.setProductionDashboardOverview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.ProductionDashboardOverviewResponse.prototype.hasProductionDashboardOverview = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralResponse general_response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.ProductionDashboardOverviewResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.ProductionDashboardOverviewResponse} returns this
*/
proto.upl_sandbach.ProductionDashboardOverviewResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.ProductionDashboardOverviewResponse} returns this
 */
proto.upl_sandbach.ProductionDashboardOverviewResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.ProductionDashboardOverviewResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};


goog.object.extend(exports, proto.upl_sandbach);
