import moment from "moment";
import { useEffect, useState } from "react";

const useTime = (): String => {
  const currentDate = new Date().getTime();

  const [time, setCurrentTime] = useState(new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [currentDate]);

  return moment().format("ddd D MMM  h:mm a");
};

export { useTime };
