import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export const RateMatrix = ({ data }) => {
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdivRateMatrix");
    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      })
    );
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        layout: root.gridLayout,
      })
    );
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 10,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
    });
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "machineName",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xAxis.data.setAll(data);
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        numberFormat: "#",
      })
    );
    function makeSeries(name: string, fieldName: string) {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "machineName",
        })
      );

      series.columns.template.setAll({
        tooltipText: "{name}, {categoryX}: {valueY} PPM",
        width: am5.percent(50),
        tooltipY: 0,
      });

      series.data.setAll(data);

      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    }
    makeSeries("Total speed", "totalSpeedPpm");
    makeSeries("Reject speed", "rejectSpeedPpm");
    makeSeries("Good speed", "goodSpeedPpm");

    chart.appear(1000, 100);

    root?._logo?.dispose();

    return () => {
      root.dispose();
    };
  }, [data]);

  return (
    <>
      <div
        id="chartdivRateMatrix"
        style={{ width: "100%", height: "100%" }}
      ></div>
    </>
  );
};
