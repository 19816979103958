import { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import * as am5xy from "@amcharts/amcharts5/xy";
import { HorizontalBarChartDataProps } from "types";

const HorizontalBar: React.FC<HorizontalBarChartDataProps> = ({
  chartID,
  data,
  numberFormat = "",
  strictMinMax = false,
}) => {
  useLayoutEffect(() => {
    var root = am5.Root.new(chartID);

    root.setThemes([am5themes_Animated.new(root)]);
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
      })
    );

    var yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 30,
    });

    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,

        categoryField: "label",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, { themeTags: ["axis"] }),
      })
    );

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        numberFormat: numberFormat,
        min: 0,
        strictMinMax: strictMinMax,
        extraMax: 0.1,
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );

    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "value",
        categoryYField: "label",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueX}",
        }),
      })
    );

    series.columns.template.setAll({
      cornerRadiusTR: 5,
      cornerRadiusBR: 5,
    });

    series.columns.template.adapters.add("fill", function (fill, target) {
      return chart.get("colors")?.getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add("stroke", function (stroke, target) {
      return chart.get("colors")?.getIndex(series.columns.indexOf(target));
    });

    yAxis.data.setAll(data);
    series.data.setAll(data);

    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
        xAxis: xAxis,
        yAxis: yAxis,
      })
    );

    series.appear();
    chart.appear(1000, 100);
    root?._logo?.dispose();

    return () => root.dispose();
  }, [chartID, data]);

  return <div style={{ width: "100%", height: "100%" }} id={chartID}></div>;
};
export default HorizontalBar;
