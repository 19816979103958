import ContentHeader from "components/ContentHeader";
import AlarmDashboard from "./AlarmDashboard";
import { AlarmDetails } from "./AlarmDetails";
import { Tabs } from "antd";
import { useDispatch } from "react-redux";
import { setFilterUpdatedFlag } from "store/actions/rootAction";

export const AlarmEvents: React.FC = () => {
  const dispatch = useDispatch();

  const items = [
    {
      label: `Dashboard`,
      key: "1",
      children: <AlarmDashboard />,
    },
    {
      label: `Details`,
      key: "2",
      children: <AlarmDetails />,
    },
  ];

  return (
    <div className="tabs-wrapper-container">
      <ContentHeader heading="Alarm & Events" />
      <Tabs defaultActiveKey="1" onChange={(activeKey:any)=>dispatch(setFilterUpdatedFlag())}>
        {items.map((item, index) => {
          return (
            <Tabs.TabPane tab={item.label} key={item.key}>
              {item.children}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};
