import './index.scss';
import braboImage from "assets/images/brabo.png";
import braboWelcomeImage from "assets/images/brabo-welcome.png";
import ContentHeader from 'components/ContentHeader';

const Dashboard = () => {
  return (
    <>
    <ContentHeader heading="Dashboard"  />
    <div className="dashboard-container">
      <div className="text-container">
        <div className="title">
          <img src={braboImage} alt="brabo" />
          <p className='title-text'>Asset Studio</p>
        </div>
        <p className='digital-factory-text'>Digital Factory Suite for UPL </p>
        <img
          className="center-image"
          src={braboWelcomeImage}
          alt="brabo"
        />
        <p className='main-menu-text'>Use the Main Menu to get started​</p>
      </div>
    </div>
    </>
  )
};

export default Dashboard;
