// source: service/ReasonCodeDashboardService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_Commons_pb = require('../common/Commons_pb.js');
goog.object.extend(proto, common_Commons_pb);
var enums_ReasonCodeEnum_pb = require('../enums/ReasonCodeEnum_pb.js');
goog.object.extend(proto, enums_ReasonCodeEnum_pb);
var model_ReasonCodeModel_pb = require('../model/ReasonCodeModel_pb.js');
goog.object.extend(proto, model_ReasonCodeModel_pb);
var model_ReasonCodeOperatorModel_pb = require('../model/ReasonCodeOperatorModel_pb.js');
goog.object.extend(proto, model_ReasonCodeOperatorModel_pb);
goog.exportSymbol('proto.proto.AssetUtilizationResponse', null, global);
goog.exportSymbol('proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo', null, global);
goog.exportSymbol('proto.proto.AvgCycleTimeResponse', null, global);
goog.exportSymbol('proto.proto.DowntimeDetailsResponse', null, global);
goog.exportSymbol('proto.proto.DowntimeReasonCodeDetailsResponse', null, global);
goog.exportSymbol('proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime', null, global);
goog.exportSymbol('proto.proto.DowntimeTrendResponse', null, global);
goog.exportSymbol('proto.proto.DowntimeValuesResponse', null, global);
goog.exportSymbol('proto.proto.LineOverviewRequest', null, global);
goog.exportSymbol('proto.proto.LossTimeAnalysisResponse', null, global);
goog.exportSymbol('proto.proto.LostTimeMatrixResponse', null, global);
goog.exportSymbol('proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix', null, global);
goog.exportSymbol('proto.proto.MachineReasonDurationCountResponse', null, global);
goog.exportSymbol('proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration', null, global);
goog.exportSymbol('proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount', null, global);
goog.exportSymbol('proto.proto.MachineRuntimeStatusResponse', null, global);
goog.exportSymbol('proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus', null, global);
goog.exportSymbol('proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration', null, global);
goog.exportSymbol('proto.proto.MtbfResponse', null, global);
goog.exportSymbol('proto.proto.MtbfResponse.TimeMtbf', null, global);
goog.exportSymbol('proto.proto.MttrResponse', null, global);
goog.exportSymbol('proto.proto.MttrResponse.TimeMttr', null, global);
goog.exportSymbol('proto.proto.NodeTimestampSetPair', null, global);
goog.exportSymbol('proto.proto.OEEAnalysisRequest', null, global);
goog.exportSymbol('proto.proto.OEEAvailResponse', null, global);
goog.exportSymbol('proto.proto.OEEByLineResponse', null, global);
goog.exportSymbol('proto.proto.OEEByLineResponse.LineOee', null, global);
goog.exportSymbol('proto.proto.OEEBySkuResponse', null, global);
goog.exportSymbol('proto.proto.OEEBySkuResponse.SKUOee', null, global);
goog.exportSymbol('proto.proto.OEEMachineWiseResponse', null, global);
goog.exportSymbol('proto.proto.OEEMachineWiseResponse.OEEMachine', null, global);
goog.exportSymbol('proto.proto.OEEParamResponse', null, global);
goog.exportSymbol('proto.proto.OEEPerfResponse', null, global);
goog.exportSymbol('proto.proto.OEEQualityResponse', null, global);
goog.exportSymbol('proto.proto.OverallAssetUtilization', null, global);
goog.exportSymbol('proto.proto.PercentageLossResponse', null, global);
goog.exportSymbol('proto.proto.PlannedStoptimealuesResponse', null, global);
goog.exportSymbol('proto.proto.ProductionOverviewRequest', null, global);
goog.exportSymbol('proto.proto.ProductionQualityResponse', null, global);
goog.exportSymbol('proto.proto.ProductionRateResponse', null, global);
goog.exportSymbol('proto.proto.ProductiveTimeResponse', null, global);
goog.exportSymbol('proto.proto.RateMatrixResponse', null, global);
goog.exportSymbol('proto.proto.RuntimeAnalysisDetailsResponse', null, global);
goog.exportSymbol('proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails', null, global);
goog.exportSymbol('proto.proto.RuntimeDowntimeResponse', null, global);
goog.exportSymbol('proto.proto.RuntimeOverviewRequest', null, global);
goog.exportSymbol('proto.proto.RuntimeValuesResponse', null, global);
goog.exportSymbol('proto.proto.TeepMatrixResponse', null, global);
goog.exportSymbol('proto.proto.TimestampSet', null, global);
goog.exportSymbol('proto.proto.TotalCountRequest', null, global);
goog.exportSymbol('proto.proto.TotalCountResponse', null, global);
goog.exportSymbol('proto.proto.TotalCountResponse.CountInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LineOverviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.LineOverviewRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.LineOverviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LineOverviewRequest.displayName = 'proto.proto.LineOverviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DowntimeTrendResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DowntimeTrendResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DowntimeTrendResponse.displayName = 'proto.proto.DowntimeTrendResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.OEEParamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.OEEParamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.OEEParamResponse.displayName = 'proto.proto.OEEParamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LostTimeMatrixResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.LostTimeMatrixResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.LostTimeMatrixResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LostTimeMatrixResponse.displayName = 'proto.proto.LostTimeMatrixResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.displayName = 'proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.TeepMatrixResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.TeepMatrixResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.TeepMatrixResponse.displayName = 'proto.proto.TeepMatrixResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RuntimeDowntimeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.RuntimeDowntimeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RuntimeDowntimeResponse.displayName = 'proto.proto.RuntimeDowntimeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RateMatrixResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.RateMatrixResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.RateMatrixResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RateMatrixResponse.displayName = 'proto.proto.RateMatrixResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RuntimeOverviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.RuntimeOverviewRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.RuntimeOverviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RuntimeOverviewRequest.displayName = 'proto.proto.RuntimeOverviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RuntimeValuesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.RuntimeValuesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RuntimeValuesResponse.displayName = 'proto.proto.RuntimeValuesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DowntimeValuesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DowntimeValuesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DowntimeValuesResponse.displayName = 'proto.proto.DowntimeValuesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PlannedStoptimealuesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PlannedStoptimealuesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PlannedStoptimealuesResponse.displayName = 'proto.proto.PlannedStoptimealuesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AssetUtilizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AssetUtilizationResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.AssetUtilizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AssetUtilizationResponse.displayName = 'proto.proto.AssetUtilizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.displayName = 'proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.OverallAssetUtilization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.OverallAssetUtilization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.OverallAssetUtilization.displayName = 'proto.proto.OverallAssetUtilization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MachineRuntimeStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.MachineRuntimeStatusResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.MachineRuntimeStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MachineRuntimeStatusResponse.displayName = 'proto.proto.MachineRuntimeStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.repeatedFields_, null);
};
goog.inherits(proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.displayName = 'proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.displayName = 'proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MttrResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.MttrResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.MttrResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MttrResponse.displayName = 'proto.proto.MttrResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MttrResponse.TimeMttr = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MttrResponse.TimeMttr, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MttrResponse.TimeMttr.displayName = 'proto.proto.MttrResponse.TimeMttr';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MtbfResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.MtbfResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.MtbfResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MtbfResponse.displayName = 'proto.proto.MtbfResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MtbfResponse.TimeMtbf = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MtbfResponse.TimeMtbf, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MtbfResponse.TimeMtbf.displayName = 'proto.proto.MtbfResponse.TimeMtbf';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RuntimeAnalysisDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.RuntimeAnalysisDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.RuntimeAnalysisDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RuntimeAnalysisDetailsResponse.displayName = 'proto.proto.RuntimeAnalysisDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.displayName = 'proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ProductionOverviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ProductionOverviewRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ProductionOverviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ProductionOverviewRequest.displayName = 'proto.proto.ProductionOverviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AvgCycleTimeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AvgCycleTimeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AvgCycleTimeResponse.displayName = 'proto.proto.AvgCycleTimeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.OEEAnalysisRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.OEEAnalysisRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.OEEAnalysisRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.OEEAnalysisRequest.displayName = 'proto.proto.OEEAnalysisRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.OEEAvailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.OEEAvailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.OEEAvailResponse.displayName = 'proto.proto.OEEAvailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.OEEPerfResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.OEEPerfResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.OEEPerfResponse.displayName = 'proto.proto.OEEPerfResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.OEEQualityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.OEEQualityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.OEEQualityResponse.displayName = 'proto.proto.OEEQualityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.OEEMachineWiseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.OEEMachineWiseResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.OEEMachineWiseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.OEEMachineWiseResponse.displayName = 'proto.proto.OEEMachineWiseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.OEEMachineWiseResponse.OEEMachine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.OEEMachineWiseResponse.OEEMachine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.OEEMachineWiseResponse.OEEMachine.displayName = 'proto.proto.OEEMachineWiseResponse.OEEMachine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.OEEBySkuResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.OEEBySkuResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.OEEBySkuResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.OEEBySkuResponse.displayName = 'proto.proto.OEEBySkuResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.OEEBySkuResponse.SKUOee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.OEEBySkuResponse.SKUOee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.OEEBySkuResponse.SKUOee.displayName = 'proto.proto.OEEBySkuResponse.SKUOee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PercentageLossResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PercentageLossResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PercentageLossResponse.displayName = 'proto.proto.PercentageLossResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LossTimeAnalysisResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LossTimeAnalysisResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LossTimeAnalysisResponse.displayName = 'proto.proto.LossTimeAnalysisResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.OEEByLineResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.OEEByLineResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.OEEByLineResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.OEEByLineResponse.displayName = 'proto.proto.OEEByLineResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.OEEByLineResponse.LineOee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.OEEByLineResponse.LineOee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.OEEByLineResponse.LineOee.displayName = 'proto.proto.OEEByLineResponse.LineOee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ProductionRateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ProductionRateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ProductionRateResponse.displayName = 'proto.proto.ProductionRateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ProductionQualityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ProductionQualityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ProductionQualityResponse.displayName = 'proto.proto.ProductionQualityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ProductiveTimeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ProductiveTimeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ProductiveTimeResponse.displayName = 'proto.proto.ProductiveTimeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DowntimeDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DowntimeDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DowntimeDetailsResponse.displayName = 'proto.proto.DowntimeDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DowntimeReasonCodeDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DowntimeReasonCodeDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.DowntimeReasonCodeDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DowntimeReasonCodeDetailsResponse.displayName = 'proto.proto.DowntimeReasonCodeDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.displayName = 'proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MachineReasonDurationCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.MachineReasonDurationCountResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.MachineReasonDurationCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MachineReasonDurationCountResponse.displayName = 'proto.proto.MachineReasonDurationCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.repeatedFields_, null);
};
goog.inherits(proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.displayName = 'proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.displayName = 'proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.TimestampSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.TimestampSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.TimestampSet.displayName = 'proto.proto.TimestampSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.NodeTimestampSetPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.NodeTimestampSetPair.repeatedFields_, null);
};
goog.inherits(proto.proto.NodeTimestampSetPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.NodeTimestampSetPair.displayName = 'proto.proto.NodeTimestampSetPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.TotalCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.TotalCountRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.TotalCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.TotalCountRequest.displayName = 'proto.proto.TotalCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.TotalCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.TotalCountResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.TotalCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.TotalCountResponse.displayName = 'proto.proto.TotalCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.TotalCountResponse.CountInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.TotalCountResponse.CountInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.TotalCountResponse.CountInfo.displayName = 'proto.proto.TotalCountResponse.CountInfo';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.LineOverviewRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LineOverviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LineOverviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LineOverviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LineOverviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nodeIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    sku: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LineOverviewRequest}
 */
proto.proto.LineOverviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LineOverviewRequest;
  return proto.proto.LineOverviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LineOverviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LineOverviewRequest}
 */
proto.proto.LineOverviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNodeId(values[i]);
      }
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSku(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LineOverviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LineOverviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LineOverviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LineOverviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNodeIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getSku();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.proto.LineOverviewRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LineOverviewRequest} returns this
 */
proto.proto.LineOverviewRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.proto.LineOverviewRequest.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LineOverviewRequest} returns this
 */
proto.proto.LineOverviewRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int64 node_id = 3;
 * @return {!Array<number>}
 */
proto.proto.LineOverviewRequest.prototype.getNodeIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.LineOverviewRequest} returns this
 */
proto.proto.LineOverviewRequest.prototype.setNodeIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.LineOverviewRequest} returns this
 */
proto.proto.LineOverviewRequest.prototype.addNodeId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.LineOverviewRequest} returns this
 */
proto.proto.LineOverviewRequest.prototype.clearNodeIdList = function() {
  return this.setNodeIdList([]);
};


/**
 * optional int32 sku = 5;
 * @return {number}
 */
proto.proto.LineOverviewRequest.prototype.getSku = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LineOverviewRequest} returns this
 */
proto.proto.LineOverviewRequest.prototype.setSku = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DowntimeTrendResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DowntimeTrendResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DowntimeTrendResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DowntimeTrendResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    plannedCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    unplannedCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    smallStopsCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    qualityStopsCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    notScheduledStopsCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    otherStopsCount: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DowntimeTrendResponse}
 */
proto.proto.DowntimeTrendResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DowntimeTrendResponse;
  return proto.proto.DowntimeTrendResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DowntimeTrendResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DowntimeTrendResponse}
 */
proto.proto.DowntimeTrendResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlannedCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnplannedCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSmallStopsCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQualityStopsCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNotScheduledStopsCount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOtherStopsCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DowntimeTrendResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DowntimeTrendResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DowntimeTrendResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DowntimeTrendResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getPlannedCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUnplannedCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSmallStopsCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getQualityStopsCount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getNotScheduledStopsCount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getOtherStopsCount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.DowntimeTrendResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.DowntimeTrendResponse} returns this
*/
proto.proto.DowntimeTrendResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DowntimeTrendResponse} returns this
 */
proto.proto.DowntimeTrendResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DowntimeTrendResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 planned_count = 2;
 * @return {number}
 */
proto.proto.DowntimeTrendResponse.prototype.getPlannedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DowntimeTrendResponse} returns this
 */
proto.proto.DowntimeTrendResponse.prototype.setPlannedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 unplanned_count = 3;
 * @return {number}
 */
proto.proto.DowntimeTrendResponse.prototype.getUnplannedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DowntimeTrendResponse} returns this
 */
proto.proto.DowntimeTrendResponse.prototype.setUnplannedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 small_stops_count = 4;
 * @return {number}
 */
proto.proto.DowntimeTrendResponse.prototype.getSmallStopsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DowntimeTrendResponse} returns this
 */
proto.proto.DowntimeTrendResponse.prototype.setSmallStopsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 quality_stops_count = 5;
 * @return {number}
 */
proto.proto.DowntimeTrendResponse.prototype.getQualityStopsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DowntimeTrendResponse} returns this
 */
proto.proto.DowntimeTrendResponse.prototype.setQualityStopsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 not_scheduled_stops_count = 6;
 * @return {number}
 */
proto.proto.DowntimeTrendResponse.prototype.getNotScheduledStopsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DowntimeTrendResponse} returns this
 */
proto.proto.DowntimeTrendResponse.prototype.setNotScheduledStopsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 other_stops_count = 7;
 * @return {number}
 */
proto.proto.DowntimeTrendResponse.prototype.getOtherStopsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DowntimeTrendResponse} returns this
 */
proto.proto.DowntimeTrendResponse.prototype.setOtherStopsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.OEEParamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.OEEParamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.OEEParamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEParamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    oee: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    availability: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    quality: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    performance: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.OEEParamResponse}
 */
proto.proto.OEEParamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.OEEParamResponse;
  return proto.proto.OEEParamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.OEEParamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.OEEParamResponse}
 */
proto.proto.OEEParamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOee(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvailability(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuality(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPerformance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.OEEParamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.OEEParamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.OEEParamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEParamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getOee();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAvailability();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getQuality();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getPerformance();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.OEEParamResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.OEEParamResponse} returns this
*/
proto.proto.OEEParamResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.OEEParamResponse} returns this
 */
proto.proto.OEEParamResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.OEEParamResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double oee = 2;
 * @return {number}
 */
proto.proto.OEEParamResponse.prototype.getOee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEParamResponse} returns this
 */
proto.proto.OEEParamResponse.prototype.setOee = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double availability = 3;
 * @return {number}
 */
proto.proto.OEEParamResponse.prototype.getAvailability = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEParamResponse} returns this
 */
proto.proto.OEEParamResponse.prototype.setAvailability = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double quality = 4;
 * @return {number}
 */
proto.proto.OEEParamResponse.prototype.getQuality = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEParamResponse} returns this
 */
proto.proto.OEEParamResponse.prototype.setQuality = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double performance = 5;
 * @return {number}
 */
proto.proto.OEEParamResponse.prototype.getPerformance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEParamResponse} returns this
 */
proto.proto.OEEParamResponse.prototype.setPerformance = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.LostTimeMatrixResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LostTimeMatrixResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LostTimeMatrixResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LostTimeMatrixResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LostTimeMatrixResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    lostTimeMatrixList: jspb.Message.toObjectList(msg.getLostTimeMatrixList(),
    proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LostTimeMatrixResponse}
 */
proto.proto.LostTimeMatrixResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LostTimeMatrixResponse;
  return proto.proto.LostTimeMatrixResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LostTimeMatrixResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LostTimeMatrixResponse}
 */
proto.proto.LostTimeMatrixResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = new proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix;
      reader.readMessage(value,proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.deserializeBinaryFromReader);
      msg.addLostTimeMatrix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LostTimeMatrixResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LostTimeMatrixResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LostTimeMatrixResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LostTimeMatrixResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getLostTimeMatrixList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    downLostTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    plannedStopLostTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    smallStopLostTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    averageSmallStopTime: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix}
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix;
  return proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix}
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDownLostTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlannedStopLostTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSmallStopLostTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAverageSmallStopTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDownLostTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPlannedStopLostTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSmallStopLostTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAverageSmallStopTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string machine_name = 1;
 * @return {string}
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.prototype.getMachineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix} returns this
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.prototype.setMachineName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 down_lost_time = 2;
 * @return {number}
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.prototype.getDownLostTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix} returns this
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.prototype.setDownLostTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 planned_stop_lost_time = 3;
 * @return {number}
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.prototype.getPlannedStopLostTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix} returns this
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.prototype.setPlannedStopLostTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 small_stop_lost_time = 4;
 * @return {number}
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.prototype.getSmallStopLostTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix} returns this
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.prototype.setSmallStopLostTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 average_small_stop_time = 5;
 * @return {number}
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.prototype.getAverageSmallStopTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix} returns this
 */
proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix.prototype.setAverageSmallStopTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.LostTimeMatrixResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.LostTimeMatrixResponse} returns this
*/
proto.proto.LostTimeMatrixResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.LostTimeMatrixResponse} returns this
 */
proto.proto.LostTimeMatrixResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LostTimeMatrixResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated MachineWiseLostTimeMatrix lost_time_matrix = 2;
 * @return {!Array<!proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix>}
 */
proto.proto.LostTimeMatrixResponse.prototype.getLostTimeMatrixList = function() {
  return /** @type{!Array<!proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix, 2));
};


/**
 * @param {!Array<!proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix>} value
 * @return {!proto.proto.LostTimeMatrixResponse} returns this
*/
proto.proto.LostTimeMatrixResponse.prototype.setLostTimeMatrixList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix}
 */
proto.proto.LostTimeMatrixResponse.prototype.addLostTimeMatrix = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.LostTimeMatrixResponse.MachineWiseLostTimeMatrix, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.LostTimeMatrixResponse} returns this
 */
proto.proto.LostTimeMatrixResponse.prototype.clearLostTimeMatrixList = function() {
  return this.setLostTimeMatrixList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.TeepMatrixResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.TeepMatrixResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.TeepMatrixResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.TeepMatrixResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    teep: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    utilization: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    scheduledlosstime: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.TeepMatrixResponse}
 */
proto.proto.TeepMatrixResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.TeepMatrixResponse;
  return proto.proto.TeepMatrixResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.TeepMatrixResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.TeepMatrixResponse}
 */
proto.proto.TeepMatrixResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTeep(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUtilization(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScheduledlosstime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.TeepMatrixResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.TeepMatrixResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.TeepMatrixResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.TeepMatrixResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getTeep();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getUtilization();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getScheduledlosstime();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.TeepMatrixResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.TeepMatrixResponse} returns this
*/
proto.proto.TeepMatrixResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.TeepMatrixResponse} returns this
 */
proto.proto.TeepMatrixResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.TeepMatrixResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double teep = 2;
 * @return {number}
 */
proto.proto.TeepMatrixResponse.prototype.getTeep = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.TeepMatrixResponse} returns this
 */
proto.proto.TeepMatrixResponse.prototype.setTeep = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double utilization = 3;
 * @return {number}
 */
proto.proto.TeepMatrixResponse.prototype.getUtilization = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.TeepMatrixResponse} returns this
 */
proto.proto.TeepMatrixResponse.prototype.setUtilization = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double scheduledLossTime = 4;
 * @return {number}
 */
proto.proto.TeepMatrixResponse.prototype.getScheduledlosstime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.TeepMatrixResponse} returns this
 */
proto.proto.TeepMatrixResponse.prototype.setScheduledlosstime = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RuntimeDowntimeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RuntimeDowntimeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RuntimeDowntimeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RuntimeDowntimeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    runtime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    downtime: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RuntimeDowntimeResponse}
 */
proto.proto.RuntimeDowntimeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RuntimeDowntimeResponse;
  return proto.proto.RuntimeDowntimeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RuntimeDowntimeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RuntimeDowntimeResponse}
 */
proto.proto.RuntimeDowntimeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRuntime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDowntime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RuntimeDowntimeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RuntimeDowntimeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RuntimeDowntimeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RuntimeDowntimeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getRuntime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDowntime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.RuntimeDowntimeResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.RuntimeDowntimeResponse} returns this
*/
proto.proto.RuntimeDowntimeResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.RuntimeDowntimeResponse} returns this
 */
proto.proto.RuntimeDowntimeResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.RuntimeDowntimeResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 runtime = 2;
 * @return {number}
 */
proto.proto.RuntimeDowntimeResponse.prototype.getRuntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RuntimeDowntimeResponse} returns this
 */
proto.proto.RuntimeDowntimeResponse.prototype.setRuntime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 downtime = 3;
 * @return {number}
 */
proto.proto.RuntimeDowntimeResponse.prototype.getDowntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RuntimeDowntimeResponse} returns this
 */
proto.proto.RuntimeDowntimeResponse.prototype.setDowntime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.RateMatrixResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RateMatrixResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RateMatrixResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RateMatrixResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RateMatrixResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    machineRateCountList: jspb.Message.toObjectList(msg.getMachineRateCountList(),
    model_ReasonCodeOperatorModel_pb.MachineWiseRateMatrixCount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RateMatrixResponse}
 */
proto.proto.RateMatrixResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RateMatrixResponse;
  return proto.proto.RateMatrixResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RateMatrixResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RateMatrixResponse}
 */
proto.proto.RateMatrixResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = new model_ReasonCodeOperatorModel_pb.MachineWiseRateMatrixCount;
      reader.readMessage(value,model_ReasonCodeOperatorModel_pb.MachineWiseRateMatrixCount.deserializeBinaryFromReader);
      msg.addMachineRateCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RateMatrixResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RateMatrixResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RateMatrixResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RateMatrixResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getMachineRateCountList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      model_ReasonCodeOperatorModel_pb.MachineWiseRateMatrixCount.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.RateMatrixResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.RateMatrixResponse} returns this
*/
proto.proto.RateMatrixResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.RateMatrixResponse} returns this
 */
proto.proto.RateMatrixResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.RateMatrixResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated MachineWiseRateMatrixCount machine_rate_count = 2;
 * @return {!Array<!proto.proto.MachineWiseRateMatrixCount>}
 */
proto.proto.RateMatrixResponse.prototype.getMachineRateCountList = function() {
  return /** @type{!Array<!proto.proto.MachineWiseRateMatrixCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_ReasonCodeOperatorModel_pb.MachineWiseRateMatrixCount, 2));
};


/**
 * @param {!Array<!proto.proto.MachineWiseRateMatrixCount>} value
 * @return {!proto.proto.RateMatrixResponse} returns this
*/
proto.proto.RateMatrixResponse.prototype.setMachineRateCountList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.MachineWiseRateMatrixCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MachineWiseRateMatrixCount}
 */
proto.proto.RateMatrixResponse.prototype.addMachineRateCount = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.MachineWiseRateMatrixCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.RateMatrixResponse} returns this
 */
proto.proto.RateMatrixResponse.prototype.clearMachineRateCountList = function() {
  return this.setMachineRateCountList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.RuntimeOverviewRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RuntimeOverviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RuntimeOverviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RuntimeOverviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RuntimeOverviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nodeIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    sku: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RuntimeOverviewRequest}
 */
proto.proto.RuntimeOverviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RuntimeOverviewRequest;
  return proto.proto.RuntimeOverviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RuntimeOverviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RuntimeOverviewRequest}
 */
proto.proto.RuntimeOverviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNodeId(values[i]);
      }
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSku(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RuntimeOverviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RuntimeOverviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RuntimeOverviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RuntimeOverviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNodeIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getSku();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.proto.RuntimeOverviewRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RuntimeOverviewRequest} returns this
 */
proto.proto.RuntimeOverviewRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.proto.RuntimeOverviewRequest.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RuntimeOverviewRequest} returns this
 */
proto.proto.RuntimeOverviewRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int64 node_id = 3;
 * @return {!Array<number>}
 */
proto.proto.RuntimeOverviewRequest.prototype.getNodeIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.RuntimeOverviewRequest} returns this
 */
proto.proto.RuntimeOverviewRequest.prototype.setNodeIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.RuntimeOverviewRequest} returns this
 */
proto.proto.RuntimeOverviewRequest.prototype.addNodeId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.RuntimeOverviewRequest} returns this
 */
proto.proto.RuntimeOverviewRequest.prototype.clearNodeIdList = function() {
  return this.setNodeIdList([]);
};


/**
 * optional int32 sku = 5;
 * @return {number}
 */
proto.proto.RuntimeOverviewRequest.prototype.getSku = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RuntimeOverviewRequest} returns this
 */
proto.proto.RuntimeOverviewRequest.prototype.setSku = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RuntimeValuesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RuntimeValuesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RuntimeValuesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RuntimeValuesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    runtime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastRuntime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    percentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RuntimeValuesResponse}
 */
proto.proto.RuntimeValuesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RuntimeValuesResponse;
  return proto.proto.RuntimeValuesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RuntimeValuesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RuntimeValuesResponse}
 */
proto.proto.RuntimeValuesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRuntime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastRuntime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RuntimeValuesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RuntimeValuesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RuntimeValuesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RuntimeValuesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getRuntime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLastRuntime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.RuntimeValuesResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.RuntimeValuesResponse} returns this
*/
proto.proto.RuntimeValuesResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.RuntimeValuesResponse} returns this
 */
proto.proto.RuntimeValuesResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.RuntimeValuesResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 runtime = 2;
 * @return {number}
 */
proto.proto.RuntimeValuesResponse.prototype.getRuntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RuntimeValuesResponse} returns this
 */
proto.proto.RuntimeValuesResponse.prototype.setRuntime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 last_runtime = 3;
 * @return {number}
 */
proto.proto.RuntimeValuesResponse.prototype.getLastRuntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RuntimeValuesResponse} returns this
 */
proto.proto.RuntimeValuesResponse.prototype.setLastRuntime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double percentage = 4;
 * @return {number}
 */
proto.proto.RuntimeValuesResponse.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RuntimeValuesResponse} returns this
 */
proto.proto.RuntimeValuesResponse.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DowntimeValuesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DowntimeValuesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DowntimeValuesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DowntimeValuesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    downtime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastDowntime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    percentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DowntimeValuesResponse}
 */
proto.proto.DowntimeValuesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DowntimeValuesResponse;
  return proto.proto.DowntimeValuesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DowntimeValuesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DowntimeValuesResponse}
 */
proto.proto.DowntimeValuesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDowntime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastDowntime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DowntimeValuesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DowntimeValuesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DowntimeValuesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DowntimeValuesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getDowntime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLastDowntime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.DowntimeValuesResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.DowntimeValuesResponse} returns this
*/
proto.proto.DowntimeValuesResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DowntimeValuesResponse} returns this
 */
proto.proto.DowntimeValuesResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DowntimeValuesResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 downtime = 2;
 * @return {number}
 */
proto.proto.DowntimeValuesResponse.prototype.getDowntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DowntimeValuesResponse} returns this
 */
proto.proto.DowntimeValuesResponse.prototype.setDowntime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 last_downtime = 3;
 * @return {number}
 */
proto.proto.DowntimeValuesResponse.prototype.getLastDowntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DowntimeValuesResponse} returns this
 */
proto.proto.DowntimeValuesResponse.prototype.setLastDowntime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double percentage = 4;
 * @return {number}
 */
proto.proto.DowntimeValuesResponse.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DowntimeValuesResponse} returns this
 */
proto.proto.DowntimeValuesResponse.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PlannedStoptimealuesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PlannedStoptimealuesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PlannedStoptimealuesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PlannedStoptimealuesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    plannedStoptime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastPlannedStoptime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    percentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PlannedStoptimealuesResponse}
 */
proto.proto.PlannedStoptimealuesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PlannedStoptimealuesResponse;
  return proto.proto.PlannedStoptimealuesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PlannedStoptimealuesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PlannedStoptimealuesResponse}
 */
proto.proto.PlannedStoptimealuesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlannedStoptime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastPlannedStoptime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PlannedStoptimealuesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PlannedStoptimealuesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PlannedStoptimealuesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PlannedStoptimealuesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getPlannedStoptime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLastPlannedStoptime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.PlannedStoptimealuesResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.PlannedStoptimealuesResponse} returns this
*/
proto.proto.PlannedStoptimealuesResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PlannedStoptimealuesResponse} returns this
 */
proto.proto.PlannedStoptimealuesResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PlannedStoptimealuesResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 planned_stoptime = 2;
 * @return {number}
 */
proto.proto.PlannedStoptimealuesResponse.prototype.getPlannedStoptime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PlannedStoptimealuesResponse} returns this
 */
proto.proto.PlannedStoptimealuesResponse.prototype.setPlannedStoptime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 last_planned_stoptime = 3;
 * @return {number}
 */
proto.proto.PlannedStoptimealuesResponse.prototype.getLastPlannedStoptime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PlannedStoptimealuesResponse} returns this
 */
proto.proto.PlannedStoptimealuesResponse.prototype.setLastPlannedStoptime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double percentage = 4;
 * @return {number}
 */
proto.proto.PlannedStoptimealuesResponse.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PlannedStoptimealuesResponse} returns this
 */
proto.proto.PlannedStoptimealuesResponse.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AssetUtilizationResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AssetUtilizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AssetUtilizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AssetUtilizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AssetUtilizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    machineTimeInfoList: jspb.Message.toObjectList(msg.getMachineTimeInfoList(),
    proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AssetUtilizationResponse}
 */
proto.proto.AssetUtilizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AssetUtilizationResponse;
  return proto.proto.AssetUtilizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AssetUtilizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AssetUtilizationResponse}
 */
proto.proto.AssetUtilizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = new proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo;
      reader.readMessage(value,proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.deserializeBinaryFromReader);
      msg.addMachineTimeInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AssetUtilizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AssetUtilizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AssetUtilizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AssetUtilizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getMachineTimeInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    runtime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    availabletime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    percentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo}
 */
proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo;
  return proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo}
 */
proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRuntime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAvailabletime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRuntime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAvailabletime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string machine_name = 1;
 * @return {string}
 */
proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.prototype.getMachineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo} returns this
 */
proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.prototype.setMachineName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 runtime = 2;
 * @return {number}
 */
proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.prototype.getRuntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo} returns this
 */
proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.prototype.setRuntime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 availableTime = 3;
 * @return {number}
 */
proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.prototype.getAvailabletime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo} returns this
 */
proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.prototype.setAvailabletime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double percentage = 4;
 * @return {number}
 */
proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo} returns this
 */
proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.AssetUtilizationResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.AssetUtilizationResponse} returns this
*/
proto.proto.AssetUtilizationResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AssetUtilizationResponse} returns this
 */
proto.proto.AssetUtilizationResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AssetUtilizationResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated AvailTimeRuntimeInfo machine_time_info = 2;
 * @return {!Array<!proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo>}
 */
proto.proto.AssetUtilizationResponse.prototype.getMachineTimeInfoList = function() {
  return /** @type{!Array<!proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo, 2));
};


/**
 * @param {!Array<!proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo>} value
 * @return {!proto.proto.AssetUtilizationResponse} returns this
*/
proto.proto.AssetUtilizationResponse.prototype.setMachineTimeInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo}
 */
proto.proto.AssetUtilizationResponse.prototype.addMachineTimeInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.AssetUtilizationResponse.AvailTimeRuntimeInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AssetUtilizationResponse} returns this
 */
proto.proto.AssetUtilizationResponse.prototype.clearMachineTimeInfoList = function() {
  return this.setMachineTimeInfoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.OverallAssetUtilization.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.OverallAssetUtilization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.OverallAssetUtilization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OverallAssetUtilization.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    runtime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    availabletime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    percentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.OverallAssetUtilization}
 */
proto.proto.OverallAssetUtilization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.OverallAssetUtilization;
  return proto.proto.OverallAssetUtilization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.OverallAssetUtilization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.OverallAssetUtilization}
 */
proto.proto.OverallAssetUtilization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRuntime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAvailabletime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.OverallAssetUtilization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.OverallAssetUtilization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.OverallAssetUtilization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OverallAssetUtilization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getRuntime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAvailabletime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.OverallAssetUtilization.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.OverallAssetUtilization} returns this
*/
proto.proto.OverallAssetUtilization.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.OverallAssetUtilization} returns this
 */
proto.proto.OverallAssetUtilization.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.OverallAssetUtilization.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 runtime = 2;
 * @return {number}
 */
proto.proto.OverallAssetUtilization.prototype.getRuntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OverallAssetUtilization} returns this
 */
proto.proto.OverallAssetUtilization.prototype.setRuntime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 availableTime = 3;
 * @return {number}
 */
proto.proto.OverallAssetUtilization.prototype.getAvailabletime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OverallAssetUtilization} returns this
 */
proto.proto.OverallAssetUtilization.prototype.setAvailabletime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double percentage = 4;
 * @return {number}
 */
proto.proto.OverallAssetUtilization.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OverallAssetUtilization} returns this
 */
proto.proto.OverallAssetUtilization.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.MachineRuntimeStatusResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MachineRuntimeStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MachineRuntimeStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MachineRuntimeStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineRuntimeStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    machineRuntimeStatusList: jspb.Message.toObjectList(msg.getMachineRuntimeStatusList(),
    proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MachineRuntimeStatusResponse}
 */
proto.proto.MachineRuntimeStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MachineRuntimeStatusResponse;
  return proto.proto.MachineRuntimeStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MachineRuntimeStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MachineRuntimeStatusResponse}
 */
proto.proto.MachineRuntimeStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = new proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus;
      reader.readMessage(value,proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.deserializeBinaryFromReader);
      msg.addMachineRuntimeStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MachineRuntimeStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MachineRuntimeStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MachineRuntimeStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineRuntimeStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getMachineRuntimeStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statusDurationList: jspb.Message.toObjectList(msg.getStatusDurationList(),
    proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus}
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus;
  return proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus}
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineName(value);
      break;
    case 2:
      var value = new proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration;
      reader.readMessage(value,proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.deserializeBinaryFromReader);
      msg.addStatusDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatusDurationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration}
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration;
  return proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration}
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration} returns this
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 duration = 2;
 * @return {number}
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration} returns this
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string machine_name = 1;
 * @return {string}
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.prototype.getMachineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus} returns this
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.prototype.setMachineName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated StatusDuration status_duration = 2;
 * @return {!Array<!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration>}
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.prototype.getStatusDurationList = function() {
  return /** @type{!Array<!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration, 2));
};


/**
 * @param {!Array<!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration>} value
 * @return {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus} returns this
*/
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.prototype.setStatusDurationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration}
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.prototype.addStatusDuration = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.StatusDuration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus} returns this
 */
proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus.prototype.clearStatusDurationList = function() {
  return this.setStatusDurationList([]);
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.MachineRuntimeStatusResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.MachineRuntimeStatusResponse} returns this
*/
proto.proto.MachineRuntimeStatusResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MachineRuntimeStatusResponse} returns this
 */
proto.proto.MachineRuntimeStatusResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MachineRuntimeStatusResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated MachineRuntimeStatus machine_runtime_status = 2;
 * @return {!Array<!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus>}
 */
proto.proto.MachineRuntimeStatusResponse.prototype.getMachineRuntimeStatusList = function() {
  return /** @type{!Array<!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus, 2));
};


/**
 * @param {!Array<!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus>} value
 * @return {!proto.proto.MachineRuntimeStatusResponse} returns this
*/
proto.proto.MachineRuntimeStatusResponse.prototype.setMachineRuntimeStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus}
 */
proto.proto.MachineRuntimeStatusResponse.prototype.addMachineRuntimeStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.MachineRuntimeStatusResponse.MachineRuntimeStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.MachineRuntimeStatusResponse} returns this
 */
proto.proto.MachineRuntimeStatusResponse.prototype.clearMachineRuntimeStatusList = function() {
  return this.setMachineRuntimeStatusList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.MttrResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MttrResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MttrResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MttrResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MttrResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    totalDowntime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalMttr: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    timeMttrList: jspb.Message.toObjectList(msg.getTimeMttrList(),
    proto.proto.MttrResponse.TimeMttr.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MttrResponse}
 */
proto.proto.MttrResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MttrResponse;
  return proto.proto.MttrResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MttrResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MttrResponse}
 */
proto.proto.MttrResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalDowntime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalMttr(value);
      break;
    case 4:
      var value = new proto.proto.MttrResponse.TimeMttr;
      reader.readMessage(value,proto.proto.MttrResponse.TimeMttr.deserializeBinaryFromReader);
      msg.addTimeMttr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MttrResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MttrResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MttrResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MttrResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotalDowntime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTotalMttr();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTimeMttrList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.proto.MttrResponse.TimeMttr.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MttrResponse.TimeMttr.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MttrResponse.TimeMttr.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MttrResponse.TimeMttr} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MttrResponse.TimeMttr.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mttr: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MttrResponse.TimeMttr}
 */
proto.proto.MttrResponse.TimeMttr.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MttrResponse.TimeMttr;
  return proto.proto.MttrResponse.TimeMttr.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MttrResponse.TimeMttr} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MttrResponse.TimeMttr}
 */
proto.proto.MttrResponse.TimeMttr.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMttr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MttrResponse.TimeMttr.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MttrResponse.TimeMttr.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MttrResponse.TimeMttr} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MttrResponse.TimeMttr.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMttr();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int64 time = 1;
 * @return {number}
 */
proto.proto.MttrResponse.TimeMttr.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MttrResponse.TimeMttr} returns this
 */
proto.proto.MttrResponse.TimeMttr.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double mttr = 2;
 * @return {number}
 */
proto.proto.MttrResponse.TimeMttr.prototype.getMttr = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MttrResponse.TimeMttr} returns this
 */
proto.proto.MttrResponse.TimeMttr.prototype.setMttr = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.MttrResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.MttrResponse} returns this
*/
proto.proto.MttrResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MttrResponse} returns this
 */
proto.proto.MttrResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MttrResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 total_downtime = 2;
 * @return {number}
 */
proto.proto.MttrResponse.prototype.getTotalDowntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MttrResponse} returns this
 */
proto.proto.MttrResponse.prototype.setTotalDowntime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double total_mttr = 3;
 * @return {number}
 */
proto.proto.MttrResponse.prototype.getTotalMttr = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MttrResponse} returns this
 */
proto.proto.MttrResponse.prototype.setTotalMttr = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated TimeMttr time_mttr = 4;
 * @return {!Array<!proto.proto.MttrResponse.TimeMttr>}
 */
proto.proto.MttrResponse.prototype.getTimeMttrList = function() {
  return /** @type{!Array<!proto.proto.MttrResponse.TimeMttr>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.MttrResponse.TimeMttr, 4));
};


/**
 * @param {!Array<!proto.proto.MttrResponse.TimeMttr>} value
 * @return {!proto.proto.MttrResponse} returns this
*/
proto.proto.MttrResponse.prototype.setTimeMttrList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.proto.MttrResponse.TimeMttr=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MttrResponse.TimeMttr}
 */
proto.proto.MttrResponse.prototype.addTimeMttr = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.proto.MttrResponse.TimeMttr, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.MttrResponse} returns this
 */
proto.proto.MttrResponse.prototype.clearTimeMttrList = function() {
  return this.setTimeMttrList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.MtbfResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MtbfResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MtbfResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MtbfResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MtbfResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    totalTimeSpent: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalMtbf: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    timeMtbfList: jspb.Message.toObjectList(msg.getTimeMtbfList(),
    proto.proto.MtbfResponse.TimeMtbf.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MtbfResponse}
 */
proto.proto.MtbfResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MtbfResponse;
  return proto.proto.MtbfResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MtbfResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MtbfResponse}
 */
proto.proto.MtbfResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalTimeSpent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalMtbf(value);
      break;
    case 4:
      var value = new proto.proto.MtbfResponse.TimeMtbf;
      reader.readMessage(value,proto.proto.MtbfResponse.TimeMtbf.deserializeBinaryFromReader);
      msg.addTimeMtbf(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MtbfResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MtbfResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MtbfResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MtbfResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotalTimeSpent();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTotalMtbf();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTimeMtbfList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.proto.MtbfResponse.TimeMtbf.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MtbfResponse.TimeMtbf.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MtbfResponse.TimeMtbf.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MtbfResponse.TimeMtbf} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MtbfResponse.TimeMtbf.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mttr: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MtbfResponse.TimeMtbf}
 */
proto.proto.MtbfResponse.TimeMtbf.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MtbfResponse.TimeMtbf;
  return proto.proto.MtbfResponse.TimeMtbf.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MtbfResponse.TimeMtbf} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MtbfResponse.TimeMtbf}
 */
proto.proto.MtbfResponse.TimeMtbf.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMttr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MtbfResponse.TimeMtbf.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MtbfResponse.TimeMtbf.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MtbfResponse.TimeMtbf} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MtbfResponse.TimeMtbf.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMttr();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int64 time = 1;
 * @return {number}
 */
proto.proto.MtbfResponse.TimeMtbf.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MtbfResponse.TimeMtbf} returns this
 */
proto.proto.MtbfResponse.TimeMtbf.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double mttr = 2;
 * @return {number}
 */
proto.proto.MtbfResponse.TimeMtbf.prototype.getMttr = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MtbfResponse.TimeMtbf} returns this
 */
proto.proto.MtbfResponse.TimeMtbf.prototype.setMttr = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.MtbfResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.MtbfResponse} returns this
*/
proto.proto.MtbfResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MtbfResponse} returns this
 */
proto.proto.MtbfResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MtbfResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 total_time_spent = 2;
 * @return {number}
 */
proto.proto.MtbfResponse.prototype.getTotalTimeSpent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MtbfResponse} returns this
 */
proto.proto.MtbfResponse.prototype.setTotalTimeSpent = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double total_mtbf = 3;
 * @return {number}
 */
proto.proto.MtbfResponse.prototype.getTotalMtbf = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MtbfResponse} returns this
 */
proto.proto.MtbfResponse.prototype.setTotalMtbf = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated TimeMtbf time_mtbf = 4;
 * @return {!Array<!proto.proto.MtbfResponse.TimeMtbf>}
 */
proto.proto.MtbfResponse.prototype.getTimeMtbfList = function() {
  return /** @type{!Array<!proto.proto.MtbfResponse.TimeMtbf>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.MtbfResponse.TimeMtbf, 4));
};


/**
 * @param {!Array<!proto.proto.MtbfResponse.TimeMtbf>} value
 * @return {!proto.proto.MtbfResponse} returns this
*/
proto.proto.MtbfResponse.prototype.setTimeMtbfList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.proto.MtbfResponse.TimeMtbf=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MtbfResponse.TimeMtbf}
 */
proto.proto.MtbfResponse.prototype.addTimeMtbf = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.proto.MtbfResponse.TimeMtbf, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.MtbfResponse} returns this
 */
proto.proto.MtbfResponse.prototype.clearTimeMtbfList = function() {
  return this.setTimeMtbfList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.RuntimeAnalysisDetailsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RuntimeAnalysisDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RuntimeAnalysisDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RuntimeAnalysisDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RuntimeAnalysisDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    detailsList: jspb.Message.toObjectList(msg.getDetailsList(),
    proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse}
 */
proto.proto.RuntimeAnalysisDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RuntimeAnalysisDetailsResponse;
  return proto.proto.RuntimeAnalysisDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RuntimeAnalysisDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse}
 */
proto.proto.RuntimeAnalysisDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = new proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails;
      reader.readMessage(value,proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.deserializeBinaryFromReader);
      msg.addDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RuntimeAnalysisDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RuntimeAnalysisDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RuntimeAnalysisDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RuntimeAnalysisDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    line: jspb.Message.getFieldWithDefault(msg, 1, ""),
    machine: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sku: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    reasonCode: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails}
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails;
  return proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails}
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachine(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLine();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMachine();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getReasonCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string line = 1;
 * @return {string}
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.getLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails} returns this
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.setLine = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string machine = 2;
 * @return {string}
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.getMachine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails} returns this
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.setMachine = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sku = 3;
 * @return {string}
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails} returns this
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails} returns this
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails} returns this
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 start_time = 6;
 * @return {number}
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails} returns this
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 end_time = 7;
 * @return {number}
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails} returns this
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string reason_code = 8;
 * @return {string}
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.getReasonCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails} returns this
 */
proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails.prototype.setReasonCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.RuntimeAnalysisDetailsResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse} returns this
*/
proto.proto.RuntimeAnalysisDetailsResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse} returns this
 */
proto.proto.RuntimeAnalysisDetailsResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.RuntimeAnalysisDetailsResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated RuntimeAnalysisDetails details = 2;
 * @return {!Array<!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails>}
 */
proto.proto.RuntimeAnalysisDetailsResponse.prototype.getDetailsList = function() {
  return /** @type{!Array<!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails, 2));
};


/**
 * @param {!Array<!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails>} value
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse} returns this
*/
proto.proto.RuntimeAnalysisDetailsResponse.prototype.setDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails}
 */
proto.proto.RuntimeAnalysisDetailsResponse.prototype.addDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.RuntimeAnalysisDetailsResponse.RuntimeAnalysisDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.RuntimeAnalysisDetailsResponse} returns this
 */
proto.proto.RuntimeAnalysisDetailsResponse.prototype.clearDetailsList = function() {
  return this.setDetailsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ProductionOverviewRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ProductionOverviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ProductionOverviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ProductionOverviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProductionOverviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nodeIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    sku: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ProductionOverviewRequest}
 */
proto.proto.ProductionOverviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ProductionOverviewRequest;
  return proto.proto.ProductionOverviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ProductionOverviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ProductionOverviewRequest}
 */
proto.proto.ProductionOverviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNodeId(values[i]);
      }
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSku(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ProductionOverviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ProductionOverviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ProductionOverviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProductionOverviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNodeIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getSku();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.proto.ProductionOverviewRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ProductionOverviewRequest} returns this
 */
proto.proto.ProductionOverviewRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.proto.ProductionOverviewRequest.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ProductionOverviewRequest} returns this
 */
proto.proto.ProductionOverviewRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int64 node_id = 3;
 * @return {!Array<number>}
 */
proto.proto.ProductionOverviewRequest.prototype.getNodeIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ProductionOverviewRequest} returns this
 */
proto.proto.ProductionOverviewRequest.prototype.setNodeIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ProductionOverviewRequest} returns this
 */
proto.proto.ProductionOverviewRequest.prototype.addNodeId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ProductionOverviewRequest} returns this
 */
proto.proto.ProductionOverviewRequest.prototype.clearNodeIdList = function() {
  return this.setNodeIdList([]);
};


/**
 * optional int32 sku = 5;
 * @return {number}
 */
proto.proto.ProductionOverviewRequest.prototype.getSku = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ProductionOverviewRequest} returns this
 */
proto.proto.ProductionOverviewRequest.prototype.setSku = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AvgCycleTimeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AvgCycleTimeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AvgCycleTimeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AvgCycleTimeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    ratedCycleTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    avgCycleTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    deviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AvgCycleTimeResponse}
 */
proto.proto.AvgCycleTimeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AvgCycleTimeResponse;
  return proto.proto.AvgCycleTimeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AvgCycleTimeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AvgCycleTimeResponse}
 */
proto.proto.AvgCycleTimeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRatedCycleTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgCycleTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeviation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AvgCycleTimeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AvgCycleTimeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AvgCycleTimeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AvgCycleTimeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getRatedCycleTime();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAvgCycleTime();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.AvgCycleTimeResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.AvgCycleTimeResponse} returns this
*/
proto.proto.AvgCycleTimeResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AvgCycleTimeResponse} returns this
 */
proto.proto.AvgCycleTimeResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AvgCycleTimeResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double rated_cycle_time = 2;
 * @return {number}
 */
proto.proto.AvgCycleTimeResponse.prototype.getRatedCycleTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AvgCycleTimeResponse} returns this
 */
proto.proto.AvgCycleTimeResponse.prototype.setRatedCycleTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double avg_cycle_time = 3;
 * @return {number}
 */
proto.proto.AvgCycleTimeResponse.prototype.getAvgCycleTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AvgCycleTimeResponse} returns this
 */
proto.proto.AvgCycleTimeResponse.prototype.setAvgCycleTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double deviation = 4;
 * @return {number}
 */
proto.proto.AvgCycleTimeResponse.prototype.getDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AvgCycleTimeResponse} returns this
 */
proto.proto.AvgCycleTimeResponse.prototype.setDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.OEEAnalysisRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.OEEAnalysisRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.OEEAnalysisRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.OEEAnalysisRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEAnalysisRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nodeIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    sku: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.OEEAnalysisRequest}
 */
proto.proto.OEEAnalysisRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.OEEAnalysisRequest;
  return proto.proto.OEEAnalysisRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.OEEAnalysisRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.OEEAnalysisRequest}
 */
proto.proto.OEEAnalysisRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNodeId(values[i]);
      }
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSku(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.OEEAnalysisRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.OEEAnalysisRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.OEEAnalysisRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEAnalysisRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNodeIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getSku();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.proto.OEEAnalysisRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEAnalysisRequest} returns this
 */
proto.proto.OEEAnalysisRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.proto.OEEAnalysisRequest.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEAnalysisRequest} returns this
 */
proto.proto.OEEAnalysisRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int64 node_id = 3;
 * @return {!Array<number>}
 */
proto.proto.OEEAnalysisRequest.prototype.getNodeIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.OEEAnalysisRequest} returns this
 */
proto.proto.OEEAnalysisRequest.prototype.setNodeIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.OEEAnalysisRequest} returns this
 */
proto.proto.OEEAnalysisRequest.prototype.addNodeId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.OEEAnalysisRequest} returns this
 */
proto.proto.OEEAnalysisRequest.prototype.clearNodeIdList = function() {
  return this.setNodeIdList([]);
};


/**
 * optional int32 sku = 5;
 * @return {number}
 */
proto.proto.OEEAnalysisRequest.prototype.getSku = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEAnalysisRequest} returns this
 */
proto.proto.OEEAnalysisRequest.prototype.setSku = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.OEEAvailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.OEEAvailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.OEEAvailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEAvailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    plannedDowntime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    unplannedDowntime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    actualRuntime: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.OEEAvailResponse}
 */
proto.proto.OEEAvailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.OEEAvailResponse;
  return proto.proto.OEEAvailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.OEEAvailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.OEEAvailResponse}
 */
proto.proto.OEEAvailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlannedDowntime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnplannedDowntime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActualRuntime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.OEEAvailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.OEEAvailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.OEEAvailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEAvailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getPlannedDowntime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUnplannedDowntime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getActualRuntime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.OEEAvailResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.OEEAvailResponse} returns this
*/
proto.proto.OEEAvailResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.OEEAvailResponse} returns this
 */
proto.proto.OEEAvailResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.OEEAvailResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 planned_downtime = 2;
 * @return {number}
 */
proto.proto.OEEAvailResponse.prototype.getPlannedDowntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEAvailResponse} returns this
 */
proto.proto.OEEAvailResponse.prototype.setPlannedDowntime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 unplanned_downtime = 3;
 * @return {number}
 */
proto.proto.OEEAvailResponse.prototype.getUnplannedDowntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEAvailResponse} returns this
 */
proto.proto.OEEAvailResponse.prototype.setUnplannedDowntime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 actual_runtime = 4;
 * @return {number}
 */
proto.proto.OEEAvailResponse.prototype.getActualRuntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEAvailResponse} returns this
 */
proto.proto.OEEAvailResponse.prototype.setActualRuntime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.OEEPerfResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.OEEPerfResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.OEEPerfResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEPerfResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    actualQuantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    plannedQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.OEEPerfResponse}
 */
proto.proto.OEEPerfResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.OEEPerfResponse;
  return proto.proto.OEEPerfResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.OEEPerfResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.OEEPerfResponse}
 */
proto.proto.OEEPerfResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActualQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlannedQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.OEEPerfResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.OEEPerfResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.OEEPerfResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEPerfResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getActualQuantity();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPlannedQuantity();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.OEEPerfResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.OEEPerfResponse} returns this
*/
proto.proto.OEEPerfResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.OEEPerfResponse} returns this
 */
proto.proto.OEEPerfResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.OEEPerfResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 actual_quantity = 2;
 * @return {number}
 */
proto.proto.OEEPerfResponse.prototype.getActualQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEPerfResponse} returns this
 */
proto.proto.OEEPerfResponse.prototype.setActualQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 planned_quantity = 3;
 * @return {number}
 */
proto.proto.OEEPerfResponse.prototype.getPlannedQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEPerfResponse} returns this
 */
proto.proto.OEEPerfResponse.prototype.setPlannedQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.OEEQualityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.OEEQualityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.OEEQualityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEQualityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    actualQuantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rejectedQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.OEEQualityResponse}
 */
proto.proto.OEEQualityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.OEEQualityResponse;
  return proto.proto.OEEQualityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.OEEQualityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.OEEQualityResponse}
 */
proto.proto.OEEQualityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActualQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRejectedQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.OEEQualityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.OEEQualityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.OEEQualityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEQualityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getActualQuantity();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRejectedQuantity();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.OEEQualityResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.OEEQualityResponse} returns this
*/
proto.proto.OEEQualityResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.OEEQualityResponse} returns this
 */
proto.proto.OEEQualityResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.OEEQualityResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 actual_quantity = 2;
 * @return {number}
 */
proto.proto.OEEQualityResponse.prototype.getActualQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEQualityResponse} returns this
 */
proto.proto.OEEQualityResponse.prototype.setActualQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 rejected_quantity = 3;
 * @return {number}
 */
proto.proto.OEEQualityResponse.prototype.getRejectedQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEQualityResponse} returns this
 */
proto.proto.OEEQualityResponse.prototype.setRejectedQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.OEEMachineWiseResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.OEEMachineWiseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.OEEMachineWiseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.OEEMachineWiseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEMachineWiseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    oeeMachineList: jspb.Message.toObjectList(msg.getOeeMachineList(),
    proto.proto.OEEMachineWiseResponse.OEEMachine.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.OEEMachineWiseResponse}
 */
proto.proto.OEEMachineWiseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.OEEMachineWiseResponse;
  return proto.proto.OEEMachineWiseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.OEEMachineWiseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.OEEMachineWiseResponse}
 */
proto.proto.OEEMachineWiseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = new proto.proto.OEEMachineWiseResponse.OEEMachine;
      reader.readMessage(value,proto.proto.OEEMachineWiseResponse.OEEMachine.deserializeBinaryFromReader);
      msg.addOeeMachine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.OEEMachineWiseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.OEEMachineWiseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.OEEMachineWiseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEMachineWiseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getOeeMachineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.OEEMachineWiseResponse.OEEMachine.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.OEEMachineWiseResponse.OEEMachine.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.OEEMachineWiseResponse.OEEMachine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.OEEMachineWiseResponse.OEEMachine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEMachineWiseResponse.OEEMachine.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    oee: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.OEEMachineWiseResponse.OEEMachine}
 */
proto.proto.OEEMachineWiseResponse.OEEMachine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.OEEMachineWiseResponse.OEEMachine;
  return proto.proto.OEEMachineWiseResponse.OEEMachine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.OEEMachineWiseResponse.OEEMachine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.OEEMachineWiseResponse.OEEMachine}
 */
proto.proto.OEEMachineWiseResponse.OEEMachine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.OEEMachineWiseResponse.OEEMachine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.OEEMachineWiseResponse.OEEMachine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.OEEMachineWiseResponse.OEEMachine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEMachineWiseResponse.OEEMachine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOee();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string machine_name = 1;
 * @return {string}
 */
proto.proto.OEEMachineWiseResponse.OEEMachine.prototype.getMachineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.OEEMachineWiseResponse.OEEMachine} returns this
 */
proto.proto.OEEMachineWiseResponse.OEEMachine.prototype.setMachineName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double oee = 2;
 * @return {number}
 */
proto.proto.OEEMachineWiseResponse.OEEMachine.prototype.getOee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEMachineWiseResponse.OEEMachine} returns this
 */
proto.proto.OEEMachineWiseResponse.OEEMachine.prototype.setOee = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.OEEMachineWiseResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.OEEMachineWiseResponse} returns this
*/
proto.proto.OEEMachineWiseResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.OEEMachineWiseResponse} returns this
 */
proto.proto.OEEMachineWiseResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.OEEMachineWiseResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated OEEMachine oee_machine = 2;
 * @return {!Array<!proto.proto.OEEMachineWiseResponse.OEEMachine>}
 */
proto.proto.OEEMachineWiseResponse.prototype.getOeeMachineList = function() {
  return /** @type{!Array<!proto.proto.OEEMachineWiseResponse.OEEMachine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.OEEMachineWiseResponse.OEEMachine, 2));
};


/**
 * @param {!Array<!proto.proto.OEEMachineWiseResponse.OEEMachine>} value
 * @return {!proto.proto.OEEMachineWiseResponse} returns this
*/
proto.proto.OEEMachineWiseResponse.prototype.setOeeMachineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.OEEMachineWiseResponse.OEEMachine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.OEEMachineWiseResponse.OEEMachine}
 */
proto.proto.OEEMachineWiseResponse.prototype.addOeeMachine = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.OEEMachineWiseResponse.OEEMachine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.OEEMachineWiseResponse} returns this
 */
proto.proto.OEEMachineWiseResponse.prototype.clearOeeMachineList = function() {
  return this.setOeeMachineList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.OEEBySkuResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.OEEBySkuResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.OEEBySkuResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.OEEBySkuResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEBySkuResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    skuOeeList: jspb.Message.toObjectList(msg.getSkuOeeList(),
    proto.proto.OEEBySkuResponse.SKUOee.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.OEEBySkuResponse}
 */
proto.proto.OEEBySkuResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.OEEBySkuResponse;
  return proto.proto.OEEBySkuResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.OEEBySkuResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.OEEBySkuResponse}
 */
proto.proto.OEEBySkuResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = new proto.proto.OEEBySkuResponse.SKUOee;
      reader.readMessage(value,proto.proto.OEEBySkuResponse.SKUOee.deserializeBinaryFromReader);
      msg.addSkuOee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.OEEBySkuResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.OEEBySkuResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.OEEBySkuResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEBySkuResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getSkuOeeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.OEEBySkuResponse.SKUOee.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.OEEBySkuResponse.SKUOee.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.OEEBySkuResponse.SKUOee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.OEEBySkuResponse.SKUOee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEBySkuResponse.SKUOee.toObject = function(includeInstance, msg) {
  var f, obj = {
    sku: jspb.Message.getFieldWithDefault(msg, 1, 0),
    oee: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.OEEBySkuResponse.SKUOee}
 */
proto.proto.OEEBySkuResponse.SKUOee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.OEEBySkuResponse.SKUOee;
  return proto.proto.OEEBySkuResponse.SKUOee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.OEEBySkuResponse.SKUOee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.OEEBySkuResponse.SKUOee}
 */
proto.proto.OEEBySkuResponse.SKUOee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSku(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.OEEBySkuResponse.SKUOee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.OEEBySkuResponse.SKUOee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.OEEBySkuResponse.SKUOee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEBySkuResponse.SKUOee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSku();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOee();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int32 sku = 1;
 * @return {number}
 */
proto.proto.OEEBySkuResponse.SKUOee.prototype.getSku = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEBySkuResponse.SKUOee} returns this
 */
proto.proto.OEEBySkuResponse.SKUOee.prototype.setSku = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double oee = 2;
 * @return {number}
 */
proto.proto.OEEBySkuResponse.SKUOee.prototype.getOee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEBySkuResponse.SKUOee} returns this
 */
proto.proto.OEEBySkuResponse.SKUOee.prototype.setOee = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.OEEBySkuResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.OEEBySkuResponse} returns this
*/
proto.proto.OEEBySkuResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.OEEBySkuResponse} returns this
 */
proto.proto.OEEBySkuResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.OEEBySkuResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SKUOee sku_oee = 2;
 * @return {!Array<!proto.proto.OEEBySkuResponse.SKUOee>}
 */
proto.proto.OEEBySkuResponse.prototype.getSkuOeeList = function() {
  return /** @type{!Array<!proto.proto.OEEBySkuResponse.SKUOee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.OEEBySkuResponse.SKUOee, 2));
};


/**
 * @param {!Array<!proto.proto.OEEBySkuResponse.SKUOee>} value
 * @return {!proto.proto.OEEBySkuResponse} returns this
*/
proto.proto.OEEBySkuResponse.prototype.setSkuOeeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.OEEBySkuResponse.SKUOee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.OEEBySkuResponse.SKUOee}
 */
proto.proto.OEEBySkuResponse.prototype.addSkuOee = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.OEEBySkuResponse.SKUOee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.OEEBySkuResponse} returns this
 */
proto.proto.OEEBySkuResponse.prototype.clearSkuOeeList = function() {
  return this.setSkuOeeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PercentageLossResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PercentageLossResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PercentageLossResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PercentageLossResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    availabilityLossPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    performanceLossPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    qualityLossPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PercentageLossResponse}
 */
proto.proto.PercentageLossResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PercentageLossResponse;
  return proto.proto.PercentageLossResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PercentageLossResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PercentageLossResponse}
 */
proto.proto.PercentageLossResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvailabilityLossPercent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPerformanceLossPercent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQualityLossPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PercentageLossResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PercentageLossResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PercentageLossResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PercentageLossResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getAvailabilityLossPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getPerformanceLossPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getQualityLossPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.PercentageLossResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.PercentageLossResponse} returns this
*/
proto.proto.PercentageLossResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PercentageLossResponse} returns this
 */
proto.proto.PercentageLossResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PercentageLossResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double availability_loss_percent = 2;
 * @return {number}
 */
proto.proto.PercentageLossResponse.prototype.getAvailabilityLossPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PercentageLossResponse} returns this
 */
proto.proto.PercentageLossResponse.prototype.setAvailabilityLossPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double performance_loss_percent = 3;
 * @return {number}
 */
proto.proto.PercentageLossResponse.prototype.getPerformanceLossPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PercentageLossResponse} returns this
 */
proto.proto.PercentageLossResponse.prototype.setPerformanceLossPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double quality_loss_percent = 4;
 * @return {number}
 */
proto.proto.PercentageLossResponse.prototype.getQualityLossPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PercentageLossResponse} returns this
 */
proto.proto.PercentageLossResponse.prototype.setQualityLossPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LossTimeAnalysisResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LossTimeAnalysisResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LossTimeAnalysisResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LossTimeAnalysisResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    oeeLossTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    availabilityLossTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    performanceLossTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    qualityLossTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LossTimeAnalysisResponse}
 */
proto.proto.LossTimeAnalysisResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LossTimeAnalysisResponse;
  return proto.proto.LossTimeAnalysisResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LossTimeAnalysisResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LossTimeAnalysisResponse}
 */
proto.proto.LossTimeAnalysisResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOeeLossTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvailabilityLossTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPerformanceLossTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQualityLossTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LossTimeAnalysisResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LossTimeAnalysisResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LossTimeAnalysisResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LossTimeAnalysisResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getOeeLossTime();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAvailabilityLossTime();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getPerformanceLossTime();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getQualityLossTime();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.LossTimeAnalysisResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.LossTimeAnalysisResponse} returns this
*/
proto.proto.LossTimeAnalysisResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.LossTimeAnalysisResponse} returns this
 */
proto.proto.LossTimeAnalysisResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LossTimeAnalysisResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double oee_loss_time = 2;
 * @return {number}
 */
proto.proto.LossTimeAnalysisResponse.prototype.getOeeLossTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LossTimeAnalysisResponse} returns this
 */
proto.proto.LossTimeAnalysisResponse.prototype.setOeeLossTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double availability_loss_time = 3;
 * @return {number}
 */
proto.proto.LossTimeAnalysisResponse.prototype.getAvailabilityLossTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LossTimeAnalysisResponse} returns this
 */
proto.proto.LossTimeAnalysisResponse.prototype.setAvailabilityLossTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double performance_loss_time = 4;
 * @return {number}
 */
proto.proto.LossTimeAnalysisResponse.prototype.getPerformanceLossTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LossTimeAnalysisResponse} returns this
 */
proto.proto.LossTimeAnalysisResponse.prototype.setPerformanceLossTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double quality_loss_time = 5;
 * @return {number}
 */
proto.proto.LossTimeAnalysisResponse.prototype.getQualityLossTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LossTimeAnalysisResponse} returns this
 */
proto.proto.LossTimeAnalysisResponse.prototype.setQualityLossTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.OEEByLineResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.OEEByLineResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.OEEByLineResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.OEEByLineResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEByLineResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    lineOeeList: jspb.Message.toObjectList(msg.getLineOeeList(),
    proto.proto.OEEByLineResponse.LineOee.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.OEEByLineResponse}
 */
proto.proto.OEEByLineResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.OEEByLineResponse;
  return proto.proto.OEEByLineResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.OEEByLineResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.OEEByLineResponse}
 */
proto.proto.OEEByLineResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = new proto.proto.OEEByLineResponse.LineOee;
      reader.readMessage(value,proto.proto.OEEByLineResponse.LineOee.deserializeBinaryFromReader);
      msg.addLineOee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.OEEByLineResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.OEEByLineResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.OEEByLineResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEByLineResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getLineOeeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.OEEByLineResponse.LineOee.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.OEEByLineResponse.LineOee.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.OEEByLineResponse.LineOee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.OEEByLineResponse.LineOee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEByLineResponse.LineOee.toObject = function(includeInstance, msg) {
  var f, obj = {
    lineName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    oee: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.OEEByLineResponse.LineOee}
 */
proto.proto.OEEByLineResponse.LineOee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.OEEByLineResponse.LineOee;
  return proto.proto.OEEByLineResponse.LineOee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.OEEByLineResponse.LineOee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.OEEByLineResponse.LineOee}
 */
proto.proto.OEEByLineResponse.LineOee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.OEEByLineResponse.LineOee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.OEEByLineResponse.LineOee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.OEEByLineResponse.LineOee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.OEEByLineResponse.LineOee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLineName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOee();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string line_name = 1;
 * @return {string}
 */
proto.proto.OEEByLineResponse.LineOee.prototype.getLineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.OEEByLineResponse.LineOee} returns this
 */
proto.proto.OEEByLineResponse.LineOee.prototype.setLineName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double oee = 2;
 * @return {number}
 */
proto.proto.OEEByLineResponse.LineOee.prototype.getOee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.OEEByLineResponse.LineOee} returns this
 */
proto.proto.OEEByLineResponse.LineOee.prototype.setOee = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.OEEByLineResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.OEEByLineResponse} returns this
*/
proto.proto.OEEByLineResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.OEEByLineResponse} returns this
 */
proto.proto.OEEByLineResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.OEEByLineResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LineOee line_oee = 2;
 * @return {!Array<!proto.proto.OEEByLineResponse.LineOee>}
 */
proto.proto.OEEByLineResponse.prototype.getLineOeeList = function() {
  return /** @type{!Array<!proto.proto.OEEByLineResponse.LineOee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.OEEByLineResponse.LineOee, 2));
};


/**
 * @param {!Array<!proto.proto.OEEByLineResponse.LineOee>} value
 * @return {!proto.proto.OEEByLineResponse} returns this
*/
proto.proto.OEEByLineResponse.prototype.setLineOeeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.OEEByLineResponse.LineOee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.OEEByLineResponse.LineOee}
 */
proto.proto.OEEByLineResponse.prototype.addLineOee = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.OEEByLineResponse.LineOee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.OEEByLineResponse} returns this
 */
proto.proto.OEEByLineResponse.prototype.clearLineOeeList = function() {
  return this.setLineOeeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ProductionRateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ProductionRateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ProductionRateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProductionRateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    productionRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    targetRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ProductionRateResponse}
 */
proto.proto.ProductionRateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ProductionRateResponse;
  return proto.proto.ProductionRateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ProductionRateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ProductionRateResponse}
 */
proto.proto.ProductionRateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProductionRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ProductionRateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ProductionRateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ProductionRateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProductionRateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getProductionRate();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTargetRate();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.ProductionRateResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.ProductionRateResponse} returns this
*/
proto.proto.ProductionRateResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ProductionRateResponse} returns this
 */
proto.proto.ProductionRateResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ProductionRateResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double production_rate = 2;
 * @return {number}
 */
proto.proto.ProductionRateResponse.prototype.getProductionRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ProductionRateResponse} returns this
 */
proto.proto.ProductionRateResponse.prototype.setProductionRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double target_rate = 3;
 * @return {number}
 */
proto.proto.ProductionRateResponse.prototype.getTargetRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ProductionRateResponse} returns this
 */
proto.proto.ProductionRateResponse.prototype.setTargetRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ProductionQualityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ProductionQualityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ProductionQualityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProductionQualityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    actualQuantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    expectedQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    targetQuantity: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ProductionQualityResponse}
 */
proto.proto.ProductionQualityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ProductionQualityResponse;
  return proto.proto.ProductionQualityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ProductionQualityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ProductionQualityResponse}
 */
proto.proto.ProductionQualityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActualQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpectedQuantity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ProductionQualityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ProductionQualityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ProductionQualityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProductionQualityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getActualQuantity();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getExpectedQuantity();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTargetQuantity();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.ProductionQualityResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.ProductionQualityResponse} returns this
*/
proto.proto.ProductionQualityResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ProductionQualityResponse} returns this
 */
proto.proto.ProductionQualityResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ProductionQualityResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 actual_quantity = 2;
 * @return {number}
 */
proto.proto.ProductionQualityResponse.prototype.getActualQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ProductionQualityResponse} returns this
 */
proto.proto.ProductionQualityResponse.prototype.setActualQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 expected_quantity = 3;
 * @return {number}
 */
proto.proto.ProductionQualityResponse.prototype.getExpectedQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ProductionQualityResponse} returns this
 */
proto.proto.ProductionQualityResponse.prototype.setExpectedQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 target_quantity = 4;
 * @return {number}
 */
proto.proto.ProductionQualityResponse.prototype.getTargetQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ProductionQualityResponse} returns this
 */
proto.proto.ProductionQualityResponse.prototype.setTargetQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ProductiveTimeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ProductiveTimeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ProductiveTimeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProductiveTimeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    productiveTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    availableTime: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ProductiveTimeResponse}
 */
proto.proto.ProductiveTimeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ProductiveTimeResponse;
  return proto.proto.ProductiveTimeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ProductiveTimeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ProductiveTimeResponse}
 */
proto.proto.ProductiveTimeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProductiveTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAvailableTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ProductiveTimeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ProductiveTimeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ProductiveTimeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProductiveTimeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getProductiveTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAvailableTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.ProductiveTimeResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.ProductiveTimeResponse} returns this
*/
proto.proto.ProductiveTimeResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ProductiveTimeResponse} returns this
 */
proto.proto.ProductiveTimeResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ProductiveTimeResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 productive_time = 2;
 * @return {number}
 */
proto.proto.ProductiveTimeResponse.prototype.getProductiveTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ProductiveTimeResponse} returns this
 */
proto.proto.ProductiveTimeResponse.prototype.setProductiveTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 available_time = 3;
 * @return {number}
 */
proto.proto.ProductiveTimeResponse.prototype.getAvailableTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ProductiveTimeResponse} returns this
 */
proto.proto.ProductiveTimeResponse.prototype.setAvailableTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DowntimeDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DowntimeDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DowntimeDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DowntimeDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    totalDowntime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    unplannedDowntime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    plannedDowntime: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DowntimeDetailsResponse}
 */
proto.proto.DowntimeDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DowntimeDetailsResponse;
  return proto.proto.DowntimeDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DowntimeDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DowntimeDetailsResponse}
 */
proto.proto.DowntimeDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalDowntime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnplannedDowntime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlannedDowntime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DowntimeDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DowntimeDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DowntimeDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DowntimeDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotalDowntime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUnplannedDowntime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPlannedDowntime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.DowntimeDetailsResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.DowntimeDetailsResponse} returns this
*/
proto.proto.DowntimeDetailsResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DowntimeDetailsResponse} returns this
 */
proto.proto.DowntimeDetailsResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DowntimeDetailsResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 total_downtime = 2;
 * @return {number}
 */
proto.proto.DowntimeDetailsResponse.prototype.getTotalDowntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DowntimeDetailsResponse} returns this
 */
proto.proto.DowntimeDetailsResponse.prototype.setTotalDowntime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 unplanned_downtime = 3;
 * @return {number}
 */
proto.proto.DowntimeDetailsResponse.prototype.getUnplannedDowntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DowntimeDetailsResponse} returns this
 */
proto.proto.DowntimeDetailsResponse.prototype.setUnplannedDowntime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 planned_downtime = 4;
 * @return {number}
 */
proto.proto.DowntimeDetailsResponse.prototype.getPlannedDowntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DowntimeDetailsResponse} returns this
 */
proto.proto.DowntimeDetailsResponse.prototype.setPlannedDowntime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DowntimeReasonCodeDetailsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DowntimeReasonCodeDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DowntimeReasonCodeDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DowntimeReasonCodeDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DowntimeReasonCodeDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    reasoncodeDowntimeList: jspb.Message.toObjectList(msg.getReasoncodeDowntimeList(),
    proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DowntimeReasonCodeDetailsResponse}
 */
proto.proto.DowntimeReasonCodeDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DowntimeReasonCodeDetailsResponse;
  return proto.proto.DowntimeReasonCodeDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DowntimeReasonCodeDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DowntimeReasonCodeDetailsResponse}
 */
proto.proto.DowntimeReasonCodeDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = new proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime;
      reader.readMessage(value,proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.deserializeBinaryFromReader);
      msg.addReasoncodeDowntime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DowntimeReasonCodeDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DowntimeReasonCodeDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DowntimeReasonCodeDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DowntimeReasonCodeDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getReasoncodeDowntimeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    downtime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime}
 */
proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime;
  return proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime}
 */
proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDowntime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDowntime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string reason_code = 1;
 * @return {string}
 */
proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.prototype.getReasonCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime} returns this
 */
proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.prototype.setReasonCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 downtime = 2;
 * @return {number}
 */
proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.prototype.getDowntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime} returns this
 */
proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime.prototype.setDowntime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.DowntimeReasonCodeDetailsResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.DowntimeReasonCodeDetailsResponse} returns this
*/
proto.proto.DowntimeReasonCodeDetailsResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DowntimeReasonCodeDetailsResponse} returns this
 */
proto.proto.DowntimeReasonCodeDetailsResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DowntimeReasonCodeDetailsResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ReasonCodeDowntime reasoncode_downtime = 2;
 * @return {!Array<!proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime>}
 */
proto.proto.DowntimeReasonCodeDetailsResponse.prototype.getReasoncodeDowntimeList = function() {
  return /** @type{!Array<!proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime, 2));
};


/**
 * @param {!Array<!proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime>} value
 * @return {!proto.proto.DowntimeReasonCodeDetailsResponse} returns this
*/
proto.proto.DowntimeReasonCodeDetailsResponse.prototype.setReasoncodeDowntimeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime}
 */
proto.proto.DowntimeReasonCodeDetailsResponse.prototype.addReasoncodeDowntime = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.DowntimeReasonCodeDetailsResponse.ReasonCodeDowntime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DowntimeReasonCodeDetailsResponse} returns this
 */
proto.proto.DowntimeReasonCodeDetailsResponse.prototype.clearReasoncodeDowntimeList = function() {
  return this.setReasoncodeDowntimeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.MachineReasonDurationCountResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MachineReasonDurationCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MachineReasonDurationCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MachineReasonDurationCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineReasonDurationCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f),
    machineReasonDurationList: jspb.Message.toObjectList(msg.getMachineReasonDurationList(),
    proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MachineReasonDurationCountResponse}
 */
proto.proto.MachineReasonDurationCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MachineReasonDurationCountResponse;
  return proto.proto.MachineReasonDurationCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MachineReasonDurationCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MachineReasonDurationCountResponse}
 */
proto.proto.MachineReasonDurationCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    case 2:
      var value = new proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration;
      reader.readMessage(value,proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.deserializeBinaryFromReader);
      msg.addMachineReasonDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MachineReasonDurationCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MachineReasonDurationCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MachineReasonDurationCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineReasonDurationCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
  f = message.getMachineReasonDurationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.toObject = function(includeInstance, msg) {
  var f, obj = {
    machine: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reasoncodeDurationList: jspb.Message.toObjectList(msg.getReasoncodeDurationList(),
    proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration}
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration;
  return proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration}
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachine(value);
      break;
    case 2:
      var value = new proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount;
      reader.readMessage(value,proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.deserializeBinaryFromReader);
      msg.addReasoncodeDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachine();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReasoncodeDurationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    downtime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount}
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount;
  return proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount}
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDowntime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDowntime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string reason_code = 1;
 * @return {string}
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.prototype.getReasonCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount} returns this
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.prototype.setReasonCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 downtime = 2;
 * @return {number}
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.prototype.getDowntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount} returns this
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.prototype.setDowntime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 count = 3;
 * @return {number}
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount} returns this
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string machine = 1;
 * @return {string}
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.prototype.getMachine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration} returns this
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.prototype.setMachine = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ReasonCodeDowntimeCount reasoncode_duration = 2;
 * @return {!Array<!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount>}
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.prototype.getReasoncodeDurationList = function() {
  return /** @type{!Array<!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount, 2));
};


/**
 * @param {!Array<!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount>} value
 * @return {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration} returns this
*/
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.prototype.setReasoncodeDurationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount}
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.prototype.addReasoncodeDuration = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.ReasonCodeDowntimeCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration} returns this
 */
proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration.prototype.clearReasoncodeDurationList = function() {
  return this.setReasoncodeDurationList([]);
};


/**
 * optional GeneralResponse general_response = 1;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.MachineReasonDurationCountResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 1));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.MachineReasonDurationCountResponse} returns this
*/
proto.proto.MachineReasonDurationCountResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MachineReasonDurationCountResponse} returns this
 */
proto.proto.MachineReasonDurationCountResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MachineReasonDurationCountResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated MachineReasonDuration machine_reason_duration = 2;
 * @return {!Array<!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration>}
 */
proto.proto.MachineReasonDurationCountResponse.prototype.getMachineReasonDurationList = function() {
  return /** @type{!Array<!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration, 2));
};


/**
 * @param {!Array<!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration>} value
 * @return {!proto.proto.MachineReasonDurationCountResponse} returns this
*/
proto.proto.MachineReasonDurationCountResponse.prototype.setMachineReasonDurationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration}
 */
proto.proto.MachineReasonDurationCountResponse.prototype.addMachineReasonDuration = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.MachineReasonDurationCountResponse.MachineReasonDuration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.MachineReasonDurationCountResponse} returns this
 */
proto.proto.MachineReasonDurationCountResponse.prototype.clearMachineReasonDurationList = function() {
  return this.setMachineReasonDurationList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.TimestampSet.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.TimestampSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.TimestampSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.TimestampSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.TimestampSet}
 */
proto.proto.TimestampSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.TimestampSet;
  return proto.proto.TimestampSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.TimestampSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.TimestampSet}
 */
proto.proto.TimestampSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.TimestampSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.TimestampSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.TimestampSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.TimestampSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.proto.TimestampSet.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.TimestampSet} returns this
 */
proto.proto.TimestampSet.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.proto.TimestampSet.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.TimestampSet} returns this
 */
proto.proto.TimestampSet.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.NodeTimestampSetPair.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.NodeTimestampSetPair.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.NodeTimestampSetPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.NodeTimestampSetPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.NodeTimestampSetPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timestampSetList: jspb.Message.toObjectList(msg.getTimestampSetList(),
    proto.proto.TimestampSet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.NodeTimestampSetPair}
 */
proto.proto.NodeTimestampSetPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.NodeTimestampSetPair;
  return proto.proto.NodeTimestampSetPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.NodeTimestampSetPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.NodeTimestampSetPair}
 */
proto.proto.NodeTimestampSetPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNodeId(value);
      break;
    case 2:
      var value = new proto.proto.TimestampSet;
      reader.readMessage(value,proto.proto.TimestampSet.deserializeBinaryFromReader);
      msg.addTimestampSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.NodeTimestampSetPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.NodeTimestampSetPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.NodeTimestampSetPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.NodeTimestampSetPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTimestampSetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.TimestampSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 node_id = 1;
 * @return {number}
 */
proto.proto.NodeTimestampSetPair.prototype.getNodeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.NodeTimestampSetPair} returns this
 */
proto.proto.NodeTimestampSetPair.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated TimestampSet timestamp_set = 2;
 * @return {!Array<!proto.proto.TimestampSet>}
 */
proto.proto.NodeTimestampSetPair.prototype.getTimestampSetList = function() {
  return /** @type{!Array<!proto.proto.TimestampSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.TimestampSet, 2));
};


/**
 * @param {!Array<!proto.proto.TimestampSet>} value
 * @return {!proto.proto.NodeTimestampSetPair} returns this
*/
proto.proto.NodeTimestampSetPair.prototype.setTimestampSetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.TimestampSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.TimestampSet}
 */
proto.proto.NodeTimestampSetPair.prototype.addTimestampSet = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.TimestampSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.NodeTimestampSetPair} returns this
 */
proto.proto.NodeTimestampSetPair.prototype.clearTimestampSetList = function() {
  return this.setTimestampSetList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.TotalCountRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.TotalCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.TotalCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.TotalCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.TotalCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeTimestampSetPairList: jspb.Message.toObjectList(msg.getNodeTimestampSetPairList(),
    proto.proto.NodeTimestampSetPair.toObject, includeInstance),
    sku: jspb.Message.getFieldWithDefault(msg, 2, 0),
    generalRequest: (f = msg.getGeneralRequest()) && common_Commons_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.TotalCountRequest}
 */
proto.proto.TotalCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.TotalCountRequest;
  return proto.proto.TotalCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.TotalCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.TotalCountRequest}
 */
proto.proto.TotalCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.NodeTimestampSetPair;
      reader.readMessage(value,proto.proto.NodeTimestampSetPair.deserializeBinaryFromReader);
      msg.addNodeTimestampSetPair(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSku(value);
      break;
    case 3:
      var value = new common_Commons_pb.GeneralRequest;
      reader.readMessage(value,common_Commons_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.TotalCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.TotalCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.TotalCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.TotalCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeTimestampSetPairList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.NodeTimestampSetPair.serializeBinaryToWriter
    );
  }
  f = message.getSku();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_Commons_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NodeTimestampSetPair node_timestamp_set_pair = 1;
 * @return {!Array<!proto.proto.NodeTimestampSetPair>}
 */
proto.proto.TotalCountRequest.prototype.getNodeTimestampSetPairList = function() {
  return /** @type{!Array<!proto.proto.NodeTimestampSetPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.NodeTimestampSetPair, 1));
};


/**
 * @param {!Array<!proto.proto.NodeTimestampSetPair>} value
 * @return {!proto.proto.TotalCountRequest} returns this
*/
proto.proto.TotalCountRequest.prototype.setNodeTimestampSetPairList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.NodeTimestampSetPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.NodeTimestampSetPair}
 */
proto.proto.TotalCountRequest.prototype.addNodeTimestampSetPair = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.NodeTimestampSetPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.TotalCountRequest} returns this
 */
proto.proto.TotalCountRequest.prototype.clearNodeTimestampSetPairList = function() {
  return this.setNodeTimestampSetPairList([]);
};


/**
 * optional int32 sku = 2;
 * @return {number}
 */
proto.proto.TotalCountRequest.prototype.getSku = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.TotalCountRequest} returns this
 */
proto.proto.TotalCountRequest.prototype.setSku = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional GeneralRequest general_request = 3;
 * @return {?proto.proto.GeneralRequest}
 */
proto.proto.TotalCountRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.proto.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralRequest, 3));
};


/**
 * @param {?proto.proto.GeneralRequest|undefined} value
 * @return {!proto.proto.TotalCountRequest} returns this
*/
proto.proto.TotalCountRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.TotalCountRequest} returns this
 */
proto.proto.TotalCountRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.TotalCountRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.TotalCountResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.TotalCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.TotalCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.TotalCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.TotalCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    countInfoList: jspb.Message.toObjectList(msg.getCountInfoList(),
    proto.proto.TotalCountResponse.CountInfo.toObject, includeInstance),
    generalResponse: (f = msg.getGeneralResponse()) && common_Commons_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.TotalCountResponse}
 */
proto.proto.TotalCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.TotalCountResponse;
  return proto.proto.TotalCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.TotalCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.TotalCountResponse}
 */
proto.proto.TotalCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.TotalCountResponse.CountInfo;
      reader.readMessage(value,proto.proto.TotalCountResponse.CountInfo.deserializeBinaryFromReader);
      msg.addCountInfo(value);
      break;
    case 2:
      var value = new common_Commons_pb.GeneralResponse;
      reader.readMessage(value,common_Commons_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.TotalCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.TotalCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.TotalCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.TotalCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.TotalCountResponse.CountInfo.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_Commons_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.TotalCountResponse.CountInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.TotalCountResponse.CountInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.TotalCountResponse.CountInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.TotalCountResponse.CountInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.TotalCountResponse.CountInfo}
 */
proto.proto.TotalCountResponse.CountInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.TotalCountResponse.CountInfo;
  return proto.proto.TotalCountResponse.CountInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.TotalCountResponse.CountInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.TotalCountResponse.CountInfo}
 */
proto.proto.TotalCountResponse.CountInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNodeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.TotalCountResponse.CountInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.TotalCountResponse.CountInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.TotalCountResponse.CountInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.TotalCountResponse.CountInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 node_id = 1;
 * @return {number}
 */
proto.proto.TotalCountResponse.CountInfo.prototype.getNodeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.TotalCountResponse.CountInfo} returns this
 */
proto.proto.TotalCountResponse.CountInfo.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.proto.TotalCountResponse.CountInfo.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.TotalCountResponse.CountInfo} returns this
 */
proto.proto.TotalCountResponse.CountInfo.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated CountInfo count_info = 1;
 * @return {!Array<!proto.proto.TotalCountResponse.CountInfo>}
 */
proto.proto.TotalCountResponse.prototype.getCountInfoList = function() {
  return /** @type{!Array<!proto.proto.TotalCountResponse.CountInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.TotalCountResponse.CountInfo, 1));
};


/**
 * @param {!Array<!proto.proto.TotalCountResponse.CountInfo>} value
 * @return {!proto.proto.TotalCountResponse} returns this
*/
proto.proto.TotalCountResponse.prototype.setCountInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.TotalCountResponse.CountInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.TotalCountResponse.CountInfo}
 */
proto.proto.TotalCountResponse.prototype.addCountInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.TotalCountResponse.CountInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.TotalCountResponse} returns this
 */
proto.proto.TotalCountResponse.prototype.clearCountInfoList = function() {
  return this.setCountInfoList([]);
};


/**
 * optional GeneralResponse general_response = 2;
 * @return {?proto.proto.GeneralResponse}
 */
proto.proto.TotalCountResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.proto.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Commons_pb.GeneralResponse, 2));
};


/**
 * @param {?proto.proto.GeneralResponse|undefined} value
 * @return {!proto.proto.TotalCountResponse} returns this
*/
proto.proto.TotalCountResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.TotalCountResponse} returns this
 */
proto.proto.TotalCountResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.TotalCountResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.proto);
