export const BackgroundColor = (Severity: string) => {
  let bgColor: string;
  switch (Severity) {
    case "Major":
      bgColor = "#FFF2E8";
      break;
    case "Minor":
      bgColor = "#FFF7E6";
      break;
    case "Critical":
      bgColor = "#FFF1F0";
      break;
    case "Warning":
      bgColor = "#FFFBE6";
      break;
    default:
      return "";
  }
  return bgColor;
};
export const TextColor = (Severity: string) => {
  let color: string;

  switch (Severity) {
    case "Major":
      color = "#fa541c";
      break;
    case "Minor":
      color = "#fa8c16";
      break;
    case "Critical":
      color = "#f5222d";
      break;
    case "Warning":
      color = "#faad14";
      break;
    case "EVENT":
      color = "#FAAD00";
      break;
    case "WARNING":
      color = "#FAAD14";
      break;
    case "MINOR":
      color = "#FA8C16";
      break;
    case "MAJOR":
      color = "#FA541C";
      break;
    case "CRITICAL":
      color = "#F5222D";
      break;
    default:
      return "";
  }
  return color;
};
