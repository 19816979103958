// source: service/Filter.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_Common_pb = require('../common/Common_pb.js');
goog.object.extend(proto, common_Common_pb);
var model_Filter_pb = require('../model/Filter_pb.js');
goog.object.extend(proto, model_Filter_pb);
goog.exportSymbol('proto.upl_sandbach.BatchResponse', null, global);
goog.exportSymbol('proto.upl_sandbach.DropdownLineAndMachineResponse', null, global);
goog.exportSymbol('proto.upl_sandbach.DropdownSKURequest', null, global);
goog.exportSymbol('proto.upl_sandbach.DropdownSKUResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.DropdownLineAndMachineResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.DropdownLineAndMachineResponse.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.DropdownLineAndMachineResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.DropdownLineAndMachineResponse.displayName = 'proto.upl_sandbach.DropdownLineAndMachineResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.DropdownSKURequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.DropdownSKURequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.DropdownSKURequest.displayName = 'proto.upl_sandbach.DropdownSKURequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.DropdownSKUResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.DropdownSKUResponse.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.DropdownSKUResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.DropdownSKUResponse.displayName = 'proto.upl_sandbach.DropdownSKUResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.BatchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.BatchResponse.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.BatchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.BatchResponse.displayName = 'proto.upl_sandbach.BatchResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.DropdownLineAndMachineResponse.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.DropdownLineAndMachineResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.DropdownLineAndMachineResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.DropdownLineAndMachineResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.DropdownLineAndMachineResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    linesList: jspb.Message.toObjectList(msg.getLinesList(),
    model_Filter_pb.Line.toObject, includeInstance),
    generalResponse: (f = msg.getGeneralResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.DropdownLineAndMachineResponse}
 */
proto.upl_sandbach.DropdownLineAndMachineResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.DropdownLineAndMachineResponse;
  return proto.upl_sandbach.DropdownLineAndMachineResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.DropdownLineAndMachineResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.DropdownLineAndMachineResponse}
 */
proto.upl_sandbach.DropdownLineAndMachineResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_Filter_pb.Line;
      reader.readMessage(value,model_Filter_pb.Line.deserializeBinaryFromReader);
      msg.addLines(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.DropdownLineAndMachineResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.DropdownLineAndMachineResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.DropdownLineAndMachineResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.DropdownLineAndMachineResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      model_Filter_pb.Line.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Line lines = 10;
 * @return {!Array<!proto.upl_sandbach.Line>}
 */
proto.upl_sandbach.DropdownLineAndMachineResponse.prototype.getLinesList = function() {
  return /** @type{!Array<!proto.upl_sandbach.Line>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_Filter_pb.Line, 10));
};


/**
 * @param {!Array<!proto.upl_sandbach.Line>} value
 * @return {!proto.upl_sandbach.DropdownLineAndMachineResponse} returns this
*/
proto.upl_sandbach.DropdownLineAndMachineResponse.prototype.setLinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.upl_sandbach.Line=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.Line}
 */
proto.upl_sandbach.DropdownLineAndMachineResponse.prototype.addLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.upl_sandbach.Line, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.DropdownLineAndMachineResponse} returns this
 */
proto.upl_sandbach.DropdownLineAndMachineResponse.prototype.clearLinesList = function() {
  return this.setLinesList([]);
};


/**
 * optional GeneralResponse general_response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.DropdownLineAndMachineResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.DropdownLineAndMachineResponse} returns this
*/
proto.upl_sandbach.DropdownLineAndMachineResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.DropdownLineAndMachineResponse} returns this
 */
proto.upl_sandbach.DropdownLineAndMachineResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.DropdownLineAndMachineResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.DropdownSKURequest.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.DropdownSKURequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.DropdownSKURequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.DropdownSKURequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    line: (f = msg.getLine()) && common_Common_pb.DropDownData.toObject(includeInstance, f),
    machine: (f = msg.getMachine()) && common_Common_pb.DropDownData.toObject(includeInstance, f),
    generalRequest: (f = msg.getGeneralRequest()) && common_Common_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.DropdownSKURequest}
 */
proto.upl_sandbach.DropdownSKURequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.DropdownSKURequest;
  return proto.upl_sandbach.DropdownSKURequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.DropdownSKURequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.DropdownSKURequest}
 */
proto.upl_sandbach.DropdownSKURequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new common_Common_pb.DropDownData;
      reader.readMessage(value,common_Common_pb.DropDownData.deserializeBinaryFromReader);
      msg.setLine(value);
      break;
    case 20:
      var value = new common_Common_pb.DropDownData;
      reader.readMessage(value,common_Common_pb.DropDownData.deserializeBinaryFromReader);
      msg.setMachine(value);
      break;
    case 30:
      var value = new common_Common_pb.GeneralRequest;
      reader.readMessage(value,common_Common_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.DropdownSKURequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.DropdownSKURequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.DropdownSKURequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.DropdownSKURequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLine();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_Common_pb.DropDownData.serializeBinaryToWriter
    );
  }
  f = message.getMachine();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.DropDownData.serializeBinaryToWriter
    );
  }
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      common_Common_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional DropDownData line = 10;
 * @return {?proto.upl_sandbach.DropDownData}
 */
proto.upl_sandbach.DropdownSKURequest.prototype.getLine = function() {
  return /** @type{?proto.upl_sandbach.DropDownData} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.DropDownData, 10));
};


/**
 * @param {?proto.upl_sandbach.DropDownData|undefined} value
 * @return {!proto.upl_sandbach.DropdownSKURequest} returns this
*/
proto.upl_sandbach.DropdownSKURequest.prototype.setLine = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.DropdownSKURequest} returns this
 */
proto.upl_sandbach.DropdownSKURequest.prototype.clearLine = function() {
  return this.setLine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.DropdownSKURequest.prototype.hasLine = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional DropDownData machine = 20;
 * @return {?proto.upl_sandbach.DropDownData}
 */
proto.upl_sandbach.DropdownSKURequest.prototype.getMachine = function() {
  return /** @type{?proto.upl_sandbach.DropDownData} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.DropDownData, 20));
};


/**
 * @param {?proto.upl_sandbach.DropDownData|undefined} value
 * @return {!proto.upl_sandbach.DropdownSKURequest} returns this
*/
proto.upl_sandbach.DropdownSKURequest.prototype.setMachine = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.DropdownSKURequest} returns this
 */
proto.upl_sandbach.DropdownSKURequest.prototype.clearMachine = function() {
  return this.setMachine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.DropdownSKURequest.prototype.hasMachine = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional GeneralRequest general_request = 30;
 * @return {?proto.upl_sandbach.GeneralRequest}
 */
proto.upl_sandbach.DropdownSKURequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.upl_sandbach.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralRequest, 30));
};


/**
 * @param {?proto.upl_sandbach.GeneralRequest|undefined} value
 * @return {!proto.upl_sandbach.DropdownSKURequest} returns this
*/
proto.upl_sandbach.DropdownSKURequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.DropdownSKURequest} returns this
 */
proto.upl_sandbach.DropdownSKURequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.DropdownSKURequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 30) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.DropdownSKUResponse.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.DropdownSKUResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.DropdownSKUResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.DropdownSKUResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.DropdownSKUResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    skusList: jspb.Message.toObjectList(msg.getSkusList(),
    common_Common_pb.DropDownData.toObject, includeInstance),
    generalResponse: (f = msg.getGeneralResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.DropdownSKUResponse}
 */
proto.upl_sandbach.DropdownSKUResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.DropdownSKUResponse;
  return proto.upl_sandbach.DropdownSKUResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.DropdownSKUResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.DropdownSKUResponse}
 */
proto.upl_sandbach.DropdownSKUResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new common_Common_pb.DropDownData;
      reader.readMessage(value,common_Common_pb.DropDownData.deserializeBinaryFromReader);
      msg.addSkus(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.DropdownSKUResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.DropdownSKUResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.DropdownSKUResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.DropdownSKUResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      common_Common_pb.DropDownData.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DropDownData skus = 10;
 * @return {!Array<!proto.upl_sandbach.DropDownData>}
 */
proto.upl_sandbach.DropdownSKUResponse.prototype.getSkusList = function() {
  return /** @type{!Array<!proto.upl_sandbach.DropDownData>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_Common_pb.DropDownData, 10));
};


/**
 * @param {!Array<!proto.upl_sandbach.DropDownData>} value
 * @return {!proto.upl_sandbach.DropdownSKUResponse} returns this
*/
proto.upl_sandbach.DropdownSKUResponse.prototype.setSkusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.upl_sandbach.DropDownData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.DropDownData}
 */
proto.upl_sandbach.DropdownSKUResponse.prototype.addSkus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.upl_sandbach.DropDownData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.DropdownSKUResponse} returns this
 */
proto.upl_sandbach.DropdownSKUResponse.prototype.clearSkusList = function() {
  return this.setSkusList([]);
};


/**
 * optional GeneralResponse general_response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.DropdownSKUResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.DropdownSKUResponse} returns this
*/
proto.upl_sandbach.DropdownSKUResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.DropdownSKUResponse} returns this
 */
proto.upl_sandbach.DropdownSKUResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.DropdownSKUResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.BatchResponse.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.BatchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.BatchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.BatchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchesList: jspb.Message.toObjectList(msg.getBatchesList(),
    model_Filter_pb.Batch.toObject, includeInstance),
    generalResponse: (f = msg.getGeneralResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.BatchResponse}
 */
proto.upl_sandbach.BatchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.BatchResponse;
  return proto.upl_sandbach.BatchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.BatchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.BatchResponse}
 */
proto.upl_sandbach.BatchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_Filter_pb.Batch;
      reader.readMessage(value,model_Filter_pb.Batch.deserializeBinaryFromReader);
      msg.addBatches(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.BatchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.BatchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.BatchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      model_Filter_pb.Batch.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Batch batches = 10;
 * @return {!Array<!proto.upl_sandbach.Batch>}
 */
proto.upl_sandbach.BatchResponse.prototype.getBatchesList = function() {
  return /** @type{!Array<!proto.upl_sandbach.Batch>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_Filter_pb.Batch, 10));
};


/**
 * @param {!Array<!proto.upl_sandbach.Batch>} value
 * @return {!proto.upl_sandbach.BatchResponse} returns this
*/
proto.upl_sandbach.BatchResponse.prototype.setBatchesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.upl_sandbach.Batch=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.Batch}
 */
proto.upl_sandbach.BatchResponse.prototype.addBatches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.upl_sandbach.Batch, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.BatchResponse} returns this
 */
proto.upl_sandbach.BatchResponse.prototype.clearBatchesList = function() {
  return this.setBatchesList([]);
};


/**
 * optional GeneralResponse general_response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.BatchResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.BatchResponse} returns this
*/
proto.upl_sandbach.BatchResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.BatchResponse} returns this
 */
proto.upl_sandbach.BatchResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.BatchResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};


goog.object.extend(exports, proto.upl_sandbach);
