import { Formik } from "formik";
import { Form, Select, Input, ResetButton, SubmitButton } from "formik-antd";
import "./index.scss";
import * as Yup from 'yup';
import { assignReasourceCodeToResource, getReasonCodeCategories } from "store/actions/reasonCodeManagement";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";


const ReasonCodeForm: any = (props: any) => {
  const { metric, startTime, line } = props;
  const categoryList = useSelector((state: any) => state.reasonCode.reasonCodeCategoryList);
  const reasonCodeFactor = useSelector((state: any) => state.reasonCode.reasonCodeFactor);
  const reasonCodeDetailsDropdown = useSelector((state: any) => state.reasonCode.reasonCodeDetailsDropdown);
  const { Option } = Select;
  const dispatch = useDispatch();
  const [reasonCodeFactorList, setReasonCodeFactorList] = useState<any>(null);
  const [reasoncodeList, setReasonCodeList] = useState<any>(null);


  useEffect(() => {
    dispatch(getReasonCodeCategories());
  }, [dispatch])

  const onChange = (value: string) => {
    if (reasonCodeFactor && reasonCodeFactor[value]) {
      setReasonCodeFactorList(reasonCodeFactor[value]);
    } else {
      setReasonCodeFactorList([]);
    }
  }

  const onChangeFactor = (value: string) => {
    if (reasonCodeDetailsDropdown && reasonCodeDetailsDropdown[value]) {
      setReasonCodeList(reasonCodeDetailsDropdown[value]);
    } else {
      setReasonCodeList([]);
    }
  }

  //Note:Replace err message and conditions accordingly
  const SignupSchema = Yup.object().shape({
    reasonCodeCategory: Yup.string()
      .required("Required"),
    reasonCodeFactor: Yup.string()
      .required("Required"),
  });

  return (
    <>
      <Formik
        initialValues={{
          batchNumber: "",
          processOrder: "",
          productSKU: "",
          fillingAndPackingLine: "",
          startTime: "",
          reasonCodeCategory: "",
          reasonCodeFactor: "",
          reasonCode: ""
        }}
        validationSchema={SignupSchema}
        //Note: Implement on submit handler
        onSubmit={(values, { setSubmitting }) => {
          const { reasonCode } = values;
          if (reasonCode) {
            dispatch(assignReasourceCodeToResource({ reasonCodeUuid: reasonCode, equipmentId: line.key }));
            setSubmitting(false);
          }
        }}
        render={(formik: any) => (
          <Form>
            <div className="reason-code-form">
              <div className="metrics-data-card">
                <div className="title">Batch No</div>
                <Form.Item
                  name="batchNumber"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="batchNumber" value={metric && metric[0].metricsValue} disabled />
                </Form.Item>
              </div>
              <div className="metrics-data-card">
                <div className="title">Process Order</div>
                <Form.Item
                  name="processOrder"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="processOrder" value={metric && metric[1].metricsValue} disabled />
                </Form.Item>
              </div>
              <div className="metrics-data-card">
                <div className="title">Product SKU</div>
                <Form.Item
                  name="productSKU"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="productSKU" value={metric && metric[2].metricsValue} disabled />
                </Form.Item>
              </div>

              <div className="metrics-data-card">
                <div className="title">Filling and Packing Line</div>
                <Form.Item
                  name="fillingAndPackingLine"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="fillingAndPackingLine" value={line.title} disabled />
                </Form.Item>
              </div>
              <div className="metrics-data-card">
                <div className="title">Start Time</div>
                <Form.Item
                  name="startTime"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="startTime" value={startTime} disabled />
                </Form.Item>
              </div>
            </div>

            <div className="reason-code-metrics-container">
              <div className="metrics-data-card">
                <div className="title">Reason Code Category</div>
                <Form.Item
                  name="reasonCodeCategory"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Select
                    name="reasonCodeCategory"
                    showSearch
                    placeholder="Select a Category"
                    optionFilterProp="children"
                    onChange={onChange}
            
                    filterOption={(input: any, option: any) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {categoryList.map((item: any) => {
                      return <Option value={item.value}>{item.category}</Option>
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="metrics-data-card">
                <div className="title">Reason Code Factor</div>
                <Form.Item
                  name="reasonCodeFactor"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Select
                    name="reasonCodeFactor"
                    showSearch
                    placeholder="Select Reason Code Factor"
                    optionFilterProp="children"
                    onChange={onChangeFactor}
            
                    filterOption={(input: any, option: any) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {reasonCodeFactorList && reasonCodeFactorList.length &&
                      reasonCodeFactorList.map((item: any) => {
                        return <Option value={item.value}>{item.category}</Option>
                      })}
                  </Select>
                </Form.Item>
              </div>
              <div className="metrics-data-card">
                <div className="title">Reason Code</div>
                <Form.Item
                  name="reasonCode"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Select
                    name="reasonCode"
                    showSearch
                    placeholder="Select Reason Code"
                    optionFilterProp="children"
                    onChange={onChange}
            
                    filterOption={(input: any, option: any) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {reasoncodeList && reasoncodeList.length &&
                      reasoncodeList.map((item: any) => {
                        return <Option value={item.value}>{item.category}</Option>
                      })}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="reason-code-metrics-button-container">
              <ResetButton disabled={false}>Reset</ResetButton>
              <SubmitButton type="primary" disabled={false}>
                Submit
              </SubmitButton>
            </div>
          </Form>
        )}
      />
    </>
  );
};

export default ReasonCodeForm;
