import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./sagas";
import rootReducer from "./reducers/root";
import reasonCodeReducer from "./reducers/reasoncode";
import dataTrendingReducer from "./reducers/dataTrending.reducer";
import alarmsEventsReducer from "./reducers/alarmsEvents";
import operatorReducer from "./reducers/operator";
import productionOverviewReducer from "./reducers/productionOverview";
import lineOverviewReducer from "./reducers/lineOverviewReducer";
import runtimeAnalysisReducer from "./reducers/runtimeAnalysisReducer";
import oeeDashboardReducer from './reducers/oeeDashboardReducer'
import ProcessOrderSummary from "./reducers/processOrderSummaryReducer";

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: {
    root: rootReducer,
    reasonCode: reasonCodeReducer,
    dataVisualizationReducer: dataTrendingReducer,
    alarmsEvents: alarmsEventsReducer,
    operator: operatorReducer,
    lineOverview: lineOverviewReducer,
    productionOverview: productionOverviewReducer,
    runtimeAnalysis: runtimeAnalysisReducer,
    oeeDashboard: oeeDashboardReducer,
    processOrderSummary:ProcessOrderSummary
  },
  middleware: [sagaMiddleware],
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleware.run(rootSaga);