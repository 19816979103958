import { TreeSelect } from "antd";
import "./index.scss";
import { ReactComponent as DownArrow } from "assets/icons/dropdownIcon.svg";
import { useSelector } from "react-redux";

export const CustomDropdownTree = (props: any) => {
  const onChange = (newValue: string, label: any) => {
    props.setSearchText(newValue);
    props.setValue(label);
    props.setNodeId(newValue);
  };
  props.setNodeId(props.searchText);
  const handleSelect = (selectedKeys, info) => { };
  const filterTreeData = useSelector((state: any) => state.root.filterTree);
  return (
    <TreeSelect
      showSearch
      style={{
        width: "336px",
        height: "30px",
      }}
      value={props.searchText}
      dropdownStyle={{
        maxHeight: 400,
        overflow: "auto",
      }}
      placeholder="Select Line/Machine"
      allowClear
      onSelect={handleSelect}
      treeDefaultExpandAll
      onChange={onChange}
      treeCheckable={true}
      showArrow
      suffixIcon={<DownArrow />}
      maxTagCount={1}
      defaultValue={props.searchText}
      treeData={filterTreeData?.children ? filterTreeData.children : []}
    />
  );
};
