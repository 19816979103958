import { ActiveAlarmCard } from "components/ActiveAlarmTable";
import ChartContainer from "components/ChartContainer";
import GuageWithHand from "components/Charts/GaugeWithHand";
import Guage from "components/Charts/Guage";
import Pareto from "components/Charts/Pareto";
import { DropdownFilterComponent } from "components/DropdownFilterComponent";
import ContentHeader from "components/ContentHeader";
import MetricsCard from "components/MetricsCard";
import ProgressBar from "components/ProgressBar";
import "./index.scss";
import { UploadExcel } from "components/upload-excel";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPPAllBatches,
  getProductionOverviewAlarmsData,
  getProductionOverviewAssetUtilizationData,
  getProductionOverviewAverageCycleTimeData,
  getProductionOverviewBatchData,
  getProductionOverviewCountData,
  getProductionOverviewMachineStatus,
  getProductionOverviewOeeData,
  getProductionOverviewPackagingData,
  getProductionOverviewParetoData,
  getProductionOverviewTableData,
} from "store/actions/productionOverview";
import { SolidGuage } from "components/Charts/SolidGuage/SolidGuage";
import moment from "moment";
import { milliToMins, millisToMinutesAndSeconds } from "utils/milliToMinutes";
import { milliToHrs, milliToMachineHrs } from "utils/milliToHrs";
import { getUrl } from "config/apiconfig";
import { Loader } from "components/loader";
import { Spin } from "antd";
import { message } from "antd";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import autoTable from "jspdf-autotable";
import {
  allTreeNodeNameIdMap,
  allTreeNodeTitle,
  dateConverter,
  findNestedObj,
  machineKeyToMachineName,
  machineKeyToStatus,
} from "utils/common";
import UplLogo from "assets/images/uplLogo.png";
import ProductionMetrics from "components/ProductionMetrics";
import { useInterval } from "utils/pollingHook";

const ProductionOverview: React.FC = () => {
  const [uploadDialog, setUploadDialog] = useState<boolean>(false);

  const dropdownFilter = (value: string) => {
    setUploadDialog(true);
  };

  const closeUploadDialog = () => {
    setUploadDialog(false);
  };
  const selectedNodeIds: any = useSelector(
    (state: any) => state.root.filterValues.nodeId
  );
  const [payload, setPayload] = useState<any>({
    lineObject: useSelector((state: any) => state.root.filterValues.lineObject),
    nodeId: useSelector((state: any) => state.root.filterValues.nodeId),
    startTime: useSelector((state: any) => state.root.filterValues.startTime),
    endTime: useSelector((state: any) => state.root.filterValues.endTime),
    severity: useSelector((state: any) => state.root.filterValues.severity),
    batches: useSelector((state: any) => state.root.filterValues.batches),
    sku: useSelector((state: any) => state.root.filterValues.sku),
  });

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const filterFlag = useSelector((state: any) => state.root.filterFlag);
  useEffect(() => {
    if (selectedNodeIds.length > 0) {
      setPayload((payload) => ({
        ...payload,
        nodeId: selectedNodeIds,
      }));
    }
  }, [selectedNodeIds]);
  useEffect(() => {
    setTimeout(() => {
      dispatch(getProductionOverviewPackagingData({ ...payload, batches }));
      dispatch(getProductionOverviewCountData({ ...payload, batches }));
      dispatch(getProductionOverviewBatchData({ ...payload, batches }));
      dispatch(getProductionOverviewParetoData({ ...payload, batches, page }));
      dispatch(getProductionOverviewTableData({ ...payload, batches, page }));
      dispatch(getProductionOverviewOeeData({ ...payload, batches }));
      dispatch(getProductionOverviewMachineStatus());
      dispatch(
        getProductionOverviewAverageCycleTimeData({
          ...payload,
          batches,
        })
      );
      dispatch(
        getProductionOverviewAssetUtilizationData({
          ...payload,
          batches,
        })
      );
    }, 100);
  }, [payload]);

  useEffect(() => {
    dispatch(getProductionOverviewTableData({ ...payload, batches, page }));
  }, [page, payload]);

  useInterval(() => {
    dispatch(getProductionOverviewMachineStatus());
  }, 10000);

  const productionOverviewBatchCountMetrics = useSelector(
    (state: any) => state.productionOverview.productionOverviewBatchCountMetrics
  );
  const productionOverviewBatchDetailsMetrics = useSelector(
    (state: any) =>
      state.productionOverview.productionOverviewBatchDetailsMetrics
  );
  const productionOverviewActiveAlarmData = useSelector(
    (state: any) => state.productionOverview.productionOverviewActiveAlarmData
  );
  const productionOverviewOeeData = useSelector(
    (state: any) => state.productionOverview.productionOverviewOeeData
  );
  const productionOverviewAverageCycleTimeData = useSelector(
    (state: any) =>
      state.productionOverview.productionOverviewAverageCycleTimeData
  );

  const productionOverviewAssetUtilizationPercentage = useSelector(
    (state: any) =>
      state.productionOverview.productionAssetUtitlization_percentage
  );

  const productionOverviewAssetUtilizationRuntime = useSelector(
    (state: any) => state.productionOverview.productionAssetUtitlization_runtime
  );

  const productionOverviewAssetUtilizationAvailableTime = useSelector(
    (state: any) =>
      state.productionOverview.productionAssetUtitlization_availableTime
  );

  const productionOverviewRowCount = useSelector(
    (state: any) =>
      state.productionOverview.productionOverviewActiveAlarmData
        .productionOverviewRowCount
  );
  const productionOverviewLoader = useSelector(
    (state: any) => state.productionOverview.productionOverviewBatchLoader
  );

  const packagingData = useSelector(
    (state: any) => state.productionOverview.packagingData
  );

  const machineStatusData = useSelector(
    (state: any) => state.productionOverview.machineStatus
  );

  const filterTreeData = useSelector((state: any) => state.root.filterTree);
  const [packagingDetails, setPackagingDetails] = useState<any>({
    carryForwardBottles: 0,
    leftOverBottles: 0,
    totalCaseCount: 0,
    carryForwardCases: 0,
    totalFinishedPallets: 0,
    totalUnfinishedPallets: 0,
    leftOverCases: 0,
    success: false,
  });
  const [
    productionOverviewBatchCountMetricsDetails,
    setProductionOverviewBatchCountMetricsDetails,
  ] = useState({
    rejectCount: 0,
    goodCount: 0,
    wipCount: 0,
    totalCount: 0,
    success: false,
  });
  const metricsDataFormatter = () => {
    let formattedArray: any = [];
    formattedArray.push({
      metricsName: "Process Order",
      metricsValue: productionOverviewBatchDetailsMetrics.batchesList,
    });
    formattedArray.push({
      metricsName: "Elapsed Time",
      metricsValue: productionOverviewBatchDetailsMetrics.elapsedTime,
    });
    formattedArray.push({
      metricsName: "Manufacturing Time",
      metricsValue: productionOverviewBatchDetailsMetrics.manufacturingTime,
    });
    formattedArray.push({
      metricsName: "Material Code",
      metricsValue: productionOverviewBatchDetailsMetrics.material,
    });
    formattedArray.push({
      metricsName: "Order Quantity",
      metricsValue:
        productionOverviewBatchDetailsMetrics.orderQuantity === "NA"
          ? "NA"
          : productionOverviewBatchDetailsMetrics.orderQuantity + " litres",
    });
    formattedArray.push({
      metricsName: "Start up Rejects",
      metricsValue: productionOverviewBatchDetailsMetrics.startupReject,
    });
    formattedArray.push({
      metricsName: "Production Rejects",
      metricsValue: productionOverviewBatchDetailsMetrics.productionReject,
    });
    return formattedArray;
  };

  const [metricsData, setMetricsData] = useState<any>([
    { metricsName: "Process Order", metricsValue: [] },
    { metricsName: "Batch Number", metricsValue: "0" },
    { metricsName: "Target Time", metricsValue: "0" },
    { metricsName: "Elapsed Time", metricsValue: "0" },
    { metricsName: "Remaning Time", metricsValue: "0" },
    { metricsName: "Manufacturing Time", metricsValue: "0" },
  ]);

  const [batches, setBatches] = useState([]);
  useEffect(() => {
    setProductionOverviewBatchCountMetricsDetails(
      (productionOverviewBatchCountMetrics) => ({
        ...productionOverviewBatchCountMetrics,
        success: false,
      })
    );
    setPackagingDetails((packagingDetails) => ({
      ...packagingDetails,
      success: false,
    }));
  }, [payload]);
  useEffect(() => {
    setMetricsData(metricsDataFormatter());
  }, [productionOverviewBatchDetailsMetrics]);
  useEffect(() => {
    setProductionOverviewBatchCountMetricsDetails(
      productionOverviewBatchCountMetrics
    );
  }, [productionOverviewBatchCountMetrics]);

  //creating array of objects of aLL machines present in leaf node
  const result: any = [];
  payload.nodeId.forEach((i) => {
    result.push(findNestedObj(filterTreeData, "key", i));
  });
  useEffect(() => {
    setPackagingDetails(packagingData);
  }, [packagingData]);

  const allNodeTittle = allTreeNodeTitle(filterTreeData?.children);
  const startDate = "StartTime :" + dateConverter(payload?.startTime);
  const endDate = "EndTime :" + dateConverter(payload?.endTime);
  const sku = "Sku :" + payload?.sku;
  const lineName = payload?.lineObject?.map((item: any) => {
    return item.machine;
  });
  const machineLines = result?.map((item: any) => {
    return item?.title;
  });
  const allMachineNameIdMap = allTreeNodeNameIdMap(filterTreeData?.children);
  const line =
    "Machine Line :" +
    (allNodeTittle?.toString()
      ? machineLines?.toString()
      : lineName?.toString());

  const exportImagePdf = async () => {
    const header = [
      "Timestamp",
      "Device Name",
      "Device Tag",
      "Alarm Description",
      "Alarm Severity",
    ];
    const rows =
      productionOverviewActiveAlarmData.productionOverviewAlarmsTableList
        ?.length > 0 &&
      productionOverviewActiveAlarmData.productionOverviewAlarmsTableList?.map(
        (elt) => [
          moment.unix(elt["timestamp"] / 1000).format("MM-DD-YYYY, HH:mm:ss "),
          elt["deviceName"],
          elt["deviceTag"],
          elt["alarmDescription"],
          elt["severity"],
        ]
      );
    message.loading("downloading pdf started", 10);
    const unit = "pt";
    const size = "A4";
    const doc: any = new jsPDF("p", unit, size);
    let alarmAndErrorSummaryImage: any;
    let chartImage: any;
    const alarmAndErrorSummary = window.document.getElementById(
      "alarmAndErrorSummary"
    ) as HTMLElement;
    const otherCharts = window.document.getElementById(
      "dashboard-charts-pdf"
    ) as HTMLElement;
    await html2canvas(alarmAndErrorSummary).then((canvas) => {
      alarmAndErrorSummaryImage = canvas.toDataURL("image/png", 1.0);
    });
    await html2canvas(otherCharts).then((canvas) => {
      chartImage = canvas.toDataURL("image/png", 1.0);
    });

    doc.setFont(undefined, "bold");
    doc.addImage(UplLogo, "PNG", 280, 7, 36, 36);
    doc.setFontSize(18);
    doc.text(50, 65, "Production Overview Report-UPL Europe (Sandbach Plant)");
    doc.setFontSize(15);
    doc.text(20, 85, "Report Information");
    doc.setFont(undefined, "normal");
    doc.text(20, 105, startDate, "left", "5");
    doc.text(20, 120, endDate);
    doc.text(20, 135, sku);
    var lines = doc.splitTextToSize(line, 650 - 57 - 57);
    doc.text(20, 150, lines);
    doc.addImage(chartImage, "PNG", 20, 175, 560, 300);
    doc.addImage(alarmAndErrorSummaryImage, "PNG", 20, 477, 560, 113);
    doc.addPage();
    const headers = [header];
    const data = rows;
    message.info(data["length"] + " record(s) are getting downloaded");
    doc.setFont(undefined, "bold");
    doc.text(210, 30, "Active Alarms");
    autoTable(doc, {
      head: headers,
      body: data,
      styles: { fontSize: 8 },
      margin: { top: 40 },
    });
    doc.save(
      "Production_Overview" + "_" + moment().format("DD/MM/YYYY_HH:MM:SS")
    );
  };

  return (
    <>
      <div>
        <ContentHeader
          heading="Production Overview"
          
        />
             <div className="production-machine-metrics-container">
              {machineStatusData?.map((statusOject) => {
                return (
                  <div className="machine-status-container">
                    <div className="title">
                      <span className={
                        machineKeyToStatus(
                          statusOject.machineId,
                          machineStatusData
                        ).toLowerCase()
                      }></span>
                      {machineKeyToMachineName(
                        statusOject.machineId,
                        allMachineNameIdMap
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
        <DropdownFilterComponent
          onClick={dropdownFilter}
          setPayload={setPayload}
          download={() => exportImagePdf()}
        />
        <div className="content-container">
          <div id={"dashboard-charts-pdf"}>
            <MetricsCard
              metricsData={metricsData}
              setSelectedBatchNumber={setBatches}
            />
            {/* Note : We might use this in future */}
            {/* <div className="progress-bar-container">
              <ProgressBar
                variableName="Startup Rejects"
                value={productionOverviewBatchDetailsMetrics.startupReject}
              />
              <ProgressBar
                variableName="Production Rejects"
                value={productionOverviewBatchDetailsMetrics.productionReject}
              />
              <ProgressBar
                variableName="Percentage Good"
                value={productionOverviewBatchDetailsMetrics.percentageGood}
                showPercentage={true}
              />
              <ProgressBar
                variableName="Percentage Reject"
                value={productionOverviewBatchDetailsMetrics.percentageReject}
                showPercentage={true}
              />
            </div> */}
            <div className="gauge-chart-container">
              <div className="manufacturing-container">
                <div className="title">Manufacturing Details</div>
                {!productionOverviewBatchCountMetricsDetails?.success ? (
                  <div className="manufacturing-loader-outer">
                    <Spin className="packaging-loader"></Spin>
                  </div>
                ) : (
                  <div className="manufacturing-counts">
                    <div className="manufacturing-counts__child">
                      <span className="name">Reject Count</span>

                      <span className="value">
                        {
                          productionOverviewBatchCountMetricsDetails?.rejectCount
                        }
                      </span>
                    </div>
                    <div className="manufacturing-counts__child">
                      <span className="name">Good Count</span>

                      <span className="value">
                        {productionOverviewBatchCountMetricsDetails?.goodCount}
                      </span>
                    </div>
                    <div className="manufacturing-counts__child">
                      <span className="name">WIP Count</span>

                      <span className="value">
                        {productionOverviewBatchCountMetricsDetails?.wipCount}
                      </span>
                    </div>
                    <div className="manufacturing-counts__child">
                      <span className="name">Total Count</span>

                      <span className="value">
                        {productionOverviewBatchCountMetricsDetails?.totalCount}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className={"production-container"}>
                <div className="title">Packaging Details</div>
                {!packagingDetails?.success ? (
                  <div className="packaging-loader-outer">
                    <Spin className="packaging-loader"></Spin>
                  </div>
                ) : (
                  <div className="counter-values">
                    <div className="counter-values__child">
                      <div className="counter-section-1">
                        <div className="counter-section-1__left">
                          <span className="name">Carry Forwarded Bottles</span>
                          <span className="value">
                            {packagingDetails?.carryForwardBottles}
                          </span>
                        </div>
                        <div className="vertical-line">
                          <div className="vertical-line__child"></div>
                        </div>
                        <div className="counter-section-1__right">
                          <span className="name">Left Over Bottles</span>
                          <span className="value">
                            {packagingDetails?.leftOverBottles}
                          </span>
                        </div>
                      </div>
                      <div className="counter-section-2">
                        <span className="name">Total Finished Cases</span>

                        <span className="value">
                          {packagingDetails?.totalCaseCount}
                        </span>
                      </div>
                    </div>
                    <div className="counter-values__child-2">
                      <div className="top-outer">
                        <div className="top-left">
                          <span className="name">Carry Forwarded Cases</span>
                          <span className="value">
                            {packagingDetails?.carryForwardCases}
                          </span>
                        </div>
                        <div className="vertical-line">
                          <div className="vertical-line__child"></div>
                        </div>
                        <div className="top-right">
                          <span className="name">Total Finished Pallets</span>
                          <span className="value">
                            {packagingDetails?.totalFinishedPallets}
                          </span>
                        </div>
                      </div>
                      <div className="horizontal-lines">
                        <div className="horizontal-lines__left"></div>
                        <div className="horizontal-lines__right"></div>
                      </div>
                      <div className="bottom-outer">
                        <div className="bottom-left">
                          <span className="name">Total Unfinished Pallets</span>

                          <span className="value">
                            {packagingDetails?.totalUnfinishedPallets}
                          </span>
                        </div>
                        <div className="vertical-line">
                          <div className="vertical-line__child"></div>
                        </div>

                        <div className="bottom-right">
                          {" "}
                          <span className="name">Left Over Cases</span>
                          <span className="value">
                            {packagingDetails?.leftOverCases}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="multi-guage-container">
              <ChartContainer
                id="overallEquipmentEffectiveness"
                title="Overall Equipment Effectiveness"
                alignChartTitle="center"
                tooltip="Overall Equipment Effectiveness"
                child={
                  <SolidGuage
                    data={
                      productionOverviewOeeData
                        ? productionOverviewOeeData.reverse()
                        : []
                    }
                    chartID={"LineoverviewoverallEquipmentEffectiveness"}
                  />
                }
                width="203px"
                height="200px"
              />
              <ChartContainer
                id="averageCycleTime"
                title="Average Cycle Time"
                alignChartTitle="left"
                tooltip="Average Cycle Time"
                child={
                  <div className="guage-chart-container">
                    <GuageWithHand
                      chartID="guageWithHandAverageCycleTime"
                      data={
                        productionOverviewAverageCycleTimeData?.averageCycleTime
                      }
                      color={
                        productionOverviewAverageCycleTimeData?.averageCycleTime <
                        productionOverviewAverageCycleTimeData?.ratedCycleTime
                          ? "#52C41A"
                          : "#f5222d"
                      }
                      max={true}
                    />
                    <div className="gauge-chart-metrics-container">
                      <div>
                        <div className="metrics-name">Rated Cycle Time</div>
                        <div className="metrics-value-first">
                          {productionOverviewAverageCycleTimeData
                            ? productionOverviewAverageCycleTimeData.ratedCycleTime +
                              " Seconds"
                            : "NA"}
                          {/* {(
                            productionOverviewAverageCycleTimeData?.ratedCycleTime+''
                          ).charAt(0) === "N"
                            ? "NA"
                            : milliToHrs(
                                productionOverviewAverageCycleTimeData?.ratedCycleTime *
                                  60000
                              ) + " Seconds"} */}
                        </div>
                      </div>
                      <div>
                        <div
                          className="metrics-name"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Deviation
                        </div>
                        <div
                          className="metrics-value-last"
                          style={{
                            color:
                              productionOverviewAverageCycleTimeData?.averageCycleTime <
                              productionOverviewAverageCycleTimeData?.ratedCycleTime
                                ? "#52C41A"
                                : "#f5222d",
                          }}
                        >
                          {/* {avg cycle time and rated cycle time are comming in minutes} */}
                          {Math.abs(
                            productionOverviewAverageCycleTimeData?.averageCycleTime -
                              productionOverviewAverageCycleTimeData?.ratedCycleTime
                          ).toFixed(2)}
                          Seconds&nbsp;
                          {Math.abs(
                            Math.floor(
                              productionOverviewAverageCycleTimeData?.deviation *
                                100
                            ) / 100
                          )}
                          %
                        </div>
                      </div>
                    </div>
                  </div>
                }
                width="203px"
                height="200px"
              />
              <ChartContainer
                id="assetUtilization"
                title="Asset Utilization"
                alignChartTitle="left"
                tooltip="Asset Utilization"
                child={
                  <div style={{ width: "100%", height: "70%" }}>
                    <GuageWithHand
                      chartID="guageWithHandAssetUtilization"
                      data={
                        productionOverviewAssetUtilizationPercentage
                          ? productionOverviewAssetUtilizationPercentage
                          : 0
                      }
                    />
                    <div className="gauge-chart-metrics-container">
                      <div>
                        <div className="metrics-name">Available Time</div>
                        <div className="metrics-value-first">
                          {milliToMachineHrs(
                            productionOverviewAssetUtilizationAvailableTime
                          ).charAt(0) === "N"
                            ? "NA"
                            : milliToMachineHrs(
                                productionOverviewAssetUtilizationAvailableTime
                              ) + " Hrs"}
                        </div>
                      </div>
                      <div>
                        <div className="metrics-name">Runtime</div>
                        <div className="metrics-value-last">
                          {milliToHrs(
                            productionOverviewAssetUtilizationRuntime
                          ).charAt(0) === "N"
                            ? "NA"
                            : milliToHrs(
                                productionOverviewAssetUtilizationRuntime
                              ) + " Hrs"}
                        </div>
                      </div>
                    </div>
                  </div>
                }
                width="203px"
                height="200px"
              />
            </div>
          </div>
          <div className="pareto-chart-container">
            <ChartContainer
              id="alarmAndErrorSummary"
              title="Alarm and Error Summary"
              alignChartTitle="left"
              tooltip="Alarm and Error Summary"
              child={
                <Pareto
                  chartID="paretoAlarmAndErrorSummary"
                  data={
                    productionOverviewActiveAlarmData.productionOverviewAlarmsCountByTagsList
                  }
                />
              }
              width="204px"
              height="300px"
              showFullScreen={true}
            />
            <ChartContainer
              id="activeAlarm"
              title="Active Alarm"
              alignChartTitle="left"
              tooltip=""
              child={
                <ActiveAlarmCard
                  chartID="activeAlarmCard"
                  data={
                    productionOverviewActiveAlarmData.productionOverviewAlarmsTableList
                  }
                  cardData={
                    productionOverviewActiveAlarmData.productionOverviewAlarmsSummaryCardData
                  }
                  height={170}
                  alarmsEventsActiveAlarmsRowCount={productionOverviewRowCount}
                  setPage={setPage}
                />
              }
              width="284px"
              height="300px"
            />
          </div>
        </div>
      </div>
      <UploadExcel
        showDialog={uploadDialog}
        closeUploadDialog={closeUploadDialog}
      />
    </>
  );
};

export default ProductionOverview;
