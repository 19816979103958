import { ReasonCodeDetail } from "types";
import { dateConverter } from "utils/common";
import { timeFormat } from "utils/Constants";
import { format } from 'date-fns';
import { milliToHrs } from "utils/milliToHrs";

export const mapReasonCodeDetails = (details: any) => {
  let reasonCodeCategoryList: ReasonCodeDetail[] = [];
  let reasonCodeFactor = {} as any;
  let reasonCodeDetails = {} as any;
  details.forEach((element: any) => {
    let reasonCodeFactorList: ReasonCodeDetail[] = [];
    if(element.getState()){
    reasonCodeCategoryList.push({
      category: element.getReasonCodeCategoryTitle(),
      value: element.getReasonCodeCategoryUuid(),
    });
  }
    if (element.getReasonCodeFactorInfoList().length > 0) {
      element.getReasonCodeFactorInfoList().forEach((factorItem: any) => {
      if(factorItem.getStatus()){
        reasonCodeFactorList.push({
          category: factorItem.getReasonCodeFactorTitle(),
          value: factorItem.getReasonCodeFactorUuid()
        });
      }
        let reasonCodeList: ReasonCodeDetail[] = [];
        if (factorItem.getReasonCodeDetailsInfoList().length > 0) {
          factorItem.getReasonCodeDetailsInfoList().forEach((reasonCodeItem: any) => {
      if(reasonCodeItem.getStatus()){
            return reasonCodeList.push({
              category: reasonCodeItem.getReasonCodeDetailsTitle(),
              value: reasonCodeItem.getReasonCodeDetailsUuid(),
              code: reasonCodeItem.getReasonCode()
            });
          }
          });
        }
        reasonCodeDetails[factorItem.getReasonCodeFactorUuid()] = reasonCodeList;
      });
    }
    reasonCodeFactor[element.getReasonCodeCategoryUuid()] = reasonCodeFactorList;
  });

  return {
    categoryList: reasonCodeCategoryList,
    reasonCodeFactor,
    reasonCodeDetails
  };
};

export const mapAllReasonCodeDetails = (details: any) => {
  let reasonCodeAllList: any = [];
  let reasonCodeFactorDetail = {} as any;
  let reasonCodeDetails = {} as any;

  let reasonCodeFactorExtractor =(reasonCodeArr:any)=>{
    let reasonCodeDetailsAllList: any = [];
    reasonCodeArr.forEach((reasonCodeItem: any) => {
      reasonCodeDetailsAllList.push({
        reasonCodeTitle: reasonCodeItem.getReasonCodeDetailsTitle(),
        key: reasonCodeItem.getReasonCodeDetailsUuid(),
        status: reasonCodeItem.getStatus(),
        createdOn: reasonCodeItem.getCreatedOn(),
        lastModified: reasonCodeItem.getModifiedOn(),
      });
    });
    return reasonCodeDetailsAllList
  }
  details.forEach((element: any) => {
    let reasonCodeFactorAllList: any = [];
    reasonCodeAllList.push({
      reasonCodeCategory: element.getReasonCodeCategoryTitle(),
      key: element.getReasonCodeCategoryUuid(),
      info: element.getReasonCodeFactorInfoList(),
      status: element.getState(),
      reasonCodeFactor: reasonCodeFactorAllList,
      reasonCode: reasonCodeDetails
    })
    if (element.getReasonCodeFactorInfoList().length > 0) {
      element.getReasonCodeFactorInfoList().forEach((factorItem: any) => {
        reasonCodeFactorAllList.push({
          reasonCodeFactor: factorItem.getReasonCodeFactorTitle(),
          key: factorItem.getReasonCodeFactorUuid(),
          status: factorItem.getStatus(),
          reasonCode: factorItem.getReasonCode(),
          info: factorItem.getReasonCodeDetailsInfoList(),
          categoryTitle: element.getReasonCodeCategoryTitle(),
          reasonCodes:reasonCodeFactorExtractor(factorItem.getReasonCodeDetailsInfoList()),
        });
        if (factorItem.getReasonCodeDetailsInfoList().length > 0) {
          let reasonCodeDetailsAllList: any = [];
          factorItem.getReasonCodeDetailsInfoList().forEach((reasonCodeItem: any) => {
            reasonCodeDetailsAllList.push({
              reasonCodeTitle: reasonCodeItem.getReasonCodeDetailsTitle(),
              key: reasonCodeItem.getReasonCodeDetailsUuid(),
              status: reasonCodeItem.getStatus(),
              reasonCodeFactor: factorItem.getReasonCodeFactorTitle(),
              reasonCodeCategory: element.getReasonCodeCategoryTitle(),
              createdOn: reasonCodeItem.getCreatedOn(),
              lastModified: reasonCodeItem.getModifiedOn(),
            });
          });
          reasonCodeDetails[factorItem.getReasonCodeFactorUuid()] = reasonCodeDetailsAllList;
        }
      });
    }
    reasonCodeFactorDetail[element.getReasonCodeCategoryUuid()] = reasonCodeFactorAllList;
  });

  return {
    allCategoryList: reasonCodeAllList,
    reasonCodeFactorDetail,
    reasonCodeDetails,
  };
};

export const mapReasonCodeDetailsListView = (details: any) => {
  let reasonCodeListData: any = [];
  details.forEach((element: any) => {
    reasonCodeListData.push({
      key: element.getReasonCodeDetailsUuid(),
      reasonCodeCategory: element.getReasonCodeCategoryTitle(),
      reasonCodeFactor: element.getReasonCodeFactorTitle(),
      reasonCode: element.getReasonCodeDetailsTitle(),
      status: element.getStatus() ? "Active" : "Inactive",
      createdOn: dateConverter(element.getCreatedOn()),
      lastModified: dateConverter(element.getModifiedOn()),
      reasonCodeCategoryUuid: element.getReasonCodeCategoryUuid(),
      reasonCodeFactorUuid: element.getReasonCodeFactorUuid()
    })
  });
  return {
    reasonCodeListData
  }
};

export const mapAMDList = (response: any) => {
  let temp: any = [];
  response.forEach((item: any, index: number) => {
    temp.push({
      key: (index + 1).toString(),
      stoppageId: item.getStoppageId(),
      fpLine: item.getFpLine(),
      equipment: item.getEquipment(),
      batchOrder: item.getBatchOrder(),
      sku: item.getProductSku(),
      reasonCode: item.getReasonCodeName(),
      operator: item.getOperator(),
      startDate: format(new Date(item.getStartDate()), timeFormat),
      endDate: format(new Date(item.getEndDate()), timeFormat),
      duration: item.getDuration().toString().charAt(0) === '-' ? milliToHrs(item.getDuration() * -1) : milliToHrs(item.getDuration()),
      status: item.getStatus() === true ? 'Active' : 'Inactive',
      reasonCodeCategory: {
        title:
          item.getReasonCodeCategoryInfo()?.getReasonCodeCategoryTitle(),
        id:item.getReasonCodeCategoryInfo()?.getReasonCodeCategoryUuid(),
      },
      reasonCodeFactor: {
        title: item.getReasonCodeFactorInfo()?.getReasonCodeFactorTitle(),
        id: item.getReasonCodeFactorInfo()?.getReasonCodeFactorUuid()

      },
      reasonCodeDetail: {
        title: item.getReasonCodeDetailsInfo()?.getReasonCodeDetailsTitle(),
        id: item.getReasonCodeDetailsInfo()?.getReasonCodeDetailsUuid(),
        code_value:item.getReasonCodeDetailsInfo()?.getReasonCode()
      }
    })
  })
  return temp;
}

export const mapReasonCodeDetailExpandedView = (details: any) => {
  let reasonCodeDataExpandedList: any = [];
  details.forEach((element: any) => {
    let factorData = element.getReasonCodeFactorInfoList().map((factorItem: any) => ({
      reasonCodeFactor: factorItem.getReasonCodeFactorTitle(),
      key: factorItem.getReasonCodeFactorUuid(),
      status: factorItem.getStatus() ? "Active" : "Inactive",
      reasonCodeCategoryUuid: element.getReasonCodeCategoryUuid(),
      reasonCodeFactorUuid: factorItem.getReasonCodeFactorUuid(),
      reasonCode: factorItem.getReasonCode(),
      children: factorItem.getReasonCodeDetailsInfoList().map((reasonCodeElement: any) => ({
        reasonCode: reasonCodeElement.getReasonCodeDetailsTitle(),
        key: reasonCodeElement.getReasonCodeDetailsUuid(),
        status: reasonCodeElement.getStatus() ? "Active" : "Inactive",
        createdOn: dateConverter(reasonCodeElement.getCreatedOn()),
        lastModified: dateConverter(reasonCodeElement.getModifiedOn()),
        reasonCodeCategoryUuid: element.getReasonCodeCategoryUuid(),
        reasonCodeFactorUuid: factorItem.getReasonCodeFactorUuid(),
        reasonCodeUuid: reasonCodeElement.getReasonCodeDetailsUuid(),
      })),
    }))
    reasonCodeDataExpandedList.push({
      reasonCodeCategory: element.getReasonCodeCategoryTitle(),
      key: element.getReasonCodeCategoryUuid(),
      reasonCodeCategoryUuid: element.getReasonCodeCategoryUuid(),
      info: element.getReasonCodeFactorInfoList(),
      status: element.getStatusList().toString() === "true" ? "Active" : "Inactive",
      reasonCode: element.getReasonCode(),
      createdOn: dateConverter(element.getCreatedOn()),
      lastModified: dateConverter(element.getModifiedOn()),
      children: factorData
    });
  })
  return {
    reasonCodeDataExpandedList
  }
};

//Not using now Might be used in future
export const mapAssignedReasonCodeDetails = (details: any) => {
  let assignedReasonCodeListData: any = [];
  details.forEach((element: any, index: any) => {
    assignedReasonCodeListData.push({
      key: index,
      categoryKey: element.getReasonCodeCategoryUuid(),
      factorKey: element.getReasonCodeFactorUuid(),
      reasonCodeKey: element.getReasonCodeDetailsUuid(),
      status: element.getStatus() ? "Active" : "Inactive",
      reasonCodeCategory: element.getReasonCodeCategoryTitle(),
      reasonCodeFactor: element.getReasonCodeFactorTitle(),
      reasonCode: element.getReasonCodeDetailsTitle()
    })
  });
  return {
    assignedReasonCodeListData
  }
}
