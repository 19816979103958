import React, { useEffect, useState } from 'react';

import ReactEcharts from "echarts-for-react";

export const AlarmTimelineGraph = (props) => {
    const days = ["data"];
    const { data, timeLine, range } = props;
    const inputRef = React.useRef();
    const [options, setOptions] = useState<{}>({});

    useEffect(() => {
        handleChartOptions(data);
    }, [data, timeLine, range]);
    const handleChartOptions = (dataArray: any) => {
        const option: any = {
            tooltip: {
                position: 'bottom',
                formatter: function (params) {
                    var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:12px;height:12px;background-color:' + color + '"></span>';
                    return "<div>" + "<div><span>StartTime  :</span><span>" + params.value[5] + "</span></div>" +
                        "<div><span>EndTime  :</span><span>" + params.value[4] + "</span></div>" + "<br/>" +
                        "<div><span>" + colorSpan("#F5222D") + "</span><span>Critical    :</span><span>" + params.value[2].Critical + "</div>" +
                        "<div><span>" + colorSpan("#FA541C") + "</span><span>Major       :</span><span>" + params.value[2].Major + "</div>" +
                        "<div><span>" + colorSpan("#FA8C16") + "</span><span>Minor       :</span><span>" + params.value[2].Minor + "</div>" +
                        "<div><span>" + colorSpan("#FADB14") + "</span><span>Warning     :</span><span>" + params.value[2].Warning + "</div>" +
                        "<div><span>" + colorSpan("#c88a10") + "</span><span>Event       :</span><span>" + params.value[2].Event + "</div>" + "</div>";
                }
            },
            singleAxis: [],
            series: []
        };
        days.forEach(function (day, idx) {
            option.singleAxis.push({
                left: 40,
                top: 30,
                type: 'category',
                boundaryGap: false,
                data: timeLine,
                height: 20 + '%',
                axisLabel: {
                    interval: 0,
                    fontSize: '9'
                },
            });
            option.series.push({
                singleAxisIndex: idx,
                coordinateSystem: 'singleAxis',
                type: 'scatter',
                data: [],
                symbolSize: function (dataItem) {
                    return dataItem[1];
                },
                itemStyle: {
                    color: function (value) {
                        switch (value.data[3]) {
                            case 'Critical':
                                return '#F5222D';
                            case 'Major':
                                return '#FA541C';
                            case 'Minor':
                                return '#FA8C16';
                            case 'Warning':
                                return "#FAAD14"
                            case 'Event':
                                return "#c88a10"
                            case 'null':
                                return "red"
                        }
                    }
                }
            });
        });
        dataArray.forEach(function (dataItem) {
            option.series[dataItem[0]].data.push([dataItem[1], dataItem[2], dataItem[3], dataItem[4], dataItem[5], dataItem[6]]);
        });
        setOptions(option);
    };

    return (
        <>
            <ReactEcharts
                option={options}
                style={{ height: "70px", width: "100%", position: 'relative', top: '-10px' }}
                ref={inputRef as any}
            />
        </>
    );
}