import * as types from "./actionTypes";

export const getProcessLineInfo = () => ({
  type: types.GET_PROCESS_LINE_INFO,
});

export const selectedFPLine = (payload: string) => ({
  type: types.SELECTED_FP_LINE,
  payload,
});

export const assignOperatorLog = (payload: any) => ({
  type: types.ASSIGN_OPERATOR_LOG,
  payload,
});

export const getMachineStatus = () => ({
  type: types.GET_MACHINE_STATUS,
});
