// source: service/AlarmEvents.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_Common_pb = require('../common/Common_pb.js');
goog.object.extend(proto, common_Common_pb);
var model_AlarmOverview_pb = require('../model/AlarmOverview_pb.js');
goog.object.extend(proto, model_AlarmOverview_pb);
var model_AlarmDetails_pb = require('../model/AlarmDetails_pb.js');
goog.object.extend(proto, model_AlarmDetails_pb);
goog.exportSymbol('proto.upl_sandbach.AlarmAndErrorDashboardResponse', null, global);
goog.exportSymbol('proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse', null, global);
goog.exportSymbol('proto.upl_sandbach.AlarmDashboardRequest', null, global);
goog.exportSymbol('proto.upl_sandbach.AlarmDetailsOverviewResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AlarmAndErrorDashboardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.AlarmAndErrorDashboardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AlarmAndErrorDashboardResponse.displayName = 'proto.upl_sandbach.AlarmAndErrorDashboardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AlarmDashboardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.AlarmDashboardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AlarmDashboardRequest.displayName = 'proto.upl_sandbach.AlarmDashboardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AlarmDetailsOverviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.AlarmDetailsOverviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AlarmDetailsOverviewResponse.displayName = 'proto.upl_sandbach.AlarmDetailsOverviewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.displayName = 'proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AlarmAndErrorDashboardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AlarmAndErrorDashboardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AlarmAndErrorDashboardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorDashboardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alarmAndEventDashboard: (f = msg.getAlarmAndEventDashboard()) && model_AlarmOverview_pb.AlarmAndEventDashboard.toObject(includeInstance, f),
    generalResponse: (f = msg.getGeneralResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AlarmAndErrorDashboardResponse}
 */
proto.upl_sandbach.AlarmAndErrorDashboardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AlarmAndErrorDashboardResponse;
  return proto.upl_sandbach.AlarmAndErrorDashboardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AlarmAndErrorDashboardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AlarmAndErrorDashboardResponse}
 */
proto.upl_sandbach.AlarmAndErrorDashboardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_AlarmOverview_pb.AlarmAndEventDashboard;
      reader.readMessage(value,model_AlarmOverview_pb.AlarmAndEventDashboard.deserializeBinaryFromReader);
      msg.setAlarmAndEventDashboard(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AlarmAndErrorDashboardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AlarmAndErrorDashboardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AlarmAndErrorDashboardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorDashboardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlarmAndEventDashboard();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      model_AlarmOverview_pb.AlarmAndEventDashboard.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional AlarmAndEventDashboard alarm_and_event_dashboard = 10;
 * @return {?proto.upl_sandbach.AlarmAndEventDashboard}
 */
proto.upl_sandbach.AlarmAndErrorDashboardResponse.prototype.getAlarmAndEventDashboard = function() {
  return /** @type{?proto.upl_sandbach.AlarmAndEventDashboard} */ (
    jspb.Message.getWrapperField(this, model_AlarmOverview_pb.AlarmAndEventDashboard, 10));
};


/**
 * @param {?proto.upl_sandbach.AlarmAndEventDashboard|undefined} value
 * @return {!proto.upl_sandbach.AlarmAndErrorDashboardResponse} returns this
*/
proto.upl_sandbach.AlarmAndErrorDashboardResponse.prototype.setAlarmAndEventDashboard = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmAndErrorDashboardResponse} returns this
 */
proto.upl_sandbach.AlarmAndErrorDashboardResponse.prototype.clearAlarmAndEventDashboard = function() {
  return this.setAlarmAndEventDashboard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmAndErrorDashboardResponse.prototype.hasAlarmAndEventDashboard = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralResponse general_response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.AlarmAndErrorDashboardResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.AlarmAndErrorDashboardResponse} returns this
*/
proto.upl_sandbach.AlarmAndErrorDashboardResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmAndErrorDashboardResponse} returns this
 */
proto.upl_sandbach.AlarmAndErrorDashboardResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmAndErrorDashboardResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AlarmDashboardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AlarmDashboardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AlarmDashboardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmDashboardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    alarmDashboardFilter: (f = msg.getAlarmDashboardFilter()) && model_AlarmOverview_pb.AlarmDashboardFilter.toObject(includeInstance, f),
    generalRequest: (f = msg.getGeneralRequest()) && common_Common_pb.GeneralRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AlarmDashboardRequest}
 */
proto.upl_sandbach.AlarmDashboardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AlarmDashboardRequest;
  return proto.upl_sandbach.AlarmDashboardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AlarmDashboardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AlarmDashboardRequest}
 */
proto.upl_sandbach.AlarmDashboardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_AlarmOverview_pb.AlarmDashboardFilter;
      reader.readMessage(value,model_AlarmOverview_pb.AlarmDashboardFilter.deserializeBinaryFromReader);
      msg.setAlarmDashboardFilter(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralRequest;
      reader.readMessage(value,common_Common_pb.GeneralRequest.deserializeBinaryFromReader);
      msg.setGeneralRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AlarmDashboardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AlarmDashboardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AlarmDashboardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmDashboardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlarmDashboardFilter();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      model_AlarmOverview_pb.AlarmDashboardFilter.serializeBinaryToWriter
    );
  }
  f = message.getGeneralRequest();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional AlarmDashboardFilter alarm_dashboard_filter = 10;
 * @return {?proto.upl_sandbach.AlarmDashboardFilter}
 */
proto.upl_sandbach.AlarmDashboardRequest.prototype.getAlarmDashboardFilter = function() {
  return /** @type{?proto.upl_sandbach.AlarmDashboardFilter} */ (
    jspb.Message.getWrapperField(this, model_AlarmOverview_pb.AlarmDashboardFilter, 10));
};


/**
 * @param {?proto.upl_sandbach.AlarmDashboardFilter|undefined} value
 * @return {!proto.upl_sandbach.AlarmDashboardRequest} returns this
*/
proto.upl_sandbach.AlarmDashboardRequest.prototype.setAlarmDashboardFilter = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmDashboardRequest} returns this
 */
proto.upl_sandbach.AlarmDashboardRequest.prototype.clearAlarmDashboardFilter = function() {
  return this.setAlarmDashboardFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmDashboardRequest.prototype.hasAlarmDashboardFilter = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralRequest general_request = 20;
 * @return {?proto.upl_sandbach.GeneralRequest}
 */
proto.upl_sandbach.AlarmDashboardRequest.prototype.getGeneralRequest = function() {
  return /** @type{?proto.upl_sandbach.GeneralRequest} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralRequest, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralRequest|undefined} value
 * @return {!proto.upl_sandbach.AlarmDashboardRequest} returns this
*/
proto.upl_sandbach.AlarmDashboardRequest.prototype.setGeneralRequest = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmDashboardRequest} returns this
 */
proto.upl_sandbach.AlarmDashboardRequest.prototype.clearGeneralRequest = function() {
  return this.setGeneralRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmDashboardRequest.prototype.hasGeneralRequest = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AlarmDetailsOverviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AlarmDetailsOverviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AlarmDetailsOverviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmDetailsOverviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeAlarmOverview: (f = msg.getActiveAlarmOverview()) && model_AlarmDetails_pb.ActiveAlarmOverview.toObject(includeInstance, f),
    generalResponse: (f = msg.getGeneralResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AlarmDetailsOverviewResponse}
 */
proto.upl_sandbach.AlarmDetailsOverviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AlarmDetailsOverviewResponse;
  return proto.upl_sandbach.AlarmDetailsOverviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AlarmDetailsOverviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AlarmDetailsOverviewResponse}
 */
proto.upl_sandbach.AlarmDetailsOverviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_AlarmDetails_pb.ActiveAlarmOverview;
      reader.readMessage(value,model_AlarmDetails_pb.ActiveAlarmOverview.deserializeBinaryFromReader);
      msg.setActiveAlarmOverview(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AlarmDetailsOverviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AlarmDetailsOverviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AlarmDetailsOverviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmDetailsOverviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveAlarmOverview();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      model_AlarmDetails_pb.ActiveAlarmOverview.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ActiveAlarmOverview active_alarm_overview = 10;
 * @return {?proto.upl_sandbach.ActiveAlarmOverview}
 */
proto.upl_sandbach.AlarmDetailsOverviewResponse.prototype.getActiveAlarmOverview = function() {
  return /** @type{?proto.upl_sandbach.ActiveAlarmOverview} */ (
    jspb.Message.getWrapperField(this, model_AlarmDetails_pb.ActiveAlarmOverview, 10));
};


/**
 * @param {?proto.upl_sandbach.ActiveAlarmOverview|undefined} value
 * @return {!proto.upl_sandbach.AlarmDetailsOverviewResponse} returns this
*/
proto.upl_sandbach.AlarmDetailsOverviewResponse.prototype.setActiveAlarmOverview = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmDetailsOverviewResponse} returns this
 */
proto.upl_sandbach.AlarmDetailsOverviewResponse.prototype.clearActiveAlarmOverview = function() {
  return this.setActiveAlarmOverview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmDetailsOverviewResponse.prototype.hasActiveAlarmOverview = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralResponse general_response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.AlarmDetailsOverviewResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.AlarmDetailsOverviewResponse} returns this
*/
proto.upl_sandbach.AlarmDetailsOverviewResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmDetailsOverviewResponse} returns this
 */
proto.upl_sandbach.AlarmDetailsOverviewResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmDetailsOverviewResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alarmErrorSummaryPieChart: (f = msg.getAlarmErrorSummaryPieChart()) && model_AlarmOverview_pb.AlarmAndErrorSummaryPieChart.toObject(includeInstance, f),
    generalResponse: (f = msg.getGeneralResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse;
  return proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_AlarmOverview_pb.AlarmAndErrorSummaryPieChart;
      reader.readMessage(value,model_AlarmOverview_pb.AlarmAndErrorSummaryPieChart.deserializeBinaryFromReader);
      msg.setAlarmErrorSummaryPieChart(value);
      break;
    case 20:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlarmErrorSummaryPieChart();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      model_AlarmOverview_pb.AlarmAndErrorSummaryPieChart.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional AlarmAndErrorSummaryPieChart alarm_error_summary_pie_chart = 10;
 * @return {?proto.upl_sandbach.AlarmAndErrorSummaryPieChart}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.prototype.getAlarmErrorSummaryPieChart = function() {
  return /** @type{?proto.upl_sandbach.AlarmAndErrorSummaryPieChart} */ (
    jspb.Message.getWrapperField(this, model_AlarmOverview_pb.AlarmAndErrorSummaryPieChart, 10));
};


/**
 * @param {?proto.upl_sandbach.AlarmAndErrorSummaryPieChart|undefined} value
 * @return {!proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse} returns this
*/
proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.prototype.setAlarmErrorSummaryPieChart = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse} returns this
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.prototype.clearAlarmErrorSummaryPieChart = function() {
  return this.setAlarmErrorSummaryPieChart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.prototype.hasAlarmErrorSummaryPieChart = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralResponse general_response = 20;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.prototype.getGeneralResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 20));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse} returns this
*/
proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse} returns this
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 20) != null;
};


goog.object.extend(exports, proto.upl_sandbach);
