// @ts-nocheck
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "pages/Login";
import PageLayout from "pages/pageLayout";
import { useState } from "react";
import { ReasonCodeOperator } from "pages/operator/index";
import { Loader } from "components/loader/index";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(localStorage.getItem("authToken"));

  const setAuthenticated = (isAuth: boolean) => {
    setIsAuthenticated(isAuth);
  }

  return isAuthenticated ? (
    <>
      <Loader />
      <Routes>
        <Route path="/operator" element={<ReasonCodeOperator />} />
        <Route path={"*"} element={<PageLayout />} />
      </Routes>
    </>
  ) : (
    <Routes>
      <Route path={"/login"} element={<Login setAuthenticated={setAuthenticated} />} />
      <Route path={"*"} element={<Login setAuthenticated={setAuthenticated} />} />
    </Routes>
  );
}

export default App;
