import "./index.scss";
import { MoreOutlined } from "@ant-design/icons";
import { ReasonCategoryCard } from "./Card";
import { Card, Divider, Dropdown, MenuProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { getReasonCodeCategoriesData } from "store/actions/reasonCodeManagement";
import { dateConverter, reasonCodeManagementSearchFilter } from "utils/common";
import { ReasonFactorCard } from "./FactorCard";
import { EditResonCode } from "../Reason-Code-Modals/EditReasonCode";
import { EditResonCodeCategory } from "../Reason-Code-Modals/EditReasonCodeCategory";
import { DeleteResonCode } from "../Reason-Code-Modals/ReasonCodeDelete";
import { EditResonCodeFactor } from "../Reason-Code-Modals/EditReasonCodeFactor";

export const GridView = (props: any) => {
  const { searchValue, setCategoryUuid, setFactorUuid, setCodeUuid } = props;

  const [selected, setSelected] = useState(0);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [factorChildActive, setFactorChildActive] = useState<boolean>(false);
  const [factorSelected, setfactorSelected] = useState(0);
  const [codeSelected, setcodeSelected] = useState(0);
  const [reasonCodeFactorDetails, setreasonCodeFactorDetails] = useState<any>([]);
  const [reasonCodeDetails, setreasonCodeDetails] = useState<any>([]);
  const [filteredGridData, setFilteredGridData] = useState<any>([]);
  const [reasonCodeFactor, setreasonCodeFactor] = useState<any>();
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [isChildActive, setIsChildActive] = useState<boolean>(false);
  const [selectedFactor, setSelectedFactor] = useState<string>("");
  const [payload, setPayload] = useState({ viewType: "grid" });
  const [selectedCodeUuid, setSelectedCodeUuid] = useState<string>("")
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [type, setType] = useState<number>(0);

  const reasonCodeCategoryData = useSelector((state: any) => state.reasonCode.reasonCodeAllList);
  const reasonCodeFactorData = useSelector((state: any) => state.reasonCode.reasonCodeFactorDetail);
  const reasonCodeData = useSelector((state: any) => state.reasonCode.reasonCodeDetails);

  const dispatch = useDispatch();
  const viewType = "grid";

  useEffect(() => {
    dispatch(getReasonCodeCategoriesData(payload));
  }, [dispatch]);
  useEffect(() => {
    if (reasonCodeCategoryData?.length > 0) {
      setFilteredGridData(reasonCodeCategoryData);
    }
  }, [reasonCodeCategoryData]);

  useEffect(() => {
    if (reasonCodeFactorData) {
      setreasonCodeFactorDetails(reasonCodeFactorData[selectedCategory])
    }
  }, [reasonCodeFactorData]);

  useEffect(() => {
    if (reasonCodeData) {
      setreasonCodeDetails(reasonCodeData[selectedFactor])
    }
  }, [reasonCodeData]);

  useEffect(() => {
    reasonCodeManagementSearchFilter(reasonCodeCategoryData, searchValue, setFilteredGridData, viewType)
  }, [searchValue]);

  const onCategoryClick = (value: any, index: any, data: any) => {
    if (data.reasonCodeFactor) {
      let bool = data.reasonCodeFactor?.some((item) => { return item.status === true });
      setIsChildActive(bool);
      if (!bool) {
        for (let i = 0; i < data.reasonCodeFactor.length; i++) {
          let obj = data.reasonCode;
          let key = data.reasonCodeFactor[i].key;
          let dataArr = obj[key];
          let reasonCodeBool = dataArr ? dataArr.some((item) => { return item.status === true }) : false;
          if (reasonCodeBool) {
            setIsChildActive(true);
            break;
          }
        }
      }
    }
    setSelectedItem(data);
    setType(0); //  0 => reason code category
    setSelected(index);
    setCategoryUuid(value)
    setSelectedCategory(value)
    setreasonCodeFactorDetails(reasonCodeFactorData[value])
    setreasonCodeDetails([])
    setfactorSelected(0)
  }

  const onFactorClick = (value: any, index: any, data: any) => {
    setfactorSelected(index);
    if (value) {
      setFactorChildActive(false);
      for (let i = 0; i < data.info.length; i++) {
        if (data.info[i].array[3]) {
          setFactorChildActive(true);
          break;
        } else {
          setFactorChildActive(false);
        }
      }
      setreasonCodeFactor(data)
      setSelectedItem(data);
      setType(1);  //  1 => reason code factor
      setFactorUuid(value);
      setSelectedFactor(value);
      setreasonCodeDetails(reasonCodeData[value])
    }
  }

  const onReasonCodeClick = (value: any, index: any, data: any) => {
    setSelectedItem(data);
    setType(2);  // 2 => reason code 
    setcodeSelected(index)
    setCodeUuid(value)
    setSelectedCodeUuid(value)
  }

  const handleEditReason = () => {
    setOpenEdit(!openEdit)
  };
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleFactorEdit = () => {
    setIsOpenEdit(!isOpenEdit);
  };

  const modalShow = (state: any, setState: any) => {
    setState(true);
  };

  const okHandle = (state: any, setState: any) => {
    setState(false);
  };

  const handleEdit = () => {
    modalShow(isEditModalOpen, setIsEditModalOpen)
  };

  const handleDelete = (data: any) => {
    modalShow(isDeleteModalOpen, setIsDeleteModalOpen)
  };

  const cancelHandle = (state: any, setState: any) => {
    setState(false);
  };
  const selectedCategoryFilterData = filteredGridData?.find((item: any) => {
    return item.key === selectedCategory
  })
  const selectedCodeFilteredData = reasonCodeDetails?.find((item: any) => {
    return item.key === selectedCodeUuid
  })

  const getAllStatus = (data: any) => {
    let statusArr = [] as any;
    data?.map((item: any) => {
      statusArr.push(item.status)
    })
    return statusArr;
  }
  const getReasonCodeAllStatus = getAllStatus(reasonCodeDetails)?.filter((item: any) => {
    return item === true
  })
  const getAllCategoryChildStatus = (categoryValues = [] as any) => {
    let statusArr = [] as any;

    categoryValues.map((item: any) => {
      if (item.status === true) {
        statusArr.push(true);
      }
      if (item.reasonCodes.length) {
        item.reasonCodes.map((resonCodeObjext: any) => {
          if (resonCodeObjext.status === true)
            statusArr.push(true);
        })
      }
    })
    return statusArr
  }

  let items: MenuProps['items'] = [
    {
      label: type === 0 ? <div onClick={handleEdit}> Edit</div> : type === 1 ? <div onClick={handleFactorEdit}> Edit</div> : <div onClick={handleEditReason}> Edit</div>,
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: <div onClick={handleDelete}> Delete</div>,
      key: '1',
    }
  ];

  return (
    <>
      <div className="grid-view-container">
        <Divider>Reason Code Category</Divider>
        <div className="scroll">
          <div className="category-content-container">
            {filteredGridData?.map((item: any, index: any) => {
              let reasonCodeLength: any = 0;
              item.reasonCodeFactor.map((element: any) => {
                reasonCodeLength = reasonCodeLength + element.info.length
              });
              return (
                <ReasonCategoryCard
                  uuid={item.key}
                  title={item.reasonCodeCategory}
                  temStatus={item.status}
                  icon={
                    <Dropdown menu={{ items }} trigger={['click']}>
                      <MoreOutlined />
                    </Dropdown>
                  }
                  content={[{ title: "Status", value: item.status === true ? "Active" : "Inactive" },
                  { title: "Reason Code Factor", value: item?.info?.length },
                  { title: "Reason Code", value: reasonCodeLength ? reasonCodeLength : "0" }
                  ]}

                  className={selected === index ? "selected" : ""}
                  onClick={() => onCategoryClick(item.key, index, item)}
                />
              )
            })}
          </div>
        </div>
        <Divider>Reason Code Factor</Divider>
        <div className="scroll">
          <div className="factor-content-container">
            {reasonCodeFactorDetails?.map((item: any, index: any) => {

              return (
                <>
                  <ReasonFactorCard
                    reasonCodeAllStatus={getReasonCodeAllStatus}
                    data={item}
                    categoryUuid={selectedCategory}
                    icon={
                      <Dropdown menu={{ items }} trigger={['click']}>
                        <MoreOutlined />
                      </Dropdown>
                    }
                    content={[{ title: "Status", value: item?.status ? "Active" : "Inactive" },
                    { title: "Reason Code", value: item?.info?.length }]}

                    className={factorSelected === index ? "selected" : ""}
                    onClick={() => onFactorClick(item.key, index, item)} />

                  <EditResonCodeFactor
                    reasonCodeAllStatus={getReasonCodeAllStatus}
                    category={selectedCategory}
                    data={reasonCodeFactor}
                    visible={isOpenEdit}
                    onCancel={() => setIsOpenEdit(false)}
                    onOk={() => setIsOpenEdit(false)}
                  />
                </>
              )
            })}
          </div>
        </div>
        <Divider>Reason Code</Divider>
        <div className="code-scroll">
          <div className="reason-code-content-container">
            {reasonCodeDetails?.map((element: any, index: any) => {
              return (
                <>
                  <Card
                    onClick={() => onReasonCodeClick(element.key, index, element)}
                    className={codeSelected === index ? "selected" : ""}>
                    <div className="reason-code-category">
                      <div className="reason-title">Reason Code Category
                        <Dropdown menu={{ items }} trigger={['click']}>
                          <MoreOutlined style={{ float: "right" }} />
                        </Dropdown></div>
                      <div className="reason-value">{element.reasonCodeCategory}</div>
                    </div>
                    <div className="reason-code-category">
                      <div className="reason-title">Reason Code Factor</div>
                      <div className="reason-value">{element.reasonCodeFactor}</div>
                    </div>
                    <div className="reason-code-category">
                      <div className="reason-title">Reason Code</div>
                      <div className="reason-value">{element.reasonCodeTitle}</div>
                    </div>
                    <div className="reason-code-status">
                      <div className="reason-title">Status</div>
                      <div className="reason-value status">
                        <span className={element?.status ? "Active" : "Inactive"}>{element?.status ? "Active" : "Inactive"}</span>
                      </div>
                    </div>
                    <div className="reason-code-status">
                      <div>
                        <div className="reason-title">Created On</div>
                        <div className="reason-value">{dateConverter(element.createdOn)}</div>
                      </div>
                      <div>
                        <div className="reason-title">Last Modified</div>
                        <div className="reason-value">{dateConverter(element.lastModified)}</div>
                      </div>
                    </div>
                  </Card>
                  <EditResonCode
                    category={selectedCategory}
                    factor={selectedFactor}
                    code={codeSelected}
                    data={selectedCodeFilteredData}
                    visible={openEdit}
                    onCancel={() => setOpenEdit(false)}
                    onOk={() => setOpenEdit(false)}
                  />
                </>
              )
            })}
          </div>
        </div>
      </div>
      <EditResonCodeCategory
        open={isEditModalOpen}
        onOk={() => okHandle(isEditModalOpen, setIsEditModalOpen)}
        onCancel={() => cancelHandle(isEditModalOpen, setIsEditModalOpen)}
        selectedCategoryFilterData={selectedCategoryFilterData}
        reasonCodeAllStatus={getAllCategoryChildStatus(reasonCodeFactorDetails)}
      />
      <DeleteResonCode
        childActive={isChildActive}
        factorChild={factorChildActive}
        data={selectedItem}
        category={selectedCategory}
        factor={selectedFactor}
        code={selectedCodeUuid}
        type={type}
        visible={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onOk={() => setIsDeleteModalOpen(false)}
      />
    </>
  );
};
