import { SwitchModuleCard } from "components/Common/SwitchModuleCard";
import { ReactComponent as PlatformIcon } from "assets/icons/brabo-platform.svg";
import { ReactComponent as AssetStudio } from "assets/icons/asset-studio.svg";
import { ReactComponent as ReasonCodeSelection } from "assets/icons/reason-code-selection.svg";
import { Text } from "components/Text";
import { BarboLogo } from "components/Logo";
import "./index.scss";
import { Modal } from "antd";
import { ReactComponent as CloseIcon } from "assets/icons/closeIcon.svg";
import { getUrl } from "config/apiconfig";

export const SwitchModule = (props: any) => {
  const { showSwitchModule, closeModal } = props;

  return (
    <Modal
      centered
      open={showSwitchModule}
      width={730}
      style={{ height: "310px" }}
      closeIcon={<CloseIcon onClick={() => closeModal(false)} />}
      wrapClassName="switch-module-modal"
      footer={null}
      onCancel={() => closeModal(false)}
    >
      <div className="switch-module-parent-container">
        <div className="switch-module-header-container">
          <BarboLogo width="128" height="28" />
          <Text type={"p14"}>
            Please select the module you want to navigate to
          </Text>
        </div>
        <div className="switch-module-data-wrapper">
          <div className="switch-module-data-inner-wrapper">
            <SwitchModuleCard
              openUrl={getUrl("braboPlatform")}
              title={"Brabo Platform"}
              icon={<PlatformIcon />}
            />
            <SwitchModuleCard
              openUrl=""
              disabled={true}
              title={"Asset Studio"}
              icon={<AssetStudio />}
            />
            <SwitchModuleCard
              openUrl={getUrl("reasonCodeSelection")}
              title={"Reason Code Selection"}
              icon={<ReasonCodeSelection />}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
