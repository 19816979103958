import { Button, Modal } from "antd";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Radio } from "antd";
import type { RadioChangeEvent } from "antd";
import { useRef, useState } from "react";
import "./index.scss";
import { ReactComponent as FileIcon } from "assets/icons/file-icon.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close-circle.svg";
import { ReactComponent as CloseFile } from "assets/icons/close-file.svg";
import {
  downloadSampleTemplate,
  uploadFile,
} from "store/services/excel-service";

export const UploadExcel = (props: any) => {
  const { showDialog, closeUploadDialog } = props;
  const [currentFile, setCurrentFile] = useState<any>(null);
  const [disableUpload, setDisableUpload] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const uploadRef = useRef<any>(undefined);

  const handleSave = () => {
    if (currentFile !== null) {
      uploadFile(currentFile);
    }
  };

  const onCancel = () => {
    closeUploadDialog();
    setFileName("");
    setCurrentFile(null);
    setDisableUpload(false);
    uploadRef.current.value = null;
  };

  const onButtonClick = () => {
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  };

  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setDisableUpload(true);
      setFileName(e.target.files[0].name);
      setCurrentFile(e.target.files[0]);
    }
  };

  const downloadTemplate = () => {
    downloadSampleTemplate();
  };

  const resetFileInput = () => {
    uploadRef.current.value = null;
    setFileName("");
    setCurrentFile(null);
    setDisableUpload(false);
  };

  return (
    <Modal
      centered
      open={showDialog}
      onOk={handleSave}
      onCancel={onCancel}
      width={331}
      closeIcon={<></>}
      wrapClassName="upload-excel"
      okText="Save"
    >
      <input
        ref={uploadRef}
        type="file"
        className="input-file-upload"
        onChange={handleChange}
      />
      <div className="upload-heading">
        <p>Upload Production Overview planning excel sheet </p>
        <CloseIcon />
      </div>
      <div className="upload-download-container">
        <div onClick={downloadTemplate} className="download-sample-template">
          <DownloadOutlined />
          <p>Download sample template</p>
        </div>

        <div
          style={{
            textAlign: "center",
            marginBottom: "44px",
            position: "relative",
          }}
        >
          <Button
            type="default"
            className="upload-btn"
            icon={<UploadOutlined />}
            onClick={onButtonClick}
            disabled={disableUpload}
          >
            Upload
          </Button>
        
        </div>
      </div>
      {fileName ? (
            <div className="file-name">
              <FileIcon style={{ marginRight: "6px" }} />
              <p className="file-name-text">{fileName}</p>
              <CloseFile className="file-close-icon" onClick={resetFileInput} />
            </div>
          ) : null}
    </Modal>
  );
};
