import { ReactComponent as DashboardImg } from "assets/icons/dashboardicon.svg";
import { ReactComponent as ProductionIcon } from "assets/icons/production.svg";
import { ReactComponent as LineOverviewIcon } from "assets/icons/line-overview.svg";
import { ReactComponent as RuntimeAnalysisIcon } from "assets/icons/runtime-analysis.svg";
import { ReactComponent as OEEIcon } from "assets/icons/oee-icon.svg";
import { ReactComponent as AlarmsIcon } from "assets/icons/alarms-icon.svg";
import { ReactComponent as DataVisualizationIcon } from "assets/icons/data-visualization.svg";
import { ReactComponent as ReasonCodeIcon } from "assets/icons/reason-icon.svg";
import { ReactComponent as ProcessOrderSummaryIcon } from "assets/icons/process-order.svg";

export const routes = [
  {
    name: "Dashboard",
    breadcrumb: "Dashboard",
    key: "dashboard",
    icon: (
      <DashboardImg
        className="menu-bar-icon"
        style={{ width: "18px", height: "18px", minWidth: "18px" }}
      />
    ),
    path: "/dashboard",
    exact: true,
  },
  {
    name: "Production Overview",
    breadcrumb: "Production Overview",
    key: "production-overview",
    icon: (
      <ProductionIcon
        className="menu-bar-icon"
        style={{ width: "20.6px", height: "16.72px" }}
      />
    ),
    path: "/production-overview",
    exact: true,
  },
  {
    name: "Line Overview",
    breadcrumb: "Line Overview",
    key: "line-overview",
    icon: (
      <LineOverviewIcon
        className="menu-bar-icon"
        style={{ width: "20px", height: "24.4px" }}
      />
    ),
    path: "/line-overview",
    exact: true,
  },
  {
    name: "Runtime Analysis",
    breadcrumb: "Runtime Analysis",
    key: "runtime-analysis",
    icon: (
      <RuntimeAnalysisIcon
        className="menu-bar-icon"
        style={{ width: "20px", height: "19.22px" }}
      />
    ),
    path: "/runtime-analysis",
    exact: true,
  },
  {
    name: "OEE",
    breadcrumb: "OEE",
    key: "oee",
    icon: (
      <OEEIcon
        className="menu-bar-icon"
        style={{ width: "20px", height: "19.44px" }}
      />
    ),
    path: "/oee",
    exact: true,
  },
  {
    name: "Alarm & Events",
    breadcrumb: "Alarm & Events",
    key: "alarm-and-events",
    icon: (
      <AlarmsIcon
        className="menu-bar-icon"
        style={{ width: "20px", height: "17.44px" }}
      />
    ),
    path: "/alarm-and-events",
    exact: true,
  },
  {
    name: "Data Visualization",
    breadcrumb: "Data Visualization",
    key: "data-visualization",
    icon: (
      <DataVisualizationIcon
        className="menu-bar-icon"
        style={{ width: "20px", height: "17.73px" }}
      />
    ),
    path: "/data-visualization",
    exact: true,
    component: <span>Data Visualization</span>,
    openWithTab: false,
  },
  {
    name: "Reason Code Mgmt.",
    breadcrumb: "Reason Code Management",
    key: "reason-code-management",
    icon: (
      <ReasonCodeIcon
        className="menu-bar-icon"
        style={{ width: "20px", height: "19.88px" }}
      />
    ),
    path: "/reason-code-management",
    exact: true,
  },
  {
    name: "Process Order Summary",
    breadcrumb: "Process Order Summary",
    key: "process-order-summary",
    icon: (
      <ProcessOrderSummaryIcon
        className="menu-bar-icon"
        style={{ width: "20px", height: "19.88px" }}
      />
    ),
    path: "/process-order-summary",
    exact: true,
  },
];
