import moment from "moment";
import { machineStatusToString } from "./serviceHelperFunctions";
import convertHoursToMinutes from "./convertHoursToMinutes";
import momentTimezone from "moment-timezone";
import { MINUTES_TILL_EIGHT_O_CLOCK } from "./Constants";

export const ascendingSort = (sortColumn: string, tableBody: any[]) => {
  return tableBody.sort((a, b) => {
    let nameA = a[sortColumn].toLowerCase();
    let nameB = b[sortColumn].toLowerCase();
    if (nameA < nameB) return -1;
    else if (nameA > nameB) return 1;
    return 0;
  });
};
//sort Array of object with Number datatype (ascending)
export const ascendingNumberSort = (sortColumn: string, tableBody: any[]) => {
  return tableBody.sort((a, b) => {
    let nameA = a[sortColumn];
    let nameB = b[sortColumn];
    if (nameA < nameB) return -1;
    else if (nameA > nameB) return 1;
    return 0;
  });
};

export const decendingSort = (sortColumn: string, tableBody: any[]) => {
  return tableBody.sort((a, b) => {
    let nameA = a[sortColumn].toLowerCase();
    let nameB = b[sortColumn].toLowerCase();
    if (nameA > nameB) return -1;
    else if (nameA < nameB) return 1;
    return 0;
  });
};
//sort Array of object with Number datatype (descending)
export const descendingNumberSort = (sortColumn: string, tableBody: any[]) => {
  return tableBody.sort((a, b) => {
    let nameA = a[sortColumn];
    let nameB = b[sortColumn];
    if (nameA > nameB) return -1;
    else if (nameA < nameB) return 1;
    return 0;
  });
};
export const exportCSVFile = (str: string, fileName: string) => {
  let csvData = new Blob([str], { type: "text/csv;charset=utf-8;" });
  let csvURL = window.URL.createObjectURL(csvData);
  let tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", fileName);
  tempLink.click();
  return;
};
export const dateConverter = (value: any) => {
  let getDate = moment(value);
  return getDate.format("DD-MM-YYYY h:mm");
};

export const reasonCodeManagementSearchFilter = (
  data: any,
  searchStatevalue: any,
  setState: any,
  viewType: string
) => {
  if (data && searchStatevalue !== "") {
    const tempCategories = [...data];
    let expandedReasonCodeFactor: any;
    let reasonCodefactor: any;
    const filterData = tempCategories.filter((value: any) => {
      let searchText = searchStatevalue.toLowerCase();
      if (viewType === "grid") {
        value.reasonCodeFactor?.map((item: any) => {
          reasonCodefactor = item.reasonCodeFactor;
        });
        return (
          value.reasonCodeCategory.toLowerCase().includes(searchText) ||
          reasonCodefactor?.toLowerCase().includes(searchText)
        );
      } else if (viewType === "list") {
        return (
          value.reasonCodeCategory.toLowerCase().includes(searchText) ||
          value.reasonCodeFactor.toLowerCase().includes(searchText) ||
          value.reasonCode.toLowerCase().includes(searchText)
        );
      } else {
        value.children?.map((item: any) => {
          expandedReasonCodeFactor = item.reasonCodeFactor;
        });
        return (
          value.reasonCodeCategory.toLowerCase().includes(searchText) ||
          expandedReasonCodeFactor?.toLowerCase().includes(searchText)
        );
      }
    });
    setState(filterData);
  } else {
    setState(data);
  }
};

export const handleKeyPress = (e: any) => {
  if (e.which === 32 && e.target.value.length === 0) {
    e.preventDefault();
  }
};

export const dateToEpoch = (value: any) => {
  var getDate = moment(value, "YYYY-MM-DD h:mm::ss:SSS");
  return getDate.valueOf();
};

export const shortHandLabel = (string: any) => {
  let splitArr: any = string.split("_", -1);
  let flabel: any = splitArr[0].substring(0, 4);
  let l_label: any = splitArr[splitArr.length - 1];
  let arr = [] as any;
  arr.push(flabel, l_label);
  let label = arr.join("_");
  return label;
};
export const allTreeNodeTitle = (data: any) => {
  let node = [] as any;
  let result = [] as any;
  data?.map((item: any) => {
    if (item.children) {
      node = allTreeNodeTitle(item.children);
    }
    result.push(...[item.title, ...node]);
  });
  return result;
};

export const allTreeNodeNameIdMap = (data: any) => {
  let node = [] as any;
  let result = [] as any;
  data?.map((item: any) => {
    if (item.children) {
      node = allTreeNodeNameIdMap(item.children);
    }
    result.push(...[{ title: item.title, id: item.key }, ...node]);
  });
  return result;
};


export const getMachineNodeIds = (data: any) => {
  let idsArray = [] as any;
  const allNodeIdsChild = (data: any) => {
    let node = [] as any;
    data?.map((obj: any) => {
      if (obj.children) node = allNodeIdsChild(obj.children);
      if (obj.children.length === 0) idsArray.push(...[obj.key])
    });
  }
  allNodeIdsChild(data)
  idsArray.filter((v, i, a) => a.indexOf(v) == i)
  return idsArray;
};


//find selected object in machine tree
export const findNestedObj = (entireObj, keyToFind, valToFind) => {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
};

export const machineKeyToStatus = (
  machineKey: any,
  machineStatusArray: any
) => {
  let status = "";
  if (machineStatusArray) {
    machineStatusArray.forEach((element) => {
      if (element.machineId === machineKey) {
        status = machineStatusToString(
          element.machineStatus
        ).toLocaleLowerCase();
      }
    });
  }
  if (status.length) {
    status = status.charAt(0).toUpperCase() + status.slice(1);
  }
  return status;
};

export const machineKeyToMachineName = (machineId, treeData) => {
 let machineIndex = treeData.findIndex((machineObject) => machineObject.id===machineId
  );
  return treeData[machineIndex]?treeData[machineIndex].title:""
};

export let startTimeCalculator = (type: string) => {
  var timeInMinutes = convertHoursToMinutes(moment().utc().format("HH:MM"));
  let currentShiftStart = momentTimezone
    .tz(`${moment().format("YYYY-MM-DD")} 08:00`, "Europe/London")
    .valueOf();

  let currentShiftEnd = momentTimezone
    .tz(moment().format(), "Europe/London")
    .valueOf();
  if (timeInMinutes < MINUTES_TILL_EIGHT_O_CLOCK) {
    currentShiftStart = momentTimezone
      .tz(
        `${moment().subtract(1, "days").format("YYYY-MM-DD")} 08:00`,
        "Europe/London"
      )
      .valueOf();
    currentShiftEnd = momentTimezone
      .tz(moment().format(), "Europe/London")
      .valueOf();
  }
  if (type === "start") return currentShiftStart;
  else return currentShiftEnd;
};