import { message } from 'antd';
import { getUrl } from 'config/apiconfig';
import moment from 'moment';
import { milliToHrs } from 'utils/milliToHrs';
import { milliToMins } from 'utils/milliToMinutes';

export class OeeAnlysisService {
    private static instance: OeeAnlysisService;
    private lineOverviewServiceClient: any;
    private lineOverviewProtoObj: any;
    private lineOverviewModel: any;
    private authToken: any = '';
    private metadata: any = '';

    private constructor() {
        const lineOverviewUrl = getUrl('reasonCodeUrl');
        this.authToken = localStorage.getItem('authToken');
        this.metadata = { 'authorization': 'Bearer ' + this.authToken };
        this.lineOverviewModel = require("protobuf/reason-code-management-protos/model/ReasonCodeOperatorModel_pb");
        const lineOverviewService = require('protobuf/reason-code-management-protos/service/ReasonCodeDashboardService_grpc_web_pb');
        this.lineOverviewProtoObj = require('protobuf/reason-code-management-protos/service/ReasonCodeDashboardService_pb');
        this.lineOverviewServiceClient = new lineOverviewService.ReasonCodeDashboardServicePromiseClient(lineOverviewUrl, null, null);
    }

    public static getInstance(): OeeAnlysisService {
        if (!OeeAnlysisService.instance) {
            OeeAnlysisService.instance = new OeeAnlysisService();
        }
        return OeeAnlysisService.instance;
    }

    getOEEAvailabilityDetails = (payload: any) => {
        const request = new this.lineOverviewProtoObj.OEEAnalysisRequest();
        request.setStartTime(payload.startTime);
        request.setEndTime(payload.endTime);
        request.setSku(payload.sku);
        request.setNodeIdList(payload.nodeId)
        return this.lineOverviewServiceClient
            .getOEEAvailabilityDetails(request, this.metadata)
            .then((response: any) => {
                let data: any[] = [];
                data.push({
                    title: "Planned Downtime",
                    value: milliToHrs(response.getPlannedDowntime()),
                    unit: "hr"
                })
                data.push({
                    title: "Unplanned Downtime",
                    value: milliToHrs(response.getUnplannedDowntime()),
                    unit: "hr"
                })
                data.push({
                    title: "Actual Runtime",
                    value: milliToHrs(response.getActualRuntime()),
                    unit: "hr"
                })
                return data;
            })
            .catch((err: any) => {
                message.error("Unable to get Line Overview OEE Data");
            });
    }

    getOEEQualityDetails = (payload: any) => {
        const request = new this.lineOverviewProtoObj.OEEAnalysisRequest();
        request.setStartTime(payload.startTime);
        request.setEndTime(payload.endTime);
        request.setSku(payload.sku);
        request.setNodeIdList(payload.nodeId)
        return this.lineOverviewServiceClient
            .getOEEQualityDetails(request, this.metadata)
            .then((response: any) => {
                let data: any[] = [];
                data.push({
                    title: "Actual Quantity",
                    value: response.getActualQuantity(),
                    unit: ""
                })
                data.push({
                    title: "Rejected Quantity",
                    value: response.getRejectedQuantity(),
                    unit: ""
                })
                return data;
            })
            .catch((err: any) => {
                message.error("Unable to get Line Overview OEE Data");
            });
    }

    getOEEPerformanceDetails = (payload: any) => {
        const request = new this.lineOverviewProtoObj.OEEAnalysisRequest();
        request.setStartTime(payload.startTime);
        request.setEndTime(payload.endTime);
        request.setSku(payload.sku);
        request.setNodeIdList(payload.nodeId)
        return this.lineOverviewServiceClient
            .getOEEPerfDetails(request, this.metadata)
            .then((response: any) => {
                let data: any[] = [];
                data.push({
                    title: "Actual Quantity",
                    value: response.getActualQuantity(),
                    unit: ""
                })
                data.push({
                    title: "Planned Quantity",
                    value: response.getPlannedQuantity(),
                    unit: ""
                })
                return data;
            })
            .catch((err: any) => {
                message.error("Unable to get Line Overview OEE Data");
            });
    }

    getDownTimeDetails = (payload: any) => {
        const request = new this.lineOverviewProtoObj.OEEAnalysisRequest();
        request.setStartTime(payload.startTime);
        request.setEndTime(payload.endTime);
        request.setSku(payload.sku);
        request.setNodeIdList(payload.nodeId)
        return this.lineOverviewServiceClient
            .getDownTimeDetails(request, this.metadata)
            .then((response: any) => {
                let res = {} as any;
                res.totalDownTime = response.getTotalDowntime();
                res.unplannedDownTime = response.getUnplannedDowntime();
                res.plannedDownTime = response.getPlannedDowntime();
                return res;
            })
            .catch((err: any) => {
                message.error("Unable to get Line Overview OEE Data");
            });
    }

    getOEEMachineDetails = (payload: any) => {
        const request = new this.lineOverviewProtoObj.OEEAnalysisRequest();
        request.setStartTime(payload.startTime);
        request.setEndTime(payload.endTime);
        request.setSku(payload.sku);
        request.setNodeIdList(payload.nodeId)
        return this.lineOverviewServiceClient
            .getOEEMachineWise(request, this.metadata)
            .then((response: any) => {
                return mapOeeMachinewise(response.getOeeMachineList());
            })
            .catch((err: any) => {
                message.error("Unable to get Line Overview OEE Data");
            });
    }

    getOEEByLineData = (payload: any) => {
        const request = new this.lineOverviewProtoObj.OEEAnalysisRequest();
        request.setStartTime(payload.startTime);
        request.setEndTime(payload.endTime);
        request.setSku(payload.sku);
        request.setNodeIdList(payload.nodeId)
        return this.lineOverviewServiceClient
            .getOEEByLine(request, this.metadata)
            .then((response: any) => {

                return mapOeeLinewise(response.getLineOeeList());
            })
            .catch((err: any) => {
                message.error("Unable to get Line Overview OEE Data");
            });
    }


    getPercentageLossService = (payload: any) => {
        const request = new this.lineOverviewProtoObj.OEEAnalysisRequest();
        request.setStartTime(payload.startTime);
        request.setEndTime(payload.endTime);
        request.setSku(payload.sku);
        request.setNodeIdList(payload.nodeId)
        return this.lineOverviewServiceClient
            .getPercentageLossAnalysis(request, this.metadata)
            .then((response: any) => {
                return mapPercentLoss(response);
            })
            .catch((err: any) => {
                message.error("Unable to get Line Overview OEE Data");
            });
    }

    getLossTimeService = (payload: any) => {
        const request = new this.lineOverviewProtoObj.OEEAnalysisRequest();
        request.setStartTime(payload.startTime);
        request.setEndTime(payload.endTime);
        request.setSku(payload.sku);
        request.setNodeIdList(payload.nodeId)
        return this.lineOverviewServiceClient
            .getLossTimeAnalysis(request, this.metadata)
            .then((response: any) => {
                return mapLossTime(response);
            })
            .catch((err: any) => {
                message.error("Unable to get Line Overview OEE Data");
            });
    }

    getOeeSkuService = (payload: any) => {
        const request = new this.lineOverviewProtoObj.OEEAnalysisRequest();
        request.setStartTime(payload.startTime);
        request.setEndTime(payload.endTime);
        request.setSku(payload.sku);
        request.setNodeIdList(payload.nodeId)
        return this.lineOverviewServiceClient
            .getOEEBySku(request, this.metadata)
            .then((response: any) => {
                return mapOeeSkuWise(response.getSkuOeeList());
            })
            .catch((err: any) => {
                message.error("Unable to get Line Overview OEE Data");
            });
    }

    getproductionRateService = (payload: any) => {
        const request = new this.lineOverviewProtoObj.OEEAnalysisRequest();
        request.setStartTime(payload.startTime);
        request.setEndTime(payload.endTime);
        request.setSku(payload.sku);
        request.setNodeIdList(payload.nodeId)
        return this.lineOverviewServiceClient
            .getProductionRate(request, this.metadata)
            .then((response: any) => {
                return mapProductionRate(response);
            })
            .catch((err: any) => {
                message.error("Unable to get Line Overview OEE Data");
            });
    }

    getProductionQualityService = (payload: any) => {
        const request = new this.lineOverviewProtoObj.OEEAnalysisRequest();
        request.setStartTime(payload.startTime);
        request.setEndTime(payload.endTime);
        request.setSku(payload.sku);
        request.setNodeIdList(payload.nodeId)
        return this.lineOverviewServiceClient
            .getProductionQuality(request, this.metadata)
            .then((response: any) => {
                let data: any[] = [];
                let total=response.getActualQuantity()+response.getExpectedQuantity()+response.getTargetQuantity()
                data.push({
                    key: "Actual Quantity",
                    value: response.getActualQuantity(),
                    width:`${(response.getActualQuantity()/total)*100}%`
                })
                data.push({
                    key: "Expected Quantity",
                    value: response.getExpectedQuantity(),
                    width:`${(response.getExpectedQuantity()/total)*100}%`

                })
                data.push({
                    key: "Target Quantity",
                    value: response.getTargetQuantity(),
                    width:`${(response.getTargetQuantity()/total)*100}%`

                })
                return data;
            })
            .catch((err: any) => {
                message.error("Unable to get Line Overview OEE Data");
            });
    }

    getProductiveTimeService = (payload: any) => {
        const request = new this.lineOverviewProtoObj.OEEAnalysisRequest();
        request.setStartTime(payload.startTime);
        request.setEndTime(payload.endTime);
        request.setSku(payload.sku);
        request.setNodeIdList(payload.nodeId)
        return this.lineOverviewServiceClient
            .getProductiveTime(request, this.metadata)
            .then((response: any) => {
                let data: any[] = [];
                let total=response.getProductiveTime()+response.getAvailableTime();
                data.push({
                    key: "Productive Time",
                    value:milliToHrs(response.getProductiveTime()),
                    width: `${(response.getProductiveTime()/total)*100}%`
                })
                data.push({
                    key: "Available Time",
                    value:milliToHrs(response.getAvailableTime()),
                    width: `${(response.getAvailableTime()/total)*100}%`
                })
                return data;
            })
            .catch((err: any) => {
                message.error("Unable to get Line Overview OEE Data");
            });
    }

    getDownTimeDurationCount = (payload: any) => {
        const request = new this.lineOverviewProtoObj.OEEAnalysisRequest();
        request.setStartTime(payload.startTime);
        request.setEndTime(payload.endTime);
        request.setNodeIdList(payload.nodeId);
        request.setSku(payload.sku);
        return this.lineOverviewServiceClient.getMachineReasonCodeDurationCount(request, this.metadata).then((response: any) => {
            return mapDowntimeDurationCount(response.getMachineReasonDurationList());
        }).catch((err: any) => {
            message.error('Unable to get DownTime Duration Count');
        });
    }

    getDownTimePareto = (payload: any) => {
        const request = new this.lineOverviewProtoObj.OEEAnalysisRequest();
        request.setStartTime(payload.startTime);
        request.setEndTime(payload.endTime);
        request.setNodeIdList(payload.nodeId);
        request.setSku(payload.sku);
        return this.lineOverviewServiceClient.getDownTimeWithReasonCode(request, this.metadata).then((response: any) => {

            return mapDownTimePareto(response.getReasoncodeDowntimeList());
        }).catch((err: any) => {
            message.error('Unable to get DownTime Pareto');
        });
    }
}

const mapOeeMachinewise = (data: any) => {
    let oeeMachineData: any[] = [];
    data.forEach((element: any) => {
        oeeMachineData.push({
            country: element.getMachineName(),
            value: element.getOee().toFixed(2),
        });
    });
    return oeeMachineData;
};

const mapOeeLinewise = (data: any) => {
    let oeeMachineData: any[] = [];
    data.forEach((element: any) => {
        oeeMachineData.push({
            label: element.getLineName(),
            value: element.getOee(),
        });
    });
    return oeeMachineData;
};

const mapOeeSkuWise = (data: any) => {
    if (!data.length) return []
    let oeeSkuData: any[] = [];
    data.forEach((element: any) => {
        oeeSkuData.push({
            value: element.getOee(),
            country: element.getSku()+" Ltr",
        });
    });
    let resultData=[...oeeSkuData];
    return resultData;
};

const mapLossTime = (data: any) => {
    let oeeLossData: any[] = [];
    oeeLossData.push({
        label: "OEE Loss",
        value: milliToMins(data.getOeeLossTime())
    })

    oeeLossData.push({
        label: "Performance Loss",
        value: milliToMins(data.getPerformanceLossTime())
    })
    oeeLossData.push({
        label: "Quality Loss",
        value: milliToMins( data.getQualityLossTime())
    })
    oeeLossData.push({
        label: "Availability Loss",
        value: milliToMins(data.getAvailabilityLossTime())
    })
    return oeeLossData;
};

const mapPercentLoss = (data: any) => {
    let oeeLossData: any[] = [];
    oeeLossData.push({
        label: "Performance Loss",
        value: data.getPerformanceLossPercent()
    })
    oeeLossData.push({
        label: "Quality Loss",
        value: data.getQualityLossPercent()
    })
    oeeLossData.push({
        label: "Availability Loss",
        value: data.getAvailabilityLossPercent()
    })
    return oeeLossData;
};

const mapDowntimeDurationCount = (details: any) => {
    let downTimeDurationList: any = [];
    let downTimeCountList: any = [];
    let reasonCodeName:any=[]
    details.forEach((element: any, index: any) => {
        let countData: any = {}
        element.getReasoncodeDurationList().forEach((item: any, index: any) => {
            countData[item.getReasonCode()] = milliToMins(item.getDowntime());
        })
        countData.equipmentName = element.getMachine();
        downTimeDurationList.push(countData)
    })
    details.forEach((element: any, index: any) => {
        let durationData: any = {}
        element.getReasoncodeDurationList().forEach((item: any, index: any) => {
            durationData[item.getReasonCode()] = item.getCount();
        })
        durationData.equipmentName = element.getMachine();
        downTimeCountList.push(durationData)
    })
    details.forEach((element:any)=>{
        element.getReasoncodeDurationList().forEach((item: any, index: any) => {
            reasonCodeName.push( item.getReasonCode());
        })
    })
    return { downTimeDurationList, downTimeCountList,reasonCodeName }
}

const mapDownTimePareto = (data: any) => {
    let downTimeParetoList: any[] = [];
    data.forEach((element: any, index: any) => {
        downTimeParetoList.push({
            key: index,
            category: element.getReasonCode(),
            value:  milliToMins(element.getDowntime())
        })
    });
    return downTimeParetoList;
}

const mapProductionRate =(data:any)=>{
    let productionRate=data.getProductionRate();
    let targetRate=data.getTargetRate();
    return {productionRate,targetRate}
}