import { Button } from "antd";
import { ReactComponent as BraboImg } from "assets/images/brabo.svg";
import './login.scss';

export const SessionExpired = (props: any) => {
  const { openLogin } = props;
  const enterKeyPress = () => { }
  const navigateLogin = () => { 
    openLogin('login');
  }
  return (
    <div className="data-container">
      <BraboImg className="brabo-image" />
      <p className="session-expiry-text">Your session has expired due to inactivity </p>
      <p className="refresh-text">Please refresh the page</p>
      <Button
        type="primary"
        className="verify-otp"
        onKeyDown={() => enterKeyPress}
        onClick={navigateLogin}
      >
        Log back in
      </Button>

    </div>
  )
}