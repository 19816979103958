// source: model/ProductionOverview.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_Common_pb = require('../common/Common_pb.js');
goog.object.extend(proto, common_Common_pb);
var model_AlarmDetails_pb = require('../model/AlarmDetails_pb.js');
goog.object.extend(proto, model_AlarmDetails_pb);
goog.exportSymbol('proto.upl_sandbach.AlarmAndErrorPareto', null, global);
goog.exportSymbol('proto.upl_sandbach.AverageCycleTime', null, global);
goog.exportSymbol('proto.upl_sandbach.BatchCount', null, global);
goog.exportSymbol('proto.upl_sandbach.BatchData', null, global);
goog.exportSymbol('proto.upl_sandbach.BatchDetails', null, global);
goog.exportSymbol('proto.upl_sandbach.BatchesDataFromAFPack', null, global);
goog.exportSymbol('proto.upl_sandbach.LineFilter', null, global);
goog.exportSymbol('proto.upl_sandbach.MachineAndBatchInfo', null, global);
goog.exportSymbol('proto.upl_sandbach.OEEPieChart', null, global);
goog.exportSymbol('proto.upl_sandbach.PPBatchesDetails', null, global);
goog.exportSymbol('proto.upl_sandbach.PPBatchesFilter', null, global);
goog.exportSymbol('proto.upl_sandbach.PPUpdateBatchRejectCounts', null, global);
goog.exportSymbol('proto.upl_sandbach.PackagingDetails', null, global);
goog.exportSymbol('proto.upl_sandbach.ProcessOrder', null, global);
goog.exportSymbol('proto.upl_sandbach.ProcessOrderEndTimeDetails', null, global);
goog.exportSymbol('proto.upl_sandbach.ProductionDashboardFilter', null, global);
goog.exportSymbol('proto.upl_sandbach.ProductionDashboardOverview', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.BatchData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.BatchData.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.BatchData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.BatchData.displayName = 'proto.upl_sandbach.BatchData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.BatchCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.BatchCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.BatchCount.displayName = 'proto.upl_sandbach.BatchCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.PackagingDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.PackagingDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.PackagingDetails.displayName = 'proto.upl_sandbach.PackagingDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.LineFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.LineFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.LineFilter.displayName = 'proto.upl_sandbach.LineFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.BatchDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.BatchDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.BatchDetails.displayName = 'proto.upl_sandbach.BatchDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.PPBatchesFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.PPBatchesFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.PPBatchesFilter.displayName = 'proto.upl_sandbach.PPBatchesFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.PPBatchesDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.PPBatchesDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.PPBatchesDetails.displayName = 'proto.upl_sandbach.PPBatchesDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.PPUpdateBatchRejectCounts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.PPUpdateBatchRejectCounts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.PPUpdateBatchRejectCounts.displayName = 'proto.upl_sandbach.PPUpdateBatchRejectCounts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.BatchesDataFromAFPack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.BatchesDataFromAFPack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.BatchesDataFromAFPack.displayName = 'proto.upl_sandbach.BatchesDataFromAFPack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.ProcessOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.ProcessOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.ProcessOrder.displayName = 'proto.upl_sandbach.ProcessOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.ProcessOrderEndTimeDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.ProcessOrderEndTimeDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.ProcessOrderEndTimeDetails.displayName = 'proto.upl_sandbach.ProcessOrderEndTimeDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.MachineAndBatchInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.MachineAndBatchInfo.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.MachineAndBatchInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.MachineAndBatchInfo.displayName = 'proto.upl_sandbach.MachineAndBatchInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.ProductionDashboardFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.ProductionDashboardFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.ProductionDashboardFilter.displayName = 'proto.upl_sandbach.ProductionDashboardFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.OEEPieChart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.OEEPieChart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.OEEPieChart.displayName = 'proto.upl_sandbach.OEEPieChart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AverageCycleTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.AverageCycleTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AverageCycleTime.displayName = 'proto.upl_sandbach.AverageCycleTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AlarmAndErrorPareto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.AlarmAndErrorPareto.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.AlarmAndErrorPareto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AlarmAndErrorPareto.displayName = 'proto.upl_sandbach.AlarmAndErrorPareto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.ProductionDashboardOverview = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.ProductionDashboardOverview, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.ProductionDashboardOverview.displayName = 'proto.upl_sandbach.ProductionDashboardOverview';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.BatchData.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.BatchData.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.BatchData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.BatchData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchData.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    productionOrderNumber: jspb.Message.getFieldWithDefault(msg, 20, ""),
    elapsedTime: jspb.Message.getFieldWithDefault(msg, 30, ""),
    manufacturingTime: jspb.Message.getFieldWithDefault(msg, 40, ""),
    material: jspb.Message.getFieldWithDefault(msg, 50, ""),
    orderQuantity: jspb.Message.getFieldWithDefault(msg, 60, ""),
    startupReject: jspb.Message.getFloatingPointFieldWithDefault(msg, 70, 0.0),
    productionReject: jspb.Message.getFloatingPointFieldWithDefault(msg, 80, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.BatchData}
 */
proto.upl_sandbach.BatchData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.BatchData;
  return proto.upl_sandbach.BatchData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.BatchData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.BatchData}
 */
proto.upl_sandbach.BatchData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addBatches(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductionOrderNumber(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setElapsedTime(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setManufacturingTime(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterial(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderQuantity(value);
      break;
    case 70:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStartupReject(value);
      break;
    case 80:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProductionReject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.BatchData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.BatchData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.BatchData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getProductionOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getElapsedTime();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getManufacturingTime();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getMaterial();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getOrderQuantity();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getStartupReject();
  if (f !== 0.0) {
    writer.writeFloat(
      70,
      f
    );
  }
  f = message.getProductionReject();
  if (f !== 0.0) {
    writer.writeFloat(
      80,
      f
    );
  }
};


/**
 * repeated string batches = 10;
 * @return {!Array<string>}
 */
proto.upl_sandbach.BatchData.prototype.getBatchesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.upl_sandbach.BatchData} returns this
 */
proto.upl_sandbach.BatchData.prototype.setBatchesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.BatchData} returns this
 */
proto.upl_sandbach.BatchData.prototype.addBatches = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.BatchData} returns this
 */
proto.upl_sandbach.BatchData.prototype.clearBatchesList = function() {
  return this.setBatchesList([]);
};


/**
 * optional string production_order_number = 20;
 * @return {string}
 */
proto.upl_sandbach.BatchData.prototype.getProductionOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchData} returns this
 */
proto.upl_sandbach.BatchData.prototype.setProductionOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string elapsed_time = 30;
 * @return {string}
 */
proto.upl_sandbach.BatchData.prototype.getElapsedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchData} returns this
 */
proto.upl_sandbach.BatchData.prototype.setElapsedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string manufacturing_time = 40;
 * @return {string}
 */
proto.upl_sandbach.BatchData.prototype.getManufacturingTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchData} returns this
 */
proto.upl_sandbach.BatchData.prototype.setManufacturingTime = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string material = 50;
 * @return {string}
 */
proto.upl_sandbach.BatchData.prototype.getMaterial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchData} returns this
 */
proto.upl_sandbach.BatchData.prototype.setMaterial = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string order_quantity = 60;
 * @return {string}
 */
proto.upl_sandbach.BatchData.prototype.getOrderQuantity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchData} returns this
 */
proto.upl_sandbach.BatchData.prototype.setOrderQuantity = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional float startup_reject = 70;
 * @return {number}
 */
proto.upl_sandbach.BatchData.prototype.getStartupReject = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 70, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BatchData} returns this
 */
proto.upl_sandbach.BatchData.prototype.setStartupReject = function(value) {
  return jspb.Message.setProto3FloatField(this, 70, value);
};


/**
 * optional float production_reject = 80;
 * @return {number}
 */
proto.upl_sandbach.BatchData.prototype.getProductionReject = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 80, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BatchData} returns this
 */
proto.upl_sandbach.BatchData.prototype.setProductionReject = function(value) {
  return jspb.Message.setProto3FloatField(this, 80, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.BatchCount.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.BatchCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.BatchCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    inCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    wipCount: jspb.Message.getFieldWithDefault(msg, 20, 0),
    goodCount: jspb.Message.getFieldWithDefault(msg, 30, 0),
    rejectCount: jspb.Message.getFieldWithDefault(msg, 40, 0),
    endOfLineCount: jspb.Message.getFieldWithDefault(msg, 50, 0),
    totalCount: jspb.Message.getFieldWithDefault(msg, 60, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.BatchCount}
 */
proto.upl_sandbach.BatchCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.BatchCount;
  return proto.upl_sandbach.BatchCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.BatchCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.BatchCount}
 */
proto.upl_sandbach.BatchCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInCount(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWipCount(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGoodCount(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRejectCount(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndOfLineCount(value);
      break;
    case 60:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.BatchCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.BatchCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.BatchCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInCount();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getWipCount();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getGoodCount();
  if (f !== 0) {
    writer.writeInt32(
      30,
      f
    );
  }
  f = message.getRejectCount();
  if (f !== 0) {
    writer.writeInt32(
      40,
      f
    );
  }
  f = message.getEndOfLineCount();
  if (f !== 0) {
    writer.writeInt32(
      50,
      f
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      60,
      f
    );
  }
};


/**
 * optional int32 in_count = 10;
 * @return {number}
 */
proto.upl_sandbach.BatchCount.prototype.getInCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BatchCount} returns this
 */
proto.upl_sandbach.BatchCount.prototype.setInCount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 wip_count = 20;
 * @return {number}
 */
proto.upl_sandbach.BatchCount.prototype.getWipCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BatchCount} returns this
 */
proto.upl_sandbach.BatchCount.prototype.setWipCount = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 good_count = 30;
 * @return {number}
 */
proto.upl_sandbach.BatchCount.prototype.getGoodCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BatchCount} returns this
 */
proto.upl_sandbach.BatchCount.prototype.setGoodCount = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional int32 reject_count = 40;
 * @return {number}
 */
proto.upl_sandbach.BatchCount.prototype.getRejectCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BatchCount} returns this
 */
proto.upl_sandbach.BatchCount.prototype.setRejectCount = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional int32 end_of_line_count = 50;
 * @return {number}
 */
proto.upl_sandbach.BatchCount.prototype.getEndOfLineCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BatchCount} returns this
 */
proto.upl_sandbach.BatchCount.prototype.setEndOfLineCount = function(value) {
  return jspb.Message.setProto3IntField(this, 50, value);
};


/**
 * optional int32 total_count = 60;
 * @return {number}
 */
proto.upl_sandbach.BatchCount.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BatchCount} returns this
 */
proto.upl_sandbach.BatchCount.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 60, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.PackagingDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.PackagingDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.PackagingDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PackagingDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCaseCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    totalFinishedPallets: jspb.Message.getFieldWithDefault(msg, 20, 0),
    totalUnfinishedPallets: jspb.Message.getFieldWithDefault(msg, 30, 0),
    leftOverBottles: jspb.Message.getFieldWithDefault(msg, 40, 0),
    leftOverCases: jspb.Message.getFieldWithDefault(msg, 50, 0),
    carryForwardedBottles: jspb.Message.getFieldWithDefault(msg, 60, 0),
    carryForwardedCases: jspb.Message.getFieldWithDefault(msg, 70, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.PackagingDetails}
 */
proto.upl_sandbach.PackagingDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.PackagingDetails;
  return proto.upl_sandbach.PackagingDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.PackagingDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.PackagingDetails}
 */
proto.upl_sandbach.PackagingDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCaseCount(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalFinishedPallets(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalUnfinishedPallets(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeftOverBottles(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeftOverCases(value);
      break;
    case 60:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCarryForwardedBottles(value);
      break;
    case 70:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCarryForwardedCases(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.PackagingDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.PackagingDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.PackagingDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PackagingDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCaseCount();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getTotalFinishedPallets();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getTotalUnfinishedPallets();
  if (f !== 0) {
    writer.writeInt32(
      30,
      f
    );
  }
  f = message.getLeftOverBottles();
  if (f !== 0) {
    writer.writeInt32(
      40,
      f
    );
  }
  f = message.getLeftOverCases();
  if (f !== 0) {
    writer.writeInt32(
      50,
      f
    );
  }
  f = message.getCarryForwardedBottles();
  if (f !== 0) {
    writer.writeInt32(
      60,
      f
    );
  }
  f = message.getCarryForwardedCases();
  if (f !== 0) {
    writer.writeInt32(
      70,
      f
    );
  }
};


/**
 * optional int32 total_case_count = 10;
 * @return {number}
 */
proto.upl_sandbach.PackagingDetails.prototype.getTotalCaseCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PackagingDetails} returns this
 */
proto.upl_sandbach.PackagingDetails.prototype.setTotalCaseCount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 total_finished_pallets = 20;
 * @return {number}
 */
proto.upl_sandbach.PackagingDetails.prototype.getTotalFinishedPallets = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PackagingDetails} returns this
 */
proto.upl_sandbach.PackagingDetails.prototype.setTotalFinishedPallets = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 total_unfinished_pallets = 30;
 * @return {number}
 */
proto.upl_sandbach.PackagingDetails.prototype.getTotalUnfinishedPallets = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PackagingDetails} returns this
 */
proto.upl_sandbach.PackagingDetails.prototype.setTotalUnfinishedPallets = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional int32 left_over_bottles = 40;
 * @return {number}
 */
proto.upl_sandbach.PackagingDetails.prototype.getLeftOverBottles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PackagingDetails} returns this
 */
proto.upl_sandbach.PackagingDetails.prototype.setLeftOverBottles = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional int32 left_over_cases = 50;
 * @return {number}
 */
proto.upl_sandbach.PackagingDetails.prototype.getLeftOverCases = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PackagingDetails} returns this
 */
proto.upl_sandbach.PackagingDetails.prototype.setLeftOverCases = function(value) {
  return jspb.Message.setProto3IntField(this, 50, value);
};


/**
 * optional int32 carry_forwarded_bottles = 60;
 * @return {number}
 */
proto.upl_sandbach.PackagingDetails.prototype.getCarryForwardedBottles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PackagingDetails} returns this
 */
proto.upl_sandbach.PackagingDetails.prototype.setCarryForwardedBottles = function(value) {
  return jspb.Message.setProto3IntField(this, 60, value);
};


/**
 * optional int32 carry_forwarded_cases = 70;
 * @return {number}
 */
proto.upl_sandbach.PackagingDetails.prototype.getCarryForwardedCases = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 70, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PackagingDetails} returns this
 */
proto.upl_sandbach.PackagingDetails.prototype.setCarryForwardedCases = function(value) {
  return jspb.Message.setProto3IntField(this, 70, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.LineFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.LineFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.LineFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.LineFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    line: jspb.Message.getFieldWithDefault(msg, 10, ""),
    sku: jspb.Message.getFieldWithDefault(msg, 15, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 20, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 30, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.LineFilter}
 */
proto.upl_sandbach.LineFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.LineFilter;
  return proto.upl_sandbach.LineFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.LineFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.LineFilter}
 */
proto.upl_sandbach.LineFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.LineFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.LineFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.LineFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.LineFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLine();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      30,
      f
    );
  }
};


/**
 * optional string line = 10;
 * @return {string}
 */
proto.upl_sandbach.LineFilter.prototype.getLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.LineFilter} returns this
 */
proto.upl_sandbach.LineFilter.prototype.setLine = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string sku = 15;
 * @return {string}
 */
proto.upl_sandbach.LineFilter.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.LineFilter} returns this
 */
proto.upl_sandbach.LineFilter.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int64 start_time = 20;
 * @return {number}
 */
proto.upl_sandbach.LineFilter.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.LineFilter} returns this
 */
proto.upl_sandbach.LineFilter.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int64 end_time = 30;
 * @return {number}
 */
proto.upl_sandbach.LineFilter.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.LineFilter} returns this
 */
proto.upl_sandbach.LineFilter.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.BatchDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.BatchDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.BatchDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    productionOrderNumber: jspb.Message.getFieldWithDefault(msg, 20, ""),
    productDescription: jspb.Message.getFieldWithDefault(msg, 30, ""),
    sku: jspb.Message.getFieldWithDefault(msg, 40, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 50, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 70, 0),
    orderQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 90, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.BatchDetails}
 */
proto.upl_sandbach.BatchDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.BatchDetails;
  return proto.upl_sandbach.BatchDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.BatchDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.BatchDetails}
 */
proto.upl_sandbach.BatchDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNumber(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductionOrderNumber(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductDescription(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 70:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 90:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOrderQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.BatchDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.BatchDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.BatchDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getProductionOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getProductDescription();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      50,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      70,
      f
    );
  }
  f = message.getOrderQuantity();
  if (f !== 0.0) {
    writer.writeFloat(
      90,
      f
    );
  }
};


/**
 * optional string batch_number = 10;
 * @return {string}
 */
proto.upl_sandbach.BatchDetails.prototype.getBatchNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchDetails} returns this
 */
proto.upl_sandbach.BatchDetails.prototype.setBatchNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string production_order_number = 20;
 * @return {string}
 */
proto.upl_sandbach.BatchDetails.prototype.getProductionOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchDetails} returns this
 */
proto.upl_sandbach.BatchDetails.prototype.setProductionOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string product_description = 30;
 * @return {string}
 */
proto.upl_sandbach.BatchDetails.prototype.getProductDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchDetails} returns this
 */
proto.upl_sandbach.BatchDetails.prototype.setProductDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string sku = 40;
 * @return {string}
 */
proto.upl_sandbach.BatchDetails.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchDetails} returns this
 */
proto.upl_sandbach.BatchDetails.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional int64 start_time = 50;
 * @return {number}
 */
proto.upl_sandbach.BatchDetails.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BatchDetails} returns this
 */
proto.upl_sandbach.BatchDetails.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 50, value);
};


/**
 * optional int64 end_time = 70;
 * @return {number}
 */
proto.upl_sandbach.BatchDetails.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 70, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BatchDetails} returns this
 */
proto.upl_sandbach.BatchDetails.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 70, value);
};


/**
 * optional float order_quantity = 90;
 * @return {number}
 */
proto.upl_sandbach.BatchDetails.prototype.getOrderQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 90, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BatchDetails} returns this
 */
proto.upl_sandbach.BatchDetails.prototype.setOrderQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 90, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.PPBatchesFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.PPBatchesFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.PPBatchesFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PPBatchesFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchString: jspb.Message.getFieldWithDefault(msg, 10, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 20, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 30, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.PPBatchesFilter}
 */
proto.upl_sandbach.PPBatchesFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.PPBatchesFilter;
  return proto.upl_sandbach.PPBatchesFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.PPBatchesFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.PPBatchesFilter}
 */
proto.upl_sandbach.PPBatchesFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchString(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.PPBatchesFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.PPBatchesFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.PPBatchesFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PPBatchesFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchString();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      30,
      f
    );
  }
};


/**
 * optional string search_string = 10;
 * @return {string}
 */
proto.upl_sandbach.PPBatchesFilter.prototype.getSearchString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.PPBatchesFilter} returns this
 */
proto.upl_sandbach.PPBatchesFilter.prototype.setSearchString = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 start_time = 20;
 * @return {number}
 */
proto.upl_sandbach.PPBatchesFilter.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PPBatchesFilter} returns this
 */
proto.upl_sandbach.PPBatchesFilter.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int64 end_time = 30;
 * @return {number}
 */
proto.upl_sandbach.PPBatchesFilter.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PPBatchesFilter} returns this
 */
proto.upl_sandbach.PPBatchesFilter.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.PPBatchesDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.PPBatchesDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PPBatchesDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    productionOrderNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    batchNumber: jspb.Message.getFieldWithDefault(msg, 15, ""),
    productDescription: jspb.Message.getFieldWithDefault(msg, 20, ""),
    line: jspb.Message.getFieldWithDefault(msg, 30, ""),
    sku: jspb.Message.getFieldWithDefault(msg, 40, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 50, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 60, 0),
    material: jspb.Message.getFieldWithDefault(msg, 70, ""),
    orderQuantity: jspb.Message.getFieldWithDefault(msg, 80, 0),
    totalQuantityProduced: jspb.Message.getFieldWithDefault(msg, 90, 0),
    goodQuantity: jspb.Message.getFieldWithDefault(msg, 100, 0),
    totalReject: jspb.Message.getFieldWithDefault(msg, 110, 0),
    startupReject: jspb.Message.getFieldWithDefault(msg, 120, 0),
    productionReject: jspb.Message.getFieldWithDefault(msg, 130, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.PPBatchesDetails}
 */
proto.upl_sandbach.PPBatchesDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.PPBatchesDetails;
  return proto.upl_sandbach.PPBatchesDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.PPBatchesDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.PPBatchesDetails}
 */
proto.upl_sandbach.PPBatchesDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductionOrderNumber(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNumber(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductDescription(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 60:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterial(value);
      break;
    case 80:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderQuantity(value);
      break;
    case 90:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalQuantityProduced(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoodQuantity(value);
      break;
    case 110:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalReject(value);
      break;
    case 120:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartupReject(value);
      break;
    case 130:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProductionReject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.PPBatchesDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.PPBatchesDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PPBatchesDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductionOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBatchNumber();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getProductDescription();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getLine();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      50,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      60,
      f
    );
  }
  f = message.getMaterial();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getOrderQuantity();
  if (f !== 0) {
    writer.writeInt64(
      80,
      f
    );
  }
  f = message.getTotalQuantityProduced();
  if (f !== 0) {
    writer.writeInt64(
      90,
      f
    );
  }
  f = message.getGoodQuantity();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
  f = message.getTotalReject();
  if (f !== 0) {
    writer.writeInt64(
      110,
      f
    );
  }
  f = message.getStartupReject();
  if (f !== 0) {
    writer.writeInt64(
      120,
      f
    );
  }
  f = message.getProductionReject();
  if (f !== 0) {
    writer.writeInt64(
      130,
      f
    );
  }
};


/**
 * optional string production_order_number = 10;
 * @return {string}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.getProductionOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.PPBatchesDetails} returns this
 */
proto.upl_sandbach.PPBatchesDetails.prototype.setProductionOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string batch_number = 15;
 * @return {string}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.getBatchNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.PPBatchesDetails} returns this
 */
proto.upl_sandbach.PPBatchesDetails.prototype.setBatchNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string product_description = 20;
 * @return {string}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.getProductDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.PPBatchesDetails} returns this
 */
proto.upl_sandbach.PPBatchesDetails.prototype.setProductDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string line = 30;
 * @return {string}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.getLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.PPBatchesDetails} returns this
 */
proto.upl_sandbach.PPBatchesDetails.prototype.setLine = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string sku = 40;
 * @return {string}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.PPBatchesDetails} returns this
 */
proto.upl_sandbach.PPBatchesDetails.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional int64 start_time = 50;
 * @return {number}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PPBatchesDetails} returns this
 */
proto.upl_sandbach.PPBatchesDetails.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 50, value);
};


/**
 * optional int64 end_time = 60;
 * @return {number}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PPBatchesDetails} returns this
 */
proto.upl_sandbach.PPBatchesDetails.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 60, value);
};


/**
 * optional string material = 70;
 * @return {string}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.getMaterial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.PPBatchesDetails} returns this
 */
proto.upl_sandbach.PPBatchesDetails.prototype.setMaterial = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional int64 order_quantity = 80;
 * @return {number}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.getOrderQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 80, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PPBatchesDetails} returns this
 */
proto.upl_sandbach.PPBatchesDetails.prototype.setOrderQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 80, value);
};


/**
 * optional int64 total_quantity_produced = 90;
 * @return {number}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.getTotalQuantityProduced = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 90, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PPBatchesDetails} returns this
 */
proto.upl_sandbach.PPBatchesDetails.prototype.setTotalQuantityProduced = function(value) {
  return jspb.Message.setProto3IntField(this, 90, value);
};


/**
 * optional int64 good_quantity = 100;
 * @return {number}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.getGoodQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PPBatchesDetails} returns this
 */
proto.upl_sandbach.PPBatchesDetails.prototype.setGoodQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 100, value);
};


/**
 * optional int64 total_reject = 110;
 * @return {number}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.getTotalReject = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 110, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PPBatchesDetails} returns this
 */
proto.upl_sandbach.PPBatchesDetails.prototype.setTotalReject = function(value) {
  return jspb.Message.setProto3IntField(this, 110, value);
};


/**
 * optional int64 startup_reject = 120;
 * @return {number}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.getStartupReject = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 120, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PPBatchesDetails} returns this
 */
proto.upl_sandbach.PPBatchesDetails.prototype.setStartupReject = function(value) {
  return jspb.Message.setProto3IntField(this, 120, value);
};


/**
 * optional int64 production_reject = 130;
 * @return {number}
 */
proto.upl_sandbach.PPBatchesDetails.prototype.getProductionReject = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 130, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PPBatchesDetails} returns this
 */
proto.upl_sandbach.PPBatchesDetails.prototype.setProductionReject = function(value) {
  return jspb.Message.setProto3IntField(this, 130, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.PPUpdateBatchRejectCounts.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.PPUpdateBatchRejectCounts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.PPUpdateBatchRejectCounts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PPUpdateBatchRejectCounts.toObject = function(includeInstance, msg) {
  var f, obj = {
    productionOrderNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    batchStartTime: jspb.Message.getFieldWithDefault(msg, 20, 0),
    startupReject: jspb.Message.getFieldWithDefault(msg, 30, 0),
    productionReject: jspb.Message.getFieldWithDefault(msg, 40, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCounts}
 */
proto.upl_sandbach.PPUpdateBatchRejectCounts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.PPUpdateBatchRejectCounts;
  return proto.upl_sandbach.PPUpdateBatchRejectCounts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.PPUpdateBatchRejectCounts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCounts}
 */
proto.upl_sandbach.PPUpdateBatchRejectCounts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductionOrderNumber(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBatchStartTime(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartupReject(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProductionReject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.PPUpdateBatchRejectCounts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.PPUpdateBatchRejectCounts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.PPUpdateBatchRejectCounts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.PPUpdateBatchRejectCounts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductionOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBatchStartTime();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getStartupReject();
  if (f !== 0) {
    writer.writeInt64(
      30,
      f
    );
  }
  f = message.getProductionReject();
  if (f !== 0) {
    writer.writeInt64(
      40,
      f
    );
  }
};


/**
 * optional string production_order_number = 10;
 * @return {string}
 */
proto.upl_sandbach.PPUpdateBatchRejectCounts.prototype.getProductionOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCounts} returns this
 */
proto.upl_sandbach.PPUpdateBatchRejectCounts.prototype.setProductionOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 batch_start_time = 20;
 * @return {number}
 */
proto.upl_sandbach.PPUpdateBatchRejectCounts.prototype.getBatchStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCounts} returns this
 */
proto.upl_sandbach.PPUpdateBatchRejectCounts.prototype.setBatchStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int64 startup_reject = 30;
 * @return {number}
 */
proto.upl_sandbach.PPUpdateBatchRejectCounts.prototype.getStartupReject = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCounts} returns this
 */
proto.upl_sandbach.PPUpdateBatchRejectCounts.prototype.setStartupReject = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional int64 production_reject = 40;
 * @return {number}
 */
proto.upl_sandbach.PPUpdateBatchRejectCounts.prototype.getProductionReject = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.PPUpdateBatchRejectCounts} returns this
 */
proto.upl_sandbach.PPUpdateBatchRejectCounts.prototype.setProductionReject = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.BatchesDataFromAFPack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.BatchesDataFromAFPack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchesDataFromAFPack.toObject = function(includeInstance, msg) {
  var f, obj = {
    productionOrderNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    batchNumber: jspb.Message.getFieldWithDefault(msg, 15, ""),
    productDescription: jspb.Message.getFieldWithDefault(msg, 20, ""),
    line: jspb.Message.getFieldWithDefault(msg, 30, ""),
    sku: jspb.Message.getFieldWithDefault(msg, 40, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 50, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 60, ""),
    material: jspb.Message.getFieldWithDefault(msg, 70, ""),
    orderQuantity: jspb.Message.getFieldWithDefault(msg, 80, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.BatchesDataFromAFPack}
 */
proto.upl_sandbach.BatchesDataFromAFPack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.BatchesDataFromAFPack;
  return proto.upl_sandbach.BatchesDataFromAFPack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.BatchesDataFromAFPack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.BatchesDataFromAFPack}
 */
proto.upl_sandbach.BatchesDataFromAFPack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductionOrderNumber(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNumber(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductDescription(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterial(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.BatchesDataFromAFPack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.BatchesDataFromAFPack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchesDataFromAFPack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductionOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBatchNumber();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getProductDescription();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getLine();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getMaterial();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getOrderQuantity();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
};


/**
 * optional string production_order_number = 10;
 * @return {string}
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.getProductionOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchesDataFromAFPack} returns this
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.setProductionOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string batch_number = 15;
 * @return {string}
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.getBatchNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchesDataFromAFPack} returns this
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.setBatchNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string product_description = 20;
 * @return {string}
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.getProductDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchesDataFromAFPack} returns this
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.setProductDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string line = 30;
 * @return {string}
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.getLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchesDataFromAFPack} returns this
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.setLine = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string sku = 40;
 * @return {string}
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchesDataFromAFPack} returns this
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string start_time = 50;
 * @return {string}
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchesDataFromAFPack} returns this
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string end_time = 60;
 * @return {string}
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchesDataFromAFPack} returns this
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional string material = 70;
 * @return {string}
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.getMaterial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchesDataFromAFPack} returns this
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.setMaterial = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional string order_quantity = 80;
 * @return {string}
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.getOrderQuantity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchesDataFromAFPack} returns this
 */
proto.upl_sandbach.BatchesDataFromAFPack.prototype.setOrderQuantity = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.ProcessOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.ProcessOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.ProcessOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ProcessOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    productionOrderNumber: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.ProcessOrder}
 */
proto.upl_sandbach.ProcessOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.ProcessOrder;
  return proto.upl_sandbach.ProcessOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.ProcessOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.ProcessOrder}
 */
proto.upl_sandbach.ProcessOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductionOrderNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.ProcessOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.ProcessOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.ProcessOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ProcessOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductionOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string production_order_number = 10;
 * @return {string}
 */
proto.upl_sandbach.ProcessOrder.prototype.getProductionOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ProcessOrder} returns this
 */
proto.upl_sandbach.ProcessOrder.prototype.setProductionOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.ProcessOrderEndTimeDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.ProcessOrderEndTimeDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.ProcessOrderEndTimeDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ProcessOrderEndTimeDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    processOrder: jspb.Message.getFieldWithDefault(msg, 10, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.ProcessOrderEndTimeDetails}
 */
proto.upl_sandbach.ProcessOrderEndTimeDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.ProcessOrderEndTimeDetails;
  return proto.upl_sandbach.ProcessOrderEndTimeDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.ProcessOrderEndTimeDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.ProcessOrderEndTimeDetails}
 */
proto.upl_sandbach.ProcessOrderEndTimeDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessOrder(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.ProcessOrderEndTimeDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.ProcessOrderEndTimeDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.ProcessOrderEndTimeDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ProcessOrderEndTimeDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessOrder();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string process_order = 10;
 * @return {string}
 */
proto.upl_sandbach.ProcessOrderEndTimeDetails.prototype.getProcessOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ProcessOrderEndTimeDetails} returns this
 */
proto.upl_sandbach.ProcessOrderEndTimeDetails.prototype.setProcessOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string end_time = 20;
 * @return {string}
 */
proto.upl_sandbach.ProcessOrderEndTimeDetails.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ProcessOrderEndTimeDetails} returns this
 */
proto.upl_sandbach.ProcessOrderEndTimeDetails.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.MachineAndBatchInfo.repeatedFields_ = [10,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.MachineAndBatchInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.MachineAndBatchInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.MachineAndBatchInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.MachineAndBatchInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineInfoList: jspb.Message.toObjectList(msg.getMachineInfoList(),
    common_Common_pb.DropDownData.toObject, includeInstance),
    batchInfoList: jspb.Message.toObjectList(msg.getBatchInfoList(),
    common_Common_pb.DropDownData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.MachineAndBatchInfo}
 */
proto.upl_sandbach.MachineAndBatchInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.MachineAndBatchInfo;
  return proto.upl_sandbach.MachineAndBatchInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.MachineAndBatchInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.MachineAndBatchInfo}
 */
proto.upl_sandbach.MachineAndBatchInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new common_Common_pb.DropDownData;
      reader.readMessage(value,common_Common_pb.DropDownData.deserializeBinaryFromReader);
      msg.addMachineInfo(value);
      break;
    case 15:
      var value = new common_Common_pb.DropDownData;
      reader.readMessage(value,common_Common_pb.DropDownData.deserializeBinaryFromReader);
      msg.addBatchInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.MachineAndBatchInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.MachineAndBatchInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.MachineAndBatchInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.MachineAndBatchInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      common_Common_pb.DropDownData.serializeBinaryToWriter
    );
  }
  f = message.getBatchInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      common_Common_pb.DropDownData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DropDownData machine_info = 10;
 * @return {!Array<!proto.upl_sandbach.DropDownData>}
 */
proto.upl_sandbach.MachineAndBatchInfo.prototype.getMachineInfoList = function() {
  return /** @type{!Array<!proto.upl_sandbach.DropDownData>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_Common_pb.DropDownData, 10));
};


/**
 * @param {!Array<!proto.upl_sandbach.DropDownData>} value
 * @return {!proto.upl_sandbach.MachineAndBatchInfo} returns this
*/
proto.upl_sandbach.MachineAndBatchInfo.prototype.setMachineInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.upl_sandbach.DropDownData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.DropDownData}
 */
proto.upl_sandbach.MachineAndBatchInfo.prototype.addMachineInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.upl_sandbach.DropDownData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.MachineAndBatchInfo} returns this
 */
proto.upl_sandbach.MachineAndBatchInfo.prototype.clearMachineInfoList = function() {
  return this.setMachineInfoList([]);
};


/**
 * repeated DropDownData batch_info = 15;
 * @return {!Array<!proto.upl_sandbach.DropDownData>}
 */
proto.upl_sandbach.MachineAndBatchInfo.prototype.getBatchInfoList = function() {
  return /** @type{!Array<!proto.upl_sandbach.DropDownData>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_Common_pb.DropDownData, 15));
};


/**
 * @param {!Array<!proto.upl_sandbach.DropDownData>} value
 * @return {!proto.upl_sandbach.MachineAndBatchInfo} returns this
*/
proto.upl_sandbach.MachineAndBatchInfo.prototype.setBatchInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.upl_sandbach.DropDownData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.DropDownData}
 */
proto.upl_sandbach.MachineAndBatchInfo.prototype.addBatchInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.upl_sandbach.DropDownData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.MachineAndBatchInfo} returns this
 */
proto.upl_sandbach.MachineAndBatchInfo.prototype.clearBatchInfoList = function() {
  return this.setBatchInfoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.ProductionDashboardFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.ProductionDashboardFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.ProductionDashboardFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ProductionDashboardFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && common_Common_pb.Filter.toObject(includeInstance, f),
    averageCycleTimeOperation: jspb.Message.getFieldWithDefault(msg, 15, ""),
    assetUtilizationMessage: jspb.Message.getFieldWithDefault(msg, 20, ""),
    batch: (f = msg.getBatch()) && common_Common_pb.DropDownData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.ProductionDashboardFilter}
 */
proto.upl_sandbach.ProductionDashboardFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.ProductionDashboardFilter;
  return proto.upl_sandbach.ProductionDashboardFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.ProductionDashboardFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.ProductionDashboardFilter}
 */
proto.upl_sandbach.ProductionDashboardFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new common_Common_pb.Filter;
      reader.readMessage(value,common_Common_pb.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAverageCycleTimeOperation(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetUtilizationMessage(value);
      break;
    case 25:
      var value = new common_Common_pb.DropDownData;
      reader.readMessage(value,common_Common_pb.DropDownData.deserializeBinaryFromReader);
      msg.setBatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.ProductionDashboardFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.ProductionDashboardFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.ProductionDashboardFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ProductionDashboardFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_Common_pb.Filter.serializeBinaryToWriter
    );
  }
  f = message.getAverageCycleTimeOperation();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAssetUtilizationMessage();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getBatch();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      common_Common_pb.DropDownData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Filter filter = 10;
 * @return {?proto.upl_sandbach.Filter}
 */
proto.upl_sandbach.ProductionDashboardFilter.prototype.getFilter = function() {
  return /** @type{?proto.upl_sandbach.Filter} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.Filter, 10));
};


/**
 * @param {?proto.upl_sandbach.Filter|undefined} value
 * @return {!proto.upl_sandbach.ProductionDashboardFilter} returns this
*/
proto.upl_sandbach.ProductionDashboardFilter.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.ProductionDashboardFilter} returns this
 */
proto.upl_sandbach.ProductionDashboardFilter.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.ProductionDashboardFilter.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string average_cycle_time_operation = 15;
 * @return {string}
 */
proto.upl_sandbach.ProductionDashboardFilter.prototype.getAverageCycleTimeOperation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ProductionDashboardFilter} returns this
 */
proto.upl_sandbach.ProductionDashboardFilter.prototype.setAverageCycleTimeOperation = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string asset_utilization_message = 20;
 * @return {string}
 */
proto.upl_sandbach.ProductionDashboardFilter.prototype.getAssetUtilizationMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.ProductionDashboardFilter} returns this
 */
proto.upl_sandbach.ProductionDashboardFilter.prototype.setAssetUtilizationMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional DropDownData batch = 25;
 * @return {?proto.upl_sandbach.DropDownData}
 */
proto.upl_sandbach.ProductionDashboardFilter.prototype.getBatch = function() {
  return /** @type{?proto.upl_sandbach.DropDownData} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.DropDownData, 25));
};


/**
 * @param {?proto.upl_sandbach.DropDownData|undefined} value
 * @return {!proto.upl_sandbach.ProductionDashboardFilter} returns this
*/
proto.upl_sandbach.ProductionDashboardFilter.prototype.setBatch = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.ProductionDashboardFilter} returns this
 */
proto.upl_sandbach.ProductionDashboardFilter.prototype.clearBatch = function() {
  return this.setBatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.ProductionDashboardFilter.prototype.hasBatch = function() {
  return jspb.Message.getField(this, 25) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.OEEPieChart.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.OEEPieChart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.OEEPieChart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.OEEPieChart.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    oeeCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    qualityCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    performanceCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    availabilityCount: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.OEEPieChart}
 */
proto.upl_sandbach.OEEPieChart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.OEEPieChart;
  return proto.upl_sandbach.OEEPieChart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.OEEPieChart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.OEEPieChart}
 */
proto.upl_sandbach.OEEPieChart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOeeCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQualityCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerformanceCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvailabilityCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.OEEPieChart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.OEEPieChart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.OEEPieChart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.OEEPieChart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOeeCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getQualityCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPerformanceCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAvailabilityCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 total_count = 1;
 * @return {number}
 */
proto.upl_sandbach.OEEPieChart.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.OEEPieChart} returns this
 */
proto.upl_sandbach.OEEPieChart.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 oee_count = 2;
 * @return {number}
 */
proto.upl_sandbach.OEEPieChart.prototype.getOeeCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.OEEPieChart} returns this
 */
proto.upl_sandbach.OEEPieChart.prototype.setOeeCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 quality_count = 3;
 * @return {number}
 */
proto.upl_sandbach.OEEPieChart.prototype.getQualityCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.OEEPieChart} returns this
 */
proto.upl_sandbach.OEEPieChart.prototype.setQualityCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 performance_count = 4;
 * @return {number}
 */
proto.upl_sandbach.OEEPieChart.prototype.getPerformanceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.OEEPieChart} returns this
 */
proto.upl_sandbach.OEEPieChart.prototype.setPerformanceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 availability_count = 5;
 * @return {number}
 */
proto.upl_sandbach.OEEPieChart.prototype.getAvailabilityCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.OEEPieChart} returns this
 */
proto.upl_sandbach.OEEPieChart.prototype.setAvailabilityCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AverageCycleTime.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AverageCycleTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AverageCycleTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AverageCycleTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableTime: jspb.Message.getFieldWithDefault(msg, 10, 0),
    avgDeviationTime: jspb.Message.getFieldWithDefault(msg, 15, 0),
    avgDeviationPercentage: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AverageCycleTime}
 */
proto.upl_sandbach.AverageCycleTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AverageCycleTime;
  return proto.upl_sandbach.AverageCycleTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AverageCycleTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AverageCycleTime}
 */
proto.upl_sandbach.AverageCycleTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAvailableTime(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgDeviationTime(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgDeviationPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AverageCycleTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AverageCycleTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AverageCycleTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AverageCycleTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableTime();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getAvgDeviationTime();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getAvgDeviationPercentage();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
};


/**
 * optional int64 available_time = 10;
 * @return {number}
 */
proto.upl_sandbach.AverageCycleTime.prototype.getAvailableTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.AverageCycleTime} returns this
 */
proto.upl_sandbach.AverageCycleTime.prototype.setAvailableTime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 avg_deviation_time = 15;
 * @return {number}
 */
proto.upl_sandbach.AverageCycleTime.prototype.getAvgDeviationTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.AverageCycleTime} returns this
 */
proto.upl_sandbach.AverageCycleTime.prototype.setAvgDeviationTime = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 avg_deviation_percentage = 20;
 * @return {number}
 */
proto.upl_sandbach.AverageCycleTime.prototype.getAvgDeviationPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.AverageCycleTime} returns this
 */
proto.upl_sandbach.AverageCycleTime.prototype.setAvgDeviationPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.AlarmAndErrorPareto.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AlarmAndErrorPareto.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AlarmAndErrorPareto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AlarmAndErrorPareto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorPareto.toObject = function(includeInstance, msg) {
  var f, obj = {
    paretodataList: jspb.Message.toObjectList(msg.getParetodataList(),
    common_Common_pb.ParetoData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AlarmAndErrorPareto}
 */
proto.upl_sandbach.AlarmAndErrorPareto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AlarmAndErrorPareto;
  return proto.upl_sandbach.AlarmAndErrorPareto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AlarmAndErrorPareto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AlarmAndErrorPareto}
 */
proto.upl_sandbach.AlarmAndErrorPareto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_Common_pb.ParetoData;
      reader.readMessage(value,common_Common_pb.ParetoData.deserializeBinaryFromReader);
      msg.addParetodata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AlarmAndErrorPareto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AlarmAndErrorPareto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AlarmAndErrorPareto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorPareto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParetodataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_Common_pb.ParetoData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ParetoData paretoData = 1;
 * @return {!Array<!proto.upl_sandbach.ParetoData>}
 */
proto.upl_sandbach.AlarmAndErrorPareto.prototype.getParetodataList = function() {
  return /** @type{!Array<!proto.upl_sandbach.ParetoData>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_Common_pb.ParetoData, 1));
};


/**
 * @param {!Array<!proto.upl_sandbach.ParetoData>} value
 * @return {!proto.upl_sandbach.AlarmAndErrorPareto} returns this
*/
proto.upl_sandbach.AlarmAndErrorPareto.prototype.setParetodataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.upl_sandbach.ParetoData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.ParetoData}
 */
proto.upl_sandbach.AlarmAndErrorPareto.prototype.addParetodata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.upl_sandbach.ParetoData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.AlarmAndErrorPareto} returns this
 */
proto.upl_sandbach.AlarmAndErrorPareto.prototype.clearParetodataList = function() {
  return this.setParetodataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.ProductionDashboardOverview.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.ProductionDashboardOverview} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ProductionDashboardOverview.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchOrder: (f = msg.getBatchOrder()) && proto.upl_sandbach.BatchData.toObject(includeInstance, f),
    oeePieChart: (f = msg.getOeePieChart()) && proto.upl_sandbach.OEEPieChart.toObject(includeInstance, f),
    averageCycleTime: (f = msg.getAverageCycleTime()) && proto.upl_sandbach.AverageCycleTime.toObject(includeInstance, f),
    assetUtilization: (f = msg.getAssetUtilization()) && common_Common_pb.AssetUtilization.toObject(includeInstance, f),
    alarmErrorsParetoData: (f = msg.getAlarmErrorsParetoData()) && proto.upl_sandbach.AlarmAndErrorPareto.toObject(includeInstance, f),
    activeAlarmOverview: (f = msg.getActiveAlarmOverview()) && model_AlarmDetails_pb.ActiveAlarmOverview.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.ProductionDashboardOverview}
 */
proto.upl_sandbach.ProductionDashboardOverview.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.ProductionDashboardOverview;
  return proto.upl_sandbach.ProductionDashboardOverview.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.ProductionDashboardOverview} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.ProductionDashboardOverview}
 */
proto.upl_sandbach.ProductionDashboardOverview.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new proto.upl_sandbach.BatchData;
      reader.readMessage(value,proto.upl_sandbach.BatchData.deserializeBinaryFromReader);
      msg.setBatchOrder(value);
      break;
    case 15:
      var value = new proto.upl_sandbach.OEEPieChart;
      reader.readMessage(value,proto.upl_sandbach.OEEPieChart.deserializeBinaryFromReader);
      msg.setOeePieChart(value);
      break;
    case 20:
      var value = new proto.upl_sandbach.AverageCycleTime;
      reader.readMessage(value,proto.upl_sandbach.AverageCycleTime.deserializeBinaryFromReader);
      msg.setAverageCycleTime(value);
      break;
    case 25:
      var value = new common_Common_pb.AssetUtilization;
      reader.readMessage(value,common_Common_pb.AssetUtilization.deserializeBinaryFromReader);
      msg.setAssetUtilization(value);
      break;
    case 30:
      var value = new proto.upl_sandbach.AlarmAndErrorPareto;
      reader.readMessage(value,proto.upl_sandbach.AlarmAndErrorPareto.deserializeBinaryFromReader);
      msg.setAlarmErrorsParetoData(value);
      break;
    case 35:
      var value = new model_AlarmDetails_pb.ActiveAlarmOverview;
      reader.readMessage(value,model_AlarmDetails_pb.ActiveAlarmOverview.deserializeBinaryFromReader);
      msg.setActiveAlarmOverview(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.ProductionDashboardOverview.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.ProductionDashboardOverview} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.ProductionDashboardOverview.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchOrder();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.upl_sandbach.BatchData.serializeBinaryToWriter
    );
  }
  f = message.getOeePieChart();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.upl_sandbach.OEEPieChart.serializeBinaryToWriter
    );
  }
  f = message.getAverageCycleTime();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.upl_sandbach.AverageCycleTime.serializeBinaryToWriter
    );
  }
  f = message.getAssetUtilization();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      common_Common_pb.AssetUtilization.serializeBinaryToWriter
    );
  }
  f = message.getAlarmErrorsParetoData();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.upl_sandbach.AlarmAndErrorPareto.serializeBinaryToWriter
    );
  }
  f = message.getActiveAlarmOverview();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      model_AlarmDetails_pb.ActiveAlarmOverview.serializeBinaryToWriter
    );
  }
};


/**
 * optional BatchData batch_order = 10;
 * @return {?proto.upl_sandbach.BatchData}
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.getBatchOrder = function() {
  return /** @type{?proto.upl_sandbach.BatchData} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.BatchData, 10));
};


/**
 * @param {?proto.upl_sandbach.BatchData|undefined} value
 * @return {!proto.upl_sandbach.ProductionDashboardOverview} returns this
*/
proto.upl_sandbach.ProductionDashboardOverview.prototype.setBatchOrder = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.ProductionDashboardOverview} returns this
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.clearBatchOrder = function() {
  return this.setBatchOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.hasBatchOrder = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional OEEPieChart oee_pie_chart = 15;
 * @return {?proto.upl_sandbach.OEEPieChart}
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.getOeePieChart = function() {
  return /** @type{?proto.upl_sandbach.OEEPieChart} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.OEEPieChart, 15));
};


/**
 * @param {?proto.upl_sandbach.OEEPieChart|undefined} value
 * @return {!proto.upl_sandbach.ProductionDashboardOverview} returns this
*/
proto.upl_sandbach.ProductionDashboardOverview.prototype.setOeePieChart = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.ProductionDashboardOverview} returns this
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.clearOeePieChart = function() {
  return this.setOeePieChart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.hasOeePieChart = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional AverageCycleTime average_cycle_time = 20;
 * @return {?proto.upl_sandbach.AverageCycleTime}
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.getAverageCycleTime = function() {
  return /** @type{?proto.upl_sandbach.AverageCycleTime} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.AverageCycleTime, 20));
};


/**
 * @param {?proto.upl_sandbach.AverageCycleTime|undefined} value
 * @return {!proto.upl_sandbach.ProductionDashboardOverview} returns this
*/
proto.upl_sandbach.ProductionDashboardOverview.prototype.setAverageCycleTime = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.ProductionDashboardOverview} returns this
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.clearAverageCycleTime = function() {
  return this.setAverageCycleTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.hasAverageCycleTime = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional AssetUtilization asset_utilization = 25;
 * @return {?proto.upl_sandbach.AssetUtilization}
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.getAssetUtilization = function() {
  return /** @type{?proto.upl_sandbach.AssetUtilization} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.AssetUtilization, 25));
};


/**
 * @param {?proto.upl_sandbach.AssetUtilization|undefined} value
 * @return {!proto.upl_sandbach.ProductionDashboardOverview} returns this
*/
proto.upl_sandbach.ProductionDashboardOverview.prototype.setAssetUtilization = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.ProductionDashboardOverview} returns this
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.clearAssetUtilization = function() {
  return this.setAssetUtilization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.hasAssetUtilization = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional AlarmAndErrorPareto alarm_errors_pareto_data = 30;
 * @return {?proto.upl_sandbach.AlarmAndErrorPareto}
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.getAlarmErrorsParetoData = function() {
  return /** @type{?proto.upl_sandbach.AlarmAndErrorPareto} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.AlarmAndErrorPareto, 30));
};


/**
 * @param {?proto.upl_sandbach.AlarmAndErrorPareto|undefined} value
 * @return {!proto.upl_sandbach.ProductionDashboardOverview} returns this
*/
proto.upl_sandbach.ProductionDashboardOverview.prototype.setAlarmErrorsParetoData = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.ProductionDashboardOverview} returns this
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.clearAlarmErrorsParetoData = function() {
  return this.setAlarmErrorsParetoData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.hasAlarmErrorsParetoData = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional ActiveAlarmOverview active_alarm_overview = 35;
 * @return {?proto.upl_sandbach.ActiveAlarmOverview}
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.getActiveAlarmOverview = function() {
  return /** @type{?proto.upl_sandbach.ActiveAlarmOverview} */ (
    jspb.Message.getWrapperField(this, model_AlarmDetails_pb.ActiveAlarmOverview, 35));
};


/**
 * @param {?proto.upl_sandbach.ActiveAlarmOverview|undefined} value
 * @return {!proto.upl_sandbach.ProductionDashboardOverview} returns this
*/
proto.upl_sandbach.ProductionDashboardOverview.prototype.setActiveAlarmOverview = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.ProductionDashboardOverview} returns this
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.clearActiveAlarmOverview = function() {
  return this.setActiveAlarmOverview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.ProductionDashboardOverview.prototype.hasActiveAlarmOverview = function() {
  return jspb.Message.getField(this, 35) != null;
};


goog.object.extend(exports, proto.upl_sandbach);
