import ChartContainer from "components/ChartContainer";
import { AlarmTimelineGraph } from "components/Charts/AlarmTimelineGraph";
import { BubbleBasedHeatMap } from "components/Charts/BubbleBasedHeatMap";
import { AlarmDetailsCollapse } from "components/Common/Collapse/Collapse";
import { TimeLineTable } from "components/Common/Table/Table";
import { DropdownFilterComponent } from "components/DropdownFilterComponent";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAlarmsAndEventsDetailsData } from "store/actions/alarmsEvents";

export const AlarmDetails = () => {
  const [selectedTableRowIndex, setSelectedTableRowIndex] = useState([]);
  let filterStartDate = useSelector(
    (state: any) => state.root.filterValues.startTime
  );
  let filterEndDate = useSelector(
    (state: any) => state.root.filterValues.endTime
  );
  const [payload, setPayload] = useState<any>({
    lineObject: useSelector((state: any) => state.root.filterValues.lineObject),
    nodeId: useSelector((state: any) => state.root.filterValues.nodeId),
    startTime: useSelector((state: any) => state.root.filterValues.startTime),
    endTime: useSelector((state: any) => state.root.filterValues.endTime),
    severity: useSelector((state: any) => state.root.filterValues.severity),
    batches: useSelector((state: any) => state.root.filterValues.batches),
    sku: useSelector((state: any) => state.root.filterValues.sku),
    page: useSelector((state: any) => state.root.filterValues.page),
  });

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [timeLine, setTimeLine] = useState<any>([]);
  const [timeLineGap, setTimeLineGap] = useState<any[]>([]);
  const [selectedRange, setSelectedRange] = useState<any>();

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        getAlarmsAndEventsDetailsData({
          ...payload,
          page,
          tagSelected: selectedTableRowIndex,
        })
      );
    }, 100);
  }, [payload, selectedTableRowIndex, page]);

  useEffect(() => {
    if (filterStartDate) {
      let timeStampDiff = filterEndDate - filterStartDate;
      timeStampDiff = (timeStampDiff - (timeStampDiff % 1000)) / 1000;
      setSelectedRange(timeStampDiff);
      makeAlarmTimelineData();
    }
  }, [filterStartDate]);

  const alarmsEventsDetailsBubbleList = useSelector(
    (state: any) => state.alarmsEvents.alarmsEventsDetailsBubbleList
  );

  const alarmsEventsDetailsTableList = useSelector(
    (state: any) => state.alarmsEvents.alarmsEventsDetailsTableList
  );

  const alarmsEventsActiveAlarmsRowCount = useSelector(
    (state: any) => state.alarmsEvents.alarmEventDetailsRowCount
  );

  const handleInBetween = (time, before, after) => {
    var beforeTime: any = before;
    var afterTime: any = after;
    if (time.isBetween(beforeTime, afterTime)) {
      return true;
    } else {
      return false;
    }
  };

  const calculateAlarmTimeline = (
    amount: any,
    unit: any,
    dividerVal: number,
    dateFormat: string
  ) => {
    let graphData: any[] = [];
    let mainData: any[] = [];
    let timeLineArry: any[] = [];
    let normalizeArr: any[] = [];
    let colorArr: any[] = [];
    let axisData: any[] = [];
    let severityArray: any[] = [];
    for (let i = 0; i < dividerVal; i++) {
      let data = moment().subtract(amount * i, unit);
      timeLineArry.push(data);
      axisData.push(data.format(dateFormat));
    }

    for (let j = 0; j < timeLineArry.length; j++) {
      let tempArr: any[] = [];
      alarmsEventsDetailsBubbleList.forEach((i) => {
        if (
          handleInBetween(
            moment.unix(i.timestamp / 1000),
            timeLineArry[j + 1],
            timeLineArry[j]
          )
        ) {
          tempArr.push(i);
        }
      });
      graphData.push(tempArr);
    }

    timeLineArry = [];
    for (let i = 0; i < graphData.length; i++) {
      const resultObject =
        graphData[i].length > 0 &&
        graphData[i].reduce(function (obj, v) {
          obj[v.severity] = (obj[v.severity] || 0) + 1;
          return obj;
        }, {});

      if (resultObject) {
        let total = {
          All: 0,
          Critical: 0,
          Major: 0,
          Warning: 0,
          Minor: 0,
          Event: 0,
        };
        let resultValues: any = Object.values(resultObject);
        let max = Math.max(...resultValues);
        let tempObj: any = { ...total, ...resultObject };
        severityArray.push(tempObj);
        normalizeArr.push(max);
        colorArr.push(
          Object.keys(resultObject).find((key) => resultObject[key] === max)
        );
      } else {
        normalizeArr.push(0);
        colorArr.push("");
        severityArray.push({
          All: 0,
          Critical: 0,
          Major: 0,
          Warning: 0,
          Minor: 0,
          Event: 0,
        });
      }
    }
    let maxPoint = Math.max(...normalizeArr);

    normalizeArr = normalizeArr.map((v) => {
      let newVal: any = v / maxPoint;
      return newVal < 0.1 && newVal > 0 ? 8 : newVal * 40;
    });

    for (let i = normalizeArr.length; i > 0; i--) {
      mainData.push([
        0,
        i,
        normalizeArr[normalizeArr.length - i],
        severityArray[normalizeArr.length - i],
        colorArr[normalizeArr.length - i ],
        axisData[axisData.length - i - 2],
        axisData[axisData.length - i - 1],
      ]);
    }
    setTimeLineGap(axisData.reverse());
    setTimeLine(mainData);
  };

  const makeAlarmTimelineData = () => {
    let amount = selectedRange / 18;

    calculateAlarmTimeline(amount, "s", 18, "DD-MM-YY HH:mm");
  };

  useEffect(() => {
    makeAlarmTimelineData();
  }, [alarmsEventsDetailsBubbleList]);

  return (
    <>
      <DropdownFilterComponent setPayload={setPayload} screen="alarms" />

      <div
        className="alarmevent-dashboard-wrapper-container"
        style={{ paddingLeft: "8px", paddingRight: "8px" }}
      >
        <div className="marginTop8">
          <ChartContainer
            id={"bubbleBasedHeatmap-ID"}
            title={"Alarm Timeline"}
            alignChartTitle={"left"}
            child={
              <AlarmTimelineGraph
                range={selectedRange}
                timeLine={timeLineGap}
                data={timeLine}
              />
            }
            height="125px"
          />
        </div>
        <div className="timeline-container">
          <ChartContainer
            id={"timlineTable"}
            child={
              <TimeLineTable
                alarmsEventsDetailsTableList={alarmsEventsDetailsTableList}
                setSelectedTableRowIndex={setSelectedTableRowIndex}
                alarmsEventsActiveAlarmsRowCount={
                  alarmsEventsActiveAlarmsRowCount
                }
                setPage={setPage}
              />
            }
            height={"290px"}
          />
        </div>
        {/* Note: Not being used now , parked for future  */}
        {/* <div className="marginTop8">
          <ChartContainer
            id={"alarmDetails"}
            title={"Alarm Details"}
            alignChartTitle={"left"}
            child={
              <AlarmDetailsCollapse
                selectedTableRowIndex={selectedTableRowIndex}
              />
            }
          />
        </div> */}
      </div>
    </>
  );
};
