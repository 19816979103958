import * as types from "./actionTypes";

export const getFilterTreeData = (payload: any) => ({
  type: types.LOAD_FILTER_TREE_DATA,
  payload,
});

export const getBatchesByLine = (payload: number) => ({
  type: types.GET_BATCHES_BY_LINE,
  payload,
});

export const setFilterValues = (payload: any) => ({
  type: types.SET_FILTER_VALUES,
  payload,
});

export const setFilterUpdatedFlag = () => ({
  type: types.SET_FILTER_UPDATED_FLAG,
});

export const setFilterValuesDataVisualization = (payload: any) => ({
  type: types.SET_FILTER_VALUES_DATA_VISUALIZATION,
  payload,
});
