import { all, fork } from "redux-saga/effects";
import { reasonCodeManagementSaga } from "./reasonCodeManagementSaga";
import { dataTrendingSaga } from "./dataVisualizationSaga";
import { alarmsEventsSaga } from "./alarmsEventsSaga";
import { operatorSaga } from "./operatorSaga";
import { lineOverviewSaga } from './lineOverViewSaga';
import { productionOverviewSaga } from "./productionOverviewSaga";
import { globalSaga } from "./globalSaga";
import { runtimeAnalysisSaga } from "./runtimeAnalysisSaga";
import { oeeAanlysisSaga } from "./oeeAnalysisSaga";

export const rootSaga = function* root() {
  yield all([
    fork(reasonCodeManagementSaga),
    fork(dataTrendingSaga),
    fork(alarmsEventsSaga),
    fork(operatorSaga),
    fork(lineOverviewSaga),
    fork(productionOverviewSaga),
    fork(globalSaga),
    fork(runtimeAnalysisSaga),
    fork(oeeAanlysisSaga)
  ]);
};
