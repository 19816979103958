import { message, Radio, RadioChangeEvent } from "antd";
import ChartContainer from "components/ChartContainer";
import Pareto from "components/Charts/Pareto";
import StackedBar from "components/Charts/StackedBar";
import ProgressBar from "components/ProgressBar";
import { getUrl } from "config/apiconfig";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import UplLogo from "assets/icons/uplLogo.png"
import { allTreeNodeTitle, dateConverter, findNestedObj } from "utils/common";
import { line } from "d3-shape";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDownTimeDetails, getDownTimePareto, getDownTimeReasonCodeDurationCount } from "store/actions/oeeScreen";
import { milliToHrs } from "utils/milliToHrs";
import "./index.scss";
import { OeeFilterComponent } from "./OeeDropDownFilter";


export const DowntimeTab = () => {
  const [value, setValue] = useState("duration");
  const [payload, setPayload] = useState<any>({
    startTime: useSelector(
      (state: any) => state.root.filterValues.startTime
    ),
    endTime: useSelector(
      (state: any) => state.root.filterValues.endTime
    ),
    nodeId: useSelector(
      (state: any) => state.root.filterValues.nodeId
    ),
    sku: useSelector(
      (state: any) => state.root.filterValues.sku
    ),
  });

  const dispatch = useDispatch();

  const downTimeDetails = useSelector((state: any) => state.oeeDashboard.downTimeDetails);
  const downTimeParetoDetails = useSelector((state: any) => state.oeeDashboard.downTimeParetoList)
  const downTimeDurationList = useSelector((state: any) => state.oeeDashboard.downTimeDurationList)
  const downTimeCountList = useSelector((state: any) => state.oeeDashboard.downTimeCountList)
  const downTimeReasonCodeName = useSelector((state: any) => state.oeeDashboard.downTimeReasonCodeName)

  const filterFlag = useSelector((state: any) => state.root.filterFlag);
  const filterTreeData = useSelector((state: any) => state.root.filterTree);
  const selectedNodeIds:any=useSelector((state:any)=>state.root.filterValues.nodeId);
  const result:any=[];

  selectedNodeIds.forEach((i)=>{    
    result.push(findNestedObj(filterTreeData,'key',i));
});
  const allNodeTittle = allTreeNodeTitle(filterTreeData?.children);
  const startDate = "StartTime :" + dateConverter(payload.startTime);
  const endDate = "EndTime :" + dateConverter(payload.endTime);
  const sku = "Sku :" + payload.sku;
  useEffect(() => {
    dispatch(getDownTimeReasonCodeDurationCount(payload));
    dispatch(getDownTimePareto(payload));
    dispatch(getDownTimeDetails(payload));
  }, [payload,filterFlag])

  const makeRequest = (data: any) => {
    if (data.nodeId.length === 0) {
      message.error("Please select at least one machine");
  }else{
    let oeePayload: any = {};
    oeePayload['startTime'] = data.startTime;
    oeePayload['endTime'] = data.endTime;
    oeePayload['nodeId'] = data.nodeId;
    oeePayload['sku'] = data.sku;
    dispatch(getDownTimeReasonCodeDurationCount(oeePayload));
    dispatch(getDownTimePareto(oeePayload));
    dispatch(getDownTimeDetails(oeePayload));
  }
}
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const lineName = payload?.lineObject?.map((item: any) => {
    return item.machine
  })
  const machineLines=result?.map((item:any)=>{
    return item?.title
  })
  const line = "Machine Line :" + (allNodeTittle?.toString() ? machineLines?.toString() : lineName?.toString());
 const exportImagePdf = async () => {
    message.loading('downloading pdf started', 10);
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const doc: any = new jsPDF(orientation, unit, size);
    let topDowntimeReasonsImage: any;
    let progressbarsImage: any;
    let downtimeParetoChartImage:any;
    
    const topDowntimeReasons = window.document.getElementById("topDowntimeReasons") as HTMLElement;
    const progressbars = window.document.getElementById("progress-bars") as HTMLElement;
    const downtimeParetoChart = window.document.getElementById("downtimeParetoChart") as HTMLElement;
    const  img:any = new Image;

    await html2canvas(topDowntimeReasons).then((canvas) => {
      topDowntimeReasonsImage = canvas.toDataURL("image/png", 1.0);
    });
    await html2canvas(progressbars).then((canvas) => {
      progressbarsImage = canvas.toDataURL("image/png", 1.0);
    });
    await html2canvas(downtimeParetoChart).then((canvas) => {
      downtimeParetoChartImage = canvas.toDataURL("image/png", 1.0);
    });
    doc.addImage(UplLogo, 'PNG', 430, 7, 36, 36);
    doc.setFontSize(18);
    doc.setFont(undefined,'bold')
    doc.text(160, 65, "OEE Analysis (Downtime) Report – UPL Europe (Sandbach Plant)");
    // doc.text(350, 30, "Down Time OEE Overview Report");
    doc.setFontSize(15);
    doc.setFont(undefined,'bold')

    doc.text(20, 95, "Report Information");
    doc.setFont(undefined,'normal')

     doc.text(20, 115, startDate, "left", "5");
    doc.text(20, 135, endDate);
    doc.text(20, 155, sku);
    let lines = doc.splitTextToSize(line, (900 - 57 - 57));
    doc.text(20, 175, lines);
    doc.addImage(topDowntimeReasonsImage, "PNG", 20, 210, 750, 275);
    doc.addImage(progressbarsImage, "PNG", 20, 510, 750, 25);
    doc.addPage()
    // doc.addImage(progressbarsImage, "PNG", left_margin, top_margin, width, height);
    
    doc.addImage(downtimeParetoChartImage, "PNG", 20, 50, 750, 275);
   

   
     

    message.info( ' record(s) are getting downloaded');
   
    doc.save('OEE Analysis (Downtime) Report' + "_" + moment().format("DD/MM/YYYY_HH:MM:SS"));
  }
  return (
    <div className="downtime-chart-container">
       <OeeFilterComponent sendRequest={makeRequest} download={()=>exportImagePdf()}/>
      <ChartContainer
        id="topDowntimeReasons"
        title="Top Downtime Reasons"
        alignChartTitle="left"
        tooltip=""
        child={
          <>
            <Radio.Group name="radiogroup" onChange={onChange} value={value}>
              <Radio value={"duration"}>By Duration</Radio>
              <Radio value={"count"}>By Count</Radio>
            </Radio.Group>
            {value === "duration" ? <StackedBar
              chartID="stackedTopDowntime"
              data={downTimeDurationList}
              screen={"OeeDownTimeDuration"}
              selectedFilter="all"
              series={downTimeReasonCodeName}
            /> :
              <StackedBar
                selectedFilter="all"
                chartID="stackedTopDowntime-count"
                data={downTimeCountList}
                screen={"OeeDownTime"}
                series={downTimeReasonCodeName}
              />
            }
          </>}
        width="150px"
        height="200px"
        showFullScreen={false}
      />
      <div className="progress-bar-container" id="progress-bars">
        <ProgressBar variableName="Total Downtime" value={downTimeDetails && downTimeDetails.totalDownTime ? milliToHrs(downTimeDetails.totalDownTime) : 100} />
        <ProgressBar variableName="Planned Downtime" value={downTimeDetails && downTimeDetails.plannedDownTime >= 0 ? milliToHrs(downTimeDetails.plannedDownTime) : 7} />
        <ProgressBar variableName="Unplanned Downtime" value={downTimeDetails && downTimeDetails.unplannedDownTime >= 0 ? milliToHrs(downTimeDetails.unplannedDownTime) : 89} />
      </div>
      <ChartContainer
        id="downtimeParetoChart"
        title="Downtime Pareto"
        alignChartTitle="left"
        tooltip="Downtime Pareto"
        child={<Pareto chartID="downtimePareto" data={downTimeParetoDetails} screen="alarms" />}
        width="150px"
        height="200px"
        showFullScreen={false}
      />
    </div>
  )
}
