
import "./index.scss";
const OeeDataCard: React.FC<any> = ({ title, value, content }) => {
    const displayContent: any = () => {

        return content && content.map((data) => {
            return (
                <div className="data-card-content">
                    <div> {data.title}</div>
                    <div>{data.value + " " + data.unit}</div>
                </div>
            );
        });
    };

    return (
        <div className="data-card-container">
            <div className="data-card-header">
                <div>{title}</div>
                <div>{value}</div>
            </div>
            {displayContent()}
        </div>
    );
};

export default OeeDataCard;