// @ts-nocheck
import { message } from 'antd';
import { getUrl } from 'config/apiconfig';
import moment from 'moment';
import { exportCSVFile } from 'utils/common';

export class TimeSeriesService {
    private static instance: TimeSeriesService;
    private timeSeriesServiceClient: any;
    private timeStampProtoObj: any;
    private ApiProtoObj: any;
    private authToken: any = '';
    private metadata: any;

    private constructor() {
        const assetURL = getUrl('assetServiceGrpc');
        const timeStampService = require('protobuf/asset_proto/service/HistorianRpc_grpc_web_pb');
        this.timeStampProtoObj = require('protobuf/asset_proto/service/HistorianRpc_pb');
        this.ApiProtoObj = require('protobuf/asset_proto/common/API_pb');
        this.authToken = localStorage.getItem('authToken');
        this.metadata = { 'authorization': 'Bearer ' + this.authToken };
        this.timeSeriesServiceClient =
            new timeStampService.HistorianServicePromiseClient(assetURL, null, null);
    }

    public static getInstance(): TimeSeriesService {
        if (!TimeSeriesService.instance) {
            TimeSeriesService.instance = new TimeSeriesService();
        }
        return TimeSeriesService.instance;
    }

    getAggregatedDataForTag(selectedRowKeysGraph: any, dateRange: Array<any>) {

        if (selectedRowKeysGraph) {
            const request = new this.timeStampProtoObj.TimeStampMultipleQuery();
            let currentTagsArr: Array<string> = [];
            selectedRowKeysGraph.forEach((element) => {
                let ID = new this.ApiProtoObj.Uuid();
                ID.setUuid(element['tagId']);
                currentTagsArr.push(ID);
            });
            request.setTagIdList(currentTagsArr);
            request.setStartTime(new Date(dateRange[0]).getTime());
            request.setEndTime(new Date(dateRange[1]).getTime());

            return this.timeSeriesServiceClient
                .getTimeStampAggregatedData(request, this.metadata)
                .then((response) => {
                    message.loading('loading selected tags data', 1);
                    return mapTagOverviewData(response.getStreamsList());
                })
                .catch((err) => {
                    message.error('Unable to get selected tags data');
                    throw new Error(err);
                });
        }
        return {};
    }




    getRawDataForTag(tagIds: any, dateRange: Array<any>,page:any) {
        let result: any = {
            data: [],
            total: 0,
        };
        if (tagIds) {
            const request = new this.timeStampProtoObj.TimeStampMultipleQuery();
            let currentTagsArr: Array<string> = [];
            tagIds.forEach((element) => {
                let ID = new this.ApiProtoObj.Uuid();
                ID.setUuid(element['tagId']);
                currentTagsArr.push(ID);
            });
            request.setTagIdList(currentTagsArr);
            request.setStartTime(new Date(dateRange[0]).getTime());
            request.setEndTime(new Date(dateRange[1]).getTime());
            if(page){
                request.setPageSize(50);
                request.setPageNumber(page)
            }
            else{
                request.setPageSize(50);
                request.setPageNumber(1)
            }
            return this.timeSeriesServiceClient
                .getTimeStampRawData(request, this.metadata)
                .then((response) => {
                    message.loading('loading selected tags data', 1);
                    result.total = response.getTotalRecords();
                    result.streamCount=response.getStreamCount()
                    result.data = mapTagOverviewRawData(response.getStreamsList(), tagIds);
                    return result
                })
                .catch((err) => {
                    message.error('Unable to get selected tags list');
                    throw new Error(err);
                });
        }
        return {};
    }

    getTimeStampRawCSV(tagIds: any, dateRange: Array<any>,page:any) {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (timezone === "Asia/Calcutta") timezone = "Asia/Kolkata";
        if (tagIds) {
            const request = new this.timeStampProtoObj.TimeStampMultipleQuery();
            let currentTagsArr: Array<string> = [];
            tagIds.forEach((element) => {
                let ID = new this.ApiProtoObj.Uuid();
                ID.setUuid(element['tagId']);
                currentTagsArr.push(ID);
            });
            request.setTagIdList(currentTagsArr);
            request.setStartTime(new Date(dateRange[0]).getTime());
            request.setEndTime(new Date(dateRange[1]).getTime());
            if(page){
                request.setPageSize(0);
                request.setPageNumber(0)
            }
            else{
                request.setPageSize(0);
                request.setPageNumber(0)
            }
            request.setTimeZone(timezone);
            return this.timeSeriesServiceClient
                .getTimeStampRawCSV(request, this.metadata)
                .then((response) => {
                    const fileName = `Data_${new Date(dateRange[0]).getTime()}_to ${new Date(dateRange[1]).getTime()}.csv`;
                    exportCSVFile(response.getPayload(), fileName);
                })
                .catch((err) => {
                    message.error('Unable to get raw csv data ');
                    throw new Error(err);
                });
        }
        return {};
    }


    getTagOverviewData = (payload: any) => {
        let result: TagOverviewDataResponse = {
            data: [],
            total: 0,
        };
        let request =
            payload.mode === dataTableConst.CYCLIC
                ? new this.dataTableProtoObj.TimeStampCyclicQuery()
                : new this.dataTableProtoObj.TimeStampMultipleQuery();
        let currentTagsArr: Array<string> = [],
            ID,
            serviceClient;
        ID = new this.dataTableProtoObj.Uuid();
        ID.setUuid(payload.selectedTagId);
        currentTagsArr.push(ID);
        request.setTagIdList(currentTagsArr);
        request.setStartTime(new Date(payload.dateRange[0]).getTime());
        request.setEndTime(new Date(payload.dateRange[1]).getTime());
        if (payload.pageSize) request.setPageSize(payload.pageSize);
        if (payload.pageCount) request.setPageNumber(payload.pageCount);
        request.setOrderBy(payload.order);
        switch (payload.mode) {
            case dataTableConst.FULL:
                serviceClient = this.dataTableServiceClient.getTimeStampRawData(
                    request,
                    this.metadata
                );
                break;
            case dataTableConst.AGGREGATE:
                serviceClient = this.dataTableServiceClient.getTimeStampAggregatedData(
                    request,
                    this.metadata
                );
                break;
            case dataTableConst.CYCLIC:
                request.setInterval(payload.valueAtIntervals);
                serviceClient = this.dataTableServiceClient.getTimeStampCyclicData(
                    request,
                    this.metadata
                );
                break;
        }
        return serviceClient
            .then((response) => {
                message.loading('loading device tag list', 1);
                result['data'] = mapTagOverviewData(
                    response.getStreamsList(),
                    payload.tagId,
                    payload.tagName,
                    payload.description,
                    payload.decimalPlaces
                );
                result.total = response.getTotalRecords();
                return result;
            })
            .catch((err) => {
                message.error('Unable to get device tag list');
                throw new Error(err);
            });
    };

}

export const mapTagOverviewData = (response: any, tagId?: any) => {
    let trendingGraphData = {};
    response.forEach((element) => {
        let tempTagData: any = [];
        element.getReadingsList().forEach((dataPoint) => {
            let temp: any = [];
            temp[0] = dataPoint.getTimestamp();
            temp[1] = dataPoint.getValue();
            tempTagData.push(temp);
        });
        trendingGraphData[element.getUuid()] = tempTagData;
    });
    return trendingGraphData;
};

export const mapTagOverviewRawData = (response: any, tagId: any) => {
    let trendingRawList: any = [];
    response.forEach((element) => {
        let tempTagData: any = {};
        element.getReadingsList().forEach((dataPoint) => {
            let time = Number(dataPoint.getTimestamp());
            tempTagData['timeStamp'] = moment(time).format(" Do MMM YYYY, h:mm:ss A");
            tempTagData['value'] = dataPoint.getValue();
            tempTagData['key'] = String(dataPoint.getTimestamp())
        });
        tempTagData['minRange'] = element.getMinRange();
        tempTagData['maxRange'] = element.getMaxRange();
        tempTagData['unit'] = element.getUnit();
        let newArr: any[] = tagId.find(item => item.tagId === element.getUuid());
        tempTagData['tagName'] = newArr && newArr['tagName'];
        tempTagData['tagDescription'] = newArr && newArr['tagDescription'];
        tempTagData['deviceName'] = newArr && newArr['deviceName'];
        trendingRawList.push(tempTagData);
    });
    return trendingRawList;
};