import moment from "moment";
import {
  AlarmsEventsActiveAlarms,
  AlarmsEventsActiveAlarmsCard,
  alarmsEventsDetailsBubble,
  alarmsEventsDetailsTable,
  AlarmsEventsGuageSummaryData,
  AlarmsEventsPareto,
  AlarmsEventsStacked,
  AlarmsEventsSummary,
} from "types";
import { forHumans } from "utils/milliToHrs";
import {
  millisToMinutesAndDotSeparatedSeconds,
  millisToMinutesAndSeconds,
  milliToMins,
} from "utils/milliToMinutes";
import severityToString, { severityToLowerCase } from "utils/severityToString";
import { paretoTooltipText } from "utils/paretoTooltipText";

export const mapAlarmsEventsDashboard = (details: any) => {
  let alarmsEventsSummaryByCountList: AlarmsEventsSummary[] = [];
  let alarmsEventsSummaryByDurationList: AlarmsEventsSummary[] = [];
  let alarmsEventsGuageSummaryByCountDataList: AlarmsEventsGuageSummaryData[] =
    [];
  let alarmsEventsGuageSummaryByDurationDataList: AlarmsEventsGuageSummaryData[] =
    [];
  let alarmsEventsCountByTagsList: AlarmsEventsPareto[] = [];
  let alarmsEventsDurationByTagsList: AlarmsEventsPareto[] = [];
  let alarmsEventsCountByDevicesList: AlarmsEventsStacked[] = [];
  let alarmsEventsDurationByDevicesList: AlarmsEventsStacked[] = [];
  let alarmsEventsActiveAlarmsList: AlarmsEventsActiveAlarms[] = [];
  let totalNoOfRows: any = 0;
  let alarmsEventsActiveAlarmsCardData: AlarmsEventsActiveAlarmsCard = {
    warningCount: 0,
    minorCount: 0,
    majorCount: 0,
    criticalCount: 0,
    eventCount: 0,
    totalCount: 0,
  };

  alarmsEventsGuageSummaryByCountDataList.push({
    category: "Total",
    value: details.getAaeSummaryPieChart().getPcByCountTotal(),
    percentage: 100,
  });
  details
    .getAaeSummaryPieChart()
    .getPcByCountList()
    .forEach((element: any) => {
      alarmsEventsSummaryByCountList.push({
        category: element.getCategory(),
        value: element.getCatValue(),
        percentage: element.getPercentage(),
        total: element.getFullCount(),
      });
      alarmsEventsGuageSummaryByCountDataList.push({
        category: element.getCategory(),
        percentage: element.getPercentage(),
        value: element.getCatValue(),
      });
    });

  alarmsEventsGuageSummaryByDurationDataList.push({
    category: "Total",
    value: details.getAaeSummaryPieChart().getPcByDurationTotal(),
    percentage: 100,
  });
  details
    .getAaeSummaryPieChart()
    .getPcByDurationList()
    .forEach((element: any) => {
      alarmsEventsSummaryByDurationList.push({
        category: element.getCategory(),
        value: element.getCatValue(),
        percentage: element.getPercentage(),
        total: element.getFullCount(),
      });
      alarmsEventsGuageSummaryByDurationDataList.push({
        category: element.getCategory(),
        percentage: element.getPercentage(),
        value: element.getCatValue(),
      });
    });

  details
    .getAaeParetoData()
    .getPdCountByTagsList()
    .forEach((element: any) => {
      alarmsEventsCountByTagsList.push({
        category: paretoTooltipText(element.getName(), element.getDesc()),
        value: element.getValue(),
      });
    });

  details
    .getAaeParetoData()
    .getPdDurationByTagsList()
    .forEach((element: any) => {
      alarmsEventsDurationByTagsList.push({
        category: paretoTooltipText(element.getName(), element.getDesc()),
        value: milliToMins(element.getValue()),
      });
    });

  details
    .getAaeSpcData()
    .getSbcCountByDevicesList()
    .forEach((element: any) => {
      alarmsEventsCountByDevicesList.push({
        equipmentName: element.getEquipmentName(),
        warningCount: element.getSeverityCounts().getWarningCount(),
        minorCount: element.getSeverityCounts().getMinorCount(),
        majorCount: element.getSeverityCounts().getMajorCount(),
        criticalCount: element.getSeverityCounts().getCriticalCount(),
        eventCount: element.getSeverityCounts().getEventCount(),
        totalCount: element.getSeverityCounts().getTotalCount(),
      });
    });

  details
    .getAaeSpcData()
    .getSbcDurationByDevicesList()
    .forEach((element: any) => {
      alarmsEventsDurationByDevicesList.push({
        equipmentName: element.getEquipmentName(),
        warningCount: milliToMins(
          element.getSeverityCounts().getWarningCount()
        ),
        minorCount: milliToMins(element.getSeverityCounts().getMinorCount()),
        majorCount: milliToMins(element.getSeverityCounts().getMajorCount()),
        criticalCount: milliToMins(
          element.getSeverityCounts().getCriticalCount()
        ),
        eventCount: milliToMins(element.getSeverityCounts().getEventCount()),
        totalCount: milliToMins(element.getSeverityCounts().getTotalCount()),
      });
    });

  details
    .getActiveAlarmOverview()
    .getActiveAlarmModelList()
    .forEach((element: any) => {
      alarmsEventsActiveAlarmsList.push({
        deviceName: element.getDeviceName(),
        alarmDescription: element.getDescription(),
        timestamp: element.getTimeStamp(),
        deviceTag: element.getTagname(),
        severity: element.getSeverity(),
        endTimestamp:element.getTimeStamp()+Number(element.getActiveDuration())
      });
    });

  totalNoOfRows = details.getActiveAlarmOverview().getTotalCount();

  alarmsEventsActiveAlarmsCardData = {
    warningCount: details
      .getActiveAlarmOverview()
      .getSeverityCounts()
      ?.getWarningCount(),
    minorCount: details
      .getActiveAlarmOverview()
      .getSeverityCounts()
      ?.getMinorCount(),
    majorCount: details
      .getActiveAlarmOverview()
      .getSeverityCounts()
      ?.getMajorCount(),
    criticalCount: details
      .getActiveAlarmOverview()
      .getSeverityCounts()
      ?.getCriticalCount(),
    eventCount: details
      .getActiveAlarmOverview()
      .getSeverityCounts()
      ?.getEventCount(),
    totalCount: details
      .getActiveAlarmOverview()
      .getSeverityCounts()
      ?.getTotalCount(),
  };

  return {
    alarmsEventsSummaryByCountList,
    alarmsEventsSummaryByDurationList,
    alarmsEventsGuageSummaryByCountDataList,
    alarmsEventsGuageSummaryByDurationDataList,
    alarmsEventsCountByTagsList,
    alarmsEventsDurationByTagsList,
    alarmsEventsCountByDevicesList,
    alarmsEventsDurationByDevicesList,
    alarmsEventsActiveAlarmsList,
    totalNoOfRows,
  };
};

export const mapAlarmsEventsTableData = (details: any) => {
  let alarmsEventsActiveAlarmsList: AlarmsEventsActiveAlarms[] = [];
  let alarmsEventsActiveAlarmsCardData: AlarmsEventsActiveAlarmsCard = {
    warningCount: 0,
    minorCount: 0,
    majorCount: 0,
    criticalCount: 0,
    eventCount: 0,
    totalCount: 0,
  };
  let totalNoOfRows: any = 0;
  details
    .getActiveAlarmOverview()
    .getActiveAlarmModelList()
    .forEach((element: any) => {
      alarmsEventsActiveAlarmsList.push({
        deviceName: element.getDeviceName(),
        alarmDescription: element.getDescription(),
        timestamp: element.getTimeStamp(),
        deviceTag: element.getTagname(),
        severity: element.getSeverity(),
        endTimestamp:element.getTimeStamp()+Number(element.getActiveDuration())
      });
    });
  totalNoOfRows = details.getActiveAlarmOverview().getTotalCount();
  alarmsEventsActiveAlarmsCardData = {
    warningCount: details
      .getActiveAlarmOverview()
      .getSeverityCounts()
      ?.getWarningCount(),
    minorCount: details
      .getActiveAlarmOverview()
      .getSeverityCounts()
      ?.getMinorCount(),
    majorCount: details
      .getActiveAlarmOverview()
      .getSeverityCounts()
      ?.getMajorCount(),
    criticalCount: details
      .getActiveAlarmOverview()
      .getSeverityCounts()
      ?.getCriticalCount(),
    eventCount: details
      .getActiveAlarmOverview()
      .getSeverityCounts()
      ?.getEventCount(),
    totalCount: details
      .getActiveAlarmOverview()
      .getSeverityCounts()
      ?.getTotalCount(),
  };
  return {
    alarmsEventsActiveAlarmsList,
    totalNoOfRows,
    alarmsEventsActiveAlarmsCardData,
  };
};

export const mapAlarmsEventsGuageData = (details: any) => {
  let alarmsEventsSummaryByCountList: AlarmsEventsSummary[] = [];
  let alarmsEventsSummaryByDurationList: AlarmsEventsSummary[] = [];
  let alarmsEventsGuageSummaryByCountDataList: AlarmsEventsGuageSummaryData[] =
    [];
  let alarmsEventsGuageSummaryByDurationDataList: AlarmsEventsGuageSummaryData[] =
    [];
  let alarmsEventsActiveAlarmsCardData: AlarmsEventsActiveAlarmsCard = {
    warningCount: 0,
    minorCount: 0,
    majorCount: 0,
    criticalCount: 0,
    eventCount: 0,
    totalCount: 0,
  };

  alarmsEventsActiveAlarmsCardData = {
    eventCount: details
      .getAlarmErrorSummaryPieChart()
      .getPcByCountList()[0]
      .getCatValue(),
    warningCount: details
      .getAlarmErrorSummaryPieChart()
      .getPcByCountList()[1]
      .getCatValue(),
    minorCount: details
      .getAlarmErrorSummaryPieChart()
      .getPcByCountList()[2]
      .getCatValue(),
    majorCount: details
      .getAlarmErrorSummaryPieChart()
      .getPcByCountList()[3]
      .getCatValue(),
    criticalCount: details
      .getAlarmErrorSummaryPieChart()
      .getPcByCountList()[4]
      .getCatValue(),
    totalCount:
      details
        .getAlarmErrorSummaryPieChart()
        .getPcByCountList()[0]
        .getCatValue() +
      details
        .getAlarmErrorSummaryPieChart()
        .getPcByCountList()[1]
        .getCatValue() +
      details
        .getAlarmErrorSummaryPieChart()
        .getPcByCountList()[2]
        .getCatValue() +
      details
        .getAlarmErrorSummaryPieChart()
        .getPcByCountList()[3]
        .getCatValue() +
      details
        .getAlarmErrorSummaryPieChart()
        .getPcByCountList()[4]
        .getCatValue(),
  };

  let totalGuageSummaryByCountValue = 0;
  let totalGuageSummaryByCountPercentage = 0;
  details
    .getAlarmErrorSummaryPieChart()
    .getPcByCountList()
    .forEach((element: any) => {
      alarmsEventsSummaryByCountList.push({
        category: element.getCategory(),
        value: element.getCatValue(),
        percentage: element.getPercentage(),
        total: element.getFullCount(),
      });
      alarmsEventsGuageSummaryByCountDataList.push({
        category: element.getCategory(),
        percentage: element.getPercentage(),
        value: element.getCatValue(),
      });
      totalGuageSummaryByCountPercentage += Math.abs(element.getPercentage());
      totalGuageSummaryByCountValue += Math.abs(element.getCatValue());
    });

  alarmsEventsGuageSummaryByCountDataList.unshift({
    category: "Total",
    value: totalGuageSummaryByCountValue,
    percentage: totalGuageSummaryByCountPercentage.toFixed(2) as any,
  });

  let totalGuageSummaryByDurationValue = 0;
  let totalGuageSummaryByDurationPercentage = 0;

  details
    .getAlarmErrorSummaryPieChart()
    .getPcByDurationList()
    .forEach((element: any) => {
      alarmsEventsSummaryByDurationList.push({
        category: element.getCategory(),
        value: element.getCatValue(),
        percentage: Math.abs(element.getPercentage()),
        total: element.getFullCount(),
      });
      alarmsEventsGuageSummaryByDurationDataList.push({
        category: element.getCategory(),
        percentage: Math.abs(element.getPercentage()),
        value: millisToMinutesAndSeconds(element.getCatValue()) + " Min",
      });
      totalGuageSummaryByDurationPercentage += Math.abs(
        element.getPercentage()
      );
      totalGuageSummaryByDurationValue += Math.abs(element.getCatValue());
    });
  alarmsEventsGuageSummaryByDurationDataList.unshift({
    category: "Total",
    value: millisToMinutesAndSeconds(totalGuageSummaryByDurationValue) + " Min",
    percentage: totalGuageSummaryByDurationPercentage.toFixed(2) as any,
  });

  return {
    alarmsEventsActiveAlarmsCardData,
    alarmsEventsSummaryByCountList,
    alarmsEventsSummaryByDurationList,
    alarmsEventsGuageSummaryByCountDataList,
    alarmsEventsGuageSummaryByDurationDataList,
  };
};

export const mapAlarmsEventsDashboardStackedChartData = (details: any) => {
  let alarmsEventsCountByDevicesList: AlarmsEventsStacked[] = [];
  let alarmsEventsDurationByDevicesList: AlarmsEventsStacked[] = [];
  details
    .getAaeSpcData()
    .getSbcCountByDevicesList()
    .forEach((element: any) => {
      alarmsEventsCountByDevicesList.push({
        equipmentName: element.getEquipmentName(),
        warningCount: element.getSeverityCounts().getWarningCount(),
        minorCount: element.getSeverityCounts().getMinorCount(),
        majorCount: element.getSeverityCounts().getMajorCount(),
        criticalCount: element.getSeverityCounts().getCriticalCount(),
        eventCount: element.getSeverityCounts().getEventCount(),
        totalCount: element.getSeverityCounts().getTotalCount(),
      });
    });

  details
    .getAaeSpcData()
    .getSbcDurationByDevicesList()
    .forEach((element: any) => {
      alarmsEventsDurationByDevicesList.push({
        equipmentName: element.getEquipmentName(),
        warningCount: Number(
          millisToMinutesAndDotSeparatedSeconds(
            element.getSeverityCounts().getWarningCount()
          )
        ),
        minorCount: Number(
          millisToMinutesAndDotSeparatedSeconds(
            element.getSeverityCounts().getMinorCount()
          )
        ),
        majorCount: Number(
          millisToMinutesAndDotSeparatedSeconds(
            element.getSeverityCounts().getMajorCount()
          )
        ),
        criticalCount: Number(
          millisToMinutesAndDotSeparatedSeconds(
            element.getSeverityCounts().getCriticalCount()
          )
        ),
        eventCount: Number(
          millisToMinutesAndDotSeparatedSeconds(
            element.getSeverityCounts().getEventCount()
          )
        ),
        totalCount: Number(
          millisToMinutesAndDotSeparatedSeconds(
            element.getSeverityCounts().getTotalCount()
          )
        ),
      });
    });

  return {
    alarmsEventsCountByDevicesList,
    alarmsEventsDurationByDevicesList,
  };
};

export const mapAlarmsEventsDashboardParetoChartData = (details: any) => {
  let alarmsEventsCountByTagsList: AlarmsEventsPareto[] = [];
  let alarmsEventsDurationByTagsList: AlarmsEventsPareto[] = [];
  if (details.getAaeParetoData().getPdCountByTagsList().length > 0) {
    details
      .getAaeParetoData()
      .getPdCountByTagsList()
      .forEach((element: any) => {
        alarmsEventsCountByTagsList.push({
          category: paretoTooltipText(element.getName(), element.getDesc()),
          value: element.getValue(),
        });
      });
  }

  if (details.getAaeParetoData().getPdDurationByTagsList().length > 0) {
    details
      .getAaeParetoData()
      .getPdDurationByTagsList()
      .forEach((element: any) => {
        alarmsEventsDurationByTagsList.push({
          category: paretoTooltipText(element.getName(), element.getDesc()),
          value: Number(
            millisToMinutesAndDotSeparatedSeconds(element.getValue())
          ),
        });
      });
  }

  return {
    alarmsEventsCountByTagsList,
    alarmsEventsDurationByTagsList,
  };
};

const severityMapper = (value: any) => {
  if (value === 0) return "All";
  else if (value === 10) return "EVENT";
  else if (value === 20) return "WARNING";
  else if (value === 30) return "MINOR";
  else if (value === 40) return "MAJOR";
  else return "CRITICAL";
};

export const mapAlarmsEventsDetails = (details: any) => {
  let alarmsEventsDetailsTableList: alarmsEventsDetailsTable[] = [];
  let alarmsEventsDetailsBubbleList: alarmsEventsDetailsBubble[] = [];
  let alarmEventDetailsRowCount: any = 0;

  details.getActiveAlarmModelList().forEach((element: any) => {
    alarmsEventsDetailsTableList.push({
      key: element.getKey(),
      deviceName: element.getDeviceName(),
      deviceTag: element.getTagname(),
      deviceTagId: element.getDeviceTag(),
      description: element.getDescription(),
      severity: severityMapper(element.getSeverity()),
      timeStamp: element.getTimeStamp(),
      errorCode: element.getErrorCode(),
      tagUuid: element.getTagUuid(),
      activeDuration: forHumans(element.getActiveDuration()),
      endTimestamp: element.getActiveDuration()===""?"-":element.getTimeStamp()+Number(element.getActiveDuration()),
      alarmState: element.getAlarmState(),
    });
  });

  alarmEventDetailsRowCount = details.getTotalCount();

  let tempSortedData = [] as any;

  details.getBubbleHeatMapList().forEach((element: any) => {
    tempSortedData.push({
      severity: element.getSeverity(),
      timestamp: element.getTimestamp(),
      value: Number(element.getValue()),
      tagUuid: element.getTaguuid(),
    });
  });

  tempSortedData = tempSortedData.sort((a: any, b: any) => {
    return a.timestamp - b.timestamp;
  });

  tempSortedData.forEach((element: any) => {
    alarmsEventsDetailsBubbleList.push({
      severity: severityToLowerCase(element.severity),
      timestamp: element.timestamp,
      value: Number(element.value),
      tagUuid: element.tagUuid,
    });
  });

  return {
    alarmsEventsDetailsTableList,
    alarmsEventsDetailsBubbleList,
    alarmEventDetailsRowCount,
  };
};
