import { Input, message, Modal, Switch } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import './index.scss';
import { Dropdown } from "components/Common/Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { createReasonCodeDetails } from "store/actions/reasonCodeManagement";

export const NewResonCode = (props: any) => {
  const { visible, open, onOk, onCancel, currentComponent } = props;
  const dispatch = useDispatch();
  const categoryList = useSelector((state: any) => state.reasonCode.reasonCodeCategoryList);
  const reasonCodeFactor = useSelector((state: any) => state.reasonCode.reasonCodeFactor);
  const [reasonCodeFactorDropdownData, setreasonCodeFactorDropdownData] = useState<any>([]);

  const [payload, setPayload] = useState<any>({
    categoryId: '',
    factorId: '',
    reasonCodeName: '',
    status: true
  })

  const onChange = (checked: boolean) => {
    setPayload({ ...payload, status: checked });
  };

  const selectedCategory = (value: string) => {
    setPayload({ ...payload, categoryId: value, factorId: '' });
    setreasonCodeFactorDropdownData(reasonCodeFactor[value]);
  }

  const selectedFactor = (value: string) => {
    setPayload({ ...payload, factorId: value });
  }

  const handleCreate = () => {
    if (payload.categoryId === "") {
      message.error('please select Reascon Code Category');
      return;
    } else if (payload.factorId === "") {
      message.error('please select Reason Factor Code');
      return;
    } else if (payload.reasonCodeName === "") {
      message.error('please enter Reason Code Name');
      return;
    }

    dispatch(createReasonCodeDetails({ ...payload, viewType: currentComponent }));
    onOk();
    setPayload({
      categoryId: '',
      factorId: '',
      reasonCodeName: '',
      status: true
    })
  }

  return (
    <Modal
      visible={visible}
      centered
      open={open}
      onOk={handleCreate}
      onCancel={onCancel}
      width={336}
      closeIcon={<CloseCircleFilled />}
      wrapClassName="create-reason-code-category"
      okText="Create"
      destroyOnClose={true}
    >
      <div className="title">Create New Reason Code</div>
      <div className="row">
        <label>Select Reason Code Category</label>
        <Dropdown optionData={categoryList} placeHolder={"Category Drop down"}
          selectedValue={selectedCategory} />
      </div>
      <div className="row">
        <label>Select Reason Code Factor</label>
        <Dropdown optionData={reasonCodeFactorDropdownData} placeHolder={"Factor Drop down"}
          selectedValue={selectedFactor} />
      </div>
      <div className="row">
        <label>Reason Code Name</label>
        <Input placeholder="Enter Reason Code Name" className="input-text"
          onChange={(e) => setPayload({ ...payload, reasonCodeName: e.target.value })} />
      </div>

      <div className="status-row">
        <label>Status</label>
        <Switch defaultChecked onChange={onChange} />
      </div>
    </Modal>
  )
}