import momentTimezone from "moment-timezone";
import moment from "moment";
import convertHoursToMinutes from "./convertHoursToMinutes";
import { MINUTES_TILL_EIGHT_O_CLOCK } from "./Constants";

const dynamicRangeHandeler = (
  selectedValue,
  rangeHandeler,
  shiftStartTime?,
  setShiftStartTime?,
  setShiftEndTime?
) => {
  switch (selectedValue) {
    case "Shift A":
      let startTime = momentTimezone
        .tz(
          `${moment(shiftStartTime).format("YYYY-MM-DD")} 08:00:00`,
          "Europe/London"
        )
        .valueOf();
      setShiftStartTime(
        momentTimezone
          .tz(
            `${moment(shiftStartTime).format("YYYY-MM-DD")} 08:00:00`,
            "Europe/London"
          )
          .valueOf()
      );
      setShiftEndTime(
        momentTimezone
          .tz(
            `${moment(shiftStartTime).format("YYYY-MM-DD")} 15:59:59`,
            "Europe/London"
          )
          .valueOf()
      );
      rangeHandeler([
        moment(startTime),
        moment(
          momentTimezone
            .tz(
              `${moment(shiftStartTime).format("YYYY-MM-DD")} 15:59:59`,
              "Europe/London"
            )
            .valueOf()
        ),
      ]);
      break;

    case "Shift B":

      let startTimeShiftB= momentTimezone
          .tz(`${moment(shiftStartTime).format("YYYY-MM-DD")} 16:00:00`, "Europe/London")
          .valueOf();
        
            let endTimeShiftB= momentTimezone(
            moment(startTimeShiftB).add(28799,"seconds"),"Europe/London").valueOf()

      setShiftStartTime(
        startTimeShiftB
      );
      setShiftEndTime(
        endTimeShiftB
      );
      rangeHandeler([
        moment(
          startTimeShiftB
        ),
        moment(
          endTimeShiftB
        ),
      ]);
      break;
    case "Shift C":

      let startTimeShiftC= momentTimezone
      .tz(`${moment(shiftStartTime).format("YYYY-MM-DD")} 16:00:00`, "Europe/London").valueOf()
        let startTimeWith8HoursAddition= momentTimezone(
        moment(startTimeShiftC).add(28800,"seconds"),"Europe/London").valueOf()
        let endTimeWith16HoursAddition= momentTimezone(
          moment(startTimeShiftC).add(57599,"seconds"),"Europe/London").valueOf()

      setShiftStartTime(
        startTimeWith8HoursAddition
      );
      setShiftEndTime(
        endTimeWith16HoursAddition
      );
      rangeHandeler([
        moment(
          startTimeWith8HoursAddition
        ),
        moment(
          endTimeWith16HoursAddition
        ),
      ]);
      break;
    case "Yesterday":
      var timeInMinutes = convertHoursToMinutes(moment().utc().format("HH:MM"));
      let previousShiftStart = momentTimezone
        .tz(
          `${moment().subtract(1, "days").format("YYYY-MM-DD")} 08:00`,
          "Europe/London"
        )
        .valueOf();

      let previousShiftEnd = momentTimezone
        .tz(`${moment().format("YYYY-MM-DD")} 07:59:59`, "Europe/London")
        .valueOf();
      if (timeInMinutes < MINUTES_TILL_EIGHT_O_CLOCK) {
        previousShiftStart = momentTimezone
          .tz(
            `${moment().subtract(2, "days").format("YYYY-MM-DD")} 08:00`,
            "Europe/London"
          )
          .valueOf();
        previousShiftEnd = momentTimezone
          .tz(
            `${moment().subtract(1, "days").format("YYYY-MM-DD")} 07:59:59`,
            "Europe/London"
          )
          .valueOf();
      }
      rangeHandeler([moment(previousShiftStart), moment(previousShiftEnd)]);

      break;
    case "Today":
      var timeInMinutes = convertHoursToMinutes(moment().utc().format("HH:MM"));
      let currentShiftStart = momentTimezone
        .tz(`${moment().format("YYYY-MM-DD")} 08:00`, "Europe/London")
        .valueOf();

      let currentShiftEnd = momentTimezone
        .tz(moment().format(), "Europe/London")
        .valueOf();
      if (timeInMinutes < MINUTES_TILL_EIGHT_O_CLOCK) {
        currentShiftStart = momentTimezone
          .tz(
            `${moment().subtract(1, "days").format("YYYY-MM-DD")} 08:00`,
            "Europe/London"
          )
          .valueOf();
        currentShiftEnd = momentTimezone
          .tz(moment().format(), "Europe/London")
          .valueOf();
      }

      rangeHandeler([moment(currentShiftStart), moment(currentShiftEnd)]);
      break;
    case "This Week":
      let thisWeek = momentTimezone
        .tz(
          `${moment().startOf("week").format("YYYY-MM-DD")} 08:00`,
          "Europe/London"
        )
        .valueOf();
      rangeHandeler([
        moment(thisWeek),
        moment(momentTimezone.tz(moment().format(), "Europe/London").valueOf()),
      ]);
      break;
    case "Last Week":
      let start = momentTimezone
        .tz(
          `${moment()
            .subtract(1, "weeks")
            .startOf("week")
            .format("YYYY-MM-DD")} 08:00`,
          "Europe/London"
        )
        .valueOf();

      let end = momentTimezone
        .tz(
          `${moment().startOf("week").format("YYYY-MM-DD")} 07:59:59`,
          "Europe/London"
        )
        .valueOf();
      rangeHandeler([moment(start), moment(end)]);
      break;
    case "MTD":
      const startOfMonth = momentTimezone
        .tz(
          `${moment().startOf("month").format("YYYY-MM-DD")} 08:00`,
          "Europe/London"
        )
        .valueOf();
      rangeHandeler([
        moment(startOfMonth),
        moment(momentTimezone.tz(moment().format(), "Europe/London").valueOf()),
      ]);
      break;
    case "YTD":
      const startOfYear = momentTimezone
        .tz(
          `${moment().startOf("year").format("YYYY-MM-DD")} 08:00`,
          "Europe/London"
        )
        .valueOf();
      rangeHandeler([
        moment(startOfYear),
        moment(momentTimezone.tz(moment().format(), "Europe/London").valueOf()),
      ]);
      break;
    default:
      break;
  }
};

export default dynamicRangeHandeler;
