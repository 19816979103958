import initialState from "./initalState";
import * as types from "store/actions/actionTypes";

export default function lineOverviewReducer(
  state = initialState.lineOverview,
  action: any
) {
  switch (action.type) {
    case types.GET_LINE_OEE_SUCCESS:
      return { ...state, oeeData: action.data };
    case types.GET_TEEP_MATRIX_BY_LINE_SUCCESS:
      return { ...state, teepMatrix: action.data };
    case types.GET_TEEP_MATRIX_BY_LINE_ERROR:
      return {
        ...state,
        teepMatrix: [
          {
            country: "New",
            sales: 0,
          },
        ],
      };
    case types.GET_DOWNTIME_BY_LINE_SUCCESS:
      return { ...state, downTimeTrend: action.data };
    case types.GET_RUNTIME_DOWNTIME_SUCCESS:
      return { ...state, runtimeDownTime: action.data };
    case types.GET_LOST_TIME_MATRIX_SUCCESS:
      return { ...state, lostTimeData: action.data };
    case types.GET_RATE_MATRIX_SUCCESS:
      return { ...state, rateMatrixData: action.data };
    case types.GET_LINE_OVERVIEW_TABLE_DATA_SUCCESS:
      const {
        alarmsEventsActiveAlarmsList,
        totalNoOfRows,
        alarmsEventsActiveAlarmsCardData,
      } = action.payload;
      state = {
        ...state,
        tableData: alarmsEventsActiveAlarmsList,
        rowCount: totalNoOfRows,
        lineOverviewAlarmsSummaryCardData: alarmsEventsActiveAlarmsCardData,
      };
      return state;


    default:
      return state;
  }
}
