// source: model/ReasonCodeModel.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_ReasonCodeDetails_pb = require('../common/ReasonCodeDetails_pb.js');
goog.object.extend(proto, common_ReasonCodeDetails_pb);
var enums_ReasonCodeCategory_pb = require('../enums/ReasonCodeCategory_pb.js');
goog.object.extend(proto, enums_ReasonCodeCategory_pb);
var enums_ReasonCodeEnum_pb = require('../enums/ReasonCodeEnum_pb.js');
goog.object.extend(proto, enums_ReasonCodeEnum_pb);
goog.exportSymbol('proto.proto.AMDFilter', null, global);
goog.exportSymbol('proto.proto.ReasonCodeBatchDetails', null, global);
goog.exportSymbol('proto.proto.ReasonCodeCategoryData', null, global);
goog.exportSymbol('proto.proto.ReasonCodeCategoryInfo', null, global);
goog.exportSymbol('proto.proto.ReasonCodeCategoryListData', null, global);
goog.exportSymbol('proto.proto.ReasonCodeData', null, global);
goog.exportSymbol('proto.proto.ReasonCodeDetailsInfo', null, global);
goog.exportSymbol('proto.proto.ReasonCodeFactorInfo', null, global);
goog.exportSymbol('proto.proto.ReasonCodeFilterData', null, global);
goog.exportSymbol('proto.proto.ReasonCodeLogInfo', null, global);
goog.exportSymbol('proto.proto.ResourceData', null, global);
goog.exportSymbol('proto.proto.ResourceInfo', null, global);
goog.exportSymbol('proto.proto.ResourceTree', null, global);
goog.exportSymbol('proto.proto.ResourceViewInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReasonCodeData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeData.displayName = 'proto.proto.ReasonCodeData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeCategoryListData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ReasonCodeCategoryListData.repeatedFields_, null);
};
goog.inherits(proto.proto.ReasonCodeCategoryListData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeCategoryListData.displayName = 'proto.proto.ReasonCodeCategoryListData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeFilterData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReasonCodeFilterData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeFilterData.displayName = 'proto.proto.ReasonCodeFilterData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeCategoryData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ReasonCodeCategoryData.repeatedFields_, null);
};
goog.inherits(proto.proto.ReasonCodeCategoryData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeCategoryData.displayName = 'proto.proto.ReasonCodeCategoryData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeCategoryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ReasonCodeCategoryInfo.repeatedFields_, null);
};
goog.inherits(proto.proto.ReasonCodeCategoryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeCategoryInfo.displayName = 'proto.proto.ReasonCodeCategoryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeFactorInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ReasonCodeFactorInfo.repeatedFields_, null);
};
goog.inherits(proto.proto.ReasonCodeFactorInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeFactorInfo.displayName = 'proto.proto.ReasonCodeFactorInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeDetailsInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReasonCodeDetailsInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeDetailsInfo.displayName = 'proto.proto.ReasonCodeDetailsInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ResourceViewInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ResourceViewInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ResourceViewInfo.displayName = 'proto.proto.ResourceViewInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ResourceTree = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ResourceTree.repeatedFields_, null);
};
goog.inherits(proto.proto.ResourceTree, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ResourceTree.displayName = 'proto.proto.ResourceTree';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ResourceData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ResourceData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ResourceData.displayName = 'proto.proto.ResourceData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ResourceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ResourceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ResourceInfo.displayName = 'proto.proto.ResourceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AMDFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AMDFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AMDFilter.displayName = 'proto.proto.AMDFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeBatchDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReasonCodeBatchDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeBatchDetails.displayName = 'proto.proto.ReasonCodeBatchDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReasonCodeLogInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReasonCodeLogInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReasonCodeLogInfo.displayName = 'proto.proto.ReasonCodeLogInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeData.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeData.toObject = function(includeInstance, msg) {
  var f, obj = {
    viewType: (f = msg.getViewType()) && common_ReasonCodeDetails_pb.ViewType.toObject(includeInstance, f),
    reasonCodeFilterData: (f = msg.getReasonCodeFilterData()) && proto.proto.ReasonCodeFilterData.toObject(includeInstance, f),
    reasonCodeStatus: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeData}
 */
proto.proto.ReasonCodeData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeData;
  return proto.proto.ReasonCodeData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeData}
 */
proto.proto.ReasonCodeData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_ReasonCodeDetails_pb.ViewType;
      reader.readMessage(value,common_ReasonCodeDetails_pb.ViewType.deserializeBinaryFromReader);
      msg.setViewType(value);
      break;
    case 2:
      var value = new proto.proto.ReasonCodeFilterData;
      reader.readMessage(value,proto.proto.ReasonCodeFilterData.deserializeBinaryFromReader);
      msg.setReasonCodeFilterData(value);
      break;
    case 3:
      var value = /** @type {!proto.proto.Status} */ (reader.readEnum());
      msg.setReasonCodeStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViewType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_ReasonCodeDetails_pb.ViewType.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeFilterData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ReasonCodeFilterData.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional ViewType view_type = 1;
 * @return {?proto.proto.ViewType}
 */
proto.proto.ReasonCodeData.prototype.getViewType = function() {
  return /** @type{?proto.proto.ViewType} */ (
    jspb.Message.getWrapperField(this, common_ReasonCodeDetails_pb.ViewType, 1));
};


/**
 * @param {?proto.proto.ViewType|undefined} value
 * @return {!proto.proto.ReasonCodeData} returns this
*/
proto.proto.ReasonCodeData.prototype.setViewType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeData} returns this
 */
proto.proto.ReasonCodeData.prototype.clearViewType = function() {
  return this.setViewType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeData.prototype.hasViewType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ReasonCodeFilterData reason_code_filter_data = 2;
 * @return {?proto.proto.ReasonCodeFilterData}
 */
proto.proto.ReasonCodeData.prototype.getReasonCodeFilterData = function() {
  return /** @type{?proto.proto.ReasonCodeFilterData} */ (
    jspb.Message.getWrapperField(this, proto.proto.ReasonCodeFilterData, 2));
};


/**
 * @param {?proto.proto.ReasonCodeFilterData|undefined} value
 * @return {!proto.proto.ReasonCodeData} returns this
*/
proto.proto.ReasonCodeData.prototype.setReasonCodeFilterData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeData} returns this
 */
proto.proto.ReasonCodeData.prototype.clearReasonCodeFilterData = function() {
  return this.setReasonCodeFilterData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeData.prototype.hasReasonCodeFilterData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status reason_code_status = 3;
 * @return {!proto.proto.Status}
 */
proto.proto.ReasonCodeData.prototype.getReasonCodeStatus = function() {
  return /** @type {!proto.proto.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.proto.Status} value
 * @return {!proto.proto.ReasonCodeData} returns this
 */
proto.proto.ReasonCodeData.prototype.setReasonCodeStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ReasonCodeCategoryListData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeCategoryListData.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeCategoryListData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeCategoryListData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeCategoryListData.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonCodeInfoListList: jspb.Message.toObjectList(msg.getReasonCodeInfoListList(),
    proto.proto.ReasonCodeDetailsInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeCategoryListData}
 */
proto.proto.ReasonCodeCategoryListData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeCategoryListData;
  return proto.proto.ReasonCodeCategoryListData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeCategoryListData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeCategoryListData}
 */
proto.proto.ReasonCodeCategoryListData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.ReasonCodeDetailsInfo;
      reader.readMessage(value,proto.proto.ReasonCodeDetailsInfo.deserializeBinaryFromReader);
      msg.addReasonCodeInfoList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeCategoryListData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeCategoryListData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeCategoryListData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeCategoryListData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonCodeInfoListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.ReasonCodeDetailsInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReasonCodeDetailsInfo reason_code_info_list = 1;
 * @return {!Array<!proto.proto.ReasonCodeDetailsInfo>}
 */
proto.proto.ReasonCodeCategoryListData.prototype.getReasonCodeInfoListList = function() {
  return /** @type{!Array<!proto.proto.ReasonCodeDetailsInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.ReasonCodeDetailsInfo, 1));
};


/**
 * @param {!Array<!proto.proto.ReasonCodeDetailsInfo>} value
 * @return {!proto.proto.ReasonCodeCategoryListData} returns this
*/
proto.proto.ReasonCodeCategoryListData.prototype.setReasonCodeInfoListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.ReasonCodeDetailsInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ReasonCodeDetailsInfo}
 */
proto.proto.ReasonCodeCategoryListData.prototype.addReasonCodeInfoList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.ReasonCodeDetailsInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ReasonCodeCategoryListData} returns this
 */
proto.proto.ReasonCodeCategoryListData.prototype.clearReasonCodeInfoListList = function() {
  return this.setReasonCodeInfoListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeFilterData.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeFilterData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeFilterData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeFilterData.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonCodeCategoryFilter: (f = msg.getReasonCodeCategoryFilter()) && common_ReasonCodeDetails_pb.ReasonCodeCategoryFilter.toObject(includeInstance, f),
    reasonCodeFactor: (f = msg.getReasonCodeFactor()) && common_ReasonCodeDetails_pb.ReasonCodeFactor.toObject(includeInstance, f),
    reasonCode: (f = msg.getReasonCode()) && common_ReasonCodeDetails_pb.ReasonCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeFilterData}
 */
proto.proto.ReasonCodeFilterData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeFilterData;
  return proto.proto.ReasonCodeFilterData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeFilterData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeFilterData}
 */
proto.proto.ReasonCodeFilterData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new common_ReasonCodeDetails_pb.ReasonCodeCategoryFilter;
      reader.readMessage(value,common_ReasonCodeDetails_pb.ReasonCodeCategoryFilter.deserializeBinaryFromReader);
      msg.setReasonCodeCategoryFilter(value);
      break;
    case 3:
      var value = new common_ReasonCodeDetails_pb.ReasonCodeFactor;
      reader.readMessage(value,common_ReasonCodeDetails_pb.ReasonCodeFactor.deserializeBinaryFromReader);
      msg.setReasonCodeFactor(value);
      break;
    case 4:
      var value = new common_ReasonCodeDetails_pb.ReasonCode;
      reader.readMessage(value,common_ReasonCodeDetails_pb.ReasonCode.deserializeBinaryFromReader);
      msg.setReasonCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeFilterData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeFilterData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeFilterData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeFilterData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonCodeCategoryFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_ReasonCodeDetails_pb.ReasonCodeCategoryFilter.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeFactor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_ReasonCodeDetails_pb.ReasonCodeFactor.serializeBinaryToWriter
    );
  }
  f = message.getReasonCode();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_ReasonCodeDetails_pb.ReasonCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReasonCodeCategoryFilter reason_code_category_filter = 2;
 * @return {?proto.proto.ReasonCodeCategoryFilter}
 */
proto.proto.ReasonCodeFilterData.prototype.getReasonCodeCategoryFilter = function() {
  return /** @type{?proto.proto.ReasonCodeCategoryFilter} */ (
    jspb.Message.getWrapperField(this, common_ReasonCodeDetails_pb.ReasonCodeCategoryFilter, 2));
};


/**
 * @param {?proto.proto.ReasonCodeCategoryFilter|undefined} value
 * @return {!proto.proto.ReasonCodeFilterData} returns this
*/
proto.proto.ReasonCodeFilterData.prototype.setReasonCodeCategoryFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeFilterData} returns this
 */
proto.proto.ReasonCodeFilterData.prototype.clearReasonCodeCategoryFilter = function() {
  return this.setReasonCodeCategoryFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeFilterData.prototype.hasReasonCodeCategoryFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ReasonCodeFactor reason_code_factor = 3;
 * @return {?proto.proto.ReasonCodeFactor}
 */
proto.proto.ReasonCodeFilterData.prototype.getReasonCodeFactor = function() {
  return /** @type{?proto.proto.ReasonCodeFactor} */ (
    jspb.Message.getWrapperField(this, common_ReasonCodeDetails_pb.ReasonCodeFactor, 3));
};


/**
 * @param {?proto.proto.ReasonCodeFactor|undefined} value
 * @return {!proto.proto.ReasonCodeFilterData} returns this
*/
proto.proto.ReasonCodeFilterData.prototype.setReasonCodeFactor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeFilterData} returns this
 */
proto.proto.ReasonCodeFilterData.prototype.clearReasonCodeFactor = function() {
  return this.setReasonCodeFactor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeFilterData.prototype.hasReasonCodeFactor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ReasonCode reason_code = 4;
 * @return {?proto.proto.ReasonCode}
 */
proto.proto.ReasonCodeFilterData.prototype.getReasonCode = function() {
  return /** @type{?proto.proto.ReasonCode} */ (
    jspb.Message.getWrapperField(this, common_ReasonCodeDetails_pb.ReasonCode, 4));
};


/**
 * @param {?proto.proto.ReasonCode|undefined} value
 * @return {!proto.proto.ReasonCodeFilterData} returns this
*/
proto.proto.ReasonCodeFilterData.prototype.setReasonCode = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeFilterData} returns this
 */
proto.proto.ReasonCodeFilterData.prototype.clearReasonCode = function() {
  return this.setReasonCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeFilterData.prototype.hasReasonCode = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ReasonCodeCategoryData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeCategoryData.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeCategoryData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeCategoryData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeCategoryData.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonCodeCategoryInfoList: jspb.Message.toObjectList(msg.getReasonCodeCategoryInfoList(),
    proto.proto.ReasonCodeCategoryInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeCategoryData}
 */
proto.proto.ReasonCodeCategoryData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeCategoryData;
  return proto.proto.ReasonCodeCategoryData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeCategoryData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeCategoryData}
 */
proto.proto.ReasonCodeCategoryData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.ReasonCodeCategoryInfo;
      reader.readMessage(value,proto.proto.ReasonCodeCategoryInfo.deserializeBinaryFromReader);
      msg.addReasonCodeCategoryInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeCategoryData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeCategoryData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeCategoryData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeCategoryData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonCodeCategoryInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.ReasonCodeCategoryInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReasonCodeCategoryInfo reason_code_category_info = 1;
 * @return {!Array<!proto.proto.ReasonCodeCategoryInfo>}
 */
proto.proto.ReasonCodeCategoryData.prototype.getReasonCodeCategoryInfoList = function() {
  return /** @type{!Array<!proto.proto.ReasonCodeCategoryInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.ReasonCodeCategoryInfo, 1));
};


/**
 * @param {!Array<!proto.proto.ReasonCodeCategoryInfo>} value
 * @return {!proto.proto.ReasonCodeCategoryData} returns this
*/
proto.proto.ReasonCodeCategoryData.prototype.setReasonCodeCategoryInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.ReasonCodeCategoryInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ReasonCodeCategoryInfo}
 */
proto.proto.ReasonCodeCategoryData.prototype.addReasonCodeCategoryInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.ReasonCodeCategoryInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ReasonCodeCategoryData} returns this
 */
proto.proto.ReasonCodeCategoryData.prototype.clearReasonCodeCategoryInfoList = function() {
  return this.setReasonCodeCategoryInfoList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ReasonCodeCategoryInfo.repeatedFields_ = [2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeCategoryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeCategoryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeCategoryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeCategoryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonCodeCategoryTitle: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statusList: (f = jspb.Message.getRepeatedBooleanField(msg, 2)) == null ? undefined : f,
    reasonCode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    reasonCodeFactorInfoList: jspb.Message.toObjectList(msg.getReasonCodeFactorInfoList(),
    proto.proto.ReasonCodeFactorInfo.toObject, includeInstance),
    reasonCodeCategoryUuid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdOn: jspb.Message.getFieldWithDefault(msg, 6, 0),
    modifiedOn: jspb.Message.getFieldWithDefault(msg, 7, 0),
    state: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeCategoryInfo}
 */
proto.proto.ReasonCodeCategoryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeCategoryInfo;
  return proto.proto.ReasonCodeCategoryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeCategoryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeCategoryInfo}
 */
proto.proto.ReasonCodeCategoryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCodeCategoryTitle(value);
      break;
    case 2:
      var values = /** @type {!Array<boolean>} */ (reader.isDelimited() ? reader.readPackedBool() : [reader.readBool()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatus(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReasonCode(value);
      break;
    case 4:
      var value = new proto.proto.ReasonCodeFactorInfo;
      reader.readMessage(value,proto.proto.ReasonCodeFactorInfo.deserializeBinaryFromReader);
      msg.addReasonCodeFactorInfo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCodeCategoryUuid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedOn(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModifiedOn(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeCategoryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeCategoryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeCategoryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeCategoryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonCodeCategoryTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writePackedBool(
      2,
      f
    );
  }
  f = message.getReasonCode();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getReasonCodeFactorInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.proto.ReasonCodeFactorInfo.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeCategoryUuid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedOn();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getModifiedOn();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getState();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string reason_code_category_title = 1;
 * @return {string}
 */
proto.proto.ReasonCodeCategoryInfo.prototype.getReasonCodeCategoryTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeCategoryInfo} returns this
 */
proto.proto.ReasonCodeCategoryInfo.prototype.setReasonCodeCategoryTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated bool status = 2;
 * @return {!Array<boolean>}
 */
proto.proto.ReasonCodeCategoryInfo.prototype.getStatusList = function() {
  return /** @type {!Array<boolean>} */ (jspb.Message.getRepeatedBooleanField(this, 2));
};


/**
 * @param {!Array<boolean>} value
 * @return {!proto.proto.ReasonCodeCategoryInfo} returns this
 */
proto.proto.ReasonCodeCategoryInfo.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {boolean} value
 * @param {number=} opt_index
 * @return {!proto.proto.ReasonCodeCategoryInfo} returns this
 */
proto.proto.ReasonCodeCategoryInfo.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ReasonCodeCategoryInfo} returns this
 */
proto.proto.ReasonCodeCategoryInfo.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * optional int64 reason_code = 3;
 * @return {number}
 */
proto.proto.ReasonCodeCategoryInfo.prototype.getReasonCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReasonCodeCategoryInfo} returns this
 */
proto.proto.ReasonCodeCategoryInfo.prototype.setReasonCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated ReasonCodeFactorInfo reason_code_factor_info = 4;
 * @return {!Array<!proto.proto.ReasonCodeFactorInfo>}
 */
proto.proto.ReasonCodeCategoryInfo.prototype.getReasonCodeFactorInfoList = function() {
  return /** @type{!Array<!proto.proto.ReasonCodeFactorInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.ReasonCodeFactorInfo, 4));
};


/**
 * @param {!Array<!proto.proto.ReasonCodeFactorInfo>} value
 * @return {!proto.proto.ReasonCodeCategoryInfo} returns this
*/
proto.proto.ReasonCodeCategoryInfo.prototype.setReasonCodeFactorInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.proto.ReasonCodeFactorInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ReasonCodeFactorInfo}
 */
proto.proto.ReasonCodeCategoryInfo.prototype.addReasonCodeFactorInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.proto.ReasonCodeFactorInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ReasonCodeCategoryInfo} returns this
 */
proto.proto.ReasonCodeCategoryInfo.prototype.clearReasonCodeFactorInfoList = function() {
  return this.setReasonCodeFactorInfoList([]);
};


/**
 * optional string reason_code_category_uuid = 5;
 * @return {string}
 */
proto.proto.ReasonCodeCategoryInfo.prototype.getReasonCodeCategoryUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeCategoryInfo} returns this
 */
proto.proto.ReasonCodeCategoryInfo.prototype.setReasonCodeCategoryUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 created_on = 6;
 * @return {number}
 */
proto.proto.ReasonCodeCategoryInfo.prototype.getCreatedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReasonCodeCategoryInfo} returns this
 */
proto.proto.ReasonCodeCategoryInfo.prototype.setCreatedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 modified_on = 7;
 * @return {number}
 */
proto.proto.ReasonCodeCategoryInfo.prototype.getModifiedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReasonCodeCategoryInfo} returns this
 */
proto.proto.ReasonCodeCategoryInfo.prototype.setModifiedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool state = 8;
 * @return {boolean}
 */
proto.proto.ReasonCodeCategoryInfo.prototype.getState = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCodeCategoryInfo} returns this
 */
proto.proto.ReasonCodeCategoryInfo.prototype.setState = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ReasonCodeFactorInfo.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeFactorInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeFactorInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeFactorInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeFactorInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonCodeFactorTitle: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    reasonCode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    reasonCodeDetailsInfoList: jspb.Message.toObjectList(msg.getReasonCodeDetailsInfoList(),
    proto.proto.ReasonCodeDetailsInfo.toObject, includeInstance),
    reasonCodeFactorUuid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdOn: jspb.Message.getFieldWithDefault(msg, 6, 0),
    modifiedOn: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeFactorInfo}
 */
proto.proto.ReasonCodeFactorInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeFactorInfo;
  return proto.proto.ReasonCodeFactorInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeFactorInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeFactorInfo}
 */
proto.proto.ReasonCodeFactorInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCodeFactorTitle(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReasonCode(value);
      break;
    case 4:
      var value = new proto.proto.ReasonCodeDetailsInfo;
      reader.readMessage(value,proto.proto.ReasonCodeDetailsInfo.deserializeBinaryFromReader);
      msg.addReasonCodeDetailsInfo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCodeFactorUuid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedOn(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModifiedOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeFactorInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeFactorInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeFactorInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeFactorInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonCodeFactorTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getReasonCode();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getReasonCodeDetailsInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.proto.ReasonCodeDetailsInfo.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeFactorUuid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedOn();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getModifiedOn();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string reason_code_factor_title = 1;
 * @return {string}
 */
proto.proto.ReasonCodeFactorInfo.prototype.getReasonCodeFactorTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeFactorInfo} returns this
 */
proto.proto.ReasonCodeFactorInfo.prototype.setReasonCodeFactorTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool status = 2;
 * @return {boolean}
 */
proto.proto.ReasonCodeFactorInfo.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCodeFactorInfo} returns this
 */
proto.proto.ReasonCodeFactorInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int64 reason_code = 3;
 * @return {number}
 */
proto.proto.ReasonCodeFactorInfo.prototype.getReasonCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReasonCodeFactorInfo} returns this
 */
proto.proto.ReasonCodeFactorInfo.prototype.setReasonCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated ReasonCodeDetailsInfo reason_code_details_info = 4;
 * @return {!Array<!proto.proto.ReasonCodeDetailsInfo>}
 */
proto.proto.ReasonCodeFactorInfo.prototype.getReasonCodeDetailsInfoList = function() {
  return /** @type{!Array<!proto.proto.ReasonCodeDetailsInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.ReasonCodeDetailsInfo, 4));
};


/**
 * @param {!Array<!proto.proto.ReasonCodeDetailsInfo>} value
 * @return {!proto.proto.ReasonCodeFactorInfo} returns this
*/
proto.proto.ReasonCodeFactorInfo.prototype.setReasonCodeDetailsInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.proto.ReasonCodeDetailsInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ReasonCodeDetailsInfo}
 */
proto.proto.ReasonCodeFactorInfo.prototype.addReasonCodeDetailsInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.proto.ReasonCodeDetailsInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ReasonCodeFactorInfo} returns this
 */
proto.proto.ReasonCodeFactorInfo.prototype.clearReasonCodeDetailsInfoList = function() {
  return this.setReasonCodeDetailsInfoList([]);
};


/**
 * optional string reason_code_factor_uuid = 5;
 * @return {string}
 */
proto.proto.ReasonCodeFactorInfo.prototype.getReasonCodeFactorUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeFactorInfo} returns this
 */
proto.proto.ReasonCodeFactorInfo.prototype.setReasonCodeFactorUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 created_on = 6;
 * @return {number}
 */
proto.proto.ReasonCodeFactorInfo.prototype.getCreatedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReasonCodeFactorInfo} returns this
 */
proto.proto.ReasonCodeFactorInfo.prototype.setCreatedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 modified_on = 7;
 * @return {number}
 */
proto.proto.ReasonCodeFactorInfo.prototype.getModifiedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReasonCodeFactorInfo} returns this
 */
proto.proto.ReasonCodeFactorInfo.prototype.setModifiedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeDetailsInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeDetailsInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeDetailsInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeDetailsInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonCodeCategoryUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reasonCodeFactorUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reasonCode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    createdOn: jspb.Message.getFieldWithDefault(msg, 5, 0),
    modifiedOn: jspb.Message.getFieldWithDefault(msg, 6, 0),
    reasonCodeDetailsUuid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    reasonCodeDetailsTitle: jspb.Message.getFieldWithDefault(msg, 8, ""),
    reasonCodeCategoryTitle: jspb.Message.getFieldWithDefault(msg, 9, ""),
    reasonCodeFactorTitle: jspb.Message.getFieldWithDefault(msg, 10, ""),
    equipmentId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    equipmentName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    equipmentDesc: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeDetailsInfo}
 */
proto.proto.ReasonCodeDetailsInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeDetailsInfo;
  return proto.proto.ReasonCodeDetailsInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeDetailsInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeDetailsInfo}
 */
proto.proto.ReasonCodeDetailsInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCodeCategoryUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCodeFactorUuid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReasonCode(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedOn(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModifiedOn(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCodeDetailsUuid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCodeDetailsTitle(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCodeCategoryTitle(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCodeFactorTitle(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEquipmentId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipmentName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipmentDesc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeDetailsInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeDetailsInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeDetailsInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeDetailsInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonCodeCategoryUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReasonCodeFactorUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReasonCode();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCreatedOn();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getModifiedOn();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getReasonCodeDetailsUuid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getReasonCodeDetailsTitle();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getReasonCodeCategoryTitle();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getReasonCodeFactorTitle();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getEquipmentId();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getEquipmentName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getEquipmentDesc();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string reason_code_category_uuid = 1;
 * @return {string}
 */
proto.proto.ReasonCodeDetailsInfo.prototype.getReasonCodeCategoryUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeDetailsInfo} returns this
 */
proto.proto.ReasonCodeDetailsInfo.prototype.setReasonCodeCategoryUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reason_code_factor_uuid = 2;
 * @return {string}
 */
proto.proto.ReasonCodeDetailsInfo.prototype.getReasonCodeFactorUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeDetailsInfo} returns this
 */
proto.proto.ReasonCodeDetailsInfo.prototype.setReasonCodeFactorUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 reason_code = 3;
 * @return {number}
 */
proto.proto.ReasonCodeDetailsInfo.prototype.getReasonCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReasonCodeDetailsInfo} returns this
 */
proto.proto.ReasonCodeDetailsInfo.prototype.setReasonCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool status = 4;
 * @return {boolean}
 */
proto.proto.ReasonCodeDetailsInfo.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCodeDetailsInfo} returns this
 */
proto.proto.ReasonCodeDetailsInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 created_on = 5;
 * @return {number}
 */
proto.proto.ReasonCodeDetailsInfo.prototype.getCreatedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReasonCodeDetailsInfo} returns this
 */
proto.proto.ReasonCodeDetailsInfo.prototype.setCreatedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 modified_on = 6;
 * @return {number}
 */
proto.proto.ReasonCodeDetailsInfo.prototype.getModifiedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReasonCodeDetailsInfo} returns this
 */
proto.proto.ReasonCodeDetailsInfo.prototype.setModifiedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string reason_code_details_uuid = 7;
 * @return {string}
 */
proto.proto.ReasonCodeDetailsInfo.prototype.getReasonCodeDetailsUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeDetailsInfo} returns this
 */
proto.proto.ReasonCodeDetailsInfo.prototype.setReasonCodeDetailsUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string reason_code_details_title = 8;
 * @return {string}
 */
proto.proto.ReasonCodeDetailsInfo.prototype.getReasonCodeDetailsTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeDetailsInfo} returns this
 */
proto.proto.ReasonCodeDetailsInfo.prototype.setReasonCodeDetailsTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string reason_code_category_title = 9;
 * @return {string}
 */
proto.proto.ReasonCodeDetailsInfo.prototype.getReasonCodeCategoryTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeDetailsInfo} returns this
 */
proto.proto.ReasonCodeDetailsInfo.prototype.setReasonCodeCategoryTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string reason_code_factor_title = 10;
 * @return {string}
 */
proto.proto.ReasonCodeDetailsInfo.prototype.getReasonCodeFactorTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeDetailsInfo} returns this
 */
proto.proto.ReasonCodeDetailsInfo.prototype.setReasonCodeFactorTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 equipment_id = 11;
 * @return {number}
 */
proto.proto.ReasonCodeDetailsInfo.prototype.getEquipmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReasonCodeDetailsInfo} returns this
 */
proto.proto.ReasonCodeDetailsInfo.prototype.setEquipmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string equipment_name = 12;
 * @return {string}
 */
proto.proto.ReasonCodeDetailsInfo.prototype.getEquipmentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeDetailsInfo} returns this
 */
proto.proto.ReasonCodeDetailsInfo.prototype.setEquipmentName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string equipment_desc = 13;
 * @return {string}
 */
proto.proto.ReasonCodeDetailsInfo.prototype.getEquipmentDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeDetailsInfo} returns this
 */
proto.proto.ReasonCodeDetailsInfo.prototype.setEquipmentDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ResourceViewInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ResourceViewInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ResourceViewInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ResourceViewInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceTree: (f = msg.getResourceTree()) && proto.proto.ResourceTree.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ResourceViewInfo}
 */
proto.proto.ResourceViewInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ResourceViewInfo;
  return proto.proto.ResourceViewInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ResourceViewInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ResourceViewInfo}
 */
proto.proto.ResourceViewInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.ResourceTree;
      reader.readMessage(value,proto.proto.ResourceTree.deserializeBinaryFromReader);
      msg.setResourceTree(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ResourceViewInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ResourceViewInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ResourceViewInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ResourceViewInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceTree();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.ResourceTree.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResourceTree resource_tree = 1;
 * @return {?proto.proto.ResourceTree}
 */
proto.proto.ResourceViewInfo.prototype.getResourceTree = function() {
  return /** @type{?proto.proto.ResourceTree} */ (
    jspb.Message.getWrapperField(this, proto.proto.ResourceTree, 1));
};


/**
 * @param {?proto.proto.ResourceTree|undefined} value
 * @return {!proto.proto.ResourceViewInfo} returns this
*/
proto.proto.ResourceViewInfo.prototype.setResourceTree = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ResourceViewInfo} returns this
 */
proto.proto.ResourceViewInfo.prototype.clearResourceTree = function() {
  return this.setResourceTree(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ResourceViewInfo.prototype.hasResourceTree = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ResourceTree.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ResourceTree.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ResourceTree.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ResourceTree} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ResourceTree.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resourceDescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resourceType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    resourceTreeList: jspb.Message.toObjectList(msg.getResourceTreeList(),
    proto.proto.ResourceTree.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ResourceTree}
 */
proto.proto.ResourceTree.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ResourceTree;
  return proto.proto.ResourceTree.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ResourceTree} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ResourceTree}
 */
proto.proto.ResourceTree.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceType(value);
      break;
    case 4:
      var value = new proto.proto.ResourceTree;
      reader.readMessage(value,proto.proto.ResourceTree.deserializeBinaryFromReader);
      msg.addResourceTree(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ResourceTree.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ResourceTree.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ResourceTree} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ResourceTree.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResourceDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResourceType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getResourceTreeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.proto.ResourceTree.serializeBinaryToWriter
    );
  }
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.proto.ResourceTree.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ResourceTree} returns this
 */
proto.proto.ResourceTree.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string resource_description = 2;
 * @return {string}
 */
proto.proto.ResourceTree.prototype.getResourceDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ResourceTree} returns this
 */
proto.proto.ResourceTree.prototype.setResourceDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string resource_type = 3;
 * @return {string}
 */
proto.proto.ResourceTree.prototype.getResourceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ResourceTree} returns this
 */
proto.proto.ResourceTree.prototype.setResourceType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ResourceTree resource_tree = 4;
 * @return {!Array<!proto.proto.ResourceTree>}
 */
proto.proto.ResourceTree.prototype.getResourceTreeList = function() {
  return /** @type{!Array<!proto.proto.ResourceTree>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.ResourceTree, 4));
};


/**
 * @param {!Array<!proto.proto.ResourceTree>} value
 * @return {!proto.proto.ResourceTree} returns this
*/
proto.proto.ResourceTree.prototype.setResourceTreeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.proto.ResourceTree=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ResourceTree}
 */
proto.proto.ResourceTree.prototype.addResourceTree = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.proto.ResourceTree, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ResourceTree} returns this
 */
proto.proto.ResourceTree.prototype.clearResourceTreeList = function() {
  return this.setResourceTreeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ResourceData.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ResourceData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ResourceData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ResourceData.toObject = function(includeInstance, msg) {
  var f, obj = {
    filterName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resourceInfo: (f = msg.getResourceInfo()) && proto.proto.ResourceInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ResourceData}
 */
proto.proto.ResourceData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ResourceData;
  return proto.proto.ResourceData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ResourceData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ResourceData}
 */
proto.proto.ResourceData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilterName(value);
      break;
    case 2:
      var value = new proto.proto.ResourceInfo;
      reader.readMessage(value,proto.proto.ResourceInfo.deserializeBinaryFromReader);
      msg.setResourceInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ResourceData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ResourceData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ResourceData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ResourceData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilterName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResourceInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ResourceInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string filter_name = 1;
 * @return {string}
 */
proto.proto.ResourceData.prototype.getFilterName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ResourceData} returns this
 */
proto.proto.ResourceData.prototype.setFilterName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ResourceInfo resource_info = 2;
 * @return {?proto.proto.ResourceInfo}
 */
proto.proto.ResourceData.prototype.getResourceInfo = function() {
  return /** @type{?proto.proto.ResourceInfo} */ (
    jspb.Message.getWrapperField(this, proto.proto.ResourceInfo, 2));
};


/**
 * @param {?proto.proto.ResourceInfo|undefined} value
 * @return {!proto.proto.ResourceData} returns this
*/
proto.proto.ResourceData.prototype.setResourceInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ResourceData} returns this
 */
proto.proto.ResourceData.prototype.clearResourceInfo = function() {
  return this.setResourceInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ResourceData.prototype.hasResourceInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ResourceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ResourceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ResourceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ResourceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reasonCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    equipmentId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ResourceInfo}
 */
proto.proto.ResourceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ResourceInfo;
  return proto.proto.ResourceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ResourceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ResourceInfo}
 */
proto.proto.ResourceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResourceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ResourceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ResourceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ResourceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ResourceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getReasonCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEquipmentId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 resource_id = 1;
 * @return {number}
 */
proto.proto.ResourceInfo.prototype.getResourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ResourceInfo} returns this
 */
proto.proto.ResourceInfo.prototype.setResourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string reason_code = 2;
 * @return {string}
 */
proto.proto.ResourceInfo.prototype.getReasonCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ResourceInfo} returns this
 */
proto.proto.ResourceInfo.prototype.setReasonCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string equipment_id = 3;
 * @return {string}
 */
proto.proto.ResourceInfo.prototype.getEquipmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ResourceInfo} returns this
 */
proto.proto.ResourceInfo.prototype.setEquipmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AMDFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AMDFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AMDFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AMDFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    shiftInfo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    textFilter: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AMDFilter}
 */
proto.proto.AMDFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AMDFilter;
  return proto.proto.AMDFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AMDFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AMDFilter}
 */
proto.proto.AMDFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.proto.ShiftInfo} */ (reader.readEnum());
      msg.setShiftInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AMDFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AMDFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AMDFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AMDFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShiftInfo();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTextFilter();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ShiftInfo shift_info = 1;
 * @return {!proto.proto.ShiftInfo}
 */
proto.proto.AMDFilter.prototype.getShiftInfo = function() {
  return /** @type {!proto.proto.ShiftInfo} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.proto.ShiftInfo} value
 * @return {!proto.proto.AMDFilter} returns this
 */
proto.proto.AMDFilter.prototype.setShiftInfo = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 start_time = 2;
 * @return {number}
 */
proto.proto.AMDFilter.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AMDFilter} returns this
 */
proto.proto.AMDFilter.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_time = 3;
 * @return {number}
 */
proto.proto.AMDFilter.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AMDFilter} returns this
 */
proto.proto.AMDFilter.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string text_filter = 4;
 * @return {string}
 */
proto.proto.AMDFilter.prototype.getTextFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AMDFilter} returns this
 */
proto.proto.AMDFilter.prototype.setTextFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeBatchDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeBatchDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeBatchDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeBatchDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonCodeCategoryInfo: (f = msg.getReasonCodeCategoryInfo()) && proto.proto.ReasonCodeCategoryInfo.toObject(includeInstance, f),
    reasonCodeFactorInfo: (f = msg.getReasonCodeFactorInfo()) && proto.proto.ReasonCodeFactorInfo.toObject(includeInstance, f),
    reasonCodeDetailsInfo: (f = msg.getReasonCodeDetailsInfo()) && proto.proto.ReasonCodeDetailsInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeBatchDetails}
 */
proto.proto.ReasonCodeBatchDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeBatchDetails;
  return proto.proto.ReasonCodeBatchDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeBatchDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeBatchDetails}
 */
proto.proto.ReasonCodeBatchDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.ReasonCodeCategoryInfo;
      reader.readMessage(value,proto.proto.ReasonCodeCategoryInfo.deserializeBinaryFromReader);
      msg.setReasonCodeCategoryInfo(value);
      break;
    case 2:
      var value = new proto.proto.ReasonCodeFactorInfo;
      reader.readMessage(value,proto.proto.ReasonCodeFactorInfo.deserializeBinaryFromReader);
      msg.setReasonCodeFactorInfo(value);
      break;
    case 3:
      var value = new proto.proto.ReasonCodeDetailsInfo;
      reader.readMessage(value,proto.proto.ReasonCodeDetailsInfo.deserializeBinaryFromReader);
      msg.setReasonCodeDetailsInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeBatchDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeBatchDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeBatchDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeBatchDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonCodeCategoryInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.ReasonCodeCategoryInfo.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeFactorInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ReasonCodeFactorInfo.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeDetailsInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.ReasonCodeDetailsInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReasonCodeCategoryInfo reason_code_category_info = 1;
 * @return {?proto.proto.ReasonCodeCategoryInfo}
 */
proto.proto.ReasonCodeBatchDetails.prototype.getReasonCodeCategoryInfo = function() {
  return /** @type{?proto.proto.ReasonCodeCategoryInfo} */ (
    jspb.Message.getWrapperField(this, proto.proto.ReasonCodeCategoryInfo, 1));
};


/**
 * @param {?proto.proto.ReasonCodeCategoryInfo|undefined} value
 * @return {!proto.proto.ReasonCodeBatchDetails} returns this
*/
proto.proto.ReasonCodeBatchDetails.prototype.setReasonCodeCategoryInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeBatchDetails} returns this
 */
proto.proto.ReasonCodeBatchDetails.prototype.clearReasonCodeCategoryInfo = function() {
  return this.setReasonCodeCategoryInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeBatchDetails.prototype.hasReasonCodeCategoryInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ReasonCodeFactorInfo reason_code_factor_info = 2;
 * @return {?proto.proto.ReasonCodeFactorInfo}
 */
proto.proto.ReasonCodeBatchDetails.prototype.getReasonCodeFactorInfo = function() {
  return /** @type{?proto.proto.ReasonCodeFactorInfo} */ (
    jspb.Message.getWrapperField(this, proto.proto.ReasonCodeFactorInfo, 2));
};


/**
 * @param {?proto.proto.ReasonCodeFactorInfo|undefined} value
 * @return {!proto.proto.ReasonCodeBatchDetails} returns this
*/
proto.proto.ReasonCodeBatchDetails.prototype.setReasonCodeFactorInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeBatchDetails} returns this
 */
proto.proto.ReasonCodeBatchDetails.prototype.clearReasonCodeFactorInfo = function() {
  return this.setReasonCodeFactorInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeBatchDetails.prototype.hasReasonCodeFactorInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ReasonCodeDetailsInfo reason_code_details_info = 3;
 * @return {?proto.proto.ReasonCodeDetailsInfo}
 */
proto.proto.ReasonCodeBatchDetails.prototype.getReasonCodeDetailsInfo = function() {
  return /** @type{?proto.proto.ReasonCodeDetailsInfo} */ (
    jspb.Message.getWrapperField(this, proto.proto.ReasonCodeDetailsInfo, 3));
};


/**
 * @param {?proto.proto.ReasonCodeDetailsInfo|undefined} value
 * @return {!proto.proto.ReasonCodeBatchDetails} returns this
*/
proto.proto.ReasonCodeBatchDetails.prototype.setReasonCodeDetailsInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeBatchDetails} returns this
 */
proto.proto.ReasonCodeBatchDetails.prototype.clearReasonCodeDetailsInfo = function() {
  return this.setReasonCodeDetailsInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeBatchDetails.prototype.hasReasonCodeDetailsInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReasonCodeLogInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReasonCodeLogInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReasonCodeLogInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeLogInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    stoppageId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fpLine: jspb.Message.getFieldWithDefault(msg, 2, ""),
    equipment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    batchOrder: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productSku: jspb.Message.getFieldWithDefault(msg, 5, ""),
    reasonCodeName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    operator: jspb.Message.getFieldWithDefault(msg, 7, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    endDate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 10, 0),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    processOrder: jspb.Message.getFieldWithDefault(msg, 12, ""),
    reasonCodeCategoryInfo: (f = msg.getReasonCodeCategoryInfo()) && proto.proto.ReasonCodeCategoryInfo.toObject(includeInstance, f),
    reasonCodeFactorInfo: (f = msg.getReasonCodeFactorInfo()) && proto.proto.ReasonCodeFactorInfo.toObject(includeInstance, f),
    reasonCodeDetailsInfo: (f = msg.getReasonCodeDetailsInfo()) && proto.proto.ReasonCodeDetailsInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReasonCodeLogInfo}
 */
proto.proto.ReasonCodeLogInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReasonCodeLogInfo;
  return proto.proto.ReasonCodeLogInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReasonCodeLogInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReasonCodeLogInfo}
 */
proto.proto.ReasonCodeLogInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStoppageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFpLine(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchOrder(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductSku(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCodeName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperator(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartDate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndDate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessOrder(value);
      break;
    case 13:
      var value = new proto.proto.ReasonCodeCategoryInfo;
      reader.readMessage(value,proto.proto.ReasonCodeCategoryInfo.deserializeBinaryFromReader);
      msg.setReasonCodeCategoryInfo(value);
      break;
    case 14:
      var value = new proto.proto.ReasonCodeFactorInfo;
      reader.readMessage(value,proto.proto.ReasonCodeFactorInfo.deserializeBinaryFromReader);
      msg.setReasonCodeFactorInfo(value);
      break;
    case 15:
      var value = new proto.proto.ReasonCodeDetailsInfo;
      reader.readMessage(value,proto.proto.ReasonCodeDetailsInfo.deserializeBinaryFromReader);
      msg.setReasonCodeDetailsInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReasonCodeLogInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReasonCodeLogInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReasonCodeLogInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReasonCodeLogInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoppageId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFpLine();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEquipment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBatchOrder();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductSku();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getReasonCodeName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOperator();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStartDate();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getEndDate();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getProcessOrder();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getReasonCodeCategoryInfo();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.proto.ReasonCodeCategoryInfo.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeFactorInfo();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.proto.ReasonCodeFactorInfo.serializeBinaryToWriter
    );
  }
  f = message.getReasonCodeDetailsInfo();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.proto.ReasonCodeDetailsInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 stoppage_id = 1;
 * @return {number}
 */
proto.proto.ReasonCodeLogInfo.prototype.getStoppageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
 */
proto.proto.ReasonCodeLogInfo.prototype.setStoppageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string fp_line = 2;
 * @return {string}
 */
proto.proto.ReasonCodeLogInfo.prototype.getFpLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
 */
proto.proto.ReasonCodeLogInfo.prototype.setFpLine = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string equipment = 3;
 * @return {string}
 */
proto.proto.ReasonCodeLogInfo.prototype.getEquipment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
 */
proto.proto.ReasonCodeLogInfo.prototype.setEquipment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string batch_order = 4;
 * @return {string}
 */
proto.proto.ReasonCodeLogInfo.prototype.getBatchOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
 */
proto.proto.ReasonCodeLogInfo.prototype.setBatchOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product_sku = 5;
 * @return {string}
 */
proto.proto.ReasonCodeLogInfo.prototype.getProductSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
 */
proto.proto.ReasonCodeLogInfo.prototype.setProductSku = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string reason_code_name = 6;
 * @return {string}
 */
proto.proto.ReasonCodeLogInfo.prototype.getReasonCodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
 */
proto.proto.ReasonCodeLogInfo.prototype.setReasonCodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string operator = 7;
 * @return {string}
 */
proto.proto.ReasonCodeLogInfo.prototype.getOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
 */
proto.proto.ReasonCodeLogInfo.prototype.setOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 start_date = 8;
 * @return {number}
 */
proto.proto.ReasonCodeLogInfo.prototype.getStartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
 */
proto.proto.ReasonCodeLogInfo.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 end_date = 9;
 * @return {number}
 */
proto.proto.ReasonCodeLogInfo.prototype.getEndDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
 */
proto.proto.ReasonCodeLogInfo.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 duration = 10;
 * @return {number}
 */
proto.proto.ReasonCodeLogInfo.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
 */
proto.proto.ReasonCodeLogInfo.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool status = 11;
 * @return {boolean}
 */
proto.proto.ReasonCodeLogInfo.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
 */
proto.proto.ReasonCodeLogInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string process_order = 12;
 * @return {string}
 */
proto.proto.ReasonCodeLogInfo.prototype.getProcessOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
 */
proto.proto.ReasonCodeLogInfo.prototype.setProcessOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional ReasonCodeCategoryInfo reason_code_category_info = 13;
 * @return {?proto.proto.ReasonCodeCategoryInfo}
 */
proto.proto.ReasonCodeLogInfo.prototype.getReasonCodeCategoryInfo = function() {
  return /** @type{?proto.proto.ReasonCodeCategoryInfo} */ (
    jspb.Message.getWrapperField(this, proto.proto.ReasonCodeCategoryInfo, 13));
};


/**
 * @param {?proto.proto.ReasonCodeCategoryInfo|undefined} value
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
*/
proto.proto.ReasonCodeLogInfo.prototype.setReasonCodeCategoryInfo = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
 */
proto.proto.ReasonCodeLogInfo.prototype.clearReasonCodeCategoryInfo = function() {
  return this.setReasonCodeCategoryInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeLogInfo.prototype.hasReasonCodeCategoryInfo = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional ReasonCodeFactorInfo reason_code_factor_info = 14;
 * @return {?proto.proto.ReasonCodeFactorInfo}
 */
proto.proto.ReasonCodeLogInfo.prototype.getReasonCodeFactorInfo = function() {
  return /** @type{?proto.proto.ReasonCodeFactorInfo} */ (
    jspb.Message.getWrapperField(this, proto.proto.ReasonCodeFactorInfo, 14));
};


/**
 * @param {?proto.proto.ReasonCodeFactorInfo|undefined} value
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
*/
proto.proto.ReasonCodeLogInfo.prototype.setReasonCodeFactorInfo = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
 */
proto.proto.ReasonCodeLogInfo.prototype.clearReasonCodeFactorInfo = function() {
  return this.setReasonCodeFactorInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeLogInfo.prototype.hasReasonCodeFactorInfo = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional ReasonCodeDetailsInfo reason_code_details_info = 15;
 * @return {?proto.proto.ReasonCodeDetailsInfo}
 */
proto.proto.ReasonCodeLogInfo.prototype.getReasonCodeDetailsInfo = function() {
  return /** @type{?proto.proto.ReasonCodeDetailsInfo} */ (
    jspb.Message.getWrapperField(this, proto.proto.ReasonCodeDetailsInfo, 15));
};


/**
 * @param {?proto.proto.ReasonCodeDetailsInfo|undefined} value
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
*/
proto.proto.ReasonCodeLogInfo.prototype.setReasonCodeDetailsInfo = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReasonCodeLogInfo} returns this
 */
proto.proto.ReasonCodeLogInfo.prototype.clearReasonCodeDetailsInfo = function() {
  return this.setReasonCodeDetailsInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReasonCodeLogInfo.prototype.hasReasonCodeDetailsInfo = function() {
  return jspb.Message.getField(this, 15) != null;
};


goog.object.extend(exports, proto.proto);
