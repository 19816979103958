import { LineOverviewCard } from "../../components/Common/LineOverviewCard";
import "./lineOverview.scss";
import { message } from "antd";
import "antd/dist/antd.css";
import UplLogo from "assets/icons/uplLogo.png";
import FillingMachineImg from "assets/images/machineImg.png";
import CappingMachineImg from "assets/images/capping-main.png";

import { Pie } from "components/Charts/Pie/pie";
import { DowntimeChart } from "components/Charts/DownTime/downtime";
import { SolidGuage } from "components/Charts/SolidGuage/SolidGuage";
import { RateMatrix } from "components/Charts/RateMatrix/RateMatrix";
import { LostTimeMatrix } from "components/Charts/LostTimeMatrix/LostTimeMatrix";
import ChartContainer from "components/ChartContainer";
import { ActiveAlarmCard } from "components/ActiveAlarmTable";
import ContentHeader from "components/ContentHeader";
import { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/icons/uplLogo.svg";
import {
  getActiveAlarmsTableData,
  getDowntimeTrend,
  getLineOEEData,
  getLostTimeMatrix,
  getRateMatrix,
  getRuntimeDowntime,
  teepMatrixData,
} from "store/actions/lineOverview";
import { getAlarmsAndEventsDashboardData } from "store/actions/alarmsEvents";
import { LineOverviewFilterComponent } from "./LineOverviewFilter";
import { getFilterTreeData } from "store/actions/rootAction";
import { OeeFilterComponent } from "pages/OEE/OeeDropDownFilter";
import { object } from "yup";
import moment from "moment";
import { getUrl } from "config/apiconfig";
import {  allTreeNodeTitle, dateConverter, findNestedObj, getMachineNodeIds } from "utils/common";
import { line } from "d3-shape";

const lostTimeList = [];

var pieData = [];

const LineOverviewData = [];

const dummyMatrix: any[] = [
  {
    machineName: "machine1",
    totalSpeedPpm: 0,
    totalSpeedPph: 0,
    rejectSpeedPpm: 0,
    goodSpeedPph: 0,
    inSpeedPph: 0,
    inSpeedPpm: 0,
  },
];

const downTime = [
  { value: 0, category: "Unplanned" },
  { value: 0, category: "Planned" },
  { value: 0, category: "Small" },
];
export const LineOverview = () => {
  const alarmsEventsActiveAlarmsList = useSelector(
    (state: any) => state.lineOverview.tableData
  );
  const alarmsEventsActiveAlarmsCardData = useSelector(
    (state: any) => state.lineOverview.lineOverviewAlarmsSummaryCardData
  );

  const alarmsEventsActiveAlarmsRowCount = useSelector(
    (state: any) => state.lineOverview.rowCount
  );

  const oeeDataList = useSelector((state: any) => state.lineOverview.oeeData);
  const teepMatrixGraphData = useSelector(
    (state: any) => state.lineOverview.teepMatrix
  );
  const downTimeList = useSelector(
    (state: any) => state.lineOverview.downTimeTrend
  );
  const lostTimeMatrix = useSelector(
    (state: any) => state.lineOverview.lostTimeData
  );
  const rateMatrixList = useSelector(
    (state: any) => state.lineOverview.rateMatrixData
  );

  const startTimeRedux = useSelector(
    (state: any) => state.root.filterValues.startTime
  );
  const endTimeRedux = useSelector(
    (state: any) => state.root.filterValues.endTime
  );
  const nodeIdRedux = useSelector(
    (state: any) => state.root.filterValues.nodeId
  );
  const skuRedux = useSelector((state: any) => state.root.filterValues.sku);
  const filterTreeData = useSelector((state: any) => state.root.filterTree);
  const nodeArr= getMachineNodeIds(filterTreeData?.children)

  const [payload, setPayload] = useState<any>({
    startTime: startTimeRedux,
    endTime: endTimeRedux,
    nodeId: nodeIdRedux,
    sku: skuRedux,
  });

  const [page, setPage] = useState(1);
  const [machineData, setMachineData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const idArray: any = [];
  const dispatch = useDispatch();
  const [oeeData, setOeeData] = useState<any[]>(LineOverviewData);
  const [teepData, setTeepData] = useState<any[]>(pieData);
  const [downTimeData, setDownTimeData] = useState<any[]>(downTime);
  const [rateData, setRateData] = useState<any[]>(dummyMatrix);
  const [lostTimeData, setLostTimeData] = useState<any[]>(lostTimeList);
  const [alarmParams, setAlarmParams] = useState<any>({
    lineObject: useSelector((state: any) => state.root.filterValues.lineObject),
    nodeId: useSelector((state: any) => state.root.filterValues.nodeId),
    startTime: useSelector((state: any) => state.root.filterValues.startTime),
    endTime: useSelector((state: any) => state.root.filterValues.endTime),
    severity: useSelector((state: any) => state.root.filterValues.severity),
    batches: useSelector((state: any) => state.root.filterValues.batches),
    sku: useSelector((state: any) => state.root.filterValues.sku),
    page: useSelector((state: any) => state.root.filterValues.page),
  });
  const filterFlag = useSelector((state: any) => state.root.filterFlag);
  const selectedNodeIds: any = useSelector(
    (state: any) => state.root.filterValues.nodeId
  );
  const allNodeTittle = allTreeNodeTitle(filterTreeData?.children);
  const result: any = [];

  //creating array of objects of aLL machines present in leaf node
  selectedNodeIds.forEach((i) => {
    result.push(findNestedObj(filterTreeData, "key", i));
  });
  const startDate = "StartTime :" + dateConverter(payload.startTime);
  const endDate = "EndTime :" + dateConverter(payload.endTime);
  const sku = "Sku :" + payload.sku;
  useEffect(() => {
    if (selectedNodeIds.length > 0) {
      setPayload(payload => ({
        ...payload,
        nodeId: selectedNodeIds
      }));
    }
  }, [selectedNodeIds])  
  useEffect(() => {
    sendRequest();
  }, [payload]);

  useEffect(() => {
    if (Object.keys(filterTreeData).length > 0) {
      getMachineData(filterTreeData);
      orderMachineData(idArray);
    }
  }, [filterTreeData]);

  useEffect(() => {
    setOeeData(oeeDataList);
  }, [oeeDataList]);

  useEffect(() => {
    if (typeof downTimeList !== undefined) {
      const noData = { category: "Not in production", value: 10 };
      let dataToShow =
        downTimeList.length && downTimeList.every((item) => item.value === 0)
          ? downTimeList.push(noData)
          : downTimeList;
      setDownTimeData(downTimeList);
    }
  }, [downTimeList]);

  useEffect(() => {
    if (teepMatrixGraphData.length > 0) {
      setTeepData(teepMatrixGraphData);
    }
  }, [teepMatrixGraphData]);

  useEffect(() => {
    if (typeof rateMatrixList !== undefined) {
      rateMatrixList.forEach(function (r) {
        let rateMatrixObjects = Object.entries(r);
        rateMatrixObjects.forEach((e) => {
          // e[0] is the key and e[1] is the value
          
          if (!isNaN(Number(e[1]))) {
            r[e[0]] = Number(Number(e[1]).toFixed(2));
          }
        });
      });
      setRateData(rateMatrixList);
    }
  }, [rateMatrixList]);

  useEffect(() => {
    if (typeof lostTimeMatrix !== undefined) {
      setLostTimeData(lostTimeMatrix);
    }
  }, [lostTimeMatrix]);

  const getMachineData = (obj) => {
    let attribute: any = {};

    if (obj.list) {
      attribute = obj.list.reduce(
        (acc, cur) => ({ ...acc, [cur.title]: cur.value }),
        {}
      );
    }
    idArray.push({
      attributeList: attribute,
      machineName: obj.title,
      machineKey: obj.key,
    });

    if (!obj.children) {
      return;
    }
    obj.children.forEach((child) => getMachineData(child));
  };

  const orderMachineData = (data: any) => {
    let newOrderedData: any = [];
    data.map((item: any) => {
      switch (item.machineName) {
        case "Capping_Machine_01":
          newOrderedData[0] = {
            ...item,
            subheading: "Capping Machine of F01",
            img: CappingMachineImg,
          };
          break;
        case "Filling_Machine_01":
          newOrderedData[1] = {
            ...item,
            subheading: "Filling Machine of F01",
            img: FillingMachineImg,
          };
          break;
        case "Filling_Machine_02":
          newOrderedData[2] = {
            ...item,
            subheading: "Filling Machine of F02",
            img: FillingMachineImg,
          };
          break;
        case "Capping_Machine_02":
          newOrderedData[3] = {
            ...item,
            subheading: "Capping Machine of F02",
            img: CappingMachineImg,
          };
          break;
        case "Filling_Machine_03":
          newOrderedData[4] = {
            ...item,
            subheading: "Filling Machine of F03",
            img: FillingMachineImg,
          };
          break;
        case "Capping_Machine_03":
          newOrderedData[5] = {
            ...item,
            subheading: "Capping Machine of F03",
            img: CappingMachineImg,
          };
          break;
        default:
          break;
      }
    });
    initialMachineData(newOrderedData);
    setMachineData(newOrderedData);
    setFilteredData(newOrderedData);
    filterMachineData(nodeIdRedux);
  };
  const lineName = payload?.lineObject?.map((item: any) => {
    return item.machine;
  });
  const machineLines = result?.map((item: any) => {
    return item?.title;
  });
  const line =
    "Machine Line :" +
    (allNodeTittle?.toString()
      ? machineLines?.toString()
      : lineName?.toString());
  const exportImagePdf = async () => {
    const header = [
      'Timestamp',
      'Device Name',
      'Device Tag',
      'Alarm Description',
      'Alarm Severity',
    ];
    const rows =
      alarmsEventsActiveAlarmsList.length > 0 &&
      alarmsEventsActiveAlarmsList.map((elt) => [
        moment.unix(elt["timestamp"] / 1000).format('MM-DD-YYYY, HH:mm:ss'),
        elt["deviceName"],
        elt["deviceTag"],
        elt["alarmDescription"],
        elt["severity"],
      ]);

    message.loading("Downloading Pdf Started", 10);
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const doc: any = new jsPDF(orientation, unit, size);
    let lineOverViewCardImage: any;
    let overallEquipmentEffectivenessImage: any;
    let downtimeTrendImage: any;
    let lineOverViewChartsImage: any;

    const lineOverViewCard = window.document.getElementById(
      "lineOverViewCards"
    ) as HTMLElement;
    const overallEquipmentEffectiveness = window.document.getElementById(
      "overallEquipmentEffectiveness"
    ) as HTMLElement;
    const downtimeTrend = window.document.getElementById(
      "downtimeTrend"
    ) as HTMLElement;
    const lineOverViewCharts = window.document.getElementById(
      "lineOverViewCharts"
    ) as HTMLElement;
    const img: any = new Image();
    await html2canvas(lineOverViewCard).then((canvas) => {
      lineOverViewCardImage = canvas.toDataURL("image/png", 1.0);
    });
    await html2canvas(overallEquipmentEffectiveness).then((canvas) => {
      overallEquipmentEffectivenessImage = canvas.toDataURL("image/png", 1.0);
    });
    await html2canvas(downtimeTrend).then((canvas) => {
      downtimeTrendImage = canvas.toDataURL("image/png", 1.0);
    });
    await html2canvas(lineOverViewCharts).then((canvas) => {
      lineOverViewChartsImage = canvas.toDataURL("image/png", 1.0);
    });

    doc.addImage(UplLogo, 'PNG', 430, 7, 36, 36);

    doc.setFontSize(18);
    doc.setFont(undefined, 'bold');
    doc.text(210, 65, "Line Overview Report – UPL Europe (Sandbach Plant)");
    doc.setFontSize(15);
    doc.setFont(undefined, 'bold');

    doc.text(20, 85, "Report Information");
    doc.setFont(undefined, "normal");
    doc.text(20, 105, startDate, "left", "5");
    doc.text(20, 125, endDate);
    doc.text(20, 145, sku);
    let lines = doc.splitTextToSize(line, 900 - 57 - 57);
    doc.text(20, 165, lines);
    doc.addImage(
      lineOverViewCardImage,
      "PNG",
      20,
      195,
      770,
      result?.length > 4 ? 390 : 270
    );
    //    doc.addImage(overallEquipmentEffectivenessImage, "PNG", left_margin, top_margin, width, height);
    doc.addPage();
    doc.addImage(overallEquipmentEffectivenessImage, "PNG", 20, 50, 375, 220);

    doc.addImage(downtimeTrendImage, "PNG", 420, 50, 375, 220);

    doc.addImage(lineOverViewChartsImage, "PNG", 20, 290, 770, 260);

    doc.addPage();

    const headers = [header];
    const data = rows;
    message.info(data["length"] + " record(s) are getting downloaded");
    doc.text(350, 30, "Active Alarms");
    autoTable(doc, {
      head: headers,
      body: data,
      styles: { fontSize: 8 },
      margin: { top: 50 },
    });
    doc.save("Line_Overview" + "_" + moment().format("DD/MM/YYYY_HH:MM:SS"));
  };
  const makeRequest = (data: any) => {
    let linePayload: any = {};
    linePayload["startTime"] = data.startTime;
    linePayload["endTime"] = data.endTime;
    linePayload["nodeId"] = data.nodeId;
    linePayload["sku"] = data.sku;

    let alarmPayload: any = alarmParams;
    alarmPayload["startTime"] = data.startTime;
    alarmPayload["endTime"] = data.endTime;
    alarmPayload["nodeId"] = data.nodeId;
    filterMachineData(data.nodeId);
    dispatch(getLineOEEData(linePayload));
    dispatch(getActiveAlarmsTableData({ ...alarmParams, page }));
    dispatch(teepMatrixData(linePayload));
    dispatch(getDowntimeTrend(linePayload));
    dispatch(getRuntimeDowntime(linePayload));
    dispatch(getLostTimeMatrix(linePayload));
    dispatch(getRateMatrix(linePayload));
  };
  const sendRequest = () => {
    dispatch(getLineOEEData(payload));
    dispatch(getActiveAlarmsTableData({ ...alarmParams, page }));
    dispatch(teepMatrixData(payload));
    dispatch(getDowntimeTrend(payload));
    dispatch(getRuntimeDowntime(payload));
    dispatch(getLostTimeMatrix(payload));
    dispatch(getRateMatrix(payload));
  };

  const initialMachineData = (data: any) => {
    let nodesId = nodeArr;
    let updatedMachineArr: any = [];
    nodesId.map((item: any) => {
      updatedMachineArr.push(
        ...data.filter((machine) => machine.machineKey === item)
      );
    });
    setFilteredData(updatedMachineArr);
  };
  const filterMachineData = (data: any) => {
    let newData = machineData;
    let updatedMachineArr: any = [];
    data.map((item: any) => {
      updatedMachineArr.push(
        ...newData.filter((machine) => machine.machineKey === item)
      );
    });
    if (newData.length) {
      setFilteredData(updatedMachineArr);
    }
  };

  return (
    <>
      <ContentHeader heading="Line Overview"  />
      <OeeFilterComponent
        sendRequest={makeRequest}
        page={page}
        download={() => exportImagePdf()}
      />
      <div className="content-container">
        <div
          className="LineOverview-card-container-wrapper marginTop8"
          id="lineOverViewCards"
        >
          {filteredData &&
            filteredData.map((item) => <LineOverviewCard machine={item} />)}
        </div>
        <div className="LineOverview-container marginTop8">
          <ChartContainer
            id="overallEquipmentEffectiveness"
            title={"Overall Equipment Effectiveness"}
            alignChartTitle={"center"}
            tooltip={"Overall Equipment Effectiveness"}
            width={"256px"}
            height="256px"
            child={
              <SolidGuage
                data={[...oeeData].reverse()}
                chartID={"LineoverviewoverallEquipmentEffectiveness"}
              />
            }
          />
          <ChartContainer
            id="downtimeTrend"
            title={"Downtime Trend"}
            alignChartTitle={"center"}
            tooltip={"Downtime Trend"}
            width={"100%"}
            height="256px"
            child={<DowntimeChart data={downTimeData} />}
          />
          <ChartContainer
            id="activeAlarma"
            title="Active Alarm"
            alignChartTitle="left"
            tooltip=""
            child={
              <ActiveAlarmCard
                chartID="paretoActiveAlarm"
                data={alarmsEventsActiveAlarmsList}
                cardData={alarmsEventsActiveAlarmsCardData}
                alarmsEventsActiveAlarmsRowCount={
                  alarmsEventsActiveAlarmsRowCount
                }
                setPage={setPage}
              />
            }
            width="100%"
          />
        </div>

        <div>
          <div
            className="Lineoverview-charts-bar-container marginTop8"
            id="lineOverViewCharts"
          >
            <ChartContainer
              id="teepMatrix"
              title={"Teep Matrix"}
              alignChartTitle={"center"}
              tooltip={"Teep matrix"}
              width={"100%"}
              height={"230px"}
              child={<Pie chartID="treeMatrixPie" data={teepData} />}
            />
            <ChartContainer
              id="rateMatrix"
              title={"Rate Matrix"}
              alignChartTitle={"left"}
              tooltip={"rate matrix"}
              width={"100%"}
              height={"230px"}
              child={<RateMatrix data={rateData} />}
              showFullScreen={true}
            />
            <ChartContainer
              id="lostTimeMatrix"
              title={"Lost Time Matrix"}
              alignChartTitle={"left"}
              tooltip={"lost matrix"}
              width={"100%"}
              height={"230px"}
              child={<LostTimeMatrix data={lostTimeData} />}
              showFullScreen={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
