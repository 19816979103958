import fillingMachineImage from "assets/images/fillingMachine.png";
import CappingMachineImg from "assets/images/capping-icon-main.png";
import { Button } from "antd";
import { useEffect, useState } from "react";
import MetricsCard from "../MetricsCard";
import ReasonCodeForm from "../ReasonCodeForm";
import { useDispatch, useSelector } from "react-redux";
import { getBatchesByLine } from "store/actions/rootAction";
import { getMachineStatus } from "store/actions/operator";
import { machineKeyToStatus } from "utils/common";
import { useInterval } from "utils/pollingHook";

const metricsData = [
  { metricsName: "Batch Number", metricsValue: "7373737" },
  { metricsName: "Process Order", metricsValue: "7373737" },
  { metricsName: "Product SKU", metricsValue: "7373737" },
  { metricsName: "Filling & Packing Line ", metricsValue: "" },
];

const Overview: React.FC = () => {
  const dispatch = useDispatch();
  const currentLineDetails = useSelector(
    (state: any) => state.operator.currentLineDetails
  );
  const selectedLine = useSelector(
    (state: any) => state.operator.selectedFpLine
  );

  const machineStatus = useSelector(
    (state: any) => state.operator.machineStatus
  );
  const filterTreeData = useSelector((state: any) => state.root.filterTree);
  const [showFillingMachineForm, setShowFillingMachineForm] = useState(false);
  const [showCappingMachineForm, setShowCappingMachineForm] = useState(false);
  const [currentMetric, setCurrentMetric] = useState<any>(metricsData);
  const [machineData, setMachineData] = useState<any>(null);
  const [fp, setFp] = useState<string>("");
  const [startTime, setStartTime] = useState<string>("");
  const [currentSelection, setCurrentSelection] = useState<any>(null);

  useEffect(() => {
    if (selectedLine !== "" && selectedLine >= 0) {
      dispatch(getBatchesByLine(selectedLine));
    }
    setCurrentSelection(selectedLine);
  }, [dispatch, selectedLine]);

  useEffect(() => {
    let metric = [...metricsData];
    if (currentLineDetails) {
      metric[0].metricsValue = currentLineDetails["batchNumber"];
      metric[1].metricsValue = currentLineDetails["processOrder"];
      metric[2].metricsValue = currentLineDetails["productSKU"];
      metric[3].metricsValue = fp || "";
      setStartTime(currentLineDetails["startTime"]);
    }
    setCurrentMetric(metric);
  }, [currentLineDetails, fp]);

  useEffect(() => {
    if (
      filterTreeData &&
      filterTreeData.children &&
      filterTreeData.children.length &&
      filterTreeData.children[0].children &&
      filterTreeData.children[0].children.length &&
      filterTreeData.children[0].children[0].children &&
      filterTreeData.children[0].children[0].children.length
    ) {
      let data = filterTreeData.children[0].children[0].children[0].children;
      if (selectedLine >= 0 && data && data[selectedLine]) {
        setFp(data[selectedLine].title);
        setMachineData(data[selectedLine].children);
      }
    }
    dispatch(getMachineStatus());
  }, [filterTreeData, selectedLine]);

  useInterval(()=>{
    dispatch(getMachineStatus());
  },10000)

  return (
    <>
      {currentMetric && currentMetric.length > 0 ? (
        <MetricsCard metricsData={currentMetric} />
      ) : null}
      <div className="desctiption-container">
        <div className="desctiption-heading">Description</div>
        <div className="desctiption-value">Description</div>
      </div>
      <div className="machine-container-outer">
        <div className="machine-container">
          <div className="image-container">
            <div className="status-container">
              <div
                className={machineKeyToStatus(
                  machineData && machineData[0] && machineData[0].key
                    ? machineData[0].key
                    : null,
                  machineStatus
                ).toLocaleLowerCase()}
              >
                {machineKeyToStatus(
                  machineData && machineData[0] && machineData[0].key
                    ? machineData[0].key
                    : null,
                  machineStatus
                )}
              </div>
            </div>
            {currentSelection === "2" ? (
              <img className="image" src={fillingMachineImage} alt="fireSpot" />
            ) : (
              <img className="image" src={CappingMachineImg} alt="fireSpot" />
            )}
          </div>
          <div className="reason-code-form-container">
            <div className="status-heading">
              {machineData && machineData[0] && machineData[0].title
                ? machineData[0].title
                : null}
            </div>
            {showFillingMachineForm ? (
              <ReasonCodeForm
                metric={currentMetric}
                line={machineData && machineData[0]}
                startTime={startTime}
              />
            ) : (
              <Button
                shape="round"
                size="large"
                onClick={() =>
                  setShowFillingMachineForm(!showFillingMachineForm)
                }
              >
                Add Reason Code
              </Button>
            )}
          </div>
        </div>
        <div className="machine-container">
          <div className=" image-container">
            <div className="status-container">
              <div
                className={machineKeyToStatus(
                  machineData && machineData[1] && machineData[1].key
                    ? machineData[1].key
                    : null,
                  machineStatus
                ).toLocaleLowerCase()}
              >
                {" "}
                {machineKeyToStatus(
                  machineData && machineData[1] && machineData[1].key
                    ? machineData[1].key
                    : null,
                  machineStatus
                )}
              </div>
            </div>
            {currentSelection === "2" ? (
              <img className="image" src={CappingMachineImg} alt="fireSpot" />
            ) : (
              <img className="image" src={fillingMachineImage} alt="fireSpot" />
            )}
          </div>
          <div className="reason-code-form-container">
            <div className="status-heading">
              {machineData && machineData[1] && machineData[1].title
                ? machineData[1].title
                : null}
            </div>
            {showCappingMachineForm ? (
              <ReasonCodeForm
                metric={currentMetric}
                line={machineData && machineData[1]}
                startTime={startTime}
              />
            ) : (
              <Button
                shape="round"
                size="large"
                onClick={() =>
                  setShowCappingMachineForm(!showCappingMachineForm)
                }
              >
                Add Reason Code
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
