import { Modal } from "antd";
import { Text } from "../Text";
import { ReactComponent as SoluleverImg } from "assets/icons/solulever.svg";
import { ReactComponent as BraboTagImg } from "assets/icons/braboTag.svg";

import "./index.scss";

export const LicenseModal = (props: any) => {
  return (
    <Modal
      title="License Information"
      visible={props.visible}
      centered
      className="about-us-main"
      onCancel={props.handleCancel}
      footer={null}
      width={482}
    >
      <div className="about-modal">
        <BraboTagImg />
        <div className="properties">
          <ul className="keys">
            <li className="blue-text">
              <Text type="para14M" screenClass="gray-text">
                Product Name
              </Text>
            </li>
            <li className="blue-text">
              <Text type="para14M" screenClass="gray-text">
                License Type
              </Text>
            </li>
            <li className="blue-text">
              <Text type="para14M" screenClass="gray-text">
                License Key
              </Text>
            </li>
            <li className="blue-text">
              <Text type="para14M" screenClass="gray-text">
                Status
              </Text>
            </li>
            <li className="blue-text">
              <Text type="para14M" screenClass="gray-text">
                Activated on
              </Text>
            </li>
            <li className="blue-text">
              <Text type="para14M" screenClass="gray-text">
                Expires on
              </Text>
            </li>
            <li className="blue-text">
              <Text type="para14M" screenClass="gray-text">
                Activated by
              </Text>
            </li>
          </ul>
          <ul className="values">
            <li >
              <Text screenClass="blue-text">Brabo Edge Platform</Text>
            </li>
            <li >
              <Text screenClass="blue-text">Evaluation License (15 Days)</Text>
            </li>
            <li >
              <Text screenClass="blue-text">Not Applicable</Text>
            </li>
            <li className="green-text">
              <Text screenClass="blue-text">Valid</Text>

            </li>
            <li>
              <Text screenClass="gray-text">
                DD/MM/YY HH:MM:SS
              </Text>
            </li>
            <li>
              <Text screenClass="gray-text">
                DD/MM/YY HH:MM:SS
              </Text>
            </li>
            <li>
              <Text screenClass="gray-text">
                Solulever
              </Text>
            </li>
          </ul>
        </div>
        <div className="footer">
          <div className="desc">
            <Text type="description" screenClass="">
              Copyright © 2020 Solulever. All Rights Reserved.
            </Text>
          </div>
        </div>
      </div>
    </Modal>
  );
};
