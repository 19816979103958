/**
 * @fileoverview gRPC-Web generated client stub for upl_sandbach
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: service/AlarmEvents.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_Common_pb = require('../common/Common_pb.js')

var model_AlarmOverview_pb = require('../model/AlarmOverview_pb.js')

var model_AlarmDetails_pb = require('../model/AlarmDetails_pb.js')
const proto = {};
proto.upl_sandbach = require('./AlarmEvents_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.upl_sandbach.AlarmEventsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.upl_sandbach.AlarmEventsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.AlarmDashboardRequest,
 *   !proto.upl_sandbach.AlarmAndErrorSPCFilterRes>}
 */
const methodDescriptor_AlarmEventsService_getFilteredStackedBarChart = new grpc.web.MethodDescriptor(
  '/upl_sandbach.AlarmEventsService/getFilteredStackedBarChart',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.AlarmDashboardRequest,
  model_AlarmOverview_pb.AlarmAndErrorSPCFilterRes,
  /**
   * @param {!proto.upl_sandbach.AlarmDashboardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_AlarmOverview_pb.AlarmAndErrorSPCFilterRes.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.AlarmDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.AlarmAndErrorSPCFilterRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.AlarmAndErrorSPCFilterRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.AlarmEventsServiceClient.prototype.getFilteredStackedBarChart =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.AlarmEventsService/getFilteredStackedBarChart',
      request,
      metadata || {},
      methodDescriptor_AlarmEventsService_getFilteredStackedBarChart,
      callback);
};


/**
 * @param {!proto.upl_sandbach.AlarmDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.AlarmAndErrorSPCFilterRes>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.AlarmEventsServicePromiseClient.prototype.getFilteredStackedBarChart =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.AlarmEventsService/getFilteredStackedBarChart',
      request,
      metadata || {},
      methodDescriptor_AlarmEventsService_getFilteredStackedBarChart);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.AlarmDashboardRequest,
 *   !proto.upl_sandbach.AlarmAndErrorParetoFilterRes>}
 */
const methodDescriptor_AlarmEventsService_getFilteredParetoChart = new grpc.web.MethodDescriptor(
  '/upl_sandbach.AlarmEventsService/getFilteredParetoChart',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.AlarmDashboardRequest,
  model_AlarmOverview_pb.AlarmAndErrorParetoFilterRes,
  /**
   * @param {!proto.upl_sandbach.AlarmDashboardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_AlarmOverview_pb.AlarmAndErrorParetoFilterRes.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.AlarmDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.AlarmAndErrorParetoFilterRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.AlarmAndErrorParetoFilterRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.AlarmEventsServiceClient.prototype.getFilteredParetoChart =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.AlarmEventsService/getFilteredParetoChart',
      request,
      metadata || {},
      methodDescriptor_AlarmEventsService_getFilteredParetoChart,
      callback);
};


/**
 * @param {!proto.upl_sandbach.AlarmDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.AlarmAndErrorParetoFilterRes>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.AlarmEventsServicePromiseClient.prototype.getFilteredParetoChart =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.AlarmEventsService/getFilteredParetoChart',
      request,
      metadata || {},
      methodDescriptor_AlarmEventsService_getFilteredParetoChart);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.AlarmDashboardRequest,
 *   !proto.upl_sandbach.AlarmAndErrorDashboardResponse>}
 */
const methodDescriptor_AlarmEventsService_getAlarmDashboardOverview = new grpc.web.MethodDescriptor(
  '/upl_sandbach.AlarmEventsService/getAlarmDashboardOverview',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.AlarmDashboardRequest,
  proto.upl_sandbach.AlarmAndErrorDashboardResponse,
  /**
   * @param {!proto.upl_sandbach.AlarmDashboardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.upl_sandbach.AlarmAndErrorDashboardResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.AlarmDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.AlarmAndErrorDashboardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.AlarmAndErrorDashboardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.AlarmEventsServiceClient.prototype.getAlarmDashboardOverview =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.AlarmEventsService/getAlarmDashboardOverview',
      request,
      metadata || {},
      methodDescriptor_AlarmEventsService_getAlarmDashboardOverview,
      callback);
};


/**
 * @param {!proto.upl_sandbach.AlarmDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.AlarmAndErrorDashboardResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.AlarmEventsServicePromiseClient.prototype.getAlarmDashboardOverview =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.AlarmEventsService/getAlarmDashboardOverview',
      request,
      metadata || {},
      methodDescriptor_AlarmEventsService_getAlarmDashboardOverview);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.AlarmDashboardRequest,
 *   !proto.upl_sandbach.AlarmDetailsOverviewResponse>}
 */
const methodDescriptor_AlarmEventsService_getAlarmDetailsOverview = new grpc.web.MethodDescriptor(
  '/upl_sandbach.AlarmEventsService/getAlarmDetailsOverview',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.AlarmDashboardRequest,
  proto.upl_sandbach.AlarmDetailsOverviewResponse,
  /**
   * @param {!proto.upl_sandbach.AlarmDashboardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.upl_sandbach.AlarmDetailsOverviewResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.AlarmDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.AlarmDetailsOverviewResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.AlarmDetailsOverviewResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.AlarmEventsServiceClient.prototype.getAlarmDetailsOverview =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.AlarmEventsService/getAlarmDetailsOverview',
      request,
      metadata || {},
      methodDescriptor_AlarmEventsService_getAlarmDetailsOverview,
      callback);
};


/**
 * @param {!proto.upl_sandbach.AlarmDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.AlarmDetailsOverviewResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.AlarmEventsServicePromiseClient.prototype.getAlarmDetailsOverview =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.AlarmEventsService/getAlarmDetailsOverview',
      request,
      metadata || {},
      methodDescriptor_AlarmEventsService_getAlarmDetailsOverview);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.AlarmDashboardRequest,
 *   !proto.upl_sandbach.AlarmDetailsOverviewResponse>}
 */
const methodDescriptor_AlarmEventsService_getAllAlarmDetailsOverview = new grpc.web.MethodDescriptor(
  '/upl_sandbach.AlarmEventsService/getAllAlarmDetailsOverview',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.AlarmDashboardRequest,
  proto.upl_sandbach.AlarmDetailsOverviewResponse,
  /**
   * @param {!proto.upl_sandbach.AlarmDashboardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.upl_sandbach.AlarmDetailsOverviewResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.AlarmDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.AlarmDetailsOverviewResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.AlarmDetailsOverviewResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.AlarmEventsServiceClient.prototype.getAllAlarmDetailsOverview =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.AlarmEventsService/getAllAlarmDetailsOverview',
      request,
      metadata || {},
      methodDescriptor_AlarmEventsService_getAllAlarmDetailsOverview,
      callback);
};


/**
 * @param {!proto.upl_sandbach.AlarmDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.AlarmDetailsOverviewResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.AlarmEventsServicePromiseClient.prototype.getAllAlarmDetailsOverview =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.AlarmEventsService/getAllAlarmDetailsOverview',
      request,
      metadata || {},
      methodDescriptor_AlarmEventsService_getAllAlarmDetailsOverview);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.AlarmDashboardRequest,
 *   !proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse>}
 */
const methodDescriptor_AlarmEventsService_getAlarmAndSummaryPieChart = new grpc.web.MethodDescriptor(
  '/upl_sandbach.AlarmEventsService/getAlarmAndSummaryPieChart',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.AlarmDashboardRequest,
  proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse,
  /**
   * @param {!proto.upl_sandbach.AlarmDashboardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.AlarmDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.AlarmEventsServiceClient.prototype.getAlarmAndSummaryPieChart =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.AlarmEventsService/getAlarmAndSummaryPieChart',
      request,
      metadata || {},
      methodDescriptor_AlarmEventsService_getAlarmAndSummaryPieChart,
      callback);
};


/**
 * @param {!proto.upl_sandbach.AlarmDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.AlarmAndErrorSummaryPieChartResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.AlarmEventsServicePromiseClient.prototype.getAlarmAndSummaryPieChart =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.AlarmEventsService/getAlarmAndSummaryPieChart',
      request,
      metadata || {},
      methodDescriptor_AlarmEventsService_getAlarmAndSummaryPieChart);
};


module.exports = proto.upl_sandbach;

