import * as types from "store/actions/actionTypes";
import initialState from "./initalState";

export default function alarmsEventsReducer(
  state = initialState.alarmsEvents,
  action: any
) {
  switch (action.type) {
    case types.GET_ALARMS_EVENTS_DASHBOARD_DATA_SUCCESS:
      const {} = action.payload;
      state = {
        ...state,
      };
      return state;

    case types.GET_ALARMS_EVENTS_TABLE_DATA_SUCCESS:
      const {
        alarmsEventsActiveAlarmsList,
        totalNoOfRows,
        alarmsEventsActiveAlarmsCardData,
      } = action.payload;
      state = {
        ...state,
        alarmsEventsActiveAlarmsCardData: alarmsEventsActiveAlarmsCardData,

        alarmsEventsActiveAlarmsList: alarmsEventsActiveAlarmsList,
        alarmEventRowCount: totalNoOfRows,
      };
      return state;

    case types.GET_ALARMS_EVENTS_DETAILS_DATA_SUCCESS:
      const {
        alarmsEventsDetailsTableList,
        alarmsEventsDetailsBubbleList,
        alarmEventDetailsRowCount,
      } = action.payload;
      state = {
        ...state,
        alarmsEventsDetailsTableList: alarmsEventsDetailsTableList,
        alarmsEventsDetailsBubbleList: alarmsEventsDetailsBubbleList,
        alarmEventDetailsRowCount: alarmEventDetailsRowCount,
      };
      return state;

    case types.GET_ALARMS_EVENTS_GUAGE_DATA_SUCCESS:
      const {
        alarmsEventsSummaryByCountList,
        alarmsEventsSummaryByDurationList,
        alarmsEventsGuageSummaryByCountDataList,
        alarmsEventsGuageSummaryByDurationDataList,
      } = action.payload;
      state = {
        ...state,
        alarmsEventsSummaryByCountList: alarmsEventsSummaryByCountList,
        alarmsEventsSummaryByDurationList: alarmsEventsSummaryByDurationList,
        alarmsEventsGuageSummaryByCountDataList:
          alarmsEventsGuageSummaryByCountDataList,
        alarmsEventsGuageSummaryByDurationDataList:
          alarmsEventsGuageSummaryByDurationDataList,
      };
      return state;

    case types.GET_ALARMS_EVENTS_STACKED_BAR_CHART_DATA_SUCCESS:
      const {
        alarmsEventsCountByDevicesList,
        alarmsEventsDurationByDevicesList,
      } = action.payload;
      if (action.typeOfValue === "BY_COUNT") {
        state = {
          ...state,
          alarmsEventsCountByDevicesList: alarmsEventsCountByDevicesList,
        };
      } else {
        state = {
          ...state,
          alarmsEventsDurationByDevicesList: alarmsEventsDurationByDevicesList,
        };
      }
      return state;
    case types.GET_ALARMS_EVENTS_PARETO_CHART_DATA_SUCCESS:
      const { alarmsEventsCountByTagsList, alarmsEventsDurationByTagsList } =
        action.payload;
      if (action.typeOfValue === "BY_COUNT") {
        state = {
          ...state,
          alarmsEventsCountByTagsList: alarmsEventsCountByTagsList,
        };
      } else {
        state = {
          ...state,
          alarmsEventsDurationByTagsList: alarmsEventsDurationByTagsList,
        };
      }
      return state;
    default:
      return state;
  }
}
