import { message } from "antd";
import { getUrl } from "config/apiconfig";
import {
  mapAvgCycleDetailsData,
  mapProductionAssetUtilization,
  mapRuntimeAnalysisAssetUtilizationData,
  mapRuntimeAnalysisDashboardData,
  mapRuntimeAnalysisDetailsData,
  mapRuntimeAnalysisDowntimeData,
  mapRuntimeAnalysisMachineRuntimeStatusData,
  mapRuntimeAnalysisMtbfData,
  mapRuntimeAnalysisMttrData,
  mapRuntimeAnalysisPlannedStoptimeData,
  mapRuntimeAnalysisRuntimeData,
} from "mappers/runtimeAnalysisMapper";

export class RuntimeAnalysisService {
  private static instance: RuntimeAnalysisService;
  private runtimeAnalysisServiceClient: any;
  private runtimeAnalysisProtoObj: any;
  private authToken: any = "";
  private metadata: any;

  private constructor() {
    const reasonCodeURL = getUrl("reasonCodeUrl");
    const runtimeAnalysisService = require("protobuf/reason-code-management-protos/service/ReasonCodeDashboardService_grpc_web_pb");
    this.runtimeAnalysisProtoObj = require("protobuf/reason-code-management-protos/service/ReasonCodeDashboardService_pb");
    this.runtimeAnalysisServiceClient =
      new runtimeAnalysisService.ReasonCodeDashboardServicePromiseClient(
        reasonCodeURL,
        null,
        null
      );
    this.authToken = localStorage.getItem("authToken");
    this.metadata = { authorization: "Bearer " + this.authToken };
  }

  public static getInstance(): RuntimeAnalysisService {
    if (!RuntimeAnalysisService.instance) {
      RuntimeAnalysisService.instance = new RuntimeAnalysisService();
    }
    return RuntimeAnalysisService.instance;
  }

  getDowntimeValues = (payload: any) => {
    const request = new this.runtimeAnalysisProtoObj.RuntimeOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    if (payload.sku && payload.sku !== "") request.setSku(payload.sku);
    request.setNodeIdList(payload.nodeId);
    return this.runtimeAnalysisServiceClient
      .getDowntimeValues(request, this.metadata)
      .then((response: any) => {
        return mapRuntimeAnalysisDowntimeData(response);
      })
      .catch((err: any) => {
        message.error("Unable to get runtime downtime data");
      });
  };
  getRuntimeValues = (payload: any) => {
    const request = new this.runtimeAnalysisProtoObj.RuntimeOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    if (payload.sku && payload.sku !== "") request.setSku(payload.sku);
    request.setNodeIdList(payload.nodeId);
    return this.runtimeAnalysisServiceClient
      .getRuntimeValues(request, this.metadata)
      .then((response: any) => {
        return mapRuntimeAnalysisRuntimeData(response);
      })
      .catch((err: any) => {
        message.error("Unable to get runtime data");
      });
  };
  getPlannedStoptimeValues = (payload: any) => {
    const request = new this.runtimeAnalysisProtoObj.RuntimeOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    if (payload.sku && payload.sku !== "") request.setSku(payload.sku);
    request.setNodeIdList(payload.nodeId);
    return this.runtimeAnalysisServiceClient
      .getPlannedStoptimeValues(request, this.metadata)
      .then((response: any) => {
        return mapRuntimeAnalysisPlannedStoptimeData(response);
      })
      .catch((err: any) => {
        message.error("Unable to get runtime planned stopped time data");
      });
  };

  getAssetUtilizationValues = (payload: any) => {
    const request = new this.runtimeAnalysisProtoObj.RuntimeOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    if (payload.sku && payload.sku !== "") request.setSku(payload.sku);
    request.setNodeIdList(payload.nodeId);
    return this.runtimeAnalysisServiceClient
      .getAssetUtilization(request, this.metadata)
      .then((response: any) => {

        return mapRuntimeAnalysisAssetUtilizationData(response);
      })
      .catch((err: any) => {
        message.error("Unable to get runtime asset utilization data");
      });
  };

  getProductionAssetUtilizationValues = (payload:any)=>{
    const request = new this.runtimeAnalysisProtoObj.ProductionOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    if (payload.sku && payload.sku !== "") request.setSku(payload.sku);
    request.setNodeIdList(payload.nodeId);
    return this.runtimeAnalysisServiceClient
      .getOverallAssetUtilization(request, this.metadata)
      .then((response: any) => {
        return mapProductionAssetUtilization(response);
      })
      .catch((err: any) => {
        message.error("Unable to get Production asset utilization data");
      });
  }

  getMachineRuntimeStatusValues = (payload: any) => {
    const request = new this.runtimeAnalysisProtoObj.RuntimeOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    if (payload.sku && payload.sku !== "") request.setSku(payload.sku);
    request.setNodeIdList(payload.nodeId);
    return this.runtimeAnalysisServiceClient
      .getMachineRuntimeStatus(request, this.metadata)
      .then((response: any) => {
        return mapRuntimeAnalysisMachineRuntimeStatusData(response);
      })
      .catch((err: any) => {
        message.error("Unable to get runtime data");
      });
  };

  getMachineRuntimeMttrValues = (payload: any) => {
    const request = new this.runtimeAnalysisProtoObj.RuntimeOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    if (payload.sku && payload.sku !== "") request.setSku(payload.sku);
    request.setNodeIdList(payload.nodeId);
    return this.runtimeAnalysisServiceClient
      .getMttrParams(request, this.metadata)
      .then((response: any) => {
        return mapRuntimeAnalysisMttrData(response);
      })
      .catch((err: any) => {
        message.error("Unable to get runtime MTTR data ");
      });
  };

  getMachineRuntimeMtbfValues = (payload: any) => {
    const request = new this.runtimeAnalysisProtoObj.RuntimeOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    if (payload.sku && payload.sku !== "") request.setSku(payload.sku);
    request.setNodeIdList(payload.nodeId);
    return this.runtimeAnalysisServiceClient
      .getMtbfParams(request, this.metadata)
      .then((response: any) => {
        return mapRuntimeAnalysisMtbfData(response);
      })
      .catch((err: any) => {
        message.error("Unable to get runtime MTBF data");
      });
  };

  getMachineRuntimeDetailsValues = (payload: any) => {
    const request = new this.runtimeAnalysisProtoObj.RuntimeOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    if (payload.sku && payload.sku !== "") request.setSku(payload.sku);
    request.setNodeIdList(payload.nodeId);
    return this.runtimeAnalysisServiceClient
      .getRuntimeAnalysisDetails(request, this.metadata)
      .then((response: any) => {
        return mapRuntimeAnalysisDetailsData(response);
      })
      .catch((err: any) => {
        message.error("Unable to get runtime MTBF data");
      });
  };

  getMachineRuntimeDashboardGanttValues = (payload: any) => {
    const request = new this.runtimeAnalysisProtoObj.RuntimeOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    if (payload.sku && payload.sku !== "") request.setSku(payload.sku);
    request.setNodeIdList(payload.nodeId);
    return this.runtimeAnalysisServiceClient
      .getRuntimeAnalysisDetails(request, this.metadata)
      .then((response: any) => {
        return mapRuntimeAnalysisDashboardData(response);
      })
      .catch((err: any) => {
        message.error("Unable to get runtime Gantt data");
      });
  };

  getAverageCycleTimeData = (payload: any) => {
    const request = new this.runtimeAnalysisProtoObj.ProductionOverviewRequest();
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    request.setNodeIdList(payload.nodeId);
     if (payload.sku && payload.sku !== "")
      request.setSku(payload.sku);
    return this.runtimeAnalysisServiceClient
      .getAvgCycleTimeDetails(request, this.metadata)
      .then((response: any) => {
        return mapAvgCycleDetailsData(response);
      })
      .catch((err: any) => {
        message.error("Unable to get average cycle time data");
      });
  }
}
