import {
  BellOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Popover, Tooltip } from "antd";
import { ReactComponent as Upl } from "assets/icons/uplLogo.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock-icon.svg";
import { ReactComponent as ConnectedIcon } from "assets/icons/connected-icon.svg";
import { ReactComponent as DisconnectedNuc } from "assets/icons/disconnected-nuc-icon.svg";
import { ReactComponent as BroboTitleImage } from "assets/images/brabo-title-image.svg";
import { ReactComponent as NewTab } from "assets/icons/new-tab.svg";
import BreadcrumbComponent from "components/Breadcrumb";
import { useFullscreen } from "hooks/useFullscreen";
import { useState } from "react";
import "./index.scss";
import { Text } from "../Text";
import { useTime } from "hooks/useTime";

export const HeaderBar = () => {
  const [isConnected, setConnected] = useState(true);
  const [isFullScreen, fullScreen] = useFullscreen();

  const [userName, setUserName] = useState<string>("Name");
  const [imageUrl, setImageUrl] = useState("");
  const [hoverCss, setHoverCss] = useState(false);

  const displayTime = useTime();

  const openNewTab = () => {
    window.open(window.location.href);
  };

  const hoverStyle = {
    background: "#F0F5FF",
    borderRight: "2px solid #2F54EB",
  };

  const signOutHandler = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    window.location.reload();
  };

  const userDropDownContent = (
    <div>
      <p
        className="signOut"
        style={hoverCss ? hoverStyle : {}}
        onMouseEnter={() => setHoverCss(true)}
        onMouseLeave={() => setHoverCss(false)}
        onClick={signOutHandler}
      >
        <Text>Sign Out</Text>
      </p>
    </div>
  );

  return (
    <>
      <div className="header-container">
        <div className="bread-crumbs-container">
          <a
            href="https://www.upl-ltd.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Upl
              style={{ margin: "14px 16px", width: "24px", height: "24px" }}
            />
          </a>
          <BreadcrumbComponent />
        </div>

        <div className="logo-container">
          <BroboTitleImage style={{ width: "112px", height: "24px" }} />
        </div>

        <div className="info-container">
          <div className="clock-wrapper">
            <div className="clock-container">
              <ClockIcon style={{ marginRight: "5px" }} />
              {displayTime}
            </div>
          </div>
          <div className="device-status-header">
            <Tooltip title={""}>
              {isConnected ? (
                <ConnectedIcon className="connected-icon" />
              ) : (
                <DisconnectedNuc className="disconnected-icon" />
              )}
            </Tooltip>
          </div>
          <ul className="right-icons-container">
            <li className="icon">
              <QuestionCircleOutlined
                style={{ color: "#fff" }}
                className="icon-style"
              />
            </li>
            <li className="icon">
              {isFullScreen ? (
                <FullscreenExitOutlined
                  style={{ color: "#fff" }}
                  className="icon-style"
                />
              ) : (
                <FullscreenOutlined
                  style={{ color: "#fff" }}
                  className="icon-style"
                />
              )}
            </li>
            <li className="icon-style" onClick={openNewTab}>
              <NewTab />
            </li>
            <li>
              <BellOutlined style={{ color: "#fff" }} className="icon-style" />
            </li>
          </ul>
          <div className="user-image-container">
            <Popover
              overlayClassName={"user-menu-popover"}
              content={userDropDownContent}
              title={
                <>
                  <div className="profile-header">
                    {imageUrl.length > 0 ? (
                      <img src={imageUrl} className="userImg" alt="userImage" />
                    ) : (
                      <UserOutlined />
                    )}
                    <Text type="p14">{userName}</Text>
                  </div>
                </>
              }
              trigger="click"
              placement="bottomRight"
            >
              <UserOutlined style={{ color: "#fff" }} className="userImg" />
            </Popover>
          </div>
        </div>
      </div>
    </>
  );
};
