import * as types from 'store/actions/actionTypes';
import initialState from './initalState';

export default function reasonCodeReducer(state = initialState.reasonCode, action: any) {
  switch (action.type) {
    
    case types.GET_REASON_CODE_CATEGORIES_SUCCESS:
      state = {
        ...state,
        reasonCodeCategoryList: action.payload.categoryList,
        reasonCodeFactor: action.payload.reasonCodeFactor,
        reasonCodeDetailsDropdown: action.payload.reasonCodeDetails
      };
      return state;
    case types.GET_REASON_CODE_CATEGORY_DATA_SUCCESS:
      state = {
        ...state,
        reasonCodeAllList: action.payload.allCategoryList,
        reasonCodeFactorDetail: action.payload.reasonCodeFactorDetail,
        reasonCodeDetails: action.payload.reasonCodeDetails,
        reasonCodeListData: action.payload.reasonCodeListData,
        reasonCodeDataExpandedList: action.payload.reasonCodeDataExpandedList
      };
      return state;
    case types.GET_ASSIGNED_REASON_CODE_SUCCESS://Not using now Might be used in future
      state = {
        ...state,
        assignedReasonCode: action.payload.assignedReasonCodeListData
      };
      return state;//Not using now Might be used in future
    case types.GET_AMD_LIST_SUCCESS:
      return {
        ...state,
        amdList: action.payload
      }
       case types.ASSIGN_OPERATOR_LOG_SUCCESS:
      return { ...state, amdList:  action.payload };
    default:
      return state;
  }
}
