import "./index.scss";
import type { RadioChangeEvent } from "antd";
import { Radio, Input } from "antd";
import { useState } from "react";
import {
  EyeOutlined,
  BarsOutlined,
  ApartmentOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { AssignmentExpandedView } from "./Expandedview";
import { AssignmentListView } from "./AssignemntListView";

const { Search } = Input;

export const Resources = (props: any) => {
  const { setEquipmentId } = props;

  const [selectedValue, setselectedValue] = useState("Expanded");
  const optionsWithDisabled = [
    { label: <BarsOutlined />, value: "List" },
    { label: <ApartmentOutlined />, value: "Expanded" },
  ];
  const onViewChange = ({ target: { value } }: RadioChangeEvent) => {
    setselectedValue(value);
  };
  const onSearch = (value: string) => { };
  return (
    <div className="resource-container">
      <div className="resource-view-container">
        <div className="title">Resources</div>
        <div className="filter-view-container">
          <div className="view-eye-container">
            View
            <EyeOutlined /> :
          </div>
          <div className="view-container">
            <Radio.Group
              options={optionsWithDisabled}
              onChange={onViewChange}
              value={selectedValue}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
        </div>
      </div>
      <div className="resource-search-container">
        <div className="search-container">
          <Search
            placeholder="Search"
            onSearch={onSearch}
            style={{ width: "100%" }}
            bordered={false}
          />
        </div>
        <div className="resource-filter">
          <FilterOutlined />
        </div>
      </div>
      <div className="selected-view-container">
        {selectedValue === "List" ? (
          <AssignmentListView />
        ) : (
          <AssignmentExpandedView setEquipmentId={setEquipmentId} />
        )}
      </div>
    </div>
  );
};
