import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { MoreOutlined } from "@ant-design/icons";
import { ReactComponent as SortIcon } from "assets/icons/sort-icon.svg";
import { useEffect, useState } from "react";
import { getReasonCodeCategoriesData } from "store/actions/reasonCodeManagement";
import { useDispatch, useSelector } from "react-redux";
import { ReasonCodeInfo } from "types";
import { reasonCodeManagementSearchFilter } from "utils/common";

export const ListView = (props: any) => {
  const { searchValue, setCategoryUuid } = props;

  const [filteredListData, setFilteredListData] = useState<any>([]);
  const [payload, setPayload] = useState({ viewType: "list" })

  const listData = useSelector((state: any) => state.reasonCode.reasonCodeListData);

  const viewType = "list";
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReasonCodeCategoriesData(payload));
  }, [dispatch]);

  useEffect(() => {
    setFilteredListData(listData);
  }, [listData]);

  useEffect(() => {
    reasonCodeManagementSearchFilter(listData, searchValue, setFilteredListData, viewType)
  }, [searchValue]);

  const rowSelection: any = {
    onSelect: (record: any) => {
      setCategoryUuid(record.reasonCodeCategoryUuid)
    },
  };

  const columns: ColumnsType<ReasonCodeInfo> = [
    {
      key: "reasonCodeCategory",
      title: (
        <div className="column-title">
          <div>Reason Code Category</div>
          <SortIcon />
        </div>
      ),
      dataIndex: "reasonCodeCategory",
    },
    {
      key: "reasonCodeFactor",
      title: (
        <div className="column-title">
          <div>Reason Code Factor</div>
          <SortIcon />
        </div>
      ),
      dataIndex: "reasonCodeFactor",
    },
    {
      key: "reasonCode",
      title: (
        <div className="column-title">
          <div>Reason Code</div>
          <SortIcon />
        </div>
      ),
      dataIndex: "reasonCode",
    },
    {
      key: "createdOn",
      title: (
        <div className="column-title">
          <div>Created On</div>
          <SortIcon />
        </div>
      ),
      dataIndex: "createdOn",
    },
    {
      key: "lastModified",
      title: (
        <div className="column-title">
          <div>Last Modified</div>
          <SortIcon />
        </div>
      ),
      dataIndex: "lastModified",
    },
    {
      key: "status",
      title: (
        <div className="column-title">
          <div>Status</div>
          <SortIcon />
        </div>
      ),
      dataIndex: "status",
      render: (_, { status }) => (
        <div className="status-container">
          <div className="value">
            <span className={`${status}`}>{status}</span>
          </div>
          <MoreOutlined />
        </div>
      ),
    },
  ];

  return (
    <div className="grid-view-container">
      <Table
        rowSelection={{
          type: "checkbox",
          ...rowSelection
        }}
        columns={columns}
        dataSource={filteredListData}
        pagination={false}
      />
    </div>
  );
};
