import { Tabs } from "antd";
import ContentHeader from "components/ContentHeader";
import { DowntimeTab } from "./DowntimeTab";
import { OeeTab } from "./OeeTab";
import "./index.scss";
import "styles/tabs.scss";
import { useDispatch } from "react-redux";
import { setFilterUpdatedFlag } from "store/actions/rootAction";

const Oee: React.FC = () => {
  const items = [
    {
      label: `OEE`,
      key: "1",
      children: <OeeTab />,
    },
    {
      label: `Downtime`,
      key: "2",
      children: <DowntimeTab />,
    },
  ];
  const dispatch = useDispatch();

  return (
    <>
      <ContentHeader heading="OEE Analysis" />
      <div className="tabs-wrapper-container">
        <Tabs defaultActiveKey="1" onChange={(activeKey:any)=>dispatch(setFilterUpdatedFlag())}>
          {items.map((item) => {
            return (
              <>
                <Tabs.TabPane tab={item.label} key={item.key}>
                  {item.children}
                </Tabs.TabPane>
              </>
            );
          })}
        </Tabs>
      </div>
    </>
  );
};

export default Oee;
