/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: service/ReasonCodeDashboardService.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_Commons_pb = require('../common/Commons_pb.js')

var enums_ReasonCodeEnum_pb = require('../enums/ReasonCodeEnum_pb.js')

var model_ReasonCodeModel_pb = require('../model/ReasonCodeModel_pb.js')

var model_ReasonCodeOperatorModel_pb = require('../model/ReasonCodeOperatorModel_pb.js')
const proto = {};
proto.proto = require('./ReasonCodeDashboardService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ReasonCodeDashboardServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ReasonCodeDashboardServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.LineOverviewRequest,
 *   !proto.proto.DowntimeTrendResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getDowntimeTrend = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getDowntimeTrend',
  grpc.web.MethodType.UNARY,
  proto.proto.LineOverviewRequest,
  proto.proto.DowntimeTrendResponse,
  /**
   * @param {!proto.proto.LineOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.DowntimeTrendResponse.deserializeBinary
);


/**
 * @param {!proto.proto.LineOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.DowntimeTrendResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.DowntimeTrendResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getDowntimeTrend =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getDowntimeTrend',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getDowntimeTrend,
      callback);
};


/**
 * @param {!proto.proto.LineOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.DowntimeTrendResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getDowntimeTrend =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getDowntimeTrend',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getDowntimeTrend);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.LineOverviewRequest,
 *   !proto.proto.OEEParamResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getOEEParams = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getOEEParams',
  grpc.web.MethodType.UNARY,
  proto.proto.LineOverviewRequest,
  proto.proto.OEEParamResponse,
  /**
   * @param {!proto.proto.LineOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.OEEParamResponse.deserializeBinary
);


/**
 * @param {!proto.proto.LineOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.OEEParamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.OEEParamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getOEEParams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOEEParams',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOEEParams,
      callback);
};


/**
 * @param {!proto.proto.LineOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.OEEParamResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getOEEParams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOEEParams',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOEEParams);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.LineOverviewRequest,
 *   !proto.proto.LostTimeMatrixResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getLostTimeMatrix = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getLostTimeMatrix',
  grpc.web.MethodType.UNARY,
  proto.proto.LineOverviewRequest,
  proto.proto.LostTimeMatrixResponse,
  /**
   * @param {!proto.proto.LineOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.LostTimeMatrixResponse.deserializeBinary
);


/**
 * @param {!proto.proto.LineOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.LostTimeMatrixResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.LostTimeMatrixResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getLostTimeMatrix =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getLostTimeMatrix',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getLostTimeMatrix,
      callback);
};


/**
 * @param {!proto.proto.LineOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.LostTimeMatrixResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getLostTimeMatrix =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getLostTimeMatrix',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getLostTimeMatrix);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.LineOverviewRequest,
 *   !proto.proto.TeepMatrixResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getTeepMatrix = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getTeepMatrix',
  grpc.web.MethodType.UNARY,
  proto.proto.LineOverviewRequest,
  proto.proto.TeepMatrixResponse,
  /**
   * @param {!proto.proto.LineOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.TeepMatrixResponse.deserializeBinary
);


/**
 * @param {!proto.proto.LineOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.TeepMatrixResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.TeepMatrixResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getTeepMatrix =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getTeepMatrix',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getTeepMatrix,
      callback);
};


/**
 * @param {!proto.proto.LineOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.TeepMatrixResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getTeepMatrix =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getTeepMatrix',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getTeepMatrix);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.LineOverviewRequest,
 *   !proto.proto.RuntimeDowntimeResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getRuntimeDowntime = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getRuntimeDowntime',
  grpc.web.MethodType.UNARY,
  proto.proto.LineOverviewRequest,
  proto.proto.RuntimeDowntimeResponse,
  /**
   * @param {!proto.proto.LineOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.RuntimeDowntimeResponse.deserializeBinary
);


/**
 * @param {!proto.proto.LineOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.RuntimeDowntimeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.RuntimeDowntimeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getRuntimeDowntime =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getRuntimeDowntime',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getRuntimeDowntime,
      callback);
};


/**
 * @param {!proto.proto.LineOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.RuntimeDowntimeResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getRuntimeDowntime =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getRuntimeDowntime',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getRuntimeDowntime);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.LineOverviewRequest,
 *   !proto.proto.RateMatrixResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getRateMatrix = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getRateMatrix',
  grpc.web.MethodType.UNARY,
  proto.proto.LineOverviewRequest,
  proto.proto.RateMatrixResponse,
  /**
   * @param {!proto.proto.LineOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.RateMatrixResponse.deserializeBinary
);


/**
 * @param {!proto.proto.LineOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.RateMatrixResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.RateMatrixResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getRateMatrix =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getRateMatrix',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getRateMatrix,
      callback);
};


/**
 * @param {!proto.proto.LineOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.RateMatrixResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getRateMatrix =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getRateMatrix',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getRateMatrix);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RuntimeOverviewRequest,
 *   !proto.proto.RuntimeValuesResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getRuntimeValues = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getRuntimeValues',
  grpc.web.MethodType.UNARY,
  proto.proto.RuntimeOverviewRequest,
  proto.proto.RuntimeValuesResponse,
  /**
   * @param {!proto.proto.RuntimeOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.RuntimeValuesResponse.deserializeBinary
);


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.RuntimeValuesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.RuntimeValuesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getRuntimeValues =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getRuntimeValues',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getRuntimeValues,
      callback);
};


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.RuntimeValuesResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getRuntimeValues =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getRuntimeValues',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getRuntimeValues);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RuntimeOverviewRequest,
 *   !proto.proto.DowntimeValuesResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getDowntimeValues = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getDowntimeValues',
  grpc.web.MethodType.UNARY,
  proto.proto.RuntimeOverviewRequest,
  proto.proto.DowntimeValuesResponse,
  /**
   * @param {!proto.proto.RuntimeOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.DowntimeValuesResponse.deserializeBinary
);


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.DowntimeValuesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.DowntimeValuesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getDowntimeValues =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getDowntimeValues',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getDowntimeValues,
      callback);
};


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.DowntimeValuesResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getDowntimeValues =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getDowntimeValues',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getDowntimeValues);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RuntimeOverviewRequest,
 *   !proto.proto.PlannedStoptimealuesResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getPlannedStoptimeValues = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getPlannedStoptimeValues',
  grpc.web.MethodType.UNARY,
  proto.proto.RuntimeOverviewRequest,
  proto.proto.PlannedStoptimealuesResponse,
  /**
   * @param {!proto.proto.RuntimeOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.PlannedStoptimealuesResponse.deserializeBinary
);


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.PlannedStoptimealuesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.PlannedStoptimealuesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getPlannedStoptimeValues =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getPlannedStoptimeValues',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getPlannedStoptimeValues,
      callback);
};


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.PlannedStoptimealuesResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getPlannedStoptimeValues =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getPlannedStoptimeValues',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getPlannedStoptimeValues);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RuntimeOverviewRequest,
 *   !proto.proto.AssetUtilizationResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getAssetUtilization = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getAssetUtilization',
  grpc.web.MethodType.UNARY,
  proto.proto.RuntimeOverviewRequest,
  proto.proto.AssetUtilizationResponse,
  /**
   * @param {!proto.proto.RuntimeOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.AssetUtilizationResponse.deserializeBinary
);


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.AssetUtilizationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.AssetUtilizationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getAssetUtilization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getAssetUtilization',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getAssetUtilization,
      callback);
};


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.AssetUtilizationResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getAssetUtilization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getAssetUtilization',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getAssetUtilization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RuntimeOverviewRequest,
 *   !proto.proto.MachineRuntimeStatusResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getMachineRuntimeStatus = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getMachineRuntimeStatus',
  grpc.web.MethodType.UNARY,
  proto.proto.RuntimeOverviewRequest,
  proto.proto.MachineRuntimeStatusResponse,
  /**
   * @param {!proto.proto.RuntimeOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.MachineRuntimeStatusResponse.deserializeBinary
);


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.MachineRuntimeStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.MachineRuntimeStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getMachineRuntimeStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getMachineRuntimeStatus',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getMachineRuntimeStatus,
      callback);
};


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.MachineRuntimeStatusResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getMachineRuntimeStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getMachineRuntimeStatus',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getMachineRuntimeStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RuntimeOverviewRequest,
 *   !proto.proto.MttrResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getMttrParams = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getMttrParams',
  grpc.web.MethodType.UNARY,
  proto.proto.RuntimeOverviewRequest,
  proto.proto.MttrResponse,
  /**
   * @param {!proto.proto.RuntimeOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.MttrResponse.deserializeBinary
);


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.MttrResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.MttrResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getMttrParams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getMttrParams',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getMttrParams,
      callback);
};


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.MttrResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getMttrParams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getMttrParams',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getMttrParams);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RuntimeOverviewRequest,
 *   !proto.proto.MtbfResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getMtbfParams = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getMtbfParams',
  grpc.web.MethodType.UNARY,
  proto.proto.RuntimeOverviewRequest,
  proto.proto.MtbfResponse,
  /**
   * @param {!proto.proto.RuntimeOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.MtbfResponse.deserializeBinary
);


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.MtbfResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.MtbfResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getMtbfParams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getMtbfParams',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getMtbfParams,
      callback);
};


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.MtbfResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getMtbfParams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getMtbfParams',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getMtbfParams);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RuntimeOverviewRequest,
 *   !proto.proto.RuntimeAnalysisDetailsResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getRuntimeAnalysisDetails = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getRuntimeAnalysisDetails',
  grpc.web.MethodType.UNARY,
  proto.proto.RuntimeOverviewRequest,
  proto.proto.RuntimeAnalysisDetailsResponse,
  /**
   * @param {!proto.proto.RuntimeOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.RuntimeAnalysisDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.RuntimeAnalysisDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.RuntimeAnalysisDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getRuntimeAnalysisDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getRuntimeAnalysisDetails',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getRuntimeAnalysisDetails,
      callback);
};


/**
 * @param {!proto.proto.RuntimeOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.RuntimeAnalysisDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getRuntimeAnalysisDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getRuntimeAnalysisDetails',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getRuntimeAnalysisDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ProductionOverviewRequest,
 *   !proto.proto.AvgCycleTimeResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getAvgCycleTimeDetails = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getAvgCycleTimeDetails',
  grpc.web.MethodType.UNARY,
  proto.proto.ProductionOverviewRequest,
  proto.proto.AvgCycleTimeResponse,
  /**
   * @param {!proto.proto.ProductionOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.AvgCycleTimeResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ProductionOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.AvgCycleTimeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.AvgCycleTimeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getAvgCycleTimeDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getAvgCycleTimeDetails',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getAvgCycleTimeDetails,
      callback);
};


/**
 * @param {!proto.proto.ProductionOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.AvgCycleTimeResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getAvgCycleTimeDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getAvgCycleTimeDetails',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getAvgCycleTimeDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.TotalCountRequest,
 *   !proto.proto.TotalCountResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getTotalCountByMachine = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getTotalCountByMachine',
  grpc.web.MethodType.UNARY,
  proto.proto.TotalCountRequest,
  proto.proto.TotalCountResponse,
  /**
   * @param {!proto.proto.TotalCountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.TotalCountResponse.deserializeBinary
);


/**
 * @param {!proto.proto.TotalCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.TotalCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.TotalCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getTotalCountByMachine =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getTotalCountByMachine',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getTotalCountByMachine,
      callback);
};


/**
 * @param {!proto.proto.TotalCountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.TotalCountResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getTotalCountByMachine =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getTotalCountByMachine',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getTotalCountByMachine);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ProductionOverviewRequest,
 *   !proto.proto.OverallAssetUtilization>}
 */
const methodDescriptor_ReasonCodeDashboardService_getOverallAssetUtilization = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getOverallAssetUtilization',
  grpc.web.MethodType.UNARY,
  proto.proto.ProductionOverviewRequest,
  proto.proto.OverallAssetUtilization,
  /**
   * @param {!proto.proto.ProductionOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.OverallAssetUtilization.deserializeBinary
);


/**
 * @param {!proto.proto.ProductionOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.OverallAssetUtilization)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.OverallAssetUtilization>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getOverallAssetUtilization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOverallAssetUtilization',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOverallAssetUtilization,
      callback);
};


/**
 * @param {!proto.proto.ProductionOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.OverallAssetUtilization>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getOverallAssetUtilization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOverallAssetUtilization',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOverallAssetUtilization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.OEEAnalysisRequest,
 *   !proto.proto.OEEAvailResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getOEEAvailabilityDetails = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getOEEAvailabilityDetails',
  grpc.web.MethodType.UNARY,
  proto.proto.OEEAnalysisRequest,
  proto.proto.OEEAvailResponse,
  /**
   * @param {!proto.proto.OEEAnalysisRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.OEEAvailResponse.deserializeBinary
);


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.OEEAvailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.OEEAvailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getOEEAvailabilityDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOEEAvailabilityDetails',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOEEAvailabilityDetails,
      callback);
};


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.OEEAvailResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getOEEAvailabilityDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOEEAvailabilityDetails',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOEEAvailabilityDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.OEEAnalysisRequest,
 *   !proto.proto.OEEQualityResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getOEEQualityDetails = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getOEEQualityDetails',
  grpc.web.MethodType.UNARY,
  proto.proto.OEEAnalysisRequest,
  proto.proto.OEEQualityResponse,
  /**
   * @param {!proto.proto.OEEAnalysisRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.OEEQualityResponse.deserializeBinary
);


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.OEEQualityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.OEEQualityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getOEEQualityDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOEEQualityDetails',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOEEQualityDetails,
      callback);
};


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.OEEQualityResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getOEEQualityDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOEEQualityDetails',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOEEQualityDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.OEEAnalysisRequest,
 *   !proto.proto.OEEMachineWiseResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getOEEMachineWise = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getOEEMachineWise',
  grpc.web.MethodType.UNARY,
  proto.proto.OEEAnalysisRequest,
  proto.proto.OEEMachineWiseResponse,
  /**
   * @param {!proto.proto.OEEAnalysisRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.OEEMachineWiseResponse.deserializeBinary
);


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.OEEMachineWiseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.OEEMachineWiseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getOEEMachineWise =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOEEMachineWise',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOEEMachineWise,
      callback);
};


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.OEEMachineWiseResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getOEEMachineWise =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOEEMachineWise',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOEEMachineWise);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.OEEAnalysisRequest,
 *   !proto.proto.OEEPerfResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getOEEPerfDetails = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getOEEPerfDetails',
  grpc.web.MethodType.UNARY,
  proto.proto.OEEAnalysisRequest,
  proto.proto.OEEPerfResponse,
  /**
   * @param {!proto.proto.OEEAnalysisRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.OEEPerfResponse.deserializeBinary
);


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.OEEPerfResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.OEEPerfResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getOEEPerfDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOEEPerfDetails',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOEEPerfDetails,
      callback);
};


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.OEEPerfResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getOEEPerfDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOEEPerfDetails',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOEEPerfDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.OEEAnalysisRequest,
 *   !proto.proto.OEEByLineResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getOEEByLine = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getOEEByLine',
  grpc.web.MethodType.UNARY,
  proto.proto.OEEAnalysisRequest,
  proto.proto.OEEByLineResponse,
  /**
   * @param {!proto.proto.OEEAnalysisRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.OEEByLineResponse.deserializeBinary
);


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.OEEByLineResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.OEEByLineResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getOEEByLine =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOEEByLine',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOEEByLine,
      callback);
};


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.OEEByLineResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getOEEByLine =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOEEByLine',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOEEByLine);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.OEEAnalysisRequest,
 *   !proto.proto.PercentageLossResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getPercentageLossAnalysis = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getPercentageLossAnalysis',
  grpc.web.MethodType.UNARY,
  proto.proto.OEEAnalysisRequest,
  proto.proto.PercentageLossResponse,
  /**
   * @param {!proto.proto.OEEAnalysisRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.PercentageLossResponse.deserializeBinary
);


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.PercentageLossResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.PercentageLossResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getPercentageLossAnalysis =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getPercentageLossAnalysis',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getPercentageLossAnalysis,
      callback);
};


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.PercentageLossResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getPercentageLossAnalysis =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getPercentageLossAnalysis',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getPercentageLossAnalysis);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.OEEAnalysisRequest,
 *   !proto.proto.LossTimeAnalysisResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getLossTimeAnalysis = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getLossTimeAnalysis',
  grpc.web.MethodType.UNARY,
  proto.proto.OEEAnalysisRequest,
  proto.proto.LossTimeAnalysisResponse,
  /**
   * @param {!proto.proto.OEEAnalysisRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.LossTimeAnalysisResponse.deserializeBinary
);


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.LossTimeAnalysisResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.LossTimeAnalysisResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getLossTimeAnalysis =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getLossTimeAnalysis',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getLossTimeAnalysis,
      callback);
};


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.LossTimeAnalysisResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getLossTimeAnalysis =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getLossTimeAnalysis',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getLossTimeAnalysis);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.OEEAnalysisRequest,
 *   !proto.proto.OEEBySkuResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getOEEBySku = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getOEEBySku',
  grpc.web.MethodType.UNARY,
  proto.proto.OEEAnalysisRequest,
  proto.proto.OEEBySkuResponse,
  /**
   * @param {!proto.proto.OEEAnalysisRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.OEEBySkuResponse.deserializeBinary
);


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.OEEBySkuResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.OEEBySkuResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getOEEBySku =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOEEBySku',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOEEBySku,
      callback);
};


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.OEEBySkuResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getOEEBySku =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getOEEBySku',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getOEEBySku);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.OEEAnalysisRequest,
 *   !proto.proto.ProductionRateResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getProductionRate = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getProductionRate',
  grpc.web.MethodType.UNARY,
  proto.proto.OEEAnalysisRequest,
  proto.proto.ProductionRateResponse,
  /**
   * @param {!proto.proto.OEEAnalysisRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ProductionRateResponse.deserializeBinary
);


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ProductionRateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ProductionRateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getProductionRate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getProductionRate',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getProductionRate,
      callback);
};


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ProductionRateResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getProductionRate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getProductionRate',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getProductionRate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.OEEAnalysisRequest,
 *   !proto.proto.ProductionQualityResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getProductionQuality = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getProductionQuality',
  grpc.web.MethodType.UNARY,
  proto.proto.OEEAnalysisRequest,
  proto.proto.ProductionQualityResponse,
  /**
   * @param {!proto.proto.OEEAnalysisRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ProductionQualityResponse.deserializeBinary
);


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ProductionQualityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ProductionQualityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getProductionQuality =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getProductionQuality',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getProductionQuality,
      callback);
};


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ProductionQualityResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getProductionQuality =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getProductionQuality',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getProductionQuality);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.OEEAnalysisRequest,
 *   !proto.proto.ProductiveTimeResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getProductiveTime = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getProductiveTime',
  grpc.web.MethodType.UNARY,
  proto.proto.OEEAnalysisRequest,
  proto.proto.ProductiveTimeResponse,
  /**
   * @param {!proto.proto.OEEAnalysisRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ProductiveTimeResponse.deserializeBinary
);


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ProductiveTimeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ProductiveTimeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getProductiveTime =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getProductiveTime',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getProductiveTime,
      callback);
};


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ProductiveTimeResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getProductiveTime =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getProductiveTime',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getProductiveTime);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.OEEAnalysisRequest,
 *   !proto.proto.DowntimeDetailsResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getDownTimeDetails = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getDownTimeDetails',
  grpc.web.MethodType.UNARY,
  proto.proto.OEEAnalysisRequest,
  proto.proto.DowntimeDetailsResponse,
  /**
   * @param {!proto.proto.OEEAnalysisRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.DowntimeDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.DowntimeDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.DowntimeDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getDownTimeDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getDownTimeDetails',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getDownTimeDetails,
      callback);
};


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.DowntimeDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getDownTimeDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getDownTimeDetails',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getDownTimeDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.OEEAnalysisRequest,
 *   !proto.proto.MachineReasonDurationCountResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getMachineReasonCodeDurationCount = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getMachineReasonCodeDurationCount',
  grpc.web.MethodType.UNARY,
  proto.proto.OEEAnalysisRequest,
  proto.proto.MachineReasonDurationCountResponse,
  /**
   * @param {!proto.proto.OEEAnalysisRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.MachineReasonDurationCountResponse.deserializeBinary
);


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.MachineReasonDurationCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.MachineReasonDurationCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getMachineReasonCodeDurationCount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getMachineReasonCodeDurationCount',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getMachineReasonCodeDurationCount,
      callback);
};


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.MachineReasonDurationCountResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getMachineReasonCodeDurationCount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getMachineReasonCodeDurationCount',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getMachineReasonCodeDurationCount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.OEEAnalysisRequest,
 *   !proto.proto.DowntimeReasonCodeDetailsResponse>}
 */
const methodDescriptor_ReasonCodeDashboardService_getDownTimeWithReasonCode = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeDashboardService/getDownTimeWithReasonCode',
  grpc.web.MethodType.UNARY,
  proto.proto.OEEAnalysisRequest,
  proto.proto.DowntimeReasonCodeDetailsResponse,
  /**
   * @param {!proto.proto.OEEAnalysisRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.DowntimeReasonCodeDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.DowntimeReasonCodeDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.DowntimeReasonCodeDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeDashboardServiceClient.prototype.getDownTimeWithReasonCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getDownTimeWithReasonCode',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getDownTimeWithReasonCode,
      callback);
};


/**
 * @param {!proto.proto.OEEAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.DowntimeReasonCodeDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeDashboardServicePromiseClient.prototype.getDownTimeWithReasonCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeDashboardService/getDownTimeWithReasonCode',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeDashboardService_getDownTimeWithReasonCode);
};


module.exports = proto.proto;

