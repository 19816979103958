import { Card } from "antd";
import "./Card.scss";
import React from "react";

export const ReasonFactorCard: React.FC<any> = ({
    data,
    content,
    onClick,
    icon,
    className,
    itemStatus,
    categoryUuid,
    reasonCodeAllStatus

}) => {

    const statusColor = (status: any) => {
        let statusClass = "";
        switch (status) {
            case "Active":
                statusClass = "Active";
                break;
            case "Inactive":
                statusClass = "Inactive";
                break;
            default:
                statusClass = "item";
                break;
        }
        return statusClass;
    };

    return (
        <>
            <Card
                size="small"
                title={data.reasonCodeFactor}
                extra={icon}
                style={{ width: "100%" }}
                className={className}
                onClick={onClick}
            >
                {content?.map((item: any) => {
                    return (
                        <div className="card-container">
                            <div className="title">{item.title}</div>
                            <div className="value">
                                <span className={statusColor(item.value)}>{item.value}</span>
                            </div>
                        </div>
                    );
                })}
            </Card>
        </>
    );
};
