import { Modal, Tree } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import './index.scss';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReasonCodeCategoriesData } from "store/actions/reasonCodeManagement";



export const AssignedModal = (props: any) => {
    const { visible, open, onOk, onCancel, setReasonCodeCategoryID } = props;

    const [expandedKeys, setExpandedKeys] = useState<any>();
    const [checkedKeys, setCheckedKeys] = useState<any>();
    const [selectedKeys, setSelectedKeys] = useState<any>();
    const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

    const viewType = "expandedlist";

    const reasonCodeDetailData = useSelector((state: any) => state.reasonCode.reasonCodeDataExpandedList);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getReasonCodeCategoriesData({ viewType }));
    }, []);

    const handleCreate = () => {
        onOk();
    }

    const onSelect = (selectedKeysValue: any, info: any) => {
        setSelectedKeys(selectedKeysValue);
    };
    const onExpand = (expandedKeysValue: any) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onCheck = (checkedKeysValue: any, info: any) => {
        setCheckedKeys(checkedKeysValue);
        setReasonCodeCategoryID(checkedKeysValue[0])
    };

    const treeDataFormater = () => {
        let treeData = [] as any;
        reasonCodeDetailData?.forEach((element: any) => {
            let factorData: any = element.children.map((factorItem: any) => ({
                title: factorItem.reasonCodeFactor,
                key: factorItem.key,
                children: factorItem.children.map((codeItem: any) => ({
                    title: codeItem.reasonCode,
                    key: codeItem.key,
                }))
            }))
            treeData.push({
                title: element.reasonCodeCategory,
                key: element.key,
                children: factorData
            });
        })
        return treeData;
    }

    return (
        <Modal
            visible={visible}
            centered
            open={open}
            onOk={handleCreate}
            onCancel={onCancel}
            width={336}
            closeIcon={<CloseCircleFilled />}
            wrapClassName="create-reason-code-category"
            okText="Assign"
        >
            <div className="title">Assign Reason Codes</div>
            <div className="assign-modal">
                <Tree
                    checkable
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}
                    onSelect={onSelect}
                    selectedKeys={selectedKeys}
                    treeData={treeDataFormater()}
                />
            </div>
        </Modal>
    )
}

