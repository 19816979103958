import moment from "moment";

export enum RangeFilters {
  SHIFT_A = "Shift A",
  SHIFT_B = "Shift B",
  SHIFT_C = "Shift C",
}
export enum DateFilters{
  This_Week= "This Week",
  Date_Format="DD-MM-YYYY HH:mm:ss",
}