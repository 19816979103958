// source: model/AlarmOverview.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_Common_pb = require('../common/Common_pb.js');
goog.object.extend(proto, common_Common_pb);
var common_Enum_pb = require('../common/Enum_pb.js');
goog.object.extend(proto, common_Enum_pb);
var model_AlarmDetails_pb = require('../model/AlarmDetails_pb.js');
goog.object.extend(proto, model_AlarmDetails_pb);
goog.exportSymbol('proto.upl_sandbach.AlarmAndErrorParetoFilterRes', null, global);
goog.exportSymbol('proto.upl_sandbach.AlarmAndErrorParetoTags', null, global);
goog.exportSymbol('proto.upl_sandbach.AlarmAndErrorSPCFilterRes', null, global);
goog.exportSymbol('proto.upl_sandbach.AlarmAndErrorStackedPieChart', null, global);
goog.exportSymbol('proto.upl_sandbach.AlarmAndErrorSummaryPieChart', null, global);
goog.exportSymbol('proto.upl_sandbach.AlarmAndEventDashboard', null, global);
goog.exportSymbol('proto.upl_sandbach.AlarmDashboardFilter', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.AlarmAndErrorSummaryPieChart.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.AlarmAndErrorSummaryPieChart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AlarmAndErrorSummaryPieChart.displayName = 'proto.upl_sandbach.AlarmAndErrorSummaryPieChart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AlarmAndErrorParetoTags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.AlarmAndErrorParetoTags.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.AlarmAndErrorParetoTags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AlarmAndErrorParetoTags.displayName = 'proto.upl_sandbach.AlarmAndErrorParetoTags';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.AlarmAndErrorStackedPieChart.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.AlarmAndErrorStackedPieChart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AlarmAndErrorStackedPieChart.displayName = 'proto.upl_sandbach.AlarmAndErrorStackedPieChart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AlarmAndEventDashboard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.AlarmAndEventDashboard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AlarmAndEventDashboard.displayName = 'proto.upl_sandbach.AlarmAndEventDashboard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AlarmAndErrorParetoFilterRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.AlarmAndErrorParetoFilterRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AlarmAndErrorParetoFilterRes.displayName = 'proto.upl_sandbach.AlarmAndErrorParetoFilterRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AlarmAndErrorSPCFilterRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.AlarmAndErrorSPCFilterRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AlarmAndErrorSPCFilterRes.displayName = 'proto.upl_sandbach.AlarmAndErrorSPCFilterRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.AlarmDashboardFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.AlarmDashboardFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.AlarmDashboardFilter.displayName = 'proto.upl_sandbach.AlarmDashboardFilter';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.repeatedFields_ = [10,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AlarmAndErrorSummaryPieChart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AlarmAndErrorSummaryPieChart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.toObject = function(includeInstance, msg) {
  var f, obj = {
    pcByCountList: jspb.Message.toObjectList(msg.getPcByCountList(),
    model_AlarmDetails_pb.AlarmPieChart.toObject, includeInstance),
    pcByCountTotal: jspb.Message.getFieldWithDefault(msg, 11, 0),
    pcByDurationList: jspb.Message.toObjectList(msg.getPcByDurationList(),
    model_AlarmDetails_pb.AlarmPieChart.toObject, includeInstance),
    pcByDurationTotal: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AlarmAndErrorSummaryPieChart}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AlarmAndErrorSummaryPieChart;
  return proto.upl_sandbach.AlarmAndErrorSummaryPieChart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AlarmAndErrorSummaryPieChart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AlarmAndErrorSummaryPieChart}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_AlarmDetails_pb.AlarmPieChart;
      reader.readMessage(value,model_AlarmDetails_pb.AlarmPieChart.deserializeBinaryFromReader);
      msg.addPcByCount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPcByCountTotal(value);
      break;
    case 12:
      var value = new model_AlarmDetails_pb.AlarmPieChart;
      reader.readMessage(value,model_AlarmDetails_pb.AlarmPieChart.deserializeBinaryFromReader);
      msg.addPcByDuration(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPcByDurationTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AlarmAndErrorSummaryPieChart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AlarmAndErrorSummaryPieChart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPcByCountList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      model_AlarmDetails_pb.AlarmPieChart.serializeBinaryToWriter
    );
  }
  f = message.getPcByCountTotal();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getPcByDurationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      model_AlarmDetails_pb.AlarmPieChart.serializeBinaryToWriter
    );
  }
  f = message.getPcByDurationTotal();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
};


/**
 * repeated AlarmPieChart pc_by_count = 10;
 * @return {!Array<!proto.upl_sandbach.AlarmPieChart>}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.prototype.getPcByCountList = function() {
  return /** @type{!Array<!proto.upl_sandbach.AlarmPieChart>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_AlarmDetails_pb.AlarmPieChart, 10));
};


/**
 * @param {!Array<!proto.upl_sandbach.AlarmPieChart>} value
 * @return {!proto.upl_sandbach.AlarmAndErrorSummaryPieChart} returns this
*/
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.prototype.setPcByCountList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.upl_sandbach.AlarmPieChart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.AlarmPieChart}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.prototype.addPcByCount = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.upl_sandbach.AlarmPieChart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.AlarmAndErrorSummaryPieChart} returns this
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.prototype.clearPcByCountList = function() {
  return this.setPcByCountList([]);
};


/**
 * optional int64 pc_by_count_total = 11;
 * @return {number}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.prototype.getPcByCountTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.AlarmAndErrorSummaryPieChart} returns this
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.prototype.setPcByCountTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated AlarmPieChart pc_by_duration = 12;
 * @return {!Array<!proto.upl_sandbach.AlarmPieChart>}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.prototype.getPcByDurationList = function() {
  return /** @type{!Array<!proto.upl_sandbach.AlarmPieChart>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_AlarmDetails_pb.AlarmPieChart, 12));
};


/**
 * @param {!Array<!proto.upl_sandbach.AlarmPieChart>} value
 * @return {!proto.upl_sandbach.AlarmAndErrorSummaryPieChart} returns this
*/
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.prototype.setPcByDurationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.upl_sandbach.AlarmPieChart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.AlarmPieChart}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.prototype.addPcByDuration = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.upl_sandbach.AlarmPieChart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.AlarmAndErrorSummaryPieChart} returns this
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.prototype.clearPcByDurationList = function() {
  return this.setPcByDurationList([]);
};


/**
 * optional int64 pc_by_duration_total = 13;
 * @return {number}
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.prototype.getPcByDurationTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.AlarmAndErrorSummaryPieChart} returns this
 */
proto.upl_sandbach.AlarmAndErrorSummaryPieChart.prototype.setPcByDurationTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.repeatedFields_ = [10,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AlarmAndErrorParetoTags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AlarmAndErrorParetoTags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.toObject = function(includeInstance, msg) {
  var f, obj = {
    pdCountByTagsList: jspb.Message.toObjectList(msg.getPdCountByTagsList(),
    common_Common_pb.ParetoData.toObject, includeInstance),
    pdCountByTagsTotal: jspb.Message.getFieldWithDefault(msg, 11, 0),
    pdDurationByTagsList: jspb.Message.toObjectList(msg.getPdDurationByTagsList(),
    common_Common_pb.ParetoData.toObject, includeInstance),
    pdDurationByTagsTotal: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AlarmAndErrorParetoTags}
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AlarmAndErrorParetoTags;
  return proto.upl_sandbach.AlarmAndErrorParetoTags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AlarmAndErrorParetoTags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AlarmAndErrorParetoTags}
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new common_Common_pb.ParetoData;
      reader.readMessage(value,common_Common_pb.ParetoData.deserializeBinaryFromReader);
      msg.addPdCountByTags(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPdCountByTagsTotal(value);
      break;
    case 15:
      var value = new common_Common_pb.ParetoData;
      reader.readMessage(value,common_Common_pb.ParetoData.deserializeBinaryFromReader);
      msg.addPdDurationByTags(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPdDurationByTagsTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AlarmAndErrorParetoTags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AlarmAndErrorParetoTags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPdCountByTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      common_Common_pb.ParetoData.serializeBinaryToWriter
    );
  }
  f = message.getPdCountByTagsTotal();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getPdDurationByTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      common_Common_pb.ParetoData.serializeBinaryToWriter
    );
  }
  f = message.getPdDurationByTagsTotal();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
};


/**
 * repeated ParetoData pd_count_by_tags = 10;
 * @return {!Array<!proto.upl_sandbach.ParetoData>}
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.prototype.getPdCountByTagsList = function() {
  return /** @type{!Array<!proto.upl_sandbach.ParetoData>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_Common_pb.ParetoData, 10));
};


/**
 * @param {!Array<!proto.upl_sandbach.ParetoData>} value
 * @return {!proto.upl_sandbach.AlarmAndErrorParetoTags} returns this
*/
proto.upl_sandbach.AlarmAndErrorParetoTags.prototype.setPdCountByTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.upl_sandbach.ParetoData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.ParetoData}
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.prototype.addPdCountByTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.upl_sandbach.ParetoData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.AlarmAndErrorParetoTags} returns this
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.prototype.clearPdCountByTagsList = function() {
  return this.setPdCountByTagsList([]);
};


/**
 * optional int64 pd_count_by_tags_total = 11;
 * @return {number}
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.prototype.getPdCountByTagsTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.AlarmAndErrorParetoTags} returns this
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.prototype.setPdCountByTagsTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated ParetoData pd_duration_by_tags = 15;
 * @return {!Array<!proto.upl_sandbach.ParetoData>}
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.prototype.getPdDurationByTagsList = function() {
  return /** @type{!Array<!proto.upl_sandbach.ParetoData>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_Common_pb.ParetoData, 15));
};


/**
 * @param {!Array<!proto.upl_sandbach.ParetoData>} value
 * @return {!proto.upl_sandbach.AlarmAndErrorParetoTags} returns this
*/
proto.upl_sandbach.AlarmAndErrorParetoTags.prototype.setPdDurationByTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.upl_sandbach.ParetoData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.ParetoData}
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.prototype.addPdDurationByTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.upl_sandbach.ParetoData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.AlarmAndErrorParetoTags} returns this
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.prototype.clearPdDurationByTagsList = function() {
  return this.setPdDurationByTagsList([]);
};


/**
 * optional int64 pd_duration_by_tags_total = 16;
 * @return {number}
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.prototype.getPdDurationByTagsTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.AlarmAndErrorParetoTags} returns this
 */
proto.upl_sandbach.AlarmAndErrorParetoTags.prototype.setPdDurationByTagsTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.repeatedFields_ = [10,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AlarmAndErrorStackedPieChart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AlarmAndErrorStackedPieChart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.toObject = function(includeInstance, msg) {
  var f, obj = {
    sbcCountByDevicesList: jspb.Message.toObjectList(msg.getSbcCountByDevicesList(),
    model_AlarmDetails_pb.AlarmStackedBarChart.toObject, includeInstance),
    sbcCountByDevicesTotal: jspb.Message.getFieldWithDefault(msg, 11, 0),
    sbcDurationByDevicesList: jspb.Message.toObjectList(msg.getSbcDurationByDevicesList(),
    model_AlarmDetails_pb.AlarmStackedBarChart.toObject, includeInstance),
    sbcDurationByDevicesTotal: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AlarmAndErrorStackedPieChart}
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AlarmAndErrorStackedPieChart;
  return proto.upl_sandbach.AlarmAndErrorStackedPieChart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AlarmAndErrorStackedPieChart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AlarmAndErrorStackedPieChart}
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new model_AlarmDetails_pb.AlarmStackedBarChart;
      reader.readMessage(value,model_AlarmDetails_pb.AlarmStackedBarChart.deserializeBinaryFromReader);
      msg.addSbcCountByDevices(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSbcCountByDevicesTotal(value);
      break;
    case 15:
      var value = new model_AlarmDetails_pb.AlarmStackedBarChart;
      reader.readMessage(value,model_AlarmDetails_pb.AlarmStackedBarChart.deserializeBinaryFromReader);
      msg.addSbcDurationByDevices(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSbcDurationByDevicesTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AlarmAndErrorStackedPieChart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AlarmAndErrorStackedPieChart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSbcCountByDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      model_AlarmDetails_pb.AlarmStackedBarChart.serializeBinaryToWriter
    );
  }
  f = message.getSbcCountByDevicesTotal();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getSbcDurationByDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      model_AlarmDetails_pb.AlarmStackedBarChart.serializeBinaryToWriter
    );
  }
  f = message.getSbcDurationByDevicesTotal();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
};


/**
 * repeated AlarmStackedBarChart sbc_count_by_devices = 10;
 * @return {!Array<!proto.upl_sandbach.AlarmStackedBarChart>}
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.prototype.getSbcCountByDevicesList = function() {
  return /** @type{!Array<!proto.upl_sandbach.AlarmStackedBarChart>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_AlarmDetails_pb.AlarmStackedBarChart, 10));
};


/**
 * @param {!Array<!proto.upl_sandbach.AlarmStackedBarChart>} value
 * @return {!proto.upl_sandbach.AlarmAndErrorStackedPieChart} returns this
*/
proto.upl_sandbach.AlarmAndErrorStackedPieChart.prototype.setSbcCountByDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.upl_sandbach.AlarmStackedBarChart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.AlarmStackedBarChart}
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.prototype.addSbcCountByDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.upl_sandbach.AlarmStackedBarChart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.AlarmAndErrorStackedPieChart} returns this
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.prototype.clearSbcCountByDevicesList = function() {
  return this.setSbcCountByDevicesList([]);
};


/**
 * optional int64 sbc_count_by_devices_total = 11;
 * @return {number}
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.prototype.getSbcCountByDevicesTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.AlarmAndErrorStackedPieChart} returns this
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.prototype.setSbcCountByDevicesTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated AlarmStackedBarChart sbc_duration_by_devices = 15;
 * @return {!Array<!proto.upl_sandbach.AlarmStackedBarChart>}
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.prototype.getSbcDurationByDevicesList = function() {
  return /** @type{!Array<!proto.upl_sandbach.AlarmStackedBarChart>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_AlarmDetails_pb.AlarmStackedBarChart, 15));
};


/**
 * @param {!Array<!proto.upl_sandbach.AlarmStackedBarChart>} value
 * @return {!proto.upl_sandbach.AlarmAndErrorStackedPieChart} returns this
*/
proto.upl_sandbach.AlarmAndErrorStackedPieChart.prototype.setSbcDurationByDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.upl_sandbach.AlarmStackedBarChart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.AlarmStackedBarChart}
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.prototype.addSbcDurationByDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.upl_sandbach.AlarmStackedBarChart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.AlarmAndErrorStackedPieChart} returns this
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.prototype.clearSbcDurationByDevicesList = function() {
  return this.setSbcDurationByDevicesList([]);
};


/**
 * optional int64 sbc_duration_by_devices_total = 16;
 * @return {number}
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.prototype.getSbcDurationByDevicesTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.AlarmAndErrorStackedPieChart} returns this
 */
proto.upl_sandbach.AlarmAndErrorStackedPieChart.prototype.setSbcDurationByDevicesTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AlarmAndEventDashboard.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AlarmAndEventDashboard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AlarmAndEventDashboard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndEventDashboard.toObject = function(includeInstance, msg) {
  var f, obj = {
    aaeSummaryPieChart: (f = msg.getAaeSummaryPieChart()) && proto.upl_sandbach.AlarmAndErrorSummaryPieChart.toObject(includeInstance, f),
    aaeParetoData: (f = msg.getAaeParetoData()) && proto.upl_sandbach.AlarmAndErrorParetoTags.toObject(includeInstance, f),
    activeAlarmOverview: (f = msg.getActiveAlarmOverview()) && model_AlarmDetails_pb.ActiveAlarmOverview.toObject(includeInstance, f),
    aaeSpcData: (f = msg.getAaeSpcData()) && proto.upl_sandbach.AlarmAndErrorStackedPieChart.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AlarmAndEventDashboard}
 */
proto.upl_sandbach.AlarmAndEventDashboard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AlarmAndEventDashboard;
  return proto.upl_sandbach.AlarmAndEventDashboard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AlarmAndEventDashboard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AlarmAndEventDashboard}
 */
proto.upl_sandbach.AlarmAndEventDashboard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new proto.upl_sandbach.AlarmAndErrorSummaryPieChart;
      reader.readMessage(value,proto.upl_sandbach.AlarmAndErrorSummaryPieChart.deserializeBinaryFromReader);
      msg.setAaeSummaryPieChart(value);
      break;
    case 15:
      var value = new proto.upl_sandbach.AlarmAndErrorParetoTags;
      reader.readMessage(value,proto.upl_sandbach.AlarmAndErrorParetoTags.deserializeBinaryFromReader);
      msg.setAaeParetoData(value);
      break;
    case 20:
      var value = new model_AlarmDetails_pb.ActiveAlarmOverview;
      reader.readMessage(value,model_AlarmDetails_pb.ActiveAlarmOverview.deserializeBinaryFromReader);
      msg.setActiveAlarmOverview(value);
      break;
    case 25:
      var value = new proto.upl_sandbach.AlarmAndErrorStackedPieChart;
      reader.readMessage(value,proto.upl_sandbach.AlarmAndErrorStackedPieChart.deserializeBinaryFromReader);
      msg.setAaeSpcData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AlarmAndEventDashboard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AlarmAndEventDashboard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AlarmAndEventDashboard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndEventDashboard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAaeSummaryPieChart();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.upl_sandbach.AlarmAndErrorSummaryPieChart.serializeBinaryToWriter
    );
  }
  f = message.getAaeParetoData();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.upl_sandbach.AlarmAndErrorParetoTags.serializeBinaryToWriter
    );
  }
  f = message.getActiveAlarmOverview();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      model_AlarmDetails_pb.ActiveAlarmOverview.serializeBinaryToWriter
    );
  }
  f = message.getAaeSpcData();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.upl_sandbach.AlarmAndErrorStackedPieChart.serializeBinaryToWriter
    );
  }
};


/**
 * optional AlarmAndErrorSummaryPieChart aae_summary_pie_chart = 10;
 * @return {?proto.upl_sandbach.AlarmAndErrorSummaryPieChart}
 */
proto.upl_sandbach.AlarmAndEventDashboard.prototype.getAaeSummaryPieChart = function() {
  return /** @type{?proto.upl_sandbach.AlarmAndErrorSummaryPieChart} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.AlarmAndErrorSummaryPieChart, 10));
};


/**
 * @param {?proto.upl_sandbach.AlarmAndErrorSummaryPieChart|undefined} value
 * @return {!proto.upl_sandbach.AlarmAndEventDashboard} returns this
*/
proto.upl_sandbach.AlarmAndEventDashboard.prototype.setAaeSummaryPieChart = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmAndEventDashboard} returns this
 */
proto.upl_sandbach.AlarmAndEventDashboard.prototype.clearAaeSummaryPieChart = function() {
  return this.setAaeSummaryPieChart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmAndEventDashboard.prototype.hasAaeSummaryPieChart = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional AlarmAndErrorParetoTags aae_pareto_data = 15;
 * @return {?proto.upl_sandbach.AlarmAndErrorParetoTags}
 */
proto.upl_sandbach.AlarmAndEventDashboard.prototype.getAaeParetoData = function() {
  return /** @type{?proto.upl_sandbach.AlarmAndErrorParetoTags} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.AlarmAndErrorParetoTags, 15));
};


/**
 * @param {?proto.upl_sandbach.AlarmAndErrorParetoTags|undefined} value
 * @return {!proto.upl_sandbach.AlarmAndEventDashboard} returns this
*/
proto.upl_sandbach.AlarmAndEventDashboard.prototype.setAaeParetoData = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmAndEventDashboard} returns this
 */
proto.upl_sandbach.AlarmAndEventDashboard.prototype.clearAaeParetoData = function() {
  return this.setAaeParetoData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmAndEventDashboard.prototype.hasAaeParetoData = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional ActiveAlarmOverview active_alarm_overview = 20;
 * @return {?proto.upl_sandbach.ActiveAlarmOverview}
 */
proto.upl_sandbach.AlarmAndEventDashboard.prototype.getActiveAlarmOverview = function() {
  return /** @type{?proto.upl_sandbach.ActiveAlarmOverview} */ (
    jspb.Message.getWrapperField(this, model_AlarmDetails_pb.ActiveAlarmOverview, 20));
};


/**
 * @param {?proto.upl_sandbach.ActiveAlarmOverview|undefined} value
 * @return {!proto.upl_sandbach.AlarmAndEventDashboard} returns this
*/
proto.upl_sandbach.AlarmAndEventDashboard.prototype.setActiveAlarmOverview = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmAndEventDashboard} returns this
 */
proto.upl_sandbach.AlarmAndEventDashboard.prototype.clearActiveAlarmOverview = function() {
  return this.setActiveAlarmOverview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmAndEventDashboard.prototype.hasActiveAlarmOverview = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional AlarmAndErrorStackedPieChart aae_spc_data = 25;
 * @return {?proto.upl_sandbach.AlarmAndErrorStackedPieChart}
 */
proto.upl_sandbach.AlarmAndEventDashboard.prototype.getAaeSpcData = function() {
  return /** @type{?proto.upl_sandbach.AlarmAndErrorStackedPieChart} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.AlarmAndErrorStackedPieChart, 25));
};


/**
 * @param {?proto.upl_sandbach.AlarmAndErrorStackedPieChart|undefined} value
 * @return {!proto.upl_sandbach.AlarmAndEventDashboard} returns this
*/
proto.upl_sandbach.AlarmAndEventDashboard.prototype.setAaeSpcData = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmAndEventDashboard} returns this
 */
proto.upl_sandbach.AlarmAndEventDashboard.prototype.clearAaeSpcData = function() {
  return this.setAaeSpcData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmAndEventDashboard.prototype.hasAaeSpcData = function() {
  return jspb.Message.getField(this, 25) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AlarmAndErrorParetoFilterRes.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AlarmAndErrorParetoFilterRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AlarmAndErrorParetoFilterRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorParetoFilterRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    aaeParetoData: (f = msg.getAaeParetoData()) && proto.upl_sandbach.AlarmAndErrorParetoTags.toObject(includeInstance, f),
    generalResponse: (f = msg.getGeneralResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AlarmAndErrorParetoFilterRes}
 */
proto.upl_sandbach.AlarmAndErrorParetoFilterRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AlarmAndErrorParetoFilterRes;
  return proto.upl_sandbach.AlarmAndErrorParetoFilterRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AlarmAndErrorParetoFilterRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AlarmAndErrorParetoFilterRes}
 */
proto.upl_sandbach.AlarmAndErrorParetoFilterRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new proto.upl_sandbach.AlarmAndErrorParetoTags;
      reader.readMessage(value,proto.upl_sandbach.AlarmAndErrorParetoTags.deserializeBinaryFromReader);
      msg.setAaeParetoData(value);
      break;
    case 15:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AlarmAndErrorParetoFilterRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AlarmAndErrorParetoFilterRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AlarmAndErrorParetoFilterRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorParetoFilterRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAaeParetoData();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.upl_sandbach.AlarmAndErrorParetoTags.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional AlarmAndErrorParetoTags aae_pareto_data = 10;
 * @return {?proto.upl_sandbach.AlarmAndErrorParetoTags}
 */
proto.upl_sandbach.AlarmAndErrorParetoFilterRes.prototype.getAaeParetoData = function() {
  return /** @type{?proto.upl_sandbach.AlarmAndErrorParetoTags} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.AlarmAndErrorParetoTags, 10));
};


/**
 * @param {?proto.upl_sandbach.AlarmAndErrorParetoTags|undefined} value
 * @return {!proto.upl_sandbach.AlarmAndErrorParetoFilterRes} returns this
*/
proto.upl_sandbach.AlarmAndErrorParetoFilterRes.prototype.setAaeParetoData = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmAndErrorParetoFilterRes} returns this
 */
proto.upl_sandbach.AlarmAndErrorParetoFilterRes.prototype.clearAaeParetoData = function() {
  return this.setAaeParetoData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmAndErrorParetoFilterRes.prototype.hasAaeParetoData = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralResponse general_response = 15;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.AlarmAndErrorParetoFilterRes.prototype.getGeneralResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 15));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.AlarmAndErrorParetoFilterRes} returns this
*/
proto.upl_sandbach.AlarmAndErrorParetoFilterRes.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmAndErrorParetoFilterRes} returns this
 */
proto.upl_sandbach.AlarmAndErrorParetoFilterRes.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmAndErrorParetoFilterRes.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AlarmAndErrorSPCFilterRes.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AlarmAndErrorSPCFilterRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AlarmAndErrorSPCFilterRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorSPCFilterRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    aaeSpcData: (f = msg.getAaeSpcData()) && proto.upl_sandbach.AlarmAndErrorStackedPieChart.toObject(includeInstance, f),
    generalResponse: (f = msg.getGeneralResponse()) && common_Common_pb.GeneralResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AlarmAndErrorSPCFilterRes}
 */
proto.upl_sandbach.AlarmAndErrorSPCFilterRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AlarmAndErrorSPCFilterRes;
  return proto.upl_sandbach.AlarmAndErrorSPCFilterRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AlarmAndErrorSPCFilterRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AlarmAndErrorSPCFilterRes}
 */
proto.upl_sandbach.AlarmAndErrorSPCFilterRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new proto.upl_sandbach.AlarmAndErrorStackedPieChart;
      reader.readMessage(value,proto.upl_sandbach.AlarmAndErrorStackedPieChart.deserializeBinaryFromReader);
      msg.setAaeSpcData(value);
      break;
    case 15:
      var value = new common_Common_pb.GeneralResponse;
      reader.readMessage(value,common_Common_pb.GeneralResponse.deserializeBinaryFromReader);
      msg.setGeneralResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AlarmAndErrorSPCFilterRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AlarmAndErrorSPCFilterRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AlarmAndErrorSPCFilterRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmAndErrorSPCFilterRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAaeSpcData();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.upl_sandbach.AlarmAndErrorStackedPieChart.serializeBinaryToWriter
    );
  }
  f = message.getGeneralResponse();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      common_Common_pb.GeneralResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional AlarmAndErrorStackedPieChart aae_spc_data = 10;
 * @return {?proto.upl_sandbach.AlarmAndErrorStackedPieChart}
 */
proto.upl_sandbach.AlarmAndErrorSPCFilterRes.prototype.getAaeSpcData = function() {
  return /** @type{?proto.upl_sandbach.AlarmAndErrorStackedPieChart} */ (
    jspb.Message.getWrapperField(this, proto.upl_sandbach.AlarmAndErrorStackedPieChart, 10));
};


/**
 * @param {?proto.upl_sandbach.AlarmAndErrorStackedPieChart|undefined} value
 * @return {!proto.upl_sandbach.AlarmAndErrorSPCFilterRes} returns this
*/
proto.upl_sandbach.AlarmAndErrorSPCFilterRes.prototype.setAaeSpcData = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmAndErrorSPCFilterRes} returns this
 */
proto.upl_sandbach.AlarmAndErrorSPCFilterRes.prototype.clearAaeSpcData = function() {
  return this.setAaeSpcData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmAndErrorSPCFilterRes.prototype.hasAaeSpcData = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralResponse general_response = 15;
 * @return {?proto.upl_sandbach.GeneralResponse}
 */
proto.upl_sandbach.AlarmAndErrorSPCFilterRes.prototype.getGeneralResponse = function() {
  return /** @type{?proto.upl_sandbach.GeneralResponse} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.GeneralResponse, 15));
};


/**
 * @param {?proto.upl_sandbach.GeneralResponse|undefined} value
 * @return {!proto.upl_sandbach.AlarmAndErrorSPCFilterRes} returns this
*/
proto.upl_sandbach.AlarmAndErrorSPCFilterRes.prototype.setGeneralResponse = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmAndErrorSPCFilterRes} returns this
 */
proto.upl_sandbach.AlarmAndErrorSPCFilterRes.prototype.clearGeneralResponse = function() {
  return this.setGeneralResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmAndErrorSPCFilterRes.prototype.hasGeneralResponse = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.AlarmDashboardFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.AlarmDashboardFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.AlarmDashboardFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmDashboardFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && common_Common_pb.Filter.toObject(includeInstance, f),
    tagSelected: jspb.Message.getFieldWithDefault(msg, 40, ""),
    severity: jspb.Message.getFieldWithDefault(msg, 50, 0),
    type: jspb.Message.getFieldWithDefault(msg, 55, ""),
    pageSize: jspb.Message.getFieldWithDefault(msg, 60, 0),
    pageCount: jspb.Message.getFieldWithDefault(msg, 65, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.AlarmDashboardFilter}
 */
proto.upl_sandbach.AlarmDashboardFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.AlarmDashboardFilter;
  return proto.upl_sandbach.AlarmDashboardFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.AlarmDashboardFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.AlarmDashboardFilter}
 */
proto.upl_sandbach.AlarmDashboardFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new common_Common_pb.Filter;
      reader.readMessage(value,common_Common_pb.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagSelected(value);
      break;
    case 50:
      var value = /** @type {!proto.upl_sandbach.Severity} */ (reader.readEnum());
      msg.setSeverity(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 60:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 65:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.AlarmDashboardFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.AlarmDashboardFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.AlarmDashboardFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.AlarmDashboardFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_Common_pb.Filter.serializeBinaryToWriter
    );
  }
  f = message.getTagSelected();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getSeverity();
  if (f !== 0.0) {
    writer.writeEnum(
      50,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      60,
      f
    );
  }
  f = message.getPageCount();
  if (f !== 0) {
    writer.writeInt32(
      65,
      f
    );
  }
};


/**
 * optional Filter filter = 10;
 * @return {?proto.upl_sandbach.Filter}
 */
proto.upl_sandbach.AlarmDashboardFilter.prototype.getFilter = function() {
  return /** @type{?proto.upl_sandbach.Filter} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.Filter, 10));
};


/**
 * @param {?proto.upl_sandbach.Filter|undefined} value
 * @return {!proto.upl_sandbach.AlarmDashboardFilter} returns this
*/
proto.upl_sandbach.AlarmDashboardFilter.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.AlarmDashboardFilter} returns this
 */
proto.upl_sandbach.AlarmDashboardFilter.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.AlarmDashboardFilter.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string tag_selected = 40;
 * @return {string}
 */
proto.upl_sandbach.AlarmDashboardFilter.prototype.getTagSelected = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.AlarmDashboardFilter} returns this
 */
proto.upl_sandbach.AlarmDashboardFilter.prototype.setTagSelected = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional Severity severity = 50;
 * @return {!proto.upl_sandbach.Severity}
 */
proto.upl_sandbach.AlarmDashboardFilter.prototype.getSeverity = function() {
  return /** @type {!proto.upl_sandbach.Severity} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {!proto.upl_sandbach.Severity} value
 * @return {!proto.upl_sandbach.AlarmDashboardFilter} returns this
 */
proto.upl_sandbach.AlarmDashboardFilter.prototype.setSeverity = function(value) {
  return jspb.Message.setProto3EnumField(this, 50, value);
};


/**
 * optional string type = 55;
 * @return {string}
 */
proto.upl_sandbach.AlarmDashboardFilter.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.AlarmDashboardFilter} returns this
 */
proto.upl_sandbach.AlarmDashboardFilter.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional int32 page_size = 60;
 * @return {number}
 */
proto.upl_sandbach.AlarmDashboardFilter.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.AlarmDashboardFilter} returns this
 */
proto.upl_sandbach.AlarmDashboardFilter.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 60, value);
};


/**
 * optional int32 page_count = 65;
 * @return {number}
 */
proto.upl_sandbach.AlarmDashboardFilter.prototype.getPageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 65, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.AlarmDashboardFilter} returns this
 */
proto.upl_sandbach.AlarmDashboardFilter.prototype.setPageCount = function(value) {
  return jspb.Message.setProto3IntField(this, 65, value);
};


goog.object.extend(exports, proto.upl_sandbach);
