import ContentHeader from 'components/ContentHeader'
import React, { useEffect, useRef, useState } from 'react'
import NewRejectCountModal from './NewRejectCountModal'
import moment from 'moment';
import {  EditOutlined } from "@ant-design/icons";
import "./index.scss";
import { useDispatch, useSelector } from 'react-redux';
import { CustomTimePicker } from 'components/Common/CustomTimePicker/CustomTimePicker';
import { Input, Table } from 'antd';
import { CustomButton } from 'components/Common/Button';
import { ColumnsType } from 'antd/es/table';
import { getPPAllBatches } from 'store/actions/productionOverview';
import { findNestedObj } from 'utils/common';
import { DateFilters } from 'utils/enums';


const ProcessOrderSummary = () => {
    const { Search } = Input;

  const [addModalData, setAddModalData] = useState<any>({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  let startTimeRedux = useSelector(
    (state: any) => state.root.filterValues.startTime
  );
  let endTimeRedux = useSelector(
    (state: any) => state.root.filterValues.endTime
  );

  const [startTime, setStartTime] = useState(
    startTimeRedux
  );
  const [searchString, setSearchString] = useState<string>("");
  const [endTime, setEndTime] = useState(endTimeRedux);

  const [selectedValue, setSelectedValue] = useState(DateFilters.This_Week);
  
  const PPBatches = useSelector((state: any) => state.processOrderSummary?.PPAllBatches);
  const UpdateRejectCount = useSelector((state: any) => state.processOrderSummary?.rejectCountUpdate);

  const [tableData, setTableData] = useState<any>([]);
  const timer = useRef<any>(null);
  const dispatch = useDispatch();
  const modalShow = (state: any, setState: any) => {
    setState(true);
  };
  const okHandle = (state: any, setState: any) => {
    setState(false);
  };
  const cancelHandle = (state: any, setState: any) => {
    setState(false);
  };
  const onChangeSearch = (e: any) => {
    setSearchString(e.target.value);
  };
   const onSearch = (value: any) => {
    setSearchString(value);
  };
  const onFilterSearchHandler = () => {
    if (startTime && endTime) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
     
      timer.current = 
         dispatch(
         getPPAllBatches({
          startTime,
          endTime,
          searchString,
        })
        );
    }
  };
  const fetchPPBatches = () => {
   dispatch(
    getPPAllBatches({
    startTime,
    endTime,    
    searchString,
   })
   );  
  }
  const editModalChange = (processOrderValue:any,startTimeValue:any) => {
    modalShow(isEditModalOpen, setIsEditModalOpen)
 
    const modalDetails = tableData.filter(item => {
    
      return item.processOrder === processOrderValue && item.StartTime === startTimeValue;
    });
    
    setAddModalData(modalDetails)
  }
  useEffect(() => {
    dispatch(getPPAllBatches({ startTime,endTime,searchString }));
  }, [dispatch,UpdateRejectCount,startTime,endTime])
  useEffect(() => {
    setTableData(PPBatches);
  }, [PPBatches]);
  useEffect(() => {
    setTableData(PPBatches);
  }, [startTime, endTime, searchString])
  

 
  
  const columns: ColumnsType<any> = [
    {
      key: "ProcessOrder",
      title: (
        <div className="column-title">
          <div>Process Order</div>
        
        </div>
      ),
      dataIndex: "processOrder",
    },
    {
      key: "BatchNumber",
      title: (
        <div className="column-title">
          <div>Batch Number</div>
        
        </div>
      ),
      dataIndex: "BatchNumber",
    },
    {
      key: "ProductDescription",
      title: (
        <div className="column-title">
          <div>Product Description</div>
        
        </div>
      ),
      dataIndex: "productDescription",
    },
    {
      key: "Line",
      title: (
        <div className="column-title">
          <div>Line</div>
          
        </div>
      ),
      dataIndex: "line",
    },
    {
      key: "Sku",
      title: (
        <div className="column-title">
          <div>SKU</div>
          
        </div>
      ),
      dataIndex: "sku",
    },
    {
      key: "StartTime",
      title: (
        <div className="column-title">
          <div>Start time</div>
          
        </div>
      ),
      dataIndex: "StartTime",
    },
    {
      key: "EndTime",
      title: (
        <div className="column-title">
          <div>End time</div>
          
        </div>
      ),
      dataIndex: "EndTime",
    },
    {
      key: "Materials",
      title: (
        <div className="column-title">
          <div>Materials</div>
          
        </div>
      ),
      dataIndex: "Materials",
    },
    {
      key: "StartupRejects",
      title: (
        <div className="column-title">
          <div>Start-up Rejects</div>
          
        </div>
      ),
      dataIndex: "StartupRejects",
    },
    {
      key: "ProductionRejects",
      title: (
        <div className="column-title">
          <div><span>Production Rejects</span></div>
          
        </div>
      ),
      dataIndex: "ProductionRejects",
      render: (_, {  ProductionRejects,processOrder,StartTime}) => (
        <>
          <div className="status-container">
            <div className="value">
              <span>{ProductionRejects}</span>
              <div
            className='edit-icon'
                onClick={() => {
                    
                  editModalChange(processOrder,StartTime)
                }
            }
          >
            <EditOutlined />
            
          </div>
            </div>
            
          </div>
        </>
      ),
    },
  ];

  return (
    <>      
    
      <div className="tabs-wrapper-container">
          <ContentHeader
          heading="Process Order Summary"
/>
          <NewRejectCountModal
        destroyOnClose={true}
         open={isEditModalOpen}
        onOk={() => okHandle(isEditModalOpen, setIsEditModalOpen)}
        onCancel={() => cancelHandle(isEditModalOpen, setIsEditModalOpen)}
        selectedRowFilterData={addModalData}
        onFilterSearchHandler={() => onFilterSearchHandler()}
        fetchData={()=>fetchPPBatches()}
      />
       <div className="management-filter-container">
       <div className="shift-container">
          <CustomTimePicker
            startTime={startTime}
            endTime={endTime}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
          />
      </div>
      <div className="search-container">
          <Search
            placeholder="Search process order"
            onChange={onChangeSearch}
            onSearch={onSearch}
            style={{ width: "100%" }}
            bordered={false}
            value={searchString}
          />
      </div>
       <div className="amd-button">
          <CustomButton type="Apply" onClick={() => onFilterSearchHandler()} />
          
        </div>
        </div>
      <div className="grid-view-container">
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          showSorterTooltip={false}
          scroll={{ y: 650 }}
        />
      </div>
      </div>
      </>
  )
}

export default ProcessOrderSummary