/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: service/ReasonCodeOperatorService.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_Commons_pb = require('../common/Commons_pb.js')

var model_ReasonCodeOperatorModel_pb = require('../model/ReasonCodeOperatorModel_pb.js')
const proto = {};
proto.proto = require('./ReasonCodeOperatorService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ReasonCodeOperatorServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ReasonCodeOperatorServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GeneralRequest,
 *   !proto.proto.ProcessLineInfoResponse>}
 */
const methodDescriptor_ReasonCodeOperatorService_getProcessLineInfo = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeOperatorService/getProcessLineInfo',
  grpc.web.MethodType.UNARY,
  common_Commons_pb.GeneralRequest,
  proto.proto.ProcessLineInfoResponse,
  /**
   * @param {!proto.proto.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ProcessLineInfoResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ProcessLineInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ProcessLineInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeOperatorServiceClient.prototype.getProcessLineInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeOperatorService/getProcessLineInfo',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeOperatorService_getProcessLineInfo,
      callback);
};


/**
 * @param {!proto.proto.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ProcessLineInfoResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeOperatorServicePromiseClient.prototype.getProcessLineInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeOperatorService/getProcessLineInfo',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeOperatorService_getProcessLineInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.FpLineOverviewRequest,
 *   !proto.proto.FpLineOverviewResponse>}
 */
const methodDescriptor_ReasonCodeOperatorService_getFpLineWiseBatchDetailOverview = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeOperatorService/getFpLineWiseBatchDetailOverview',
  grpc.web.MethodType.UNARY,
  proto.proto.FpLineOverviewRequest,
  proto.proto.FpLineOverviewResponse,
  /**
   * @param {!proto.proto.FpLineOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.FpLineOverviewResponse.deserializeBinary
);


/**
 * @param {!proto.proto.FpLineOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.FpLineOverviewResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.FpLineOverviewResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeOperatorServiceClient.prototype.getFpLineWiseBatchDetailOverview =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeOperatorService/getFpLineWiseBatchDetailOverview',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeOperatorService_getFpLineWiseBatchDetailOverview,
      callback);
};


/**
 * @param {!proto.proto.FpLineOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.FpLineOverviewResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeOperatorServicePromiseClient.prototype.getFpLineWiseBatchDetailOverview =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeOperatorService/getFpLineWiseBatchDetailOverview',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeOperatorService_getFpLineWiseBatchDetailOverview);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.MachineDetailRequest,
 *   !proto.proto.MachineDetailResponse>}
 */
const methodDescriptor_ReasonCodeOperatorService_getMachineBasedDetails = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeOperatorService/getMachineBasedDetails',
  grpc.web.MethodType.UNARY,
  proto.proto.MachineDetailRequest,
  proto.proto.MachineDetailResponse,
  /**
   * @param {!proto.proto.MachineDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.MachineDetailResponse.deserializeBinary
);


/**
 * @param {!proto.proto.MachineDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.MachineDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.MachineDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeOperatorServiceClient.prototype.getMachineBasedDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeOperatorService/getMachineBasedDetails',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeOperatorService_getMachineBasedDetails,
      callback);
};


/**
 * @param {!proto.proto.MachineDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.MachineDetailResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeOperatorServicePromiseClient.prototype.getMachineBasedDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeOperatorService/getMachineBasedDetails',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeOperatorService_getMachineBasedDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ReasonCodeAssignRequest,
 *   !proto.proto.GeneralResponse>}
 */
const methodDescriptor_ReasonCodeOperatorService_assignReasonCodeToLog = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeOperatorService/assignReasonCodeToLog',
  grpc.web.MethodType.UNARY,
  proto.proto.ReasonCodeAssignRequest,
  common_Commons_pb.GeneralResponse,
  /**
   * @param {!proto.proto.ReasonCodeAssignRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_Commons_pb.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ReasonCodeAssignRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeOperatorServiceClient.prototype.assignReasonCodeToLog =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeOperatorService/assignReasonCodeToLog',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeOperatorService_assignReasonCodeToLog,
      callback);
};


/**
 * @param {!proto.proto.ReasonCodeAssignRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeOperatorServicePromiseClient.prototype.assignReasonCodeToLog =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeOperatorService/assignReasonCodeToLog',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeOperatorService_assignReasonCodeToLog);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.FpLogRequest,
 *   !proto.proto.FpLogResponse>}
 */
const methodDescriptor_ReasonCodeOperatorService_getFpLineBasedLogs = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeOperatorService/getFpLineBasedLogs',
  grpc.web.MethodType.UNARY,
  proto.proto.FpLogRequest,
  proto.proto.FpLogResponse,
  /**
   * @param {!proto.proto.FpLogRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.FpLogResponse.deserializeBinary
);


/**
 * @param {!proto.proto.FpLogRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.FpLogResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.FpLogResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeOperatorServiceClient.prototype.getFpLineBasedLogs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeOperatorService/getFpLineBasedLogs',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeOperatorService_getFpLineBasedLogs,
      callback);
};


/**
 * @param {!proto.proto.FpLogRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.FpLogResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeOperatorServicePromiseClient.prototype.getFpLineBasedLogs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeOperatorService/getFpLineBasedLogs',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeOperatorService_getFpLineBasedLogs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.MachinesRunningStatusRequest,
 *   !proto.proto.MachinesRunningStatusResponse>}
 */
const methodDescriptor_ReasonCodeOperatorService_getMachinesRunningStatus = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeOperatorService/getMachinesRunningStatus',
  grpc.web.MethodType.UNARY,
  proto.proto.MachinesRunningStatusRequest,
  proto.proto.MachinesRunningStatusResponse,
  /**
   * @param {!proto.proto.MachinesRunningStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.MachinesRunningStatusResponse.deserializeBinary
);


/**
 * @param {!proto.proto.MachinesRunningStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.MachinesRunningStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.MachinesRunningStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeOperatorServiceClient.prototype.getMachinesRunningStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeOperatorService/getMachinesRunningStatus',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeOperatorService_getMachinesRunningStatus,
      callback);
};


/**
 * @param {!proto.proto.MachinesRunningStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.MachinesRunningStatusResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeOperatorServicePromiseClient.prototype.getMachinesRunningStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeOperatorService/getMachinesRunningStatus',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeOperatorService_getMachinesRunningStatus);
};


module.exports = proto.proto;

