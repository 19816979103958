import "./index.scss";
import { SolidGuageAnalysisProps } from "types";

export const SolidGuageAnalysisContainer: React.FC<SolidGuageAnalysisProps> = ({
  data,
}) => {
  const bordercolor = [
    "#91D5FF",
    "#FADB14",
    "#FFE58F",
    "#FFD591",
    "#FFBB96",
    "#FFA39E",
  ];
  const categroyColor = [
    "#40A9FF",
    "#FADB14",
    "#FFC53D",
    "#FFA940",
    "#FF7A45",
    "#FF4D4F",
  ];
  const valueColor = [
    "#1890FF",
    "#FADB14",
    "#FAAD14",
    "#FA8C16",
    "#FA541C",
    "#F5222D",
  ];
  return (
    <>
      <div className="solidguage-analysis-container">
        {data.map((item: any, index: any) => {
          return (
            <>
              <div
                className="solidguage-analysis-data-container"
                key={index}
                style={{ borderColor: bordercolor[index] }}
              >
                <div
                  className="solidguage-analysis-category"
                  style={{ color: categroyColor[index] }}
                >
                  {item.category}
                </div>
                <div
                  className="solidguage-analysis-value"
                  style={{ color: valueColor[index] }}
                >
                  {item.value}
                </div>
                <div className="solidguage-analysis-percentage">
                  {item.percentage}%
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
