import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal as AntModal } from 'antd';
import React from 'react';
import './index.scss';

export const Modal = (props: any) => {
  return (
    <AntModal
      className="custom-ant-modal"
      closeIcon={<CloseCircleOutlined style={{ color: '#fff' }} />}
      centered={true}
      {...props}
    >
      {props.children}
    </AntModal>
  );
};
