import "./index.scss";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  UpCircleOutlined,
  DownCircleOutlined,
  MoreOutlined
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReasonCodeCategoriesData } from "store/actions/reasonCodeManagement";
import { reasonCodeManagementSearchFilter } from "utils/common";

interface DataType {
  key: React.ReactNode;
  reasonCodeCategory: string;
  reasonCodeFactor: string;
  reasonCode: string;
  createdOn: string;
  lastModified: string;
  status: string;
  children?: DataType[];
}

const columns: ColumnsType<DataType> = [
  {
    title: "Reason Code Category",
    dataIndex: "reasonCodeCategory",
    key: "reasonCodeCategory",
  },
  {
    title: "Reason Code Factor",
    dataIndex: "reasonCodeFactor",
    key: "reasonCodeFactor",
    render: (text, record) => {
      return (
        <div className="record">
          <div>
            {text}
            {record.children?.length && (
              <span className="count">{record.children?.length}</span>
            )}
          </div>
        </div>
      );
    },
  },
  {
    title: "Reason Code",
    dataIndex: "reasonCode",
    key: "reasonCode",
    render: (text, record) => {
      return (
        <div className="record">
          <div>
            {text}
            {record.children?.length && (
              <span className="count">{record.children?.length}</span>
            )}
          </div>
        </div>
      );
    },
  },
  {
    title: "Created On",
    dataIndex: "createdOn",
    key: "createdOn",
  },
  {
    title: "Last Modified",
    dataIndex: "lastModified",
    key: "lastModified",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, { status }) => (
      <div className="status-container">
        <div className="value">
          <span className={`${status}`}>{status}</span>
        </div>
        <MoreOutlined />
      </div>
    ),
  }
];


export const ExpandedView = (props: any) => {
  const { searchValue, setCategoryUuid, setFactorUuid, setCodeUuid } = props;

  const [filteredExpandedData, setFilteredExpandedData] = useState<any>([])
  const [payload, setPayload] = useState({ viewType: "expandedlist" })

  const viewType = "expandedlist";

  const reasonCodeDetailData = useSelector((state: any) => state.reasonCode.reasonCodeDataExpandedList);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getReasonCodeCategoriesData(payload));
  }, []);
  useEffect(() => {
    setFilteredExpandedData(reasonCodeDetailData);
  }, [reasonCodeDetailData]);
  useEffect(() => {
    reasonCodeManagementSearchFilter(reasonCodeDetailData, searchValue, setFilteredExpandedData, viewType)
  }, [searchValue]);

  const rowSelection: any = {
    onSelect: (record: any) => {
      setCategoryUuid(record.reasonCodeCategoryUuid);
      setFactorUuid(record.reasonCodeFactorUuid)
      setCodeUuid(record.reasonCodeUuid)
    },
  };

  return (
    <div className="expanded-view-container">
      <Table
        columns={columns}
        rowSelection={{
          type: "checkbox",
          checkStrictly: false,
          ...rowSelection
        }}
        dataSource={filteredExpandedData}
        pagination={false}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <>
                {record.children?.length ? (
                  < UpCircleOutlined onClick={(e) => onExpand(record, e)} />
                ) : null}
              </>
            ) : (
              <>
                {record.children?.length ? (
                  <DownCircleOutlined onClick={(e) => onExpand(record, e)} />
                ) : null}
              </>
            ),
        }}
      />
    </div >
  );
};


