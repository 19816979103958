import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './loader.scss';

export const Loader = () => {
  const showLoader = useSelector((state: any) => state.root.showLoader);

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setShow(showLoader);
  }, [showLoader]);

  return <>{show ? <Spin className="spinning"></Spin> : null}</>;
};