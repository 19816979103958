import ContentHeader from "components/ContentHeader";
import { TrendingDashboard } from "./Trending";
import { Tabs } from "antd";
import { DataVisualizationTable } from "./TableData";
import { useDispatch } from "react-redux";
import { setFilterUpdatedFlag } from "store/actions/rootAction";

export const DataVisualization: React.FC = () => {
  const items = [
    {
      label: `Trending`,
      key: "1",
      children: <TrendingDashboard />,
    },
    {
      label: `Data Table`,
      key: "2",
      children: <DataVisualizationTable />,
    },
  ];
  const dispatch = useDispatch();

  return (
    <div className="tabs-wrapper-container">
      <ContentHeader heading="Data Visualization" />
      <Tabs defaultActiveKey="1" onChange={(activeKey:any)=>dispatch(setFilterUpdatedFlag())}>
        {items.map((item, index) => {
          return (
            <Tabs.TabPane tab={item.label} key={item.key}>
              {item.children}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};
