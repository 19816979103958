/**
 * @fileoverview gRPC-Web generated client stub for upl_sandbach
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: service/ProductionOverview.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_Common_pb = require('../common/Common_pb.js')

var model_ProductionOverview_pb = require('../model/ProductionOverview_pb.js')

var model_Filter_pb = require('../model/Filter_pb.js')

var model_AlarmOverview_pb = require('../model/AlarmOverview_pb.js')
const proto = {};
proto.upl_sandbach = require('./ProductionOverview_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.upl_sandbach.ProductionOverviewServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.upl_sandbach.ProductionOverviewServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.BatchFilterRequest,
 *   !proto.upl_sandbach.BatchDataResponse>}
 */
const methodDescriptor_ProductionOverviewService_getBatchDetails = new grpc.web.MethodDescriptor(
  '/upl_sandbach.ProductionOverviewService/getBatchDetails',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.BatchFilterRequest,
  proto.upl_sandbach.BatchDataResponse,
  /**
   * @param {!proto.upl_sandbach.BatchFilterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.upl_sandbach.BatchDataResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.BatchFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.BatchDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.BatchDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.ProductionOverviewServiceClient.prototype.getBatchDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getBatchDetails',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getBatchDetails,
      callback);
};


/**
 * @param {!proto.upl_sandbach.BatchFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.BatchDataResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.ProductionOverviewServicePromiseClient.prototype.getBatchDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getBatchDetails',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getBatchDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.BatchFilterRequest,
 *   !proto.upl_sandbach.BatchCountResponse>}
 */
const methodDescriptor_ProductionOverviewService_getBatchCount = new grpc.web.MethodDescriptor(
  '/upl_sandbach.ProductionOverviewService/getBatchCount',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.BatchFilterRequest,
  proto.upl_sandbach.BatchCountResponse,
  /**
   * @param {!proto.upl_sandbach.BatchFilterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.upl_sandbach.BatchCountResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.BatchFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.BatchCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.BatchCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.ProductionOverviewServiceClient.prototype.getBatchCount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getBatchCount',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getBatchCount,
      callback);
};


/**
 * @param {!proto.upl_sandbach.BatchFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.BatchCountResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.ProductionOverviewServicePromiseClient.prototype.getBatchCount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getBatchCount',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getBatchCount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.BatchFilterRequest,
 *   !proto.upl_sandbach.PackagingDetailsResponse>}
 */
const methodDescriptor_ProductionOverviewService_getPackagingDetails = new grpc.web.MethodDescriptor(
  '/upl_sandbach.ProductionOverviewService/getPackagingDetails',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.BatchFilterRequest,
  proto.upl_sandbach.PackagingDetailsResponse,
  /**
   * @param {!proto.upl_sandbach.BatchFilterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.upl_sandbach.PackagingDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.BatchFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.PackagingDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.PackagingDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.ProductionOverviewServiceClient.prototype.getPackagingDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getPackagingDetails',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getPackagingDetails,
      callback);
};


/**
 * @param {!proto.upl_sandbach.BatchFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.PackagingDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.ProductionOverviewServicePromiseClient.prototype.getPackagingDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getPackagingDetails',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getPackagingDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.PPBatchesFilterRequest,
 *   !proto.upl_sandbach.PPBatchesDetailsResponse>}
 */
const methodDescriptor_ProductionOverviewService_getPPAllBatches = new grpc.web.MethodDescriptor(
  '/upl_sandbach.ProductionOverviewService/getPPAllBatches',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.PPBatchesFilterRequest,
  proto.upl_sandbach.PPBatchesDetailsResponse,
  /**
   * @param {!proto.upl_sandbach.PPBatchesFilterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.upl_sandbach.PPBatchesDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.PPBatchesFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.PPBatchesDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.PPBatchesDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.ProductionOverviewServiceClient.prototype.getPPAllBatches =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getPPAllBatches',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getPPAllBatches,
      callback);
};


/**
 * @param {!proto.upl_sandbach.PPBatchesFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.PPBatchesDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.ProductionOverviewServicePromiseClient.prototype.getPPAllBatches =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getPPAllBatches',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getPPAllBatches);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.PPUpdateBatchRejectCountsRequest,
 *   !proto.upl_sandbach.PPUpdateBatchRejectCountsResponse>}
 */
const methodDescriptor_ProductionOverviewService_updateRejectCounts = new grpc.web.MethodDescriptor(
  '/upl_sandbach.ProductionOverviewService/updateRejectCounts',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.PPUpdateBatchRejectCountsRequest,
  proto.upl_sandbach.PPUpdateBatchRejectCountsResponse,
  /**
   * @param {!proto.upl_sandbach.PPUpdateBatchRejectCountsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.upl_sandbach.PPUpdateBatchRejectCountsResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.PPUpdateBatchRejectCountsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.PPUpdateBatchRejectCountsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.PPUpdateBatchRejectCountsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.ProductionOverviewServiceClient.prototype.updateRejectCounts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/updateRejectCounts',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_updateRejectCounts,
      callback);
};


/**
 * @param {!proto.upl_sandbach.PPUpdateBatchRejectCountsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.PPUpdateBatchRejectCountsResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.ProductionOverviewServicePromiseClient.prototype.updateRejectCounts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/updateRejectCounts',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_updateRejectCounts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.LineFilterRequest,
 *   !proto.upl_sandbach.BatchDetailsResponse>}
 */
const methodDescriptor_ProductionOverviewService_getBatchesByLine = new grpc.web.MethodDescriptor(
  '/upl_sandbach.ProductionOverviewService/getBatchesByLine',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.LineFilterRequest,
  proto.upl_sandbach.BatchDetailsResponse,
  /**
   * @param {!proto.upl_sandbach.LineFilterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.upl_sandbach.BatchDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.LineFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.BatchDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.BatchDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.ProductionOverviewServiceClient.prototype.getBatchesByLine =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getBatchesByLine',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getBatchesByLine,
      callback);
};


/**
 * @param {!proto.upl_sandbach.LineFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.BatchDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.ProductionOverviewServicePromiseClient.prototype.getBatchesByLine =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getBatchesByLine',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getBatchesByLine);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.BatchesDataFromAFPackRequest,
 *   !proto.upl_sandbach.GeneralResponse>}
 */
const methodDescriptor_ProductionOverviewService_insertBatchesFromAFPack = new grpc.web.MethodDescriptor(
  '/upl_sandbach.ProductionOverviewService/insertBatchesFromAFPack',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.BatchesDataFromAFPackRequest,
  common_Common_pb.GeneralResponse,
  /**
   * @param {!proto.upl_sandbach.BatchesDataFromAFPackRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_Common_pb.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.BatchesDataFromAFPackRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.ProductionOverviewServiceClient.prototype.insertBatchesFromAFPack =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/insertBatchesFromAFPack',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_insertBatchesFromAFPack,
      callback);
};


/**
 * @param {!proto.upl_sandbach.BatchesDataFromAFPackRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.ProductionOverviewServicePromiseClient.prototype.insertBatchesFromAFPack =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/insertBatchesFromAFPack',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_insertBatchesFromAFPack);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.GeneralRequest,
 *   !proto.upl_sandbach.ProcessOrderListResponse>}
 */
const methodDescriptor_ProductionOverviewService_getPONOfRunningBatches = new grpc.web.MethodDescriptor(
  '/upl_sandbach.ProductionOverviewService/getPONOfRunningBatches',
  grpc.web.MethodType.UNARY,
  common_Common_pb.GeneralRequest,
  proto.upl_sandbach.ProcessOrderListResponse,
  /**
   * @param {!proto.upl_sandbach.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.upl_sandbach.ProcessOrderListResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.ProcessOrderListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.ProcessOrderListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.ProductionOverviewServiceClient.prototype.getPONOfRunningBatches =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getPONOfRunningBatches',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getPONOfRunningBatches,
      callback);
};


/**
 * @param {!proto.upl_sandbach.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.ProcessOrderListResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.ProductionOverviewServicePromiseClient.prototype.getPONOfRunningBatches =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getPONOfRunningBatches',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getPONOfRunningBatches);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest,
 *   !proto.upl_sandbach.GeneralResponse>}
 */
const methodDescriptor_ProductionOverviewService_updateEndTimeForRunningBatches = new grpc.web.MethodDescriptor(
  '/upl_sandbach.ProductionOverviewService/updateEndTimeForRunningBatches',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest,
  common_Common_pb.GeneralResponse,
  /**
   * @param {!proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_Common_pb.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.ProductionOverviewServiceClient.prototype.updateEndTimeForRunningBatches =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/updateEndTimeForRunningBatches',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_updateEndTimeForRunningBatches,
      callback);
};


/**
 * @param {!proto.upl_sandbach.ProcessOrderEndTimeDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.ProductionOverviewServicePromiseClient.prototype.updateEndTimeForRunningBatches =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/updateEndTimeForRunningBatches',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_updateEndTimeForRunningBatches);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.GeneralRequest,
 *   !proto.upl_sandbach.LatestDateResponse>}
 */
const methodDescriptor_ProductionOverviewService_getLatestDateOfValidatingAFPack = new grpc.web.MethodDescriptor(
  '/upl_sandbach.ProductionOverviewService/getLatestDateOfValidatingAFPack',
  grpc.web.MethodType.UNARY,
  common_Common_pb.GeneralRequest,
  proto.upl_sandbach.LatestDateResponse,
  /**
   * @param {!proto.upl_sandbach.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.upl_sandbach.LatestDateResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.LatestDateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.LatestDateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.ProductionOverviewServiceClient.prototype.getLatestDateOfValidatingAFPack =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getLatestDateOfValidatingAFPack',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getLatestDateOfValidatingAFPack,
      callback);
};


/**
 * @param {!proto.upl_sandbach.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.LatestDateResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.ProductionOverviewServicePromiseClient.prototype.getLatestDateOfValidatingAFPack =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getLatestDateOfValidatingAFPack',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getLatestDateOfValidatingAFPack);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.MachineAndBatchResponse,
 *   !proto.upl_sandbach.MachineAndBatchResponse>}
 */
const methodDescriptor_ProductionOverviewService_getMachineAndBatchInfo = new grpc.web.MethodDescriptor(
  '/upl_sandbach.ProductionOverviewService/getMachineAndBatchInfo',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.MachineAndBatchResponse,
  proto.upl_sandbach.MachineAndBatchResponse,
  /**
   * @param {!proto.upl_sandbach.MachineAndBatchResponse} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.upl_sandbach.MachineAndBatchResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.MachineAndBatchResponse} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.MachineAndBatchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.MachineAndBatchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.ProductionOverviewServiceClient.prototype.getMachineAndBatchInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getMachineAndBatchInfo',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getMachineAndBatchInfo,
      callback);
};


/**
 * @param {!proto.upl_sandbach.MachineAndBatchResponse} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.MachineAndBatchResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.ProductionOverviewServicePromiseClient.prototype.getMachineAndBatchInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getMachineAndBatchInfo',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getMachineAndBatchInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.AlarmDashboardFilter,
 *   !proto.upl_sandbach.AlarmAndErrorPareto>}
 */
const methodDescriptor_ProductionOverviewService_getParetoFilter = new grpc.web.MethodDescriptor(
  '/upl_sandbach.ProductionOverviewService/getParetoFilter',
  grpc.web.MethodType.UNARY,
  model_AlarmOverview_pb.AlarmDashboardFilter,
  model_ProductionOverview_pb.AlarmAndErrorPareto,
  /**
   * @param {!proto.upl_sandbach.AlarmDashboardFilter} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_ProductionOverview_pb.AlarmAndErrorPareto.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.AlarmDashboardFilter} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.AlarmAndErrorPareto)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.AlarmAndErrorPareto>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.ProductionOverviewServiceClient.prototype.getParetoFilter =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getParetoFilter',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getParetoFilter,
      callback);
};


/**
 * @param {!proto.upl_sandbach.AlarmDashboardFilter} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.AlarmAndErrorPareto>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.ProductionOverviewServicePromiseClient.prototype.getParetoFilter =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getParetoFilter',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getParetoFilter);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.upl_sandbach.MachineAndBatchRequest,
 *   !proto.upl_sandbach.ProductionDashboardOverviewResponse>}
 */
const methodDescriptor_ProductionOverviewService_getProductionDashboardOverview = new grpc.web.MethodDescriptor(
  '/upl_sandbach.ProductionOverviewService/getProductionDashboardOverview',
  grpc.web.MethodType.UNARY,
  proto.upl_sandbach.MachineAndBatchRequest,
  proto.upl_sandbach.ProductionDashboardOverviewResponse,
  /**
   * @param {!proto.upl_sandbach.MachineAndBatchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.upl_sandbach.ProductionDashboardOverviewResponse.deserializeBinary
);


/**
 * @param {!proto.upl_sandbach.MachineAndBatchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.upl_sandbach.ProductionDashboardOverviewResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.upl_sandbach.ProductionDashboardOverviewResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.upl_sandbach.ProductionOverviewServiceClient.prototype.getProductionDashboardOverview =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getProductionDashboardOverview',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getProductionDashboardOverview,
      callback);
};


/**
 * @param {!proto.upl_sandbach.MachineAndBatchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.upl_sandbach.ProductionDashboardOverviewResponse>}
 *     Promise that resolves to the response
 */
proto.upl_sandbach.ProductionOverviewServicePromiseClient.prototype.getProductionDashboardOverview =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/upl_sandbach.ProductionOverviewService/getProductionDashboardOverview',
      request,
      metadata || {},
      methodDescriptor_ProductionOverviewService_getProductionDashboardOverview);
};


module.exports = proto.upl_sandbach;

