import "./index.scss";
import { CustomButton } from "components/Common/Button";
import { Button, Table } from "antd";
import { format } from "date-fns";
import { Text } from "components/Text";
import { ReactComponent as AlarmIcon } from "assets/icons/alarmIcon.svg";
import { ReactComponent as CriticalAlarmIcon } from "assets/icons/criticalAlarmIcon.svg";
import { ReactComponent as MajorAlarmIcon } from "assets/icons/majorAlarmIcon.svg";
import { ReactComponent as MinorAlarmIcon } from "assets/icons/minorAlarmIcon.svg";
import { ReactComponent as WarningAlarmIcon } from "assets/icons/warningAlarmIcon.svg";
import moment from "moment";
const { Severity } = require("protobuf/upl-sandbach-protos/common/Enum_pb");
let locale = {
  emptyText: "No Active Alarm",
};

const columns = [
  {
    title: (
      <div className="column-title">
        <div> Start Time</div>
      </div>
    ),
    dataIndex: "timestamp",
    key: "timestamp",
    render: (timestamp: any) => (
      <div>{format(new Date(timestamp), "dd/MM/yy HH:mm:ss")}</div>
    ),
    width: "25%",
  },
  {
    title: (
      <div className="column-title">
        <div>Device</div>
      </div>
    ),
    dataIndex: "deviceName",
    key: "deviceName",
    width: "10%",
  },
  {
    title: (
      <div className="column-title">
        <div>Device Tag</div>
      </div>
    ),
    dataIndex: "deviceTag",
    key: "deviceTag",
  },
  {
    title: (
      <div className="column-title">
        <div>Alarm Description</div>
      </div>
    ),
    key: "alarmDescription",
    dataIndex: "alarmDescription",
    width: "16%",

  },
  {
    title: (
      <div className="column-title">
        <div>Severity</div>
      </div>
    ),
    key: "severity",
    render: (_: any, record: any) => {
      let severity = severityToString(record.severity);
      return <CustomButton type={severity} />;
    },
  },
];

export const severityToString = (value: number) => {
  let entries = Object.entries(Severity);
  let severity = "";
  entries.forEach(([key, val]) => {
    if (value === val) {
      severity = key.toLocaleLowerCase();
    }
  });
  return severity.charAt(0).toUpperCase() + severity.slice(1);
};

export const ActiveAlarmCard = (props: any) => {
  const { height = 218 } = props;
  const changeHandler = (page) => {
    props.setPage(page);
  };
  return (
    <>
      <div className="activealarmCard-main-container">
        <div className="activealarmCard-button-container">
          <Button
            type="default"
            className="total card-with-hover"
            icon={<AlarmIcon />}
          >
            <div className="show-on-hover">
              <Text>Total</Text>
            </div>
            <Text>{props.cardData.totalCount}</Text>
          </Button>
          <Button
            type="default"
            className="critical card-with-hover"
            icon={<CriticalAlarmIcon />}
          >
            <div className="show-on-hover">
              <Text>Critical</Text>
            </div>
            <Text>{props.cardData.criticalCount}</Text>
          </Button>
          <Button
            type="default"
            className="major card-with-hover"
            icon={<MajorAlarmIcon />}
          >
            <div className="show-on-hover">
              <Text>Major</Text>
            </div>
            <Text>{props.cardData.majorCount}</Text>
          </Button>
          <Button
            type="default"
            className="minor card-with-hover"
            icon={<MinorAlarmIcon />}
          >
            <div className="show-on-hover">
              <Text>Minor</Text>
            </div>
            <Text>{props.cardData.minorCount} </Text>
          </Button>
          <Button
            type="default"
            className="warning card-with-hover"
            icon={<WarningAlarmIcon />}
          >
            <div className="show-on-hover">
              <Text>Warning</Text>
            </div>
            <Text>{props.cardData.warningCount} </Text>
          </Button>
          <Button
            type="default"
            className="event card-with-hover"
            icon={<WarningAlarmIcon />}
          >
            <div className="show-on-hover">
              <Text>Event</Text>
            </div>
            <Text>{props.cardData.eventCount} </Text>
          </Button>
        </div>
        <Table
          size="small"
          columns={columns}
          pagination={{
            total: props.alarmsEventsActiveAlarmsRowCount,
            defaultCurrent: 1,
            pageSize: 50,
            showSizeChanger: false,
            onChange: changeHandler,
          }}
          locale={locale}
          dataSource={[...props.data]}
          scroll={{ x: 400, y: height }}
        />
      </div>
    </>
  );
};
