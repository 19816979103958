// source: model/Filter.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_Common_pb = require('../common/Common_pb.js');
goog.object.extend(proto, common_Common_pb);
var common_Enum_pb = require('../common/Enum_pb.js');
goog.object.extend(proto, common_Enum_pb);
goog.exportSymbol('proto.upl_sandbach.Batch', null, global);
goog.exportSymbol('proto.upl_sandbach.BatchFilter', null, global);
goog.exportSymbol('proto.upl_sandbach.BatchFilter.Line', null, global);
goog.exportSymbol('proto.upl_sandbach.BatchFilter.Line.Machine', null, global);
goog.exportSymbol('proto.upl_sandbach.Line', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.BatchFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.BatchFilter.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.BatchFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.BatchFilter.displayName = 'proto.upl_sandbach.BatchFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.BatchFilter.Line = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.BatchFilter.Line.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.BatchFilter.Line, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.BatchFilter.Line.displayName = 'proto.upl_sandbach.BatchFilter.Line';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.BatchFilter.Line.Machine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.BatchFilter.Line.Machine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.BatchFilter.Line.Machine.displayName = 'proto.upl_sandbach.BatchFilter.Line.Machine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.Line = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.upl_sandbach.Line.repeatedFields_, null);
};
goog.inherits(proto.upl_sandbach.Line, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.Line.displayName = 'proto.upl_sandbach.Line';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.upl_sandbach.Batch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.upl_sandbach.Batch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.upl_sandbach.Batch.displayName = 'proto.upl_sandbach.Batch';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.BatchFilter.repeatedFields_ = [10,50];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.BatchFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.BatchFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.BatchFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    lineList: jspb.Message.toObjectList(msg.getLineList(),
    proto.upl_sandbach.BatchFilter.Line.toObject, includeInstance),
    sku: (f = msg.getSku()) && common_Common_pb.DropDownData.toObject(includeInstance, f),
    startTime: jspb.Message.getFieldWithDefault(msg, 30, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 40, 0),
    batchesList: (f = jspb.Message.getRepeatedField(msg, 50)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.BatchFilter}
 */
proto.upl_sandbach.BatchFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.BatchFilter;
  return proto.upl_sandbach.BatchFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.BatchFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.BatchFilter}
 */
proto.upl_sandbach.BatchFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new proto.upl_sandbach.BatchFilter.Line;
      reader.readMessage(value,proto.upl_sandbach.BatchFilter.Line.deserializeBinaryFromReader);
      msg.addLine(value);
      break;
    case 20:
      var value = new common_Common_pb.DropDownData;
      reader.readMessage(value,common_Common_pb.DropDownData.deserializeBinaryFromReader);
      msg.setSku(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.addBatches(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.BatchFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.BatchFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.BatchFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.upl_sandbach.BatchFilter.Line.serializeBinaryToWriter
    );
  }
  f = message.getSku();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_Common_pb.DropDownData.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      30,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      40,
      f
    );
  }
  f = message.getBatchesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      50,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.BatchFilter.Line.repeatedFields_ = [30];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.BatchFilter.Line.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.BatchFilter.Line.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.BatchFilter.Line} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchFilter.Line.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 10, 0),
    name: jspb.Message.getFieldWithDefault(msg, 20, ""),
    machineList: jspb.Message.toObjectList(msg.getMachineList(),
    proto.upl_sandbach.BatchFilter.Line.Machine.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.BatchFilter.Line}
 */
proto.upl_sandbach.BatchFilter.Line.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.BatchFilter.Line;
  return proto.upl_sandbach.BatchFilter.Line.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.BatchFilter.Line} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.BatchFilter.Line}
 */
proto.upl_sandbach.BatchFilter.Line.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 30:
      var value = new proto.upl_sandbach.BatchFilter.Line.Machine;
      reader.readMessage(value,proto.upl_sandbach.BatchFilter.Line.Machine.deserializeBinaryFromReader);
      msg.addMachine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.BatchFilter.Line.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.BatchFilter.Line.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.BatchFilter.Line} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchFilter.Line.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getMachineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      30,
      f,
      proto.upl_sandbach.BatchFilter.Line.Machine.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.BatchFilter.Line.Machine.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.BatchFilter.Line.Machine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.BatchFilter.Line.Machine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchFilter.Line.Machine.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 10, 0),
    name: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.BatchFilter.Line.Machine}
 */
proto.upl_sandbach.BatchFilter.Line.Machine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.BatchFilter.Line.Machine;
  return proto.upl_sandbach.BatchFilter.Line.Machine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.BatchFilter.Line.Machine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.BatchFilter.Line.Machine}
 */
proto.upl_sandbach.BatchFilter.Line.Machine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 20:
      var value = /** @type {!proto.upl_sandbach.Machines} */ (reader.readEnum());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.BatchFilter.Line.Machine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.BatchFilter.Line.Machine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.BatchFilter.Line.Machine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.BatchFilter.Line.Machine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getName();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
};


/**
 * optional int64 id = 10;
 * @return {number}
 */
proto.upl_sandbach.BatchFilter.Line.Machine.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BatchFilter.Line.Machine} returns this
 */
proto.upl_sandbach.BatchFilter.Line.Machine.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional Machines name = 20;
 * @return {!proto.upl_sandbach.Machines}
 */
proto.upl_sandbach.BatchFilter.Line.Machine.prototype.getName = function() {
  return /** @type {!proto.upl_sandbach.Machines} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.upl_sandbach.Machines} value
 * @return {!proto.upl_sandbach.BatchFilter.Line.Machine} returns this
 */
proto.upl_sandbach.BatchFilter.Line.Machine.prototype.setName = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional int64 id = 10;
 * @return {number}
 */
proto.upl_sandbach.BatchFilter.Line.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BatchFilter.Line} returns this
 */
proto.upl_sandbach.BatchFilter.Line.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string name = 20;
 * @return {string}
 */
proto.upl_sandbach.BatchFilter.Line.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.BatchFilter.Line} returns this
 */
proto.upl_sandbach.BatchFilter.Line.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * repeated Machine machine = 30;
 * @return {!Array<!proto.upl_sandbach.BatchFilter.Line.Machine>}
 */
proto.upl_sandbach.BatchFilter.Line.prototype.getMachineList = function() {
  return /** @type{!Array<!proto.upl_sandbach.BatchFilter.Line.Machine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.upl_sandbach.BatchFilter.Line.Machine, 30));
};


/**
 * @param {!Array<!proto.upl_sandbach.BatchFilter.Line.Machine>} value
 * @return {!proto.upl_sandbach.BatchFilter.Line} returns this
*/
proto.upl_sandbach.BatchFilter.Line.prototype.setMachineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 30, value);
};


/**
 * @param {!proto.upl_sandbach.BatchFilter.Line.Machine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.BatchFilter.Line.Machine}
 */
proto.upl_sandbach.BatchFilter.Line.prototype.addMachine = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 30, opt_value, proto.upl_sandbach.BatchFilter.Line.Machine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.BatchFilter.Line} returns this
 */
proto.upl_sandbach.BatchFilter.Line.prototype.clearMachineList = function() {
  return this.setMachineList([]);
};


/**
 * repeated Line line = 10;
 * @return {!Array<!proto.upl_sandbach.BatchFilter.Line>}
 */
proto.upl_sandbach.BatchFilter.prototype.getLineList = function() {
  return /** @type{!Array<!proto.upl_sandbach.BatchFilter.Line>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.upl_sandbach.BatchFilter.Line, 10));
};


/**
 * @param {!Array<!proto.upl_sandbach.BatchFilter.Line>} value
 * @return {!proto.upl_sandbach.BatchFilter} returns this
*/
proto.upl_sandbach.BatchFilter.prototype.setLineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.upl_sandbach.BatchFilter.Line=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.BatchFilter.Line}
 */
proto.upl_sandbach.BatchFilter.prototype.addLine = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.upl_sandbach.BatchFilter.Line, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.BatchFilter} returns this
 */
proto.upl_sandbach.BatchFilter.prototype.clearLineList = function() {
  return this.setLineList([]);
};


/**
 * optional DropDownData sku = 20;
 * @return {?proto.upl_sandbach.DropDownData}
 */
proto.upl_sandbach.BatchFilter.prototype.getSku = function() {
  return /** @type{?proto.upl_sandbach.DropDownData} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.DropDownData, 20));
};


/**
 * @param {?proto.upl_sandbach.DropDownData|undefined} value
 * @return {!proto.upl_sandbach.BatchFilter} returns this
*/
proto.upl_sandbach.BatchFilter.prototype.setSku = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.BatchFilter} returns this
 */
proto.upl_sandbach.BatchFilter.prototype.clearSku = function() {
  return this.setSku(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.BatchFilter.prototype.hasSku = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional int64 start_time = 30;
 * @return {number}
 */
proto.upl_sandbach.BatchFilter.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BatchFilter} returns this
 */
proto.upl_sandbach.BatchFilter.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional int64 end_time = 40;
 * @return {number}
 */
proto.upl_sandbach.BatchFilter.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.BatchFilter} returns this
 */
proto.upl_sandbach.BatchFilter.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * repeated string batches = 50;
 * @return {!Array<string>}
 */
proto.upl_sandbach.BatchFilter.prototype.getBatchesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 50));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.upl_sandbach.BatchFilter} returns this
 */
proto.upl_sandbach.BatchFilter.prototype.setBatchesList = function(value) {
  return jspb.Message.setField(this, 50, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.BatchFilter} returns this
 */
proto.upl_sandbach.BatchFilter.prototype.addBatches = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 50, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.BatchFilter} returns this
 */
proto.upl_sandbach.BatchFilter.prototype.clearBatchesList = function() {
  return this.setBatchesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.upl_sandbach.Line.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.Line.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.Line.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.Line} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.Line.toObject = function(includeInstance, msg) {
  var f, obj = {
    line: (f = msg.getLine()) && common_Common_pb.DropDownData.toObject(includeInstance, f),
    machinesList: jspb.Message.toObjectList(msg.getMachinesList(),
    common_Common_pb.DropDownData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.Line}
 */
proto.upl_sandbach.Line.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.Line;
  return proto.upl_sandbach.Line.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.Line} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.Line}
 */
proto.upl_sandbach.Line.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new common_Common_pb.DropDownData;
      reader.readMessage(value,common_Common_pb.DropDownData.deserializeBinaryFromReader);
      msg.setLine(value);
      break;
    case 20:
      var value = new common_Common_pb.DropDownData;
      reader.readMessage(value,common_Common_pb.DropDownData.deserializeBinaryFromReader);
      msg.addMachines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.Line.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.Line.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.Line} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.Line.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLine();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_Common_pb.DropDownData.serializeBinaryToWriter
    );
  }
  f = message.getMachinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      common_Common_pb.DropDownData.serializeBinaryToWriter
    );
  }
};


/**
 * optional DropDownData line = 10;
 * @return {?proto.upl_sandbach.DropDownData}
 */
proto.upl_sandbach.Line.prototype.getLine = function() {
  return /** @type{?proto.upl_sandbach.DropDownData} */ (
    jspb.Message.getWrapperField(this, common_Common_pb.DropDownData, 10));
};


/**
 * @param {?proto.upl_sandbach.DropDownData|undefined} value
 * @return {!proto.upl_sandbach.Line} returns this
*/
proto.upl_sandbach.Line.prototype.setLine = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.upl_sandbach.Line} returns this
 */
proto.upl_sandbach.Line.prototype.clearLine = function() {
  return this.setLine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.upl_sandbach.Line.prototype.hasLine = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated DropDownData machines = 20;
 * @return {!Array<!proto.upl_sandbach.DropDownData>}
 */
proto.upl_sandbach.Line.prototype.getMachinesList = function() {
  return /** @type{!Array<!proto.upl_sandbach.DropDownData>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_Common_pb.DropDownData, 20));
};


/**
 * @param {!Array<!proto.upl_sandbach.DropDownData>} value
 * @return {!proto.upl_sandbach.Line} returns this
*/
proto.upl_sandbach.Line.prototype.setMachinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.upl_sandbach.DropDownData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.upl_sandbach.DropDownData}
 */
proto.upl_sandbach.Line.prototype.addMachines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.upl_sandbach.DropDownData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.upl_sandbach.Line} returns this
 */
proto.upl_sandbach.Line.prototype.clearMachinesList = function() {
  return this.setMachinesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.upl_sandbach.Batch.prototype.toObject = function(opt_includeInstance) {
  return proto.upl_sandbach.Batch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.upl_sandbach.Batch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.Batch.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    processOrder: jspb.Message.getFieldWithDefault(msg, 20, ""),
    targetTime: jspb.Message.getFieldWithDefault(msg, 30, 0),
    elapseTime: jspb.Message.getFieldWithDefault(msg, 40, 0),
    remainingTime: jspb.Message.getFieldWithDefault(msg, 50, 0),
    manufacturingTime: jspb.Message.getFieldWithDefault(msg, 60, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.upl_sandbach.Batch}
 */
proto.upl_sandbach.Batch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.upl_sandbach.Batch;
  return proto.upl_sandbach.Batch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.upl_sandbach.Batch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.upl_sandbach.Batch}
 */
proto.upl_sandbach.Batch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessOrder(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetTime(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setElapseTime(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRemainingTime(value);
      break;
    case 60:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setManufacturingTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.upl_sandbach.Batch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.upl_sandbach.Batch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.upl_sandbach.Batch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.upl_sandbach.Batch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getProcessOrder();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getTargetTime();
  if (f !== 0) {
    writer.writeInt64(
      30,
      f
    );
  }
  f = message.getElapseTime();
  if (f !== 0) {
    writer.writeInt64(
      40,
      f
    );
  }
  f = message.getRemainingTime();
  if (f !== 0) {
    writer.writeInt64(
      50,
      f
    );
  }
  f = message.getManufacturingTime();
  if (f !== 0) {
    writer.writeInt64(
      60,
      f
    );
  }
};


/**
 * optional string batch_id = 10;
 * @return {string}
 */
proto.upl_sandbach.Batch.prototype.getBatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.Batch} returns this
 */
proto.upl_sandbach.Batch.prototype.setBatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string process_order = 20;
 * @return {string}
 */
proto.upl_sandbach.Batch.prototype.getProcessOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.upl_sandbach.Batch} returns this
 */
proto.upl_sandbach.Batch.prototype.setProcessOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional int64 target_time = 30;
 * @return {number}
 */
proto.upl_sandbach.Batch.prototype.getTargetTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.Batch} returns this
 */
proto.upl_sandbach.Batch.prototype.setTargetTime = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional int64 elapse_time = 40;
 * @return {number}
 */
proto.upl_sandbach.Batch.prototype.getElapseTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.Batch} returns this
 */
proto.upl_sandbach.Batch.prototype.setElapseTime = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional int64 remaining_time = 50;
 * @return {number}
 */
proto.upl_sandbach.Batch.prototype.getRemainingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.Batch} returns this
 */
proto.upl_sandbach.Batch.prototype.setRemainingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 50, value);
};


/**
 * optional int64 manufacturing_time = 60;
 * @return {number}
 */
proto.upl_sandbach.Batch.prototype.getManufacturingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {number} value
 * @return {!proto.upl_sandbach.Batch} returns this
 */
proto.upl_sandbach.Batch.prototype.setManufacturingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 60, value);
};


goog.object.extend(exports, proto.upl_sandbach);
