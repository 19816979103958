import { message } from "antd";
import axios from "axios";
import { getUrl } from "config/apiconfig";

export const downloadSampleTemplate = () => {
  const downloadTemplate = getUrl("sampleTemplateDownload");

  fetch(downloadTemplate, {
    method: "GET",
  })
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      let fileName = "Production_Overview.xlsx";
      let xlsxURL = window.URL.createObjectURL(blob);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute("download", fileName);
      tempLink.click();
    })
    .catch((err) => {
      message.error(`Unable to download Sample Template`);
    });
};

export const uploadFile = (file: any) => {
  let formData = new FormData();
  formData.append("file", file);

  axios({
    method: "post",
    url: getUrl("uploadTemplate"),
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {
      message.success("File Successfully Uploaded");
    })
    .catch(function ({ response }) {
      message.error(response.data);
    });
};


