import initialState from "./initalState";
import * as types from "store/actions/actionTypes";

export default function runtimeAnalysisReducer(
  state = initialState.runtimeAnalysis,
  action: any
) {
  switch (action.type) {
    case types.GET_RUNTIME_ANALYSIS_DASHBOARD_DOWNTIME_DATA_SUCCESS:
      state = {
        ...state,
        downtime: {
          currentValue: action.payload.runtimeAnalysisCardData.currentValue,
          lastValue: action.payload.runtimeAnalysisCardData.lastValue,
          percentage: action.payload.runtimeAnalysisCardData.percentage,
        },
      };
      return state;
    case types.GET_RUNTIME_ANALYSIS_DASHBOARD_RUNTIME_DATA_SUCCESS:
      state = {
        ...state,
        runtime: {
          currentValue: action.payload.runtimeAnalysisCardData.currentValue,
          lastValue: action.payload.runtimeAnalysisCardData.lastValue,
          percentage: action.payload.runtimeAnalysisCardData.percentage,
        },
      };
      return state;
    case types.GET_RUNTIME_ANALYSIS_DASHBOARD_PLANNED_STOPTIME_DATA_SUCCESS:
      state = {
        ...state,
        plannedStoptime: {
          currentValue: action.payload.runtimeAnalysisCardData.currentValue,
          lastValue: action.payload.runtimeAnalysisCardData.lastValue,
          percentage: action.payload.runtimeAnalysisCardData.percentage,
        },
      };
      return state;
    case types.GET_RUNTIME_ANALYSIS_DASHBOARD_ASSET_UTILIZATION_DATA_SUCCESS:
      state = {
        ...state,
        assetUtilization: action.payload.runtimeAnalysisAssetUtilizationList,
      };
      return state;
    case types.GET_RUNTIME_ANALYSIS_DASHBOARD_MACHINE_RUNTIME_STATUS_DATA_SUCCESS:
      state = {
        ...state,
        machineRuntimeStatus:
          action.payload.runtimeAnalysisMachineRuntimeStatusList,
      };
      return state;
    case types.GET_RUNTIME_ANALYSIS_DASHBOARD_MTTR_DATA_SUCCESS:
      state = {
        ...state,
        mttr: {
          runtimeAnalysisMttrList: action.payload.runtimeAnalysisMttrList,
          runtimeAnalysisMttrCardData:
            action.payload.runtimeAnalysisMttrCardData,
        },
      };
      return state;
    case types.GET_RUNTIME_ANALYSIS_DASHBOARD_MTBF_DATA_SUCCESS:
      state = {
        ...state,
        mtbf: {
          runtimeAnalysisMtbfList: action.payload.runtimeAnalysisMtbfList,
          runtimeAnalysisMtbfCardData:
            action.payload.runtimeAnalysisMtbfCardData,
        },
      };
      return state;
    case types.GET_RUNTIME_ANALYSIS_DETAILS_DATA_SUCCESS:
      state = {
        ...state,
        details: {
          runtimeStatistics:
            action.payload.runtimeAnalysisDetailsRuntimeStatisticsList,
          downtimeStatistics:
            action.payload.runtimeAnalysisDetailsDowntimeStatisticsList,
        },
      };
      return state;
    case types.GET_RUNTIME_ANALYSIS_DASHBOARD_GANTT_DATA_SUCCESS:
      state = {
        ...state,
        gantt: action.payload.runtimeAnalysisDashboardGanttData,
        machineNames: action.payload.machineNames,
      };
      return state;
      case types.GET_RUNTIME_ANALYSIS_DASHBOARD_GANTT_DATA_LOADING:
      state = {
        ...state,
      ganttLoading:action.showLoader
      };
      return state;
      
    default:
      return state;
  }
}
