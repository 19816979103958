import { ApiConfig } from "types";

export const nlDev = "uplsandbach.nl-dev.solulever.com";
export const upl = "mesukprd.upl-ltd.com";
export const nlDemo = "uplsandbach.nl-demo.solulever.com";
export const nlaDemo = "uplsandbach.nl-ademo.solulever.com";
export const uplDev = "uplsandbach.upl-dev.solulever.com";
export const demoMesukPrd = "demo.mesukprd.solulever.com";

const nlDevConfig: ApiConfig = {
  braboPlatform: "https://brabo-platform.nl-dev.solulever.com/",
  reasonCodeSelection: "https://uplsandbach.nl-dev.solulever.com/operator",
  loginUrl: "//user.nl-dev.solulever.com/api/v1/login",
  logoutUrl: "//user.nl-dev.solulever.com/api/v1/logout",
  generateOTP: "//user.nl-dev.solulever.com/api/v1/password/generateOtp",
  validateOTP: "//user.nl-dev.solulever.com/api/v1/password/changePassword",
  sampleTemplateDownload:
    "https://upl-usecase.rest.nl-dev.solulever.com/download_sample_file_batch_details/",
  uploadTemplate:
    "https://upl-usecase.rest.nl-dev.solulever.com/upload_file_batch_details/",
  reasonCodeUrl: "//reasoncode.api.nl-dev.solulever.com",
  assetServiceGrpc: "//asset.api.nl-dev.solulever.com",
  alarmServiceUrl: "//alert.api.nl-dev.solulever.com",
  productionOverviewServiceUrl: "//upl-usecase.api.nl-dev.solulever.com",
  alarmsDataUrl:
    "https://alert.rest.nl-dev.solulever.com/public/api/v1/downloadAlarmOverviewCsv",
};

const uplConfig: ApiConfig = {
  braboPlatform: "https://mesukcoreapp.upl-ltd.com/",
  reasonCodeSelection: "https://mesukprd.upl-ltd.com/operator",
  loginUrl: "//user.mesukcoreapp.solulever.com/api/v1/login",
  logoutUrl: "//user.mesukcoreapp.solulever.com/api/v1/logout",
  generateOTP:
    "//user.api.mesukcoreapp.solulever.com/api/v1/password/generateOtp",
  validateOTP:
    "//user.api.mesukcoreapp.solulever.com/api/v1/password/changePassword",
  sampleTemplateDownload:
    "https://upl-usecase.rest.mesukcoreapp.solulever.com/download_sample_file_batch_details/",
  uploadTemplate:
    "https://upl-usecase.rest.mesukcoreapp.solulever.com/upload_file_batch_details/",
  reasonCodeUrl: "//reasoncode.api.mesukcoreapp.solulever.com",
  assetServiceGrpc: "//asset.api.mesukcoreapp.solulever.com",
  alarmServiceUrl: "//alert.api.mesukcoreapp.solulever.com",
  productionOverviewServiceUrl: "//upl-usecase.api.mesukcoreapp.solulever.com",
  alarmsDataUrl:
    "https://alert.rest.mesukcoreapp.solulever.com/public/api/v1/downloadAlarmOverviewCsv",
};

const uplDevConfig: ApiConfig = {
  braboPlatform: "https://brabo-platform.upl-dev.solulever.com",
  reasonCodeSelection: "https://uplsandbach.upl-dev.solulever.com/operator",
  loginUrl: "//user.brabo-platform.upl-dev.solulever.com/api/v1/login",
  logoutUrl: "//user.brabo-platform.upl-dev.solulever.com/api/v1/logout",
  generateOTP:
    "//user.api.brabo-platform.upl-dev.solulever.com/api/v1/password/generateOtp",
  validateOTP:
    "//user.api.brabo-platform.upl-dev.solulever.com/api/v1/password/changePassword",
  sampleTemplateDownload:
    "https://upl-usecase.rest.brabo-platform.upl-dev.solulever.com/download_sample_file_batch_details/",
  uploadTemplate:
    "https://upl-usecase.rest.brabo-platform.upl-dev.solulever.com/upload_file_batch_details/",
  reasonCodeUrl: "//reasoncode.api.brabo-platform.upl-dev.solulever.com",
  assetServiceGrpc: "//asset.api.brabo-platform.upl-dev.solulever.com",
  alarmServiceUrl: "//alert.api.brabo-platform.upl-dev.solulever.com",
  productionOverviewServiceUrl:
    "//upl-usecase.api.brabo-platform.upl-dev.solulever.com",
  alarmsDataUrl:
    "https://alert.rest.brabo-platform.upl-dev.solulever.com/public/api/v1/downloadAlarmOverviewCsv",
};

const nlaDemoConfig: ApiConfig = {
  braboPlatform: "https://brabo-platform.nl-ademo.solulever.com",
  reasonCodeSelection: "https://uplsandbach.nl-ademo.solulever.com/operator",
  loginUrl: "//user.brabo-platform.nl-ademo.solulever.com/api/v1/login",
  logoutUrl: "//user.brabo-platform.nl-ademo.solulever.com/api/v1/logout",
  generateOTP:
    "//user.api.brabo-platform.nl-ademo.solulever.com/api/v1/password/generateOtp",
  validateOTP:
    "//user.api.brabo-platform.nl-ademo.solulever.com/api/v1/password/changePassword",
  sampleTemplateDownload:
    "https://upl-usecase.rest.brabo-platform.nl-ademo.solulever.com/download_sample_file_batch_details/",
  uploadTemplate:
    "https://upl-usecase.rest.brabo-platform.nl-ademo.solulever.com/upload_file_batch_details/",
  reasonCodeUrl: "//reasoncode.api.brabo-platform.nl-ademo.solulever.com",
  assetServiceGrpc: "//asset.api.brabo-platform.nl-ademo.solulever.com",
  alarmServiceUrl: "//alert.api.brabo-platform.nl-ademo.solulever.com",
  productionOverviewServiceUrl:
    "//upl-usecase.api.brabo-platform.nl-ademo.solulever.com",
  alarmsDataUrl:
    "https://alert.rest.brabo-platform.nl-ademo.solulever.com/public/api/v1/downloadAlarmOverviewCsv",
};

const nlDemoConfig: ApiConfig = {
  braboPlatform: "https://brabo-platform.nl-demo.solulever.com",
  reasonCodeSelection: "https://uplsandbach.nl-demo.solulever.com/operator",
  loginUrl: "//user.brabo-platform.nl-demo.solulever.com/api/v1/login",
  logoutUrl: "//user.brabo-platform.nl-demo.solulever.com/api/v1/logout",
  generateOTP:
    "//user.api.brabo-platform.nl-demo.solulever.com/api/v1/password/generateOtp",
  validateOTP:
    "//user.api.brabo-platform.nl-demo.solulever.com/api/v1/password/changePassword",
  sampleTemplateDownload:
    "https://upl-usecase.rest.brabo-platform.nl-demo.solulever.com/download_sample_file_batch_details/",
  uploadTemplate:
    "https://upl-usecase.rest.brabo-platform.nl-demo.solulever.com/upload_file_batch_details/",
  reasonCodeUrl: "//reasoncode.api.brabo-platform.nl-demo.solulever.com",
  assetServiceGrpc: "//asset.api.brabo-platform.nl-demo.solulever.com",
  alarmServiceUrl: "//alert.api.brabo-platform.nl-demo.solulever.com",
  productionOverviewServiceUrl:
    "//upl-usecase.api.brabo-platform.nl-demo.solulever.com",
  alarmsDataUrl:
    "https://alert.rest.brabo-platform.nl-demo.solulever.com/public/api/v1/downloadAlarmOverviewCsv",
};

const demoMesukPrdConfig: ApiConfig = {
  braboPlatform: "https://brabo-config.demo.mesukcoreapp.solulever.com",
  reasonCodeSelection: "https://demo.mesukcoreapp.solulever.com/operator",
  loginUrl: "//user.demo.mesukcoreapp.solulever.com/api/v1/login",
  logoutUrl: "//user.demo.mesukcoreapp.solulever.com/api/v1/logout",
  generateOTP:
    "//user.api.demo.mesukcoreapp.solulever.com/api/v1/password/generateOtp",
  validateOTP:
    "//user.api.demo.mesukcoreapp.solulever.com/api/v1/password/changePassword",
  sampleTemplateDownload:
    "https://upl-usecase.rest.demo.mesukcoreapp.solulever.com/download_sample_file_batch_details/",
  uploadTemplate:
    "https://upl-usecase.rest.demo.mesukcoreapp.solulever.com/upload_file_batch_details/",
  reasonCodeUrl: "//reasoncode.api.demo.mesukcoreapp.solulever.com",
  assetServiceGrpc: "//asset.api.demo.mesukcoreapp.solulever.com",
  alarmServiceUrl: "//alert.api.demo.mesukcoreapp.solulever.com",
  productionOverviewServiceUrl:
    "//upl-usecase.api.demo.mesukcoreapp.solulever.com",
  alarmsDataUrl:
    "https://alert.rest.demo.mesukcoreapp.solulever.com/public/api/v1/downloadAlarmOverviewCsv",
};

const localhost: ApiConfig = {
  braboPlatform: "http://localhost:3000",
  reasonCodeSelection: "http://localhost:3000/operator",
  loginUrl: "http://localhost:6038/api/v1/login",
  logoutUrl: "http://localhost:6038/api/v1/logout",
  sampleTemplateDownload:
    "https://upl-usecase.rest.nl-dev.solulever.com/download_sample_file_batch_details/",
  uploadTemplate:
    "https://upl-usecase.rest.nl-dev.solulever.com/upload_file_batch_details/",
  generateOTP: "http://localhost:6038/api/v1/password/generateOtp",
  validateOTP: "http://localhost:6038/api/v1/password/changePassword",
  reasonCodeUrl: "http://localhost:6589",
  assetServiceGrpc: "http://localhost:6040",
  alarmServiceUrl: "http://localhost:6041",
  productionOverviewServiceUrl: "http://localhost:6049",
  alarmsDataUrl:
    "https://alert.rest.mesukcoreapp.solulever.com/public/api/v1/downloadAlarmOverviewCsv",
};

export const getUrl = (serviceName: string) => {
  switch (window.location.hostname) {
    case nlDev:
      return nlDevConfig[serviceName];
    case upl:
      return uplConfig[serviceName];
    case uplDev:
      return uplDevConfig[serviceName];
    case nlaDemo:
      return nlaDemoConfig[serviceName];
    case nlDemo:
      return nlDemoConfig[serviceName];
    case demoMesukPrd:
      return demoMesukPrdConfig[serviceName];
    default:
      return localhost[serviceName];
  }
};
