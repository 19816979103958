import * as types from "./actionTypes";

export const getLineOEEData = (payload: any) => ({
    type: types.GET_LINE_OEE,
    payload,
});

export const teepMatrixData = (payload: any) => ({
    type: types.GET_TEEP_MATRIX_BY_LINE,
    payload,
})

export const getRuntimeDowntime = (payload: any) => ({
    type: types.GET_RUNTIME_DOWNTIME,
    payload,
})

export const getDowntimeTrend = (payload: any) => ({
    type: types.GET_DOWNTIME_BY_LINE,
    payload,
})

export const getLostTimeMatrix = (payload: any) => ({
    type: types.GET_LOST_TIME_MATRIX,
    payload,
})

export const getRateMatrix = (payload: any) => ({
    type: types.GET_RATE_MATRIX,
    payload,
})

export const getActiveAlarmsTableData = (payload: any) => {
   return ({
    type: types.GET_LINE_OVERVIEW_TABLE_DATA,
    payload,
})}
