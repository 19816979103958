import * as types from './actionTypes';

export const editReasonCodeCategory = (payload: any) => ({
  type: types.EDIT_REASON_CODE_CATEGORY,
  payload,
});

export const createReasonCodeCategory = (payload: any) => ({
  type: types.CREATE_REASON_CODE_CATEGORY,
  payload,
});

export const getReasonCodeCategories = () => ({
  type: types.GET_REASON_CODE_CATEGORIES
});

export const createReasonCodeFactor = (payload: any) => ({
  type: types.CREATE_REASON_CODE_FACTOR,
  payload
});

export const getReasonCodeCategoriesData = (payload: any) => (
  {
    type: types.GET_REASON_CODE_CATEGORY_DATA,
    payload
  }
);

export const createReasonCodeDetails = (payload: any) => ({
  type: types.ADD_REASON_CODE_DETAILS,
  payload
});

export const getAssignedReasonCode = (payload: any) => ({
  type: types.GET_ASSIGNED_REASON_CODE,
  payload
});

export const getAMDList = (payload: any) => ({
  type: types.GET_AMD_LIST,
  payload
})

export const assignReasourceCodeToResource = (payload: any) => ({
  type: types.ASSIGN_REASON_CODE_TO_RESOURCE,
  payload
});

export const deleteReasonCodeCategory = (payload: any) => ({
  type: types.DELETE_REASON_CODE_CATEGORY,
  payload
})

export const unAssignReasonCodeToResource = (payload: any) => ({
  type: types.UNASSIGN_REASON_CODE_TO_RESOURCE,
  payload
});

export const endDownTimeAMD = (payload: any) => ({
  type: types.END_DOWNTIME_AMD,
  payload
});

export const createReasonCodeLogs = (payload: any) => ({
  type: types.CREATE_REASON_CODE_LOGS,
  payload
});

export const deleteReasonCodeLogs = (payload: any) => ({
  type: types.DELETE_REASON_CODE_LOGS,
  payload
});

export const editReasonCodeLogs = (payload: any) => ({
  type: types.EDIT_REASON_CODE_LOGS,
  payload
})
