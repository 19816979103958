import { Modal, Input, DatePicker, message } from "antd";
import { CloseCircleFilled, ClockCircleOutlined } from "@ant-design/icons";
import "./index.scss";
import { Dropdown } from "components/Common/Dropdown/Dropdown";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { TreeSelectDropdown } from "components/Common/MachineIdDropdown";
import { dateToEpoch, findNestedObj } from "utils/common";
import {  editReasonCodeCategory, editReasonCodeLogs } from "store/actions/reasonCodeManagement";

const skuData = [
  {
    category: "1 Ltr",
    value: "1",
    full: 100,
  },
  {
    category: "5 Ltr",
    value: "5",
    full: 100,
  },
  {
    category: "10 Ltr",
    value: "10",
    full: 100,
  },
  {
    category: "15 Ltr",
    value: "15",
    full: 100,
  },
  {
    category: "20 Ltr",
    value: "20",
    full: 100,
  },
  
];
export const AddReasonCodeModal = (props: any) => {
  const { visible, open, onOk, onCancel, onFilterSearchHandler,selectedRowFilterData,fetchData } = props;
  
  const dispatch = useDispatch();
  
  const categoryData = useSelector((state: any) => state.reasonCode.reasonCodeCategoryList);
  
  const factorData = useSelector((state: any) => state.reasonCode.reasonCodeFactor);
  
  const codeData = useSelector((state: any) => state.reasonCode.reasonCodeDetailsDropdown);
  
  const [reasonCodeFactorDropdownData, setreasonCodeFactorDropdownData] = useState<any>();
  const [reasonCodeDropDownData, setreasonCodeDropdownData] = useState<any>([])
  const [payload, setPayload] = useState<any>({
    categoryId: '',
    factorId: '',
    reasonCodeName: '',
    status: true
  })
  const dateFormat = 'DD-MM-YYYY HH:mm:ss';
  
  const [line, setLine] = useState<any>();
  const [lineName, setLineName] = useState<any>("");
  const [sku, setSku] = useState<any>();
  const [batchOrder, setBatchOrder] = useState<any>()
  const [processOrder, setProcessOrder] = useState<any>()
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>( );
  const [reasoncodeId, setReasonCodeId] = useState<any>()
  const [valueName, setValueName] = useState<any>(0)
  const [selectedCategoryValue,setSelectedCategoryValue]=useState<any>({category:'',value:''})
  const [reasonCodeValue, setReasonCodeValue] = useState({category:'',value:'',code_value:''})
  const [selectedFactorValue,setSelectedFactorValue]=useState<any>({category:'',value:''})
  const selectedCategory = (value: any,category:string) => {
    setSelectedCategoryValue({category:category,value:value})
    setPayload({ ...payload, categoryId: value, factorId: '' });
    setreasonCodeFactorDropdownData(factorData[value]);
  }
  const selectedFactor = (value: any, category: string,code_value:any) => {
    setReasonCodeValue({category:category,value:value,code_value:code_value})
    setPayload({ ...payload, factorId: value });
    setreasonCodeDropdownData(codeData[value])
  }
  const selectedCode = (value: string) => {
    setReasonCodeId(value)
  }
  const handleSelectedLine = (data: any) => {
    setLine(data)
  };
  const filterTreeData = useSelector((state: any) => state.root.filterTree);

  const selectedSku = (value: any) => {
    setSku(value)
  }
  const onStartTimeHandler = (time: any, timeString: string) => {
    const getStartTime = dateToEpoch(moment(time._d).format('YYYY-MM-DD h:mm::ss:SSS'));
    setStartTime(getStartTime);
  };
  const onEndTimeHandler = (time: any, timeString: string) => {
    const getEndTime = dateToEpoch(moment(time._d).format('YYYY-MM-DD h:mm::ss:SSS'));
    setEndTime(getEndTime);
  };

  const handleCreate = () => {
    debugger
    if (lineName === "") {
      message.error("Select process Line")
    } else if (startTime === "") {
      message.error("Select startTime")
    } else if (endTime === "") {
      message.error("Select endTime")
    }
    else if(sku==="" ||0){
      message.error("Select SKU")
  }
    else {
    
      dispatch(editReasonCodeLogs({
        stoppageId: selectedRowFilterData.stoppageId,
        processLine: lineName?.toString(), batchNumber: batchOrder, processOrder: processOrder,
        productSku: valueName, startTime: dateToEpoch (startTime), endTime: dateToEpoch(endTime) , reasonCodeId: reasoncodeId,
        parentId: Number(line?.toString())
      }));
      onOk();
      fetchData();
    }
  }
 
  useEffect(() => {
    const machineId = findNestedObj(filterTreeData?.children, 'title', selectedRowFilterData?.equipment)?.value
    setStartTime(moment(selectedRowFilterData?.startDate, dateFormat));
    setEndTime(moment(selectedRowFilterData?.endDate,dateFormat) )
    setProcessOrder(selectedRowFilterData?.batchOrder )
    setSku(selectedRowFilterData?.sku)
    setBatchOrder(selectedRowFilterData?.batchOrder) 
    setLineName(selectedRowFilterData?.fpLine);
    setLine(machineId);
    setSelectedCategoryValue({
      category: selectedRowFilterData?.reasonCodeCategory?.title,
      value: selectedRowFilterData?.reasonCodeCategory?.id
    })
    setSelectedFactorValue({ category: selectedRowFilterData?.reasonCodeFactor?.title, id: selectedRowFilterData?.reasonCodeFactor?.id });
    setReasonCodeValue({
      category: selectedRowFilterData?.reasonCodeDetail?.title,
      value: selectedRowFilterData?.reasonCodeDetail?.id,
      code_value:selectedRowFilterData?.reasonCodeDetail?.code_value
    })
    setReasonCodeId(selectedRowFilterData?.reasonCodeDetail?.code_value)
    // setreasonCodeFactorDropdownData(factorData[selectedRowFilterData?.reasonCodeCategory?.id]);
  }, [selectedRowFilterData?.stoppageId,open])
  return (
    <Modal
      visible={visible}
      centered
      open={open}
      destroyOnClose={true}
      onOk={handleCreate}
      onCancel={onCancel}
      width={528}
      closeIcon={<CloseCircleFilled />}
      wrapClassName="add-reasoncode-modal"
      okText="Update"
    >
      <div className="add-modal-container">
        <div className="title">Add Reason Code</div>
        <div className="input-container">
          <div className="input-content">
            <span>Filling & Packing Line</span>
            <Input placeholder="Filling & Packing Line" value={lineName}  disabled/>

          </div>

          <div className="input-content">
            <span>Batch Order</span>
            <Input placeholder="Batch Order" value={batchOrder} onChange={(e) => setBatchOrder(e.target.value)} disabled />
          </div>
        </div>
        <div className="input-container">
          <div className="input-content">
            <span>Process Order</span>
            <Input
              placeholder="Process Order"
              value={processOrder}
              onChange={(e) => setProcessOrder(e.target.value)} disabled/>
          </div>
          <div className="input-content">
            <span>Product SKU</span>
            <Dropdown
              optionData={skuData}
              placeHolder={"Product SKU"}
              selectedItem={sku}
              selectedValue={selectedSku}
              setValueName={setValueName}
              disabled
              
            />
          </div>
        </div>
        <div className="input-container">
          <div className="input-content">
            <span>Start Time</span>
         
            <DatePicker
              onChange={onStartTimeHandler}
              value={startTime}
              format="YYYY-MM-DD HH:mm:ss"
              suffixIcon={<ClockCircleOutlined />}
              disabled
            />

          </div>
          <div className="input-content">
            <span>End Time</span>
            <DatePicker
              onChange={onEndTimeHandler}
              value={endTime}
              format="YYYY-MM-DD HH:mm:ss"
              suffixIcon={<ClockCircleOutlined />}
              disabled
            />
          </div>
        </div>
        <div className="input-container">
          <div className="input-content">
            <span>Reason Code Category</span>
            <Dropdown
              optionData={categoryData}
              placeHolder={"Reason Code Category"}
              selectedValue={selectedCategory}
              DefaultValue={selectedCategoryValue.category?selectedCategoryValue.category:''}
              />
          </div>
          <div className="input-content">
            <span>Reason Code Factor</span>
            <Dropdown
              optionData={reasonCodeFactorDropdownData}
              placeHolder={"Reason Code Factor"}
              selectedValue={selectedFactor}
              DefaultValue={ selectedFactorValue.category?selectedFactorValue.category:''} />
          </div>
        </div>
        <div className="input-container">
          <div className="input-content">
            <span>Reason Code</span>
            <Dropdown
              data={reasonCodeDropDownData}
              placeHolder={"Reason Code"}
              selectedValue={selectedCode} 
              DefaultValue={reasonCodeValue.category?reasonCodeValue.category:''}
              />
          </div>
        </div>
      </div>
    </Modal>
  );
};
