import { Layout } from "antd";
import { CollapsibleSidebar } from "components/CollapsibleSidebar";
import { HeaderBar } from "components/Header";
import { FooterLink } from "components/FooterLink";
import { useEffect, useState } from "react";
import { routes } from "routes";
import "./pageLayout.scss";
import { Content } from "components/Content";
import { useDispatch } from "react-redux";
import { getFilterTreeData } from "store/actions/rootAction";

const PageLayout: React.FC = () => {
  const { Sider } = Layout;
  const [selectedRoute] = useState(routes);
  const [collapsed, setCollapsed] = useState(true);

  const onCollapse = (sidebarCollapse: any) => {
    setCollapsed(sidebarCollapse);
  };

  // TODO: export this method from /utils
  const braboRedirect = () => {
    const url = window.open(
      "https://brabo-platform.nl-dev.solulever.com/brabo-dashboard",
      "_blank"
    );
    if (url) {
      url.focus();
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFilterTreeData({ id: "2", cloudPushBool: 0 }));
  }, []);

  return (
    <div>
      <HeaderBar />
      <Layout
        className="site-layout-background"
        style={{ flexDirection: "row" }}
      >
        <Sider
          className="sider"
          collapsible
          trigger={null}
          collapsed={collapsed}
          onCollapse={(value: boolean) => setCollapsed(value)}
        >
          <CollapsibleSidebar
            collapsed={collapsed}
            menu={selectedRoute}
            changeSideBar={onCollapse}
          />
          <FooterLink collapsed={collapsed} />
        </Sider>
        <div
          style={{
            backgroundColor: "white",
            width: "-webkit-fill-available",
            marginLeft: collapsed ? "49px" : "225px",
          }}
        >
          <Content />
        </div>
      </Layout>
    </div>
  );
};

export default PageLayout;
