export const fullScreenElement = (elementId: string, state: any, setState: any) => {
  let doc = window.document;
  let docEl: any = doc.getElementById(elementId);
  let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
  let cancelFullScreen = doc.exitFullscreen || docEl.mozCancelFullScreen || docEl.webkitExitFullscreen || docEl.msExitFullscreen;
  if (state) {
    setState(false);
    cancelFullScreen.call(doc);
  }
  if (!doc.fullscreenElement && !docEl.mozFullScreenElement && !docEl.webkitFullscreenElement &&
    !docEl.msFullscreenElement) {
    requestFullScreen.call(docEl);
    if (elementId) {
      setState(true)
    }
  }
}