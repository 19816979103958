import { CustomButton } from "components/Common/Button";
import { CustomShiftTimePicker } from "components/Common/CustomShiftTimePicker";
import { LineOverviewDropdown } from "components/Common/Dropdown/LineOverViewDropdown";
import { TreeSelectDropdown } from "components/Common/MachineIdDropdown";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import moment from "moment";
import { Select, DatePicker, TreeSelect, message } from "antd";
import "./index.scss";
import { ReactComponent as DownArrow } from "assets/icons/dropdownIcon.svg";
import './index.scss';
import { useDispatch, useSelector } from "react-redux";
import { getUrl } from "config/apiconfig";
import { getAlarmsAndEventsDashboardData } from "store/actions/alarmsEvents";
import { getActiveAlarmsTableData } from "store/actions/lineOverview";
import { setFilterValues } from "store/actions/rootAction";
import {  getMachineNodeIds } from "utils/common";
import convertHoursToMinutes from "utils/convertHoursToMinutes";
import dynamicRangeHandler from "utils/dynamicRangeHandler";
import momentTimezone from "moment-timezone";
import { RangeFilters } from "utils/enums";

const data = [
  "Shift A",
  "Shift B",
  "Shift C",
  "Today",
  "Yesterday",
  "This Week",
  "Last Week",
  "MTD",
  "YTD",
];

const { RangePicker } = DatePicker;

export const OeeFilterComponent = (props: any) => {

    const { Option } = Select;
    const skuData = [
        {
            category: "1 Ltr",
            value: "1",
            full: 100,
        },
        {
            category: "5 Ltr",
            value: "5",
            full: 100,
        },
        {
            category: "10 Ltr",
            value: "10",
            full: 100,
        },
        {
            category: "15 Ltr",
            value: "15",
            full: 100,
        },
        {
            category: "20 Ltr",
            value: "20",
            full: 100,
        },
    ];

    let severityRedux = useSelector(
        (state: any) => state.root.filterValues.severity
      );
    
    
      const [sku, setSku] = useState<any>(
        useSelector((state: any) => state.root.filterValues.sku)
      );
  
    
      let startTimeRedux = useSelector(
        (state: any) => state.root.filterValues.startTime
      );
      let endTimeRedux = useSelector(
        (state: any) => state.root.filterValues.endTime
      );
      let selectedRangeRedux = useSelector(
        (state: any) => state.root.filterValues.selectedRange
      );
      let selectedNodeIdsRedux = useSelector(
        (state: any) => state.root.filterValues.nodeId
      );
      const filterTreeData = useSelector((state: any) => state.root.filterTree);
      const nodeArr= getMachineNodeIds(filterTreeData?.children)
    
      const [startTime, setStartTime] = useState(startTimeRedux);
      const [endTime, setEndTime] = useState(endTimeRedux);
      const [selectedValue, setSelectedValue] = useState(
        useSelector((state: any) => state.root.filterValues.selectedRange)
      );

      const [shiftStartTime, setShiftStartTime] = useState(startTimeRedux);
      const [shiftEndTime, setShiftEndTime] = useState(endTimeRedux);

      const datePickerChangeHandler = (value) => {
        if (selectedValue === "Shift A") {
          setStartTime(
            momentTimezone
              .tz(`${value.format("YYYY-MM-DD")} 08:00:00`, "Europe/London")
              .valueOf()
          );
          setEndTime(
            momentTimezone
              .tz(`${value.format("YYYY-MM-DD")} 15:59:59`, "Europe/London")
              .valueOf()
          );
          setShiftStartTime(
            momentTimezone
              .tz(`${value.format("YYYY-MM-DD")} 08:00:00`, "Europe/London")
              .valueOf()
          );
          setShiftEndTime(
            momentTimezone
              .tz(`${value.format("YYYY-MM-DD")} 15:59:59`, "Europe/London")
              .valueOf()
          );
        } else if (selectedValue === "Shift B") {
          let startTime= momentTimezone
          .tz(`${value.format("YYYY-MM-DD")} 16:00:00`, "Europe/London")
          .valueOf();
          setStartTime(startTime);
          setEndTime(
            momentTimezone(
            moment(startTime).add(28799,"seconds"),"Europe/London").valueOf()
          );
          setShiftStartTime(
            startTime
          );
          setShiftEndTime(
            momentTimezone(
              moment(startTime).add(28799,"seconds"),"Europe/London").valueOf()
          );
        } else {
          let startTime= momentTimezone
          .tz(`${value.format("YYYY-MM-DD")} 16:00:00`, "Europe/London").valueOf()
            let startTimeWith8HoursAddition= momentTimezone(
            moment(startTime).add(28800,"seconds"),"Europe/London").valueOf()
            let endTimeWith16HoursAddition= momentTimezone(
              moment(startTime).add(57599,"seconds"),"Europe/London").valueOf()
          setStartTime(
            startTimeWith8HoursAddition
          );
          setEndTime(
            endTimeWith16HoursAddition
          );
          setShiftStartTime(
            startTimeWith8HoursAddition
          );
          setShiftEndTime(
            endTimeWith16HoursAddition
          );
        }
      };
   
    const [treeValue, setTreeValue] = useState<any>( useSelector((state: any) => state.root.filterValues.nodeId));
    useEffect(() => {
      setSelectedValue(selectedRangeRedux);
    }, [selectedRangeRedux]);
    useEffect(() => {
      setTreeValue(selectedNodeIdsRedux);
    }, [selectedNodeIdsRedux]);
  
    useEffect(() => {
      setStartTime(startTimeRedux);
      setEndTime(endTimeRedux);
    }, [startTimeRedux, endTimeRedux]);
 
    const [skuValue, setSkuValue] = useState<any>({
        category: "5 Ltr",
        value:  sku
    },);

    const onChangeSelector = (value: any) => {
        setSelectedValue(value);
    };

    const onChange = (newValue: any, label: any) => {
        setTreeValue(newValue);
    };


    const onClickHandler = () => {
        if (treeValue.length === 0) {
            message.error("Please select at least one machine");
        }else{
            dispatch(
                setFilterValues({
                  key: "sku",
                  value: sku,
                })
              );
              dispatch(
                setFilterValues({
                  key: "startTime",
                  value: startTime,
                })
              );
              dispatch(
                setFilterValues({
                  key: "endTime",
                  value: endTime,
                })
              );
              dispatch(
                setFilterValues({
                  key: "nodeId",
                  value: treeValue,
                })
              );
              dispatch(
                setFilterValues({
                  key: "selectedRange",
                  value: selectedValue,
                })
              );
        props.sendRequest({
            sku: skuValue.value,
            startTime: startTime,
            endTime: endTime,
            nodeId: treeValue
        });
    }
    };


    const dispatch = useDispatch();

    useEffect(()=>{
        let alarmPayload: any = {};
        alarmPayload["startTime"] = startTime;
        alarmPayload["endTime"] = endTime;
        alarmPayload["severity"]="0";
        alarmPayload["page"]=props.page
        alarmPayload["nodeId"]=treeValue
        dispatch(getActiveAlarmsTableData(alarmPayload));
      },[props.page])

      useEffect(() => {
        dynamicRangeHandler(selectedValue, rangeHandler,shiftStartTime,setShiftStartTime,setShiftEndTime);
      }, [selectedValue]);

    const handleReset = () => {
        setSkuValue(skuData[1]);
        setSku(5);
        rangeHandler([moment(new Date()).subtract(1, 'days'), moment(new Date())])
        onChangeSelector("This Week")
        setTreeValue([])
        props.sendRequest({
            sku: 5,
            startTime: moment(new Date()).valueOf(),
            endTime: moment(new Date()).subtract(7, "days").valueOf(),
            nodeId: []
        });
        dispatch(
            setFilterValues({
              key: "sku",
              value: "5",
            })
          );
          dispatch(
            setFilterValues({
              key: "startTime",
              value: moment(moment().startOf("week")).valueOf(),
            })
          );
          dispatch(
            setFilterValues({
              key: "endTime",
              value: moment(new Date()).valueOf(),
            })
          );
          dispatch(
            setFilterValues({
              key: "lineObject",
              value: [],
            })
          );
          dispatch(
            setFilterValues({
              key: "nodeId",
              value: nodeArr,
            })
          );
          dispatch(
            setFilterValues({
              key: "severity",
              value: "0",
            })
          );
          dispatch(
            setFilterValues({
              key: "selectedRange",
              value: "This Week",
            })
          );
    };

    useEffect(() => {
      setStartTime(startTimeRedux);
      setEndTime(endTimeRedux);
    }, [startTimeRedux, endTimeRedux]);

    const onChangeSKU = (value: any) => {
        setSku(value);
        let SkuObj: any = skuData.filter(item => item.value === value)
        setSkuValue(SkuObj[0]);
    };

    const rangeHandler = (value) => {
          setStartTime(moment(value[0]).valueOf());
          setEndTime(moment(value[1]).valueOf());
      };

    return (
        <>
            <div className="filtercomponent-container">
            
                <div className="filtercomponent-left-container">
               
                    <TreeSelect
                        showSearch
                        style={{
                            width: "336px",
                            height: "30px",
                        }}
                        value={treeValue}
                        dropdownStyle={{
                            maxHeight: 400,
                            overflow: "auto",
                        }}
                        placeholder="Select Line/Machine"
                        allowClear
                        treeDefaultExpandAll
                        onChange={onChange}
                        treeCheckable={true}
                        showArrow
                        suffixIcon={<DownArrow />}
                        maxTagCount={1}
                        defaultValue={treeValue}
                        treeData={filterTreeData?.children ? filterTreeData.children : []}
                    />
                   
                    <Select
                        style={{
                            width: "84px",
                            height: "30px",
                        }}
                        value={skuValue}
                        showSearch
                        placeholder={"Select SKU"}
                        optionFilterProp="children"
                        onChange={onChangeSKU}
                        filterOption={true}
                        suffixIcon={<DownArrow />}
                    >
                        {skuData?.map((item: any) => {
                            return (
                                <>
                                    <Option value={item.value}>{item.category}</Option>
                                </>
                            );
                        })}
                        ;
                    </Select>

                    <Select
                        style={{
                            width: "200px",
                            height: "30px",
                        }}
                        showSearch
                        placeholder={"YTD"}
                        optionFilterProp="children"
                        value={selectedValue}
                        onChange={(value) => onChangeSelector(value)}
                        filterOption={true}
                    >
                        {data?.map((item: any) => {
                            return (
                                <>
                                    <Option value={item}>{item}</Option>
                                </>
                            );
                        })}
                        ;
                    </Select>
                {!selectedValue.includes("Shift") ? (
                    <RangePicker
                        suffixIcon={<ClockCircleOutlined />}
                        allowClear={true}
                        format={"DD-MM-YYYY HH:mm:ss"}
                        showTime
                        className={"rangepicker"}
                        value={[moment(startTime), moment(endTime)]}
                        onChange={rangeHandler}
                    ></RangePicker>)
                    : (
                      <>
                        <DatePicker
                            //Note : As per discussion with Devendra this is done specific to UPL
                        value={selectedValue===RangeFilters.SHIFT_C? moment(shiftStartTime).subtract(1,"days"):moment(shiftStartTime)}
                          onChange={datePickerChangeHandler}
                        />
                        <div className="shift">
                          {moment(shiftStartTime).format("DD-MM-YYYY HH:mm:ss")} to{" "}
                          {moment(shiftEndTime).format("DD-MM-YYYY HH:mm:ss")}
                        </div>
                      </>
                    )}
                </div>
                <div className="dropdownFiltercard-right-container flex-gap">
                    <CustomButton type="Apply" onClick={onClickHandler} />
                    <CustomButton type="Reset" onClick={handleReset} />
                    <CustomButton type="Download" onClick={props.download?props.download:null}/>
                </div>
            </div>
        </>
    );
};
