import { all, call, put, takeLatest } from "redux-saga/effects";
import * as types from "store/actions/actionTypes";
import { AssetModelService } from "store/services/assetModelService";

export function* getTreeFilterData({ type: string, payload }) {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const assetModelService = AssetModelService.getInstance();
    const result = yield call(
      [assetModelService, assetModelService.getTagMappingTree],
      payload.id,
      payload.cloudPushBool
    );
    yield put({ type: types.LOAD_FILTER_TREE_DATA_SUCCESS, payload: result });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* globalSaga() {
  yield all([takeLatest(types.LOAD_FILTER_TREE_DATA, getTreeFilterData)]);
}
