import { ProgressBarProps } from "types";
import "./index.scss";

const StatisticsDataCard: React.FC<ProgressBarProps> = ({
  value,
  variableName,
}) => {
  const color = "47,84,235";

  return (
    <div
      className="container"
      style={{
        border: `1px solid rgb(${color})`,
      }}
    >
      <div
        style={{
          backgroundColor: `rgba(${color},0.20)`,
          borderRadius: 12,
        }}
      >
        <div
          className="progress-bar-variable"
          style={{ color: `rgb(${color})` }}
        >
          {variableName}
        </div>
      </div>
      <div className="progress-bar-value" style={{ color: `rgb(${color})` }}>
        {value}
      </div>
    </div>
  );
};

export default StatisticsDataCard;
