import { Tabs } from "antd";
import "./tabs.scss";
import "./index.scss";
import Logs from "./Logs";
import Overview from "./Overview";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAMDList } from "store/actions/reasonCodeManagement";

const ReasonCodeLandingPage: React.FC = () => {
  const items = [
    {
      label: `Overview`,
      key: "overview",
      children: <Overview />,
    },
    {
      label: `Logs`,
      key: "logs",
      children: <Logs />,
    },
  ];
  const dispatch = useDispatch();

  const assignReasonCodeLoadingStatus = useSelector(
    (state: any) => state.operator.assignReasonCodeLoading
  );

  useEffect(() => {
    dispatch(
      getAMDList({
        shiftInfo: 0,
        startTime: 0,
        endTime: Date.now(),
        searchText: "",
      })
    );
  }, [dispatch, assignReasonCodeLoadingStatus]);
  return (
    <>
      <div className="reason-code-tabs">
        <Tabs defaultActiveKey="1">
          {items.map((item, index) => {
            return (
              <Tabs.TabPane tab={item.label} key={item.key}>
                {item.children}
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>
    </>
  );
};

export default ReasonCodeLandingPage;
