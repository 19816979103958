// source: common/Enums.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.AggregationMethod', null, global);
goog.exportSymbol('proto.ApiStatusCode', null, global);
goog.exportSymbol('proto.CloudPushEligible', null, global);
goog.exportSymbol('proto.DataTypeEnum', null, global);
goog.exportSymbol('proto.KafkaRecordType', null, global);
goog.exportSymbol('proto.ModelType', null, global);
goog.exportSymbol('proto.Quality', null, global);
goog.exportSymbol('proto.SortOrder', null, global);
goog.exportSymbol('proto.TagOrigin', null, global);
goog.exportSymbol('proto.TagType', null, global);
goog.exportSymbol('proto.TimeInterval', null, global);
/**
 * @enum {number}
 */
proto.ModelType = {
  ISA88_95: 0,
  FLEXIBLE: 1
};

/**
 * @enum {number}
 */
proto.DataTypeEnum = {
  STRING: 0,
  FLOAT: 1,
  INTEGER: 2,
  DATETIME: 3,
  DATE: 4,
  CUSTOM: 5
};

/**
 * @enum {number}
 */
proto.TimeInterval = {
  FOREVER: 0,
  LAST_1_HOUR: 1,
  LAST_24_HOURS: 2,
  LAST_7_DAYS: 3,
  LAST_1_MONTH: 4,
  LAST_3_MONTHS: 5,
  LAST_6_MONTHS: 6,
  LAST_1_YEAR: 7
};

/**
 * @enum {number}
 */
proto.SortOrder = {
  ASCENDING: 0,
  DESCENDING: 1
};

/**
 * @enum {number}
 */
proto.TagType = {
  DEFAULT: 0,
  OPC_ALARM: 1
};

/**
 * @enum {number}
 */
proto.TagOrigin = {
  PLC: 0,
  USER: 1
};

/**
 * @enum {number}
 */
proto.AggregationMethod = {
  AVERAGED: 0,
  ADDITIVE: 1,
  MAX: 2,
  MIN: 3
};

/**
 * @enum {number}
 */
proto.Quality = {
  GOOD: 0,
  BAD: 1
};

/**
 * @enum {number}
 */
proto.CloudPushEligible = {
  ALL: 0,
  YES: 1,
  NO: 2
};

/**
 * @enum {number}
 */
proto.KafkaRecordType = {
  TAG: 0,
  NODE: 1,
  MODEL: 2
};

/**
 * @enum {number}
 */
proto.ApiStatusCode = {
  SUCCESS: 0,
  STREAM_GETLIST_FAILED: 1,
  STREAM_UPDATE_NODE_MAPPING_FAILED: 2,
  STREAM_DELETE_FAILED: 3,
  STREAM_GETDETAILS_FAILED: 4,
  NODE_GETTREE_FAILED: 5,
  NODE_UPSERT_FAILED: 6,
  NODE_DELETE_FAILED: 7,
  NODE_GETDETAILS_FAILED: 8,
  MODEL_GETLIST_FAILED: 9,
  MODEL_UPSERT_FAILED: 10,
  MODEL_DELETE_FAILED: 11,
  MODEL_GETDETAILS_FAILED: 12,
  EQUIPMENT_GETLIST_FAILED: 13,
  EQUIPMENT_GETDETAILS_FAILED: 14,
  EQUIPMENT_UPSERT_FAILED: 15,
  EQUIPMENT_DELETE_FAILED: 16,
  EQUIPMENT_DUPLICATE_FAILED: 17,
  EQUIPMENTCATEGORY_UPSERT_FAILED: 18,
  EQUIPMENTCATEGORY_DELETE_FAILED: 19,
  EQUIPMENTATTRIBUTE_UPSERT_FAILED: 20,
  EQUIPMENTATTRIBUTE_DELETE_FAILED: 21,
  NODETYPE_GETLIST_FAILED: 22,
  NODETYPE_UPSERT_FAILED: 23,
  NODETYPE_GETDETAILS_FAILED: 24,
  NODETYPE_DELETE_FAILED: 25,
  NODETYPE_DUPLICATE_FAILED: 26,
  NODETYPE_CATEGORY_UPSERT_FAILED: 27,
  NODETYPE_CATEGORY_DELETE_FAILED: 28,
  NODETYPE_ATTRIBUTE_UPSERT_FAILED: 29,
  NODETYPE_ATTRIBUTE_DELETE_FAILED: 30,
  GET_TIMESTAMP_AGGREGATED_DATA_FAILED: 31,
  GET_TIMESTAMP_RAW_DATA_FAILED: 32,
  GET_TIMESTAMP_LAST_DATA_FAILED: 33,
  GET_TIMESTAMP_RAW_EXCEL_FAILED: 34,
  GET_TIMESTAMP_RAW_CSV_FAILED: 35,
  GET_MAPPEDTAGS_FAILED: 36,
  CLOSE_MAPPEDTAGS_STREAMING_FAILED: 37,
  GET_DISTINCT_PARENT_NODES_FAILED: 38,
  GET_DISTINCT_EU_FAILED: 39,
  GET_DISTINCT_DATATYPES_FAILED: 40,
  GET_NODE_TYPE_LIST_FOR_NODE_FAILED: 41,
  STREAM_TIMESTAMP_RAWDATA_FAILED: 42,
  CLOSE_TIMESERIES_RAWDATA_STREAMING_FAILED: 43,
  GET_TIMESERIES_STATS_FAILED: 44,
  GET_TIMESERIES_CYCLICDATA_FAILED: 45,
  GET_CHILD_NODE_ID_LIST_FAILED: 46,
  GET_CHILD_NODE_LIST_FAILED: 47,
  NODE_DELETE_ROOT_NODE_NOT_ALLOWED: 48,
  STREAM_UPSERT_FAILED: 49,
  DOWNLOAD_PARAMETER_TEMPLATE_FAILED: 50,
  UPLOAD_PARAMETER_TEMPLATE_FAILED: 51,
  GET_STREAM_DETAILS_BY_NAME_FAILED: 52,
  GET_TAG_TIMESERIES_INFO_FAILED: 53,
  GET_TAG_TIMESERIES_INFO_WITH_VALUE_FAILED: 54
};

goog.object.extend(exports, proto);
