import { all, call, put, takeLatest } from "redux-saga/effects";
import * as types from 'store/actions/actionTypes';
import { OperatorService } from "store/services/operatorService";

export function* getProcessLineInfoSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const operatorService = OperatorService.getInstance();
    const processLineList = yield call([operatorService, operatorService.getProcessLines]);
    yield put({ type: types.GET_PROCESS_LINE_INFO_SUCCESS, payload: processLineList });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getBatchesByLineSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const operatorService = OperatorService.getInstance();
    const currentLineDetails = yield call([operatorService, operatorService.getBatchesByLine], payload);
    yield put({ type: types.GET_BATCHES_BY_LINE_SUCCESS, currentLineDetails });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

const updateStoppageDetails=(amdList:any, stoppageId:any, reasonCodeCategory:any, reasonCodeFactor:any, reasonCodeDetail:any)=> {
  return amdList.map((item :any)=> {
    if (item.stoppageId === stoppageId) {
      return {
        ...item,
        reasonCode:reasonCodeDetail.category,
        reasonCodeCategory: reasonCodeCategory,
        reasonCodeFactor:reasonCodeFactor,
        reasonCodeDetail:reasonCodeDetail
      };
    }
    return item;
  });
}

export function* assignOperatorLogSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const operatorService = OperatorService.getInstance();
    yield call([operatorService, operatorService.assignOperatorLog], payload);
    const {categoryObject,factorObject,codeObject}=payload;
    const amdList=payload.amdList;
    const updatedAmdList= updateStoppageDetails(amdList, payload.stoppageIdList[0],categoryObject,factorObject,codeObject)
    yield put({ type: types.ASSIGN_OPERATOR_LOG_SUCCESS, payload: updatedAmdList });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getMachineStatusSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    const operatorService = OperatorService.getInstance();
    const machineStatus = yield call([operatorService, operatorService.getMachineStatus]);
    yield put({ type: types.GET_MACHINE_STATUS_SUCCESS, machineStatus });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* operatorSaga() {
  yield all([
    takeLatest(types.GET_PROCESS_LINE_INFO, getProcessLineInfoSaga),
    takeLatest(types.GET_BATCHES_BY_LINE, getBatchesByLineSaga),
    takeLatest(types.ASSIGN_OPERATOR_LOG, assignOperatorLogSaga),
    takeLatest(types.GET_MACHINE_STATUS, getMachineStatusSaga)

  ]);
}