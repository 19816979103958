import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import { GuageChartDataProps } from "types";

const Guage: React.FC<GuageChartDataProps> = ({
  chartID,
  data,
  label = "",
  radius = 120,
}) => {
  useLayoutEffect(() => {
    let root = am5.Root.new(chartID);

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: -180,
        endAngle: 0,
        innerRadius: -30,
        radius: am5.percent(radius),
      })
    );
    chart.children.unshift(
      am5.Label.new(root, {
        text: label,
        fontSize: 14,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        y: am5.percent(85),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    let axisRenderer = am5radar.AxisRendererCircular.new(root, {
      strokeOpacity: 0.0,
      minGridDistance: 40,
      innerRadius: -10,
      strokeWidth: 8,
    });

    axisRenderer.ticks.template.setAll({
      visible: true,
      strokeOpacity: 0.3,
      inside: true,
      minPosition: 0.01,
      length: 10,
    });

    axisRenderer.grid.template.setAll({
      visible: false,
    });

    axisRenderer.labels.template.setAll({
      textType: "adjusted",
      inside: true,
      radius: 25,
    });
    axisRenderer.grid.template.set("strokeOpacity", 1);

    let axis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: data? data + ((data*25)/100) : 2000,
        strictMinMax: true,
        renderer: axisRenderer,
      })
    );

    function createRange(start: any, end: any, color: any, label: any) {
      var rangeDataItem: any = axis.makeDataItem({
        value: start,
        endValue: end,
      });

      axis.createAxisRange(rangeDataItem);

      rangeDataItem.get("axisFill").setAll({
        visible: true,
        fill: color,
        fillOpacity: 0.8,
      });

      rangeDataItem.get("tick").setAll({
        visible: true,
      });

      rangeDataItem.get("label").setAll({
        text: label,
        inside: true,
        radius: 5,
        fontSize: "0.9em",
        fill: am5.color(0xffffff),
      });
    }
    const range = data ? data + ((data*25)/100) : 2000;
    createRange(0, data, am5.color("#2F54EB"), "");
    createRange(data, range, am5.color("#F0F0F0"), "");
    root?._logo?.dispose();

    return () => root.dispose();
  }, [chartID, data]);

  return <div style={{ width: "100%", height: "100%" }} id={chartID}></div>;
};
export default Guage;
