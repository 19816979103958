import { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import * as am5xy from "@amcharts/amcharts5/xy";
import { ChartDataProps } from "types";

const Bar: React.FC<ChartDataProps> = ({ chartID, data }) => {
  useLayoutEffect(() => {
    var root = am5.Root.new(chartID);

    root.setThemes([am5themes_Animated.new(root)]);
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        layout: root.verticalLayout,
      })
    );

    chart
      ?.get("colors")
      ?.set("colors", [
        am5.color("#67B7DC"),
        am5.color("#6794DC"),
        am5.color("#6771DC"),
      ]);
    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    xRenderer.labels.template.setAll({
      centerY: am5.p50,
      centerX: am5.p50,
      paddingRight: 15,
      fill:am5.color("#ffffff")
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "country",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        max: 100,
        min: 0,
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {}),
        numberFormat: "#'%'",
      })
    );

    // am5xy.CategoryAxis.renderer.grid.template.disabled = true;

    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        sequencedInterpolation: true,
        categoryXField: "country",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}",
        }),
      })
    );

    series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
    series.columns.template.adapters.add("fill", function (fill, target) {
      return chart?.get("colors")?.getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add("stroke", function (stroke, target) {
      return chart?.get("colors")?.getIndex(series.columns.indexOf(target));
    });

    xAxis.data.setAll(data);
    series.data.setAll(data);

    series.appear();
    chart.appear(1000, 100);
    root?._logo?.dispose();

    return () => root.dispose();
  }, [chartID, data]);

  return <div style={{ width: "100%", height: "100%" }} id={chartID}></div>;
};
export default Bar;
