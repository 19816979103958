import { AlarmEvents } from "pages/AlarmEvents/AlarmEvents";
import { DataVisualization } from "pages/DataVisualization";
import { LineOverview } from "pages/LineOverview";
import Oee from "pages/OEE";
import ProductionOverview from "pages/ProductionOverview";
import RuntimeAnalysis from "pages/RuntimeAnalysis";
import { ReasonCode } from "pages/ReasonCodeManagement";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "pages/Dashboard";
import ProcessOrderSummary from "pages/ProcessOrderSummary";

export const Content = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />}></Route>
      <Route path="/production-overview" element={<ProductionOverview />}></Route>
      <Route path="/line-overview" element={<LineOverview />} />
      <Route path="/oee" element={<Oee />} />
      <Route path={"/alarm-and-events"} element={<AlarmEvents />} />
      <Route path={"/data-visualization"} element={<DataVisualization />} />
      <Route path={"/runtime-analysis"} element={<RuntimeAnalysis />} />
      <Route path={"/reason-code-management"} element={<ReasonCode />} />
      <Route path={"/process-order-summary"} element={<ProcessOrderSummary/>} />

      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
};
