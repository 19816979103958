import { TreeSelect, Select, Checkbox, Table, message } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getModelsList, getTagMappingTree, getAllTags } from 'store/actions/dataVisualization.actions';

export interface DeviceDescData {
  tagName: string;
  id: string;
  description: string;
  key: string;
}

export const AssignmentExpandedView = (props: any) => {
  const { Option } = Select;
  const { TreeNode } = TreeSelect;
  const { setEquipmentId } = props;

  const [modelName, setModelName] = useState<any>();
  const [AssetHeirarchyTree, setAssetHeirarchyTree] = useState<any>();
  const [nodeName, setNodeName] = useState<any>("");

  const selectedParent = useRef<string>('');
  const dispatch = useDispatch();

  const modelList = useSelector((state: any) => state.dataVisualizationReducer.modelList);
  const assetHeirarchyTree = useSelector(
    (state: any) => state.dataVisualizationReducer.tagMappingTree
  );

  useEffect(() => {
    dispatch(getModelsList({}));
  }, []);
  useEffect(() => {
    if (assetHeirarchyTree.key) {
      let assetTree: Array<any> = [];
      assetTree.push(assetHeirarchyTree);
      let currentTree = createTree(assetTree);
      setAssetHeirarchyTree(currentTree);
    }
  }, [assetHeirarchyTree]);

  const onChange = (value: any) => {
    if (value !== undefined) {
      selectedParent.current = value[value.length - 1];
      dispatch(getAllTags({
        status: 0
      }));
      setNodeName(value);
      setEquipmentId(value)
    } else {
      setNodeName(null);
    }
  };
  const handleModelSelected = (selectedModel: number) => {
    if (selectedModel !== undefined) {
      dispatch(getTagMappingTree({ id: selectedModel, cloudPushBool: 0 }));
      modelList.forEach((currentModel: any) => {
        if (currentModel.key === selectedModel) {
          setModelName(currentModel.modelName);
        }
      });
    } else {
      setModelName(null);
    }
  };

  const onChangeCheck = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };
  const createTree: any = (treeStruct: Array<any>) => {
    let currentTree = treeStruct.map((element) => {
      return (
        <TreeNode value={element.key} title={element['title']} key={element['key']}>
          {element['children'] ? createTree(element['children']) : null}
        </TreeNode>
      );
    });
    return currentTree;
  };

  return (
    <div className="resource-dropdown">
      <Select
        placeholder="Select Model"
        className="tag-filter-options"
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        onChange={handleModelSelected}
        allowClear={!!modelName}
        value={modelName ? modelName : null}
      >
        {modelList.map((item: any) => {
          return (
            <Option value={item['key']} key={item['key']}>
              <div className='option-text'>
                <Checkbox onChange={onChangeCheck}></Checkbox>
                <div>{item['modelName']}</div>
              </div>
            </Option>
          );
        })}
      </Select>
      <TreeSelect
        showSearch
        style={{ width: '100%' }}
        value={nodeName ? nodeName : null}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder="UPL Sandbach"
        allowClear={nodeName ? true : false}
        onChange={onChange}
      >
        {AssetHeirarchyTree}
      </TreeSelect>

    </div>
  );
};
