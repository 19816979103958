import initialState from './initalState';
import * as types from 'store/actions/actionTypes';

export default function (state = initialState.tagsDataTrending, action: any) {
    switch (action.type) {
        case types.SET_GRAPH_TYPE_SUCCESS:
            return { ...state, graphType: action.graphType };
        case types.GET_AGGREGATED_DATA_FOR_TAG_SUCCESS:
            return { ...state, graphData: action.graphData };
        case types.GET_TAG_OVERVIEW_DATA_SUCCESS:
            return {
                ...state,
                ...{
                    tagsOverviewData: {
                        ...state.tagsOverviewData,
                        ...action.tagOverviewData,
                    },
                },
            };
        case types.UPDATE_SELECTED_GRAPH_TAGS_SUCCESS:
            return { ...state, selectedGraphTags: action.selectedGraphTags };
        case types.GET_ALL_TAGS_SUCCESS:
            return { ...state, tagList: action.payload };
        case types.GET_MODELS_LIST_SUCCESS:
            return { ...state, modelList: action.payload };
        case types.GET_TAGMAPPING_TREE_SUCCESS:
            return { ...state, tagMappingTree: action.payload };
        case types.GET_TAG_OVERVIEW_DATA_FOR_DATA_TABLE_SUCCESS:
            return { ...state, tableList: action.tagData };
        case types.GET_RAW_CSV_DATA_SUCCESS:
            return { ...state, rawCsvString: action.rawCSVData };
        default:
            return state;
    }
}