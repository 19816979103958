import * as types from "./actionTypes";

export const getProductionOverviewCountData = (payload: any) => ({
  type: types.GET_PRODUCTION_OVERVIEW_COUNT_DATA,
  payload,
});

export const getProductionOverviewBatchData = (payload: any) => ({
  type: types.GET_PRODUCTION_OVERVIEW_BATCH_DATA,
  payload,
});

export const getProductionOverviewAlarmsData = (payload: any) => ({
  type: types.GET_PRODUCTION_OVERVIEW_ALARMS_DATA,
  payload,
});

export const getProductionOverviewOeeData = (payload: any) => ({
  type: types.GET_PRODUCTION_OVERVIEW_OEE_DATA,
  payload,
});

export const getProductionOverviewAverageCycleTimeData = (payload: any) => ({
  type: types.GET_PRODUCTION_OVERVIEW_AVERAGE_CYCLE_TIME_DATA,
  payload,
});

export const getProductionOverviewAssetUtilizationData = (payload: any) => ({
  type: types.GET_PRODUCTION_OVERVIEW_ASSET_UTILIZATION_DATA,
  payload,
});

export const getProductionOverviewTableData = (payload: any) => ({
  type: types.GET_PRODUCTION_OVERVIEW_TABLE_DATA,
  payload,
});

export const getProductionOverviewParetoData = (payload: any) => ({
  type: types.GET_PRODUCTION_OVERVIEW_PARETO_DATA,
  payload,
});

export const getProductionOverviewTableCardData = (payload: any) => ({
  type: types.GET_PRODUCTION_OVERVIEW_TABLE_CARD_DATA,
  payload,
});

export const getProductionOverviewPackagingData = (payload: any) => ({
  type: types.GET_PRODUCTION_OVERVIEW_PACKAGING_DATA,
  payload,
});

export const getProductionOverviewMachineStatus = () => ({
  type: types.GET_PRODUCTION_OVERVIEW_MACHINE_STATUS,
});
export const getPPAllBatches = (payload:any) => ({
  type: types.GET_PPALL_BATCHES_DATA,
  payload

})
export const updateRejejectCount = (payload: any) => ({
  type: types.UPDATE_REJECT_COUNT,
  payload
})
export const updateRejejectCountSuccess = (payload: any) => ({
  type: types.UPDATE_REJECT_COUNT_SUCCESS,
  payload
})
