import { Tabs } from "antd";
import ContentHeader from "components/ContentHeader";
import { DropdownFilterComponent } from "components/DropdownFilterComponent";
import Dashboard from "./Dashboard";
import "styles/tabs.scss";
import Details from "./Details";
import { useDispatch } from "react-redux";
import { setFilterUpdatedFlag } from "store/actions/rootAction";

const RuntimeAnalysis: React.FC = () => {
  const items = [
    {
      label: `Dashboard`,
      key: "1",
      children: <Dashboard />,
    },
    {
      label: `Details`,
      key: "2",
      children: <Details />,
    },
  ];
  const dispatch = useDispatch();

  return (
    <>
      <ContentHeader  heading="Runtime Analysis" />
      <div className="tabs-wrapper-container">
        <Tabs defaultActiveKey="1" onChange={(activeKey:any)=>dispatch(setFilterUpdatedFlag())}>
          {items.map((item, index) => {
            return (
              <>
                <Tabs.TabPane tab={item.label} key={item.key}>
                  {item.children}
                </Tabs.TabPane>
              </>
            );
          })}
        </Tabs>
      </div>
    </>
  );
};

export default RuntimeAnalysis;
