import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { dataLoader } from "@amcharts/amcharts4/core";

export const DowntimeChart = ({ data }) => {
  let colors = data.length>6?["#722ED1", "#FFC069", "#5CDBD3", "#FF7875", "#FF0087", "#87009D","#DCDCDE"]:["#722ED1", "#FFC069", "#5CDBD3", "#FF7875", "#FF0087", "#87009D"];
  
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdivDowntimeChart");

    root.setThemes([am5themes_Animated.new(root)]);
    
    let dataWithColors: any = [];
    if (data && data.length > 0) {
      data.forEach((element: any, index: number) => {
        dataWithColors.push({
          ...element,
          columnSettings: {
            fill: colors[index],
          },
        });

      });
    }

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        marginTop:15,
        innerRadius: am5.percent(50),
        radius: am5.percent(90)
      })
    );

    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        alignLabels: false,
       
      })
    );

    series.labels.template.setAll({
      textType: "circular",
      centerX: 0,
      centerY: 0
    });
    series.slices.template.setAll({
      templateField: "columnSettings",
      strokeWidth: 0,
      stroke: am5.color(0xffffff),
    });
    series.data.setAll(dataWithColors);

  
    let legend = chart.children.push(am5.Legend.new(root, {
      x: am5.percent(50),
      centerX: am5.percent(50),
      marginTop: 15,
      layout: root.gridLayout ,
      fill: root.interfaceColors.get("alternativeBackground"),
      clickTarget: "none"
    }));
    
    legend.data.setAll(series.dataItems);

    series.appear(100, 100);
    series.ticks.template.set("visible", false);
    series.labels.template.set("visible", false);
    series.slices.template.set("toggleKey", "none");
    root?._logo?.dispose();

    return () => {

      root.dispose();
    };

  }, [data]);
  return (
    <div
      id="chartdivDowntimeChart"
      style={{ width: "100%", height: "100%" }}
    ></div>
  );

};
