import { Divider } from "antd";
import camelToTitle from "utils/camelToTitle";
import "./index.scss";

const StatisticsTable = (props: any) => {
  const data = props.data;

  const getColumnCount = () => {
    if (data && data.length && data[0].data) {
      return Object.keys(data[0].data[0]).length;
    }
    return 0;
  };

  const getColumns = () => {
    let keys: string[] = [];
    if (data && data.length && data[0].data) {
      keys = Object.keys(data[0].data[0]);
      return keys.map((item: string) => {
        return (
          <div key={item} className="child-col">
            <span className="title">{camelToTitle(item)}</span>
          </div>
        );
      });
    } else return <></>;
  };

  const displayTableData = () => {
    return data.map((item: any) => {
      return (
        <>
          <Divider>
            <div className="text-box">{item.row}</div>
          </Divider>
          {item.data && item.data.length
            ? item.data.map((rowData: any) => {
                return (
                  <>
                    <div
                      className="row-container"
                      style={{
                        gridTemplateColumns: `repeat(${getColumnCount()}, 1fr)`,
                      }}
                    >
                      {Object.values(rowData).map((itemValue: any) => {
                        return (
                          <div
                            key={itemValue + Math.floor(1000 + Math.random() * 9000) } // Generating unique key
                            className={`row-item ${itemValue.toLowerCase()}`}
                          >
                            {itemValue}
                          </div>
                        );
                      })}
                    </div> 
                  </>
                );
              })
            : null}
        </>
      );
    });
  };

  return (
    <div className="statistics-container">
      <div className="table-header">
        <div
          className="parent"
          style={{
            gridTemplateColumns: `repeat(${getColumnCount()}, 1fr)`,
          }}
        >
          {getColumns()}
        </div>
      </div>
      <div className="row-wrapper">{displayTableData()}</div>
    </div>
  );
};

export default StatisticsTable;
