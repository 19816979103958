import initialState from "./initalState";
import * as types from "store/actions/actionTypes";
import {  getMachineNodeIds } from "utils/common";

export default function rootReducer(state = initialState.root, action: any) {
  switch (action.type) {
    case types.SHOW_LOADER_SUCCESS:
      return { ...state, showLoader: action.showLoader };
    case types.LOAD_FILTER_TREE_DATA_SUCCESS: {
      return { ...state, filterTree: action.payload, filterValues: {...state.filterValues, nodeId: getMachineNodeIds(action.payload.children)} };
      
          }
    case types.SET_FILTER_VALUES: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case types.SET_FILTER_UPDATED_FLAG: {
      return {
        ...state,
        filterFlag: !state.filterFlag,
      };
    }
    case types.SET_FILTER_VALUES_DATA_VISUALIZATION: {
      return {
        ...state,
        filterValuesDataVisualization: {
          ...state.filterValuesDataVisualization,
          [action.payload.key]: action.payload.value,
        },
      };
    }
  }
  return state;
}
