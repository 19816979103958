import { all, call, put, takeLatest } from "redux-saga/effects";
import * as types from 'store/actions/actionTypes';
import { LineOverviewService } from "store/services/lineOverviewService";
import { AlarmsEventsService } from "store/services/alarmsEventsService";



export function* getOEEData({ type, payload }) {

    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });

        const lineOverviewService = LineOverviewService.getInstance();

        const data = yield call(
            [lineOverviewService, lineOverviewService.getOEEData],
            payload
        );


        yield put({
            type: types.GET_LINE_OEE_SUCCESS,
            data,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });

    } catch (error) {
        yield put({ type: 'GET_LINE_OEE_ERROR', error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getTeepMatrixData({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const lineOverviewService = LineOverviewService.getInstance();
        const data = yield call(
            [lineOverviewService, lineOverviewService.getTeepMatrixData],
            payload
        );

        if (data.length) {
            yield put({
                type: types.GET_TEEP_MATRIX_BY_LINE_SUCCESS,
                data
            });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: 'GET_TEEP_MATRIX_BY_LINE_ERROR', error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getDowntimeByLine({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const lineOverviewService = LineOverviewService.getInstance();
        const data = yield call(
            [lineOverviewService, lineOverviewService.getDownTimeData],
            payload
        );
        if (data.length) {
            yield put({
                type: types.GET_DOWNTIME_BY_LINE_SUCCESS,
                data,
            });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: 'GET_DOWNTIME_BY_LINE_ERROR', error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getRunTimeDownTime({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const lineOverviewService = LineOverviewService.getInstance();
        const data = yield call(
            [lineOverviewService, lineOverviewService.fetchRuntimeDowntime],
            payload
        );
        if (data.length) {
            yield put({
                type: types.GET_RUNTIME_DOWNTIME_SUCCESS,
                data
            });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: 'GET_RUNTIME_DOWNTIME_ERROR', error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getRateMatrixByLine({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const lineOverviewService = LineOverviewService.getInstance();
        const data = yield call(
            [lineOverviewService, lineOverviewService.getRateMatrixData],
            payload
        );
        if (data.length) {
            yield put({
                type: types.GET_RATE_MATRIX_SUCCESS,
                data,
            });

            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: 'GET_RATE_MATRIX_ERROR', error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getLostTimeMatrix({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const lineOverviewService = LineOverviewService.getInstance();
        const data = yield call(
            [lineOverviewService, lineOverviewService.getLostTimeMatrixData],
            payload
        );
        if (data.length) {
            yield put({
                type: types.GET_LOST_TIME_MATRIX_SUCCESS,
                data,
            });
            yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
        }
    } catch (error) {
        yield put({ type: 'GET_LOST_TIME_MATRIX_ERROR', error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getActiveAlarmsTableData({
    type,
    payload,
  }: {
    type: string;
    payload: any;
  }): Generator<any> {
    try {
      yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
      const alarmsEventsService = AlarmsEventsService.getInstance();
      const alarmsEventsDetails = yield call(
        [alarmsEventsService, alarmsEventsService.getAlarmsTableData],
        payload
      );
      yield put({
        type: types.GET_LINE_OVERVIEW_TABLE_DATA_SUCCESS,
        payload: alarmsEventsDetails,
      });
      yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error) {
      yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
  }

  export function* getActiveAlarmsTableCardData({
    type,
    payload,
  }: {
    type: string;
    payload: any;
  }): Generator<any> {
    try {
      yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
      const alarmsEventsService = AlarmsEventsService.getInstance();
      const guageData = yield call(
        [alarmsEventsService, alarmsEventsService.getGuageData],
        payload
      );
      yield put({
        type: types.GET_LINE_OVERVIEW_TABLE_CARD_DATA_SUCCESS,
        payload: guageData,
      });
      yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error) {
      yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
  }
  
export function* lineOverviewSaga() {
    yield all([
        takeLatest(types.GET_LINE_OEE, getOEEData),
        takeLatest(types.GET_TEEP_MATRIX_BY_LINE, getTeepMatrixData),
        takeLatest(types.GET_DOWNTIME_BY_LINE, getDowntimeByLine),
        takeLatest(types.GET_LOST_TIME_MATRIX, getLostTimeMatrix),
        takeLatest(types.GET_RUNTIME_DOWNTIME, getRunTimeDownTime),
        takeLatest(types.GET_RATE_MATRIX, getRateMatrixByLine),
        takeLatest(types.GET_LINE_OVERVIEW_TABLE_DATA, getActiveAlarmsTableData),
        takeLatest(types.GET_LINE_OVERVIEW_TABLE_CARD_DATA, getActiveAlarmsTableCardData),
    ]);
}