import { Text } from "components/Text";

export const SwitchModuleCard = (props: any) => {
  const { title, icon, openUrl, disabled } = props;

  const redirectUrl = () => {
    if(disabled) {
      return;
    }
    
    const url = window.open(openUrl, "_blank");
    if (url) {
      url.focus();
    }
  }

  return (
    <>
      <div onClick={redirectUrl} className="switch-module-card-container"
        style={disabled ? { cursor: 'not-allowed' } : {}}>
        <div className="switch-module-card-data-container">
          {icon}
          <Text type={"p14"}>{title}</Text>
        </div>
      </div>
    </>
  );
};
