import "./login.scss";
import { ReactComponent as BraboImg } from "assets/images/brabo.svg";
import { Button, Input, message } from "antd";
import { useState } from "react";
import { LockOutlined } from "@ant-design/icons";
import { changePassword } from "store/services/login";

export const ResetPassword = (props: any) => {
  const { openSessionExpired } = props;
  const [passwordCombination, setPasswordCombination] = useState<any>({
    newPassword: '',
    confirmPassword: ''
  })

  const enterKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter" || event.code === "Enter") {
      createNewPassword();
    }
  };

  const createNewPassword = () => {
    if (passwordCombination.newPassword === "" || passwordCombination.confirmPassword === "") {
      message.error('please check passwords');
      return;
    } else if (passwordCombination.newPassword !== passwordCombination.confirmPassword) {
      message.error('Passwords does not match');
      return;
    }
    changePassword({ userId: localStorage.getItem("userId"), otp: localStorage.getItem("otp"), newPassword: passwordCombination.newPassword })
      .then(async (response) => {
        let changePasswordResponse = await response.json();
        if (changePasswordResponse.passwordUpdated) {
          message.success(changePasswordResponse.responseMessage);
          localStorage.removeItem("otp");
          openSessionExpired('login');
        } else {
          message.error(changePasswordResponse.responseMessage);
        }
      }).catch((error: any) => {
        if (error !== 500) {
          message.error('Network error, Please check your connection');
        } else {
          message.error('Password is not updated');
        }
      })

  }

  const handleCancel = () => {
    openSessionExpired('login');
  }

  return (
    <div className="data-container">
      <BraboImg className="brabo-image" />
      <p className="forgot-text">Reset Password</p>
      <Input.Password
        className="input otp-input"
        style={{ marginBottom: '24px' }}
        placeholder="New Password"
        value={passwordCombination.newPassword}
        prefix={<LockOutlined />}
        onChange={(e) => setPasswordCombination({ ...passwordCombination, newPassword: e.target.value })}
      />
      <Input.Password
        className="input otp-input"
        placeholder="Confirm New Password"
        value={passwordCombination.confirmPassword}
        prefix={<LockOutlined />}
        onChange={(e) => setPasswordCombination({ ...passwordCombination, confirmPassword: e.target.value })}
      />
      <Button
        type="primary"
        className="verify-otp"
        onKeyDown={() => enterKeyPress}
        onClick={createNewPassword}
      >
        Create New Password
      </Button>
      <Button
        type="default"
        className="cancel-button"
        onClick={handleCancel}
      >
        Cancel
      </Button>
    </div>
  );
}