import { AlarmsEvents } from "types";
import * as types from "./actionTypes";

export const getAlarmsAndEventsDashboardData = (payload: AlarmsEvents) => ({
  type: types.GET_ALARMS_EVENTS_DASHBOARD_DATA,
  payload,
});

export const getAlarmsAndEventsDetailsData = (payload: AlarmsEvents) => ({
  type: types.GET_ALARMS_EVENTS_DETAILS_DATA,
  payload,
});

export const getAlarmsAndEventsStackedBarData = (payload: AlarmsEvents) => ({
  type: types.GET_ALARMS_EVENTS_STACKED_BAR_CHART_DATA,
  payload,
});

export const getAlarmsAndEventsParetoData = (payload: AlarmsEvents) => ({
  type: types.GET_ALARMS_EVENTS_PARETO_DATA,
  payload,
});

export const getAlarmsAndEventsTableData = (payload: AlarmsEvents) => ({
  type: types.GET_ALARMS_EVENTS_TABLE_DATA,
  payload,
});

export const getAlarmsAndEventsGuageData = (payload: AlarmsEvents) => ({
  type: types.GET_ALARMS_EVENTS_GUAGE_DATA,
  payload,
});
