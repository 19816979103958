import { all, call, put, takeLatest } from "redux-saga/effects";
import * as types from "store/actions/actionTypes";
import { AlarmsEventsService } from "store/services/alarmsEventsService";
import { LineOverviewService } from "store/services/lineOverviewService";
import { OeeAnlysisService } from "store/services/oeeAnlysisService";
import { ProductionOverviewService } from "store/services/productionOverviewService";
import { ReasonCodeService } from "store/services/reasonCodeService";
import { RuntimeAnalysisService } from "store/services/runtimeAnalysisService";
import { OperatorService } from "store/services/operatorService";


export function* getProductionOverviewBatchCountData({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    yield put({ type: types.GET_PRODUCTION_OVERVIEW_BATCH_DATA_LOADER_SUCCESS, productionOverviewBatchLoader: true });
    const productionOverviewService = ProductionOverviewService.getInstance();
    const productionOverviewDetails = yield call(
      [
        productionOverviewService,
        productionOverviewService.getProductionBatchCountData,
      ],
      payload
    );
    yield put({
      type: types.GET_PRODUCTION_OVERVIEW_COUNT_DATA_SUCCESS,
      payload: productionOverviewDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    yield put({ type: types.GET_PRODUCTION_OVERVIEW_BATCH_DATA_LOADER_SUCCESS, productionOverviewBatchLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getProductionOverviewBatchDetailsData({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const productionOverviewService = ProductionOverviewService.getInstance();
    const productionOverviewDetails = yield call(
      [
        productionOverviewService,
        productionOverviewService.getProductionBatchDetailsData,
      ],
      payload
    );
    yield put({
      type: types.GET_PRODUCTION_OVERVIEW_BATCH_DATA_SUCCESS,
      payload: productionOverviewDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getProductionOverviewAlarmsData({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const productionOverviewService = AlarmsEventsService.getInstance();

    const productionOverviewDetails = yield call(
      [
        productionOverviewService,
        productionOverviewService.getAlarmsDashboardData,
      ],
      payload
    );
    yield put({
      type: types.GET_PRODUCTION_OVERVIEW_ALARMS_DATA_SUCCESS,
      payload: productionOverviewDetails,
      dataFor: "alarmCard",
    });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getProductionOverviewTableData({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const productionOverviewService = AlarmsEventsService.getInstance();
    const productionOverviewTableDetails = yield call(
      [productionOverviewService, productionOverviewService.getAlarmsTableData],
      payload
    );

    yield put({
      type: types.GET_PRODUCTION_OVERVIEW_TABLE_DATA_SUCCESS,
      payload: productionOverviewTableDetails,
      dataFor: "table",
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getProductionOverviewParetoData({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const productionOverviewService = AlarmsEventsService.getInstance();

 
    const alarmsEventsDetailsByCount = yield call(
      [
        productionOverviewService,
        productionOverviewService.getAlarmsDashboardParetoChartData,
      ],
      { ...payload, type: "BY_COUNT" }
    );

    yield put({
      type: types.GET_PRODUCTION_OVERVIEW_PARETO_CHART_DATA_SUCCESS,
      payload: alarmsEventsDetailsByCount,
      dataFor: "pareto",
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getProductionOverviewOeeData({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const productionOverviewService = LineOverviewService.getInstance();
    const productionOverviewDetails = yield call(
      [productionOverviewService, productionOverviewService.getOEEData],
      payload
    );
    yield put({
      type: types.GET_PRODUCTION_OVERVIEW_OEE_DATA_SUCCESS,
      payload: productionOverviewDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getProductionOverviewAverageCycleTimeData({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const runtimeAnalysisService = RuntimeAnalysisService.getInstance();
    const productionOverviewDetails = yield call(
      [runtimeAnalysisService, runtimeAnalysisService.getAverageCycleTimeData],
      payload
    );
    yield put({
      type: types.GET_PRODUCTION_OVERVIEW_AVERAGE_CYCLE_TIME_DATA_SUCCESS,
      payload: productionOverviewDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getProductionOverviewAssetUtilizationData({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const runtimeAnalysisService = RuntimeAnalysisService.getInstance();
    const runtimeAnalysisServiceDetails = yield call(
      [
        runtimeAnalysisService,
        runtimeAnalysisService.getProductionAssetUtilizationValues,
      ],
      payload
    );
    yield put({
      type: types.GET_PRODUCTION_OVERVIEW_ASSET_UTILIZATION_DATA_SUCCESS,
      payload: runtimeAnalysisServiceDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getProductionOverviewTableCardData({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const alarmsEventsService = AlarmsEventsService.getInstance();
    const guageData = yield call(
      [alarmsEventsService, alarmsEventsService.getGuageData],
      payload
    );
    yield put({
      type: types.GET_PRODUCTION_OVERVIEW_TABLE_CARD_DATA_SUCCESS,
      payload: guageData,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getProductionOverviewPackagingData({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const productionOverviewService = ProductionOverviewService.getInstance();
    const productionOverviewDetails = yield call(
      [
        productionOverviewService,
        productionOverviewService.getPackagingDetails,
      ],
      payload
    );
    yield put({
      type: types.GET_PRODUCTION_OVERVIEW_PACKAGING_DATA_SUCCESS,
      payload: productionOverviewDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

export function* getProductionOverviewMachineStatusSaga({ type, payload }: { type: string, payload: any }): Generator<any> {
  try {
    const operatorService = OperatorService.getInstance();
    const machineStatus = yield call([operatorService, operatorService.getMachineStatus]);
    yield put({ type: types.GET_PRODUCTION_OVERVIEW_MACHINE_STATUS_SUCCESS, machineStatus });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

//AF Pack Batches Saga
export function* getPPAllBatchesData({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const productionOverviewService = ProductionOverviewService.getInstance();
    const productionOverviewDetails = yield call(
      [
        productionOverviewService,
        productionOverviewService.getPPAllBatchesDetails,
      ],
      payload
    );
    yield put({
      type: types.GET_PPALL_BATCHES_DATA_SUCCESS,
      payload: productionOverviewDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}

//Update Rejectcount in AF Pack Batches
export function* updateRejectCountSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  yield put({
    type:types.UPDATE_REJECT_COUNT_SUCCESS,
    rejectCountUpdate:false
  })
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const productionOverviewService = ProductionOverviewService.getInstance();
   yield call(
      [
        productionOverviewService,
        productionOverviewService.updateRejectCountDetails,
      ],
      payload
    );
    yield put({
      type:types.UPDATE_REJECT_COUNT_SUCCESS,
      payload:true
    })
    
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}
export function* updateRejectCountSuccessSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
    const productionOverviewService = ProductionOverviewService.getInstance();
    const productionOverviewDetails = yield call(
      [
        productionOverviewService,
        productionOverviewService.updateRejectCountDetails,
      ],
      payload
    );
    yield put({
      type: types.GET_PPALL_BATCHES_DATA_SUCCESS,
      payload: productionOverviewDetails,
    });
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error) {
    yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
  }
}
export function* productionOverviewSaga() {
  yield all([
    takeLatest(
      types.GET_PRODUCTION_OVERVIEW_COUNT_DATA,
      getProductionOverviewBatchCountData
    ),
    takeLatest(
      types.GET_PRODUCTION_OVERVIEW_BATCH_DATA,
      getProductionOverviewBatchDetailsData
    ),
    takeLatest(
      types.GET_PRODUCTION_OVERVIEW_ALARMS_DATA,
      getProductionOverviewAlarmsData
    ),
    takeLatest(
      types.GET_PRODUCTION_OVERVIEW_OEE_DATA,
      getProductionOverviewOeeData
    ),
    takeLatest(
      types.GET_PRODUCTION_OVERVIEW_AVERAGE_CYCLE_TIME_DATA,
      getProductionOverviewAverageCycleTimeData
    ),
    takeLatest(
      types.GET_PRODUCTION_OVERVIEW_ASSET_UTILIZATION_DATA,
      getProductionOverviewAssetUtilizationData
    ),
    takeLatest(
      types.GET_PRODUCTION_OVERVIEW_TABLE_DATA,
      getProductionOverviewTableData
    ),
    takeLatest(
      types.GET_PRODUCTION_OVERVIEW_PARETO_DATA,
      getProductionOverviewParetoData
    ),
    takeLatest(
      types.GET_PRODUCTION_OVERVIEW_TABLE_CARD_DATA,
      getProductionOverviewTableCardData,
    ),
    takeLatest(
      types.GET_PRODUCTION_OVERVIEW_PACKAGING_DATA,
      getProductionOverviewPackagingData,
    ),
    takeLatest(
      types.GET_PRODUCTION_OVERVIEW_MACHINE_STATUS,
      getProductionOverviewMachineStatusSaga,
    ),
    takeLatest(
      types.GET_PPALL_BATCHES_DATA,getPPAllBatchesData
    ),
    takeLatest(
      types.UPDATE_REJECT_COUNT,updateRejectCountSaga
    )
  ]);
}
