import { useEffect, useRef, useState } from "react";
import { Button, Input, message } from "antd";
import { ReactComponent as BraboImg } from "assets/images/brabo.svg";
import "./login.scss";
import { ReactComponent as OTP } from "assets/icons/otp-icon.svg";
import { generateOtp, validateOTP } from "store/services/login";

const ForgotPassword: any = (props: any) => {
  const { openResetPassword } = props;
  const [otp, setOTP] = useState<any>({
    otpSent: false,
    otpValue: '',
    resendCount: 0
  });

  const [timer, setTimer] = useState(60);
  const id = useRef<any>(null);


  const verifyOTPHandle = () => {
    if(otp.otpValue === "") {
      message.error('please enter otp for verification');
      return;
    }

    validateOTP({ userId: localStorage.getItem("userId"), otp: otp.otpValue, validateOTP: true})
    .then(async (response) => {
      let otpResponse = await response.json();
      if(otpResponse.otpUpdated) {
        message.success(otpResponse.responseMessage);
        localStorage.setItem('otp', otp.otpValue);
        openResetPassword('resetPassword');
      } else {
        message.error(otpResponse.responseMessage);
      }
    }).catch((error: any) => {
      if (error !== 500) {
        message.error('Network error, Please check your connection');
      } else {
        message.error('Incorrect OTP');
      }
    })
  }

  const enterKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter" || event.code === "Enter") {
      verifyOTPHandle();
    }
  };

  const handleCancel = () => { 
    openResetPassword('login');
  }

  useEffect(() => {
    if (timer === 0) {
      clearInterval(id.current);
      setOTP({ ...otp, otpSent: false });
      setTimer(60);
    }
  }, [timer, otp]);

  const startTimer = () => {
    id.current = setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
  }

  const handleResendOTP = () => {
    if(otp.resendCount === 3) {
      message.error('only 3 otp allowed');
      return;
    }

    setOTP({
      otpSent: true,
      otpValue: '',
      resendCount: otp.resendCount+1
    });

    let userId = localStorage.getItem("userId");
    if (userId !== null) {
      generateOtp(userId);
    }

    startTimer();
  }

  return (
    <div className="data-container">
      <BraboImg className="brabo-image" />
      <p className="forgot-text">Forgot password?</p>
      <div className="otp-text">
        <p>An OTP has been sent to the respective </p>
        <p>email ID to reset your password</p>
      </div>
      <Input
        className="input otp-input"
        placeholder="Enter your OTP"
        value={otp.otpValue}
        prefix={<OTP />}
        onChange={(e) => setOTP({ ...otp, otpValue: e.target.value })}
      />
      {otp.otpSent ? <p className="resend-text">Resend OTP: {timer}s</p> : <p onClick={handleResendOTP}
        className="resend-otp-link">Resend OTP</p>}
      <Button
        type="primary"
        className="verify-otp"
        onKeyDown={() => enterKeyPress}
        onClick={verifyOTPHandle}
      >
        Verify OTP
      </Button>
      <Button
        type="default"
        className="cancel-button"
        onClick={handleCancel}
      >
        Cancel
      </Button>

    </div>
  );
};

export default ForgotPassword;
