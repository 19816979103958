import { AppstoreOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";
import "./index.scss";

const BreadcrumbComponent: React.FC = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const breadcrumbNameMap: Record<string, string> = {
    "/production-overview": "Production Overview",
    "/line-overview": "Line Overview",
    "/alarm-and-events": "Alarm & Events",
    "/oee": "OEE Analysis",
    "/runtime-analysis": " Runtime Analysis",
    "/reason-code-management": "Reason Code Management",
    "/dashboard": "Dashboard",
    "/data-visualization": "Data Visualization",
    "/process-order-summary":"Process Order Summary"
  };

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link style={{ color: "white" }} to={url}>
          {breadcrumbNameMap[url]}
        </Link>
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <AppstoreOutlined style={{ color: "white" }} />
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return <Breadcrumb>{breadcrumbItems}</Breadcrumb>;
};

export default BreadcrumbComponent;
