import { Card, Dropdown, Menu } from "antd";
import { ReasonCategoryCardProps } from "types/index";
import { MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from 'antd';
import "./Card.scss";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { editReasonCodeCategory } from "store/actions/reasonCodeManagement";

export const ReasonCategoryCard: React.FC<any> = ({
  icon,
  title,
  content,
  onClick,
  className,
  itemStatus,
  uuid

}) => {

  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);

  const [category, setCategory] = useState<string>('');
  const [status, setStatus] = useState<boolean>(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (title) {
      setCategory(title)
    }
  }, [title])

  useEffect(() => {
    if (itemStatus) {
      setStatus(itemStatus)
    }
  }, [itemStatus])

  const statusColor = (status: any) => {
    let statusClass = "";
    switch (status) {
      case "Active":
        statusClass = "Active";
        break;
      case "Inactive":
        statusClass = "Inactive";
        break;
      default:
        statusClass = "item";
        break;
    }
    return statusClass;
  };

  const handleEdit = () => {
    setIsOpenEdit(!isOpenEdit);
  };

  const onHandleSave = () => {
    dispatch(editReasonCodeCategory({ category, status, uuid, viewType: "grid", type: 0 }))
    setIsOpenEdit(false)
  }

  return (
    <>
      <Card
        size="small"
        title={title}
        extra={icon}
        style={{ width: "100%" }}
        className={className}
        onClick={onClick}
      >
        {content?.map((item: any) => {
          return (
            <div className="card-container">
              <div className="title">{item.title}</div>
              <div className="value">
                <span className={statusColor(item.value)}>{item.value}</span>
              </div>
            </div>
          );
        })}
      </Card>
    </>
  );
};
