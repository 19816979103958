import * as types from "./actionTypes";

export const getOEEAvailabilityAction = (payload: any) => ({
    type: types.GET_OEE_AVAILABILTY_DETAILS,
    payload,
});

export const getOEEQualityAction = (payload: any) => ({
    type: types.GET_OEE_QUALITY,
    payload,
});

export const getOEEPerfAction = (payload: any) => ({
    type: types.GET_OEE_PERFORMANCE,
    payload,
});

export const getOEEMachineWiseAction = (payload: any) => ({
    type: types.GET_OEE_MACHINEWISE,
    payload,
});

export const getOEEByLineAction = (payload: any) => ({
    type: types.GET_OEE_BY_LINE,
    payload,
});
export const getPercentageLossAction = (payload: any) => ({
    type: types.GET_PERCENT_LOSS,
    payload,
});

export const getLossTimeAction = (payload: any) => ({
    type: types.GET_LOSS_TIME_ACTION,
    payload,
});


export const getOEEBySkuAction = (payload: any) => ({
    type: types.GET_OEE_BY_SKU,
    payload,
});


export const getProductionRateAction = (payload: any) => ({
    type: types.GET_PRODUCTION_RATE,
    payload,
});

export const getProductionQualityAction = (payload: any) => ({
    type: types.GET_PRODUCTION_QUALITY,
    payload,
});


export const getProductinTimeAction = (payload: any) => ({
    type: types.GET_PRODUCTIVE_TIME,
    payload,
});

export const getDownTimeDetails = (payload: any) => ({
    type: types.GET_DOWNTIME_DETAILS,
    payload
});

export const getDownTimeReasonCodeDurationCount = (payload: any) => ({
    type: types.GET_DOWNTIME_DURATION_COUNT,
    payload
});

export const getDownTimePareto = (payload: any) => ({
    type: types.GET_DOWNTIME_PARETO,
    payload
})
