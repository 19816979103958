// @ts-nocheck
import { ModelList, TagInfo } from "interface/assetInterface";
import { format } from 'date-fns';
import { timeFormat } from "utils/Constants";
import React from 'react';
import { CarryOutOutlined } from '@ant-design/icons';


export const mapModelResponse = (response: any) => {

    let tempAssetModelList: ModelList[] = [];
    response.forEach((item: any, index: any) => {
        tempAssetModelList.push({
            key: item.getId().getId(),
            modelName: item.getName(),
            description: item.getDescription(),
            lastModified: format(new Date(item.getBase().getUpdatedOn()), timeFormat),
            createdOn: format(new Date(item.getBase().getCreatedOn()), timeFormat),
        });
    });
    return tempAssetModelList;
};

export const mapTagList = (response: any) => {
    let tagList: any[] = [];

    response.forEach((element: any, index: any) => {

        tagList.push({
            key: (index + 1).toString(),
            tagId: element.getId().getUuid(),
            tagName: element.getTagName(),
            tagDescription: element.getTagDesc(),
            deviceName: element.getDeviceName(),
            parentLevel: element.getParentNode()
                ? element.getParentNode().getName()
                : '',
            unit: element.getDataType()?.getUnit(),
            minRange: element.getDataType()?.getMinRange(),
            maxRange: element.getDataType()?.getMaxRange()
        });
    });
    return tagList;
};


export interface treeData {
    title: string;
    key: string;
    icon: React.ReactNode;
    children: any[];
}


export const faltMachineData = (obj: any) => {
    const machineData: any = [];
    const attributes = treeObject.getNodeAttributesList();
    let machine = {
        attributeList: [],
        machineName: "",
        machineKey: 0
    }
    let tempAttribute: any = [];
    attributes.map((item: any) => {
        tempAttribute.push(
            {
                title: item.getName(),
                value: item.getValue()
            }
        )
    });

    machine['attributeList'] = tempAttribute;
    machine['machineName'] = treeObject.getName();
    machine['machineKey'] = treeObject.getId().getId();
    machineData.push(machine);


}

export const mapTree = (treeObject: any) => {
    let result: any = {
        title: '',
        key: '',
        value: 0,
        icon: (
            <CarryOutOutlined />

        ),
        children: [],
        list: []
    };
    if (treeObject.getChildNodesList() && treeObject.getChildNodesList().length) {
        result.children = treeObject.getChildNodesList().map((item) => {

            return mapTree(item);
        });
    }
    result.title = treeObject.getName();
    result.key = treeObject.getId().getId();
    result.value = treeObject.getId().getId();

    const attributes = treeObject.getNodeAttributesList();
    let tempAttribute: any = [];
    attributes.map((item: any) => {
        tempAttribute.push(
            {
                title: item.getName(),
                value: item.getValue()
            }
        )

    })
    result.list = tempAttribute;
    return result;
};

export const mapMachineData = (treeObject: any) => {

    let result: any = {
        title: '',
        key: '',
        value: 0,
        children: [],
        list: []
    };
    if (treeObject.getChildNodesList() && treeObject.getChildNodesList().length) {
        result.children = treeObject.getChildNodesList().map((item) => {
            return mapTree(item);
        });
    }
    result.title = treeObject.getName();
    result.key = treeObject.getId().getId();
    result.value = treeObject.getId().getId();
    const attributes = treeObject.getNodeAttributesList();

    let tempAttribute: any = [];
    attributes.map((item: any) => {
        tempAttribute.push(
            {
                title: item.getName(),
                value: item.getValue()
            }
        )

    })
    result.list = tempAttribute;
    return result;


};
