import { format } from "date-fns"
import { timeFormat } from "utils/Constants"
import { milliToHrs } from "utils/milliToHrs"

export const mapProductionOverviewBatchCount = (details: any) => {
  let productionOverviewBatchCountMetrics: any = {}

  productionOverviewBatchCountMetrics.inCount = details.getInCount()
  productionOverviewBatchCountMetrics.wipCount = details.getWipCount()
  productionOverviewBatchCountMetrics.goodCount = details.getGoodCount()

  productionOverviewBatchCountMetrics.rejectCount = details.getRejectCount()
  productionOverviewBatchCountMetrics.endOfLineCount =
    details.getEndOfLineCount()
  productionOverviewBatchCountMetrics.totalCount = details.getTotalCount()
  productionOverviewBatchCountMetrics.success = true
  return {
    productionOverviewBatchCountMetrics,
  }
}

export const mapProductionOverviewBatchDetails = (details: any) => {
  let productionOverviewBatchDetailsMetrics: any = {}
  productionOverviewBatchDetailsMetrics.productionOrderNumber =
    details.getProductionOrderNumber()
  productionOverviewBatchDetailsMetrics.material = details.getMaterial()
  productionOverviewBatchDetailsMetrics.orderQuantity =
    details.getOrderQuantity()
  productionOverviewBatchDetailsMetrics.batchesList = details.getBatchesList()
  productionOverviewBatchDetailsMetrics.elapsedTime =
    milliToHrs(details.getElapsedTime()) + " Hrs"
  productionOverviewBatchDetailsMetrics.manufacturingTime =
    milliToHrs(details.getManufacturingTime()) + " Hrs"
  productionOverviewBatchDetailsMetrics.startupReject =
    details.getStartupReject()
  productionOverviewBatchDetailsMetrics.productionReject =
    details.getProductionReject()
  return {
    productionOverviewBatchDetailsMetrics,
  }
}

export const mapProductionOverviewPackagingDetails = (details: any) => {
  let productionOverviewPackagingDetails: any = {}
  productionOverviewPackagingDetails.totalCaseCount =
    details.getTotalCaseCount()
  productionOverviewPackagingDetails.totalFinishedPallets =
    details.getTotalFinishedPallets()
  productionOverviewPackagingDetails.totalUnfinishedPallets =
    details.getTotalUnfinishedPallets()
  productionOverviewPackagingDetails.leftOverBottles =
    details.getLeftOverBottles()
  productionOverviewPackagingDetails.leftOverCases = details.getLeftOverCases()
  productionOverviewPackagingDetails.carryForwardBottles =
    details.getCarryForwardedBottles()
  productionOverviewPackagingDetails.carryForwardCases =
    details.getCarryForwardedCases()
  productionOverviewPackagingDetails.success = true
  return productionOverviewPackagingDetails
}

export const mapPPAllBatchesDetails = (details: any) => {
  const dataList: any = []
  details.forEach((item: any, index: number) => {
    dataList.push({
      key:
        item?.getProductionOrderNumber().toString() +
        item?.getStartTime().toString(),
      processOrder: item?.getProductionOrderNumber(),
      productDescription: item?.getProductDescription(),
      line: item?.getLine(),
      sku: item?.getSku(),
      StartTime: format(new Date(item?.getStartTime()), timeFormat),
      EndTime: item?.getEndTime() === 0 ?'-':format(new Date(item?.getEndTime()), timeFormat),
      Materials: item.getMaterial(),
      orderQuantity: item.getOrderQuantity(),
      totalQuantityProduced: item.getTotalQuantityProduced(),
      goodQuantity: item.getGoodQuantity(),
      totalReject: item?.getTotalReject(),
      StartupRejects: item?.getStartupReject(),
      ProductionRejects: item?.getProductionReject(),
      BatchNumber: item?.getBatchNumber(),
    })
  })
  return dataList
}
