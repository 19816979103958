/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: service/ReasonCodeManagementService.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var model_ReasonCodeModel_pb = require('../model/ReasonCodeModel_pb.js')

var common_Commons_pb = require('../common/Commons_pb.js')

var common_ReasonCodeDetails_pb = require('../common/ReasonCodeDetails_pb.js')

var enums_ReasonCodeEnum_pb = require('../enums/ReasonCodeEnum_pb.js')
const proto = {};
proto.proto = require('./ReasonCodeManagementService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ReasonCodeManagementServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ReasonCodeManagementServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ReasonCodeManagementRequest,
 *   !proto.proto.ReasonCodeManagementResponse>}
 */
const methodDescriptor_ReasonCodeManagementService_getReasonCodeManagementData = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeManagementService/getReasonCodeManagementData',
  grpc.web.MethodType.UNARY,
  proto.proto.ReasonCodeManagementRequest,
  proto.proto.ReasonCodeManagementResponse,
  /**
   * @param {!proto.proto.ReasonCodeManagementRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ReasonCodeManagementResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ReasonCodeManagementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ReasonCodeManagementResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ReasonCodeManagementResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeManagementServiceClient.prototype.getReasonCodeManagementData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/getReasonCodeManagementData',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_getReasonCodeManagementData,
      callback);
};


/**
 * @param {!proto.proto.ReasonCodeManagementRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ReasonCodeManagementResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeManagementServicePromiseClient.prototype.getReasonCodeManagementData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/getReasonCodeManagementData',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_getReasonCodeManagementData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.AssignedCodeRequest,
 *   !proto.proto.AssignedCodeResponse>}
 */
const methodDescriptor_ReasonCodeManagementService_getAssignedReasonCodeData = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeManagementService/getAssignedReasonCodeData',
  grpc.web.MethodType.UNARY,
  proto.proto.AssignedCodeRequest,
  proto.proto.AssignedCodeResponse,
  /**
   * @param {!proto.proto.AssignedCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.AssignedCodeResponse.deserializeBinary
);


/**
 * @param {!proto.proto.AssignedCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.AssignedCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.AssignedCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeManagementServiceClient.prototype.getAssignedReasonCodeData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/getAssignedReasonCodeData',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_getAssignedReasonCodeData,
      callback);
};


/**
 * @param {!proto.proto.AssignedCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.AssignedCodeResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeManagementServicePromiseClient.prototype.getAssignedReasonCodeData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/getAssignedReasonCodeData',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_getAssignedReasonCodeData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.AssignNewCodeRequest,
 *   !proto.proto.GeneralResponse>}
 */
const methodDescriptor_ReasonCodeManagementService_assignReasonCodeToResource = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeManagementService/assignReasonCodeToResource',
  grpc.web.MethodType.UNARY,
  proto.proto.AssignNewCodeRequest,
  common_Commons_pb.GeneralResponse,
  /**
   * @param {!proto.proto.AssignNewCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_Commons_pb.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.proto.AssignNewCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeManagementServiceClient.prototype.assignReasonCodeToResource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/assignReasonCodeToResource',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_assignReasonCodeToResource,
      callback);
};


/**
 * @param {!proto.proto.AssignNewCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeManagementServicePromiseClient.prototype.assignReasonCodeToResource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/assignReasonCodeToResource',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_assignReasonCodeToResource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.AssignNewCodeRequest,
 *   !proto.proto.GeneralResponse>}
 */
const methodDescriptor_ReasonCodeManagementService_unAssignReasonCodeToResource = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeManagementService/unAssignReasonCodeToResource',
  grpc.web.MethodType.UNARY,
  proto.proto.AssignNewCodeRequest,
  common_Commons_pb.GeneralResponse,
  /**
   * @param {!proto.proto.AssignNewCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_Commons_pb.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.proto.AssignNewCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeManagementServiceClient.prototype.unAssignReasonCodeToResource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/unAssignReasonCodeToResource',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_unAssignReasonCodeToResource,
      callback);
};


/**
 * @param {!proto.proto.AssignNewCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeManagementServicePromiseClient.prototype.unAssignReasonCodeToResource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/unAssignReasonCodeToResource',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_unAssignReasonCodeToResource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.AMDReasonCodeDetailRequest,
 *   !proto.proto.AMDReasonCodeDetailResponse>}
 */
const methodDescriptor_ReasonCodeManagementService_getReasonCodeDetailedInfoAmd = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeManagementService/getReasonCodeDetailedInfoAmd',
  grpc.web.MethodType.UNARY,
  proto.proto.AMDReasonCodeDetailRequest,
  proto.proto.AMDReasonCodeDetailResponse,
  /**
   * @param {!proto.proto.AMDReasonCodeDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.AMDReasonCodeDetailResponse.deserializeBinary
);


/**
 * @param {!proto.proto.AMDReasonCodeDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.AMDReasonCodeDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.AMDReasonCodeDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeManagementServiceClient.prototype.getReasonCodeDetailedInfoAmd =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/getReasonCodeDetailedInfoAmd',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_getReasonCodeDetailedInfoAmd,
      callback);
};


/**
 * @param {!proto.proto.AMDReasonCodeDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.AMDReasonCodeDetailResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeManagementServicePromiseClient.prototype.getReasonCodeDetailedInfoAmd =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/getReasonCodeDetailedInfoAmd',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_getReasonCodeDetailedInfoAmd);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpsertReasonCodeRequest,
 *   !proto.proto.UpsertReasonCodeResponse>}
 */
const methodDescriptor_ReasonCodeManagementService_upsertReasonCode = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeManagementService/upsertReasonCode',
  grpc.web.MethodType.UNARY,
  proto.proto.UpsertReasonCodeRequest,
  proto.proto.UpsertReasonCodeResponse,
  /**
   * @param {!proto.proto.UpsertReasonCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpsertReasonCodeResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpsertReasonCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpsertReasonCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpsertReasonCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeManagementServiceClient.prototype.upsertReasonCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/upsertReasonCode',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_upsertReasonCode,
      callback);
};


/**
 * @param {!proto.proto.UpsertReasonCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpsertReasonCodeResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeManagementServicePromiseClient.prototype.upsertReasonCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/upsertReasonCode',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_upsertReasonCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ReasonCodeInfoRequest,
 *   !proto.proto.ReasonCodeInfoResponse>}
 */
const methodDescriptor_ReasonCodeManagementService_getReasonCodeInfo = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeManagementService/getReasonCodeInfo',
  grpc.web.MethodType.UNARY,
  proto.proto.ReasonCodeInfoRequest,
  proto.proto.ReasonCodeInfoResponse,
  /**
   * @param {!proto.proto.ReasonCodeInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ReasonCodeInfoResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ReasonCodeInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ReasonCodeInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ReasonCodeInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeManagementServiceClient.prototype.getReasonCodeInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/getReasonCodeInfo',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_getReasonCodeInfo,
      callback);
};


/**
 * @param {!proto.proto.ReasonCodeInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ReasonCodeInfoResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeManagementServicePromiseClient.prototype.getReasonCodeInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/getReasonCodeInfo',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_getReasonCodeInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.EndDowntimeRequest,
 *   !proto.proto.GeneralResponse>}
 */
const methodDescriptor_ReasonCodeManagementService_endDowntime = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeManagementService/endDowntime',
  grpc.web.MethodType.UNARY,
  proto.proto.EndDowntimeRequest,
  common_Commons_pb.GeneralResponse,
  /**
   * @param {!proto.proto.EndDowntimeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_Commons_pb.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.proto.EndDowntimeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeManagementServiceClient.prototype.endDowntime =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/endDowntime',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_endDowntime,
      callback);
};


/**
 * @param {!proto.proto.EndDowntimeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeManagementServicePromiseClient.prototype.endDowntime =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/endDowntime',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_endDowntime);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpsertReasonCodeLogsRequest,
 *   !proto.proto.GeneralResponse>}
 */
const methodDescriptor_ReasonCodeManagementService_upsertReasonCodeToLogs = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeManagementService/upsertReasonCodeToLogs',
  grpc.web.MethodType.UNARY,
  proto.proto.UpsertReasonCodeLogsRequest,
  common_Commons_pb.GeneralResponse,
  /**
   * @param {!proto.proto.UpsertReasonCodeLogsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_Commons_pb.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpsertReasonCodeLogsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeManagementServiceClient.prototype.upsertReasonCodeToLogs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/upsertReasonCodeToLogs',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_upsertReasonCodeToLogs,
      callback);
};


/**
 * @param {!proto.proto.UpsertReasonCodeLogsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeManagementServicePromiseClient.prototype.upsertReasonCodeToLogs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/upsertReasonCodeToLogs',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_upsertReasonCodeToLogs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteLogRequest,
 *   !proto.proto.GeneralResponse>}
 */
const methodDescriptor_ReasonCodeManagementService_deleteReasonCodeLog = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeManagementService/deleteReasonCodeLog',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteLogRequest,
  common_Commons_pb.GeneralResponse,
  /**
   * @param {!proto.proto.DeleteLogRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_Commons_pb.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteLogRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeManagementServiceClient.prototype.deleteReasonCodeLog =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/deleteReasonCodeLog',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_deleteReasonCodeLog,
      callback);
};


/**
 * @param {!proto.proto.DeleteLogRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeManagementServicePromiseClient.prototype.deleteReasonCodeLog =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/deleteReasonCodeLog',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_deleteReasonCodeLog);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.AMDReasonCodeDetailRequest,
 *   !proto.proto.AmdCsvResponse>}
 */
const methodDescriptor_ReasonCodeManagementService_getAmdLogsCSV = new grpc.web.MethodDescriptor(
  '/proto.ReasonCodeManagementService/getAmdLogsCSV',
  grpc.web.MethodType.UNARY,
  proto.proto.AMDReasonCodeDetailRequest,
  proto.proto.AmdCsvResponse,
  /**
   * @param {!proto.proto.AMDReasonCodeDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.AmdCsvResponse.deserializeBinary
);


/**
 * @param {!proto.proto.AMDReasonCodeDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.AmdCsvResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.AmdCsvResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ReasonCodeManagementServiceClient.prototype.getAmdLogsCSV =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/getAmdLogsCSV',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_getAmdLogsCSV,
      callback);
};


/**
 * @param {!proto.proto.AMDReasonCodeDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.AmdCsvResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ReasonCodeManagementServicePromiseClient.prototype.getAmdLogsCSV =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ReasonCodeManagementService/getAmdLogsCSV',
      request,
      metadata || {},
      methodDescriptor_ReasonCodeManagementService_getAmdLogsCSV);
};


module.exports = proto.proto;

