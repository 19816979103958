export const ACTIVE_STATUS = "Active";
export const INACTIVE_STATUS = "Inactive";
export const timeFormat = 'dd-MM-yy HH:mm:ss';

export const TAG_DATA_TABLE_FILTER_COL = [
    'Tag Name',
    'Description',
    'Value',
    'Device Name',
    'Date & Time',
    "Unit",
    "MinRange",
    "MaxRange"
];

// Note: This is minutes from 12:00 to 08:00
export const MINUTES_TILL_EIGHT_O_CLOCK = 419
