import { ChartContainerProps } from "types";
import {
  InfoCircleOutlined,
  ExpandAltOutlined,
  ShrinkOutlined,
} from "@ant-design/icons";
import "./index.scss";
import { Tooltip } from "antd";
import { useState } from "react";
import { fullScreenElement } from "utils/fullScreen";
import { ReactComponent as ExpandIcon } from "assets/icons/expandIcon.svg";

const ChartContainer: React.FC<ChartContainerProps> = ({
  id,
  title,
  alignChartTitle,
  tooltip,
  width,
  height,
  child,
  showFullScreen,
  paddingtop,
  severity,
  guageAnalysis,
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  return (
    <div id={id} className="chart-container">
      <div
        className="title-container"
        style={{
          placeContent: alignChartTitle,
        }}
      >
        <div className="title-container-left">
          <div className="title">{title}</div>
          {tooltip && (
            <div className="title-tooltip-icon">
              <Tooltip placement="topLeft" title={tooltip}>
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          )}
        </div>
        {severity && <div className="severity-container">{severity}</div>}
        {showFullScreen ? (
          <div
            className="title-container-right"
            onClick={() => fullScreenElement(id, fullScreen, setFullScreen)}
          >
            {fullScreen ? <ShrinkOutlined /> : <ExpandIcon />}
          </div>
        ) : null}
      </div>
      <div
        style={{
          minWidth: width,
          height: fullScreen ? "99%" : height,
          paddingTop: paddingtop ? paddingtop : "0px",
        }}
      >
        {child}
      </div>
      {guageAnalysis && <>{guageAnalysis}</>}
    </div>
  );
};

export default ChartContainer;
