import "./index.scss";
import { Table, Input } from "antd";
import type { ColumnsType } from "antd/es/table";
import { MoreOutlined } from "@ant-design/icons";
import { CustomButton } from "components/Common/Button";
import { ReactComponent as SortIcon } from "assets/icons/sort-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { assignReasourceCodeToResource, getAssignedReasonCode, getReasonCodeCategoriesData, unAssignReasonCodeToResource } from "store/actions/reasonCodeManagement";
import { handleKeyPress } from "utils/common";
import { AssignedModal } from "./AssignedModal";


const columns: ColumnsType<any> = [
  {
    key: "reasonCodeCategory",
    title: (
      <div className="column-title">
        <div>Reason Code Category</div>
        <SortIcon />
      </div>
    ),
    dataIndex: "reasonCodeCategory",
  },
  {
    key: "reasonCodeFactor",
    title: (
      <div className="column-title">
        <div>Reason Code Factor</div>
        <SortIcon />
      </div>
    ),
    dataIndex: "reasonCodeFactor",
  },
  {
    key: "reasonCode",
    title: (
      <div className="column-title">
        <div>Reason Code</div>
        <SortIcon />
      </div>
    ),
    dataIndex: "reasonCode",
    render: (_, { reasonCode }) => (
      <div className="status-container">
        <div className="value">
          <span className={""}>{reasonCode}</span>
        </div>
        <MoreOutlined />
      </div>
    ),
  },
];
const { Search } = Input;

export const Assigned = (props: any) => {
  const { equipmentId } = props
  const [searchValue, setSearchValue] = useState("");
  const [selectedRow, setSelectedRow] = useState("");
  const [reasonCodeCategoryId, setReasonCodeCategoryID] = useState("");
  const [assignReasonCodePayload, setAssignReasonCodePayload] = useState<any>({
    equipment_id: equipmentId
  });

  const dispatch = useDispatch();

  const onSearch = (value: string) => { setSearchValue(value) };

  const assignedReasonCodeData = useSelector((state: any) => state.reasonCode.assignedReasonCode)
  useEffect(() => {
    dispatch(getAssignedReasonCode({ assignReasonCodePayload }));
  }, [assignReasonCodePayload]);

  const rowSelection: any = {
    onSelect: (record: any) => {
      setSelectedRow(record.reasonCodeKey)
    }
  };

  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);

  const onAssignModal = () => {
    setShowCategoryModal(true);
  }

  const onAssignHandler = () => {
    dispatch(assignReasourceCodeToResource({ reasonCodeUuid: reasonCodeCategoryId, equipmentId: equipmentId }));
    setShowCategoryModal(false)
  }

  const onUnAssignhandler = () => {
    dispatch(unAssignReasonCodeToResource({ reasonCodeUuid: selectedRow, equipmentId: equipmentId }));
  }

  return (
    <>
      <div className="assigned-container">
        <div className="title">Assigned Reason Codes</div>
        <div className="assigned-filter">
          {/* not use  */}
          {/* <CustomButton type="Filter" /> */}
          <div className="search-container">
            <Search
              placeholder="Search"
              onSearch={onSearch}
              style={{ width: "100%" }}
              bordered={false}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="button-container">
            <CustomButton type="Delete" onClick={() => onUnAssignhandler()} />
            <CustomButton type="Assign"
              onClick={() => onAssignModal()}
            />
          </div>
        </div>
        {/* not use in now */}
        {/* <div className="assigned-input-container">
          <div className="input-container">
            <span>Resource Name</span>
            <Input placeholder="Equipment 1" />
          </div>
          <div className="input-container">
            <span>Description</span>
            <Input placeholder="Equipment 1 Description" />
          </div>
          <div className="input-container">
            <span>Type</span>
            <Input placeholder="Equipment Module" />
          </div>
        </div> */}
        <div className="grid-view-container">
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection
            }}
            columns={columns}
            dataSource={assignedReasonCodeData}
            pagination={false}
            scroll={{ y: 600 }}
          />
        </div>
      </div>
      <AssignedModal
        visible={showCategoryModal}
        onCancel={() => setShowCategoryModal(false)}
        onOk={() => onAssignHandler()}
        setReasonCodeCategoryID={setReasonCodeCategoryID}
      />
    </>
  );
};
