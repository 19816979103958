import { Button, Select, Spin, Table } from "antd";
import { MoreOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import "./index.scss";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { endDownTimeAMD, getAMDList, getReasonCodeCategories } from "store/actions/reasonCodeManagement";
import { CustomButton } from "components/Common/Button";
import AssignModal from "../LogsAssignModal/AssignModal";
import moment from "moment";
import { number } from "yup";
import { AddReasonCodeModal } from "pages/ReasonCodeManagement/AMD/AddReasonCodeModal";

const { Option } = Select;

const Logs: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Note: if filter is to of last 30 days, this is commented as per discussion with Devendra
    // dispatch(getAMDList({ shiftInfo: 0, startTime: moment(new Date()).subtract(30, "days").valueOf(), endTime: Date.now(), searchText: "" }));
    dispatch(getAMDList({ shiftInfo: 0, startTime: 0, endTime: Date.now(), searchText: "" }));

  }, [dispatch]);

  const amdLogsList = useSelector((state: any) => state.reasonCode.amdList);
  const [tabledata, setTableData] = useState<any>([]);
  const selectedLine = useSelector((state: any) => state.operator.selectedFpLine);

  useEffect(() => {
    const selectedMachine = `F0${Number(selectedLine) + 1}`
    const filteredAmdLogsList:any = [];
    amdLogsList?.forEach( (element) =>{
      if(element.fpLine===selectedMachine)
      filteredAmdLogsList.push(element)
      });
    setTableData([...filteredAmdLogsList] || []);
  }, [amdLogsList,selectedLine])

  const columns: ColumnsType<any> = [
    {
      key: "stoppageId",
      title: "Stopage ID",
      dataIndex: "stoppageId",
    },
    {
      key: "equipment",
      title: "Equipment",
      dataIndex: "equipment",
    },
    {
      key: "reasonCode",
      title: "Reason Code",
      dataIndex: "reasonCode",
    },
    {
      key: "operator",
      title: "Operator",
      dataIndex: "operator",
    },
    {
      key: "startDate",
      title: "Start Time",
      dataIndex: "startDate",
    },
    {
      key: "endDate",
      title: "End Time",
      dataIndex: "endDate",
    },
    {
      key: "duration",
      title: "Duration",
      dataIndex: "duration",
    },
  ];

  const scrl = useRef<HTMLDivElement>(null);

  const scroll = (scrollType: any) => {
    const tableBody = document.getElementsByClassName(
      "ant-table-body"
    ) as HTMLCollectionOf<HTMLElement>;

    if (scrollType === "up") tableBody[0].scrollBy(0, -100);
    else tableBody[0].scrollBy(0, 100);
  };
  const [selectedKey, setSelectedKey] = useState<any>()
  const [payload, setPayload] = useState<any>({
    stoppageId:[],
    endTime: number
  })

  const rowSelection: any = {
    onSelect: (record: any) => {
      setSelectedKey(record?.stoppageId);
      setPayload({ ...payload, endTime: record.endTime });
    },
  };
  useEffect(() => {
    dispatch(getReasonCodeCategories());
  }, [dispatch])

  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalShow = (state: any, setState: any) => {
    setState(true);
  };
  const okHandle = (state: any, setState: any) => {
    setState(false);
  };
  const cancelHandle = (state: any, setState: any) => {
    setState(false);
  };

  const onEndDownTimeHandler = () => {
    dispatch(endDownTimeAMD({ ...payload, stoppageId: [selectedKey] }));
  }

  const selectedRowFilterData = tabledata.find((item: any) => {
    return item.stoppageId === selectedKey;
  });


  return (
    <div className="table-container">
      <div className="filter-container">
        {/* <Select
          size="large"
          defaultValue="Current Shift"
          style={{ width: 160 }}
        >
          <Option value="currentShift">Current Shift</Option>
          <Option value="previousShift">Previous Shift</Option>
        </Select> */}
        {/* <Button size="large" shape="round">
          Filter
        </Button> */}
        {/* <Button size="large" shape="round">
          Acknowledge
        </Button> */}

        {/* <Select size="large" defaultValue="End Downtime" style={{ width: 160, marginRight: '32px' }}>
          <Option value="endDowntime">End Downtime</Option>
        </Select> */}

        {/* <Button size="large" shape="round">
          Assign Reason Code
        </Button> */}
        <CustomButton type="End Downtime" onClick={() => onEndDownTimeHandler()} />
        <CustomButton
          type="Assign Reason Code"
          onClick={() => modalShow(isModalOpen, setIsModalOpen)}
        />
      </div >
      <AssignModal
        open={isModalOpen}
        onOk={() => okHandle(isModalOpen, setIsModalOpen)}
        onCancel={() => cancelHandle(isModalOpen, setIsModalOpen)}
        selectedKey={selectedKey}
        selectedRowFilterData={selectedRowFilterData}
      />
      <Spin spinning={amdLogsList?.length===0}>
      <Table
        ref={scrl}
        rowSelection={{
          type: "radio",
          ...rowSelection
        }}
        scroll={{
          y: "calc(100vh - 270px)",
        }}
        columns={columns}
        dataSource={[...tabledata]}
        pagination={false}
      />
      </Spin>
      <div className="scroll-button-container">
        <Button
          className="up-button"
          shape="round"
          size="large"
          onClick={() => scroll("up")}
        >
          <UpOutlined />
        </Button>
        <Button
          className="down-button"
          shape="round"
          size="large"
          onClick={() => scroll("down")}
        >
          <DownOutlined />
        </Button>
      </div>
    </div >
  );
};

export default Logs;
