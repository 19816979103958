import { message } from "antd";
import { getUrl } from "config/apiconfig";
import {
  mapAlarmsEventsDashboard,
  mapAlarmsEventsDashboardParetoChartData,
  mapAlarmsEventsDashboardStackedChartData,
  mapAlarmsEventsDetails,
  mapAlarmsEventsGuageData,
  mapAlarmsEventsTableData,
} from "mappers/alarmsEvents.mapper";

export class AlarmsEventsService {
  private static instance: AlarmsEventsService;
  private alarmEventServiceClient: any;
  private alarmEventProtoObj: any;
  private commonProtoObj: any;
  private authToken: any = "";
  private metadata: any;

  private constructor() {
    const alarmURL = getUrl("alarmServiceUrl");
    this.commonProtoObj = require("protobuf/upl-sandbach-protos/common/Common_pb");
    const alarmEventService = require("protobuf/upl-sandbach-protos/service/AlarmEvents_grpc_web_pb");
    this.alarmEventProtoObj = require("protobuf/upl-sandbach-protos/service/AlarmEvents_pb");
    this.alarmEventServiceClient =
      new alarmEventService.AlarmEventsServicePromiseClient(
        alarmURL,
        null,
        null
      );
    this.authToken = localStorage.getItem("authToken");
    this.metadata = { authorization: "Bearer " + this.authToken };
  }

  public static getInstance(): AlarmsEventsService {
    if (!AlarmsEventsService.instance) {
      AlarmsEventsService.instance = new AlarmsEventsService();
    }
    return AlarmsEventsService.instance;
  }

  getAlarmsDashboardData = (payload: any) => {
    const request = new this.alarmEventProtoObj.AlarmDashboardRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedBy("brabo-web");
    generalRequest.setRequestedService("alarm-dashboard-data");
    request.setGeneralRequest(generalRequest);
    const alarmFilterReq = new this.alarmEventProtoObj.AlarmDashboardFilter();
    const alarmFilters = new this.commonProtoObj.Filter();
    alarmFilters.setStartTime(payload.startTime);
    alarmFilters.setNodeidsList(payload.nodeId);
    alarmFilters.setEndTime(payload.endTime);
    alarmFilterReq.setFilter(alarmFilters);
    alarmFilterReq.setSeverity(payload.severity);
    alarmFilterReq.setPageSize(50);
    alarmFilterReq.setPageCount(payload.page);
    request.setAlarmDashboardFilter(alarmFilterReq);
    return this.alarmEventServiceClient
      .getAlarmDashboardOverview(request, this.metadata)
      .then((response: any) => {
        return mapAlarmsEventsDashboard(response.getAlarmAndEventDashboard());
      })
      .catch((err: any) => {
        message.error("Unable to get alarms dashboard data");
      });
  };

  getAlarmsDashboardStackedChartData = (payload: any) => {
    const request = new this.alarmEventProtoObj.AlarmDashboardRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedBy("brabo-web");
    generalRequest.setRequestedService("alarm-dashboard-data");
    request.setGeneralRequest(generalRequest);
    const alarmFilterReq = new this.alarmEventProtoObj.AlarmDashboardFilter();
    const alarmFilters = new this.commonProtoObj.Filter();
    alarmFilters.setStartTime(payload.startTime);
    alarmFilters.setNodeidsList(payload.nodeId);
    alarmFilters.setEndTime(payload.endTime);
    alarmFilterReq.setFilter(alarmFilters);
    alarmFilterReq.setSeverity(payload.severity);
    alarmFilterReq.setPageSize(50);
    alarmFilterReq.setPageCount(payload.page);
    alarmFilterReq.setType(payload.type);
    request.setAlarmDashboardFilter(alarmFilterReq);
    return this.alarmEventServiceClient
      .getFilteredStackedBarChart(request, this.metadata)
      .then((response: any) => {
        return mapAlarmsEventsDashboardStackedChartData(response);
      })
      .catch((err: any) => {
        message.error("Unable to get alarms stacked graph data");
      });
  };

  getAlarmsDashboardParetoChartData = (payload: any) => {
    const request = new this.alarmEventProtoObj.AlarmDashboardRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedBy("brabo-web");
    generalRequest.setRequestedService("alarm-dashboard-data");
    request.setGeneralRequest(generalRequest);
    const alarmFilterReq = new this.alarmEventProtoObj.AlarmDashboardFilter();
    const alarmFilters = new this.commonProtoObj.Filter();
    alarmFilters.setStartTime(payload.startTime);
    alarmFilters.setNodeidsList(payload.nodeId);
    alarmFilters.setEndTime(payload.endTime);
    alarmFilterReq.setFilter(alarmFilters);
    alarmFilterReq.setSeverity(payload.severity);
    alarmFilterReq.setPageSize(50);
    alarmFilterReq.setPageCount(payload.page);
    alarmFilterReq.setType(payload.type);
    request.setAlarmDashboardFilter(alarmFilterReq);
    return this.alarmEventServiceClient
      .getFilteredParetoChart(request, this.metadata)
      .then((response: any) => {
        return mapAlarmsEventsDashboardParetoChartData(response);
      })
      .catch((err: any) => {
        message.error("Unable to get Pareto data");
      });
  };

  getAlarmsTableData = (payload: any) => {
    const request = new this.alarmEventProtoObj.AlarmDashboardRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedBy("brabo-web");
    generalRequest.setRequestedService("alarm-dashboard-data");
    request.setGeneralRequest(generalRequest);
    const alarmFilterReq = new this.alarmEventProtoObj.AlarmDashboardFilter();
    const alarmFilters = new this.commonProtoObj.Filter();
    alarmFilters.setNodeidsList(payload.nodeId);
    alarmFilters.setStartTime(payload.startTime);
    alarmFilters.setEndTime(payload.endTime);
    alarmFilterReq.setFilter(alarmFilters);
    alarmFilterReq.setSeverity(payload.severity);
    alarmFilterReq.setPageSize(50);
    alarmFilterReq.setPageCount(payload.page);
    request.setAlarmDashboardFilter(alarmFilterReq);
    return this.alarmEventServiceClient
      .getAlarmDetailsOverview(request, this.metadata)
      .then((response: any) => {
        return mapAlarmsEventsTableData(response);
      })
      .catch((err: any) => {
        message.error("Unable to get alarms table data");
      });
  };

  getGuageData = (payload: any) => {
    const request = new this.alarmEventProtoObj.AlarmDashboardRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedBy("brabo-web");
    generalRequest.setRequestedService("alarm-dashboard-data");
    request.setGeneralRequest(generalRequest);
    const alarmFilterReq = new this.alarmEventProtoObj.AlarmDashboardFilter();
    const alarmFilters = new this.commonProtoObj.Filter();
    alarmFilters.setNodeidsList(payload.nodeId);
    alarmFilters.setStartTime(payload.startTime);
    alarmFilters.setEndTime(payload.endTime);
    alarmFilterReq.setFilter(alarmFilters);
    alarmFilterReq.setSeverity(payload.severity);
    alarmFilterReq.setPageSize(50);
    alarmFilterReq.setPageCount(payload.page);
    request.setAlarmDashboardFilter(alarmFilterReq);
    return this.alarmEventServiceClient
      .getAlarmAndSummaryPieChart(request, this.metadata)
      .then((response: any) => {
        return mapAlarmsEventsGuageData(response);
      })
      .catch((err: any) => {
        console.error(err);
        message.error("Unable to get Guage data");
      });
  };

  getAlarmsDetailsData = (payload: any) => {
    const request = new this.alarmEventProtoObj.AlarmDashboardRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedBy("brabo-web");
    generalRequest.setRequestedService("alarm-dashboard-data");
    request.setGeneralRequest(generalRequest);
    const alarmFilterReq = new this.alarmEventProtoObj.AlarmDashboardFilter();
    const alarmFilters = new this.commonProtoObj.Filter();
    alarmFilters.setNodeidsList(payload.nodeId);
    alarmFilters.setStartTime(payload.startTime);
    alarmFilters.setEndTime(payload.endTime);
    alarmFilterReq.setFilter(alarmFilters);
    alarmFilterReq.setSeverity(payload.severity);
    alarmFilterReq.setPageSize(50);
    alarmFilterReq.setPageCount(payload.page);
    if (payload.tagSelected.length)
      alarmFilterReq.setTagSelected(payload.tagSelected[0].tagUuid);
    request.setAlarmDashboardFilter(alarmFilterReq);
    return this.alarmEventServiceClient
      .getAllAlarmDetailsOverview(request, this.metadata)
      .then((response: any) => {
        return mapAlarmsEventsDetails(response.getActiveAlarmOverview());
      })
      .catch((err: any) => {
        message.error("Unable to get alarms details data");
      });
  };
}
