import { CaretDownOutlined } from "@ant-design/icons";
import { Tree } from "antd";
import type { TreeProps } from "antd/es/tree";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getTagMappingTree } from 'store/actions/dataVisualization.actions';



export interface DeviceDescData {
    tagName: string;
    id: string;
    description: string;
    key: string;
}

export const AssignmentListView = () => {
    const onSelect: TreeProps["onSelect"] = (selectedKeys, info) => { };

    const dispatch = useDispatch();
    const assetHeirarchyTree = useSelector(
        (state: any) => state.dataVisualizationReducer.tagMappingTree
    );
    const id = 2;
    useEffect(() => {
        dispatch(getTagMappingTree({ id: 2, cloudPushBool: 0 }));
    }, [id])

    return (
        <Tree
            showLine
            switcherIcon={<CaretDownOutlined />}
            autoExpandParent={true}
            defaultExpandAll={true}
            onSelect={onSelect}
            defaultExpandParent={true}
            treeData={assetHeirarchyTree ? assetHeirarchyTree.children : []}
            showIcon={true}
        />
    )
}