import {
  DatePicker,
  message,
  Popconfirm,
  Table,
  Radio,
  Switch,
  Select,
  Pagination,
} from "antd";
import { CustomButton } from "../../../components/Common/Button";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { ascendingSort, dateConverter, decendingSort } from "utils/common";
import { TagsFilterPanel } from "../TagsFilterPanel/index";
import { VList } from "utils/virtualTable/index";
import "../tagsDataTrending.scss";
import { ReactComponent as UpArrow } from "assets/icons/up-arrow.svg";
import { ReactComponent as DownArrow } from "assets/icons/down-arrow.svg";
import { ClockCircleOutlined, FilterOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import {
  getAggregatedDataForTagAction,
  getAllTags,
  getDatatrendingViewDetails,
  getDatatrendingViewOptions,
  saveCurrentView,
} from "store/actions/dataVisualization.actions";
import { useDispatch, useSelector } from "react-redux";
import { GraphContainer } from "../GraphContainer";
import {
  setFilterUpdatedFlag,
  setFilterValuesDataVisualization,
} from "store/actions/rootAction";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import UplLogo from "assets/images/uplLogo.png";
import convertHoursToMinutes from "utils/convertHoursToMinutes";
import dynamicRangeHandler from "utils/dynamicRangeHandler";
import momentTimezone from "moment-timezone";
import { RangeFilters } from "utils/enums";

const { RangePicker } = DatePicker;
const { Option } = Select;
export const TrendingDashboard: any = () => {
  const tagsOverviewData = useSelector(
    (state: any) => state.dataVisualizationReducer.graphData
  );
  const dataTrendingViewOptions = useSelector(
    (state: any) => state.dataVisualizationReducer.dataTrendingViewOptions
  );
  let currentViewDetails = useSelector(
    (state: any) => state.dataVisualizationReducer.currentViewDetails
  );
  const navigate = useNavigate();

  const [graphType, setGraphType] = useState("line");
  const [page, setPage] = useState<number>(1);
  const startTimeRedux = useSelector(
    (state: any) => state.root.filterValuesDataVisualization.startTime
  );
  const endTimeRedux = useSelector(
    (state: any) => state.root.filterValuesDataVisualization.endTime
  );

  const [shiftStartTime, setShiftStartTime] = useState(startTimeRedux);
  const [shiftEndTime, setShiftEndTime] = useState(endTimeRedux);

  const datePickerChangeHandler = (value) => {
    if (selectedValue === "Shift A") {
      setDateRange([moment(
        momentTimezone
          .tz(`${value.format("YYYY-MM-DD")} 08:00:00`, "Europe/London")
          .valueOf()
      ),
      moment(
        momentTimezone
          .tz(`${value.format("YYYY-MM-DD")} 15:59:59`, "Europe/London")
          .valueOf()
      )])
      setShiftStartTime(
        momentTimezone
          .tz(`${value.format("YYYY-MM-DD")} 08:00:00`, "Europe/London")
          .valueOf()
      );
      setShiftEndTime(
        momentTimezone
          .tz(`${value.format("YYYY-MM-DD")} 15:59:59`, "Europe/London")
          .valueOf()
      );
    } else if (selectedValue === "Shift B") {
      let startTime= momentTimezone
      .tz(`${value.format("YYYY-MM-DD")} 16:00:00`, "Europe/London")
      .valueOf();
      setDateRange([moment(startTime),
      moment(
        momentTimezone(
        moment(startTime).add(28799,"seconds"),"Europe/London").valueOf()
        )])
      setShiftStartTime(
        startTime
      );
      setShiftEndTime(
        momentTimezone(
          moment(startTime).add(28799,"seconds"),"Europe/London").valueOf()
      );
    } else {
      let startTime= momentTimezone
      .tz(`${value.format("YYYY-MM-DD")} 16:00:00`, "Europe/London").valueOf()
        let startTimeWith8HoursAddition= momentTimezone(
        moment(startTime).add(28800,"seconds"),"Europe/London").valueOf()
        let endTimeWith16HoursAddition= momentTimezone(
          moment(startTime).add(57599,"seconds"),"Europe/London").valueOf()
      setDateRange([
        moment(startTimeWith8HoursAddition),
        moment(endTimeWith16HoursAddition)]
      );
      setShiftStartTime(
        startTimeWith8HoursAddition
      );
      setShiftEndTime(
        endTimeWith16HoursAddition
      );
    }
  };
  const [dateRange, setDateRange] = useState<any>([
    moment(startTimeRedux),
    moment(endTimeRedux),
  ]);
  const filterFlag = useSelector((state: any) => state.root.filterFlag);

  useEffect(() => {
    setDateRange([moment(startTimeRedux), moment(endTimeRedux)]);
  }, [startTimeRedux, filterFlag]);

  const [tableData, setTableData] = useState<any>([]);
  const [selectedTagsForGraph, setSelectedTagsForGraph] = useState<
    Array<object>
  >([]);
  const [selectedTagsDataForGraph, setSelectedTagsDataForGraph] = useState<
    Array<string>
  >([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const dispatch = useDispatch();
  const selectedTagIdsRef = useRef<Array<string>>([]);
  const [legendsColor, setLegendsColor] = useState<any>();
  const filterValuesDataVisualizationRedux = useSelector(
    (state: any) => state.root.filterValuesDataVisualization.selectedRange
  );

  const [selectedTimeValue, setSelectedTimeValue] = useState<any>(
    filterValuesDataVisualizationRedux
  );
  useEffect(() => {
    setSelectedTimeValue(filterValuesDataVisualizationRedux);
  }, [filterValuesDataVisualizationRedux]);
  const [sparklineData, setSparklineData] = useState<any>();
  const [currentViewID, setCurrentViewID] = useState<number>();

  let modelNameRedux = useSelector(
    (state: any) => state.root.filterValuesDataVisualization.modelName
  );
  let nodeNameRedux = useSelector(
    (state: any) => state.root.filterValuesDataVisualization.nodeName
  );

  const [modelName, setModelName] = useState<string>(modelNameRedux);
  const [nodeName, setNodeName] = useState<string>(nodeNameRedux);

  useEffect(() => {
    setModelName(modelNameRedux);
    setNodeName(nodeNameRedux);
  }, [modelNameRedux, nodeNameRedux]);
  const columns = [
    {
      title: "Tag Name",
      dataIndex: "tagName",
      ellipsis: false,
      key: "tagName",
    },
    {
      title: "Description",
      dataIndex: "tagDescription",
      ellipsis: true,
      key: "tagDescription",
    },
    {
      title: "Device Name",
      dataIndex: "deviceName",
      ellipsis: true,
      key: "deviceName",
    },
    {
      title: (
        <>
          <div className="sort-arrow-container">
            Unit
            <div className="sort-arrows">
              <UpArrow
                className="up-arrow"
                onClick={() =>
                  setTableData(ascendingSort("Unit", [...tableData]))
                }
              />
              <DownArrow
                className="down-arrow"
                onClick={() =>
                  setTableData(decendingSort("Unit", [...tableData]))
                }
              />
            </div>
          </div>
        </>
      ),
      dataIndex: "unit",
      ellipsis: true,
      key: "unit",
    },
    {
      title: (
        <>
          <div className="sort-arrow-container">
            Min Range
            <div className="sort-arrows">
              <UpArrow
                className="up-arrow"
                onClick={() =>
                  setTableData(ascendingSort("tagName", [...tableData]))
                }
              />
              <DownArrow
                className="down-arrow"
                onClick={() =>
                  setTableData(decendingSort("tagName", [...tableData]))
                }
              />
            </div>
          </div>
        </>
      ),
      dataIndex: "minRange",
      ellipsis: true,
      key: "minRange",
    },
    {
      title: (
        <>
          <div className="sort-arrow-container">
            Max Range
            <div className="sort-arrows">
              <UpArrow
                className="up-arrow"
                onClick={() =>
                  setTableData(ascendingSort("maxRange", [...tableData]))
                }
              />
              <DownArrow
                className="down-arrow"
                onClick={() =>
                  setTableData(decendingSort("maxRange", [...tableData]))
                }
              />
            </div>
          </div>
        </>
      ),
      dataIndex: "maxRange",
      ellipsis: true,
      key: "maxRange",
    },
    {
      title: (
        <>
          <div className="sort-arrow-container">
            <FilterOutlined />
          </div>
        </>
      ),
      key: "action",
      render: (text: any, record: any) => (
        <Popconfirm
          placement="top"
          title={
            <>
              <div>Warning Delete Tag Selection</div>
              <div>
                Are you sure you want to delete selected Tag Selection ?
              </div>
            </>
          }
          okText="Confirm"
          cancelText="Cancel"
          onConfirm={() => handleDeleteTag(record)}
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getDatatrendingViewOptions("Data-Table"));
  }, [tagsOverviewData]);

  useEffect(() => {
    if (currentViewID !== undefined) {
      dispatch(getDatatrendingViewDetails(currentViewID));
    }
  }, [currentViewID]);

  useEffect(() => {
    let currentGraphTagsData: any = [];
    selectedTagsForGraph.forEach((selectedGraphTag) => {
      currentGraphTagsData.push(selectedGraphTag);
    });
    setSelectedTagsDataForGraph(currentGraphTagsData);
  }, [selectedTagsForGraph]);

  useEffect(() => {
    if (currentViewDetails && Object.keys(currentViewDetails).length > 0) {
      setSelectedTagsDataForGraph(currentViewDetails.currentTags);
      let currentDateRange = currentViewDetails.dateRange;
      currentDateRange[0] = moment(currentDateRange[0]);
      currentDateRange[1] = moment(currentDateRange[1]);
      setDateRange(currentDateRange);
      setGraphType(currentViewDetails.graphType);
      setSelectedTagsForGraph(currentViewDetails.selectedTags);
      setSparklineData(currentViewDetails.sparklineData);
      setTableData(currentViewDetails.tableData);

      setNodeName(currentViewDetails.nodeName);
      setModelName(currentViewDetails.modelName);
      setSelectedKeys(currentViewDetails.selectedRowKeys);
      dispatch(
        getAllTags({
          status: 0,
        })
      );
      dispatch(
        getAggregatedDataForTagAction({
          selectedRowKeysGraph: currentViewDetails.selectedTags,
          dateRange: currentDateRange,
        })
      );
    }
  }, [currentViewDetails]);

  const handleDateChangeApiCall = (
    selectedTagsForGraph?: Object,
    customDateRange?: any
  ) => {
    dispatch(
      getAggregatedDataForTagAction({
        selectedRowKeysGraph: selectedTagsForGraph,
        dateRange: customDateRange ? customDateRange : dateRange,
        pageId: page,
      })
    );
  };
  const onDateRangeChange = (dateRange: any) => {
    if (dateRange) {
      setDateRange([dateRange[0], dateRange[1]]);
    } else {
      setDateRange(null);
    }
  };

  const handleLegendsColor = (legendsColorObj: any) => {
    setLegendsColor(legendsColorObj);
  };

  const handleDateRangeChange = (selectedDateRange: any) => {
    if (selectedDateRange) {
      setDateRange([selectedDateRange[0], selectedDateRange[1]]);
    }
  };

  const handleDeleteTag = (record: any) => {
    let tagsAfterDeletion: any = [];
    let tagKeysAfterDeletion: any = [];
    let tableDataAfterDeletion = tableData.filter(function (element: any) {
      return element["tagName"] !== record["tagName"];
    });

    tableDataAfterDeletion.forEach((selectedTag: any) => {
      tagsAfterDeletion.push(selectedTag);
      tagKeysAfterDeletion.push(selectedTag["key"]);
    });
    setSelectedTagsForGraph(tagsAfterDeletion);
    setTableData(tableDataAfterDeletion);
    setSelectedKeys(tagKeysAfterDeletion);
    handleDateChangeApiCall(tagsAfterDeletion);
  };

  const setdefaultDateTime = () => {
    let last24hoursDate = moment(
      new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    );
    setDateRange([last24hoursDate, moment(new Date())]);
  };

  const handleApply = () => {
    if (selectedTagsForGraph.length > 0) {
      dispatch(setFilterUpdatedFlag());
      dispatch(
        setFilterValuesDataVisualization({
          key: "startTime",
          value: dateRange[0].valueOf(),
        })
      );
      dispatch(
        setFilterValuesDataVisualization({
          key: "endTime",
          value: dateRange[1].valueOf(),
        })
      );
      dispatch(
        setFilterValuesDataVisualization({
          key: "selectedRange",
          value: selectedTimeValue,
        })
      );
      dispatch(
        getAggregatedDataForTagAction({
          selectedRowKeysGraph: selectedTagsForGraph,
          dateRange: dateRange,
        })
      );
      dispatch(
        setFilterValuesDataVisualization({
          key: "modelName",
          value: modelName,
        })
      );
      dispatch(
        setFilterValuesDataVisualization({
          key: "nodeName",
          value: nodeName,
        })
      );
    } else {
      message.info("Please Select Tag!");
    }
  };

  const handleSelectedTags = (tagIds: Array<any>) => {
    let selectedRowKeysGraph: any = [...selectedTagsForGraph];
    let selectedRowKeys: any = [...selectedKeys];
    let selectedTags: any = [...tableData];
    if (selectedTagIdsRef.current.length < tagIds.length) {
      for (let i = 1; i <= tagIds.length; i++) {
        const lastTagId = tagIds[tagIds.length - i]["tagId"];
        const lastTagKey = tagIds[tagIds.length - i]["key"];
        const tagName = tagIds[tagIds.length - i]["tagName"];
        if (selectedRowKeys.indexOf(lastTagKey) != -1) {
          message.warning("Tag " + tagName + " already added.", 1);
        } else {
          selectedRowKeysGraph.push(tagIds[tagIds.length - i]);
          selectedRowKeys.push(tagIds[tagIds.length - i]["key"]);
          selectedTags.push(tagIds[tagIds.length - i]);
          setTableData(selectedTags);
          setSelectedTagsForGraph(selectedRowKeysGraph);
          setSelectedKeys(selectedRowKeys);
        }
      }
    }
  };

  const updateGraphType = (type: any) => {
    setGraphType(type);
  };

  const rowSelection = {
    hideSelectAll: true,
    selectedRowKeys: selectedKeys,
    selectedRowKeysGraph: selectedTagsForGraph,
    onChange: (selectedRowKeys: any, selectedRowKeysGraph: any) => {
      setSelectedKeys(selectedRowKeys);
      setSelectedTagsForGraph(selectedRowKeysGraph);
    },
  };
  const [selectedValue, setSelectedValue] = useState("Today");

  const handleUserSelectedTime = (value: any) => {
    setSelectedValue(value);
  };

  const timeData = [
    "Shift A",
    "Shift B",
    "Shift C",
    "Today",
    "Yesterday",
    "This Week",
    "Last Week",
    "MTD",
    "YTD",
  ];

  const rangeHandler = (value) => {
    setDateRange([moment(value[0]), moment(value[1])]);
  };

  useEffect(() => {
    dynamicRangeHandler(selectedValue, rangeHandler,shiftStartTime,setShiftStartTime,setShiftEndTime);
  }, [selectedValue]);

  const handleReset = () => {
    setTableData([]);
    setSelectedTagsForGraph([]);
    handleUserSelectedTime({
      category: "Day",
      value: "86400000",
    });
    setdefaultDateTime();
    setNodeName("");
    setModelName("");
  };

  const filterTreeData = useSelector((state: any) => state.root.filterTree);
  const allNode = (data: any) => {
    let node = [] as any;
    let result = [] as any;
    data?.map((item: any) => {
      if (item.children) {
        node = allNode(item.children);
      }
      result.push(...[{ name: item.title, key: item.key }, ...node]);
    });
    return result;
  };
  const allKeys = allNode(filterTreeData?.children);

  const selectedNodeDetails = allKeys?.find((item: any) => {
    if (item.key === nodeName) {
      return item;
    }
  });
  const selectedNodeName = "Node Name: " + selectedNodeDetails?.name;
  const startDate = "StartTime :" + dateConverter(dateRange[0]);
  const endDate = "EndTime :" + dateConverter(dateRange[1]);
  const selectedModelName = "ModelName :" + modelName;
  const tagName = selectedTagsForGraph?.map((item: any) => {
    return item.tagName?.toString();
  });
  const selectedTagName = "Tag Name: " + tagName?.toString();
  const exportImagePdf = async () => {
    const header = [
      "Tag Name",
      "Description",
      "Device Name",
      "Unit",
      "Min Range",
      "Max Range",
    ];
    const rows =
      tableData?.length > 0 &&
      tableData?.map((elt) => [
        elt["tagName"],
        elt["tagDescription"],
        elt["deviceName"],
        elt["unit"],
        elt["minRange"],
        elt["maxRange"],
      ]);
    message.loading("downloading pdf started", 10);
    const unit = "pt";
    const size = "A4";
    const doc: any = new jsPDF("p", unit, size);
    let chartImage: any;
    const otherCharts = window.document.getElementById(
      "dashboard-charts-pdf"
    ) as HTMLElement;
    await html2canvas(otherCharts).then((canvas) => {
      chartImage = canvas.toDataURL("image/png", 1.0);
    });
    doc.setFont(undefined, "bold");
    doc.addImage(UplLogo, "PNG", 280, 7, 36, 36);
    doc.setFontSize(18);
    doc.text(
      30,
      65,
      "Data visualization Trending Report-UPL Europe (Sandbach Plant)"
    );
    doc.setFontSize(15);
    doc.text(20, 90, "Report Information");
    doc.setFont(undefined, "normal");
    doc.text(20, 110, startDate, "left", "5");
    doc.text(20, 125, endDate);
    doc.text(20, 140, selectedModelName);
    doc.text(20, 155, selectedNodeName);
    doc.text(20, 170, selectedTagName);
    doc.addImage(chartImage, "PNG", 20, 195, 560, 300);
    doc.addPage();
    const headers = [header];
    const data = rows;
    message.info(data["length"] + " record(s) are getting downloaded");
    doc.setFontSize(18);
    doc.setFont(undefined, "bold");
    doc.text(210, 30, "Selected Tag Details");
    autoTable(doc, {
      head: headers,
      body: data,
      styles: { fontSize: 8 },
      margin: { top: 40 },
    });
    doc.save(
      "Data_Visualization_Trending" +
        "_" +
        moment().format("DD/MM/YYYY_HH:MM:SS")
    );
  };

  return (
    <>
      <div className="trending-container">
        <div className="trending-container-buttons">
          <div className="time-selector">
            <p>Quick Time Selector : </p>
            <Select
              style={{
                width: "140px",
                height: "30px",
              }}
              className="time-selector-select"
              showSearch={false}
              value={selectedValue}
              defaultValue={"This Week"}
              optionFilterProp="children"
              onChange={handleUserSelectedTime}
              filterOption={true}
              suffixIcon={<DownArrow />}
            >
              {timeData?.map((item: any) => {
                return (
                  <>
                    <Option value={item}>{item}</Option>
                  </>
                );
              })}
              ;
            </Select>
            {/* {" "}
                        <InfoCircleOutlined style={{ margin: "0 8px 0 4px", color: "rgba(0, 0, 0, 0.45)" }} />
                        <Radio.Group defaultValue="28800000" onChange={handleUserSelectedTime}>
                            <Radio.Button value="35500000">Current Shift</Radio.Button>
                            <Radio.Button value="86400000">Day</Radio.Button>
                            <Radio.Button value="604800000">Week</Radio.Button>
                            <Radio.Button value="2592000000">Month</Radio.Button>
                        </Radio.Group> */}

{!selectedValue.includes("Shift") ? (
            <RangePicker
              suffixIcon={<ClockCircleOutlined />}
              allowClear={true}
              value={dateRange}
              showTime={{ format: 'HH:mm:ss' }}
              className="date-picker"
              format={"DD-MM-YYYY HH:mm:ss"}
              onChange={onDateRangeChange}
            />
)
                    : (
                      <>
                        <DatePicker
                            //Note : As per discussion with Devendra this is done specific to UPL
                          value={selectedValue===RangeFilters.SHIFT_C? moment(shiftStartTime).subtract(1,"days"):moment(shiftStartTime)}
                          onChange={datePickerChangeHandler}
                        />
                        <div className="shift">
                          {moment(shiftStartTime).format("DD-MM-YYYY HH:mm:ss")} to{" "}
                          {moment(shiftEndTime).format("DD-MM-YYYY HH:mm:ss")}
                        </div>
                      </>
                    )}
          </div>
          <div className="dropdownFiltercard-right-container flex-gap">
            <CustomButton type="Apply" onClick={handleApply} />
            <CustomButton type="Reset" onClick={handleReset} />
            <CustomButton type="Download" onClick={() => exportImagePdf()} />
            {/* <div style={{ display: "flex", marginTop: "4px" }}><div style={{ color: '#BFBFBF', paddingRight: "4px" }}>Live</div><Switch defaultChecked={false} onChange={onChangeLive} /></div> */}
          </div>
        </div>
        <div className="trending-container-body">
          <div className="filter-col">
            <TagsFilterPanel
              rowselection={true}
              selectedTag={handleSelectedTags}
              extraRequired={false}
              selectionType={"checkbox"}
              modelName={modelName}
              nodeName={nodeName}
              setModelName={setModelName}
              setNodeName={setNodeName}
            />
          </div>
          <div className="graph-col">
            <div id="dashboard-charts-pdf">
              <GraphContainer
                graphType={graphType}
                selectedTags={selectedTagsForGraph}
                updateGraphType={updateGraphType}
                currentTags={selectedTagsDataForGraph}
                currentNodeName={nodeName}
                handleSelectedDateRange={handleDateRangeChange}
                setLegendsColor={handleLegendsColor}
                setStreamSparkline={setSparklineData}
                sparklineData={sparklineData}
              />
            </div>
            <div className="tags-data-trending">
              <Table
                columns={columns}
                dataSource={tableData}
                size="small"
                pagination={false}
                scroll={{ y: "calc(100vh - 712px)" }}
                components={VList({ height: 1000 })}
                rowSelection={rowSelection}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <SaveModal
        showSaveModalPopUp={showSavePopup}
        handleCancel={handleCancel}
        handleOk={handleOk}
      /> */}
    </>
  );
};
