/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: service/AssetRpc.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_Enums_pb = require('../common/Enums_pb.js')

var common_BaseMessage_pb = require('../common/BaseMessage_pb.js')

var common_API_pb = require('../common/API_pb.js')

var objects_Asset_pb = require('../objects/Asset_pb.js')
const proto = require('./AssetRpc_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.ModelServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.ModelServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ModelOverviewParameters,
 *   !proto.ModelsOverviewList>}
 */
const methodDescriptor_ModelService_getList = new grpc.web.MethodDescriptor(
  '/ModelService/getList',
  grpc.web.MethodType.UNARY,
  proto.ModelOverviewParameters,
  proto.ModelsOverviewList,
  /**
   * @param {!proto.ModelOverviewParameters} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ModelsOverviewList.deserializeBinary
);


/**
 * @param {!proto.ModelOverviewParameters} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ModelsOverviewList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ModelsOverviewList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ModelServiceClient.prototype.getList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ModelService/getList',
      request,
      metadata || {},
      methodDescriptor_ModelService_getList,
      callback);
};


/**
 * @param {!proto.ModelOverviewParameters} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ModelsOverviewList>}
 *     Promise that resolves to the response
 */
proto.ModelServicePromiseClient.prototype.getList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ModelService/getList',
      request,
      metadata || {},
      methodDescriptor_ModelService_getList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Model,
 *   !proto.ApiResponse>}
 */
const methodDescriptor_ModelService_upsert = new grpc.web.MethodDescriptor(
  '/ModelService/upsert',
  grpc.web.MethodType.UNARY,
  objects_Asset_pb.Model,
  common_API_pb.ApiResponse,
  /**
   * @param {!proto.Model} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_API_pb.ApiResponse.deserializeBinary
);


/**
 * @param {!proto.Model} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ModelServiceClient.prototype.upsert =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ModelService/upsert',
      request,
      metadata || {},
      methodDescriptor_ModelService_upsert,
      callback);
};


/**
 * @param {!proto.Model} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ApiResponse>}
 *     Promise that resolves to the response
 */
proto.ModelServicePromiseClient.prototype.upsert =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ModelService/upsert',
      request,
      metadata || {},
      methodDescriptor_ModelService_upsert);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ID,
 *   !proto.ApiResponse>}
 */
const methodDescriptor_ModelService_delete = new grpc.web.MethodDescriptor(
  '/ModelService/delete',
  grpc.web.MethodType.UNARY,
  common_API_pb.ID,
  common_API_pb.ApiResponse,
  /**
   * @param {!proto.ID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_API_pb.ApiResponse.deserializeBinary
);


/**
 * @param {!proto.ID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ModelServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ModelService/delete',
      request,
      metadata || {},
      methodDescriptor_ModelService_delete,
      callback);
};


/**
 * @param {!proto.ID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ApiResponse>}
 *     Promise that resolves to the response
 */
proto.ModelServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ModelService/delete',
      request,
      metadata || {},
      methodDescriptor_ModelService_delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ID,
 *   !proto.ModelDetails>}
 */
const methodDescriptor_ModelService_getDetails = new grpc.web.MethodDescriptor(
  '/ModelService/getDetails',
  grpc.web.MethodType.UNARY,
  common_API_pb.ID,
  proto.ModelDetails,
  /**
   * @param {!proto.ID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ModelDetails.deserializeBinary
);


/**
 * @param {!proto.ID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ModelDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ModelDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ModelServiceClient.prototype.getDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ModelService/getDetails',
      request,
      metadata || {},
      methodDescriptor_ModelService_getDetails,
      callback);
};


/**
 * @param {!proto.ID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ModelDetails>}
 *     Promise that resolves to the response
 */
proto.ModelServicePromiseClient.prototype.getDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ModelService/getDetails',
      request,
      metadata || {},
      methodDescriptor_ModelService_getDetails);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.NodeServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.NodeServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ModelTreeParameters,
 *   !proto.NodeDetails>}
 */
const methodDescriptor_NodeService_getTree = new grpc.web.MethodDescriptor(
  '/NodeService/getTree',
  grpc.web.MethodType.UNARY,
  proto.ModelTreeParameters,
  proto.NodeDetails,
  /**
   * @param {!proto.ModelTreeParameters} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.NodeDetails.deserializeBinary
);


/**
 * @param {!proto.ModelTreeParameters} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.NodeDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.NodeDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.NodeServiceClient.prototype.getTree =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/NodeService/getTree',
      request,
      metadata || {},
      methodDescriptor_NodeService_getTree,
      callback);
};


/**
 * @param {!proto.ModelTreeParameters} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.NodeDetails>}
 *     Promise that resolves to the response
 */
proto.NodeServicePromiseClient.prototype.getTree =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/NodeService/getTree',
      request,
      metadata || {},
      methodDescriptor_NodeService_getTree);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Node,
 *   !proto.ApiResponse>}
 */
const methodDescriptor_NodeService_upsert = new grpc.web.MethodDescriptor(
  '/NodeService/upsert',
  grpc.web.MethodType.UNARY,
  objects_Asset_pb.Node,
  common_API_pb.ApiResponse,
  /**
   * @param {!proto.Node} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_API_pb.ApiResponse.deserializeBinary
);


/**
 * @param {!proto.Node} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.NodeServiceClient.prototype.upsert =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/NodeService/upsert',
      request,
      metadata || {},
      methodDescriptor_NodeService_upsert,
      callback);
};


/**
 * @param {!proto.Node} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ApiResponse>}
 *     Promise that resolves to the response
 */
proto.NodeServicePromiseClient.prototype.upsert =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/NodeService/upsert',
      request,
      metadata || {},
      methodDescriptor_NodeService_upsert);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ID,
 *   !proto.ApiResponse>}
 */
const methodDescriptor_NodeService_delete = new grpc.web.MethodDescriptor(
  '/NodeService/delete',
  grpc.web.MethodType.UNARY,
  common_API_pb.ID,
  common_API_pb.ApiResponse,
  /**
   * @param {!proto.ID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_API_pb.ApiResponse.deserializeBinary
);


/**
 * @param {!proto.ID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.NodeServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/NodeService/delete',
      request,
      metadata || {},
      methodDescriptor_NodeService_delete,
      callback);
};


/**
 * @param {!proto.ID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ApiResponse>}
 *     Promise that resolves to the response
 */
proto.NodeServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/NodeService/delete',
      request,
      metadata || {},
      methodDescriptor_NodeService_delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.NodeDetailsParameters,
 *   !proto.NodeDetails>}
 */
const methodDescriptor_NodeService_getDetails = new grpc.web.MethodDescriptor(
  '/NodeService/getDetails',
  grpc.web.MethodType.UNARY,
  proto.NodeDetailsParameters,
  proto.NodeDetails,
  /**
   * @param {!proto.NodeDetailsParameters} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.NodeDetails.deserializeBinary
);


/**
 * @param {!proto.NodeDetailsParameters} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.NodeDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.NodeDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.NodeServiceClient.prototype.getDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/NodeService/getDetails',
      request,
      metadata || {},
      methodDescriptor_NodeService_getDetails,
      callback);
};


/**
 * @param {!proto.NodeDetailsParameters} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.NodeDetails>}
 *     Promise that resolves to the response
 */
proto.NodeServicePromiseClient.prototype.getDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/NodeService/getDetails',
      request,
      metadata || {},
      methodDescriptor_NodeService_getDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Empty,
 *   !proto.NodeDetailsList>}
 */
const methodDescriptor_NodeService_getStreamParentList = new grpc.web.MethodDescriptor(
  '/NodeService/getStreamParentList',
  grpc.web.MethodType.UNARY,
  common_API_pb.Empty,
  proto.NodeDetailsList,
  /**
   * @param {!proto.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.NodeDetailsList.deserializeBinary
);


/**
 * @param {!proto.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.NodeDetailsList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.NodeDetailsList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.NodeServiceClient.prototype.getStreamParentList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/NodeService/getStreamParentList',
      request,
      metadata || {},
      methodDescriptor_NodeService_getStreamParentList,
      callback);
};


/**
 * @param {!proto.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.NodeDetailsList>}
 *     Promise that resolves to the response
 */
proto.NodeServicePromiseClient.prototype.getStreamParentList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/NodeService/getStreamParentList',
      request,
      metadata || {},
      methodDescriptor_NodeService_getStreamParentList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GetChildNodesParams,
 *   !proto.NodeIdList>}
 */
const methodDescriptor_NodeService_getChildNodeIdList = new grpc.web.MethodDescriptor(
  '/NodeService/getChildNodeIdList',
  grpc.web.MethodType.UNARY,
  proto.GetChildNodesParams,
  proto.NodeIdList,
  /**
   * @param {!proto.GetChildNodesParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.NodeIdList.deserializeBinary
);


/**
 * @param {!proto.GetChildNodesParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.NodeIdList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.NodeIdList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.NodeServiceClient.prototype.getChildNodeIdList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/NodeService/getChildNodeIdList',
      request,
      metadata || {},
      methodDescriptor_NodeService_getChildNodeIdList,
      callback);
};


/**
 * @param {!proto.GetChildNodesParams} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.NodeIdList>}
 *     Promise that resolves to the response
 */
proto.NodeServicePromiseClient.prototype.getChildNodeIdList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/NodeService/getChildNodeIdList',
      request,
      metadata || {},
      methodDescriptor_NodeService_getChildNodeIdList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GetChildNodesParams,
 *   !proto.NodeChildMapList>}
 */
const methodDescriptor_NodeService_getAllChildNodesList = new grpc.web.MethodDescriptor(
  '/NodeService/getAllChildNodesList',
  grpc.web.MethodType.UNARY,
  proto.GetChildNodesParams,
  proto.NodeChildMapList,
  /**
   * @param {!proto.GetChildNodesParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.NodeChildMapList.deserializeBinary
);


/**
 * @param {!proto.GetChildNodesParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.NodeChildMapList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.NodeChildMapList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.NodeServiceClient.prototype.getAllChildNodesList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/NodeService/getAllChildNodesList',
      request,
      metadata || {},
      methodDescriptor_NodeService_getAllChildNodesList,
      callback);
};


/**
 * @param {!proto.GetChildNodesParams} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.NodeChildMapList>}
 *     Promise that resolves to the response
 */
proto.NodeServicePromiseClient.prototype.getAllChildNodesList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/NodeService/getAllChildNodesList',
      request,
      metadata || {},
      methodDescriptor_NodeService_getAllChildNodesList);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.StreamServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.StreamServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.StreamFilter,
 *   !proto.StreamOverviewList>}
 */
const methodDescriptor_StreamService_getList = new grpc.web.MethodDescriptor(
  '/StreamService/getList',
  grpc.web.MethodType.UNARY,
  proto.StreamFilter,
  proto.StreamOverviewList,
  /**
   * @param {!proto.StreamFilter} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.StreamOverviewList.deserializeBinary
);


/**
 * @param {!proto.StreamFilter} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.StreamOverviewList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.StreamOverviewList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.StreamServiceClient.prototype.getList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/StreamService/getList',
      request,
      metadata || {},
      methodDescriptor_StreamService_getList,
      callback);
};


/**
 * @param {!proto.StreamFilter} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.StreamOverviewList>}
 *     Promise that resolves to the response
 */
proto.StreamServicePromiseClient.prototype.getList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/StreamService/getList',
      request,
      metadata || {},
      methodDescriptor_StreamService_getList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Stream,
 *   !proto.ApiResponse>}
 */
const methodDescriptor_StreamService_updateStreamNodeMapping = new grpc.web.MethodDescriptor(
  '/StreamService/updateStreamNodeMapping',
  grpc.web.MethodType.UNARY,
  objects_Asset_pb.Stream,
  common_API_pb.ApiResponse,
  /**
   * @param {!proto.Stream} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_API_pb.ApiResponse.deserializeBinary
);


/**
 * @param {!proto.Stream} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.StreamServiceClient.prototype.updateStreamNodeMapping =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/StreamService/updateStreamNodeMapping',
      request,
      metadata || {},
      methodDescriptor_StreamService_updateStreamNodeMapping,
      callback);
};


/**
 * @param {!proto.Stream} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ApiResponse>}
 *     Promise that resolves to the response
 */
proto.StreamServicePromiseClient.prototype.updateStreamNodeMapping =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/StreamService/updateStreamNodeMapping',
      request,
      metadata || {},
      methodDescriptor_StreamService_updateStreamNodeMapping);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Uuid,
 *   !proto.ApiResponse>}
 */
const methodDescriptor_StreamService_delete = new grpc.web.MethodDescriptor(
  '/StreamService/delete',
  grpc.web.MethodType.UNARY,
  common_API_pb.Uuid,
  common_API_pb.ApiResponse,
  /**
   * @param {!proto.Uuid} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_API_pb.ApiResponse.deserializeBinary
);


/**
 * @param {!proto.Uuid} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.StreamServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/StreamService/delete',
      request,
      metadata || {},
      methodDescriptor_StreamService_delete,
      callback);
};


/**
 * @param {!proto.Uuid} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ApiResponse>}
 *     Promise that resolves to the response
 */
proto.StreamServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/StreamService/delete',
      request,
      metadata || {},
      methodDescriptor_StreamService_delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Uuid,
 *   !proto.StreamDetails>}
 */
const methodDescriptor_StreamService_getDetails = new grpc.web.MethodDescriptor(
  '/StreamService/getDetails',
  grpc.web.MethodType.UNARY,
  common_API_pb.Uuid,
  proto.StreamDetails,
  /**
   * @param {!proto.Uuid} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.StreamDetails.deserializeBinary
);


/**
 * @param {!proto.Uuid} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.StreamDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.StreamDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.StreamServiceClient.prototype.getDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/StreamService/getDetails',
      request,
      metadata || {},
      methodDescriptor_StreamService_getDetails,
      callback);
};


/**
 * @param {!proto.Uuid} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.StreamDetails>}
 *     Promise that resolves to the response
 */
proto.StreamServicePromiseClient.prototype.getDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/StreamService/getDetails',
      request,
      metadata || {},
      methodDescriptor_StreamService_getDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.StreamName,
 *   !proto.StreamDetails>}
 */
const methodDescriptor_StreamService_getDetailsByName = new grpc.web.MethodDescriptor(
  '/StreamService/getDetailsByName',
  grpc.web.MethodType.UNARY,
  proto.StreamName,
  proto.StreamDetails,
  /**
   * @param {!proto.StreamName} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.StreamDetails.deserializeBinary
);


/**
 * @param {!proto.StreamName} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.StreamDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.StreamDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.StreamServiceClient.prototype.getDetailsByName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/StreamService/getDetailsByName',
      request,
      metadata || {},
      methodDescriptor_StreamService_getDetailsByName,
      callback);
};


/**
 * @param {!proto.StreamName} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.StreamDetails>}
 *     Promise that resolves to the response
 */
proto.StreamServicePromiseClient.prototype.getDetailsByName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/StreamService/getDetailsByName',
      request,
      metadata || {},
      methodDescriptor_StreamService_getDetailsByName);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Empty,
 *   !proto.DataTypeList>}
 */
const methodDescriptor_StreamService_getDistinctEuList = new grpc.web.MethodDescriptor(
  '/StreamService/getDistinctEuList',
  grpc.web.MethodType.UNARY,
  common_API_pb.Empty,
  proto.DataTypeList,
  /**
   * @param {!proto.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.DataTypeList.deserializeBinary
);


/**
 * @param {!proto.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.DataTypeList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.DataTypeList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.StreamServiceClient.prototype.getDistinctEuList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/StreamService/getDistinctEuList',
      request,
      metadata || {},
      methodDescriptor_StreamService_getDistinctEuList,
      callback);
};


/**
 * @param {!proto.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.DataTypeList>}
 *     Promise that resolves to the response
 */
proto.StreamServicePromiseClient.prototype.getDistinctEuList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/StreamService/getDistinctEuList',
      request,
      metadata || {},
      methodDescriptor_StreamService_getDistinctEuList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Empty,
 *   !proto.DataTypeList>}
 */
const methodDescriptor_StreamService_getDistinctPrimitiveTypeList = new grpc.web.MethodDescriptor(
  '/StreamService/getDistinctPrimitiveTypeList',
  grpc.web.MethodType.UNARY,
  common_API_pb.Empty,
  proto.DataTypeList,
  /**
   * @param {!proto.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.DataTypeList.deserializeBinary
);


/**
 * @param {!proto.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.DataTypeList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.DataTypeList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.StreamServiceClient.prototype.getDistinctPrimitiveTypeList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/StreamService/getDistinctPrimitiveTypeList',
      request,
      metadata || {},
      methodDescriptor_StreamService_getDistinctPrimitiveTypeList,
      callback);
};


/**
 * @param {!proto.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.DataTypeList>}
 *     Promise that resolves to the response
 */
proto.StreamServicePromiseClient.prototype.getDistinctPrimitiveTypeList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/StreamService/getDistinctPrimitiveTypeList',
      request,
      metadata || {},
      methodDescriptor_StreamService_getDistinctPrimitiveTypeList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Stream,
 *   !proto.ApiResponse>}
 */
const methodDescriptor_StreamService_upsert = new grpc.web.MethodDescriptor(
  '/StreamService/upsert',
  grpc.web.MethodType.UNARY,
  objects_Asset_pb.Stream,
  common_API_pb.ApiResponse,
  /**
   * @param {!proto.Stream} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_API_pb.ApiResponse.deserializeBinary
);


/**
 * @param {!proto.Stream} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.StreamServiceClient.prototype.upsert =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/StreamService/upsert',
      request,
      metadata || {},
      methodDescriptor_StreamService_upsert,
      callback);
};


/**
 * @param {!proto.Stream} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ApiResponse>}
 *     Promise that resolves to the response
 */
proto.StreamServicePromiseClient.prototype.upsert =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/StreamService/upsert',
      request,
      metadata || {},
      methodDescriptor_StreamService_upsert);
};


module.exports = proto;

