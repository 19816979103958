import { useLayoutEffect, useRef } from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import * as am5xy from "@amcharts/amcharts5/xy";
import { HorizontalBarChartDataProps } from "types";

const seriescolor = [
  "#85A5FF",
  "#B37FEB",
  "#5CDBD3",
  "#95DE64",
  "#69C0FF",
  "#C767DC",
];

const StackedBar: React.FC<HorizontalBarChartDataProps> = ({
  chartID,
  data,
  numberFormat = "",
  strictMinMax = false,
  legendXPosition = 65,
  selectedFilter,
  screen = "",
  series,
}) => {
  const chartRef = useRef<any>(null);
  const currentSeries: any = useRef<any>(undefined);

  const applyFilter = () => {
    if (selectedFilter && selectedFilter === "all") return data;
    else {
      data = data.map((element: any) => {
        let tempObj = {
          equipmentName: element["equipmentName"],
          [`${selectedFilter}`]: element[`${selectedFilter}`],
        };

        return tempObj;
      });
    }
  };
  useLayoutEffect(() => {
    var root = am5.Root.new(chartID);

    root.setThemes([am5themes_Animated.new(root)]);
    chartRef.current = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      })
    );
    applyFilter();

    var yAxis = chartRef.current.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "equipmentName",
        renderer: am5xy.AxisRendererY.new(root, {
          minGridDistance: 0,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    let yRenderer = yAxis.get("renderer");
    yRenderer.labels.template.setAll({
      fontSize: "0.8em",
      visible: true,
    });

    yRenderer.grid.template.setAll({
      stroke: am5.color(0xffffff),
      strokeWidth: 0,
    });

    yAxis.data.setAll(data);

    var xAxis = chartRef.current.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        numberFormat:
          screen === "runtimeAnalysis" ||
          screen === "alarms" ||
          screen === "OeeDownTimeDuration"
            ? "# Min"
            : "#",
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );

    let xRenderer = xAxis.get("renderer");
    xRenderer.labels.template.setAll({
      fontSize: "0.8em",
      visible: true,
    });

    var legend = chartRef.current.children.push(
      am5.Legend.new(root, {
        centerX: am5.p0,
        x: am5.percent(legendXPosition),
        y: am5.percent(-10),
      })
    );

    legend.markers.template.setAll({
      width: 10,
      height: 10,
    });
    legend.labels.template.setAll({
      fontSize: 10,
      fontWeight: "400",
      width: 28,
    });

    function makeSeries(name: string, fieldName: string, color: any) {
      currentSeries.current = chartRef.current.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          baseAxis: yAxis,
          valueXField: fieldName,
          categoryYField: "equipmentName",
          fill: color,
        })
      );

      currentSeries.current.columns.template.setAll({
        tooltipText:
          screen === "runtimeAnalysis" ||
          screen === "alarms" ||
          screen === "OeeDownTimeDuration"
            ? "{name}, {categoryY}: {valueX} Min"
            : "{name}, {categoryY}: {valueX}",
        tooltipY: am5.percent(90),
        maxHeight: 20,
      });
      currentSeries.current.data.setAll(data);

      currentSeries.current.appear();

      currentSeries.current.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      legend.data.push(currentSeries.current);
    }

    if (screen === "runtimeAnalysis") {
      makeSeries("Running", "Running", "#95DE64");
      makeSeries("Idle", "Idle", "#FFD666");
      makeSeries("Stopped", "Stopped", "#FF7875");
    } else if (screen === "OeeDownTime" || screen === "OeeDownTimeDuration") {
      series?.map((item: any, index: any) => {
        return makeSeries(item, item, seriescolor[index]);
      });
    } else {
      makeSeries("Critical Count", "criticalCount", "#F5222D");
      makeSeries("Major Count", "majorCount", "#FA541C");
      makeSeries("Minor Count", "minorCount", "#FA8C16");
      makeSeries("Warning Count", "warningCount", "#FAAD14");
      makeSeries("Event Count", "eventCount", "#FADB14");
    }

    chartRef.current.appear(1000, 100);
    root?._logo?.dispose();

    return () => root.dispose();
  }, [chartID, data, selectedFilter]);

  return <div style={{ width: "100%", height: "100%" }} id={chartID}></div>;
};
export default StackedBar;
