//common
export const SHOW_LOADER = 'SHOW_LOADER';
export const SHOW_LOADER_SUCCESS = 'SHOW_LOADER_SUCCESS';
export const LOAD_FILTER_TREE_DATA = 'LOAD_FILTER_TREE_DATA';
export const LOAD_FILTER_TREE_DATA_SUCCESS = 'LOAD_FILTER_TREE_DATA_SUCCESS';
export const SET_FILTER_VALUES = "SET_FILTER_VALUES";
export const SET_FILTER_UPDATED_FLAG = "SET_FILTER_UPDATED_FLAG";
export const SET_FILTER_VALUES_DATA_VISUALIZATION = "SET_FILTER_VALUES_DATA_VISUALIZATION";

// Reason Code Management
export const CREATE_REASON_CODE_CATEGORY = 'CREATE_REASON_CODE_CATEGORY';
export const CREATE_REASON_CODE_CATEGORY_SUCESS = 'CREATE_REASON_CODE_CATEGORY_SUCESS';
export const GET_REASON_CODE_CATEGORIES = 'GET_REASON_CODE_CATEGORIES';
export const GET_REASON_CODE_CATEGORIES_SUCCESS = 'GET_REASON_CODE_CATEGORIES_SUCCESS';
export const CREATE_REASON_CODE_FACTOR = 'CREATE_REASON_CODE_FACTOR';
export const CREATE_REASON_CODE_FACTOR_SUCCESS = 'CREATE_REASON_CODE_FACTOR_SUCCESS';
export const GET_REASON_CODE_CATEGORY_DATA = 'GET_REASON_CODE_CATEGORY_DATA';
export const GET_REASON_CODE_CATEGORY_DATA_SUCCESS = 'GET_REASON_CODE_CATEGORY_DATA_SUCCESS';
export const ADD_REASON_CODE_DETAILS = 'ADD_REASON_CODE_DETAILS';
export const ADD_REASON_CODE_DETAILS_SUCCESS = 'ADD_REASON_CODE_DETAILS_SUCCESS';
export const GET_ASSIGNED_REASON_CODE = 'GET_ASSIGNED_REASON_CODE';
export const GET_ASSIGNED_REASON_CODE_SUCCESS = 'GET_ASSIGNED_REASON_CODE_SUCCESS';
export const GET_AMD_LIST = 'GET_AMD_LIST';
export const GET_AMD_LIST_SUCCESS = 'GET_AMD_LIST_SUCCESS';
export const ASSIGN_REASON_CODE_TO_RESOURCE = 'ASSIGN_REASON_CODE_TO_RESOURCE';
export const ASSIGN_REASON_CODE_TO_RESOURCE_SUCCESS = 'ASSIGN_REASON_CODE_TO_RESOURCE_SUCCESS';
export const DELETE_REASON_CODE_CATEGORY = 'DELETE_REASON_CODE_CATEGORY';
export const DELETE_REASON_CODE_CATEGORY_SUCCESS = "DELETE_REASON_CODE_CATEGORY_SUCCESS";
export const UNASSIGN_REASON_CODE_TO_RESOURCE = 'UNASSIGN_REASON_CODE_TO_RESOURCE';
export const UNASSIGN_REASON_CODE_TO_RESOURCE_SUCCESS = 'UNASSIGN_REASON_CODE_TO_RESOURCE_SUCCESS';
export const END_DOWNTIME_AMD = 'END_DOWNTIME_AMD';
export const END_DOWNTIME_AMD_SUCCESS = 'END_DOWNTIME_AMD_SUCCESS';
export const CREATE_REASON_CODE_LOGS = 'CREATE_REASON_CODE_LOGS';
export const CREATE_REASON_CODE_LOGS_SUCCESS = 'CREATE_REASON_CODE_LOGS_SUCCESS';
export const DELETE_REASON_CODE_LOGS = 'DELETE_REASON_CODE_LOGS';
export const DELETE_REASON_CODE_LOGS_SUCCESS = "DELETE_REASON_CODE_LOGS_SUCCESS";
export const EDIT_REASON_CODE_LOGS = "EDIT_REASON_CODE_LOGS";
export const EDIT_REASON_CODE_LOGS_SUCCESS = "EDIT_REASON_CODE_LOGS_SUCCESS"
export const EDIT_REASON_CODE_CATEGORY = "EDIT_REASON_CODE_CATEGORY";
export const EDIT_REASON_CODE_CATEGORY_SUCCESS = "EDIT_REASON_CODE_CATEGORY_SUCCESS";
export const EDIT_REASON_CODE_CATEGORY_ERROR = "EDIT_REASON_CODE_CATEGORY_ERROR";


// Tag treding types
export const SET_GRAPH_TYPE = 'SET_GRAPH_TYPE';
export const SET_GRAPH_TYPE_SUCCESS = 'SET_GRAPH_TYPE_SUCCESS';
export const GET_AGGREGATED_DATA_FOR_TAG = 'GET_AGGREGATED_DATA_FOR_TAG';
export const GET_AGGREGATED_DATA_FOR_TAG_SUCCESS = 'GET_AGGREGATED_DATA_FOR_TAG_SUCCESS';
export const GET_TAG_OVERVIEW_DATA = 'GET_TAG_OVERVIEW_DATA';
export const GET_TAG_OVERVIEW_DATA_SUCCESS = 'GET_TAG_OVERVIEW_DATA_SUCCESS';
export const REMOVE_TAG_OVERVIEW_DATA = 'REMOVE_TAG_OVERVIEW_DATA';
export const REMOVE_TAG_OVERVIEW_DATA_SUCCESS = 'REMOVE_TAG_OVERVIEW_DATA_SUCCESS';
export const UPDATE_SELECTED_GRAPH_TAGS = 'UPDATE_SELECTED_GRAPH_TAGS';
export const UPDATE_SELECTED_GRAPH_TAGS_SUCCESS = 'UPDATE_SELECTED_GRAPH_TAGS_SUCCESS';
export const GET_DATA_TRENDING_VIEW = 'GET_DATA_TRENDING_VIEW';
export const GET_DATA_TRENDING_VIEW_SUCCESS = 'GET_DATA_TRENDING_VIEW_SUCCESS';
export const GET_DATA_TRENDING_VIEW_DETAILS = 'GET_DATA_TRENDING_VIEW_DETAILS';
export const GET_DATA_TRENDING_VIEW_DETAILS_SUCCESS = 'GET_DATA_TRENDING_VIEW_DETAILS_SUCCESS';
export const SAVE_DATA_TRENDING_SCREEN_VIEW = 'SAVE_DATA_TRENDING_SCREEN_VIEW';
export const DELETE_DATA_TRENDING_SCREEN_VIEW = 'DELETE_DATA_TRENDING_SCREEN_VIEW';
export const GET_TAG_OVERVIEW_DATA_FOR_DATA_TABLE = "GET_TAG_OVERVIEW_DATA_FOR_DATA_TABLE"
export const GET_TAG_OVERVIEW_DATA_FOR_DATA_TABLE_SUCCESS = "GET_TAG_OVERVIEW_DATA_FOR_DATA_TABLE_SUCCESS"
export const GET_TAG_OVERVIEW_DATA_FOR_DATA_TABLE_ERROR = "GET_TAG_OVERVIEW_DATA_FOR_DATA_TABLE_ERROR"

// assetModel Types

export const GET_MODELS_LIST = "GET_MODELS_LIST";
export const GET_ALL_TAGS = "GET_ALL_TAGS";
export const GET_ALL_TAGS_SUCCESS = "GET_ALL_TAGS_SUCCESS";
export const GET_TAGMAPPING_TREE = "GET_TAGMAPPING_TREE";
export const EMPTY_TABLE_BODY = "EMPTY_TABLE_BODY";
export const GET_MODELS_LIST_SUCCESS = 'GET_MODELS_LIST_SUCCESS';
export const GET_TAGMAPPING_TREE_SUCCESS = 'GET_TAGMAPPING_TREE_SUCCESS';
export const GET_RAW_CSV_DATA = "GET_RAW_CSV_DATA";
export const GET_RAW_CSV_DATA_SUCCESS = 'GET_RAW_CSV_DATA_SUCCESS';


//Alarms and Events
export const GET_ALARMS_EVENTS_DASHBOARD_DATA = "GET_ALARMS_EVENTS_DASHBOARD_DATA";
export const GET_ALARMS_EVENTS_DASHBOARD_DATA_SUCCESS = "GET_ALARMS_EVENTS_DASHBOARD_DATA_SUCCESS";
export const GET_ALARMS_EVENTS_DETAILS_DATA = "GET_ALARMS_EVENTS_DETAILS_DATA"
export const GET_ALARMS_EVENTS_DETAILS_DATA_SUCCESS = "GET_ALARMS_EVENTS_DETAILS_DATA_SUCCESS"
export const GET_ALARMS_EVENTS_STACKED_BAR_CHART_DATA = "GET_ALARMS_EVENTS_STACKED_BAR_CHART_DATA"
export const GET_ALARMS_EVENTS_STACKED_BAR_CHART_DATA_SUCCESS = "GET_ALARMS_EVENTS_STACKED_BAR_CHART_DATA_SUCCESS"
export const GET_ALARMS_EVENTS_PARETO_DATA = "GET_ALARMS_EVENTS_PARETO_DATA"
export const GET_ALARMS_EVENTS_PARETO_CHART_DATA_SUCCESS = "GET_ALARMS_EVENTS_PARETO_CHART_DATA_SUCCESS"
export const GET_ALARMS_EVENTS_TABLE_DATA = "GET_ALARMS_EVENTS_TABLE_DATA"
export const GET_ALARMS_EVENTS_TABLE_DATA_SUCCESS = "GET_ALARMS_EVENTS_TABLE_DATA_SUCCESS"
export const GET_ALARMS_EVENTS_GUAGE_DATA = "GET_ALARMS_EVENTS_GUAGE_DATA"
export const GET_ALARMS_EVENTS_GUAGE_DATA_SUCCESS = "GET_ALARMS_EVENTS_GUAGE_DATA_SUCCESS"
export const ALARMS_EVENTS_DOWNLOAD_DATA = "ALARMS_EVENTS_DOWNLOAD_DATA"
export const ALARMS_EVENTS_DOWNLOAD_DATA_SUCCESS = "ALARMS_EVENTS_DOWNLOAD_DATA_SUCCESS"

// Operator
export const GET_PROCESS_LINE_INFO = 'GET_PROCESS_LINE_INFO';
export const GET_PROCESS_LINE_INFO_SUCCESS = 'GET_PROCESS_LINE_INFO_SUCCESS';
export const SELECTED_FP_LINE = 'SELECTED_FP_LINE';
export const GET_BATCHES_BY_LINE = 'GET_BATCHES_BY_LINE';
export const GET_BATCHES_BY_LINE_SUCCESS = 'GET_BATCHES_BY_LINE_SUCCESS';
export const ASSIGN_OPERATOR_LOG = 'ASSIGN_OPERATOR_LOG';
export const ASSIGN_OPERATOR_LOG_SUCCESS = 'ASSIGN_OPERATOR_LOG_SUCCESS';
export const GET_MACHINE_STATUS = "GET_MACHINE_STATUS";
export const GET_MACHINE_STATUS_SUCCESS= "GET_MACHINE_STATUS_SUCCESS"
export const SET_MACHINE_STATUS = "SET_MACHINE_STATUS";

// line overview
export const GET_LINE_OEE = "GET_LINE_OEE";
export const GET_LINE_OEE_SUCCESS = "GET_LINE_OEE_SUCCESS";
export const GET_LINE_OEE_ERROR = "GET_LINE_OEE_ERROR";
export const GET_TEEP_MATRIX_BY_LINE = 'GET_TEEP_MATRIX_BY_LINE';
export const GET_TEEP_MATRIX_BY_LINE_SUCCESS = 'GET_TEEP_MATRIX_BY_LINE_SUCCESS';
export const GET_TEEP_MATRIX_BY_LINE_ERROR = 'GET_TEEP_MATRIX_BY_LINE_ERROR';
export const GET_DOWNTIME_BY_LINE = "GET_DOWNTIME_BY_LINE";
export const GET_DOWNTIME_BY_LINE_SUCCESS = "GET_DOWNTIME_BY_LINE_SUCCESS";
export const GET_DOWNTIME_BY_LINE_ERROR = "GET_DOWNTIME_BY_LINE_ERROR";

export const GET_RUNTIME_DOWNTIME = 'GET_RUNTIME_DOWNTIME';
export const GET_RUNTIME_DOWNTIME_SUCCESS = 'GET_RUNTIME_DOWNTIME_SUCCESS';
export const GET_RUNTIME_DOWNTIME_ERROR = 'GET_RUNTIME_DOWNTIME_ERROR';

export const GET_LOST_TIME_MATRIX = "GET_LOST_TIME_MATRIX";
export const GET_LOST_TIME_MATRIX_SUCCESS = "GET_LOST_TIME_MATRIX_SUCCESS";
export const GET_LOST_TIME_MATRIX_ERROR = "GET_LOST_TIME_MATRIX_ERROR";

export const GET_RATE_MATRIX = "GET_RATE_MATRIX";
export const GET_RATE_MATRIX_SUCCESS = "GET_RATE_MATRIX_SUCCESS";
export const GET_RATE_MATRIX_ERROR = "GET_RATE_MATRIX_ERROR";
export const GET_LINE_OVERVIEW_TABLE_DATA = "GET_LINE_OVERVIEW_TABLE_DATA"
export const GET_LINE_OVERVIEW_TABLE_DATA_SUCCESS = "GET_LINE_OVERVIEW_TABLE_DATA_SUCCESS"
export const GET_LINE_OVERVIEW_TABLE_CARD_DATA = "GET_LINE_OVERVIEW_TABLE_CARD_DATA"
export const GET_LINE_OVERVIEW_TABLE_CARD_DATA_SUCCESS = "GET_LINE_OVERVIEW_TABLE_CARD_DATA_SUCCESS"


//Production Overview
export const GET_PRODUCTION_OVERVIEW_COUNT_DATA = "GET_PRODUCTION_OVERVIEW_COUNT_DATA";
export const GET_PRODUCTION_OVERVIEW_COUNT_DATA_SUCCESS = "GET_PRODUCTION_OVERVIEW_COUNT_DATA_SUCCESS";
export const GET_PRODUCTION_OVERVIEW_BATCH_DATA = "GET_PRODUCTION_OVERVIEW_BATCH_DATA";
export const GET_PRODUCTION_OVERVIEW_BATCH_DATA_SUCCESS = "GET_PRODUCTION_OVERVIEW_BATCH_DATA_SUCCESS";
export const GET_PRODUCTION_OVERVIEW_ALARMS_DATA = "GET_PRODUCTION_OVERVIEW_ALARMS_DATA";
export const GET_PRODUCTION_OVERVIEW_ALARMS_DATA_SUCCESS = "GET_PRODUCTION_OVERVIEW_ALARMS_DATA_SUCCESS";
export const GET_PRODUCTION_OVERVIEW_OEE_DATA = "GET_PRODUCTION_OVERVIEW_OEE_DATA";
export const GET_PRODUCTION_OVERVIEW_OEE_DATA_SUCCESS = "GET_PRODUCTION_OVERVIEW_OEE_DATA_SUCCESS";
export const GET_PRODUCTION_OVERVIEW_AVERAGE_CYCLE_TIME_DATA = "GET_PRODUCTION_OVERVIEW_AVERAGE_CYCLE_TIME_DATA";
export const GET_PRODUCTION_OVERVIEW_AVERAGE_CYCLE_TIME_DATA_SUCCESS = "GET_PRODUCTION_OVERVIEW_AVERAGE_CYCLE_TIME_DATA_SUCCESS";
export const GET_PRODUCTION_OVERVIEW_ASSET_UTILIZATION_DATA = "GET_PRODUCTION_OVERVIEW_ASSET_UTILIZATION_DATA";
export const GET_PRODUCTION_OVERVIEW_ASSET_UTILIZATION_DATA_SUCCESS = "GET_PRODUCTION_OVERVIEW_ASSET_UTILIZATION_DATA_SUCCESS";
export const GET_PRODUCTION_OVERVIEW_TABLE_DATA = "GET_PRODUCTION_OVERVIEW_TABLE_DATA"
export const GET_PRODUCTION_OVERVIEW_TABLE_DATA_SUCCESS = "GET_PRODUCTION_OVERVIEW_TABLE_DATA_SUCCESS"
export const GET_PRODUCTION_OVERVIEW_PARETO_DATA = "GET_PRODUCTION_OVERVIEW_PARETO_DATA"
export const GET_PRODUCTION_OVERVIEW_PARETO_CHART_DATA_SUCCESS = "GET_PRODUCTION_OVERVIEW_PARETO_CHART_DATA_SUCCESS"
export const GET_PRODUCTION_OVERVIEW_TABLE_CARD_DATA = "GET_PRODUCTION_OVERVIEW_TABLE_CARD_DATA"
export const GET_PRODUCTION_OVERVIEW_TABLE_CARD_DATA_SUCCESS = "GET_PRODUCTION_OVERVIEW_TABLE_CARD_DATA_SUCCESS"
export const GET_PRODUCTION_OVERVIEW_BATCH_DATA_LOADER_SUCCESS="GET_PRODUCTION_OVERVIEW_BATCH_DATA_LOADER_SUCCESS"
export const GET_PRODUCTION_OVERVIEW_PACKAGING_DATA = "GET_PRODUCTION_OVERVIEW_PACKAGING_DATA";
export const GET_PRODUCTION_OVERVIEW_PACKAGING_DATA_SUCCESS = "GET_PRODUCTION_OVERVIEW_PACKAGING_DATA_SUCCESS";
export const GET_PRODUCTION_OVERVIEW_MACHINE_STATUS = "GET_PRODUCTION_OVERVIEW_MACHINE_STATUS";
export const GET_PRODUCTION_OVERVIEW_MACHINE_STATUS_SUCCESS = "GET_PRODUCTION_OVERVIEW_MACHINE_STATUS_SUCCESS";
export const GET_PPALL_BATCHES_DATA = "GET_PPALL_BATCHES_DATA"
export const GET_PPALL_BATCHES_DATA_SUCCESS = "GET_PPALL_BATCHES_DATA_SUCCESS"
export const UPDATE_REJECT_COUNT = "UPDATE_REJECT_COUNT";
export const UPDATE_REJECT_COUNT_SUCCESS = "UPDATE_REJECT_COUNT_SUCCESS";

//Runtime Analysis

export const GET_RUNTIME_ANALYSIS_DASHBOARD_DOWNTIME_DATA = "GET_RUNTIME_ANALYSIS_DASHBOARD_DOWNTIME_DATA";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_DOWNTIME_DATA_SUCCESS = "GET_RUNTIME_ANALYSIS_DASHBOARD_DOWNTIME_DATA_SUCCESS";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_RUNTIME_DATA = "GET_RUNTIME_ANALYSIS_DASHBOARD_RUNTIME_DATA";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_RUNTIME_DATA_SUCCESS = "GET_RUNTIME_ANALYSIS_DASHBOARD_RUNTIME_DATA_SUCCESS";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_PLANNED_STOPTIME_DATA = "GET_RUNTIME_ANALYSIS_DASHBOARD_PLANNED_STOPTIME_DATA";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_PLANNED_STOPTIME_DATA_SUCCESS = "GET_RUNTIME_ANALYSIS_DASHBOARD_PLANNED_STOPTIME_DATA_SUCCESS";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_ASSET_UTILIZATION_DATA = "GET_RUNTIME_ANALYSIS_DASHBOARD_ASSET_UTILIZATION_DATA";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_ASSET_UTILIZATION_DATA_SUCCESS = "GET_RUNTIME_ANALYSIS_DASHBOARD_ASSET_UTILIZATION_DATA_SUCCESS";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_MACHINE_RUNTIME_STATUS_DATA = "GET_RUNTIME_ANALYSIS_DASHBOARD_MACHINE_RUNTIME_STATUS_DATA";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_MACHINE_RUNTIME_STATUS_DATA_SUCCESS = "GET_RUNTIME_ANALYSIS_DASHBOARD_MACHINE_RUNTIME_STATUS_DATA_SUCCESS";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_MTTR_DATA = "GET_RUNTIME_ANALYSIS_DASHBOARD_MTTR_DATA";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_MTTR_DATA_SUCCESS = "GET_RUNTIME_ANALYSIS_DASHBOARD_MACHINE_MTTR_DATA_SUCCESS";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_MTBF_DATA = "GET_RUNTIME_ANALYSIS_DASHBOARD_MTBF_DATA";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_MTBF_DATA_SUCCESS = "GET_RUNTIME_ANALYSIS_DASHBOARD_MACHINE_MTBF_DATA_SUCCESS";
export const GET_RUNTIME_ANALYSIS_DETAILS_DATA = "GET_RUNTIME_ANALYSIS_DETAILS_DATA";
export const GET_RUNTIME_ANALYSIS_DETAILS_DATA_SUCCESS = "GET_RUNTIME_ANALYSIS_DETAILS_DATA_SUCCESS";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_GANTT_DATA = "GET_RUNTIME_ANALYSIS_DASHBOARD_GANTT_DATA";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_GANTT_DATA_LOADING = "GET_RUNTIME_ANALYSIS_DASHBOARD_GANTT_DATA_LOADING";
export const GET_RUNTIME_ANALYSIS_DASHBOARD_GANTT_DATA_SUCCESS = "GET_RUNTIME_ANALYSIS_DASHBOARD_GANTT_DATA_SUCCESS";

// OEE

export const GET_OEE_AVAILABILTY_DETAILS = "GET_OEE_AVAILABILTY_DETAILS";
export const GET_OEE_AVAILABILTY_DETAILS_SUCCESS = "GET_OEE_AVAILABILTY_DETAILS_SUCCESS";
export const GET_OEE_AVAILABILTY_DETAILS_ERROR = "GET_OEE_AVAILABILTY_DETAILS_ERROR";
export const GET_OEE_QUALITY = "GET_OEE_QUALITY";
export const GET_OEE_QUALITY_SUCCESS = "GET_OEE_QUALITY_SUCCESS";
export const GET_OEE_QUALITY_ERROR = "GET_OEE_QUALITY_ERROR";
export const GET_OEE_PERFORMANCE = "GET_OEE_PERFORMANCE";
export const GET_OEE_PERFORMANCE_SUCCESS = "GET_OEE_PERFORMANCE_SUCCESS";
export const GET_OEE_PERFORMANCE_ERROR = "GET_OEE_PERFORMANCE_ERROR";
export const GET_OEE_MACHINEWISE = "GET_OEE_MACHINEWISE"
export const GET_OEE_MACHINEWISE_SUCCESS = "GET_OEE_MACHINEWISE_SUCCESS"
export const GET_OEE_MACHINEWISE_ERROR = "GET_OEE_MACHINEWISE_ERROR"
export const GET_OEE_BY_LINE = "GET_OEE_BY_LINE"
export const GET_OEE_BY_LINE_SUCCESS = "GET_OEE_BY_LINE_SUCCESS"
export const GET_OEE_BY_LINE_ERROR = "GET_OEE_BY_LINE_ERROR"
export const GET_PERCENT_LOSS = 'GET_PERCENT_LOSS'
export const GET_PERCENT_LOSS_SUCCESS = 'GET_PERCENT_LOSS_SUCCESS'
export const GET_PERCENT_LOSS_ERROR = 'GET_PERCENT_LOSS_ERROR'
export const GET_OEE_BY_SKU = "GET_OEE_BY_SKU"
export const GET_OEE_BY_SKU_SUCCESS = "GET_OEE_BY_SKU_SUCCESS"
export const GET_OEE_BY_SKU_ERROR = "GET_OEE_BY_SKU_ERROR"
export const GET_LOSS_TIME_ACTION = "GET_LOSS_TIME_ACTION"
export const GET_LOSS_TIME_ACTION_SUCCESS = "GET_LOSS_TIME_ACTION_ERROR"
export const GET_LOSS_TIME_ACTION_ERROR = "GET_LOSS_TIME_ACTION_ERROR"
export const GET_PRODUCTION_RATE = "GET_PRODUCTION_RATE"
export const GET_PRODUCTION_RATE_SUCCESS = "GET_PRODUCTION_RATE_SUCCESS"
export const GET_PRODUCTION_RATE_ERROR = "GET_PRODUCTION_RATE_ERROR"
export const GET_PRODUCTION_QUALITY = "GET_PRODUCTION_QUALITY"
export const GET_PRODUCTION_QUALITY_SUCCESS = "GET_PRODUCTION_QUALITY_SUCCESS"
export const GET_PRODUCTION_QUALITY_ERROR = "GET_PRODUCTION_QUALITY_ERROR"
export const GET_PRODUCTIVE_TIME = "GET_PRODUCTIVE_TIME"
export const GET_PRODUCTIVE_TIME_SUCCESS = "GET_PRODUCTIVE_TIME_SUCCESS"
export const GET_PRODUCTIVE_TIME_ERROR = "GET_PRODUCTIVE_TIME_ERROR"



// OEE Downtime
export const GET_DOWNTIME_DETAILS = 'GET_DOWNTIME_DETAILS';
export const GET_DOWNTIME_DETAILS_SUCCESS = 'GET_DOWNTIME_DETAILS_SUCCESS';
export const GET_DOWNTIME_DURATION_COUNT = 'GET_DOWNTIME_DURATION_COUNT';
export const GET_DOWNTIME_DURATION_COUNT_SUCCESS = 'GET_DOWNTIME_DURATION_COUNT_SUCCESS';
export const GET_DOWNTIME_PARETO = "GET_DOWNTIME_PARETO";
export const GET_DOWNTIME_PARETO_SUCCESS = 'GET_DOWNTIME_PARETO_SUCCESS';