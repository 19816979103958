import { message } from "antd";
import axios from "axios";
import { getUrl } from "config/apiconfig";
import severityToString from "utils/severityToString";

export const downloadAlarmsData = (
  startTime,
  endTime,
  severity,
  nodeId = [],
  timezone
) => {
  const alarmsDownloadUrl = getUrl("alarmsDataUrl");
  let reqObject:any={
    startTime: startTime,
    endTime: endTime,
    nodeIds: nodeId,
    severity: severityToString(severity),
    timeZone:timezone
  }
  if(timezone==="Europe/London"){
    reqObject={
      startTime: startTime,
      endTime: endTime,
      nodeIds: nodeId,
      severity: severityToString(severity)
    }
  }
 
  axios
    .post(
      alarmsDownloadUrl,
     reqObject,
      { responseType: "blob" }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `alarms_data_${new Date().getTime()}.csv`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      message.error("Error occured while downloading data");
    });
};
