// @ts-nocheck
import { message } from 'antd';
import { getUrl } from 'config/apiconfig';
import { dataTrendingConfigViewOptions } from './mapper/dataTrending';
const { ApiStatusCode } = require('protobuf/dashboard_config_proto/common/Enums_pb');
export class DataTrendingConfigService {
    private static instance: DataTrendingConfigService;
    private dataTrendingConfigServiceClient: any;
    private dataTrendingConfigProtoObj: any;
    private authToken: any = '';
    private metadata: any;

    private constructor() {
        const dashboardUrl = getUrl('dashboardConfigService');
        this.authToken = localStorage.getItem('authToken');
        this.metadata = { 'authorization': 'Bearer ' + this.authToken };
        // TODO: remove commented code
        // const dataTrendingConfigService = require('protobuf/batch-management/protos/service/');
        this.dataTrendingConfigProtoObj = require('protobuf/dashboard_config_proto/service/ConfigService_pb');
        // TODO: remove commented code
        // this.dataTrendingConfigServiceClient = new dataTrendingConfigService.ConfigurationServicePromiseClient(dashboardUrl, null, null);
    }

    public static getInstance(): DataTrendingConfigService {
        if (!DataTrendingConfigService.instance) {
            DataTrendingConfigService.instance = new DataTrendingConfigService();
        }
        return DataTrendingConfigService.instance;
    }

    getViewOptions = (screenName: string) => {
        let request = new this.dataTrendingConfigProtoObj.ScreenName();
        request.setScreenName(screenName);
        return this.dataTrendingConfigServiceClient
            .getList(request, this.metadata)
            .then((response) => {
                if (
                    response &&
                    response.getApiResponse().getCode() === ApiStatusCode.SUCCESS
                ) {
                    return dataTrendingConfigViewOptions(response.getConfigListList());
                }
            })
            .catch((err) => {
                message.error('Unable to get view options');
                throw new Error(err);
            });
    };

    getCurrentScreenDetails = (configId: number) => {
        let request = new this.dataTrendingConfigProtoObj.ID();
        request.setId(configId);
        return this.dataTrendingConfigServiceClient
            .getDetails(request, this.metadata)
            .then((response) => {
                if (
                    response &&
                    response.getApiResponse().getCode() === ApiStatusCode.SUCCESS
                ) {
                    return JSON.parse(response.getConfigDetails().getConfig());
                }
            })
            .catch((err) => {
                message.error('Unable to get selected view');
                throw new Error(err);
            });
    };

    saveCurrentView = (payload: any) => {
        let request = new this.dataTrendingConfigProtoObj.ConfigDetails();
        request.setConfigName(payload.viewName);
        request.setScreenName(payload.screenName);
        request.setConfig(payload.viewData);
        return this.dataTrendingConfigServiceClient
            .upsert(request, this.metadata)
            .then((response: any) => {
                if (response && response.getCode() === ApiStatusCode.SUCCESS) {
                    return response.getCode();
                }
            })
            .catch((err: any) => {
                message.error('Unable to save current view');
                throw new Error(err);
            });
    };

    deleteCurrentView = (payload: any) => {
        let request = new this.dataTrendingConfigProtoObj.ID();
        request.setId(payload.id);
        return this.dataTrendingConfigServiceClient
            .delete(request, this.metadata)
            .then((response: any) => {
                if (response && response.getCode() === ApiStatusCode.SUCCESS) {
                    return response.getCode();
                } else {
                    message.error('Unable to delete current view');
                }
            })
            .catch((err: any) => {
                message.error('Unable to delete current view');
                throw new Error(err);
            });
    };


}


