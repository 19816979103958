// @ts-nocheck
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Select, Table, Tooltip, TreeSelect } from 'antd';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import Search from 'antd/es/input/Search';
import { Text } from 'components/Text';
// import { DeviceDescData } from 'mappers/tagsDataTable.mappers';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emptyTableBody, getAllTags, getModelsList, getTagMappingTree } from 'store/actions/dataVisualization.actions';
import { ascendingSort, decendingSort } from 'utils/common';
import './tagFilter.scss';
import { ReactComponent as UpArrow } from "assets/icons/up-arrow.svg";
import { ReactComponent as DownArrow } from "assets/icons/down-arrow.svg";
export interface DeviceDescData {
  tagName: string;
  id: string;
  description: string;
  key: string;
}
export const TagsFilterPanel = (props: any) => {
  const { TreeNode } = TreeSelect;
  const { Option } = Select;
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [tagDescDS, setTagDescDS] = useState<DeviceDescData[]>([]);
  const [tableBody, setTableBody] = useState<DeviceDescData[]>([]);
  const {
    selectedTag,
    addTagsForGraph,
    selectionType,
    setNodeName,
    nodeName,
    modelName,
    setModelName,
  } = props;
  const dispatch = useDispatch();
  const assetHeirarchyTree = useSelector(
    (state: any) => state.dataVisualizationReducer.tagMappingTree
  );
  const deviceTagListData = useSelector(
    (state: any) => state.dataVisualizationReducer.tagList
  );
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [resetNode, setResetNode] = useState<boolean>(true);
  const selectedParent = useRef<string>('');
  const [AssetHeirarchyTree, setAssetHeirarchyTree] = useState<any>();
  const modelList = useSelector((state: any) => state.dataVisualizationReducer.modelList);

  useEffect(() => {
    return () => {
      dispatch(emptyTableBody());
    };
  }, []);
  useEffect(() => {
    dispatch(getModelsList({}));   //done
  }, []);

  useEffect(() => {
    setTagDescDS(deviceTagListData);
    setTableBody(deviceTagListData);
  }, [deviceTagListData]);
  useEffect(() => {
    if (assetHeirarchyTree.key) {
      let assetTree: Array<any> = [];
      assetTree.push(assetHeirarchyTree);
      let currentTree = createTree(assetTree);
      setAssetHeirarchyTree(currentTree);
    }
  }, [assetHeirarchyTree]);

  const addTagsHandler = () => {
    selectedTag(selectedRow);
    setSelectedKeys([]);
  };
  const columns = [
    {
      title: (
        <div className="sort-arrow-container">
          Description
          <div className="sort-arrows">
            <UpArrow className='up-arrow' onClick={() =>
              setTagDescDS(ascendingSort('description', [...tagDescDS]))
            } />
            <DownArrow className='down-arrow' onClick={() =>
              setTagDescDS(decendingSort('description', [...tagDescDS]))
            } />
          </div>
        </div>
      ),
      dataIndex: 'tagDescription',
      ellipsis: true,
      width: '34%'
    },
    {
      title: (
        <div className="sort-arrow-container">
          Device Name
          <div className="sort-arrows">
            <UpArrow className='up-arrow' onClick={() =>
              setTagDescDS(ascendingSort('deviceName', [...tagDescDS]))
            } />
            <DownArrow className='down-arrow' onClick={() =>
              setTagDescDS(decendingSort('deviceName', [...tagDescDS]))
            } />
          </div>
        </div>
      ),
      dataIndex: 'deviceName',
      ellipsis: true,
      width: '36%'
    },
    {
      title: (
        <div className="sort-arrow-container">
          Tag Name
          <div className="sort-arrows">
            <UpArrow className='up-arrow' onClick={() =>
              setTagDescDS(ascendingSort('tagName', [...tagDescDS]))
            } />
            <DownArrow className='down-arrow' onClick={() =>
              setTagDescDS(decendingSort('tagName', [...tagDescDS]))
            } />
          </div>
        </div>
      ),
      dataIndex: 'tagName',
      ellipsis: true,
      width: '30%'
    }    
  ];
  const cardTitle = (
    <>
      <div className="main-div-tagfilter">
        <div className="tagFilter-heading">
          <Text type="p16">Device Tags</Text>
          <Tooltip
            className="tooltip"
            title="Search Equipment tags for visualization in data table or trending by equipments"
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <div className="tagfilter-addBtn">
          <Button
            type="default"
            className="dataVisualisation-btn-add"
            icon={
              <PlusOutlined
                style={{ cursor: 'pointer' }}
                onClick={addTagsForGraph}
              />
            }
            onClick={addTagsHandler}
          >
            Add Tag
          </Button>
        </div>
      </div>
    </>
  );

  const onChange = (value: any) => {
    if (value !== undefined) {
      selectedParent.current = value[value.length - 1];
      dispatch(getAllTags({
        status: 0,
        nodeId: value
      }));
      setNodeName(value);
    } else {
      setNodeName(null);
    }
  };

  const selectTagHandler = (record: any) => {
    selectedTag(record);
  };

  const rowSelection = {
    hideSelectAll: true,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      if (selectedRowKeys.length > 6) {
        message.info("Please select only 6 Tags ", 10)
      } else {
        setSelectedKeys(selectedRowKeys);
        setSelectedRow(selectedRows);
      }
    },
  };
  const filterData = (e: any, column1: string, column2: string) => {
    if (e === undefined) return;
    let str = e.target.value;
    if (str === '') {
      setTagDescDS([...tableBody]);
      return;
    }
    setTagDescDS(
      [...tableBody].filter(
        (element: any) =>
          element[column1].includes(str) ||
          element[column2].includes(str) ||
          element[column2].includes(str.toUpperCase()) ||
          element[column1].includes(str.toUpperCase())
      )
    );
  };
  const createTree: any = (treeStruct: Array<any>) => {
    let currentTree = treeStruct.map((element) => {
      return (
        <TreeNode value={element['key']} title={element['title']}>
          {element['children'] ? createTree(element['children']) : null}
        </TreeNode>
      );
    });
    return currentTree;
  };

  const handleModelSelected = (selectedModel: number) => {
    if (selectedModel !== undefined) {
      setResetNode(true);
      dispatch(getTagMappingTree({ id: selectedModel, cloudPushBool: 0 }));
      modelList.forEach((currentModel: any) => {
        if (currentModel.key === selectedModel) {
          setModelName(currentModel.modelName);
        }
      });
    } else {
      setModelName(null);
    }
  };

  const onChangeCheck = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const onClearModel = () => {
    setResetNode(false);
    setNodeName(null);
  };

  return (
    <div>
      <div className="tag-filter-heading-container">{cardTitle}</div>
      <div className="tag-filter-options-container">
        {
          <Select
            placeholder="Select Model"
            className="tag-filter-options"
            onChange={handleModelSelected}
            allowClear={!!modelName}
            onClear={onClearModel}
            value={modelName ? modelName : null}
          >
            {modelList.map((item: any) => {
              return (
                <Option value={item['key']} key={item['key']}>
                  <div className='option-text'>
                    <Checkbox onChange={onChangeCheck}></Checkbox>
                    <div>{item['modelName']}</div>
                  </div>
                </Option>
              );
            })}
          </Select>
        }
        <TreeSelect
          className="tag-filter-options"
          showSearch
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          value={nodeName ? nodeName : null}
          placeholder="Select Node"
          allowClear={!!nodeName}
          onChange={onChange}
        >
          {resetNode && AssetHeirarchyTree}
        </TreeSelect>
        <Search
          size="small"
          className="tag-filter-options"
          allowClear
          placeholder="Search by tag name/description"
          onChange={(e) => filterData(e, 'tagName', 'tagDescription')}
        />
      </div>
      <div className="tag-filter-table-container">
        <div className="tag-filter-table">
          <Table
            dataSource={resetNode && !!nodeName ? tagDescDS : []}
            columns={columns}
            onRow={(record) => {
              return {
                onClick: () => {
                  selectTagHandler(record);
                },
              };
            }}
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            pagination={false}
            size="small"
            scroll={{ y: 'calc(100vh - 379px' }}
            locale={{
              emptyText: (
                <div className="empty-table-text">
                  No data for given selection.
                </div>
              ),
            }}
          ></Table>
        </div>
      </div>
    </div>
  );
};
