// source: service/HistorianRpc.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var objects_Historian_pb = require('../objects/Historian_pb.js');
goog.object.extend(proto, objects_Historian_pb);
var common_API_pb = require('../common/API_pb.js');
goog.object.extend(proto, common_API_pb);
var common_Enums_pb = require('../common/Enums_pb.js');
goog.object.extend(proto, common_Enums_pb);
goog.exportSymbol('proto.CloseRawDataStreamingReq', null, global);
goog.exportSymbol('proto.TagTimeseriesRequest', null, global);
goog.exportSymbol('proto.TagTimeseriesResponse', null, global);
goog.exportSymbol('proto.TagTimeseriesValueInfoRequest', null, global);
goog.exportSymbol('proto.TagTimeseriesValueInfoResponse', null, global);
goog.exportSymbol('proto.TemplateDownloadResponse', null, global);
goog.exportSymbol('proto.TemplateUploadReq', null, global);
goog.exportSymbol('proto.TimeStampCyclicQuery', null, global);
goog.exportSymbol('proto.TimeStampMultipleQuery', null, global);
goog.exportSymbol('proto.TimeStampMultipleResponse', null, global);
goog.exportSymbol('proto.TimeStampStatsResponse', null, global);
goog.exportSymbol('proto.TimeStampStreamingQuery', null, global);
goog.exportSymbol('proto.TimeStampStreamingResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TimeStampMultipleQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TimeStampMultipleQuery.repeatedFields_, null);
};
goog.inherits(proto.TimeStampMultipleQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TimeStampMultipleQuery.displayName = 'proto.TimeStampMultipleQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TimeStampMultipleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TimeStampMultipleResponse.repeatedFields_, null);
};
goog.inherits(proto.TimeStampMultipleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TimeStampMultipleResponse.displayName = 'proto.TimeStampMultipleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TimeStampCyclicQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TimeStampCyclicQuery.repeatedFields_, null);
};
goog.inherits(proto.TimeStampCyclicQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TimeStampCyclicQuery.displayName = 'proto.TimeStampCyclicQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TimeStampStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TimeStampStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.TimeStampStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TimeStampStatsResponse.displayName = 'proto.TimeStampStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TimeStampStreamingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TimeStampStreamingResponse.repeatedFields_, null);
};
goog.inherits(proto.TimeStampStreamingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TimeStampStreamingResponse.displayName = 'proto.TimeStampStreamingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TimeStampStreamingQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TimeStampStreamingQuery.repeatedFields_, null);
};
goog.inherits(proto.TimeStampStreamingQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TimeStampStreamingQuery.displayName = 'proto.TimeStampStreamingQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CloseRawDataStreamingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CloseRawDataStreamingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CloseRawDataStreamingReq.displayName = 'proto.CloseRawDataStreamingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TemplateDownloadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TemplateDownloadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TemplateDownloadResponse.displayName = 'proto.TemplateDownloadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TemplateUploadReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TemplateUploadReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TemplateUploadReq.displayName = 'proto.TemplateUploadReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TagTimeseriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TagTimeseriesRequest.repeatedFields_, null);
};
goog.inherits(proto.TagTimeseriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TagTimeseriesRequest.displayName = 'proto.TagTimeseriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TagTimeseriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TagTimeseriesResponse.repeatedFields_, null);
};
goog.inherits(proto.TagTimeseriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TagTimeseriesResponse.displayName = 'proto.TagTimeseriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TagTimeseriesValueInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TagTimeseriesValueInfoRequest.repeatedFields_, null);
};
goog.inherits(proto.TagTimeseriesValueInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TagTimeseriesValueInfoRequest.displayName = 'proto.TagTimeseriesValueInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TagTimeseriesValueInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TagTimeseriesValueInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.TagTimeseriesValueInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TagTimeseriesValueInfoResponse.displayName = 'proto.TagTimeseriesValueInfoResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TimeStampMultipleQuery.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TimeStampMultipleQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.TimeStampMultipleQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TimeStampMultipleQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeStampMultipleQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagIdList: jspb.Message.toObjectList(msg.getTagIdList(),
    common_API_pb.Uuid.toObject, includeInstance),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    orderBy: jspb.Message.getFieldWithDefault(msg, 6, 0),
    tagOrigin: jspb.Message.getFieldWithDefault(msg, 7, 0),
    timeZone: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TimeStampMultipleQuery}
 */
proto.TimeStampMultipleQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TimeStampMultipleQuery;
  return proto.TimeStampMultipleQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TimeStampMultipleQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TimeStampMultipleQuery}
 */
proto.TimeStampMultipleQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.Uuid;
      reader.readMessage(value,common_API_pb.Uuid.deserializeBinaryFromReader);
      msg.addTagId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 6:
      var value = /** @type {!proto.SortOrder} */ (reader.readEnum());
      msg.setOrderBy(value);
      break;
    case 7:
      var value = /** @type {!proto.TagOrigin} */ (reader.readEnum());
      msg.setTagOrigin(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeZone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TimeStampMultipleQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TimeStampMultipleQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TimeStampMultipleQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeStampMultipleQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagIdList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_API_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOrderBy();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTagOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getTimeZone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * repeated Uuid tag_id = 1;
 * @return {!Array<!proto.Uuid>}
 */
proto.TimeStampMultipleQuery.prototype.getTagIdList = function() {
  return /** @type{!Array<!proto.Uuid>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_API_pb.Uuid, 1));
};


/**
 * @param {!Array<!proto.Uuid>} value
 * @return {!proto.TimeStampMultipleQuery} returns this
*/
proto.TimeStampMultipleQuery.prototype.setTagIdList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Uuid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Uuid}
 */
proto.TimeStampMultipleQuery.prototype.addTagId = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Uuid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TimeStampMultipleQuery} returns this
 */
proto.TimeStampMultipleQuery.prototype.clearTagIdList = function() {
  return this.setTagIdList([]);
};


/**
 * optional int64 start_time = 2;
 * @return {number}
 */
proto.TimeStampMultipleQuery.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeStampMultipleQuery} returns this
 */
proto.TimeStampMultipleQuery.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_time = 3;
 * @return {number}
 */
proto.TimeStampMultipleQuery.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeStampMultipleQuery} returns this
 */
proto.TimeStampMultipleQuery.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.TimeStampMultipleQuery.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeStampMultipleQuery} returns this
 */
proto.TimeStampMultipleQuery.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 page_number = 5;
 * @return {number}
 */
proto.TimeStampMultipleQuery.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeStampMultipleQuery} returns this
 */
proto.TimeStampMultipleQuery.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional SortOrder order_by = 6;
 * @return {!proto.SortOrder}
 */
proto.TimeStampMultipleQuery.prototype.getOrderBy = function() {
  return /** @type {!proto.SortOrder} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.SortOrder} value
 * @return {!proto.TimeStampMultipleQuery} returns this
 */
proto.TimeStampMultipleQuery.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional TagOrigin tag_origin = 7;
 * @return {!proto.TagOrigin}
 */
proto.TimeStampMultipleQuery.prototype.getTagOrigin = function() {
  return /** @type {!proto.TagOrigin} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.TagOrigin} value
 * @return {!proto.TimeStampMultipleQuery} returns this
 */
proto.TimeStampMultipleQuery.prototype.setTagOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string time_zone = 8;
 * @return {string}
 */
proto.TimeStampMultipleQuery.prototype.getTimeZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.TimeStampMultipleQuery} returns this
 */
proto.TimeStampMultipleQuery.prototype.setTimeZone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TimeStampMultipleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TimeStampMultipleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TimeStampMultipleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TimeStampMultipleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeStampMultipleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    streamsList: jspb.Message.toObjectList(msg.getStreamsList(),
    objects_Historian_pb.TimeSeries.toObject, includeInstance),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 2, 0),
    streamCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    apiResponse: (f = msg.getApiResponse()) && common_API_pb.ApiResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TimeStampMultipleResponse}
 */
proto.TimeStampMultipleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TimeStampMultipleResponse;
  return proto.TimeStampMultipleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TimeStampMultipleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TimeStampMultipleResponse}
 */
proto.TimeStampMultipleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new objects_Historian_pb.TimeSeries;
      reader.readMessage(value,objects_Historian_pb.TimeSeries.deserializeBinaryFromReader);
      msg.addStreams(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRecords(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStreamCount(value);
      break;
    case 4:
      var value = new common_API_pb.ApiResponse;
      reader.readMessage(value,common_API_pb.ApiResponse.deserializeBinaryFromReader);
      msg.setApiResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TimeStampMultipleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TimeStampMultipleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TimeStampMultipleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeStampMultipleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      objects_Historian_pb.TimeSeries.serializeBinaryToWriter
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStreamCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getApiResponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_API_pb.ApiResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TimeSeries streams = 1;
 * @return {!Array<!proto.TimeSeries>}
 */
proto.TimeStampMultipleResponse.prototype.getStreamsList = function() {
  return /** @type{!Array<!proto.TimeSeries>} */ (
    jspb.Message.getRepeatedWrapperField(this, objects_Historian_pb.TimeSeries, 1));
};


/**
 * @param {!Array<!proto.TimeSeries>} value
 * @return {!proto.TimeStampMultipleResponse} returns this
*/
proto.TimeStampMultipleResponse.prototype.setStreamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TimeSeries=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TimeSeries}
 */
proto.TimeStampMultipleResponse.prototype.addStreams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TimeSeries, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TimeStampMultipleResponse} returns this
 */
proto.TimeStampMultipleResponse.prototype.clearStreamsList = function() {
  return this.setStreamsList([]);
};


/**
 * optional int64 total_records = 2;
 * @return {number}
 */
proto.TimeStampMultipleResponse.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeStampMultipleResponse} returns this
 */
proto.TimeStampMultipleResponse.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 stream_count = 3;
 * @return {number}
 */
proto.TimeStampMultipleResponse.prototype.getStreamCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeStampMultipleResponse} returns this
 */
proto.TimeStampMultipleResponse.prototype.setStreamCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ApiResponse api_response = 4;
 * @return {?proto.ApiResponse}
 */
proto.TimeStampMultipleResponse.prototype.getApiResponse = function() {
  return /** @type{?proto.ApiResponse} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ApiResponse, 4));
};


/**
 * @param {?proto.ApiResponse|undefined} value
 * @return {!proto.TimeStampMultipleResponse} returns this
*/
proto.TimeStampMultipleResponse.prototype.setApiResponse = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TimeStampMultipleResponse} returns this
 */
proto.TimeStampMultipleResponse.prototype.clearApiResponse = function() {
  return this.setApiResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TimeStampMultipleResponse.prototype.hasApiResponse = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TimeStampCyclicQuery.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TimeStampCyclicQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.TimeStampCyclicQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TimeStampCyclicQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeStampCyclicQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagIdList: jspb.Message.toObjectList(msg.getTagIdList(),
    common_API_pb.Uuid.toObject, includeInstance),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    interval: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    orderBy: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TimeStampCyclicQuery}
 */
proto.TimeStampCyclicQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TimeStampCyclicQuery;
  return proto.TimeStampCyclicQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TimeStampCyclicQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TimeStampCyclicQuery}
 */
proto.TimeStampCyclicQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.Uuid;
      reader.readMessage(value,common_API_pb.Uuid.deserializeBinaryFromReader);
      msg.addTagId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInterval(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 7:
      var value = /** @type {!proto.SortOrder} */ (reader.readEnum());
      msg.setOrderBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TimeStampCyclicQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TimeStampCyclicQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TimeStampCyclicQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeStampCyclicQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagIdList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_API_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getInterval();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOrderBy();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * repeated Uuid tag_id = 1;
 * @return {!Array<!proto.Uuid>}
 */
proto.TimeStampCyclicQuery.prototype.getTagIdList = function() {
  return /** @type{!Array<!proto.Uuid>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_API_pb.Uuid, 1));
};


/**
 * @param {!Array<!proto.Uuid>} value
 * @return {!proto.TimeStampCyclicQuery} returns this
*/
proto.TimeStampCyclicQuery.prototype.setTagIdList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Uuid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Uuid}
 */
proto.TimeStampCyclicQuery.prototype.addTagId = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Uuid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TimeStampCyclicQuery} returns this
 */
proto.TimeStampCyclicQuery.prototype.clearTagIdList = function() {
  return this.setTagIdList([]);
};


/**
 * optional int64 start_time = 2;
 * @return {number}
 */
proto.TimeStampCyclicQuery.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeStampCyclicQuery} returns this
 */
proto.TimeStampCyclicQuery.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_time = 3;
 * @return {number}
 */
proto.TimeStampCyclicQuery.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeStampCyclicQuery} returns this
 */
proto.TimeStampCyclicQuery.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 interval = 4;
 * @return {number}
 */
proto.TimeStampCyclicQuery.prototype.getInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeStampCyclicQuery} returns this
 */
proto.TimeStampCyclicQuery.prototype.setInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 page_size = 5;
 * @return {number}
 */
proto.TimeStampCyclicQuery.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeStampCyclicQuery} returns this
 */
proto.TimeStampCyclicQuery.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 page_number = 6;
 * @return {number}
 */
proto.TimeStampCyclicQuery.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeStampCyclicQuery} returns this
 */
proto.TimeStampCyclicQuery.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional SortOrder order_by = 7;
 * @return {!proto.SortOrder}
 */
proto.TimeStampCyclicQuery.prototype.getOrderBy = function() {
  return /** @type {!proto.SortOrder} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.SortOrder} value
 * @return {!proto.TimeStampCyclicQuery} returns this
 */
proto.TimeStampCyclicQuery.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TimeStampStatsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TimeStampStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TimeStampStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TimeStampStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeStampStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeseriesStatsList: jspb.Message.toObjectList(msg.getTimeseriesStatsList(),
    objects_Historian_pb.TimeSeriesStats.toObject, includeInstance),
    totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    apiResponse: (f = msg.getApiResponse()) && common_API_pb.ApiResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TimeStampStatsResponse}
 */
proto.TimeStampStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TimeStampStatsResponse;
  return proto.TimeStampStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TimeStampStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TimeStampStatsResponse}
 */
proto.TimeStampStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new objects_Historian_pb.TimeSeriesStats;
      reader.readMessage(value,objects_Historian_pb.TimeSeriesStats.deserializeBinaryFromReader);
      msg.addTimeseriesStats(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 3:
      var value = new common_API_pb.ApiResponse;
      reader.readMessage(value,common_API_pb.ApiResponse.deserializeBinaryFromReader);
      msg.setApiResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TimeStampStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TimeStampStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TimeStampStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeStampStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeseriesStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      objects_Historian_pb.TimeSeriesStats.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getApiResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_API_pb.ApiResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TimeSeriesStats timeseries_stats = 1;
 * @return {!Array<!proto.TimeSeriesStats>}
 */
proto.TimeStampStatsResponse.prototype.getTimeseriesStatsList = function() {
  return /** @type{!Array<!proto.TimeSeriesStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, objects_Historian_pb.TimeSeriesStats, 1));
};


/**
 * @param {!Array<!proto.TimeSeriesStats>} value
 * @return {!proto.TimeStampStatsResponse} returns this
*/
proto.TimeStampStatsResponse.prototype.setTimeseriesStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TimeSeriesStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TimeSeriesStats}
 */
proto.TimeStampStatsResponse.prototype.addTimeseriesStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TimeSeriesStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TimeStampStatsResponse} returns this
 */
proto.TimeStampStatsResponse.prototype.clearTimeseriesStatsList = function() {
  return this.setTimeseriesStatsList([]);
};


/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.TimeStampStatsResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeStampStatsResponse} returns this
 */
proto.TimeStampStatsResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ApiResponse api_response = 3;
 * @return {?proto.ApiResponse}
 */
proto.TimeStampStatsResponse.prototype.getApiResponse = function() {
  return /** @type{?proto.ApiResponse} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ApiResponse, 3));
};


/**
 * @param {?proto.ApiResponse|undefined} value
 * @return {!proto.TimeStampStatsResponse} returns this
*/
proto.TimeStampStatsResponse.prototype.setApiResponse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TimeStampStatsResponse} returns this
 */
proto.TimeStampStatsResponse.prototype.clearApiResponse = function() {
  return this.setApiResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TimeStampStatsResponse.prototype.hasApiResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TimeStampStreamingResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TimeStampStreamingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TimeStampStreamingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TimeStampStreamingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeStampStreamingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    streamsList: jspb.Message.toObjectList(msg.getStreamsList(),
    objects_Historian_pb.TimeSeries.toObject, includeInstance),
    clientId: (f = msg.getClientId()) && common_API_pb.Uuid.toObject(includeInstance, f),
    apiResponse: (f = msg.getApiResponse()) && common_API_pb.ApiResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TimeStampStreamingResponse}
 */
proto.TimeStampStreamingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TimeStampStreamingResponse;
  return proto.TimeStampStreamingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TimeStampStreamingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TimeStampStreamingResponse}
 */
proto.TimeStampStreamingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new objects_Historian_pb.TimeSeries;
      reader.readMessage(value,objects_Historian_pb.TimeSeries.deserializeBinaryFromReader);
      msg.addStreams(value);
      break;
    case 2:
      var value = new common_API_pb.Uuid;
      reader.readMessage(value,common_API_pb.Uuid.deserializeBinaryFromReader);
      msg.setClientId(value);
      break;
    case 3:
      var value = new common_API_pb.ApiResponse;
      reader.readMessage(value,common_API_pb.ApiResponse.deserializeBinaryFromReader);
      msg.setApiResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TimeStampStreamingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TimeStampStreamingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TimeStampStreamingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeStampStreamingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      objects_Historian_pb.TimeSeries.serializeBinaryToWriter
    );
  }
  f = message.getClientId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_API_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getApiResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_API_pb.ApiResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TimeSeries streams = 1;
 * @return {!Array<!proto.TimeSeries>}
 */
proto.TimeStampStreamingResponse.prototype.getStreamsList = function() {
  return /** @type{!Array<!proto.TimeSeries>} */ (
    jspb.Message.getRepeatedWrapperField(this, objects_Historian_pb.TimeSeries, 1));
};


/**
 * @param {!Array<!proto.TimeSeries>} value
 * @return {!proto.TimeStampStreamingResponse} returns this
*/
proto.TimeStampStreamingResponse.prototype.setStreamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TimeSeries=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TimeSeries}
 */
proto.TimeStampStreamingResponse.prototype.addStreams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TimeSeries, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TimeStampStreamingResponse} returns this
 */
proto.TimeStampStreamingResponse.prototype.clearStreamsList = function() {
  return this.setStreamsList([]);
};


/**
 * optional Uuid client_id = 2;
 * @return {?proto.Uuid}
 */
proto.TimeStampStreamingResponse.prototype.getClientId = function() {
  return /** @type{?proto.Uuid} */ (
    jspb.Message.getWrapperField(this, common_API_pb.Uuid, 2));
};


/**
 * @param {?proto.Uuid|undefined} value
 * @return {!proto.TimeStampStreamingResponse} returns this
*/
proto.TimeStampStreamingResponse.prototype.setClientId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TimeStampStreamingResponse} returns this
 */
proto.TimeStampStreamingResponse.prototype.clearClientId = function() {
  return this.setClientId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TimeStampStreamingResponse.prototype.hasClientId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ApiResponse api_response = 3;
 * @return {?proto.ApiResponse}
 */
proto.TimeStampStreamingResponse.prototype.getApiResponse = function() {
  return /** @type{?proto.ApiResponse} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ApiResponse, 3));
};


/**
 * @param {?proto.ApiResponse|undefined} value
 * @return {!proto.TimeStampStreamingResponse} returns this
*/
proto.TimeStampStreamingResponse.prototype.setApiResponse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TimeStampStreamingResponse} returns this
 */
proto.TimeStampStreamingResponse.prototype.clearApiResponse = function() {
  return this.setApiResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TimeStampStreamingResponse.prototype.hasApiResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TimeStampStreamingQuery.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TimeStampStreamingQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.TimeStampStreamingQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TimeStampStreamingQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeStampStreamingQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagIdList: jspb.Message.toObjectList(msg.getTagIdList(),
    common_API_pb.Uuid.toObject, includeInstance),
    streamingIntervalMs: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TimeStampStreamingQuery}
 */
proto.TimeStampStreamingQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TimeStampStreamingQuery;
  return proto.TimeStampStreamingQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TimeStampStreamingQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TimeStampStreamingQuery}
 */
proto.TimeStampStreamingQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.Uuid;
      reader.readMessage(value,common_API_pb.Uuid.deserializeBinaryFromReader);
      msg.addTagId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStreamingIntervalMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TimeStampStreamingQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TimeStampStreamingQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TimeStampStreamingQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeStampStreamingQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagIdList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_API_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getStreamingIntervalMs();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated Uuid tag_id = 1;
 * @return {!Array<!proto.Uuid>}
 */
proto.TimeStampStreamingQuery.prototype.getTagIdList = function() {
  return /** @type{!Array<!proto.Uuid>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_API_pb.Uuid, 1));
};


/**
 * @param {!Array<!proto.Uuid>} value
 * @return {!proto.TimeStampStreamingQuery} returns this
*/
proto.TimeStampStreamingQuery.prototype.setTagIdList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Uuid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Uuid}
 */
proto.TimeStampStreamingQuery.prototype.addTagId = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Uuid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TimeStampStreamingQuery} returns this
 */
proto.TimeStampStreamingQuery.prototype.clearTagIdList = function() {
  return this.setTagIdList([]);
};


/**
 * optional int32 streaming_interval_ms = 2;
 * @return {number}
 */
proto.TimeStampStreamingQuery.prototype.getStreamingIntervalMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeStampStreamingQuery} returns this
 */
proto.TimeStampStreamingQuery.prototype.setStreamingIntervalMs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CloseRawDataStreamingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.CloseRawDataStreamingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CloseRawDataStreamingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CloseRawDataStreamingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: (f = msg.getClientId()) && common_API_pb.Uuid.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CloseRawDataStreamingReq}
 */
proto.CloseRawDataStreamingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CloseRawDataStreamingReq;
  return proto.CloseRawDataStreamingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CloseRawDataStreamingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CloseRawDataStreamingReq}
 */
proto.CloseRawDataStreamingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.Uuid;
      reader.readMessage(value,common_API_pb.Uuid.deserializeBinaryFromReader);
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CloseRawDataStreamingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CloseRawDataStreamingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CloseRawDataStreamingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CloseRawDataStreamingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.Uuid.serializeBinaryToWriter
    );
  }
};


/**
 * optional Uuid client_id = 1;
 * @return {?proto.Uuid}
 */
proto.CloseRawDataStreamingReq.prototype.getClientId = function() {
  return /** @type{?proto.Uuid} */ (
    jspb.Message.getWrapperField(this, common_API_pb.Uuid, 1));
};


/**
 * @param {?proto.Uuid|undefined} value
 * @return {!proto.CloseRawDataStreamingReq} returns this
*/
proto.CloseRawDataStreamingReq.prototype.setClientId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CloseRawDataStreamingReq} returns this
 */
proto.CloseRawDataStreamingReq.prototype.clearClientId = function() {
  return this.setClientId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CloseRawDataStreamingReq.prototype.hasClientId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TemplateDownloadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TemplateDownloadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TemplateDownloadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TemplateDownloadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiResponse: (f = msg.getApiResponse()) && common_API_pb.ApiResponse.toObject(includeInstance, f),
    base64Data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TemplateDownloadResponse}
 */
proto.TemplateDownloadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TemplateDownloadResponse;
  return proto.TemplateDownloadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TemplateDownloadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TemplateDownloadResponse}
 */
proto.TemplateDownloadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.ApiResponse;
      reader.readMessage(value,common_API_pb.ApiResponse.deserializeBinaryFromReader);
      msg.setApiResponse(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBase64Data(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TemplateDownloadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TemplateDownloadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TemplateDownloadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TemplateDownloadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_API_pb.ApiResponse.serializeBinaryToWriter
    );
  }
  f = message.getBase64Data();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional ApiResponse api_response = 1;
 * @return {?proto.ApiResponse}
 */
proto.TemplateDownloadResponse.prototype.getApiResponse = function() {
  return /** @type{?proto.ApiResponse} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ApiResponse, 1));
};


/**
 * @param {?proto.ApiResponse|undefined} value
 * @return {!proto.TemplateDownloadResponse} returns this
*/
proto.TemplateDownloadResponse.prototype.setApiResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TemplateDownloadResponse} returns this
 */
proto.TemplateDownloadResponse.prototype.clearApiResponse = function() {
  return this.setApiResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TemplateDownloadResponse.prototype.hasApiResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string base64_data = 2;
 * @return {string}
 */
proto.TemplateDownloadResponse.prototype.getBase64Data = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TemplateDownloadResponse} returns this
 */
proto.TemplateDownloadResponse.prototype.setBase64Data = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string file_name = 3;
 * @return {string}
 */
proto.TemplateDownloadResponse.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TemplateDownloadResponse} returns this
 */
proto.TemplateDownloadResponse.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TemplateUploadReq.prototype.toObject = function(opt_includeInstance) {
  return proto.TemplateUploadReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TemplateUploadReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TemplateUploadReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    base64Data: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TemplateUploadReq}
 */
proto.TemplateUploadReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TemplateUploadReq;
  return proto.TemplateUploadReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TemplateUploadReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TemplateUploadReq}
 */
proto.TemplateUploadReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBase64Data(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TemplateUploadReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TemplateUploadReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TemplateUploadReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TemplateUploadReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase64Data();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string base64_data = 1;
 * @return {string}
 */
proto.TemplateUploadReq.prototype.getBase64Data = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TemplateUploadReq} returns this
 */
proto.TemplateUploadReq.prototype.setBase64Data = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TagTimeseriesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TagTimeseriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TagTimeseriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TagTimeseriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TagTimeseriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagUuidList: jspb.Message.toObjectList(msg.getTagUuidList(),
    common_API_pb.Uuid.toObject, includeInstance),
    timestampSet: (f = msg.getTimestampSet()) && objects_Historian_pb.TimestampSet.toObject(includeInstance, f),
    tagValue: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TagTimeseriesRequest}
 */
proto.TagTimeseriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TagTimeseriesRequest;
  return proto.TagTimeseriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TagTimeseriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TagTimeseriesRequest}
 */
proto.TagTimeseriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_API_pb.Uuid;
      reader.readMessage(value,common_API_pb.Uuid.deserializeBinaryFromReader);
      msg.addTagUuid(value);
      break;
    case 2:
      var value = new objects_Historian_pb.TimestampSet;
      reader.readMessage(value,objects_Historian_pb.TimestampSet.deserializeBinaryFromReader);
      msg.setTimestampSet(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTagValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TagTimeseriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TagTimeseriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TagTimeseriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TagTimeseriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagUuidList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_API_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getTimestampSet();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      objects_Historian_pb.TimestampSet.serializeBinaryToWriter
    );
  }
  f = message.getTagValue();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated Uuid tag_uuid = 1;
 * @return {!Array<!proto.Uuid>}
 */
proto.TagTimeseriesRequest.prototype.getTagUuidList = function() {
  return /** @type{!Array<!proto.Uuid>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_API_pb.Uuid, 1));
};


/**
 * @param {!Array<!proto.Uuid>} value
 * @return {!proto.TagTimeseriesRequest} returns this
*/
proto.TagTimeseriesRequest.prototype.setTagUuidList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Uuid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Uuid}
 */
proto.TagTimeseriesRequest.prototype.addTagUuid = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Uuid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TagTimeseriesRequest} returns this
 */
proto.TagTimeseriesRequest.prototype.clearTagUuidList = function() {
  return this.setTagUuidList([]);
};


/**
 * optional TimestampSet timestamp_set = 2;
 * @return {?proto.TimestampSet}
 */
proto.TagTimeseriesRequest.prototype.getTimestampSet = function() {
  return /** @type{?proto.TimestampSet} */ (
    jspb.Message.getWrapperField(this, objects_Historian_pb.TimestampSet, 2));
};


/**
 * @param {?proto.TimestampSet|undefined} value
 * @return {!proto.TagTimeseriesRequest} returns this
*/
proto.TagTimeseriesRequest.prototype.setTimestampSet = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TagTimeseriesRequest} returns this
 */
proto.TagTimeseriesRequest.prototype.clearTimestampSet = function() {
  return this.setTimestampSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TagTimeseriesRequest.prototype.hasTimestampSet = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 tag_value = 3;
 * @return {number}
 */
proto.TagTimeseriesRequest.prototype.getTagValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.TagTimeseriesRequest} returns this
 */
proto.TagTimeseriesRequest.prototype.setTagValue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TagTimeseriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TagTimeseriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TagTimeseriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TagTimeseriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TagTimeseriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagTimeseriesInfoList: jspb.Message.toObjectList(msg.getTagTimeseriesInfoList(),
    objects_Historian_pb.TagTimeseriesInfo.toObject, includeInstance),
    apiResponse: (f = msg.getApiResponse()) && common_API_pb.ApiResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TagTimeseriesResponse}
 */
proto.TagTimeseriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TagTimeseriesResponse;
  return proto.TagTimeseriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TagTimeseriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TagTimeseriesResponse}
 */
proto.TagTimeseriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new objects_Historian_pb.TagTimeseriesInfo;
      reader.readMessage(value,objects_Historian_pb.TagTimeseriesInfo.deserializeBinaryFromReader);
      msg.addTagTimeseriesInfo(value);
      break;
    case 2:
      var value = new common_API_pb.ApiResponse;
      reader.readMessage(value,common_API_pb.ApiResponse.deserializeBinaryFromReader);
      msg.setApiResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TagTimeseriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TagTimeseriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TagTimeseriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TagTimeseriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagTimeseriesInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      objects_Historian_pb.TagTimeseriesInfo.serializeBinaryToWriter
    );
  }
  f = message.getApiResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_API_pb.ApiResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TagTimeseriesInfo tag_timeseries_info = 1;
 * @return {!Array<!proto.TagTimeseriesInfo>}
 */
proto.TagTimeseriesResponse.prototype.getTagTimeseriesInfoList = function() {
  return /** @type{!Array<!proto.TagTimeseriesInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, objects_Historian_pb.TagTimeseriesInfo, 1));
};


/**
 * @param {!Array<!proto.TagTimeseriesInfo>} value
 * @return {!proto.TagTimeseriesResponse} returns this
*/
proto.TagTimeseriesResponse.prototype.setTagTimeseriesInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TagTimeseriesInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TagTimeseriesInfo}
 */
proto.TagTimeseriesResponse.prototype.addTagTimeseriesInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TagTimeseriesInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TagTimeseriesResponse} returns this
 */
proto.TagTimeseriesResponse.prototype.clearTagTimeseriesInfoList = function() {
  return this.setTagTimeseriesInfoList([]);
};


/**
 * optional ApiResponse api_response = 2;
 * @return {?proto.ApiResponse}
 */
proto.TagTimeseriesResponse.prototype.getApiResponse = function() {
  return /** @type{?proto.ApiResponse} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ApiResponse, 2));
};


/**
 * @param {?proto.ApiResponse|undefined} value
 * @return {!proto.TagTimeseriesResponse} returns this
*/
proto.TagTimeseriesResponse.prototype.setApiResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TagTimeseriesResponse} returns this
 */
proto.TagTimeseriesResponse.prototype.clearApiResponse = function() {
  return this.setApiResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TagTimeseriesResponse.prototype.hasApiResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TagTimeseriesValueInfoRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TagTimeseriesValueInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TagTimeseriesValueInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TagTimeseriesValueInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TagTimeseriesValueInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagTimeseriesInfoList: jspb.Message.toObjectList(msg.getTagTimeseriesInfoList(),
    objects_Historian_pb.TagTimeseriesInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TagTimeseriesValueInfoRequest}
 */
proto.TagTimeseriesValueInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TagTimeseriesValueInfoRequest;
  return proto.TagTimeseriesValueInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TagTimeseriesValueInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TagTimeseriesValueInfoRequest}
 */
proto.TagTimeseriesValueInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new objects_Historian_pb.TagTimeseriesInfo;
      reader.readMessage(value,objects_Historian_pb.TagTimeseriesInfo.deserializeBinaryFromReader);
      msg.addTagTimeseriesInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TagTimeseriesValueInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TagTimeseriesValueInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TagTimeseriesValueInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TagTimeseriesValueInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagTimeseriesInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      objects_Historian_pb.TagTimeseriesInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TagTimeseriesInfo tag_timeseries_info = 1;
 * @return {!Array<!proto.TagTimeseriesInfo>}
 */
proto.TagTimeseriesValueInfoRequest.prototype.getTagTimeseriesInfoList = function() {
  return /** @type{!Array<!proto.TagTimeseriesInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, objects_Historian_pb.TagTimeseriesInfo, 1));
};


/**
 * @param {!Array<!proto.TagTimeseriesInfo>} value
 * @return {!proto.TagTimeseriesValueInfoRequest} returns this
*/
proto.TagTimeseriesValueInfoRequest.prototype.setTagTimeseriesInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TagTimeseriesInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TagTimeseriesInfo}
 */
proto.TagTimeseriesValueInfoRequest.prototype.addTagTimeseriesInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TagTimeseriesInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TagTimeseriesValueInfoRequest} returns this
 */
proto.TagTimeseriesValueInfoRequest.prototype.clearTagTimeseriesInfoList = function() {
  return this.setTagTimeseriesInfoList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TagTimeseriesValueInfoResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TagTimeseriesValueInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TagTimeseriesValueInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TagTimeseriesValueInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TagTimeseriesValueInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagTimeseriesValueInfoList: jspb.Message.toObjectList(msg.getTagTimeseriesValueInfoList(),
    objects_Historian_pb.TagTimeseriesValueInfo.toObject, includeInstance),
    apiResponse: (f = msg.getApiResponse()) && common_API_pb.ApiResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TagTimeseriesValueInfoResponse}
 */
proto.TagTimeseriesValueInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TagTimeseriesValueInfoResponse;
  return proto.TagTimeseriesValueInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TagTimeseriesValueInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TagTimeseriesValueInfoResponse}
 */
proto.TagTimeseriesValueInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new objects_Historian_pb.TagTimeseriesValueInfo;
      reader.readMessage(value,objects_Historian_pb.TagTimeseriesValueInfo.deserializeBinaryFromReader);
      msg.addTagTimeseriesValueInfo(value);
      break;
    case 2:
      var value = new common_API_pb.ApiResponse;
      reader.readMessage(value,common_API_pb.ApiResponse.deserializeBinaryFromReader);
      msg.setApiResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TagTimeseriesValueInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TagTimeseriesValueInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TagTimeseriesValueInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TagTimeseriesValueInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagTimeseriesValueInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      objects_Historian_pb.TagTimeseriesValueInfo.serializeBinaryToWriter
    );
  }
  f = message.getApiResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_API_pb.ApiResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TagTimeseriesValueInfo tag_timeseries_value_info = 1;
 * @return {!Array<!proto.TagTimeseriesValueInfo>}
 */
proto.TagTimeseriesValueInfoResponse.prototype.getTagTimeseriesValueInfoList = function() {
  return /** @type{!Array<!proto.TagTimeseriesValueInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, objects_Historian_pb.TagTimeseriesValueInfo, 1));
};


/**
 * @param {!Array<!proto.TagTimeseriesValueInfo>} value
 * @return {!proto.TagTimeseriesValueInfoResponse} returns this
*/
proto.TagTimeseriesValueInfoResponse.prototype.setTagTimeseriesValueInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TagTimeseriesValueInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TagTimeseriesValueInfo}
 */
proto.TagTimeseriesValueInfoResponse.prototype.addTagTimeseriesValueInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TagTimeseriesValueInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TagTimeseriesValueInfoResponse} returns this
 */
proto.TagTimeseriesValueInfoResponse.prototype.clearTagTimeseriesValueInfoList = function() {
  return this.setTagTimeseriesValueInfoList([]);
};


/**
 * optional ApiResponse api_response = 2;
 * @return {?proto.ApiResponse}
 */
proto.TagTimeseriesValueInfoResponse.prototype.getApiResponse = function() {
  return /** @type{?proto.ApiResponse} */ (
    jspb.Message.getWrapperField(this, common_API_pb.ApiResponse, 2));
};


/**
 * @param {?proto.ApiResponse|undefined} value
 * @return {!proto.TagTimeseriesValueInfoResponse} returns this
*/
proto.TagTimeseriesValueInfoResponse.prototype.setApiResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TagTimeseriesValueInfoResponse} returns this
 */
proto.TagTimeseriesValueInfoResponse.prototype.clearApiResponse = function() {
  return this.setApiResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TagTimeseriesValueInfoResponse.prototype.hasApiResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto);
