import './index.scss';
import { ReactComponent as SelectedFPIcon } from "assets/icons/selectedfp.svg";
import { ReactComponent as FPIcon } from "assets/icons/fp.svg";
import { useMemo, useState, useEffect } from 'react';
import { FPLine } from 'types';
import { Avatar, Button } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { selectedFPLine } from "store/actions/operator";
import { getFilterTreeData } from 'store/actions/rootAction';

export const Sidebar = () => {
  const dispatch = useDispatch();
  const filterTreeData = useSelector((state: any) => state.root.filterTree);
  const [userInfo, setUserName] = useState<any>({
    userName: '',
    userID: ''
  });

  useEffect(() => {
    if (localStorage.getItem('userName')) {
      let name = localStorage.getItem('userName');
      if (name) {
        let str = name.split(' ').map(i => i.charAt(0).toUpperCase());
        setUserName({ userID: str.join(''), userName: name })
      }
    }
  }, [])

  const sideMenu: FPLine[] = useMemo(() => [{
    key: 1,
    icon: <SelectedFPIcon className='icon' />,
    title: 'F&P Line 1',
    selected: true,
    processLineId: '',
    processLineName: ''
  },
  {
    key: 2,
    icon: <FPIcon className='icon' />,
    title: 'F&P Line 2',
    selected: false,
    processLineId: '',
    processLineName: ''
  },
  {
    key: 3,
    icon: <FPIcon className='icon' />,
    title: 'F&P Line 3',
    selected: false,
    processLineId: '',
    processLineName: ''
  }], []);

  const [sidebar, setSidebar] = useState<FPLine[]>(sideMenu);

  useEffect(() => {
    dispatch(getFilterTreeData({ id: "2", cloudPushBool: 0 }));
  }, [dispatch]);

  useEffect(() => {
    if (filterTreeData && filterTreeData.children && filterTreeData.children.length &&
      filterTreeData.children[0].children && filterTreeData.children[0].children.length && filterTreeData.children[0].children[0].children
      && filterTreeData.children[0].children[0].children.length) {
      let data = filterTreeData.children[0].children[0].children[0].children;
      let menu = [...sideMenu];
      menu.forEach((item: any, index: any) => {
        item.title = data[index].title;
        item.key = data[index].key;
      })
      dispatch(selectedFPLine("0"));
      setSidebar(menu);
    }
  }, [filterTreeData])

  // useEffect(() => {
  //   dispatch(getProcessLineInfo());
  // }, [dispatch])

  // useEffect(() => {
  //   if (processLineList && processLineList.length > 0) {
  //     processLineList.forEach((item: any, index: number) => {
  //       sideMenu[index].processLineId = item.processLineId;
  //       sideMenu[index].processLineName = item.processLineName;
  //     })
  //     setSidebar(sideMenu);

  //     if (processLineList[0].processLineName !== '') {
  //       dispatch(getFPlineDetails(processLineList[0].processLineName))
  //     }
  //   }
  // }, [processLineList, sideMenu, dispatch])


  const lineSelected = (selectedIndex: number) => {
    let currentFP = [...sidebar];
    currentFP.forEach((item, index) => {
      if (index !== selectedIndex) {
        item.selected = false;
        item.icon = <FPIcon className='icon' />
      } else {
        item.selected = true;
        item.icon = <SelectedFPIcon className='icon' />
      }
    })

    setSidebar(currentFP);
    if (selectedIndex >= 0) {
      dispatch(selectedFPLine((selectedIndex).toString()));
    }
  }

  const signOut = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    window.location.reload();
  }

  return (
    <div className="side-bar-container">
      <div className='box-container'>
        {sidebar.map((item, index) => {
          return (
            <div key={item.key}
              onClick={() => lineSelected(index)} className="fp-box"
              style={{ border: `2px solid ${item.selected ? '#1890FF' : '#BFBFBF'}` }}>
              {item.icon}
              {item.title}
            </div>
          )
        })}
      </div>
      <div className='user-container'>
        <Avatar className='avatar'>
          {userInfo.userID}
        </Avatar>
        <span className='user-name-text'>{userInfo.userName}</span>
      </div>
      <Button onClick={signOut} className='signout-btn'><span>Sign Out</span></Button>
    </div>
  )
}