// @ts-nocheck
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as types from 'store/actions/actionTypes';
import { AssetModelService } from "store/services/assetModelService";
import { DataTrendingConfigService } from "store/services/dataTrendingService";
import { TimeSeriesService } from "store/services/timeSeriesService";

export function* getDataTrendingConfig({ type, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const dataTrendingConfigService = DataTrendingConfigService.getInstance();
        const dataTrendingViewOptions = yield call(
            [dataTrendingConfigService, dataTrendingConfigService.getViewOptions],
            payload
        );
        yield put({
            type: types.GET_DATA_TRENDING_VIEW_SUCCESS,
            dataTrendingViewOptions,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error) {
        yield put({ type: 'GET_DATA_TRENDING_VIEW_ERROR', error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getDataTrendingConfigScreenDetails({ type: string, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const dataTrendingConfigService = DataTrendingConfigService.getInstance();
        const dataTrendingViewDetails = yield call(
            [
                dataTrendingConfigService,
                dataTrendingConfigService.getCurrentScreenDetails,
            ],
            payload
        );
        yield put({
            type: types.GET_DATA_TRENDING_VIEW_DETAILS_SUCCESS,
            dataTrendingViewDetails,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error) {
        yield put({
            type: 'GET_DATA_TRENDING_VIEW_SCREEN_DETAILS_ERROR',
            error,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}
export function* saveView({ type: string, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const dataTrendingConfigService = DataTrendingConfigService.getInstance();
        const saveViewApiResp = yield call(
            [dataTrendingConfigService, dataTrendingConfigService.saveCurrentView],
            payload
        );
        if (saveViewApiResp === 0) {
            const dataTrendingViewOptions = yield call(
                [dataTrendingConfigService, dataTrendingConfigService.getViewOptions],
                payload.screenName
            );
            yield put({
                type: types.GET_DATA_TRENDING_VIEW_SUCCESS,
                dataTrendingViewOptions,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error) {
        yield put({ type: 'SAVE_DATA_TRENDING_SCREEN_VIEW_ERROR', error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

//asset model Saga combined

export function* getModelListSaga({ type: string, payload }) {

    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const assetModelService = AssetModelService.getInstance();

        const result = yield call(
            [assetModelService, assetModelService.getList],
            payload
        );
        yield put({ type: types.GET_MODELS_LIST_SUCCESS, payload: result });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error) {
        yield put({ type: 'GET_MODELS_LIST_ERROR', error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getAllTagsSaga({ type: string, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const assetModelService = AssetModelService.getInstance();
        const response = yield call(
            [assetModelService, assetModelService.getAllTags],
            payload
        );
        yield put({ type: types.GET_ALL_TAGS_SUCCESS, payload: response });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error) {
        yield put({ type: 'GET_ALL_TAGS_ERROR', error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getTagMappingTreeSaga({ type: string, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const assetModelService = AssetModelService.getInstance();
        const result = yield call(
            [assetModelService, assetModelService.getTagMappingTree],
            payload.id,
            payload.cloudPushBool
        );
        yield put({ type: types.GET_TAGMAPPING_TREE_SUCCESS, payload: result });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error) {
        yield put({ type: 'GET_TAGMAPPING_TREE_SUCCESS_ERROR', error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getAggregatedDataForTagSaga({ type: string, payload }) {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const timeSeriesService = TimeSeriesService.getInstance();
        const tagsData = yield call(
            [timeSeriesService, timeSeriesService.getAggregatedDataForTag],
            payload.selectedRowKeysGraph,
            payload.dateRange
        );

        yield put({
            type: types.GET_AGGREGATED_DATA_FOR_TAG_SUCCESS,
            graphData: tagsData,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error) {
        yield put({ type: 'GET_AGGREGATED_DATA_FOR_TAG_ERROR', error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}


export function* getTagOverviewDataSaga({ type: string, payload }) {
    const { tagIds, dateRange, page } = payload
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const timeSeriesService = TimeSeriesService.getInstance();
        const tagData = yield call([timeSeriesService, timeSeriesService.getRawDataForTag], tagIds, dateRange, page);


        yield put({
            type: types.GET_TAG_OVERVIEW_DATA_FOR_DATA_TABLE_SUCCESS,
            tagData,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error) {
        yield put({ type: 'GET_TAG_OVERVIEW_DATA_FOR_DATA_TABLE_ERROR', error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* getRawCSVDataSaga({ type: string, payload }) {
    const { tagIds, dateRange, page } = payload
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const timeSeriesService = TimeSeriesService.getInstance();
        yield call([timeSeriesService, timeSeriesService.getTimeStampRawCSV], tagIds, dateRange, page);
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error) {
        yield put({ type: 'GET_TAG_OVERVIEW_DATA_FOR_DATA_TABLE_ERROR', error });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    }
}

export function* dataTrendingSaga() {
    yield all([
        takeLatest(types.GET_DATA_TRENDING_VIEW, getDataTrendingConfig),
        takeLatest(types.GET_DATA_TRENDING_VIEW_DETAILS, getDataTrendingConfigScreenDetails),
        takeLatest(types.SAVE_DATA_TRENDING_SCREEN_VIEW, saveView),
        yield takeLatest(types.GET_MODELS_LIST, getModelListSaga),
        yield takeLatest(types.GET_ALL_TAGS, getAllTagsSaga),
        yield takeLatest(types.GET_TAGMAPPING_TREE, getTagMappingTreeSaga),
        takeEvery(types.GET_AGGREGATED_DATA_FOR_TAG, getAggregatedDataForTagSaga),
        takeLatest(types.GET_TAG_OVERVIEW_DATA_FOR_DATA_TABLE, getTagOverviewDataSaga),
        takeLatest(types.GET_RAW_CSV_DATA, getRawCSVDataSaga)
    ]);
}
