export const millisToMinutesAndSeconds = (millis: any) => {
  let minutes = Math.abs( Math.floor(millis / 60000));
  let seconds =  Math.abs((millis % 60000) / 1000).toFixed(0) as any;
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};


export const milliToMins = (milli: any) => {
  return Math.floor(milli / 60000);
};

export const millisToMinutesAndDotSeparatedSeconds = (millis: number) => {
  let minutes = Math.abs(Math.floor(millis / 60000));
  let seconds = Math.abs((millis % 60000) / 1000).toFixed(0) as any;
  return minutes + "." + (seconds < 10 ? "0" : "") + seconds;
};
