export function milliToHrs(timeMS) {
  const [MS_IN_SEC, SEC_IN_DAY, SEC_IN_HOUR, SEC_IN_MIN] = [
    1000, 86400, 3600, 60,
  ];
  let seconds = Math.round(Math.abs(timeMS) / MS_IN_SEC);
  const days = Math.floor(seconds / SEC_IN_DAY) as any;
  seconds = Math.floor(seconds % SEC_IN_DAY);
  const hours = Math.floor(seconds / SEC_IN_HOUR);
  seconds = Math.floor(seconds % SEC_IN_HOUR);
  const minutes = Math.floor(seconds / SEC_IN_MIN);
  seconds = Math.floor(seconds % SEC_IN_MIN);
  const [dd, hh, mm, ss] = [days, hours, minutes, seconds].map((item) =>
    item < 10 ? "0" + item : item.toString()
  );

  if (dd === "00") return hh + ":" + mm + ":" + ss;
  else return dd + " days " + hh + ":" + mm + ":" + ss;
}

export function milliToMachineHrs(timeMS) {
  const [MS_IN_SEC, SEC_IN_DAY, SEC_IN_HOUR, SEC_IN_MIN] = [
    1000, 86400, 3600, 60,
  ];
  let seconds = Math.round(Math.abs(timeMS) / MS_IN_SEC);
  const days = Math.floor(seconds / SEC_IN_DAY) as any;
  seconds = Math.floor(seconds % SEC_IN_DAY);
  const hours = Math.floor(seconds / SEC_IN_HOUR);
  seconds = Math.floor(seconds % SEC_IN_HOUR);
  const minutes = Math.floor(seconds / SEC_IN_MIN);
  seconds = Math.floor(seconds % SEC_IN_MIN);
  const [dd, hh, mm, ss] = [days, hours, minutes, seconds].map((item) =>
    item < 10 ? "0" + item : item.toString()
  );

  if (dd === "00") return hh + ":" + mm + ":" + ss;
  else return dd + " Machine days " + hh + ":" + mm + ":" + ss;
}

export const forHumans = (ms: any) => {
  if (ms < 0) ms = -ms;
  const time = {
    day: Math.floor(ms / 86400000),
    hour: Math.floor(ms / 3600000) % 24,
    minute: Math.floor(ms / 60000) % 60,
    second: Math.floor(ms / 1000) % 60,
    millisecond: Math.floor(ms) % 1000,
  };
  return Object.entries(time)
    .filter((val) => val[1] !== 0)
    .map((val) => val[1] + " " + (val[1] !== 1 ? val[0] + "s" : val[0]))
    .join(", ");
};
