import { Input, message, Modal, Switch } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import './index.scss';
import { Dropdown } from "components/Common/Dropdown/Dropdown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createReasonCodeFactor, editReasonCodeCategory, getReasonCodeCategories } from "store/actions/reasonCodeManagement";


export const EditResonCodeFactor = (props: any) => {
    const { visible, onOk, onCancel, category, data, reasonCodeAllStatus } = props;
    const dispatch = useDispatch();
    const categoryList = useSelector((state: any) => state.reasonCode.reasonCodeCategoryList);

    const [payload, setPayload] = useState<any>({
        category: '',
        factorName: '',
        factorUuid: '',
        status: false
    })

    const [selected, setSelected] = useState<any>({ category: "", value: "" })
    const [localStatusState, setLocalStatusState] = useState(false)

    useEffect(() => {
        dispatch(getReasonCodeCategories());
    }, [dispatch])

    useEffect(() => {
        if (data) {
            setPayload({ ...payload, category: category, factorName: data.reasonCodeFactor, factorUuid: data.key, status: data.status });
            setSelected({ ...selected, category: data.categoryTitle, value: category })
            setLocalStatusState(data.status)
        }
    }, [data])

    useEffect(() => { setLocalStatusState(data?.status) }, [visible])

    const onChange = (checked: boolean) => {
        if (reasonCodeAllStatus.length === 0) {
            setLocalStatusState(checked)
        }
        else {
            message.error('Please mark all reason codes inactive');
            return;
        }
    };

    const selectedvalue = (value: string) => {
        setPayload({ ...payload, category: value });
    }

    const handleCreate = () => {
        setPayload({ ...payload, status: localStatusState });
        if (payload.category === "") {
            message.error('please select Reascon Code Category');
            return;
        } else if (payload.factorName === "") {
            message.error('please enter Reason Code Factor Name');
            return;
        }
        dispatch(editReasonCodeCategory({ ...payload, status: localStatusState, viewType: "grid", type: 1 }));
        onOk();
        setPayload({
            category: '',
            factorName: '',
            status: false
        })
    }

    return (
        <Modal
            open={visible}
            centered
            onOk={handleCreate}
            onCancel={onCancel}
            width={336}
            closeIcon={<CloseCircleFilled />}
            wrapClassName="create-reason-code-category"
            okText="Save"
            destroyOnClose={true}
        >
            <div className="title">Edit Reason Code Factor</div>
            <div className="row">
                <label>Select Reason Code Category</label>
                <Dropdown optionData={categoryList} placeHolder={"Category Drop down"}
                    selectedValue={selectedvalue} DefaultValue={selected} />
            </div>
            <div className="row">
                <label>Reason Code Factor Name</label>
                <Input placeholder="Enter Reason Code Factor Name" className="input-text" value={payload.factorName}
                    onChange={(e) => setPayload({ ...payload, factorName: e.target.value })} />
            </div>

            <div className="status-row">
                <label>Status</label>
                <Switch checked={localStatusState} onChange={(checked: boolean) => onChange(checked)} />
            </div>
        </Modal>
    )
}