import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import { ChartDataProps } from "types";

const GuageWithHand: React.FC<ChartDataProps> = (props) => {
  const { chartID, max = false, color = "#52C41A" } = props;
  useLayoutEffect(() => {
    var root = am5.Root.new(chartID);

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360,
      })
    );

    chart.getNumberFormatter().set("numberFormat", max ? "#'Secs'" : "#'%'");

    let axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: -10,
    });

    axisRenderer.grid.template.setAll({
      stroke: root.interfaceColors.get("background"),
      visible: true,
      strokeOpacity: 0.8,
    });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: max ? props.data + 20 : 100,
        strictMinMax: true,
        renderer: axisRenderer,
      })
    );

    let axisDataItem = xAxis.makeDataItem({});

    let clockHand = am5radar.ClockHand.new(root, {
      radius: am5.percent(-20),
      innerRadius: -15,
      pinRadius: 40,
      bottomWidth: 10,
      topWidth: 0,
    });

    clockHand.pin.setAll({
      fillOpacity: 0,
      strokeOpacity: 0.0,
      stroke: am5.color(0x000000),
      strokeWidth: 1,
    });
    clockHand.hand.setAll({
      fillOpacity: 1,
      fill: am5.color(0x1890ff),
      strokeOpacity: 0.5,
      stroke: am5.color(0x000000),
      strokeWidth: 0.0,
    });

    let bullet = axisDataItem.set(
      "bullet",
      am5xy.AxisBullet.new(root, {
        sprite: clockHand,
      })
    );

    xAxis.createAxisRange(axisDataItem);

    let label = chart.radarContainer.children.push(
      am5.Label.new(root, {
        centerX: am5.percent(50),
        textAlign: "center",
        centerY: am5.percent(50),
        fontSize: "1.5em",
      })
    );

    axisDataItem.set("value", props.data);
    bullet.get("sprite").on("rotation", function () {
      let value: any = axisDataItem.get("value");
      if (props.data && props.data !== Infinity) {
        if (max) {
          label.set("text", props.data.toFixed(2) + " Secs");

        } else {
          label.set("text", Math.round(value).toString() + "%");
        }
      } else {
        label.set("text", "0");
      }
    });

    chart.bulletsContainer.set("mask", undefined);

    let colorSet = am5.ColorSet.new(root, {});

    let axisRange0: any = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: 0,
        endValue: max ? props.data + 20 : props.data,
      })
    );

    axisRange0.get("axisFill").setAll({
      visible: true,
      fill: am5.color(color),
    });

    axisRange0.get("label").setAll({
      forceHidden: true,
    });

    let axisRange1: any = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: props.data,
        endValue: max ? props.data + 20 : 100,
      })
    );

    axisRange1.get("axisFill").setAll({
      visible: true,
      fill: am5.color("#D3D3D3"),
    });

    axisRange1.get("label").setAll({
      forceHidden: true,
    });

    chart.appear(1000, 100);
    root?._logo?.dispose();

    return () => root.dispose();
  }, [chartID, props.data]);

  return <div style={{ width: "100%", height: "100%" }} id={chartID}></div>;
};
export default GuageWithHand;
