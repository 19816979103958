// @ts-nocheck
import { EyeInvisibleFilled } from '@ant-design/icons';
import { Card } from 'antd';
import { Graph } from 'components/Graph';
import { Text } from 'components/Text';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './graphContainer.scss';

export const GraphContainer = (props) => {
    const {
        graphType,
        selectedTags,
        updateGraphType,
        currentTags,
        currentNodeName,
        handleSelectedDateRange,
        setLegendsColor,
        setStreamSparkline,
        sparklineData,
    } = props;
    const tagsData = useSelector(
        (state: any) => state.dataVisualizationReducer.graphData
    );
    const [isDataEmpty, setIsDataEmpty] = useState(true);
    const [graphData, setGraphData] = useState<Array<any>>([]);
    const [numberOfSeries, setNumberOfSeries] = useState(0);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isStreamMode, setIsStreamMode] = useState<boolean>(false);
    const [liveStreamId, setLiveStreamId] = useState<string>('');
    const [showGraphComponent, setShowGraphComponent] = useState<any>();
    const [showVerticalLine, setShowVerticalLine] = useState<boolean>(false);
    const [showHorizonalLine, setShowHorizonalLine] = useState<boolean>(false);
    let responseData = useRef<any>({});

    useEffect(() => {
        setShowGraphComponent(
            <Graph
                data={graphData}
                numberOfSeries={numberOfSeries}
                type={graphType}
                fullScreenMode={isFullScreen}
                containerId="graphContainer"
                exportTitle="Tags-trend"
                currentTagsArr={currentTags}
                handleSelectedDateRange={handleSelectedDateRange}
                setLegendsColor={setLegendsColor}
                isStreamMode={isStreamMode}
                setliveStreamClientId={setLiveStreamId}
                showVerticalLine={showVerticalLine}
                showHorizonalLine={showHorizonalLine}
                setStreamSparkline={setStreamSparkline}
                sparklineData={sparklineData}
            />
        );
    }, [graphData, showVerticalLine, showHorizonalLine, isStreamMode]);

    useEffect(() => {
        responseData.current = { ...responseData.current, ...tagsData };
    }, [tagsData]);

    useEffect(() => {
        if (selectedTags && selectedTags.length) {
            setIsDataEmpty(false);
            let maxLen = Number.MIN_SAFE_INTEGER;
            let gData: any = {},
                _graphData: Array<any> = [];
            selectedTags.forEach((tagId) => {
                if (responseData.current[tagId['tagId']]) {
                    responseData.current[tagId['tagId']].forEach((d) => {
                        if (!gData[d[0]]) {
                            gData[d[0]] = [d[1]];
                            if (!gData[d[0]][tagId['tagId']]) {
                                gData[d[0]]['tagName'] = tagId['tagName'];
                                gData[d[0]]['deviceName'] = tagId['deviceName'];
                            }
                        } else {
                            gData[d[0]].push(d[1]);
                        }
                    });
                }
            });

            Object.keys(gData)
                .sort()
                .forEach((date) => {
                    let element = {};
                    const len = gData[date].length;
                    gData[date].forEach((val, ind) => {
                        element['value' + (ind + 1)] = val;
                        element['tagName'] = gData[date]['tagName'];
                        element['deviceName'] = gData[date]['deviceName'];
                    });
                    _graphData.push({ date: new Date(+date), ...element });
                });
            setNumberOfSeries(currentTags.length);
            setGraphData(_graphData);
        } else {
            setIsDataEmpty(true);
            setGraphData([]);
            setNumberOfSeries(0);
        }
    }, [selectedTags, tagsData]);

    const exitHandler = () => {
        if (
            !document.fullscreenElement &&
            !document['webkitIsFullScreen'] &&
            !document['mozFullScreen'] &&
            !document['mozFullScreen'] &&
            !document['mozFullScreen'] &&
            !document['msFullscreenElemen']
        ) {
            setIsFullScreen(false);
            removeEventForEscapePress();
        }
    };

    const addEventForEscapePress = () => {
        document.addEventListener('fullscreenchange', exitHandler);
        document.addEventListener('webkitfullscreenchange', exitHandler);
        document.addEventListener('mozfullscreenchange', exitHandler);
        document.addEventListener('MSFullscreenChange', exitHandler);
    };

    const removeEventForEscapePress = () => {
        document.removeEventListener('fullscreenchange', exitHandler);
        document.removeEventListener('webkitfullscreenchange', exitHandler);
        document.removeEventListener('mozfullscreenchange', exitHandler);
        document.removeEventListener('MSFullscreenChange', exitHandler);
    };

    const fullscreenGraphWindow = () => {
        const graphElement: HTMLElement | null =
            document.getElementById('graphContainer');
        if (graphElement) {
            if (graphElement.requestFullscreen) {
                graphElement.requestFullscreen();
            } else if (graphElement['mozRequestFullScreen']) {
                /* Firefox */
                graphElement['mozRequestFullScreen']();
            } else if (graphElement['webkitRequestFullscreen']) {
                /* Chrome, Safari & Opera */
                graphElement['webkitRequestFullscreen']();
            } else if (graphElement['msRequestFullscreen']) {
                /* IE/Edge */
                graphElement['msRequestFullscreen']();
            }
        }
        setIsFullScreen(true);
        addEventForEscapePress();
    };

    const closeFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document['webkitExitFullscreen']) {
            /* Safari */
            document['webkitExitFullscreen']();
        } else if (document['msExitFullscreen']) {
            /* IE11 */
            document['msExitFullscreen']();
        }
        setIsFullScreen(false);
    };

    const handleLiveMode = () => {
        if (liveStreamId) {
            setIsStreamMode(false);
            setLiveStreamId('');
        } else {
            setIsStreamMode(true);
        }
    };

    const showHideGraphTools = (
        <>
            {numberOfSeries > 0 ? (
                <div className="graph-live-tools">
                    <div className="graph-tools">
                        <img src="assets/images/icons/backward.svg" />
                        <img
                            src={
                                liveStreamId !== ''
                                    ? 'assets/images/icons/liveModePause.svg'
                                    : 'assets/images/icons/liveModePlay.svg'
                            }
                            className="live-mode-play-btn"
                            onClick={handleLiveMode}
                        />
                        <img src="assets/images/icons/fast-forward.svg" />
                    </div>
                </div>
            ) : null}
        </>
    );

    return (
        <>
            {isDataEmpty ? (
                <Card
                    size="small"
                    title={<Text type="p16">Trends</Text>}
                    className="graph-container empty-container"
                >
                    <div className="empty">
                        <EyeInvisibleFilled className="empty-data-icon" />
                        <span>No tags selected</span>
                        <span>Select tags from tag selection pane</span>
                    </div>
                </Card>
            ) : (
                <div className="graph-container-trending" id="graph-container-trending">
                    <Card
                        id="graphContainer"
                        size="small"
                        title={
                            <div style={{ display: 'flex', flexGrow: 1 }}>
                                <div>
                                    <Text type="p16">Trends</Text>
                                </div>
                            </div>
                        }
                        className="graph-container"
                    >
                        {showGraphComponent}
                    </Card>
                    {showHideGraphTools}
                </div>
            )}
        </>
    );
};
