import { getUrl } from "config/apiconfig";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { startTimeCalculator } from "utils/common";

const initialState = {
  root: {
    showLoader: false,
    filterTree: {},
    filterValues: {
      lineObject: [],
      nodeId: [],
      startTime: startTimeCalculator("start"),
      endTime: startTimeCalculator("end"),
      severity: "0",
      batches: [],
      sku: "5",
      page: 1,
      selectedRange: "Today",
    },
    filterFlag: false,
    filterValuesDataVisualization: {
      startTime: startTimeCalculator("start"),
      endTime: startTimeCalculator("end"),
      selectedRange: "Today",
      modelName: "",
      nodeName: "",
    },
  },
  tagsDataTrending: {
    graphData: {},
    dataTrendingViewOptions: [],
    currentViewDetails: {},
    tagMappingTree: {},
    tagList: [],
    modelList: [],
    showLoader: false,
    tagsOverviewData: [],
    tableList: [],
    rawCsvString: [],
  },
  oeeDashboard: {
    oeeAvailabilty: [],
    oeeQuality: [],
    oeePerfomance: [],
    oeeMachineWise: [],
    oeeLineWise: [],
    oeeSKUWise: [],
    oeeLossTime: [],
    oeeLossTimePercent: [],
    productionQuality: [],
    productionTime: [],
    downTimeDetails: {},
    downTimeParetoList: [],
    downTimeDurationList: [],
    downTimeCountList: [],
    downTimeReasonCodeName: [],
    productionRate: 0,
    targetRate: 0,
  },
  reasonCode: {
    reasonCodeCategoryList: [],
    reasonCodeFactor: {},
    reasonCodeDetailsDropdown: {},
    reasonCodeAllList: [],
    reasonCodeFactorDetail: {},
    reasonCodeDetails: {},
    reasonCodeListData: [],
    reasonCodeDataExpandedList: [],
    assignedReasonCode: [], //Not using now Might be used in future
    amdList: [],
  },
  alarmsEvents: {
    alarmsEventsSummaryByCountList: [],
    alarmsEventsSummaryByDurationList: [],
    alarmsEventsGuageSummaryByCountDataList: [],
    alarmsEventsGuageSummaryByDurationDataList: [],
    alarmsEventsCountByTagsList: [],
    alarmsEventsDurationByTagsList: [],
    alarmsEventsCountByDevicesList: [],
    alarmsEventsDurationByDevicesList: [],
    alarmsEventsActiveAlarmsList: [],
    alarmsEventsActiveAlarmsCardData: {},
    alarmsEventsDetailsTableList: [],
    alarmsEventsDetailsBubbleList: [],
    alarmEventRowCount: 0,
    alarmEventDetailsRowCount: 0,
  },
  operator: {
    processLineList: [],
    currentLineDetails: {},
    selectedFpLine: "",
    assignReasonCodeLoading: false,
    machineStatus: [],
  },
  lineOverview: {
    oeeData: [],
    teepMatrix: [],
    downTimeTrend: [],
    runtimeDownTime: {},
    lostTimeData: [],
    rateMatrixData: [],
    tableData: [],
    rowCount: 0,
    lineOverviewAlarmsSummaryCardData: {},
  },
  productionOverview: {
    productionOverviewBatchCountMetrics: {
      inCount: 0,
      wipCount: 0,
      goodCount: 0,
      rejectCount: 0,
      endOfLineCount: 0,
      totalCount: 0,
    },
    productionOverviewBatchLoader: false,
    productionOverviewBatchDetailsMetrics: {
      productionOrderNumber: 0,
      elapsedTime: 0,
      manufacturingTime: 0,
      startupReject: 0,
      productionReject: 0,
      percentageCount: 0,
      material:0,
      orderQuantity:0
    },
    productionOverviewActiveAlarmData: {
      productionOverviewRowCount: 0,
      productionOverviewAlarmsTableList: [],
      productionOverviewAlarmsCountByTagsList: [],
      productionOverviewAlarmsSummaryCardData: {},
    },
    productionOverviewOeeData: [],
    productionOverviewAverageCycleTimeData: {
      availableTime: 0,
      averageCycleTime: 0,
      averageDeviation: 0,
    },
    productionAssetUtitlization_percentage: 0,
    productionAssetUtitlization_availableTime: 0,
    productionAssetUtitlization_runtime: 0,
    packagingData: {
      totalCaseCount: 0,
      totalFinishedPallets: 0,
      totalUnfinishedPallets: 0,
      leftOverBottles: 0,
    },
    machineStatus: [],
  },
  runtimeAnalysis: {
    downtime: {
      currentValue: 0,
      lastValue: 0,
      percentage: 0,
    },
    runtime: {
      currentValue: 0,
      lastValue: 0,
      percentage: 0,
    },
    plannedStoptime: {
      currentValue: 0,
      lastValue: 0,
      percentage: 0,
    },
    assetUtilization: [],
    machineRuntimeStatus: [],
    mttr: {
      runtimeAnalysisMttrList: [],
      runtimeAnalysisMttrCardData: {},
    },
    mtbf: {
      runtimeAnalysisMtbfList: [],
      runtimeAnalysisMtbfCardData: {},
    },
    details: {
      runtimeStatistics: [],
      downtimeStatistics: [],
    },
    gantt: [],
    machineNames:[],
    ganttLoading:false
  },
  processOrderSummary: {
    PPAllBatches:[],
    rejectCountUpdate:false
  }
};

export default initialState;
