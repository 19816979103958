// source: common/Enum.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.upl_sandbach.DowntimeReason', null, global);
goog.exportSymbol('proto.upl_sandbach.GeneralStatusCode', null, global);
goog.exportSymbol('proto.upl_sandbach.Machines', null, global);
goog.exportSymbol('proto.upl_sandbach.ProcessStatus', null, global);
goog.exportSymbol('proto.upl_sandbach.Severity', null, global);
goog.exportSymbol('proto.upl_sandbach.Shift', null, global);
/**
 * @enum {number}
 */
proto.upl_sandbach.Shift = {
  DEFAULT_SHIFT: 0,
  CURRENT_SHIFT: 10,
  DAY_SHIFT: 20,
  NIGHT_SHIFT: 30
};

/**
 * @enum {number}
 */
proto.upl_sandbach.Severity = {
  ALL: 0,
  EVENT: 10,
  WARNING: 20,
  MINOR: 30,
  MAJOR: 40,
  CRITICAL: 50
};

/**
 * @enum {number}
 */
proto.upl_sandbach.ProcessStatus = {
  DEFAULT_STATUS: 0,
  RUNNING: 10,
  IDLE: 20,
  STOPPED: 30
};

/**
 * @enum {number}
 */
proto.upl_sandbach.GeneralStatusCode = {
  DEFAULT_CODE: 0,
  SUCCESS: 200,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500
};

/**
 * @enum {number}
 */
proto.upl_sandbach.DowntimeReason = {
  DEFAULT_REASON: 0,
  DAMAGED: 10,
  ELECTRICALLY_DEACTIVE: 20,
  OPERATOR_ERROR: 30,
  NO_ANALYSIS: 40,
  OTHER_CAUSES: 50
};

/**
 * @enum {number}
 */
proto.upl_sandbach.Machines = {
  DEFAULT_MACHINE: 0,
  FILLING: 10,
  CAPPING: 20
};

goog.object.extend(exports, proto.upl_sandbach);
