export const dataTrendingConfigViewOptions = (response: any) => {

    let viewOptionsList: Array<Object> = [];
    response.forEach((element: any) => {
        let currentConfigObj: any = {};
        currentConfigObj['id'] = element.getId().getId();
        currentConfigObj['viewName'] = element.getConfigName();
        viewOptionsList.push(currentConfigObj);
    });
    return viewOptionsList;
};