const { Severity } = require("protobuf/upl-sandbach-protos/common/Enum_pb");

export const severityToString = (value: any) => {
  let entries = Object.entries(Severity);
  let severity = "";
  entries.forEach(([key, val]) => {
    if (Number(value) === val) {
      severity = key;
    }
  });
  return severity;
};

export default severityToString;

export const severityToLowerCase = (value: number) => { let entries = Object.entries(Severity); let severity = ""; entries.forEach(([key, val]) => { if (value === val) { severity = key.toLocaleLowerCase(); } }); return severity.charAt(0).toUpperCase() + severity.slice(1); };
