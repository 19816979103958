import { Dropdown, Menu, RadioChangeEvent } from "antd";
import { Radio, Input } from "antd";
import { useState } from "react";
import {
  EyeOutlined,
  AppstoreOutlined,
  BarsOutlined,
  InsertRowAboveOutlined,
} from "@ant-design/icons";
import "./index.scss";
import { GridView } from "./Grid";
import { CustomButton } from "components/Common/Button";
import { ListView } from "./ListView";
import { ExpandedView } from "./ExpandedView";
import { NewResonCodeCategory } from "../Reason-Code-Modals/NewReasonCodeCategory";
import { NewResonCodeFactor } from "../Reason-Code-Modals/NewReasonCodeFactor";
import { NewResonCode } from "../Reason-Code-Modals/NewReasonCode";
import { handleKeyPress } from "utils/common";
import { useDispatch } from "react-redux";
import { deleteReasonCodeCategory, getReasonCodeCategoriesData } from "store/actions/reasonCodeManagement";


export const ReasonCodeManagementFilterComonent = () => {
  const { Search } = Input;
  const [selectedValue, setselectedValue] = useState("grid");
  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
  const [showFactorModal, setShowFactorModal] = useState<boolean>(false);
  const [showCodeModal, setShowCodeModal] = useState<boolean>(false);
  const optionsWithDisabled = [
    { label: <AppstoreOutlined />, value: "grid" },
    { label: <BarsOutlined />, value: "list" },
    { label: <InsertRowAboveOutlined />, value: "expandedlist" },
  ];
  const [searchValue, setSearchValue] = useState("");
  const [categoryUuid, setCategoryUuid] = useState();
  const [factorUuid, setFactorUuid] = useState();
  const [codeUuid, setCodeUuid] = useState()

  const onViewChange = ({ target: { value } }: RadioChangeEvent) => {
    setselectedValue(value);
  };

  const dispatch = useDispatch()

  const onSearch = (value: string) => { setSearchValue(value) };

  const handleMenuItemClick = (item: string) => {
    switch (item) {
      case 'category':
        setShowCategoryModal(true);
        break;
      case 'factor':
        setShowFactorModal(true);
        break;
      case 'code':
        setShowCodeModal(true);
        break;
    }
  }

  const menu = (
    <Menu
      items={[
        {
          key: 'reasonCodeCategory',
          label: <div onClick={() => handleMenuItemClick('category')}>Reason Code Category</div>
        },
        {
          key: 'resonCodeFactor',
          label: <div onClick={() => handleMenuItemClick('factor')}>Reason Code Factor</div>,
        },
        {
          key: '3',
          label: <div onClick={() => handleMenuItemClick('code')}>Reason Code</div>,
        },
      ]}
    />
  );
  const onCategoryDeleteHandler = () => {
    dispatch(deleteReasonCodeCategory({ categoryUuid, factorUuid, codeUuid }))
    setTimeout(() => {
      dispatch(getReasonCodeCategoriesData({ viewType: selectedValue }));
    }, 1000)
  }

  return (
    <>
      <div className="management-filter-container">
        <div className="filter-view-container">
          <div className="view-eye-container">
            View
            <EyeOutlined /> :
          </div>
          <div className="view-container">
            <Radio.Group
              options={optionsWithDisabled}
              onChange={onViewChange}
              value={selectedValue}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
          <div className="filter-button-container">
            {/* not using now */}
            {/* <CustomButton type={"Filter"} /> */}
          </div>
        </div>
        <div className="search-container">
          <Search
            placeholder="Search"
            onSearch={onSearch}
            style={{ width: "100%" }}
            bordered={false}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </div>
        <div className="button-container">
          {/* not using now */}
          {/* <CustomButton type="Import" />
          <CustomButton type="Save Template" /> */}
          <Dropdown overlay={menu} placement="bottomLeft" arrow trigger={['click']}>
            <CustomButton type="Add" />
          </Dropdown>
        </div>
      </div>
      {selectedValue === "grid" ? (
        <GridView
          searchValue={searchValue}
          setCategoryUuid={setCategoryUuid}
          setFactorUuid={setFactorUuid}
          setCodeUuid={setCodeUuid} />
      ) : selectedValue === "list" ? (
        <ListView
          searchValue={searchValue}
          setCategoryUuid={setCategoryUuid} />
      ) : (
        selectedValue === "expandedlist" &&
        <ExpandedView searchValue={searchValue}
          setCategoryUuid={setCategoryUuid}
          setFactorUuid={setFactorUuid}
          setCodeUuid={setCodeUuid} />
      )}
      <NewResonCodeCategory
        currentComponent={selectedValue}
        visible={showCategoryModal}
        onCancel={() => setShowCategoryModal(false)}
        onOk={() => setShowCategoryModal(false)}
      />
      <NewResonCodeFactor
        currentComponent={selectedValue}
        visible={showFactorModal}
        onCancel={() => setShowFactorModal(false)}
        onOk={() => setShowFactorModal(false)}
      />
      <NewResonCode
        currentComponent={selectedValue}
        visible={showCodeModal}
        onCancel={() => setShowCodeModal(false)}
        onOk={() => setShowCodeModal(false)}
      />
    </>
  );
};
