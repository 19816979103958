import initialState from "./initalState";
import * as types from 'store/actions/actionTypes';

export default function oeeDashboardReducer(state = initialState.oeeDashboard, action: any) {

    switch (action.type) {
        case types.GET_OEE_AVAILABILTY_DETAILS_SUCCESS:
            return { ...state, oeeAvailabilty: action.data };
        case types.GET_OEE_QUALITY_SUCCESS:
            return { ...state, oeeQuality: action.data };
        case types.GET_OEE_PERFORMANCE_SUCCESS:
            return { ...state, oeePerfomance: action.data };
        case types.GET_OEE_MACHINEWISE_SUCCESS:
            return { ...state, oeeMachineWise: action.data };
        case types.GET_OEE_BY_LINE_SUCCESS:
            return { ...state, oeeLineWise: action.data };
        case types.GET_OEE_BY_SKU_SUCCESS:
            return { ...state, oeeSKUWise: action.data };
        case types.GET_LOSS_TIME_ACTION_SUCCESS:
            return { ...state, oeeLossTime: action.data };
        case types.GET_PERCENT_LOSS_SUCCESS:
            return { ...state, oeeLossTimePercent: action.data };
        case types.GET_PRODUCTION_RATE_SUCCESS:
            return { ...state, productionRate: action.data.productionRate, targetRate: action.data.targetRate };
        case types.GET_PRODUCTION_QUALITY_SUCCESS:
            return { ...state, productionQuality: action.data };
        case types.GET_PRODUCTIVE_TIME_SUCCESS:
            return { ...state, productionTime: action.data };
        case types.GET_DOWNTIME_DETAILS_SUCCESS:
            return { ...state, downTimeDetails: action.downtimeDetails };
        case types.GET_DOWNTIME_PARETO_SUCCESS:
            return { ...state, downTimeParetoList: action.payload };
        case types.GET_DOWNTIME_DURATION_COUNT_SUCCESS:
            return {
                ...state, downTimeDurationList: action.payload.downTimeDurationList,
                downTimeCountList: action.payload.downTimeCountList,
                downTimeReasonCodeName: action.payload.reasonCodeName
            };
        default:
            return state;
    }
}