import { ArrowLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Text } from "components/Text";
import { useNavigate } from "react-router-dom";
import { ContentHeaderProps } from "types";
import "./index.scss";

const ContentHeader: React.FC<ContentHeaderProps> = ({
  heading

}) => {
  const navigate = useNavigate();

  return (
    <div className="content-header-container">
      <ArrowLeftOutlined className="arrow-left" onClick={() => navigate("/")} />
      <Text customClassName="h20">{heading}</Text>
     
    </div>
  );
};

export default ContentHeader;
