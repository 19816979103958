import ChartContainer from "components/ChartContainer";
import Bar from "components/Charts/Bar";
import Guage from "components/Charts/Guage";
import HorizontalBar from "components/Charts/HorizontalBar";
import { Pie } from "components/Charts/Pie/pie";
import OeeDataCard from "components/OeeDataCard";
import Progress from "components/Progress/Progress";
import { getUrl } from "config/apiconfig";
import UplLogo from "assets/icons/uplLogo.png";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLineOEEData } from "store/actions/lineOverview";
import {
  getLossTimeAction,
  getOEEAvailabilityAction,
  getOEEByLineAction,
  getOEEBySkuAction,
  getOEEMachineWiseAction,
  getOEEPerfAction,
  getOEEQualityAction,
  getPercentageLossAction,
  getProductinTimeAction,
  getProductionQualityAction,
  getProductionRateAction,
} from "store/actions/oeeScreen";
import { getFilterTreeData } from "store/actions/rootAction";
import { OeeFilterComponent } from "./OeeDropDownFilter";
import { message } from "antd";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { allTreeNodeTitle, dateConverter, findNestedObj } from "utils/common";
var dataBarChart = [];

var pieData = [];

var horizontalData = [];

const oeeAvailablity = [];

const LineOverviewData = [
  {
    category: "Availability",
    value: 0,
  },
  {
    category: "Performance",
    value: 0,
  },
  {
    title: "Quality",
    value: 0,
  },
];

const CardTitle = {
  OEE: 0,
  Availability: 0,
  Quality: 0,
  Performance: 0,
};
const productionData = [
  { key: "Actual Quantity", value: 9488, width: "20%" },
  { key: "Expected Quantity", value: 8685, width: "40%" },
  { key: "Target Quantity", value: 27000, width: "40%" },
];
const productiveTime = [{ key: "", value: "02:24:43", width: "20%" }];

export const OeeTab = () => {
  const oeeAvailabiltyData = useSelector(
    (state: any) => state.oeeDashboard.oeeAvailabilty
  );
  const oeeQualityData = useSelector(
    (state: any) => state.oeeDashboard.oeeQuality
  );
  const oeePerformanceData = useSelector(
    (state: any) => state.oeeDashboard.oeePerfomance
  );
  const oeeByMachineData = useSelector(
    (state: any) => state.oeeDashboard.oeeMachineWise
  );
  const oeeByLineData = useSelector(
    (state: any) => state.oeeDashboard.oeeLineWise
  );
  const oeeBySkuData = useSelector(
    (state: any) => state.oeeDashboard.oeeSKUWise
  );
  const oeeLossTimeData = useSelector(
    (state: any) => state.oeeDashboard.oeeLossTime
  );
  const oeeLossTimePercentData = useSelector(
    (state: any) => state.oeeDashboard.oeeLossTimePercent
  );
  const oeeProductionRate = useSelector(
    (state: any) => state.oeeDashboard.productionRate
  );
  const oeeProductionTargetRate = useSelector(
    (state: any) => state.oeeDashboard.targetRate
  );
  const oeeProductionQualityData = useSelector(
    (state: any) => state.oeeDashboard.productionQuality
  );
  const oeeProductionTimeData = useSelector(
    (state: any) => state.oeeDashboard.productionTime
  );
  const overallOeeData = useSelector(
    (state: any) => state.lineOverview.oeeData
  );

  const [oeeData, setOeeData] = useState<any[]>(LineOverviewData);
  const [oeeAvailableList, setOeeAvailableList] =
    useState<any[]>(oeeAvailablity);
  const [qualityList, setQualityList] = useState<any[]>(oeeAvailablity);
  const [oeePerfomanceList, setOeePerfomanceList] =
    useState<any[]>(oeeAvailablity);
  const [oeeByMachine, setoeeByMachine] = useState<any[]>(dataBarChart);
  const [oeeByLine, setOeeByLine] = useState<any[]>(pieData);
  const [oeeBySku, setOeeBySku] = useState<any[]>(horizontalData);
  const [lossTime, setLossTime] = useState<any[]>(horizontalData);
  const [lossTimePercent, setLossTimePercent] = useState<any[]>(pieData);
  const [productionRate, setProductionRate] = useState<any>(0);
  const [productionTargetRate, setProductionTargetRate] = useState<any>(0);
  const [productionQuality, setProductionQuality] =
    useState<any[]>(productionData);
  const [productionTime, setProductionTime] = useState<any[]>(productiveTime);
  const [mainValues, setMainValues] = useState<any>(CardTitle);

  const dispatch = useDispatch();

  const [payload, setPayload] = useState<any>({
    startTime: useSelector((state: any) => state.root.filterValues.startTime),
    endTime: useSelector((state: any) => state.root.filterValues.endTime),
    nodeId: useSelector((state: any) => state.root.filterValues.nodeId),
    sku: useSelector((state: any) => state.root.filterValues.sku),
  });

  const filterFlag = useSelector((state: any) => state.root.filterFlag);
  const filterTreeData = useSelector((state: any) => state.root.filterTree);
  const selectedNodeIds: any = useSelector(
    (state: any) => state.root.filterValues.nodeId
  );
  const result: any = [];

  selectedNodeIds.forEach((i) => {
    result.push(findNestedObj(filterTreeData, "key", i));
  });
  const allNodeTittle = allTreeNodeTitle(filterTreeData?.children);
  const startDate = "StartTime :" + dateConverter(payload.startTime);
  const endDate = "EndTime :" + dateConverter(payload.endTime);
  const sku = "Sku :" + payload.sku;
  const machineLines = result?.map((item: any) => {
    return item?.title;
  });
  const lineName = payload?.lineObject?.map((item: any) => {
    return item.machine;
  });
  const line =
    "Machine Line :" +
    (allNodeTittle?.toString()
      ? machineLines?.toString()
      : lineName?.toString());
  const exportImagePdf = async () => {
    message.loading("downloading pdf started", 10);
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const doc: any = new jsPDF(orientation, unit, size);
    let oeeCardsImage: any;
    let oeeGraphsImage: any;
    let timeAnalysisAndProductionRateImage: any;
    let lossAnalysisAndProductionQualityImage: any;

    const oeeCards = window.document.getElementById("oeeCards") as HTMLElement;
    const oeeGraphs = window.document.getElementById(
      "oeeGraphs"
    ) as HTMLElement;
    const timeAnalysisAndProductionRate = window.document.getElementById(
      "timeAnalysisAndProductionRate"
    ) as HTMLElement;
    const lossAnalysisAndProductionQuality = window.document.getElementById(
      "lossAnalysisAndProductionQuality"
    ) as HTMLElement;

    const img: any = new Image();

    await html2canvas(oeeCards).then((canvas) => {
      oeeCardsImage = canvas.toDataURL("image/png", 1.0);
    });
    await html2canvas(oeeGraphs).then((canvas) => {
      oeeGraphsImage = canvas.toDataURL("image/png", 1.0);
    });
    await html2canvas(timeAnalysisAndProductionRate).then((canvas) => {
      timeAnalysisAndProductionRateImage = canvas.toDataURL("image/png", 1.0);
    });
    await html2canvas(lossAnalysisAndProductionQuality).then((canvas) => {
      lossAnalysisAndProductionQualityImage = canvas.toDataURL(
        "image/png",
        1.0
      );
    });
    doc.addImage(UplLogo, "PNG", 430, 7, 36, 36);
    doc.setFontSize(18);
    doc.setFont(undefined, "bold");
    doc.text(200, 65, "OEE Analysis Report – UPL Europe (Sandbach Plant)");
    // doc.text(350, 30, "Down Time OEE Overview Report");
    doc.setFontSize(15);
    doc.setFont(undefined, "bold");

    doc.text(20, 95, "Report Information");
    doc.setFont(undefined, "normal");

    doc.text(20, 115, startDate, "left", "5");
    doc.text(20, 135, endDate);
    doc.text(20, 155, sku);
    let lines = doc.splitTextToSize(line, 900 - 57 - 57);
    doc.text(20, 175, lines);
    doc.addImage(oeeCardsImage, "PNG", 20, 180, 770, 175);
    doc.addImage(oeeGraphsImage, "PNG", 20, 370, 770, 200);
    doc.addPage();
    // // doc.addImage(progressbarsImage, "PNG", left_margin, top_margin, width, height);

    doc.addImage(timeAnalysisAndProductionRateImage, "PNG", 20, 30, 770, 175);

    doc.addImage(
      lossAnalysisAndProductionQualityImage,
      "PNG",
      20,
      220,
      770,
      300
    );

    message.info(" record(s) are getting downloaded");

    doc.save(
      "OEE Analysis Report" + "_" + moment().format("DD/MM/YYYY_HH:MM:SS")
    );
  };
  useEffect(() => {
    if (selectedNodeIds.length > 0) {
      setPayload((payload) => ({
        ...payload,
        nodeId: selectedNodeIds,
      }));
    }
  }, [selectedNodeIds]);

  useEffect(() => {
    sendRequest();
  }, [filterFlag, payload]);

  useEffect(() => {
    if (oeeQualityData) {
      setQualityList(oeeQualityData);
    }
  }, [oeeQualityData]);

  useEffect(() => {
    if (typeof overallOeeData !== undefined) {
      setOee(overallOeeData);
    }
  }, [overallOeeData]);

  useEffect(() => {
    if (typeof oeeAvailabiltyData !== undefined) {
      setOeeAvailableList(oeeAvailabiltyData);
    }
  }, [oeeAvailabiltyData]);

  useEffect(() => {
    if (typeof oeePerformanceData !== undefined) {
      setOeePerfomanceList(oeePerformanceData);
    }
  }, [oeePerformanceData]);

  useEffect(() => {
    if (typeof oeeByMachineData !== undefined) {
      setoeeByMachine(oeeByMachineData);
    }
  }, [oeeByMachineData]);

  useEffect(() => {
    if (typeof oeeByLineData !== undefined) {
      let oeeByLineDataUpto2Decimals =
        oeeByLineData.length &&
        oeeByLineData.map((ele) => {
          ele["value"] = Number(ele?.value?.toFixed(2));
          return ele;
        });
      setOeeByLine(oeeByLineDataUpto2Decimals);
    }
  }, [oeeByLineData]);

  useEffect(() => {
    if (oeeBySkuData) {
      setOeeBySku(oeeBySkuData);
    }
  }, [oeeBySkuData]);

  useEffect(() => {
    if (oeeLossTimeData) {
      setLossTime(oeeLossTimeData);
    }
  }, [oeeLossTimeData]);

  useEffect(() => {
    if (typeof oeeLossTimePercentData !== undefined) {
      setLossTimePercent(oeeLossTimePercentData);
    }
  }, [oeeLossTimePercentData]);

  useEffect(() => {
    if (oeeLossTimePercentData) {
      setProductionRate(oeeProductionRate);
      setProductionTargetRate(oeeProductionTargetRate);
    }
  }, [oeeProductionRate, oeeProductionTargetRate]);

  useEffect(() => {
    if (typeof oeeLossTimePercentData !== undefined) {
      setProductionQuality(oeeProductionQualityData);
    }
  }, [oeeProductionQualityData]);

  useEffect(() => {
    if (typeof oeeLossTimePercentData !== undefined) {
      setProductionTime(oeeProductionTimeData);
    }
  }, [oeeProductionTimeData]);

  const setOee = (data: any) => {
    let newData: any[] = [];
    let newObj: any = {};
    if (data && data.length > 0) {
      data.map((item: any) => {
        newData.push({
          title: item.category,
          value: parseFloat(item.value).toFixed(2),
          unit: "%",
        });
        newObj[item.category] = item.value;
      });

      setOeeData(newData);
      setMainValues(newObj);
    }
  };

  const sendRequest = () => {
    dispatch(getOEEAvailabilityAction(payload));
    dispatch(getOEEQualityAction(payload));
    dispatch(getOEEPerfAction(payload));
    dispatch(getOEEMachineWiseAction(payload));
    dispatch(getOEEByLineAction(payload));
    dispatch(getOEEBySkuAction(payload));
    dispatch(getLossTimeAction(payload));
    dispatch(getPercentageLossAction(payload));
    dispatch(getProductionRateAction(payload));
    dispatch(getProductionQualityAction(payload));
    dispatch(getProductinTimeAction(payload));
    dispatch(getLineOEEData(payload));
  };

  const makeRequest = (data: any) => {
    let oeePayload: any = {};
    oeePayload["startTime"] = data.startTime;
    oeePayload["endTime"] = data.endTime;
    oeePayload["nodeId"] = data.nodeId;
    oeePayload["sku"] = data.sku;
    dispatch(getLineOEEData(oeePayload));
    dispatch(getOEEAvailabilityAction(oeePayload));
    dispatch(getOEEQualityAction(oeePayload));
    dispatch(getOEEPerfAction(oeePayload));
    dispatch(getOEEMachineWiseAction(oeePayload));
    dispatch(getOEEByLineAction(oeePayload));
    dispatch(getOEEBySkuAction(oeePayload));
    dispatch(getLossTimeAction(oeePayload));
    dispatch(getPercentageLossAction(oeePayload));
    dispatch(getProductionRateAction(oeePayload));
    dispatch(getProductionQualityAction(oeePayload));
    dispatch(getProductinTimeAction(oeePayload));
  };

  return (
    <>
      <div className="content-container">
        <OeeFilterComponent
          sendRequest={makeRequest}
          download={() => exportImagePdf()}
        />
        <div className="data-card-parent-container" id="oeeCards">
          <OeeDataCard
            title="Overall OEE"
            value={parseFloat(mainValues["OEE"]).toFixed(2) + "%"}
            content={oeeData.filter((item) => item.title !== "OEE")}
          />
          <OeeDataCard
            title="Availability"
            value={parseFloat(mainValues["Availability"]).toFixed(2) + "%"}
            content={oeeAvailableList}
          />
          <OeeDataCard
            title="Performance"
            value={parseFloat(mainValues["Performance"]).toFixed(2) + "%"}
            content={oeePerfomanceList}
          />
          <OeeDataCard
            title="Quality"
            value={parseFloat(mainValues["Quality"]).toFixed(2) + "%"}
            content={qualityList}
          />
        </div>
        <div className="oee-graph-container" id="oeeGraphs">
          <ChartContainer
            id="oeeByMachine"
            title="OEE by Machine"
            alignChartTitle="center"
            tooltip=""
            child={<Bar chartID="barOeeByMachine" data={oeeByMachine} />}
            width="150px"
            height="220px"
            showFullScreen={false}
          />
          <ChartContainer
            id="oeeBySKUs"
            title="OEE by SKUs"
            alignChartTitle="left"
            tooltip=""
            child={<Bar chartID="pieOeeByF&PLine" data={oeeBySku} />}
            width="150px"
            height="220px"
            showFullScreen={false}
          />
          <ChartContainer
            id="oeeByF&PLine"
            title="OEE by F&P Line"
            alignChartTitle="left"
            tooltip=""
            child={
              <HorizontalBar
                chartID="horizontalBarOeeBySKUs"
                data={oeeByLine}
                numberFormat="#'%'"
                strictMinMax={true}
              />
            }
            width="150px"
            height="220px"
            showFullScreen={false}
          />
        </div>
        <div className="oee-graph-container" id="timeAnalysisAndProductionRate">
          <ChartContainer
            id="lossTimeAnalysis"
            title="Loss Time Analysis"
            alignChartTitle="center"
            tooltip=""
            child={
              <HorizontalBar
                chartID="horizontalBarLossTimeAnalysis"
                data={lossTime}
                numberFormat="#  Min"
              />
            }
            width="150px"
            height="180px"
            showFullScreen={false}
          />
          <ChartContainer
            id="productionRate"
            title="Production Rate"
            alignChartTitle="center"
            tooltip=""
            child={
              <div style={{ width: "100%", height: "70%" }}>
                <Guage
                  chartID="ProductionRate"
                  data={productionRate}
                  label="U/Hr"
                  radius={90}
                />
                <div className="gauge-chart-metrics-container">
                  <div>
                    <div className="metrics-name">Production Rate</div>
                    <div className="metrics-value-first">
                      {Math.floor(productionRate)}
                    </div>
                  </div>
                  <div>
                    <div className="metrics-name">Target Rate</div>
                    <div className="metrics-value-last">
                      {Math.floor(productionTargetRate)}
                    </div>
                  </div>
                </div>
              </div>
            }
            width="150px"
            height="180px"
            showFullScreen={false}
          />
        </div>
        <div
          className="oee-graph-last-container"
          id="lossAnalysisAndProductionQuality"
        >
          <ChartContainer
            id="percentageLossAnalysis"
            title="Percentage Loss Analysis"
            alignChartTitle="left"
            tooltip=""
            child={
              <Pie chartID="percentageLossAnalysisPie" data={lossTimePercent} />
            }
            width="150px"
            height="220px"
            showFullScreen={false}
          />
          <div className="progress-chart-container">
            <ChartContainer
              id="productionQuality"
              title="Production Quantity"
              alignChartTitle="left"
              tooltip=""
              child={<Progress data={productionQuality} />}
              width="650px"
              height="100px"
              showFullScreen={true}
            />
            <ChartContainer
              id="productiveTime"
              title="Productive Time"
              alignChartTitle="left"
              tooltip=""
              child={<Progress data={productionTime} />}
              width="650px"
              height="100px"
              showFullScreen={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
