import { ProcessLineDetails } from "types";
import { format } from "date-fns";

export const mapProcessLines = (processLines: any) => {
  let processLineInfo: ProcessLineDetails[] = [];
  processLines.forEach((element: any) => {
    processLineInfo.push({
      processLineId: element.getProcessLineId(),
      processLineName: element.getProcessLineName(),
      batchInfo: element.getBatchInfo() ? element.getBatchInfo() : "",
    });
  });
  return processLineInfo;
};

export const mapFPLineDetails = (lineDetails: any) => {
  const fpLineDetails = {} as any;
  fpLineDetails.batchNumber = lineDetails.getBatchNumber();
  fpLineDetails.productSKU = lineDetails.getSku();
  fpLineDetails.processOrder = lineDetails.getProductionOrderNumber();
  let time = format(new Date(lineDetails.getStartTime()), "hh:mm aaaaa'm'");
  fpLineDetails.startTime = time;
  return fpLineDetails;
};

export const mapStatusToMachine = (machineStatusList: any) => {
  let mappedMachineStatus: any = [];
  machineStatusList.forEach((element: any) => {
    mappedMachineStatus.push({
      machineId: element.getMachineId(),
      machineStatus: element.getMachineStatus(),
    });
  });
  return mappedMachineStatus.sort((a, b) => a.machineId - b.machineId);
};
