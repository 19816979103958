import { Input, Modal, Switch } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import './index.scss';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createReasonCodeCategory } from "store/actions/reasonCodeManagement";

export const NewResonCodeCategory = (props: any) => {
  const { visible, open, onOk, onCancel, currentComponent } = props;

  const [category, setCategory] = useState<string>('');
  const [status, setStatus] = useState<boolean>(true);

  const dispatch = useDispatch();

  const onChange = (checked: boolean) => {
    setStatus(checked);
  };
  const handleOnChange = (e: any) => {
    setCategory(e.target.value);
  }
  const handleCreate = () => {
    dispatch(createReasonCodeCategory({ category, status, viewType: currentComponent }));
    onOk();
    setCategory('');
    setStatus(true);
  }


  return (
    <Modal
      visible={visible}
      centered
      open={open}
      onOk={handleCreate}
      onCancel={onCancel}
      width={336}
      closeIcon={<CloseCircleFilled />}
      wrapClassName="create-reason-code-category"
      okText="Create"
      destroyOnClose={true}
    >
      <div className="title">Create New Reason Code Category</div>
      <div className="row">
        <label>Category Name</label>
        <Input placeholder="Enter Category Name" className="input-text" onChange={handleOnChange} />
      </div>

      <div className="status-row">
        <label>Status</label>
        <Switch defaultChecked onChange={onChange} />
      </div>
    </Modal>
  )
}