import { ReactComponent as BraboImg } from "assets/images/brabo.svg";
import {
  UserOutlined,
  LockOutlined
} from "@ant-design/icons";
import { useState } from "react";
import { Button, Checkbox, message, Input } from "antd";
import { generateOtp, loginUser } from "store/services/login";

export const UserLogin = (props: any) => {
  const { setAuthenticated, openForgotPassword } = props;
  const [userInfo, setUserInfo] = useState({
    userName: "",
    userPassword: "",
  });

  const loginClickHandler = (event: any) => {
    if (userInfo.userName === "" || userInfo.userPassword === "") {
      message.error('Please check username and password');
      return;
    }

    loginUser(userInfo, false)
      .then(async (response) => {
        let loginResponseData = await response.json();
        if (loginResponseData.error) {
          const error =
            (loginResponseData && loginResponseData.message) ||
            response.status;
          return Promise.reject(error);
        } else if (!loginResponseData.success) {
          message.error(
            'Your account has been disabled, please contact your administrator'
          );
        } else if (loginResponseData.success) {
          if (loginResponseData.userLoginToken) {
            localStorage.setItem('authToken', loginResponseData.userLoginToken);
          }
          localStorage.setItem('userName', userInfo.userName);
          message.success('Login Successful');
          setAuthenticated(true);
        }
      })
      .catch((error) => {
        if (error !== 500) {
          message.error('Network error, Please check your connection');
        } else {
          message.error('Incorrect Username or Password');
        }
        setAuthenticated(false);
      });
    event.preventDefault();
  }

  const enterKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter" || event.code === "Enter") {
      loginClickHandler(event);
    }
  };

  const handleForgotPassword = () => {
    if (userInfo.userName === "") {
      message.warn('please enter username for otp');
      return;
    }


    openForgotPassword('forgotpassword');
    generateOtp(userInfo.userName);
  }

  const changeUserName = (e: any) => {
    setUserInfo({
      ...userInfo,
      userName: e.target.value,
    })
    localStorage.setItem('userId', e.target.value);
  }

  return (
    <div className="data-container">
      <BraboImg className="brabo-image" />
      <Input
        className="input"
        placeholder="Username"
        value={userInfo.userName}
        prefix={<UserOutlined />}
        onChange={changeUserName}
      />
      <br />
      <Input.Password
        className="input"
        value={userInfo.userPassword}
        prefix={<LockOutlined />}
        placeholder=" password"
        onChange={(e) =>
          setUserInfo({
            ...userInfo,
            userPassword: e.target.value,
          })
        }
      />
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Checkbox className="remember-me-checkbox">
          <p className="disabled-text">Remember Me</p>
        </Checkbox>
        <p onClick={handleForgotPassword} className="disabled-text" style={{ cursor: 'pointer' }}>Forget password</p>
      </div>
      <Button
        type="primary"
        className="login-button"
        onKeyDown={() => enterKeyPress}
        onClick={loginClickHandler}
      >
        Log in
      </Button>
    </div>
  )
}