import { Modal, Input, DatePicker, message } from "antd";
import { CloseCircleFilled, ClockCircleOutlined } from "@ant-design/icons";
import "../../ReasonCodeManagement/AMD/index.scss"
import { Dropdown } from "components/Common/Dropdown/Dropdown";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { TreeSelectDropdown } from "components/Common/MachineIdDropdown";
import { assignOperatorLog } from "store/actions/operator";
import { number } from "yup/lib/locale";

const AssignModal = (props: any) => {
    const { visible, open, onOk, onCancel, selectedKey, selectedRowFilterData } = props;

    const dispatch = useDispatch();
    const dateFormat = 'DD-MM-YYYY HH:mm:ss';

    const categoryData = useSelector((state: any) => state.reasonCode.reasonCodeCategoryList);

    const factorData = useSelector((state: any) => state.reasonCode.reasonCodeFactor);

    const codeData = useSelector((state: any) => state.reasonCode.reasonCodeDetailsDropdown);
    const amdList = useSelector((state: any) => state.reasonCode.amdList);


    const [reasonCodeFactorDropdownData, setreasonCodeFactorDropdownData] = useState<any>([]);
    const [reasonCodeDropDownData, setreasonCodeDropdownData] = useState<any>([])
    const [payload, setPayload] = useState<any>({
        categoryId: '',
        factorId: '',
        reasonCodeName: '',
        status: true
    })
    const [line, setLine] = useState<any>();
    const [processOrder, setProcessOrder] = useState<any>()
    const [reasoncodeId, setReasonCodeId] = useState<any>()
    const [assignPayload, setAssignPayload] = useState<any>({
        stoppageIdList: [],
        reasonCodeId: number
    })
    const [selectedCategoryValue,setSelectedCategoryValue]=useState<any>({category:'',value:''})
    const [reasonCodeValue, setReasonCodeValue] = useState({category:'',value:'',code_value:''})
    const [selectedFactorValue,setSelectedFactorValue]=useState<any>({category:'',value:''})
    const [categoryObject,setCategoryObject]=useState<any>({});
    const [factorObject,setFactorObject]=useState<any>({});

    const selectedCategory = (value: string,category:string) => {
        const categoryObject=categoryData.find((item:any) => item.value === value);
        setCategoryObject(categoryObject)
        setSelectedCategoryValue({category:category,value:value})
        setPayload({ ...payload, categoryId: value, factorId: '' });
        setreasonCodeFactorDropdownData(factorData[value]);
    }
    const selectedFactor = (value: string,category: string,code_value:any) => {
        const factorObject=factorData[selectedCategoryValue?.value].find((item:any) => item.value === value);
        setFactorObject(factorObject);
        setReasonCodeValue({category:category,value:value,code_value:code_value})
        setPayload({ ...payload, factorId: value });
        setreasonCodeDropdownData(codeData[value])
    }
    const selectedCode = (value: string) => {
        
        const codeObject=codeData[reasonCodeValue?.value].find((item:any) => item.code === value);

        setReasonCodeId(value)

        setAssignPayload({ ...assignPayload, reasonCodeId: value,categoryObject:categoryObject,factorObject:factorObject,codeObject:codeObject ,amdList:amdList});
    }
    const handleSelectedLine = (data: any) => {
        setLine(data)
    };
    const handleAssign = () => {
        dispatch(assignOperatorLog({ ...assignPayload,amdList:amdList, stoppageIdList: [selectedKey] }))
        onOk();
    }

    useEffect(() => {
        setSelectedCategoryValue({
          category: selectedRowFilterData?.reasonCodeCategory?.title,
          value: selectedRowFilterData?.reasonCodeCategory?.id
        })
        setSelectedFactorValue({ category: selectedRowFilterData?.reasonCodeFactor?.title, id: selectedRowFilterData?.reasonCodeFactor?.id });
        setReasonCodeValue({
          category: selectedRowFilterData?.reasonCodeDetail?.title,
          value: selectedRowFilterData?.reasonCodeDetail?.id,
          code_value:selectedRowFilterData?.reasonCodeDetail?.code_value
        })
        setReasonCodeId(selectedRowFilterData?.reasonCodeDetail?.code_value)
      }, [selectedRowFilterData?.stoppageId,open])
    return (
        <Modal
            visible={visible}
            centered
            open={open}
            onOk={handleAssign}
            onCancel={onCancel}
            width={528}
            closeIcon={<CloseCircleFilled />}
            wrapClassName="add-reasoncode-modal"
            destroyOnClose={true}
            okText="Assign"
        >
            <div className="add-modal-container">
                <div className="title">Assign Reason Code</div>
                <div className="input-container">
                    <div className="input-content">
                        <span>Filling & Packing Line</span>
                           <Input placeholder="Filling & Packing Line" value={selectedRowFilterData?.fpLine}  disabled/>
                    </div>
                    <div className="input-content">
                        <span>Equipment</span>
                        <Input
                            value={selectedRowFilterData?.equipment}
                            placeholder="Capping Machine"
                            onChange={(e) => setProcessOrder(e.target.value)} disabled/>
                    </div>
                </div>
                <div className="input-container">
                    <div className="input-content">
                        <span>Start Time</span>
                        <DatePicker
                            showTime={{ format: 'DD-MM-YYYY HH:mm:ss' }}
                            format="DD-MM-YYYY HH:mm:ss"
                            suffixIcon={<ClockCircleOutlined />}
                            defaultValue={moment(selectedRowFilterData?.startDate, dateFormat) }
                            disabled />
                    </div>
                    <div className="input-content">
                        <span>Reason Code Category</span>
                        <Dropdown
                            optionData={categoryData}
                            placeHolder={"Reason Code Category"}
                            selectedValue={selectedCategory}
                            DefaultValue={selectedCategoryValue.category?selectedCategoryValue.category:''}
                        />
                    </div>
                </div>
                <div className="input-container">
                    <div className="input-content">
                        <span>Reason Code Factor</span>
                        <Dropdown
                            optionData={reasonCodeFactorDropdownData}
                            placeHolder={"Reason Code Factor"}
                            selectedValue={selectedFactor}
                            DefaultValue={ selectedFactorValue.category?selectedFactorValue.category:''} />
                    </div>
                    <div className="input-content">
                        <span>Reason Code</span>
                        <Dropdown
                            data={reasonCodeDropDownData}
                            placeHolder={"Reason Code"}
                            selectedValue={selectedCode} 
                            DefaultValue={reasonCodeValue.category?reasonCodeValue.category:''}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default AssignModal;