import { message } from "antd";
import { getUrl } from "config/apiconfig";
import { mapReasonCodeDetails, mapAllReasonCodeDetails, mapReasonCodeDetailsListView, mapReasonCodeDetailExpandedView, mapAssignedReasonCodeDetails, mapAMDList, } from 'mappers/reasoncode.mapper';
import { AddReasonCode, ReasonCodeCategory, ReasonCodeFactor } from "types";

export class ReasonCodeService {
  private static instance: ReasonCodeService;
  private reasonCodeManagementServiceClient: any;
  private reasonCodeManagementProtoObj: any;
  private commonProtoObj: any;
  private reasonCodeModelProtoObj: any;
  private authToken: any = '';
  private metadata: any;

  private constructor() {
    const reasonCodeURL = getUrl('reasonCodeUrl');
    this.commonProtoObj = require('protobuf/reason-code-management-protos/common/Commons_pb');
    this.reasonCodeModelProtoObj = require('protobuf/reason-code-management-protos/model/ReasonCodeModel_pb');
    const reasonCodeManagementService = require('protobuf/reason-code-management-protos/service/ReasonCodeManagementService_grpc_web_pb');
    this.reasonCodeManagementProtoObj = require('protobuf/reason-code-management-protos/service/ReasonCodeManagementService_pb');
    this.reasonCodeManagementServiceClient = new reasonCodeManagementService.ReasonCodeManagementServicePromiseClient(reasonCodeURL, null, null);
    this.authToken = localStorage.getItem('authToken');
    this.metadata = { 'authorization': 'Bearer ' + this.authToken };
  }

  public static getInstance(): ReasonCodeService {
    if (!ReasonCodeService.instance) {
      ReasonCodeService.instance = new ReasonCodeService();
    }
    return ReasonCodeService.instance;
  }

  //EDIT REASON CODE
  editReasonCodeCategory = (payload: any) => {

    const request = new this.reasonCodeManagementProtoObj.UpsertReasonCodeRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedby('brabo');
    generalRequest.setRequestedfor('edit-reason-code-category');
    request.setGeneralRequest(generalRequest);
    const batchRequestUpdated = this.handleEditType(payload);
    request.setReasonCodeBatchDetails(batchRequestUpdated);
    request.setOperationInfo(1);
    return this.reasonCodeManagementServiceClient.upsertReasonCode(request, this.metadata).then((response: any) => {
      this.handleEditResponse(payload, response);
      return true;
    })
      .catch((err: any) => {
        message.error('Unable to Edit Reason Code Category');
      });
  }


  handleEditResponse = (payload: any, response: any) => {
    switch (payload.type) {
      case 0:
        if (response.getGeneralResponse().getResponsecode() === 1) {
          message.success('Reason Code Category Edit');
        }
        break;
      case 1:
        if (response.getGeneralResponse().getResponsecode() === 4) {
          message.success('Reason Code Factory Edited Succesfully');

        }
        break;
      case 2:
        if (response.getGeneralResponse().getResponsecode() === 7) {
          message.success('Reason Code Details Edited Succesfully');

        }
        break;
      default:
        break;
    }
  }


  handleEditType = (payload: any) => {
    const reasonCodeBatchDetails = new this.reasonCodeModelProtoObj.ReasonCodeBatchDetails();
    const categoryInfo = new this.reasonCodeModelProtoObj.ReasonCodeCategoryInfo();
    const factorInfo = new this.reasonCodeModelProtoObj.ReasonCodeFactorInfo();
    const detailsInfo = new this.reasonCodeModelProtoObj.ReasonCodeDetailsInfo();
    switch (payload.type) {
      case 0:
        categoryInfo.setReasonCodeCategoryTitle(payload.category);
        categoryInfo.setState(payload.status)
        categoryInfo.setReasonCodeCategoryUuid(payload.uuid);
        reasonCodeBatchDetails.setReasonCodeCategoryInfo(categoryInfo);
        return reasonCodeBatchDetails;

      case 1:
        categoryInfo.setReasonCodeCategoryUuid(payload.category);
        factorInfo.setReasonCodeFactorTitle(payload.factorName)
        factorInfo.setReasonCodeFactorUuid(payload.factorUuid)
        factorInfo.setStatus(payload.status)
        reasonCodeBatchDetails.setReasonCodeFactorInfo(factorInfo);
        reasonCodeBatchDetails.setReasonCodeCategoryInfo(categoryInfo);
        return reasonCodeBatchDetails;

      case 2:
        categoryInfo.setReasonCodeCategoryUuid(payload.category);
        factorInfo.setReasonCodeFactorUuid(payload.factorUuid)
        detailsInfo.setStatus(payload.status)
        detailsInfo.setReasonCodeDetailsUuid(payload.reasonUuid);
        detailsInfo.setReasonCodeDetailsTitle(payload.reasonName);
        reasonCodeBatchDetails.setReasonCodeFactorInfo(factorInfo);
        reasonCodeBatchDetails.setReasonCodeCategoryInfo(categoryInfo);
        reasonCodeBatchDetails.setReasonCodeDetailsInfo(detailsInfo);
        return reasonCodeBatchDetails;
      default:
        break;
    }
  }

  createReasonCodeCategory = (payload: ReasonCodeCategory) => {
    const request = new this.reasonCodeManagementProtoObj.UpsertReasonCodeRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedby('brabo');
    generalRequest.setRequestedfor('create-reason-code-category');
    request.setGeneralRequest(generalRequest);
    const reasonCodeBatchDetails = new this.reasonCodeModelProtoObj.ReasonCodeBatchDetails();
    const categoryInfo = new this.reasonCodeModelProtoObj.ReasonCodeCategoryInfo();
    categoryInfo.setReasonCodeCategoryTitle(payload.category);
    categoryInfo.setStatusList([payload.status]);
    reasonCodeBatchDetails.setReasonCodeCategoryInfo(categoryInfo);
    request.setReasonCodeBatchDetails(reasonCodeBatchDetails);
    return this.reasonCodeManagementServiceClient.upsertReasonCode(request, this.metadata).then((response: any) => {
      message.success('Reason Code Category Created');
      return true;
    })
      .catch((err: any) => {
        message.error('Unable to add Reason Code Category');
      });
  }



  createResonCodeFactor = (payload: ReasonCodeFactor) => {
    const request = new this.reasonCodeManagementProtoObj.UpsertReasonCodeRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedby('brabo');
    generalRequest.setRequestedfor('create-reason-code-category');
    request.setGeneralRequest(generalRequest);
    const factorInfo = new this.reasonCodeModelProtoObj.ReasonCodeFactorInfo();
    factorInfo.setReasonCodeFactorTitle(payload.factorName);
    factorInfo.setStatus(payload.status);
    const reasonCodeBatchDetails = new this.reasonCodeModelProtoObj.ReasonCodeBatchDetails();
    const categoryInfo = new this.reasonCodeModelProtoObj.ReasonCodeCategoryInfo();
    categoryInfo.setReasonCodeCategoryUuid(payload.categoryId);
    reasonCodeBatchDetails.setReasonCodeCategoryInfo(categoryInfo);
    reasonCodeBatchDetails.setReasonCodeFactorInfo(factorInfo);
    request.setReasonCodeBatchDetails(reasonCodeBatchDetails);
    return this.reasonCodeManagementServiceClient.upsertReasonCode(request, this.metadata).then((response: any) => {
      message.success('Reason Code Factor Created');
      return true;
    })
      .catch(() => {
        message.error('Unable to add Reason Factor to Category');
      });
  }

  createReasonCodeDetails = (payload: AddReasonCode) => {
    const request = new this.reasonCodeManagementProtoObj.UpsertReasonCodeRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedby('brabo');
    generalRequest.setRequestedfor('create-reason-code-details');
    request.setGeneralRequest(generalRequest);
    const detailsInfo = new this.reasonCodeModelProtoObj.ReasonCodeDetailsInfo();
    detailsInfo.setReasonCodeDetailsTitle(payload.reasonCodeName);
    detailsInfo.setStatus(payload.status);
    const factorInfo = new this.reasonCodeModelProtoObj.ReasonCodeFactorInfo();
    factorInfo.setReasonCodeFactorUuid(payload.factorId);
    const reasonCodeBatchDetails = new this.reasonCodeModelProtoObj.ReasonCodeBatchDetails();
    const categoryInfo = new this.reasonCodeModelProtoObj.ReasonCodeCategoryInfo();
    categoryInfo.setReasonCodeCategoryUuid(payload.categoryId);
    reasonCodeBatchDetails.setReasonCodeCategoryInfo(categoryInfo);
    reasonCodeBatchDetails.setReasonCodeFactorInfo(factorInfo);
    reasonCodeBatchDetails.setReasonCodeDetailsInfo(detailsInfo);
    request.setReasonCodeBatchDetails(reasonCodeBatchDetails);
    return this.reasonCodeManagementServiceClient.upsertReasonCode(request, this.metadata).then((response: any) => {
      message.success('Reason Code Name added to Reason Code Category');
      return true;
    })
      .catch(() => {
        message.error('Unable to add Reason Code added to Reason Code Category');
      });
  }

  getReasonCodeDetails = () => {
    const request = new this.reasonCodeManagementProtoObj.ReasonCodeManagementRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedby('brabo');
    generalRequest.setRequestedfor('get-reason-code-details');
    request.setGeneralRequest(generalRequest);
    const viewType = new this.reasonCodeModelProtoObj.ViewType();
    viewType.setExpandedView(true);
    const reasonCodeData = new this.reasonCodeManagementProtoObj.ReasonCodeData();
    reasonCodeData.setViewType(viewType);
    request.setReasonCodeData(reasonCodeData);
    return this.reasonCodeManagementServiceClient.getReasonCodeManagementData(request, this.metadata).then((response: any) => {
      return mapReasonCodeDetails(response.getReasonCodeCategoryData().getReasonCodeCategoryInfoList());
    })
      .catch(() => {
        message.error('Unable to get reason code category');
      });
  }

  deleteReasonCodeCategory = (payload: any) => {
    const request = new this.reasonCodeManagementProtoObj.UpsertReasonCodeRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedby('brabo');
    generalRequest.setRequestedfor('delete-reason-code-category');
    request.setGeneralRequest(generalRequest);
    const reasonCodeCategoryInfo = new this.reasonCodeModelProtoObj.ReasonCodeCategoryInfo();
    reasonCodeCategoryInfo.setReasonCodeCategoryUuid(payload.categoryUuid);
    const reasonCodeBatchDetails = new this.reasonCodeManagementProtoObj.ReasonCodeBatchDetails();
    reasonCodeBatchDetails.setReasonCodeCategoryInfo(reasonCodeCategoryInfo);
    if (payload.factorUuid) {
      const reasonCodeFactorInfo = new this.reasonCodeModelProtoObj.ReasonCodeFactorInfo();
      reasonCodeFactorInfo.setReasonCodeFactorUuid(payload.factorUuid)
      reasonCodeBatchDetails.setReasonCodeFactorInfo(reasonCodeFactorInfo);
    }
    if (payload.codeUuid) {
      const reasonCodeInfo = new this.reasonCodeModelProtoObj.ReasonCodeDetailsInfo();
      reasonCodeInfo.setReasonCodeDetailsUuid(payload.codeUuid);
      reasonCodeBatchDetails.setReasonCodeDetailsInfo(reasonCodeInfo);
    }
    request.setReasonCodeBatchDetails(reasonCodeBatchDetails);
    request.setOperationInfo(3);
    return this.reasonCodeManagementServiceClient.upsertReasonCode(request, this.metadata).then((response: any) => {
      message.success('Reason Code category Delete');
      return true;
    })
      .catch((err: any) => {
        message.error('Unable to delete Reason Code Category');
      });
  }

  getViewTypeRequest = (type: string) => {
    const viewType = new this.reasonCodeModelProtoObj.ViewType();
    switch (type) {
      case 'grid':
        viewType.setGridView(true);
        break;
      case 'list':
        viewType.setListView(true);
        break;
      case 'expandedlist':
        viewType.setExpandedView(true);
        break;
      default:
        viewType.setListView(true);
    }
    return viewType;
  }

  getAllReasonCodes = (payload: any) => {
    const request = new this.reasonCodeManagementProtoObj.ReasonCodeManagementRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedby('brabo');
    generalRequest.setRequestedfor('get-all-reason-code');
    request.setGeneralRequest(generalRequest);
    const reasonCodeData = new this.reasonCodeManagementProtoObj.ReasonCodeData();
    reasonCodeData.setViewType(this.getViewTypeRequest(payload.viewType));
    request.setReasonCodeData(reasonCodeData);
    return this.reasonCodeManagementServiceClient.getReasonCodeManagementData(request, this.metadata).then((response: any) => {
      if (payload.viewType === 'grid') {
        return mapAllReasonCodeDetails(response.getReasonCodeCategoryData().getReasonCodeCategoryInfoList());
      } else if (payload.viewType === 'list') {
        return mapReasonCodeDetailsListView(response.getReasonCodeCategoryListData().getReasonCodeInfoListList());
      } else {
        return mapReasonCodeDetailExpandedView(response.getReasonCodeCategoryData().getReasonCodeCategoryInfoList())
      }
    })
      .catch((err: any) => {
        message.error('Unable to get reason code category');
      });
  }

  getAllAssignedReasonCode = (payload: any) => {
    const request = new this.reasonCodeManagementProtoObj.AssignedCodeRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedby('brabo');
    generalRequest.setRequestedfor('get-all-reason-code');
    request.setGeneralRequest(generalRequest);
    const asignedReasonCodeData = new this.reasonCodeManagementProtoObj.ResourceData();
    asignedReasonCodeData.setResourceInfo(payload.equipment_id);
    request.setResourceData(asignedReasonCodeData);
    return this.reasonCodeManagementServiceClient.getAssignedReasonCodeData(request, this.metadata).then((response: any) => {
      return mapAssignedReasonCodeDetails(response.getReasonCodeInfoList());
    })
      .catch((err: any) => {
        message.error('Unable to get reason code category');
      });
  }

  getAMDList = (payload: any) => {
    const request = new this.reasonCodeManagementProtoObj.AMDReasonCodeDetailRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedby('brabo');
    generalRequest.setRequestedfor('get-amd-reason-code');
    request.setGeneralRequest(generalRequest);
    const filter = new this.reasonCodeManagementProtoObj.AMDFilter();
    filter.setShiftInfo(payload.shiftInfo);
    filter.setStartTime(payload.startTime);
    filter.setEndTime(payload.endTime);
    filter.setTextFilter(payload.searchText);
    request.setAmdFilter(filter);
    return this.reasonCodeManagementServiceClient.getReasonCodeDetailedInfoAmd(request, this.metadata).then((response: any) => {
      return mapAMDList(response.getReasonCodeLogInfoList());
    })
      .catch((err: any) => {
        message.error('Unable to get reason code amd list');
      });
  }

  assignmentReasonCodeToResource = (payload: any) => {
    const request = new this.reasonCodeManagementProtoObj.AssignNewCodeRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedby('brabo');
    generalRequest.setRequestedfor('get-all-reason-code');
    request.setGeneralRequest(generalRequest);
    request.setReasonCodeUuid(payload.reasonCodeUuid);
    request.setEquipmentId(payload.equipmentId);
    return this.reasonCodeManagementServiceClient.assignReasonCodeToResource(request, this.metadata).then((response: any) => {
      message.success('Reason Code Details has been assigned to equipment');
    })
      .catch((err: any) => {
        message.error('Unable to get Assign Reason Code Details to equipment');
      });
  }

  unAssignmentReasonCodeToResource = (payload: any) => {
    const request = new this.reasonCodeManagementProtoObj.AssignNewCodeRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedby('brabo');
    generalRequest.setRequestedfor('unassign-reason-code-to-resource');
    request.setGeneralRequest(generalRequest);
    request.setReasonCodeUuid(payload.reasonCodeUuid);
    request.setEquipmentId(payload.equipmentId);
    return this.reasonCodeManagementServiceClient.unAssignReasonCodeToResource(request, this.metadata).then((response: any) => {
      message.success('Reason Code Details has been unAssigned to equipement');
    })
      .catch((err: any) => {
        message.error('Unable to unAssign Reason Code Details to equipement');
      });
  }

  endDownTimeAmd = (payload: any) => {
    const request = new this.reasonCodeManagementProtoObj.EndDowntimeRequest();
    request.setStoppageIdList(payload.stoppageId);
    request.setEndTime(payload.endTime);
    return this.reasonCodeManagementServiceClient.endDowntime(request, this.metadata).then((response: any) => {
      message.success('End DownTime successful ');
    })
      .catch((err: any) => {
        message.error('Unable to End DownTime ');
      });
  }

  createReasonCodeLogs = (payload: any) => {
    const request = new this.reasonCodeManagementProtoObj.UpsertReasonCodeLogsRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedby('brabo');
    generalRequest.setRequestedfor('create-reason-code');
    request.setProcessLine(payload.processLine);
    request.setBatchNumber(payload.batchNumber);
    request.setProcessOrder(payload.processOrder);
    request.setProductSku(payload.productSku);
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    request.setReasonCodeId(payload.reasonCodeId);
    request.setParentId(payload.parentId)
    request.setGeneralRequest(generalRequest);
    return this.reasonCodeManagementServiceClient.upsertReasonCodeToLogs(request, this.metadata).then((response: any) => {
      message.success('Created Reason Code Log');
      return true;
    })
      .catch((err: any) => {
        message.error('Unable to Create Reason Code Log');
      });
  }


  deleteRasonCodeLogs = (payload: any) => {
    const request = new this.reasonCodeManagementProtoObj.DeleteLogRequest();
    request.setStoppageIdList(payload.stoppageId)
    return this.reasonCodeManagementServiceClient.deleteReasonCodeLog(request, this.metadata).then((response: any) => {
      message.success('Deleted Reason Code Log');
    })
      .catch((err: any) => {
        message.error('Unable to Delete Reason Code Log');
      });
  }

  editReasonCodeLogs = (payload: any) => {
    const request = new this.reasonCodeManagementProtoObj.UpsertReasonCodeLogsRequest();
    const generalRequest = new this.commonProtoObj.GeneralRequest();
    generalRequest.setRequestedby('brabo');
    generalRequest.setRequestedfor('edit-reason-code');
    request.setStoppageId(payload.stoppageId);
    request.setProcessLine(payload.processLine);
    request.setBatchNumber(payload.batchNumber);
    request.setProcessOrder(payload.processOrder);
    request.setProductSku(payload.productSku);
    request.setStartTime(payload.startTime);
    request.setEndTime(payload.endTime);
    request.setReasonCodeId(payload.reasonCodeId);
    request.setParentId(payload.parentId);
    request.setGeneralRequest(generalRequest);
    return this.reasonCodeManagementServiceClient.upsertReasonCodeToLogs(request, this.metadata).then((response: any) => {
      message.success('Reason Code Log is edited');
      return true;
    })
      .catch((err: any) => {
        message.error('Unable to Edit Reason Code Log');
      });
  }
}
