// Note: This neeeds to be fixed from backend and then removed from ui

const { MachineStatus } = require("protobuf/reason-code-management-protos/enums/ReasonCodeEnum_pb");

export const lineToEnum = (value: string) => {
  if (value !== "DEFAULT_SKU") {
    return value.replace("0", "");
  }
  return value;
};

export const machineToEnum=(machine:string)=>{
    if(machine!=="DEFAULT_MACHINE"){
        return machine.substring(0,7).toUpperCase();
    }
    return machine
}

export const machineStatusToString = (value: any) => {
  let machineKeys = Object.entries(MachineStatus);
  let severity = "";
  machineKeys.forEach(([key, val]) => {
    if (Number(value) === val) {
      severity = key;
    }
  });
  return severity;
};